import React from 'react';
import PropTypes from 'prop-types';
import EntityField from './entity-field';

const NewEntity = ({
  setEntityName,
  entityName,
  customEntity,
  entityTemplateFields,
  cancelNewEntity,
  createEntity,
  creatingEntity,
  fieldState,
  dispatch,
}) => {
  const renderEntityField = (field) => {
    return (
      <EntityField
        field={field}
        newEntity
        editing={false}
        fieldState={fieldState}
        dispatch={dispatch}
      />
    );
  };

  return (
    <div className='entityinput raised'>
      <label> Name
        <input type='text' onChange={(e) => setEntityName(e.target.value)} value={entityName} placeholder={`Enter ${customEntity.name} name`} />
      </label>
      <ul>
        {entityTemplateFields.map(renderEntityField)}
      </ul>
      <div className='align-right'>
        <button type='button' className='btn-link margin-right' onClick={cancelNewEntity}>Cancel</button>
        <button type='button' name='saveentity' onClick={createEntity} disabled={creatingEntity}>Save {customEntity.name}</button>
      </div>
    </div>
  );
};

NewEntity.propTypes = {
  setEntityName: PropTypes.func.isRequired,
  entityName: PropTypes.string,
  customEntity: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  entityTemplateFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cancelNewEntity: PropTypes.func.isRequired,
  createEntity: PropTypes.func.isRequired,
  creatingEntity: PropTypes.bool.isRequired,
  fieldState: PropTypes.shape({}).isRequired,
};

export default NewEntity;
