import React, {
  useState, useRef, useMemo, useContext,
} from 'react';
import PropTypes from 'prop-types';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import Popdown from 'components/shared/popdown';
import {
  waiveRequirement,
  unwaiveRequirement,
  unlinkSubprocess,
} from 'actions/subprocess-requirement-actions';
import { confirm } from 'modules/alert-confirm';
import useOnclickOutside from 'react-cool-onclickoutside';

const Options = ({
  requirementId,
  isWaived,
  unlinkable,
  isGov,
}) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);
  const onWaiveClick = () => {
    waiveRequirement(dispatch, requirementId);
    setOptionsOpen(false);
  };

  const onUnwaiveClick = () => {
    unwaiveRequirement(dispatch, requirementId);
    setOptionsOpen(false);
  };

  const onUnlinkClick = () => {
    confirm('Unlink subprocess?', 'Are you sure you want to unlink this sub-submission from its parent submission?', () => {
      unlinkSubprocess(dispatch, requirementId);
      setOptionsOpen(false);
    });
  };

  const optionsMenuRef = useRef();
  const optionsAnchor = useRef();
  const [optionsOpen, setOptionsOpen] = useState(false);

  useOnclickOutside(() => {
    setOptionsOpen(false);
  }, { refs: [optionsAnchor], ignoreClass: 'popdown' });

  const onOptionsClick = () => {
    setOptionsOpen(!optionsOpen);
  };

  const options = useMemo(() => {
    const options = [];

    if (unlinkable) {
      options.push((
        <li>
          <button
            type='button'
            onClick={onUnlinkClick}
          >
            Unlink subprocess
          </button>
        </li>
      ));
    }

    if (!isGov) { return options; }

    if (!isWaived) {
      options.push((
        <li>
          <button
            type='button'
            onClick={onWaiveClick}
          >
            Waive requirement
          </button>
        </li>
      ));
    } else {
      options.push((
        <li>
          <button
            type='button'
            onClick={onUnwaiveClick}
          >
            Unwaive requirement
          </button>
        </li>
      ));
    }

    return options;
  }, [isWaived, unlinkable]);

  return (
    <div className='flowtimeline-activerequirement-options'>
      <button
        ref={optionsAnchor}
        type='button'
        className='unstyled'
        onClick={onOptionsClick}
      >
        <i
          className='icon icon-dots-horizontal bigger margin-right-less'
        />
      </button>
      { optionsOpen && (
        <Popdown
          anchorRef={optionsAnchor}
          justify='right'
        >
          <ul
            className='popdown-listmenu'
            ref={optionsMenuRef}
          >
            {options}
          </ul>
        </Popdown>
      )}
    </div>
  );
};

Options.propTypes = {
  requirementId: PropTypes.number.isRequired,
};

export default Options;
