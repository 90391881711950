import React, { useState, useEffect } from 'react';
import AddressValidator from './address_input/address-validator';

const AddressInput = ({defaultValue, completionBegun, onChange, innerRef, required, disabled, id}) => {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [initialDefault, setInitialDefault] = useState(defaultValue);

  useEffect(() => {
    if (initialDefault !== defaultValue) {
      completionBegun();
    }
  }, [defaultValue, initialDefault]);

  function handleFocus() {
    setFocused(true);
  }

  function handleSave(address) {
    setFocused(false);
    setValue(address);
    onChange({}, address);
  }

  function handleCancel() {
    completionBegun();
    setFocused(false);
  }

  if (focused) {
    return (
      <AddressValidator defaultValue={value} handleSave={handleSave} handleCancel={handleCancel} />
    );
  }

  return (
    <input
      name='address'
      id={id}
      ref={innerRef}
      aria-required={required}
      onFocus={handleFocus}
      disabled={disabled}
      type='text'
      defaultValue={value}
      autoComplete='new-password'
    />
  );
};

export default AddressInput;
