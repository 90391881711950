import StepDataAPI from '../apis/step-data-api';
import LogicConfigAPI from '../apis/logic-config-api';
import Flash from '../components/shared/flash';
import Dispatcher from '../appdispatcher';
import FlowStepConstants from '../constants/template-manager-constants';

function onLogicConfigSaved(logicConfig) {
  Dispatcher.dispatch({
    actionType: FlowStepConstants.LOGIC_CONFIG_ADDED,
    logicConfig,
  });
}

function onLogicConfigDestroy(res) {
  const logicConfig = res.logic_config;
  const newStepAssignmentGroups = res?.new_groups;

  Dispatcher.dispatch({
    actionType: FlowStepConstants.LOGIC_CONFIG_DESTROYED,
    logicConfig,
    newStepAssignmentGroups,
  });
}

function formatConditions(conditions) {
  return conditions.map((c, index) => {
    return {
      source_id: c.source_id,
      source_type: 'StepDatum',
      comparator: c.comparator,
      match_value: c.match_value,
      connective: (index === conditions.length - 1) ? null : c.connective, // Last condition has no connective
    };
  });
}

const LogicActions = {
  fetchAvailableFields(sectionId) {
    return StepDataAPI.availableForSectionConfig(sectionId)
											.fail(() => { Flash.error(); });
  },

  create(logicableId, logicableType, action, conditions) {
    return LogicConfigAPI.create(logicableId, logicableType, action, formatConditions(conditions))
									.done(onLogicConfigSaved)
									.fail(() => { Flash.error(); });
  },

  update(logicConfigId, action, conditions) {
    return LogicConfigAPI.update(logicConfigId, action, formatConditions(conditions))
                  .done(onLogicConfigSaved)
                  .fail(() => { Flash.error(); });
  },

  destroy(logicConfigId) {
    return LogicConfigAPI.destroy(logicConfigId)
												 .done(onLogicConfigDestroy);
  },
};

export default LogicActions;
