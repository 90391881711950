import ProcessAssignmentsAPI from 'apis/process-assignments-api';
import InitiatedFlowConstants from 'constants/initiated-flow-constants';

const ProcessAssignmentsActions = {
  dispatchAssignmentUpdated(dispatch, attachment) {
    dispatch({
      type: InitiatedFlowConstants.ASSIGNMENT_UPDATED,
      personAttachment: attachment,
    });
  },

  assign(processId, assigneeId, assigneeType) {
    return ProcessAssignmentsAPI.assign(processId, assigneeId, assigneeType);
  },

  unassign(processId, assignmentId) {
    return ProcessAssignmentsAPI.unassign(processId, assignmentId);
  },
};

export default ProcessAssignmentsActions;
