const SearchPagesApi = {


  publicSearch(params) {
    const url = '/search';

    return $.ajax(url, {
      method: 'get',
      dataType: 'json',
      data: params,
    });
  },

  customSearch(params, slug) {
    const url = `/search_pages/${slug}/search`;

    return $.ajax(url, {
      method: 'get',
      dataType: 'json',
      data: params,
    });
  },
};
export default SearchPagesApi;
