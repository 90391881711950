import NProgress from 'nprogress';
import FlowTemplateAPI from '../apis/flow-template-api';
import Flash from '../components/shared/flash';

const TemplateExtrasActions = {
  cloneTemplate(templateId, teamId) {
    NProgress.start();

    return FlowTemplateAPI.clone(templateId, teamId)
                          .fail((res) => {
                            if (res.responseJSON) {
                              Flash.error(res.responseJSON.errors[0]);
                            } else {
                              Flash.error();
                            }
                          })
                          .always(NProgress.done());
  },

  duplicateTemplate(templateId, teamId, cloneOutputDocuments) {
    const data = {
      team_id: teamId,
      clone_output_documents: cloneOutputDocuments
    };

    return FlowTemplateAPI.duplicate(templateId, data)
                          .fail((res) => {
                            if (res.responseJSON) {
                              Flash.error(res.responseJSON.errors[0]);
                            } else {
                              Flash.error();
                            }
                          })
                          .always(NProgress.done());
  },
};

export default TemplateExtrasActions;
