import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { reject, isNull, findIndex, isEmpty, filter } from 'underscore';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MultiSelect = (props) => {
  const [options, setOptions] = useState(props.options ?? []);
  const [activeSelection, setActiveSelection] = useState(null);
  const [filterTerm, setFilterTerm] = useState('');

  const availableOptions = useMemo(() => {
    return filter(options, (option) => {
      let matchName = true;
      if (!isEmpty(filterTerm)) {
        matchName = option.label.toLowerCase().includes(filterTerm.toLowerCase());
      }
      return matchName && !option.selected;
    });
  }, [options, filterTerm]);

  const selectedOptions = useMemo(() => {
    return reject(options, (option) => {
      return !option.selected;
    });
  }, [options]);

  useEffect(() => {
    if (props.onSelect) {
      props.onSelect(selectedOptions.map((s) => s.value));
    }
  }, [selectedOptions]);

  const toggleSelect = () => {
    const dupOption = { ...activeSelection };
    dupOption.selected = !dupOption.selected;
    const dupOptions = [...options];
    const index = findIndex(dupOptions, (o) => { return o.value === dupOption.value; });
    dupOptions[index] = dupOption;
    setActiveSelection(null);
    setOptions(dupOptions);
  };

  const selectAll = () => {
    const dupOptions = [...options];
    dupOptions.map((option) => {
      const dupOption = option;
      dupOption.selected = true;
      return dupOption;
    });
    setOptions(dupOptions);
  };

  const clearAll = () => {
    const dupOptions = [...options];
    dupOptions.map((option) => {
      const dupOption = option;
      dupOption.selected = false;
      return dupOption;
    });
    setOptions(dupOptions);
  };

  const onFilterTermChange = (e) => {
    setFilterTerm(e.currentTarget.value);
  };

  const renderOption = (option) => {
    return (
      <li
        key={`option-${option.value}`}
        className={`${option.value === activeSelection?.value ? 'selected' : ''}`}
        onClick={() => setActiveSelection(option)}
      >
        {option.label}
      </li>
    );
  };
  return (
    <div className='multiselect'>
      <div className='multiselect-searchbar'>
        <input onChange={(e) => { setFilterTerm(e.target.value); }} value={filterTerm} type='text' placeholder='Search...' />
      </div>
      <div className='multiselect-list'>
        <div>
          <ul>
            {availableOptions.map(renderOption)}
          </ul>
          <button type='button' onClick={selectAll} className='btn-link'>Select All</button>
        </div>
        <div className='multiselect-controls'>
          <FontAwesomeIcon
            icon={icon({ name: 'circle-arrow-right' })}
            disabled={isNull(activeSelection) || activeSelection.selected}
            style={{ color: '#0463B7', height: '1rem' }}
            size='2xl'
            onClick={toggleSelect}
          />
          <FontAwesomeIcon
            icon={icon({ name: 'circle-arrow-left' })}
            disabled={isNull(activeSelection) || !activeSelection.selected}
            style={{ color: '#0463B7', height: '1rem' }}
            size='2xl'
            onClick={toggleSelect}
          />
        </div>
        <div>
          <ul>
            {selectedOptions.map(renderOption)}
          </ul>
          <button type='button' onClick={clearAll} className='btn-link'>Clear All</button>
        </div>
      </div>
      </div>
  );
};

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
  })),
  onSelect: PropTypes.func.isRequired,
};

export default MultiSelect;
