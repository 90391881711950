import React, {
  useContext, useState, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import {
  createDoc,
  replaceDoc,
} from 'actions/document-manager-actions';
import {
  success as flashSuccess,
  error as flashError,
} from 'components/shared/flash';
import { useHistory } from 'react-router-dom';
import { DocumentDispatchContext } from 'contexts/document-dispatch-context';
import LoadingSpinner from 'components/shared/loading-spinner';

const DragAndDropBox = ({
  document, prompt, onChange, replacing,
}) => {
  const history = useHistory();

  const dispatch = useContext(DocumentDispatchContext);

  const [uploading, setUploading] = useState(false);

  const onDrop = (acceptedFiles) => {
    setUploading(true);

    if (document.id) {
      return replaceDoc(document.id, document.master_template_id, acceptedFiles)
        .done((doc) => {
          dispatch({ type: 'update', document: doc });
          onChange();
        })
        .fail(() => { flashError(); })
        .always(() => { setUploading(false); });
    }

    return createDoc(document.master_template_id, acceptedFiles)
      .done((doc) => {
        dispatch({ type: 'create', document: doc });
        history.push(`${doc.id}`);
      })
      .fail(() => { flashError(); })
      .always(() => { setUploading(false); });
  };

  const {
    getRootProps, getInputProps, isDragActive, open,
  } = useDropzone({ onDrop });

  useEffect(() => {
    // Not a great way of doing this. Apologies, future developer.

    if (replacing) {
      open();
    }
  }, [replacing]);

  const dndStatus = useMemo(() => {
    if (uploading) {
      return (
        <>
          <LoadingSpinner
            size='medium'
            className='vmiddle margin-right'
          />
          <span className='vmiddle'>Uploading</span>
        </>
      );
    }

    if (isDragActive) {
      return <span>Drop files here ...</span>;
    }

    return <span>{prompt}</span>;
  }, [uploading, isDragActive]);

  return (
    <div className='dnd-box margin-bottom' {...getRootProps()}>
      <input {...getInputProps()} />
      {dndStatus}
    </div>
  );
};

DragAndDropBox.propTypes = {
  document: PropTypes.shape({
    master_template_id: PropTypes.number.isRequired,
    id: PropTypes.number,
  }).isRequired,
  prompt: PropTypes.string,
};

DragAndDropBox.defaultProps = {
  prompt: 'Drag and drop some files here, or click to select files',
};

export default DragAndDropBox;
