import React from 'react';

const TasksHeaderBar = ({count, overdueCount, completed}) => {
  function percentage () {
    return Math.round(( completed / count ) * 100)
  }

  if (!count) {
    return <></>
  }

  return (
    <div className='tasks-header'>
      <div className='inline-block'>
        <span className='padding-right'>
          <strong>{count}</strong>{' Tasks'}
        </span>
        <span className='text-alert color-left neutral'>
          <strong>{overdueCount}</strong>{` Overdue`}
        </span>
      </div>
      <div className='float-right'>
        <span className='vtop'>{`Completion: ${completed}/${count}`}</span>
        <div className="inline-block progressbar-container">
          <div className="progressbar-fill" style={{width: `${percentage()}%`}}></div>
        </div>
        <span className='vtop'>{`${percentage()}%`}</span>
      </div>
    </div>
  )
}

export default TasksHeaderBar;