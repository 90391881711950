import React from 'react';
import Output from './output/output';

const OutputsContainer = (props) => {
  function renderOutput(output) {
    return <Output output={output} />;
  }

  return props.outputs.map(renderOutput);
};

export default OutputsContainer;
