import React, { useEffect, useRef, useState } from 'react';
import { Document, pdfjs, Page } from 'react-pdf';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const MappingPdfViewer = (props) => {
  const containerSize = 700;
  const containerRef = useRef();
  const [numPages, setNumPages] = useState(0);
  const [highlighted, setHighlighted] = useState([]);

  useEffect(() => {
    if (!props.activeSelect || numPages === 0) return;

    clearHighlighted();

    const inputs = containerRef.current.querySelectorAll(`.annotationLayer section [name="${props.activeSelect}"]`);
    if (isEmpty(inputs)) return;

    inputs.forEach((input, index) => {
      input.classList.add('highlighted');
      highlighted.push(input);

      const inputTop = input.getBoundingClientRect().top;
      if (index === inputs.length - 1 && (inputTop < 0 || inputTop > containerSize)) {
        const page = input.closest('.react-pdf__Page');
        containerRef.current.scrollTo({
          top: (page.dataset.pageNumber - 1) * page.clientHeight,
          behavior: 'smooth',
        });
      }
    });
  }, [props.activeSelect, containerRef, numPages, highlighted]);

  useEffect(() => {
    if (!props.activeSelect) {
      clearHighlighted();
    }
  }, [props.activeSelect]);

  const clearHighlighted = () => {
    if (isEmpty(highlighted)) return;

    highlighted.forEach((el) => {
      el.classList.remove('highlighted');
    });
    setHighlighted([]);
  };

  const onDocumentLoadSuccess = ({ numPages: nPages }) => {
    setNumPages(nPages);
  };

  return (
    <div id='mappingpdfviewer-pdfviewercontainer' ref={containerRef}>
      <Document
        file={props.file}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(
          new Array(numPages),
          (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderForms
              renderTextLayer={false}
            />
          ),
        )}
      </Document>
    </div>
  );
};
MappingPdfViewer.propTypes = {
  file: PropTypes.string.isRequired,
  activeSelect: PropTypes.string,
};

export default MappingPdfViewer;
