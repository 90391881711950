import React from 'react';
import renderer from 'modules/renderer';

import UserContainer from 'components/user-container';
import CommonProviders from 'components/common-providers';
import UnverifiedEmailApp from 'components/unverified-email-app';

function account() {
  renderer(
    (
      <CommonProviders>
        <UserContainer
          user={CityGrows.Server.user}
          settingsData={CityGrows.Server.settingsData}
          initialPath={CityGrows.Server.initialPath}
        />
      </CommonProviders>
    ), document.getElementById('user_account'),
  );
}

function unverifiedEmail() {
  renderer(
    (
      <CommonProviders>
        <UnverifiedEmailApp />
      </CommonProviders>
    ), document.getElementById('unverified-email-page'),
  );
}

export default {
  account,
  unverifiedEmail,
};
