import React from 'react';
import renderer from 'modules/renderer';

import CommonProviders from '../../components/common-providers';
import TemplateStats from '../../components/template-stats';
import DataVisualizer from '../../components/shared/data-visualizer';
import PublicProcessOverview from '../../components/public-process-overview';
import PublicSearch from '../../components/public-search';
import PublicOverviewHeader from '../../components/public-overview-header';

const show = () => {
  renderer(
    <CommonProviders>
      <PublicProcessOverview
        user={CityGrows.Server.currentUser}
        flowTemplateData={CityGrows.Server.flowTemplateData}
        myTeams={CityGrows.Server.teams}
      />
    </CommonProviders>,
    document.getElementById('publicoverview-container'),
  );
};

const data = () => {
  renderer(
    <CommonProviders>
      <PublicOverviewHeader
        user={CityGrows.Server.currentUser}
        flowTemplateData={CityGrows.Server.flowTemplateData}
        myTeams={CityGrows.Server.teams}
      />
    </CommonProviders>, document.getElementById('publicoverview-container'),
  );
  renderer(
    <CommonProviders>
      <TemplateStats data={CityGrows.Server.launchdata} />
    </CommonProviders>, document.getElementById('initiatedbar'),
  );
  renderer(
    <CommonProviders>
      <DataVisualizer visualizableFields={CityGrows.Server.visualizableFields} public />
    </CommonProviders>, document.getElementById('datavisualizer'),
  );
};

const faqs = () => {
  renderer(
    <CommonProviders>
      <PublicOverviewHeader
        user={CityGrows.Server.currentUser}
        flowTemplateData={CityGrows.Server.flowTemplateData}
        myTeams={CityGrows.Server.teams}
      />
    </CommonProviders>, document.getElementById('publicoverview-container'),
  );
};

const search = () => {
  renderer(
    <CommonProviders>
      <PublicOverviewHeader
        user={CityGrows.Server.currentUser}
        flowTemplateData={CityGrows.Server.flowTemplateData}
        myTeams={CityGrows.Server.teams}
      />
    </CommonProviders>, document.getElementById('publicoverview-container'),
  );
  renderer(
    <CommonProviders>
      <PublicSearch
        instructions={CityGrows.Server.flowTemplateData.master_template.public_search_instructions}
        flowTemplateId={CityGrows.Server.flowTemplateData.id}
      />
    </CommonProviders>, document.getElementById('data_search'),
  );
};

export default {
  data,
  show,
  faqs,
  search,
};
