import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import ActiveStepActions from 'actions/active-step-actions';
import PaymentStepActions from 'actions/payment-step-actions';
import CheckForm from './check-form';
import CashForm from './cash-form';
import StripeForm from './stripe-form';
import CreditCardForm from './credit-card-form';

const UnPaid = (props) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);
  const [chargesEnabled, setChargesEnabled] = useState(true);

  useEffect(() => {
    // chargesEnabled is only used to show admins a disabled alert
    if (!CityGrows.Server.admin) { return; }

    PaymentStepActions.checkChargesEnabled(props.activeStep.initiated_flow_id)
      .done((res) => { setChargesEnabled(res.chargesEnabled); });
  }, [props.activeStep.initiated_flow_id]);

  function paymentMethodChanged(e) {
    if (props.step.generic_type === 'Step') {
      ActiveStepActions.updateCurrentActiveStep(dispatch, props.activeStep.id, {
        payment_method: e.currentTarget.value,
      });
    } else {
      PaymentStepActions.update(dispatch, props.activeStep.id, {
        payment_method: e.currentTarget.value,
      });
    }

    if (e.currentTarget.value !== 'creditcard') {
      props.onStripePaymentTypeChange(null);
    }
  }

  function paymentToggle(type, label) {
    return (
      <label>
        <input
          type='radio'
          name='paymentmethod'
          onChange={paymentMethodChanged}
          defaultChecked={props.activeStep.payment_method === type}
          value={type}
        />{label}
      </label>
    );
  }

  function renderPaymentMethodSelector() {
    if (!props.step.config.accepts_checks && !props.step.config.accepts_cash) {
      return;
    }

    return (
      <div className='margin-bottom'>
        <fieldset>
          <legend className='subtitle'>Payment method</legend>
          {paymentToggle('creditcard', 'Pay online')}
          {props.step.config.accepts_cash && paymentToggle('cash', 'Cash')}
          {props.step.config.accepts_checks && paymentToggle('check', 'Check')}
        </fieldset>
      </div>
    );
  }

  function renderCreditCardForm() {
    if (props.thirdPartyProcessor) {
      return (
        <CreditCardForm
          config={props.step.config}
          type={props.step.type}
          fee={props.fee}
          amount={props.amount}
          noFee={props.step.no_fee}
          activeStep={props.activeStep}
          customFeePercentage={props.step.custom_fee_percentage}
          disabled={disabled()}
        />
      );
    }

    return (
      <StripeForm
        type={props.step.type}
        activeStep={props.activeStep}
        amount={props.amount}
        fee={props.fee}
        noFee={props.step.no_fee}
        disabled={disabled()}
        chargesEnabled={chargesEnabled}
        customFeePercentage={props.step.custom_fee_percentage}
        teamFriendlyId={props.teamFriendlyId}
        config={props.step.config}
        onPaymentTypeChange={(type) => {
          props.onStripePaymentTypeChange(type);
        }}
        onPaymentAmountChange={(amount) => {
          props.onStripePaymentAmountChange(amount);
        }}
      />
    );
  }

  function disabled() {
    if (props.step.type === 'PaymentRequirement' && props.step.canceled) { return true; }

    return props.step.config.calculated && !props.activeStep.calculated_payment;
  }

  function renderPaymentForm() {
    switch (props.activeStep.payment_method) {
    case 'creditcard': {
      return renderCreditCardForm();
    }
    case 'check': {
      return (
        <CheckForm
          admin={props.admin}
          type={props.step.type}
          activeStep={props.activeStep}
          checkInstructions={props.step.config.check_instructions}
        />
      );
    }
    case 'cash': {
      return (
        <CashForm
          admin={props.admin}
          type={props.step.type}
          activeStep={props.activeStep}
          cashInstructions={props.step.config.cash_instructions}
        />
      );
    }
    }
  }

  return (
    <>
      {renderPaymentMethodSelector()}
      {renderPaymentForm()}
    </>
  );
};

UnPaid.propTypes = {
  activeStep: PropTypes.object.isRequired,
  amount: PropTypes.number.isRequired,
  fee: PropTypes.number.isRequired,
  step: PropTypes.object.isRequired,
  admin: PropTypes.bool.isRequired,
  onStripePaymentTypeChange: PropTypes.func.isRequired,
  onStripePaymentAmountChange: PropTypes.func.isRequired,
};

export default UnPaid;
