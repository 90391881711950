import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Actions from 'actions/survey-submission-actions';
import SurveySubmissionAPI from 'apis/survey-submission-api';
import Survey from 'components/shared/survey';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';

const SurveySubmissionAdminView = (props) => {
  const [collectedData, setCollectedData] = useState(null);

  useEffect(() => {
    loadCollectedData();
  }, []);

  const dispatch = useContext(InitiatedFlowDispatchContext);

  function loadCollectedData() {
    SurveySubmissionAPI.fetchData(props.submission.id)
                       .done(onSurveyDataLoaded);
  }

  function onSurveyDataLoaded(data) {
    setCollectedData(data);
  }

  function onDeleteClick(e) {
    e.preventDefault();

    Actions.destroy(props.submission.id)
           .done(() => {
             onSurveySubmissionDestroyed();
           });
  }

  function onSurveySubmissionDestroyed() {
    props.history.replace('/');
    Actions.dispatchSubmissionDestroyed(dispatch, props.submission.id);
  }

  function renderContent() {
    if (collectedData) {
      return (
        <Survey
          survey={props.survey}
          existingSubmission={Object.assign(props.submission, { collected_data: collectedData })}
        />
      );
    }

    return renderLoadingContent();
  }

  function renderLoadingContent() {
    return (
      <div className='text-center padded'>
        <div className='progress'>
          <div>Loading</div>
        </div>
      </div>
    );
  }

  function renderTopLinks() {
    return (
      <div className='survey-toplinks margin-top-more'>
        <Link className='secondary' to='/'>{'< Back to all responses'}</Link>
        <a href='#' className='float-right color-alert margin-left' onClick={onDeleteClick}>Delete</a>
      </div>
    );
  }

  return (
    <div className='scroll fullheight'>
      {renderTopLinks()}
      {renderContent()}
    </div>
  );
};

SurveySubmissionAdminView.propTypes = {
  submission: PropTypes.object.isRequired,
  survey: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default SurveySubmissionAdminView;
