import React from 'react';
import UserDisc from 'components/shared/user-disc';

class ListOfPeoplePerson extends React.Component {
  onClick = () => {
    this.props.onSelect(this.props.person);
  };

  render() {
    return (
      <li onClick={this.onClick}>
        <UserDisc
          user={this.props.person}
          className='margin-right'
          options={{
            showName: true,
          }}
        />
      </li>
    );
  }
}

export default ListOfPeoplePerson;
