import NProgress from 'nprogress';
import Dispatcher from '../appdispatcher';
import Flash from '../components/shared/flash';
import Constants from '../constants/template-manager-constants';
import SurveyAPI from '../apis/survey-api';
import StepDataAPI from '../apis/step-data-api';

const SurveyActions = {
  update(surveyId, attrs) {
    return SurveyAPI.update(surveyId, attrs)
            .done(this.dispatchSurveyUpdated)
            .fail(this.onSurveyUpdateFail);
  },

  addSection(surveyId, stepId) {
    NProgress.start();

    StepDataAPI.addSection('Survey', surveyId)

      .done((section) => {
        Dispatcher.dispatch({
          actionType: Constants.SURVEY_SECTION_ADDED,
          section,
          stepId,
        });

        NProgress.done();
      });
  },

  copySection(sectionId, stepId) {
    StepDataAPI.copySection(sectionId)
      .done((section) => {
        Dispatcher.dispatch({
          actionType: Constants.SURVEY_SECTION_COPIED,
          section,
          stepId,
        });
      });
  },

  dispatchSurveyUpdated(survey) {
    Dispatcher.dispatch({
      actionType: Constants.SURVEY_UPDATED,
      survey,
    });
  },

  onSurveyUpdateFail(res) {
    Flash.error(res.responseJSON.errors.base[0]);
  },

  updateIdentified(surveyId, newValue) {
    const request = SurveyAPI.update(surveyId, {
      identified: newValue,
    });

    request.done(this.dispatchSurveyUpdated)
           .fail(() => { Flash.error(); });
  },
};

export const exportResponses = (activeStepId) => {
  const URL = `/active_steps/${activeStepId}/survey_submission/exports`;

  return $.ajax(URL, {
    method: 'POST',
    dataType: 'json'
  });
};

export default SurveyActions;
