import React from 'react';
import renderer from 'modules/renderer';
import FinanceSubnav from '../components/shared/finance-subnav';
import PrimaryTabs from '../components/shared/primary-tabs';

const index = () => {
  renderer(<PrimaryTabs />, document.getElementById('tabs'));
  renderer(
    (
      <FinanceSubnav teamSlug={CityGrows.Server.teamId} activeLink='disputes'/>
    ), document.getElementById('finance-left-nav')
  );

};

const show = () => {
  renderer(
    (
      <FinanceSubnav teamSlug={CityGrows.Server.teamId} activeLink='disputes' />
    ), document.getElementById('finance-left-nav')
  );

};

export default {
  index,
  show,
};