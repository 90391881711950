import {
  update as apiUpdate,
  revoke as apiRevoke,
  unrevoke as apiUnrevoke,
  renewalOptIn as apiRenewalOptIn,
  renewalOptOut as apiRenewalOptOut,
} from 'apis/issued-output-api';
import InitiatedFlowConstants from 'constants/initiated-flow-constants';
import { error, success } from 'components/shared/flash';
import { any } from 'underscore';

const onUpdateComplete = (dispatch, outputAttrs) => {
  if (any(outputAttrs.errors)) {
    error(outputAttrs.errors[0]);
    return;
  }

  dispatch({
    type: InitiatedFlowConstants.OUTPUT_UPDATED,
    attrs: outputAttrs,
  });

  success('Update successful');
};

export const update = (dispatch, id, attrs) => {
  const req = apiUpdate(id, {
    issued_at: attrs.issuedAt,
    expires_at: attrs.expiresAt,
  })
  .done((res) => { onUpdateComplete(dispatch, res); })
  .fail(() => { error(); });

  return req;
};

export const revoke = (dispatch, id) => {
  const onRevoked = (attrs) => {
    dispatch({
      type: InitiatedFlowConstants.OUTPUT_UPDATED,
      attrs,
    });

    success('Output revoked');
  };

  return apiRevoke(id)
    .done(onRevoked)
    .fail(() => { error(); });
};

export const unrevoke = (dispatch, id) => {
  const onUnrevoked = (attrs) => {
    dispatch({
      type: InitiatedFlowConstants.OUTPUT_UPDATED,
      attrs,
    });

    success('Output re-issued');
  };

  return apiUnrevoke(id)
    .done(onUnrevoked)
    .fail(() => { error(); });
};

export const renewalOptIn = (dispatch, id) => {
  const onRenewalOptIn = (attrs) => {
    dispatch({
      type: InitiatedFlowConstants.OUTPUT_UPDATED,
      attrs,
    });

    success('Renewal reminders enabled');
  };

  return apiRenewalOptIn(id)
    .done(onRenewalOptIn)
    .fail(() => { error(); });
};

export const renewalOptOut = (dispatch, id) => {
  const onRenewalOptOut = (attrs) => {
    dispatch({
      type: InitiatedFlowConstants.OUTPUT_UPDATED,
      attrs,
    });

    success('Renewal reminders disabled');
  };

  return apiRenewalOptOut(id)
    .done(onRenewalOptOut)
    .fail(() => { error(); });
};

export default {
  update,
  revoke,
  unrevoke,
  renewalOptIn,
  renewalOptOut,
};
