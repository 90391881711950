import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AdvancedLabel from 'components/shared/advanced-label';
import NoNegativesInput from 'components/shared/no-negatives-input';

const CheckboxSettings = ({ numberOfOptions, minNumberOfChecks, onDataChanged }) => {
  const [minNumChecks, setMinNumChecks] = useState(minNumberOfChecks);

  const onMinChecksChange = (value) => {
    setMinNumChecks(value);
    if (value <= numberOfOptions) {
      onDataChanged({ min_num_checks: value });
    }
  };

  const validateMinNumChecks = () => {
    return minNumChecks <= numberOfOptions;
  };

  return (
    <tr>
      <td>
        <AdvancedLabel
          label='Minimum Number Of Selections'
          required
        />
      </td>
      <td>
        <NoNegativesInput
          type='number'
          onChange={onMinChecksChange}
          klass={`${!validateMinNumChecks() ? 'invalid' : ''}`}
          value={minNumChecks}
          name='minchecks'
          allowZero
        />
        {!validateMinNumChecks() && (
          <div className='fieldmanager-invalidfield'>
            Cannot be greater than the number of available selections
          </div>
        )}
      </td>
    </tr>
  );
};

CheckboxSettings.propTypes = {
  numberOfOptions: PropTypes.number.isRequired,
  minNumberOfChecks: PropTypes.number.isRequired,
  onDataChanged: PropTypes.func.isRequired,
};

export default CheckboxSettings;
