import React from 'react';
import renderer from 'modules/renderer';

import 'select2';

import CommonProviders from 'components/common-providers';
import { pollForLogin } from 'modules/poll-for-login';
import FormsList from 'components/forms-container/forms-list';
import TemplateManager from '../components/template-manager';
import TemplateManagerActions from '../actions/template-manager-actions';
import PrimaryTabs from '../components/shared/primary-tabs';

const index = () => {
  renderer(<PrimaryTabs />, document.getElementById('tabs'));
  renderer(
    (
      <CommonProviders>
        <FormsList
          teamFriendlyId={CityGrows.Server.team.friendly_id}
          starredFormIds={CityGrows.Server.starredFormIds}
          masterTemplates={CityGrows.Server.masterTemplates}
          enableFormCreation={CityGrows.Server.enableFormCreation}
        />
      </CommonProviders>
    ), document.getElementById('formscontainer'),
  );

  $('select').select2({
    minimumResultsForSearch: -1,
    width: 'style',
  });

  $('#templateselect').change((e) => {
    document.location = `?status=${e.currentTarget.value}`;
  });
};

const show = () => {
  Offline.options = { requests: false };
  initStore();
  initTemplateManager();
  pollForLogin();
};

function initStore() {
  TemplateManagerActions.setFlowTemplate(CityGrows.Server.initialFlowTemplateData);
  TemplateManagerActions.dispatchMasterTemplateLoaded(CityGrows.Server.initialMasterTemplateData);
}

function initTemplateManager() {
  renderer(
      <CommonProviders>
        <TemplateManager
          userAccessData={CityGrows.Server.userAccessData}
          allowedStepDatumTypes={CityGrows.Server.allowedStepDatumTypes}
          myTeams={CityGrows.Server.myTeams}
        />
      </CommonProviders>, document.getElementById('templatemanager'),
  );
}

export default {
  show,
  index,
};
