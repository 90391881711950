import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import UserAPI from 'apis/user-api';
import { Switch } from 'react-router';
import {
  HashRouter as Router,
  NavLink,
  Route,
  useHistory,
  useLocation,
} from 'react-router-dom';

import LeftMenu from 'components/shared/left-menu';
import Flash from 'components/shared/flash';
import UserEmailSettings from './user/email-settings';
import UserProfile from './user/profile';
import UserPasswordManager from './user/password-manager';
import MfaManager from './user/mfa-manager';

const activeClass = 'active';

const UserContainerRouter = (props) => {
  return (
    <Router>
      <UserContainer {...props} />
    </Router>
  );
};

const UserContainer = ({ initialPath, user, settingsData }) => {
  const [localUser, setUser] = useState(user);
  const [imageLoading, setImageLoading] = useState(false);
  const location = useLocation();
  const initialLocation = useRef(location);
  const history = useHistory();

  useEffect(() => {
    if (initialLocation.current.pathname === '/') {
      // redirect if not on any child route
      history.push(initialPath);
    }
  }, [history, initialPath]);

  const changePassword = (data) => (
    UserAPI.update(data)
      .done(() => Flash.success('Password updated'))
      .fail((r) => Flash.error(r.responseText))
  );

  const changeAvatar = (file) => {
    setImageLoading(true);
    UserAPI.uploadAvatar(file)
      .done((r) => {
        Flash.success('Avatar updated');
        setImageLoading(false);
        setUser({ ...user, avatar_src: r.avatar_src, has_avatar: r.has_avatar });
      });
  };

  const sendUpdate = (update) => {
    const updatedUser = { ...localUser, ...update };
    return UserAPI.update(updatedUser)
      .done(() => {
        Flash.success('Profile updated');
        setUser(updatedUser);
      })
      .fail((res) => Flash.error(res.responseText));
  };

  const profileRenderer = () => (
    <UserProfile
      user={localUser}
      updateUser={sendUpdate}
      changeAvatar={changeAvatar}
      imageLoading={imageLoading}
    />
  );

  const passwordManagerRenderer = () => (
    <UserPasswordManager changePassword={changePassword} />
  );

  const mfaManagerRenderer = () => (
    <MfaManager />
  );

  const emailSettingsRenderer = () => (
    <UserEmailSettings
      currentUserId={user.id}
      emailSettings={settingsData.userSettings}
      workflows={settingsData.workflows}
      teams={settingsData.teams}
      initialType={settingsData.initialType}
      emails={settingsData.emails}
    />
  );

  const renderEmailSettingsLink = () => {
    if (window.CityGrows.Server.admin === false) { return null; }

    return (
      <li>
        <NavLink
          activeClassName={activeClass}
          to='/email_settings'
        >
          Email Settings
        </NavLink>
      </li>
    );
  };

  return (
    <div className='config'>
      <LeftMenu>
        <li>
          <NavLink
            activeClassName={activeClass}
            to='/profile'
          >
            Profile
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName={activeClass}
            to='/password'
          >
            Change Password
          </NavLink>

          {/* { */}
          {/*  CityGrows.Server.currentUser?.emailVerified */}
          {/*  && <NavLink activeClassName={activeClass} to='/password'> Change Password </NavLink> */}
          {/* } */}
        </li>
        {/* <li> */}
        {/*  {CityGrows.Server.currentUser?.isGov && <NavLink activeClassName={activeClass} to='/mfa'> Security </NavLink>} */}
        {/* </li> */}
        {renderEmailSettingsLink()}
      </LeftMenu>
      <div className='config-main'>
        <div className='raised bg-white padded-more margin-bottom-more'>
          <Switch>
            <Route path='/profile' render={profileRenderer} />
            <Route path='/password' render={passwordManagerRenderer} />
            {/* {CityGrows.Server.currentUser?.emailVerified && <Route path='/password' render={passwordManagerRenderer} />} */}
            {/* {CityGrows.Server.currentUser?.isGov && <Route path='/mfa' render={mfaManagerRenderer} />} */}
            {window.CityGrows.Server.admin === true && <Route path='/email_settings' render={emailSettingsRenderer} />}
          </Switch>
        </div>
      </div>
    </div>
  );
};

UserContainer.propTypes = {
  initialPath: PropTypes.string,
  user: PropTypes.object,
  settingsData: PropTypes.object,
};

export default UserContainerRouter;
