import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import Actions from 'actions/multiple-choice-option-actions';
import useDerivedState from 'components/hooks/use-derived-state';
import { reorder } from 'modules/dnd-utils';
import FileInput from 'components/shared/file-input';
import MultipleChoiceOption from './multiple-choice-option';

// This component is used for both Multiple Choice and Checkboxes
const MultipleChoices = ({
  options, disabled, onOptionChange, stepDatum, onDeleteFail,
}) => {
  const [newElements, setNewElements] = useState(false);
  const [formulaValuesEnabled, setFormulaValuesEnabled] = useState(
    options.some((option) => option.numeric_value !== null),
  );
  const sortedOptionsFromProps = useMemo(
    () => options.sort((a, b) => (a.position - b.position)),
    [options],
  );
  const [orderedOptions, setOrderedOptions] = useDerivedState(sortedOptionsFromProps);

  // need to find the first option that has a non numeric display value that is not null
  const findTestOption = () => options.find((option) => (option.name && Number.isNaN(option.name)));

  // This is a weird way of doing this.
  const removeNumericValues = () => {
    options.forEach((option) => {
      onOptionChange(option.id, { numeric_value: null });
    });
  };

  const onDragEnd = ({ source, destination }) => {
    if (!destination) {
      return;
    }
    if (destination.index === source.index) {
      return;
    }
    setOrderedOptions((oldOptions) => reorder(
      oldOptions,
      source.index,
      destination.index,
    ));
    const draggedOptionId = orderedOptions[source.index].id;
    const newPosition = destination.index + 1;
    Actions.reorderOption(draggedOptionId, newPosition)
      .fail(() => setOrderedOptions(orderedOptions));
  };

  const clearMultipleChoiceOptions = (e) => {
    e.preventDefault();
    Actions.destroyAll(stepDatum.id);
  };

  const uploadMultipleChoices = (e) => (
    Actions.uploadOptions(stepDatum.id, e.currentTarget.files[0])
  );

  const removeFormulaValues = (e) => {
    e.preventDefault();
    setFormulaValuesEnabled(false);
    // first test that disabling formula values won't break a formula
    const testOption = findTestOption();
    if (testOption) {
      onOptionChange(testOption.id, { numeric_value: null })
        // on success remove all the numeric values
        .done(removeNumericValues)
        // on failure, reenable the formula values component
        .fail(() => setFormulaValuesEnabled(true));
    } else {
      // if there is no testOption, then all the display values are numeric or null
      // and the numeric values can be safely removed
      removeNumericValues();
    }
  };

  // Basically undoing the sort, and without something
  // telling you the position on the object.
  const indexOfOption = (option) => {
    return options.indexOf(option) + 1;
  };

  const canDelete = useMemo(() => {
    if (disabled) { return false; }

    switch (stepDatum.data_type) {
    case 'multiple_choice':
      return options.length > 2;
    case 'checkboxes':
      return options.length > 1;
    default:
      throw new Error(`Unhandled data_type ${stepDatum.data_type}`);
    }
  }, [stepDatum.data_type, options.length]);

  // There's probably a better way to do this.
  // It's really just saying "focus the last thing"
  const optionNeedsFocus = (option) => {
    return (option.id === options.slice(-1)[0].id) && newElements;
  };

  const fakeActionClicked = () => {
    setNewElements(true);
    Actions.addOption(stepDatum.id);
  };

  const inputsClass = formulaValuesEnabled ? 'fullwidth' : 'fieldmanager-multiplechoice-left';
  return (
    <div className='fieldmanager-multiplechoices'>
      {formulaValuesEnabled && (
        <div className='fieldmanager-multiplechoicelabels'>
          <span className='fieldmanager-multiplechoice-left'>
            <label>Display value</label>
          </span>

          <span className='fieldmanager-multiplechoice-right'>
            <label>Numeric value</label>
          </span>
        </div>
      )}
      <div className='fieldmanager-multiplechoices-maincontent'>

        <DragDropContext
          onDragEnd={onDragEnd}
        >
          <Droppable droppableId={`multipleChoiceDroppable-${stepDatum.id}`}>
            {({ innerRef, droppableProps, placeholder }) => (
              <div className={inputsClass} ref={innerRef} {...droppableProps}>
                <span>
                  {orderedOptions.map((optionData, index) => (
                    <MultipleChoiceOption
                      key={optionData.id}
                      index={index}
                      option={optionData}
                      optionNumber={indexOfOption(optionData)}
                      canDelete={canDelete}
                      shouldFocus={optionNeedsFocus(optionData)}
                      onOptionChange={onOptionChange}
                      onDeleteFail={onDeleteFail}
                      disabled={disabled}
                      formulaValuesEnabled={formulaValuesEnabled}
                      dataType={stepDatum.data_type}
                    />
                  ))}
                  {placeholder}
                </span>
                {!disabled && (
                  <div onFocus={fakeActionClicked} className='fieldmanager-inputwrap fake'>
                    {/* TODO: there should be a way to do this "click for new option"
                    concept without focus manipulation, and in a way that preserves
                    the input so that the user's focus isn't jumping. */}
                    <input
                      name='newoption'
                      key={`Option ${options.length + 1}`}
                      readOnly
                      type='text'
                      value={`Option ${options.length + 1}`}
                    />
                  </div>
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {!formulaValuesEnabled && (
          <div className='fieldmanager-multiplechoice-right align-center'>
            <a
              className='secondary fieldmanager-formulavaluelink'
              href='#'
              onClick={(e) => {
                e.preventDefault();
                setFormulaValuesEnabled(true);
              }}
            >Set Formula Values
            </a>
          </div>
        )}
      </div>
      <div className='align-left' style={{ display: 'inline-flex' }}>
        <a
          href='#'
          className={`margin-right-less secondary${disabled ? ' disabled' : ''}`}
          onClick={clearMultipleChoiceOptions}
        >
          Clear all
        </a>

        <FileInput onChange={uploadMultipleChoices} disabled={disabled} fileType='text/csv' />
      </div>
      {formulaValuesEnabled && (
        <div className='align-right'>
          <a className='secondary' href='#' onClick={removeFormulaValues}>Remove Formula Values</a>
        </div>
      )}
    </div>
  );
};

MultipleChoices.propTypes = {
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  stepDatum: PropTypes.object.isRequired,
  onDeleteFail: PropTypes.func.isRequired,
  onOptionChange: PropTypes.func.isRequired,
};

export default MultipleChoices;
