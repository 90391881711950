import React from 'react';
import PropTypes from 'prop-types';

const ExpandedSection = (props) => {
  return (
    <div className='expandedsection'>
      <div className='expandedsection-inner'>{props.children}</div>
    </div>
  );
};

export default ExpandedSection;
