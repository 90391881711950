import React, { useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import UserPreferenceActions from 'actions/user-preference-actions';
import { isEmpty } from 'underscore';
import NewForm from '../new-form';

const FormsList = (props) => {
  const [starredFormIds, setStarredFormIds] = useState(props.starredFormIds);

  const starredForms = useMemo(() => {
    return props.masterTemplates.filter((template) => {
      return starredFormIds.includes(template.id);
    });
  }, [starredFormIds, props.masterTemplates]);

  const forms = useMemo(() => {
    const tmp = props.masterTemplates.filter((template) => {
      return !starredFormIds.includes(template.id);
    });
    if (props.formsPagination) {
      return tmp.slice(0, props.formsPagination);
    }

    return tmp;
  }, [starredFormIds, props.masterTemplates, props.formsPagination]);

  const showViewAllLink = useMemo(() => {
    return props.formsPagination
      && props.teamFriendlyId
      && (props.masterTemplates.length - starredForms.length) > props.formsPagination;
  }, [starredForms, props.masterTemplates, props.formsPagination, props.teamFriendlyId]);

  const starForm = (id) => {
    UserPreferenceActions.starForm(id)
      .done((res) => {
        setStarredFormIds(res.starred_master_template_ids);
      });
  };

  const unstarForm = (id) => {
    UserPreferenceActions.unstarForm(id)
      .done((res) => {
        setStarredFormIds(res.starred_master_template_ids);
      });
  };

  const renderCreateNewForm = (e, teamId) => {
    e.preventDefault();
    e.stopPropagation();
    const newFormContainer = document.getElementById('newformcontainer');
    ReactDOM.render(<NewForm teamId={teamId} unmountOnExit />, newFormContainer);
  };

  const renderFormItem = (form, isStarred) => {
    let starIcon;

    if (isStarred) {
      starIcon = (
        <FontAwesomeIcon
          icon={icon({ name: 'star', style: 'solid' })}
          onClick={() => unstarForm(form.id)}
          className='staricon starred'
          size='lg'
          title='Click to unstar this form'
        />
      );
    } else {
      starIcon = (
        <FontAwesomeIcon
          icon={icon({ name: 'star', style: 'regular' })}
          onClick={() => starForm(form.id)}
          className='staricon'
          size='lg'
          title='Click to star this form'
        />
      );
    }
    return (
      <li className='flowtemplatebox' key={`li-${form.id}`}>
        <a href={`/workflows/${form.id}`}>
          <div className='spanlink' />
        </a>
        <div className='flowtemplatebox-contentwrap'>
          <div className='flowtemplatebox-title'>{form.name}</div>
          <div className={`flowtemplatebox-status ${form.status === 'Published' ? 'published' : ''}`}>{form.status}</div>
          {starIcon}
        </div>
      </li>
    );
  };

  const renderList = () => {
    const canCreateForms = props.teamFriendlyId && props.enableFormCreation;
    if (!isEmpty(forms) || canCreateForms) {
      return (
        <>
          {!isEmpty(starredForms) && <div className='formlisttitle margin-top'>Your forms</div>}
          <ul className='flowtemplatebox-wrap'>
            {forms.map((form) => {
              return renderFormItem(form, false);
            })}

            {canCreateForms && (
              <li className='newform flowtemplatebox create' key='li-new'>
                <div className='spanlink' onClick={(e) => renderCreateNewForm(e, props.teamFriendlyId)} />
                <div className='flowtemplatebox-contentwrap'>
                  <FontAwesomeIcon icon={icon({ name: 'circle-plus' })} size='2x' />
                  <a href=''>Create new form</a>
                </div>
              </li>
            )}
          </ul>
        </>
      );
    }

    if (isEmpty(starredForms)) {
      return (
        <div className='margin-top margin-left'>No forms to show</div>
      );
    }
  };

  const renderTitle = () => {
    if (props.teamName) {
      if (props.teamFriendlyId) {
        return <a href={`/teams/${props.teamFriendlyId}/master_templates`} className='subtitle'>{props.teamName}</a>;
      }
      return <div><div className='subtitle'>{props.teamName}</div></div>;
    }
  };

  return (
    <div className='padding-top margin-bottom-more'>
      {renderTitle()}
      {!isEmpty(starredForms) && (
        <>
          <div className='formlisttitle'>Starred forms</div>
          <ul className='flowtemplatebox-wrap'>
            {starredForms.map((form) => {
              return renderFormItem(form, true);
            })}
          </ul>
        </>
      )}
      {renderList()}
      {showViewAllLink && (
        <div className='margin-left'>
          <a href={`/teams/${props.teamFriendlyId}/master_templates`}>{`View all ${props.masterTemplates.length} forms for this team`}</a>
        </div>
      )}
    </div>
  );
};

FormsList.propTypes = {
  teamFriendlyId: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  masterTemplates: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  })).isRequired,
  starredFormIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  enableFormCreation: PropTypes.bool.isRequired,
  formsPagination: PropTypes.number,
};

export default FormsList;
