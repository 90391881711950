import React, { useState, useContext } from 'react';
import { revoke, unrevoke } from 'actions/issued-output-actions';
import { confirm } from 'modules/alert-confirm';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';

const RevokeButton = ({ output }) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);
  const [submitting, setSubmitting] = useState(false);
  const buttonText = output.revoked ? 'Unrevoke Output' : 'Revoke Output'
  const onRevokeClick = () => {
    setSubmitting(true);
    if (output.revoked) {
      return unrevokeAction();
    }

    return revokeAction();
  };

  const revokeAction = () => {
    return confirm('Are you sure you want to revoke this output?', `This will make <strong>${output.name}</strong> unavailable for download. The applicant will be notified.`)
            .done(() => { revoke(dispatch, output.id); })
            .always(() => { setSubmitting(false); });
  };

  const unrevokeAction = () => {
    return confirm('Are you sure you want to re-issue this output?', `This will re-enable downloads for <strong>${output.name}</strong>`)
             .done(() => { unrevoke(dispatch, output.id); })
             .always(() => { setSubmitting(false); });
  };

  return (
    <button type='button' name='revoke' onClick={onRevokeClick} disabled={submitting}>
      <span>
        {buttonText}
      </span>
    </button>
  );
};

export default RevokeButton;
