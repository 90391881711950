import { sortBy } from 'underscore';

const OPERATOR_IS = { id: 'is', label: 'Is' };
const OPERATOR_IS_NOT = { id: 'is_not', label: 'Is not' };
const OPERATOR_RANGE = { id: 'range', label: 'Between' };
const OPERATOR_LESS = { id: 'less', label: 'Less than' };
const OPERATOR_LONGER = { id: 'longer', label: 'Longer than' };

export const WORKFLOW_STATUSES = [
  { id: 'all', label: 'All' },
  { id: 'published', label: 'Published' },
  { id: 'unpublished', label: 'Unpublished' },
  { id: 'closed', label: 'Closed' },
  { id: 'archived', label: 'Archived' },
];

export const COMPLETION_STATUSES = [
  { id: 'active', label: 'Active' },
  { id: 'completed', label: 'Completed' },
  { id: 'halted', label: 'Halted' },
  { id: 'all', label: 'All' },
];

export const DATE_TERMS = [
  { id: 'minutes', label: 'Minutes' },
  { id: 'hours', label: 'Hours' },
  { id: 'days', label: 'Days' },
];

export const IMPORTED_OPTIONS = [
  { id: 'all', label: 'All' },
  { id: 'not_imported', label: 'Not imported' },
  { id: 'imported', label: 'Imported' },
  { id: 'specific_import', label: 'Specific import' },
];

export const OUTPUT_STATUSES = [
  { id: 'all', label: 'All' },
  { id: 'issued', label: 'Issued' },
  { id: 'expired', label: 'Expired' },
  { id: 'unissued', label: 'Unissued' },
  { id: 'revoked', label: 'Revoked' },
];

export const RENEWAL_STATUSES = [
  { id: 'all', label: 'All' },
  { id: 'renewal', label: 'Is renewal' },
  { id: 'not_renewal', label: 'Not renewal' },
];

const TEAM_SELECT = {
  id: 'team',
  label: 'Team',
  type: 'teamSelect',
  filterGroup: 'main',
  defaults: {
    value: [],
    operator: OPERATOR_IS.id
  },
  operators: [
    OPERATOR_IS,
    OPERATOR_IS_NOT,
  ]
};

const FORM_SELECT = {
  id: 'form',
  label: 'Form',
  type: 'formSelect',
  filterGroup: 'main',
  defaults: {
    value: [],
    operator: OPERATOR_IS.id,
    options: {
      status: 'all'
    }
  },
  operators: [
    OPERATOR_IS,
    OPERATOR_IS_NOT,
  ]
};

const OUTPUT_STATUS_SELECT = {
  id: 'output_status',
  label: 'Output status',
  type: 'outputStatus',
  filterGroup: 'main',
  defaults: {
    value: 'issued',
    operator: OPERATOR_IS.id,
  },
  operators: [
    OPERATOR_IS
  ]
};

const RENEWAL_STATUS_SELECT = {
  id: 'renewal_status',
  label: 'Renewal',
  type: 'renewalStatus',
  filterGroup: 'main',
  defaults: {
    value: 'all',
    operator: OPERATOR_IS.id,
  },
  operators: [
    OPERATOR_IS
  ]
};

export const CURRENT_STEP_SELECT = {
  id: 'current_step',
  label: 'Current step',
  type: 'currentStep',
  filterGroup: 'formFields',
  defaults: {
    operator: OPERATOR_IS.id
  },
  operators: [
    OPERATOR_IS,
    OPERATOR_IS_NOT,
  ]
};

export const DIMENSIONS = [
  TEAM_SELECT,
  FORM_SELECT,
  {
    id: 'assigned_to_me',
    label: 'Only assigned to me',
    type: 'assignedToggle',
    filterGroup: 'main',
    defaults: {
      value: true
    },
    operators: []
  },
  {
    id: 'applicant',
    label: 'Applicant',
    type: 'applicantSearch',
    filterGroup: 'main',
    defaults: {
      operator: OPERATOR_IS.id,
      options: {
        applicant: null
      },
    },
    operators: [
      OPERATOR_IS,
      OPERATOR_IS_NOT,
    ]
  },
  {
    id: 'launched_at',
    label: 'Launched at',
    type: 'launchedAt',
    filterGroup: 'main',
    defaults: {
      value: [null, null],
      operator: OPERATOR_RANGE.id
    },
    operators: [
      OPERATOR_RANGE,
    ]
  },
  {
    id: 'current_step_time',
    label: 'Time in current step',
    type: 'currentStepTime',
    filterGroup: 'main',
    defaults: {
      value: 1,
      operator: OPERATOR_LONGER.id,
      options: {
        term: 'days'
      }
    },
    operators: [
      OPERATOR_LONGER,
      OPERATOR_LESS
    ]
  },
  {
    id: 'completed_date',
    label: 'Completion date',
    type: 'completedDate',
    filterGroup: 'main',
    defaults: {
      value: [null, null],
      operator: OPERATOR_RANGE.id
    },
    operators: [
      OPERATOR_RANGE,
    ]
  },
  {
    id: 'completed_status',
    label: 'Completion status',
    type: 'completedStatus',
    filterGroup: 'main',
    defaults: {
      value: 'active',
      operator: OPERATOR_IS.id
    },
    operators: [
      OPERATOR_IS,
      OPERATOR_IS_NOT,
    ]
  },
  {
    id: 'imported',
    label: 'Imported',
    type: 'importedSelect',
    filterGroup: 'main',
    defaults: {
      value: 'all',
      operator: OPERATOR_IS.id,
      options: {
        source_id: 'all'
      }
    },
    operators: [
      OPERATOR_IS
    ]
  },
  {
    id: 'tag',
    label: 'Tag',
    type: 'tagSelect',
    filterGroup: 'main',
    defaults: {
      operator: OPERATOR_IS.id,
      value: [],
    },
    operators: [
      OPERATOR_IS,
      OPERATOR_IS_NOT
    ]
  },
  OUTPUT_STATUS_SELECT,
  RENEWAL_STATUS_SELECT,
  {
    id: 'submission',
    label: 'Submission ID',
    type: 'submission',
    filterGroup: 'main',
    defaults: {
      operator: OPERATOR_IS.id,
    },
    operators: [
      OPERATOR_IS
    ]
  },
];

export const getSubmissionDimensions = () => {
  const options = sortBy(DIMENSIONS, 'label');
  return [{ id: 'main', options }];
};

export const getOutputDimensions = () => {
  const options = [
    TEAM_SELECT,
    FORM_SELECT,
    OUTPUT_STATUS_SELECT,
    {
      id: 'output_issued',
      label: 'Issued date',
      type: 'outputIssued',
      filterGroup: 'main',
      defaults: {
        value: [null, null],
        operator: OPERATOR_RANGE.id
      },
      operators: [
        OPERATOR_RANGE,
      ]
    },
    RENEWAL_STATUS_SELECT,
    {
      id: 'output_renewals_enabled',
      label: 'Renewals enabled',
      type: 'outputRenewalsEnabled',
      filterGroup: 'main',
      defaults: {
        value: 'true'
      },
      operators: []
    },
  ];
  return [{ id: 'main', options: sortBy(options, 'label') }];
};
