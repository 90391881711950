import keyMirror from 'keymirror';

export const FILTER_NAMES = {
  APPLICANT: 'applicant',
  LAUNCHED_AT: 'launchedAt',
  CURRENT_STEP_TIME: 'currentStepTime',
  COMPLETION_DATE: 'completionDate',
  COMPLETION_STATUS: 'completionStatus',
  IMPORTED: 'imported',
  ISSUED_AT: 'issuedAt',
  TAG: 'tag',
  OUTPUT_STATUS: 'outputStatus',
  RENEWAL: 'renewal',
  CUSTOM_ID: 'customId',
  CURRENT_STEP: 'currentStepId',
  WORKFLOW_STATUS: 'workflowStatus',
};

export default keyMirror({
  ITEMS_LOADED: null,
  MORE_ITEMS_LOADED: null,
  STEP_DATA_LOADED: null,
  ITEMS_LOADING: null,
  LOAD_FAILED: null,
}, 'DASHBOARD');
