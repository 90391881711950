import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { icon as i } from '@fortawesome/fontawesome-svg-core/import.macro';
import Picker from 'components/shared/picker';
import FlowStepActions from 'actions/flow-step-actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDataTypes } from 'modules/step-data-types';
import { isNull } from 'underscore';

const FIELD_ICONS = {
  string: <FontAwesomeIcon icon={i({ name: 'input-text' })} />,
  text: <FontAwesomeIcon icon={i({ name: 'align-left' })} />,
  email: <FontAwesomeIcon icon={i({ name: 'at' })} />,
  integer: <FontAwesomeIcon icon={i({ name: 'input-pipe' })} />,
  float: <FontAwesomeIcon icon={i({ name: 'input-numeric' })} />,
  money: <FontAwesomeIcon icon={i({ name: 'money-check-dollar' })} />,
  address: <FontAwesomeIcon icon={i({ name: 'map-pin' })} />,
  date_range: <FontAwesomeIcon icon={i({ name: 'calendar-days' })} />,
  date: <FontAwesomeIcon icon={i({ name: 'clock' })} />,
  multiple_choice: <FontAwesomeIcon icon={i({ name: 'list-check' })} />,
  checkboxes: <FontAwesomeIcon icon={i({ name: 'list-radio' })} />,
  file: <FontAwesomeIcon icon={i({ name: 'cloud-arrow-up' })} />,
  phone: <FontAwesomeIcon icon={i({ name: 'phone-office' })} />,
  entity: <FontAwesomeIcon icon={i({ name: 'brackets-curly' })} />,
};

const NewFieldPicker = (props) => {
  const [fieldType, setFieldType] = useState(null);
  const items = useMemo(() => {
    if (isNull(props.stepType)) { return []; }
    return getDataTypes(props.stepType, { customEntities: props.customEntities })
      .map((dataType) => {
        const icon = FIELD_ICONS[dataType.type.split('-')[0]];
        return {
          id: dataType.type,
          title: dataType.name,
          icon,
        };
      });
  }, [props.customEntities, props.stepType]);
  const onFieldCreate = () => {
    return FlowStepActions.addData(props.sectionId, fieldType)
      .done(() => props.onClose());
  };

  return (
    <Picker
      title='Add Field'
      items={items}
      onSave={onFieldCreate}
      onSelect={setFieldType}
      onClose={props.onClose}
      columns={4}
      saveTitle='Add Field'
    />
  );
};

NewFieldPicker.propTypes = {
  sectionId: PropTypes.number.isRequired,
  stepType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  customEntities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default NewFieldPicker;
