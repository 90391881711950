import Shield from 'modules/shield';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import CloseIfOutsideWrapper from 'components/shared/close-if-outside-wrapper';
import useFocusTrap from 'components/hooks/use-focus-trap';
import XClose from 'components/shared/x-close';

const Modal = (props) => {
  const focusEl = useFocusTrap(onClose);

  useEffect(() => {
    Shield.on();

    return () => {
      Shield.off();
    };
  }, []);

  function onClose() {
    if (props.onOutsideClick) {
      props.onOutsideClick();
    }
  }

  function className() {
    const classes = ['centered'];

    if (props.className) {
      classes.push(props.className);
    }

    if (!props.scroll) {
      classes.push('modal-noscroll');
    }

    return classes.join(' ');
  }

  function renderMain() {
    return (
      <div id='modal' className={className()} ref={focusEl}>
        <XClose onClick={onClose} buttonClass='float-right' label='Close invite modal'/>
        {props.children}
      </div>
    )
  }

  if (props.enableCloseIfOutside) {
    return (
      <CloseIfOutsideWrapper onClose={onClose}>
        {renderMain()}
      </CloseIfOutsideWrapper>
    );
  }

  return renderMain()
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onOutsideClick: PropTypes.func,
  className: PropTypes.string,
  scroll: PropTypes.bool,
  enableCloseIfOutside: PropTypes.bool,
};

Modal.defaultProps = {
  scroll: true,
  enableCloseIfOutside: true,
};

export default Modal;
