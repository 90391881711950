import React, { useState, useRef } from 'react';
import ReactCrop from 'react-image-crop';
import NProgress from 'nprogress';

const PuplicPortalHeader = props => {
  const bannerRef = useRef();
  const [defFile, setDefFile] = useState(null);
  const [defFileCrop, setDefFileCrop] = useState({
    unit: 'px',
    x: 0,
    y: 0,
    width: 160,
    height: 90,
  });
  const [bannerImageAspect, setBannerImageAspect] = useState(1.0);
  const [newBannerPresent, setNewBannerPresent] = useState(false);
  const [bannerOptions, setBannerOptions] = useState({});

  const onDefFileCrop = (crop) => {
    setDefFileCrop(crop);
    setBannerOptions({
      x: Math.floor(defFileCrop.x * bannerImageAspect),
      y: Math.floor(defFileCrop.y * bannerImageAspect),
      w: Math.floor(defFileCrop.width * bannerImageAspect),
      h: Math.floor(defFileCrop.height * bannerImageAspect),
    });
  }

  const onBannerChange = (event) => {
    var reader = new FileReader();

    reader.onload = function (ev) {
      setDefFile(ev.target.result);

      var img = new Image;
      img.onload = function() {
        if (img.width > bannerRef.current.width) {
          setBannerImageAspect(img.width / bannerRef.current.width);
        }

        setBannerOptions({
          x: Math.floor(defFileCrop.x * bannerImageAspect),
          y: Math.floor(defFileCrop.y * bannerImageAspect),
          w: Math.floor(defFileCrop.width * bannerImageAspect),
          h: Math.floor(defFileCrop.height * bannerImageAspect),
        });
      };

      img.src = ev.target.result;
    };

    reader.readAsDataURL(event.currentTarget.files[0]);
    setNewBannerPresent(event.currentTarget.files.length > 0)
  };

  const onBannerSave = () => {
    if (!newBannerPresent) {
      e.preventDefault();
    }

    NProgress.start();
  };

  const renderPublicBanner = () => {
    if (props.team.public_portal_banner.present) {
      return <img
        className='imgborder margin-top-less'
        style={{ maxHeight: '100px' }}
        src={props.team.public_portal_banner.url}
        alt='Public header banner'
      />;
    }
  }

  return (
    <>
      <label>
        <span>Public Portal Header</span>
        {renderPublicBanner()}
        <form encType='multipart/form-data' method='post' action={`/teams/${props.team.friendly_id}`}>
          <input type='hidden' name='_method' value='patch' />
          <input type='hidden' name='team[public_portal_banner_options]' value={JSON.stringify(bannerOptions)} />
          <input
            disabled={props.disabled}
            type='file'
            accept='image/*'
            name='team[public_portal_banner]'
            onChange={onBannerChange}
          />
          <input
            onClick={onBannerSave}
            className={`btn-primary small${(newBannerPresent ? '' : ' disabled')}`}
            type='submit'
            value={props.team.public_portal_banner.present ? 'Replace' : 'Add'}
          />
          {props.team.public_portal_banner.present && (
            <input
              name='remove_banner'
              className={`btn-secondary small margin-left-less`}
              type='submit'
              value='Delete'
            />
          )}
        </form>
      </label>

      <ReactCrop aspect={16/9} crop={defFileCrop} onChange={onDefFileCrop}>
        <img style={{ width: '100%' }} src={defFile} ref={bannerRef} />
      </ReactCrop>
    </>
  )
}

export default PuplicPortalHeader;

