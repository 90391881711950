import { extend } from 'underscore';

const DashboardAPI = {
  fetch: function(params){
    const URL = '/dashboard/fetch';

    return $.ajax(URL, {
      method: 'GET',
      dataType: 'json',
      data: params
    });
  },

  fetchAssigned: function(filters){
    return $.ajax('/dashboard/fetch_assigned', {
      method: 'GET',
      dataType: 'json',
      data: filters
    })
  },

  fetchOutputs: function(params){
    const URL = '/dashboard/fetch_outputs';

    return $.ajax(URL, {
      method: 'GET',
      dataType: 'json',
      data: params
    });
  },

  delete: function(params, confirmCount){
    const URL = '/dashboard/delete';

    params = extend(params, { confirm_count: confirmCount });

    return $.ajax(URL, {
      method: 'DELETE',
      dataType: 'json',
      data: params
    });
  },

  export: function(params){
    const URL = '/dashboard/export';
    return $.ajax(URL, {
      method: 'GET',
      dataType: 'json',
      data: params
    });
  },

  exportOutputs: function(params){
    const URL = '/dashboard/export_outputs';
    return $.ajax(URL, {
      method: 'GET',
      dataType: 'json',
      data: params
    });
  },
};

export default DashboardAPI;
