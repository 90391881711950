const MembershipAPI = {
  index(masterTemplateId) {
    const url = `/master_templates/${masterTemplateId}/master_template_memberships`;
    const request = $.ajax(url, {
      dataType: 'json',
      method: 'GET',
    });

    return request;
  },

  create(userId, masterTemplateId) {
    const url = `/master_templates/${masterTemplateId}/master_template_memberships`;
    const request = $.ajax(url, {
      dataType: 'json',
      method: 'POST',
      data: {
        membership: {
          user_id: userId,
        },
      },
    });

    return request;
  },

  sendInvite(masterTemplateId, name, email) {
    const url = `/master_templates/${masterTemplateId}/master_template_memberships/add_pseudomember`;
    const request = $.ajax(url, {
      dataType: 'json',
      method: 'POST',
      data: {
        master_template_id: masterTemplateId,
        name,
        email,
      },
    });

    return request;
  },

  resendInvite(invitationId) {
    const url = `/invitations/${invitationId}/resend_invite`;
    const request = $.ajax(url, {
      dataType: 'json',
      method: 'POST',
    });

    return request;
  },

  update(membershipId, attrs) {
    const url = `/master_template_memberships/${membershipId}`;
    const request = $.ajax(url, {
      method: 'PUT',
      dataType: 'json',
      data: {
        membership: attrs,
      },
    });

    return request;
  },

  destroy(membershipId) {
    const url = `/master_template_memberships/${membershipId}`;
    const request = $.ajax(url, {
      method: 'DELETE',
      dataType: 'json',
    });

    return request;
  },
};

export default MembershipAPI;
