import React from 'react';
import { any, findWhere } from 'underscore';
import Map from 'components/shared/input_fields/entity-input/map';

const CollectInfoStepSummary = ({step, collectedData}) => {
  function renderSections() {
    if (any(step.sections)) {
      return (
        <table key={step.id}>
          <tbody>
            {step.sections.map(renderSectionData)}
          </tbody>
        </table>
      );
    }

    return <div className='color-text-medium margin-top-less'>No data to display for this section</div>;
  };

  function renderSectionData(section) {
    if (!any(section.step_data)) { return; }

    return (
      <React.Fragment key={section.id}>
        <tr className='collecteddatatable-sectionname'>
          <td>{section.name}</td><td />
        </tr>
        {section.step_data.map(renderDatum)}
      </React.Fragment>
    );
  }

  function renderDatum(datum) {
    return (
      <tr key={datum.id}>
        <td>{datum.name}</td>
        <td>{renderValueForDatum(datum.id)}</td>
      </tr>
    );
  }

  function renderValueForDatum(stepDatumId) {
    const collectedDatum = findWhere(collectedData, {
      step_datum_id: stepDatumId,
    });

    if (!collectedDatum) { return '---'; }

    if (collectedDatum.data_type === 'entity' && collectedDatum.entity?.is_esri) {
      const fieldData = Object.entries(collectedDatum.entity.field_data).map(([key, val]) => {
        return (
          <div className='margin-bottom'>
            <label>
              {key}
            </label>
            <span>{val}</span>
          </div>
        );
      });

      return (
        <div>
          {fieldData}
          <Map
            feature={collectedDatum.entity.feature}
          />
        </div>
      );
    }

    return <span dangerouslySetInnerHTML={{ __html: collectedDatum.formatted_content }} />;
  };

  return (
    <div className='collecteddatatable-wrap'>
      {renderSections()}
    </div>
  );
};

export default CollectInfoStepSummary;
