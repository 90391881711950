import React from 'react';
import PropTypes from 'prop-types';
import { exportResponses } from 'actions/survey-actions';
import ajaxDownload from 'modules/ajax-download';
import BackgroundedJobLink from 'components/shared/backgrounded-job-link';
import { error } from 'components/shared/flash';
import { alert } from 'modules/alert-confirm';
import SurveySubmissionLine from './survey-submission-line';
import SurveySubmissionLineTaskView from './survey-submission-line-task-view';

const SurveyResponses = ({
  surveySubmissions,
  activeStepId,
  initiatedFlowId,
  taskView,
}) => {
  const renderSurveySubmissions = () => {
    const submissions = surveySubmissions;

    if (submissions.length) {
      return (
        <ul className='surveystep-responses'>
          {submissions.map(renderSurveySubmissionLine)}
        </ul>
      );
    }
  };

  const renderSurveySubmissionLine = (submission) => {
    if (taskView) {
      return (
        <SurveySubmissionLineTaskView
          submission={submission}
          key={submission.id}
          initiatedFlowId={initiatedFlowId}
        />
      )
    }

    return (
      <SurveySubmissionLine
        submission={submission}
        key={submission.id}
        initiatedFlowId={initiatedFlowId}
      />
    );
  };

  const alertWithDownloadURL = (url) => {
    alert('Download ready', `<a target='_blank' href='${url}'>Click here to download your file</a>`);
  };

  const onSurveyDownloadReady = (res) => {
    const parsed = JSON.parse(res);
    const url = parsed.file_url;
    const fileName = parsed.file_name;

    ajaxDownload(url, fileName)
      .catch(() => { alertWithDownloadURL(url); });
  };

  return (
    <section>
      <div className='surveystep-label inline-block padding-bottom'>Responses ({surveySubmissions.length})</div>
      <BackgroundedJobLink
        queueFunction={() => { return exportResponses(activeStepId); }}
        onJobComplete={onSurveyDownloadReady}
        onFail={error}
        workingText='Exporting...'
        className='float-right btn-link strong'
        disabled={surveySubmissions.length == 0}
      >
        Export responses
      </BackgroundedJobLink>
      {renderSurveySubmissions()}
    </section>
  );
};

SurveyResponses.propTypes = {
  surveySubmissions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeStepId: PropTypes.number.isRequired,
};

export default SurveyResponses;
