import React from "react";

import Select from "../select";
import { RENEWAL_STATUSES } from "./constants";

const RenewalStatus = ({
  changeFilterValue,
  filterState,
}) => {
  const onStatusChange = (value) => {
    changeFilterValue(value.id);
  };

  return (
    <div className='metricsdashboard-filterselect'>
      <Select
        options={RENEWAL_STATUSES}
        onChange={onStatusChange}
        getOptionValue={(opt) => opt.id}
        value={RENEWAL_STATUSES.find((opt) => opt.id === filterState.value)}
        className='react-select-metrics advanced-filter'
      />
    </div>
  );
};

export default RenewalStatus;
