const SearchAPI = {
  searchApplicants(terms, searchAll = false) {
    const url = '/search/applicants';
    return $.ajax(url, {
      dataType: 'json',
      method: 'GET',
      data: {
        terms,
        search_all: searchAll,
      },
    });
  },

  searchThirdPartyReviewers(terms, initiatedFlowId) {
    const url = '/search/third_party_reviewers';
    return $.ajax(url, {
      dataType: 'json',
      method: 'GET',
      data: {
        terms,
        initiated_flow_id: initiatedFlowId,
      },
    });
  },

  searchSupportContacts(terms, masterTemplateId) {
    const url = '/search/support_contacts';
    return $.ajax(url, {
      dataType: 'json',
      method: 'GET',
      data: {
        terms,
        id: masterTemplateId,
      },
    });
  },

  usersAndRoles(terms, teamId) {
    const url = '/search/users_and_roles';
    return $.ajax(url, {
      dataMethod: 'GET',
      data: {
        terms,
        team_id: teamId,
      },
    });
  }
};

export default SearchAPI;
