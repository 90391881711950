import React from 'react';
import BarChart from 'components/shared/chart/bar';
import DonutChart from 'components/shared/chart/donut';
import COLORS from 'constants/chart-colors';
import NoDataPlaceholder from '../../shared/no-data-placeholder';

const SecondRow = (props) => {
  function renderChargesChart() {
    const labels = Object.keys(props.transactionData.charges);
    const data = Object.values(props.transactionData.charges).map((charge) => { return charge / 100});

    const dta = {
      labels,
      datasets: [{
        indexAxis: 'x',
        data,
        backgroundColor: [COLORS[1]],
      }],
    };

    const maxLimit = props.transactionData.max_charge * 1.1

    return (
      <div className='chart'>
        <h2 className='align-center'>Revenue Collected</h2>
        <div className='barchart'>
          <BarChart
            data={dta}
            options={{
              plugins: {
                legend: { display: false },
              },
              scales: {
                y: {
                  suggestedMax: maxLimit,
                  ticks: {
                    callback(label, _index, _ticks) {
                      return `$${label}`;
                    },
                  },
                },
              },
            }}
          />
        </div>
      </div>
    );
  }

  function noData() {
    const total = props.transactionData.by_type.card + props.transactionData.by_type.ACH

    return total === 0;
  }

  function percentage(numerator) {
    const total = props.transactionData.by_type.card + props.transactionData.by_type.ACH

    if (noData()) { return (0); }

    const prcntge = (numerator / total) * 100;
    return Math.round(prcntge);
  }

  function renderLegend() {
    const values = Object.values(props.transactionData.by_type)
    return ['Card', 'ACH'].map((label, index) => {
      return (
        <li>
          <span className='dot' style={{backgroundColor: COLORS[index]}}/>
          <span>{label}</span>
          <span className='percentage'><strong>{`${values[index]}`}</strong>{` (${percentage(values[index])}%)`}</span>
        </li>
      );
    });
  }

  function renderChart() {
    if (noData()) { return <NoDataPlaceholder type='donut' />; }

    const data = {
      labels: Object.keys(props.transactionData.by_type),
      datasets: [{
        data: Object.values(props.transactionData.by_type),
        backgroundColor: COLORS.slice(0,2)
      }]
    }

    return (
        <div className='piechart'>
          <DonutChart 
            data={data} 
            options={{plugins: {legend: {display: false}}}}
          />
        <div className='pielegend'>
          <ul>
            {renderLegend()}
          </ul>
        </div>
      </div>
    )

  }

  function renderDonutChart() {
    return (
      <div className='chart'>
        <h2 className='align-center'>Revenue by Payment Type</h2>
        <div className='flex'/>
        {renderChart()}
      </div>
    )
  }

  return (
    <div className='metricsdashboard-finances-secondrow'>
      {renderChargesChart()}
      {renderDonutChart()}
    </div>
  );
};

export default SecondRow;
