import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import PaymentStepDetails from 'components/shared/payment-step-details';
import _ from 'underscore';
import StepDataCollector from 'components/shared/step-data-collector';
import RequirementActions from 'actions/requirement-actions';
import InlineNotification from 'components/shared/inline-notification';
import PaymentStep from '../steps/payment-step';
import CollectInfoStep from '../steps/collect-info-step';
import RequirementDescriptionManager from './requirement-description-manager';
import RequirementButtons from './requirement-buttons';

const SupplementalRequirement = (props) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);
  const title = useRef();
  const debouncedSubmitChange = _.debounce(submitChange, 1000);

  function update(id, attrs) {
    return RequirementActions.updateAttrs(dispatch, id, attrs, props.requirement.type);
  }

  function submitChange() {
    update(props.requirement.id, { name: title.current.value });
  }

  function renderRequirement() {
    switch (props.requirement.type) {
    case ('PaymentRequirement'):
      return (
        <PaymentStep
          activeStep={props.requirement.payment}
          teamFriendlyId={CityGrows.Server.initiatedFlowData.team.friendly_id}
          supplemental
          step={props.requirement}
          charges={props.requirement.payment.charges}
          admin={props.admin}
        />
      );
    case ('DataRequirement'):
      return (
        <CollectInfoStep
          step={props.requirement}
          collectedData={props.collectedData}
          initiatedFlow={props.initiatedFlow}
          admin={props.admin}
          teamId={props.teamId}
          locked={props.requirement.active_step.locked}
          activeStep={props.requirement.active_step}
          signaturesAllowed={false}
        />
      );
    default:
      throw `Unrecognized step type ${props.requirement.type}`;
    }
  }

  function renderRequirementEditor() {
    switch (props.requirement.type) {
    case ('PaymentRequirement'):
      return (
        <PaymentStepDetails
          step={props.requirement}
          updateAttrs={update}
          disabled={false}
        />
      );

    case ('DataRequirement'):
      return (
        <StepDataCollector
          key={`datacollector-${props.requirement.id}`}
          sections={props.requirement.sections}
          allowedStepDatumTypes={CityGrows.Server.allowedStepDatumTypes}
          disabled={false}
          sectionableType='Requirement'
          sectionableId={props.requirement.id}
          flowTemplate={props.requirement.flowTemplate}
          stepId={props.requirement.id}
        />
      );
    default:
      throw `Unrecognized step type ${props.requirement.type}`;
    }
  }

  function stepNameHeader() {
    switch (props.requirement.type) {
      case 'PaymentRequirement':
        return 'Additional Payment Name';
      default:
        return `${props.requirement.type} Step`;
    }
  }

  function renderRequirementDetails() {
    if (props.requirement.sent) { return; }

    return (
      <>
        <div className='stepcreator-steptype'>
          {stepNameHeader()}
        </div>

        <div className='stepcreator-details-stepname'>
          <input
            ref={title}
            id='requesttitle'
            placeholder='Add a request name'
            type='text'
            defaultValue={props.requirement.name_or_default}
            onChange={debouncedSubmitChange}
          />
        </div>
        <RequirementDescriptionManager
          key={props.requirement.id}
          step={props.requirement}
          description={props.requirement.description}
          update={update}
        />
      </>
    );
  }

  function canceledBanner() {
    if (props.requirement.canceled) {
      return <InlineNotification> This requirement has been canceled.</InlineNotification>;
    }
  }

  if (props.requirement.sent) {
    return (
      <>
        <div className='currentstep-stepsection nopadding-top nopadding-bottom'>{canceledBanner()}</div>
        {renderRequirement()}
        <div className='currentstep-stepsection padding-bottom'>
          <RequirementButtons
            requirementId={props.requirement.id}
            type={props.requirement.type}
            requirementCompleted={props.requirement.completed}
            requirementSent={props.requirement.sent}
            requirementCanceled={props.requirement.canceled}
          />
        </div>
      </>
    );
  }

  return (
    <div className='requirementcreator-details'>
      <div className='stepcreator-details-padding'>
        {renderRequirementDetails()}
        <div className='linebreak' />
        {renderRequirementEditor()}
        <RequirementButtons
          requirementId={props.requirement.id}
          type={props.requirement.type}
          requirementCompleted={props.requirement.completed}
          requirementSent={props.requirement.sent}
          requirementCanceled={props.requirement.canceled}
        />
      </div>
    </div>
  );
};

SupplementalRequirement.propTypes = {
  requirement: PropTypes.object.isRequired,
  admin: PropTypes.bool.isRequired,
  collectedData: PropTypes.array,
  initiatedFlow: PropTypes.object.isRequired,
  teamId: PropTypes.number.isRequired,
};

export default SupplementalRequirement;
