import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import pluralize from 'modules/pluralize';
import InlineNotification from 'components/shared/inline-notification';

const ConstituentReviewInfo = (props) => {
  const outstandingChangeRequests = () => {
    const test = (d) => {
      return !d.resolved && _.isNull(d.step_approval?.approval_status);
    };

    return _.filter(props.discussions, test);
  };

  const changes = outstandingChangeRequests();
  const count = changes.length;

  if (count > 0) {
    return (
      <InlineNotification className='margin-bottom'>
        <div>
          You have {count} requested {pluralize('change', count, { many: 'changes' })} that  {pluralize('needs', count, { many: 'need' })} to be addressed. When you have made the requested changes, click the <em>Resolved</em> checkbox in the discussion.&nbsp;
          <a href='#' onClick={props.onOpenDiscussionClick}>View requested {pluralize('change', count, { many: 'changes' })}</a>
        </div>
      </InlineNotification>
    );
  }

  if (props.hasThirdPartyReview) { return <></>; }

  return (
    <div>
      Review is in progress. No action is required from you at this time.
      <div className='linebreak margin-bottom-more margin-top' />
    </div>
  );
};

ConstituentReviewInfo.propTypes = {
  discussions: PropTypes.array.isRequired,
  hasThirdPartyReview: PropTypes.bool.isRequired,
  onOpenDiscussionClick: PropTypes.func.isRequired,
};

ConstituentReviewInfo.defaultProps = {
  hasThirdPartyReview: false,
  onOpenDiscussionClick: () => {},
};

export default ConstituentReviewInfo;
