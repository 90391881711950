import PropTypes from 'prop-types';
import React from 'react';
import TextEditor from 'components/shared/text-editor';
import TextEditorButtons from 'components/shared/text-editor/buttons';

class InstructionsManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      instructions: this.props.instructions,
    };
  }

  onSave = () => {
    // Because blank Quill editor still has tags in it
    //

    const newInstructions = (this.state.instructions === '<p><br></p>' ? null : this.state.instructions);

    this.props.onSave(newInstructions);
  };

  onChange = (instructions) => {
    this.setState({ instructions });
  };

  render() {
    return (
      <>
        <TextEditor
          className='instructions'
          onChange={this.onChange}
          content={this.state.instructions || ''}
          formattedMentionData={this.props.templateVariables}
          referenceKey='identity'
          {...!this.props.isActive && { toolbar: [] }}
        />

        {this.props.isActive && <TextEditorButtons onSave={this.onSave} onClose={this.props.onClose} type='Instructions' />}
      </>
    );
  }
}

InstructionsManager.propTypes = {
  instructions: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  templateVariables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClose: PropTypes.func,
  isActive: PropTypes.bool,
};

InstructionsManager.defaultProps = {
  onClose: () => {},
  isActive: true,
};

export default InstructionsManager;
