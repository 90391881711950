import React, { useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { error as flashError } from 'components/shared/flash';
import pluralize from 'modules/pluralize';
import { disc as discStyle } from 'theme/discs';
import randomcolor from 'randomcolor';
import { isNull } from 'underscore';
import Popdown from 'components/shared/popdown';
import RoleAPI from 'apis/role-api';
import ListOfPeople from 'components/shared/list-of-people';
import LoadingSpinner from 'components/shared/loading-spinner';
import { css } from '@emotion/react';
import useOnclickOutside from 'react-cool-onclickoutside';

const RoleDisc = ({ role }) => {
  const [showMembers, setShowMembers] = useState(false);
  const [roleMembers, setRoleMembers] = useState(null);

  const backgroundStyle = useMemo(() => {
    return {
      backgroundColor: randomcolor({
        hue: 'random',
        seed: role.id * 4,
        luminosity: 'dark',
      }),
    };
  }, [role.id]);

  const onMembersClick = () => {
    setShowMembers(!showMembers);

    if (!isNull(roleMembers)) { return; }

    RoleAPI.show(role.id)
      .done((loadedRole) => {
        setRoleMembers(loadedRole.users.map((attachment) => { return attachment.user; }));
      })
      .fail(() => {
        flashError();
      });
  };

  const membersContentRef = useRef();
  const elForAnchor = useRef();

  useOnclickOutside(() => {
    setShowMembers(false);
  }, { refs: [elForAnchor] });

  const renderMembersContent = () => {
    if (isNull(roleMembers)) {
      return <LoadingSpinner size='small' />;
    }

    return (
      <ListOfPeople users={roleMembers} />
    );
  };

  return (
    <div
      css={css`
        display:flex;
        align-items:center;
      `}
    >
      <div>
        <span
          css={discStyle}
          style={backgroundStyle}
        >
          {role.initials}
        </span>
      </div>

      <div className='margin-left-less'>
        <div>{role.name}</div>

        <div>
          <button
            className='btn-link'
            type='button'
            onClick={onMembersClick}
            ref={elForAnchor}
          >
            {role.user_count} role {pluralize('member', role.user_count)}
          </button>

          { showMembers && (
            <Popdown anchorRef={elForAnchor}>
              <div className='margins' ref={membersContentRef}>
                {renderMembersContent()}
              </div>
            </Popdown>
          )}
        </div>
      </div>
    </div>
  );
};

RoleDisc.propTypes = {
  role: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    initials: PropTypes.string.isRequired,
    user_count: PropTypes.number.isRequired,
  }).isRequired,
};

export default RoleDisc;
