import ActionButton from 'components/shared/action-button';
import PropTypes from 'prop-types';
import React, { memo, useContext } from 'react';
import TemplateManagerActions from 'actions/template-manager-actions';
import truncate from 'truncate';
import UserAccessActions from 'actions/user-access-actions';
import VersionNumber from 'components/shared/version-number';
import AccessManagerDispatchContext from 'contexts/access-manager-dispatch-context';
import { withRouter } from 'react-router';
import TemplateNavBar from './template_header/template-navbar';
import LaunchOptions from './template_header/launch-options';
import FlowTemplateName from './template_header/flow-template-name';

const FlowTemplateHeader = memo((props) => {
  const dispatch = useContext(AccessManagerDispatchContext);
  const uneditable = () => {
    return props.permissionType !== 'write';
  };

  const onPublishClick = () => {
    return TemplateManagerActions.publish(props.flowTemplate.id)
            .done(() => props.history.push('/stats'));
  };

  const renderPublishButton = () => {
    return (
      <div className='templatenavbar-publish'>
        <ActionButton onClick={onPublishClick} className='btn-primary' htmlID='publish-button'>
          Publish
        </ActionButton>
      </div>
    );
  };

  const renderPublishOrLaunch = () => {
    const currentUser = { ...CityGrows.Server.currentUser, ...{ id: CityGrows.Server.currentUserId } };
    const renderLaunchOptions = () => {
      return (
        <LaunchOptions
          key='launchoptions'
          masterTemplate={props.masterTemplate}
          flowTemplateId={props.flowTemplate.id}
          applicant={currentUser}
        />
      );
    };

    if (!props.masterTemplate.ever_published) {
      return (props.permissionType === 'write') && renderPublishButton();
    }

    return renderLaunchOptions();
  };

  const onJoinTemplateClick = (e) => {
    e.preventDefault();
    UserAccessActions.addUser(CityGrows.Server.currentUserId, props.flowTemplate.master_template_id, dispatch);
  };

  const isTemplateMember = () => {
    return ['write', 'read'].includes(props.permissionType);
  };

  const renderJoinButton = () => {
    if (isTemplateMember()) { return; }

    return (
      <div className='templatenavbar-joinbtn margin-right-more'>
        <a onClick={onJoinTemplateClick} className='text-right' href=''>
          <i className='icon-plus-white' />
          + Join this form
        </a>
      </div>
    );
  };

  const { team } = props.flowTemplate;
  return (
    <div className='templateheader-wrap'>
      <div className='templateheader-leftwrap'>
        <FlowTemplateName
          flowTemplate={props.flowTemplate}
          disabled={uneditable()}
        />

        <TemplateNavBar
          permissionType={props.permissionType}
          flowTemplate={props.flowTemplate}
          masterTemplate={props.masterTemplate}
        />
      </div>

      <div className='templateheader-rightwrap'>
        {renderPublishOrLaunch()}
        {renderJoinButton()}

        <div className='templateheader-fragment margin-right-more'>
          <VersionNumber
            version={props.flowTemplate.version}
            masterTemplateId={props.flowTemplate.master_template_id}
          />

        </div>
        <div className='templateheader-fragment margin-right-more'>
          <div title={team.name}>
            <a href={`/teams/${team.friendly_id}`}>{truncate(team.name, 20)}</a>
          </div>
        </div>
      </div>
    </div>
  );
});

FlowTemplateHeader.displayName = 'FlowTemplateHeader';

FlowTemplateHeader.propTypes = {
  permissionType: PropTypes.string.isRequired,
  flowTemplate: PropTypes.object.isRequired,
  masterTemplate: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(FlowTemplateHeader);
