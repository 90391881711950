import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import EntityConfigurationActions from 'actions/entity-configuration-actions';
import SlidingToggle from 'components/shared/sliding-toggle';
import EntityConfigurationDispatchContext from 'contexts/entity-configuration-dispatch-context';
import { error } from 'components/shared/flash';
import { confirm } from 'modules/alert-confirm';

const CustomEntityField = (props) => {
  const dispatch = useContext(EntityConfigurationDispatchContext);
  const [viewable, setViewable] = useState(props.field.viewable);
  const [name, setName] = useState(props.field.name);
  const [editSuggestable, setEditSuggestable] = useState(props.field.edit_suggestable);
  const [editingName, setEditingName] = useState(false);

  const nameBlank = () => {
    if (name === '') {
      error('Name can\'t be blank');
      return true;
    }

    return false;
  };

  const updateName = (e) => {
    setName(e.target.value);
  };

  const saveNewEntity = () => {
    if (nameBlank()) { return; }

    const attrs = {
      name,
      viewable,
      edit_suggestable: editSuggestable,
      data_type: 'string',
    };

    EntityConfigurationActions.addField(props.customEntityId, attrs, dispatch)
                              .done(props.onNewFieldAdded);
  };

  const saveName = () => {
    if (nameBlank()) { return; }

    if (props.field.name === name) {
      setEditingName(false);
      return;
    }

    updateField('name', name)
      .done(() => { setEditingName(false); });
  };

  const editName = (e) => {
    e.preventDefault();
    setEditingName(true);
  };

  const deleteField = (e) => {
    e.preventDefault();

    if (props.field.data_count > 0) {
      confirm('Delete this field?', 'This will also delete each organization\'s data associated with this field.', () => {
        EntityConfigurationActions.removeField(props.field.id, dispatch);
      });
    } else {
      EntityConfigurationActions.removeField(props.field.id, dispatch);
    }
  };

  const onViewableChanged = () => {
    if (!props.new) {
      updateField('viewable', !props.field.viewable);
    }

    setViewable(!props.field.viewable);
  };

  const onEditableChanged = () => {
    if (!props.new) {
      updateField('edit_suggestable', !props.field.edit_suggestable);
    }

    setEditSuggestable(!props.field.edit_suggestable);
  };

  const updateField = (key, value = e) => {
    const attrs = { };
    attrs[`${key}`] = value;

    return EntityConfigurationActions.updateField(props.field.id, attrs, dispatch);
  };

  const viewToggle = () => {
    return (
      <SlidingToggle
        onToggle={onViewableChanged}
        on={viewable}
        label='View'
        disabled={props.disabled}
        className='margin-right'
      />
    );
  };

  const editToggle = () => {
    if (!viewable || props.isEsri) { return ''; }

    return (
      <SlidingToggle
        onToggle={onEditableChanged}
        on={editSuggestable}
        disabled={props.disabled}
        label='Suggest edits'
        className='margin-right'
      />
    );
  };

  const deleteButton = () => {
    return (
      <i title='Delete field' onClick={deleteField} className='icon-trash-grey bigger clickable' />
    );
  };

  const nameField = () => {
    const readOnly = !props.new && !editingName;
    const editable = !props.new && !props.disabled && !props.isEsri;
    return (
      <div>
        <div className='entityconfiguration-name custom'>
          <input
            type='text'
            autoComplete='off'
            className={`custom-entity-field-input ${readOnly ? 'readOnly' : ''}`}
            name='name'
            placeholder='Add field name'
            readOnly={readOnly}
            onChange={updateName}
            value={name}
          />
          {editable && editNameAction()}
        </div>

        {props.disabled && (
          <span className='float-right'>Uneditable</span>
        )}
      </div>
    );
  };

  const editNameAction = () => {
    if (props.isEsri) { return; }
    
    if (editingName) {
      return <button type='button' onClick={saveName}>Save</button>;
    }

    return <a href='#' className='secondary' onClick={editName}>Edit</a>;
  };

  function actionButtons() {
    return (
      <div className='float-right padding-top'>
        <button type='button' className='btn-link' onClick={props.cancelNewField}>Cancel</button>
        <button type='button' className='margin-left btn-primary' onClick={saveNewEntity}>Save Field</button>
      </div>
    );
  }

  const showDelete = !props.new && !props.disabled && !props.isEsri;

  return (
    <li>
      <div>
        {nameField()}
      </div>

      <div className='entityconfiguration-field-options'>
        Constituents can:
        <span className='entityconfiguration-field- margin-right-less margin-left'>
          {viewToggle()}
          {!props.isEsri && editToggle()}
        </span>
        {showDelete && deleteButton()}
      </div>
      
      {props.new && actionButtons()}
    </li>
  );
};

CustomEntityField.propTypes = {
  new: PropTypes.bool,
  disabled: PropTypes.bool,
  field: PropTypes.object,
  customEntityId: PropTypes.number,
  onNewFieldAdded: PropTypes.func,
  cancelNewField: PropTypes.func,
  isEsri: PropTypes.bool.isRequired,
};

export default CustomEntityField;
