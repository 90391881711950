import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import QueryString from 'query-string';
import dayjs from 'dayjs';
import { exportTransactions, exportCashAndChecks } from 'apis/transactions-api';
import ExportsMessage from 'components/shared/exports-message';

class DateFilters extends React.Component {
  constructor(props) {
    super(props);
    const params = QueryString.parse(location.search);

    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);

    const endDate = new Date();

    this.state = {
      exportQueued: false,
      exportQueueRequested: false,
      startDate: (params.start_date ? dayjs(params.start_date).toDate() : startDate),
      endDate: (params.end_date ? dayjs(params.end_date).toDate() : endDate),
    };
  }

  onStartChange = (dateString) => {
    this.setState({
      startDate: dateString,
    });
  };

  onEndChange = (dateString) => {
    this.setState({
      endDate: dateString,
    });
  };

  goButtonClicked = () => {
    document.location = `?${this.urlParams()}`;
  };

  formatDate(date) {
    return date ? date.toISOString() : null;
  }

  urlParams = () => {
    return $.param({
      start_date: this.formatDate(this.state.startDate),
      end_date: this.formatDate(this.state.endDate),
    });
  };

  transactionsExport = () => {
    exportTransactions(
      this.props.teamId,
      this.formatDate(this.state.startDate),
      this.formatDate(this.state.endDate),
    )

    .done(() => { this.setState({ exportQueued: true }); })
    .always(() => { this.setState({ exportQueueRequested: false }); });
  }

  cashAndChecksExport = () => {
    exportCashAndChecks(
      this.props.teamId,
      this.formatDate(this.state.startDate),
      this.formatDate(this.state.endDate),
    )

    .done(() => { this.setState({ exportQueued: true }); })
    .always(() => { this.setState({ exportQueueRequested: false }); });
  }

  onExportClick = () => {
    this.setState({ exportQueueRequested: true });

    if (this.props.type === 'transactions') {
      return this.transactionsExport();
    }

    return this.cashAndChecksExport();
  };

  render() {
    return (
      <div>
        <div className='width-25 inline-block'>
          <span className='margin-right color-text-medium'>From:</span>
          <DatePicker
            onChange={this.onStartChange}
            className='margin-right'
            selected={this.state.startDate}
            dateFormat={['yyyy-MM-dd', 'yyyy-M-d', 'M/d/yyyy', 'M-d-yyyy']}
            placeholderText='YYYY-MM-DD'
          />
        </div>

        <div className='width-25 inline-block'>
          <span className='margin-right color-text-medium'>To:</span>

          <DatePicker
            onChange={this.onEndChange}
            className='margin-right'
            selected={this.state.endDate}
            dateFormat={['yyyy-MM-dd', 'yyyy-M-d', 'M/d/yyyy', 'M-d-yyyy']}
            placeholderText='YYYY-MM-DD'
          />
        </div>

        <button onClick={this.goButtonClicked} className='btn-primary margin-right'>Go</button>
        <button disabled={this.state.exportQueueRequested} className='btn-thirdary' onClick={this.onExportClick}>Export to CSV</button>

        {this.state.exportQueued && <ExportsMessage className='margin-top' />}
      </div>
    );
  }
}

DateFilters.propTypes = {
  teamId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default DateFilters;
