const ProcessAssignmentsAPI = {
  assign: function(id, assigneeId, assigneeType) {
    const URL = `/initiated_flows/${id}/process_assignments/`;

    return $.ajax(URL, {
      method: 'POST',
      data: {
        assignee_id: assigneeId,
        assignee_type: assigneeType
      },
      dataType: 'json'
    });
  },

  unassign: function(id, assignmentId) {
    const URL = `/initiated_flows/${id}/process_assignments/${assignmentId}`;

    return $.ajax(URL, {
      method: 'DELETE',
      dataType: 'json'
    });
  }
}

export default ProcessAssignmentsAPI
