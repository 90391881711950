
import 'select2';

const knew = () => {
  $('select').select2();
};

export default {
  new: knew,
};
