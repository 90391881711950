import React, { useState, useContext, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import ExpandableContent from 'components/shared/expandable-content';
import RequestIsGovContext from 'contexts/request-is-gov-context';
import truncate from 'truncate';
import pluralize from 'modules/pluralize';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import OutputDownloadLink from './output-download-link';
import RenewalManager from './renewal-manager';
import RenewalOptOutLink from './renewal-opt-out-link';
import RevokeButton from './revoke-button';
import OutputDetails from './output-details';

dayjs.extend(localizedFormat);

const Output = (props) => {
  let isGov = useContext(RequestIsGovContext);
  const [expanded, setExpanded] = useState(!props.collapse);

  // TODO:
  // This is super hacky, should just be using a different context

  // override the Context if for a custom search page result

  if (props.isCustomResult) {
    isGov = false;
  }

  const { issued } = props.output;

  function toggleCollapse () {
    setExpanded(!expanded);
  };

  const renderStatusTag = () => {
    let tagLabel;

    if (!props.output.issued) {
      tagLabel = 'Unissued';
    } else if (props.output.revoked) {
      tagLabel = 'Revoked';
    } else if (props.output.expired) {
      tagLabel = 'Expired';
    } else {
      tagLabel = 'Valid';
    }

    const openRevealArrowClass = expanded ? 'open' : '';

    return (
      <button type='button' onClick={toggleCollapse} className='output-status-wrapper btn-link'>
        <span className={`output-statustag static ${classNameForStatus()}`}>
          {tagLabel}
        </span>
        <i
          title={expanded ? 'close output details' : 'Open output details'}
          className={`icon-arrow-left bigger ${openRevealArrowClass}`}
        />
      </button>
    );
  };

  const renderHeaderDocumentLink = () => {
    const href = `/issued_outputs/${props.output.id}`;

    return <a href={href} target='_blank'>{truncate(props.output.name, 45)}</a>
  }

  const classNameForStatus = () => {
    if (props.output.revoked) {
      return 'revoked';
    } if (!props.output.issued) {
      return 'unissued';
    } if (props.output.expired) {
      return 'expired';
    }
    return 'valid';
  };

  const classNameForStyle = () => {
    if (props.style === 'mini') {
      return 'mini';
    }

    return '';
  };

  const renderRenewals = () => {
    const renderRenewal = (r) => {
      return <li className='margin-bottom-less'><a href={`/initiated_flows/manager/${r.initiated_flow_id}`}>{r.flow_template_name} #{r.initiated_flow_scoped_id}</a></li>;
    };

    return props.output.simple_renewal_info.map(renderRenewal);
  };

  const renderRenewalInfo = () => {
    const html = [];

    if (props.output.renewal) {
      html.push(
        <dl className='margin-bottom'>
          <dt>Renewal</dt>
          <dd>Originally issued via <a href={`/initiated_flows/${props.output.original_output.initiated_flow_id}`} className='secondary'>{props.output.original_output.flow_template_name} #{props.output.original_output.initiated_flow_scoped_id}</a></dd>
        </dl>,
      );
    }

    if (props.output.renewed) {
      const renewalCount = props.output.simple_renewal_info.length;

      html.push(
        <ExpandableContent linkText={`Renewed ${renewalCount} ${pluralize('time', renewalCount)}`}>
          <ul>{renderRenewals()}</ul>
        </ExpandableContent>,
      );
    }

    return html;
  };

  const [renewing, setRenewing] = useState(false);

  const showRevoke = useMemo(() => {
    return issued && isGov;
  }, [issued, isGov]);

  const renderOutputActionContent = () => {
    if (renewing) {
      return (
        <RenewalManager
          output={props.output}
          onCancelClick={() => { setRenewing(false); }}
          variation={props.style}
        />
      );
    }

    return (
      <ul className='output-actions'>
        { props.output.is_currently_renewable
            && (
              <li>
                <button
                  type='button'
                  onClick={(e) => { e.preventDefault(); setRenewing(true); }}
                >
                  Renew
                </button>
              </li>
            )}
        {showRevoke && (
          <li>
            <RevokeButton output={props.output} />
          </li>
        )}
        <li>
          {renderDownloadLink()}
        </li>
      </ul>
    );
  };

  const renderDownloadLink = () => {
    if (!props.output.revoked) {
      return (
        <OutputDownloadLink output={props.output} />
      );
    }
    return (
      <button
        type='button'
        disabled
        className='disabled output-downloadlink'
      >
        <span>Download</span>
      </button>
    );
  };

  function renderExpanded() {
    return (
      <div className={`output-mainwrap`}>

        {renderRenewalInfo()}

        <OutputDetails output={props.output} style={props.style} />

        <RenewalOptOutLink
          output={props.output}
          admin={CityGrows.Server.admin}
        />
        {renderOutputActionContent()}
      </div>
    );
  }

  return (
    <div className={`output ${classNameForStyle()} ${classNameForStatus()}`}>

      <div style={{ display: 'flex' }}>
        <i className='icon-doc' />
        <div className='doc-title'>
          {renderHeaderDocumentLink()}
        </div>

        {renderStatusTag()}
      </div>

      {expanded && renderExpanded()}
    </div>
  );
};

Output.propTypes = {
  output: PropTypes.shape({
    id: PropTypes.isRequired,
    expired: PropTypes.bool.isRequired,
    expires: PropTypes.bool.isRequired,
    expires_at: PropTypes.string.isRequired,
    is_currently_renewable: PropTypes.bool.isRequired,
    issued: PropTypes.bool.isRequired,
    issued_at: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    renewal: PropTypes.bool.isRequired,
    revoked: PropTypes.bool.isRequired,
  }).isRequired,
  style: PropTypes.string,
  isCustomResult: PropTypes.bool,
  collapse: PropTypes.bool,
};

Output.defaultProps = {
  style: 'normal',
  collapse: false,
};

export default Output;
