import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { createFilter } from 'react-select';
import Select from 'components/shared/select';
import {
  isMatch, uniq, has, isEmpty,
} from 'underscore';
import { selectValueForMapping } from 'modules/document-mapping-helper';

const Mapping = ({
  mapping,
  defaultMappables,
  index,
  onChange,
  onMenuOpen,
  onMenuClose,
  latestTemplateVersionNumber,
  documentStartVersion,
  isLast,
  documentStopVersion,
}) => {
  const defaultSelectValue = useMemo(() => {
    if (!mapping.id) { return; }

    return defaultMappables.find(({ value, label }) => {
      return isMatch(JSON.parse(value), selectValueForMapping(mapping));
    });
  }, [mapping, defaultMappables]);

  const formatOptionLabel = (o) => {
    let versionHTML = '';
    if (o.earliest_version) {
      const versionInfo = uniq([o.earliest_version, latestTemplateVersionNumber === o.latest_version ? 'Latest' : o.latest_version]).join(' - ');
      versionHTML = <><span className='versionnumber'>V({versionInfo})</span> </>;
    }

    return <>{versionHTML}{o.label}</>;
  };

  // TODO:
  // Ideally this would use the selected version rather
  // than the persisted version. I suspect this is good
  // enough for the time being though.

  const fullCoverage = useMemo(() => {
    const s = defaultSelectValue;

    if (!has(s, 'earliest_version') || !has(s, 'latest_version')) {
      return true;
    }

    if (isEmpty(documentStopVersion)) {
      return s.earliest_version <= documentStartVersion && s.latest_version >= latestTemplateVersionNumber;
    }

    return s.earliest_version <= documentStartVersion && s.latest_version >= documentStopVersion;
  }, [defaultSelectValue, documentStartVersion, documentStopVersion]);

  return (
    <li>
      <label>
        {mapping.field_name}
        <Select
          defaultValue={defaultSelectValue}
          onChange={(selection) => { onChange(selection, mapping.id, mapping.field_name); }}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          options={defaultMappables}
          className='react-select'
          classNamePrefix='react-select'
          menuPosition='fixed'
          maxMenuHeight={120}
          menuPlacement={isLast ? 'top' : 'auto'}
          filterOption={createFilter({
            ignoreCase: true,
            ignoreAccents: true,
            matchFrom: 'any',
            stringify: (option) => `${option.label} ${option.value}`,
            trim: true,
          })}
          formatOptionLabel={formatOptionLabel}
          styles={{
            container: (provided) => ({
              ...provided,
              minWidth: '10rem',
              maxWidth: '50rem',
            }),
          }}
        />
        <input
          value={mapping.id}
          type='hidden'
          name={`master_template_document[document_field_mappings_attributes][${index}][id]`}
        />
        <input
          value={mapping.field_name}
          type='hidden'
          name={`master_template_document[document_field_mappings_attributes][${index}][field_name]`}
        />

      </label>

      { !fullCoverage && (
        <div className='text-semialert strong'>Warning: This field does not exist on some covered form versions</div>
      )}
    </li>
  );
};

Mapping.propTypes = {
  mapping: PropTypes.shape({
    id: PropTypes.number,
    field_name: PropTypes.string.isRequired,
  }).isRequired,
  defaultMappables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  index: PropTypes.number.isRequired,
  isLast: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  document: PropTypes.shape({}).isRequired,
  latestTemplateVersionNumber: PropTypes.number.isRequired,
  documentStartVersion: PropTypes.number.isRequired,
  documentStopVersion: PropTypes.number.isRequired,
};

export default Mapping;
