import { useState, useEffect } from 'react';

import TagAPI from 'apis/tag-api';

const useLoadTags = () => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    TagAPI.index().
      done((res) => setTags(res.tags));
  }, []);

  return tags;
};

export default useLoadTags;
