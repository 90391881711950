import React from 'react';
import _ from 'underscore';
import dayjs from 'dayjs';

const AdminStepGroup = (props) => {
  const NO_DATA_SYMBOL = '---';

  const renderSteps = () => {
    return props.steps.map(renderStep);
  };

  const renderStep = (step) => {
    return (
      <li key={step.id}>{step.name}</li>
    );
  };

  const renderStepRange = () => {
    const firstPos = _.first(props.steps).position;
    const lastPos = _.last(props.steps).position;

    if (firstPos === lastPos) {
      return `Step ${firstPos}`;
    }
    return `Steps ${firstPos} - ${lastPos}`;
  };

  const renderMedianTime = () => {
    const times = props.digest?.median_step_times;

    // Return unless time is present for each step in group
    if (!times || !_.all(times, (t) => { return t; })) { return NO_DATA_SYMBOL; }

    const totalTime = props.steps.reduce((total, step) => {
      return total + Number(times[step.id]);
    }, 0);


    const duration = dayjs.duration(totalTime, 'seconds');
    return duration.humanize();
  };

  return (
    <div className='stepbreakdown-step city'>
      <div className='stepbreakdown-resp'>
        <dl>
          <dt>{props.cityName}</dt>
          <dd>{renderStepRange()}</dd>
        </dl>
      </div>

      <div className='stepbreakdown-stepmain'>
        <div className='stepbreakdown-skipinfo'>{props.steps.length} steps are not your responsibility</div>
        <div className='stepbreakdown-meta'>
          <div className='stepbreakdown-timeest' title='Median time to complete this group of steps'>{renderMedianTime()}</div>
        </div>

        <ul className='stepbreakdown-skippedsteps'>
          {renderSteps()}
        </ul>

      </div>
    </div>
  );
};

export default AdminStepGroup;
