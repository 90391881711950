import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import AdvancedFilter from '../shared/advanced-filter';
import { AdvancedFilterProvider } from '../shared/advanced-filter/context';
import { getOutputDimensions } from '../shared/advanced-filter/constants';
import DashboardAPI from 'apis/dashboard-api';
import ErrorBoundary from 'components/shared/error-boundary';
import LoadingSpinner from 'components/shared/loading-spinner';
import OutputsTable from './outputs-tab/table';
import useLoadMoreOutputs from '../hooks/use-load-more-outputs';
import AlertConfirm, { alert } from 'modules/alert-confirm';

const OUTPUTS_SORTS = [
  { value: 'date_issued_asc', label: 'Date issued (ascending)' },
  { value: 'date_issued_desc', label: 'Date issued (descending)' },
  { value: 'date_expire_asc', label: 'Expiration date (ascending)' },
  { value: 'date_expire_desc', label: 'Expiration date (descending)' },
];

const OutputsTab = (props) => {
  const [lastFilters, setLastFilters] = useState({});
  const [outputs, setOutputs] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const onSortChange = (filters) => {
    applyFilter(filters);
  };

  const loadMore = (rowIndex) => {
    const addItems = (newItems) => {
      setOutputs([...outputs, ...newItems]);
    }

    useLoadMoreOutputs(rowIndex, lastFilters, addItems);
  };

  const applyFilter = (filters) => {
    setIsLoading(true);
    return DashboardAPI.fetchOutputs(filters).done((res) => {
      setTotalCount(res.total_count);
      setOutputs(res.outputs);
    }).fail(() => {
      alert('Can\'t load dashboard content', 'We\'re having trouble loading your dashboard content. Please try again in a few moments.');
    }).always(() => {
      setLastFilters(filters);
      setIsLoading(false);
    });
  };

  const onActionClicked = (action) => {
    if (totalCount === 0) {
      alert(`Nothing to ${action}`, 'No outputs match your search criteria. Try changing the filters.');
      return;
    }

    switch(action) {
      case 'export':
        return handleExport();
      default:
        return null;
    }
  };

  const handleExport = () => {
    DashboardAPI.exportOutputs(lastFilters).
      done(() => { AlertConfirm.alert('Export queued.', "You will be emailed when your export completes. Finished exports can be found on the <a href='/exports'>Exports</a> page."); });
  };

  const renderSelectedDocumentsMessage = () => {
    return (
      <span className='margin-right'>
        {isLoading
          ? 'Loading...'
          : <>{totalCount} matching</>
        }
      </span>
    );
  };

  const renderMainContent = () => {
    if (isLoading) {
      return (
        <div className='dashboard-loading'>
          <LoadingSpinner size='large' />
        </div>
      );
    }

    if (totalCount > 0) {
      return (
        <OutputsTable
          outputs={outputs}
          totalCount={totalCount}
          loadMoreItems={loadMore}
          activeOrder={lastFilters.sort}
          sortingFn={onSortChange}
        />
      );
    }
  }

  return (
    <ErrorBoundary>
      <AdvancedFilterProvider initial={{ availableFilters: getOutputDimensions(), activeFilters: [], currentFilter: {}, savedFilters: props.savedFilters, defaultFilters: ['output_status'], triggerRefresh: 0  }}>
        <div className='padded border-bottom'>
          <AdvancedFilter
            onFilterApply={applyFilter}
            kind='output'
            menuAvailable
            defaultFilterIds={['output_status']}
            defaultFilter={props.defaultFilter}
          />
        </div>

        <div className='dashboard-maincontent'>
          <div className='dashboard-actionbar cf'>
            <div className='left'>
              <h1>Outputs</h1> <span className='dashboard-processcount'>{renderSelectedDocumentsMessage()}</span>
              </div>

              <div className='right'>
                <ul>
                  <li>
                    <button type='button' className='unstyled' onClick={() => onActionClicked('export')}>
                      <FontAwesomeIcon icon={icon({ name: 'file-export' })} />
                      <span>Export</span>
                    </button>
                  </li>
                </ul>
              </div>
          </div>

          {renderMainContent()}
        </div>
      </AdvancedFilterProvider>
    </ErrorBoundary>
  );
}

export default OutputsTab;
