var RequirementAPI = {
  get: function(requirement) {
    var url = '/supplemental_requirements/' + requirement.id;
    return $.ajax(url, {
      method: 'GET',
      dataType: 'json',
      data: {
        type: requirement.type
      }
    });
  },

  update: function(requirementId, attrs, type){
    var url = '/supplemental_requirements/' + requirementId;
    return $.ajax(url, {
      method: 'PUT',
      dataType: 'json',
      data: {
        supplemental_requirement: attrs,
        type: type
      }
    });
  }

  // NOT CURRENTLY BEING USED
  // delete: function(requirementId, type){
  //   var url = '/supplemental_requirements/' + requirementId;
  //   return $.ajax(url, {
  //     method: 'DELETE',
  //     dataType: 'json',
  //     data: {
  //       type: type
  //     }
  //   });
  // },
};

export default RequirementAPI;
