const RoleAPI = {
  show(roleId) {
    const url = `/roles/${roleId}`;

    return $.ajax(url, {
      method: 'GET',
      dataType: 'json',
    });
  },

  create(role, teamId, addUserToRole) {
    const url = `/teams/${teamId}/roles`;

    return $.ajax(url, {
      method: 'POST',
      dataType: 'json',
      data: {
        role,
        add_user_to_role: addUserToRole,
      },
    });
  },

  update(role, teamId, addUserToRole) {
    const url = `/teams/${teamId}/roles/${role.id}`;

    return $.ajax(url, {
      method: 'PUT',
      dataType: 'json',
      data: {
        role,
        addUserToRole,
      },
    });
  },

  destroy(roleId, teamId) {
    const url = `/teams/${teamId}/roles/${roleId}`;

    return $.ajax(url, {
      method: 'DELETE',
      dataType: 'json',
    });
  },

  attach(attachableId, attachableType, roleId) {
    const url = '/attachments/toggle';

    return $.ajax(url, {
      method: 'PUT',
      dataType: 'JSON',
      data: {
        attachableId,
        attachableType,
        attachmentId: roleId,
        attachmentType: 'Role',
      },
    });
  },

  search(teamId, opts) {
    const url = `/teams/${teamId}/roles/search`;

    return $.ajax(url, {
      method: 'GET',
      dataType: 'json',
      data: {
        search_term: opts.searchTerm,
        user_id: opts.userId,
      },
    });
  },
};

export default RoleAPI;
