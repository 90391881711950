import React, { useEffect, useState } from "react";

import SourceFilterActions from 'actions/dashboard/source-filter-actions';
import Select from "../select";
import { IMPORTED_OPTIONS } from "./constants";

const DEFAULT_OPTION = { id: 'all', label: 'All' };


const ImportedSelect = ({
  changeFilterValue,
  changeFilterValueOptions,
  filterState,
  minify,
}) => {
  const [dataImports, setDataImports] = useState(null);
  const optionValue = IMPORTED_OPTIONS.find((opt) => opt.id === filterState.value);

  useEffect(() => {
    SourceFilterActions.loadSources()
     .done(setDataImports);
  }, []);

  const formattedSources = dataImports
    ? [
      DEFAULT_OPTION,
      ...dataImports.map(({ id, name }) => ({
        id: id,
        label: name,
      })),
    ]
    : [DEFAULT_OPTION];

  const onImportSelectChange = (value) => {
    changeFilterValue(value.id);
  };

  const onImportedSourceChange = (value) => {
    changeFilterValueOptions({
      source_id: value.id
    });
  };

  const renderImportedSourceSelect = () => {
    return (
      <div className='metricsdashboard-filterselect'>
        <Select
          options={formattedSources}
          onChange={onImportedSourceChange}
          getOptionValue={(opt) => opt.id}
          value={formattedSources.find((opt) => opt.id === Number(filterState.options.source_id))}
          className='react-select-metrics advanced-filter'
        />
      </div>
    );
  }

  return (
    <>
      <div className='metricsdashboard-filterselect'>
        <Select
          options={IMPORTED_OPTIONS}
          onChange={onImportSelectChange}
          getOptionValue={(opt) => opt.id}
          defaultValue={optionValue}
          className='react-select-metrics advanced-filter'
        />
      </div>
      {(filterState.value == 'specific_import') && renderImportedSourceSelect()}
    </>
  );
};

export default ImportedSelect;
