export const create = (masterTemplateId, formData) => {
  const url = `/master_templates/${masterTemplateId}/documents`;

  return $.ajax(url, {
    dataType: 'json',
    method: 'POST',
    data: formData,
    processData: false,
    cache: false,
    contentType: false
  });
};

export const update = (id, masterTemplateId, formData) => {
  const url = `/master_templates/${masterTemplateId}/documents/${id}`;

  return $.ajax(url, {
    dataType: 'json',
    method: 'PUT',
    data: formData,
    processData: false,
    cache: false,
    contentType: false
  });
};

export const replace = (id, masterTemplateId, formData) => {
  const url = `/master_templates/${masterTemplateId}/documents/${id}/replace`;

  return $.ajax(url, {
    dataType: 'json',
    method: 'PUT',
    data: formData,
    processData: false,
    cache: false,
    contentType: false
  });
};

export const destroy = (slug, id) => {
  const url = `/master_templates/${slug}/documents/${id}`;

  return $.ajax(url, {
    dataType: 'json',
    method: 'DELETE',
  });
};

export const publish = (masterTemplateId, id) => {
  const url = `/master_templates/${masterTemplateId}/documents/${id}/publish`;

  return $.ajax(url, {
    dataType: 'json',
    method: 'POST'
  });
};

export const unpublish = (masterTemplateId, id) => {
  const url = `/master_templates/${masterTemplateId}/documents/${id}/unpublish`;

  return $.ajax(url, {
    dataType: 'json',
    method: 'POST'
  });
};

export const fetchMappables = (masterTemplateId, documentId, query) => {
  const url = `/master_templates/${masterTemplateId}/documents/${documentId}/fetch_mappables`;

  return $.get(url, {
    query,
  });
};

export default {
  create,
  update,
  destroy,
  fetchMappables
};
