import { useRef, useEffect } from 'react';

const useKeyDown = (callback) => {
  const targetRef = useRef();
  const keydownRef = useRef();
  keydownRef.current = callback;
  useEffect(() => {
    // NOTE: this indirection looks weird but is necessary. This provides
    // a locally stable function reference for registering and unregistering
    // the keybind, while still allowing the keydownRef's value to change.
    const keydownCallback = (e) => keydownRef.current(e);
    const target = targetRef.current;
    target.addEventListener('keydown', keydownCallback);
    return () => target.removeEventListener('keydown', keydownCallback);
  }, []);
  return targetRef;
};

export default useKeyDown;
