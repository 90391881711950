import React from 'react';
import EditSuggestionsAPI from 'apis/edit-suggestions-api';

class EditSuggestionsRequest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addingPOC: false,
      updating: false,
      suggestions: this.props.request.suggestions,
    };
  }

  authToken() {
    return $('meta[name=csrf-token]').attr('content');
  }

  toggleAddPOC = () => {
    this.setState({ addingPOC: !this.state.addingPOC });
  };

  rejectChange = (e) => {
    this.setState({ updating: true });
    EditSuggestionsAPI.rejectSuggestion(this.props.request.id, e.currentTarget.dataset.id)
      .done((res) => this.setState({ suggestions: res }))
      .always(() => { this.setState({ updating: false }); });
  };

  cancel = (e) => {
    e.preventDefault();
    this.props.deSelectRequest();
  };

  renderSuggestion(suggestion) {
    return (
      <li className='editsuggestionsrequest-suggestion' key={suggestion.id}>
        <div className='editsuggestionsrequest-fieldname'>
          {suggestion.field.replace(/_/g, ' ')}
        </div>
        <div className='editsuggestionsrequest-suggestioncontent'>

          <div>
            <div className='flex'>
              <div className='editsuggestionsrequest-label suggested'>Suggested</div>
              <div className='editsuggestionsrequest-value suggested'>{suggestion.new_content}</div>
            </div>

            <div className='flex'>
              <div className='editsuggestionsrequest-label current'>Previous value</div>
              <div className='editsuggestionsrequest-value current'>{suggestion.current_content}</div>
            </div>
          </div>

        </div>

        <div className='editsuggestionsrequest-rejectsuggestion'>
          <button className='btn-link alert' data-id={suggestion.id} onClick={this.rejectChange}>Reject this change</button>
        </div>

      </li>
    );
  }

  renderChanges() {
    return (
      <ul>
        {this.state.suggestions.map((suggestion) => {
          return this.renderSuggestion(suggestion);
        })}
      </ul>
    );
  }

  renderPocAdd() {
    return (
      <>
        <input id='poc' type='checkbox' checked={this.state.addingPOC} onChange={this.toggleAddPOC} />
        <label className='inline-block' htmlFor='poc'>Add {this.props.request.user.name} ({this.props.request.user.email}) as a point of contact for {this.props.request.entity_name}</label>
      </>
    );
  }

  render() {
    if (this.state.suggestions.length == 0) {
      return (
        <div className='align-center'>
          <p>All suggestions on this request have been resolved.</p>
          <a className='btn btn-secondary' href={`/teams/${this.props.teamId}/edit_suggestions_requests`}>Go Back</a>
        </div>
      );
    }
    return (
      <div className='margin-top'>
        <p>{this.props.request.user.name} wants to make the following changes to <span className='bold'>{this.props.request.entity_name}</span>:</p>
        <div className='border-top'>
          {this.renderChanges()}
        </div>
        <div className='margin-top-more'>
          {!this.props.request.user.is_poc && this.renderPocAdd() }
        </div>
        <div className='align-center padding-top'>
          <form id='form'>
            <input type='hidden' name='adding_poc' value={this.state.addingPOC} />
            <input type='hidden' name='authenticity_token' value={this.authToken()} />
          </form>
          <button className='btn-link alert margin-right-more' type='submit' form='form' formMethod='POST' formAction={`/edit_suggestions_requests/${this.props.request.id}/reject_request`}>Reject all changes</button>
          <button
            className='btn-primary'
            type='submit'
            form='form'
            formMethod='POST'
            formAction={`/edit_suggestions_requests/${this.props.request.id}/accept_request`}
          >
            Accept all changes
          </button>
        </div>
      </div>
    );
  }
}

export default EditSuggestionsRequest;
