export const create = (stepId, assigneeId, assigneeType) => {
  const url = `/steps/${stepId}/step_assignment_groups`;
  return $.ajax(url, {
    method: 'POST',
    dataType: 'json',
    data: {
      step_assignment_group: {
        step_assignments_attributes: [{
          assignee_id: assigneeId,
          assignee_type: assigneeType,
        }],
      },
    },
  });
};

export const createPrevAssignees = (stepId) => {
  // Create an empty assignment group with
  // delete_old_assignments = false. Preserves
  // assignees from the previous step.

  const url = `/steps/${stepId}/step_assignment_groups`;
  return $.ajax(url, {
    method: 'POST',
    dataType: 'json',
    data: {
      step_assignment_group: {
        delete_old_assignments: false,
      },
    },
  });
};

export const destroy = (stepAssignmentGroupId) => {
  const url = `/step_assignment_groups/${stepAssignmentGroupId}`;
  return $.ajax(url, {
    method: 'DELETE',
    dataType: 'json',
  });
};

export default {
  create,
  createPrevAssignees,
  destroy,
};
