import React from 'react';
import renderer from 'modules/renderer';
import AdminRolesContainer from 'components/admin-roles-container';
import PrimaryTabs from '../components/shared/primary-tabs';

function index() {
  renderer(<PrimaryTabs />, document.getElementById('tabs'));
  renderer(<AdminRolesContainer roles={CityGrows.Server.roles}
                                       workflows={CityGrows.Server.workflows}
                                       teamId={CityGrows.Server.teamId}
                                       currentUserId={CityGrows.Server.currentUserId}/>, document.getElementById('roles'));
}

function knew() {
  renderer(<AdminRolesContainer roles={CityGrows.Server.roles}
                                       workflows={CityGrows.Server.workflows}
                                       teamId={CityGrows.Server.teamId}
                                       roleName={CityGrows.Server.roleName}
                                       referrerLink={CityGrows.Server.referrerLink}
                                       currentUserId={CityGrows.Server.currentUserId}/>, document.getElementById('roles'));
}

function show() {
  renderer(<AdminRolesContainer roles={CityGrows.Server.roles}
                                       workflows={CityGrows.Server.workflows}
                                       teamId={CityGrows.Server.teamId}
                                       role={CityGrows.Server.role}
                                       currentUserId={CityGrows.Server.currentUserId}/>, document.getElementById('roles'));
}

export default {
  index: index,
  new: knew,
  show: show
};
