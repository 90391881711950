import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SignaturePad from 'react-signature-canvas';
import Modal from 'components/shared/modal';
import ReviewActions from 'actions/review-actions';
import _ from 'underscore';

const SubmitReviewWidget = (props) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [name, setName] = useState('');
  const [signed, setSigned] = useState(false);
  const signaturePad = useRef();

  useEffect(() => {
    if (signaturePad.current) {
      signaturePad.current.off();
    }
  }, []);

  function addText() {
    setSigned(false);
    const canvas = signaturePad.current.getCanvas();
    signaturePad.current.clear();

    const ctx = canvas.getContext('2d');
    ctx.font = '30px Brush Script MT, cursive';
    ctx.fillText(name, 200, 40);
    if (name.length > 0) { 
      setError(false); 
      setSigned(true);
    }
  }

  function reviewText() {
    if (!!props.affidavit && props.affidavit.length > 0) {
      return props.affidavit;
    }
    return 'I certify that I have reviewed this submission';
  }

  function onCancelClick(e) {
    e.preventDefault();

    props.onClose();
  }

  function authorizeSubmit() {
    if (submitting) { return false; }
  
    if (signatureRequired() && !signed) {
      setError(true);
      return false;
    }

    return true;
  }

  function onSubmitReview(e) {
    e.preventDefault();

    if (!authorizeSubmit()) { return; }

    setSubmitting(true);

    const signatureData = signatureRequired()
      ? signaturePad.current.getTrimmedCanvas().toDataURL()
      : null;
    const { roleId } = props;
    ReviewActions.approve(props.reviewGroupId, props.activeStepId, props.approvalStatus, {
      comment: props.comment,
      signatureData,
      roleId,
    })
    .done(onReviewSubmitComplete)
    .fail(onClose);
  }

  function onClose() {
    if (_.isFunction(props.onClose)) { props.onClose(); }
  }

  function onReviewSubmitComplete(res) {
    setSubmitting(false);

    onClose();
    if (_.isFunction(props.onReviewComplete)) { props.onReviewComplete(res); }
  }

  function signatureRequired() {
    const signatureRequiredStatuses = [
      'approve',
      'reject',
    ];

    return props.requireSignature && _.contains(signatureRequiredStatuses, props.approvalStatus);
  }

  function renderError() {
    if (error) {
      return <div className='text-alert margin-top-less'>A signature is required in order to continue</div>;
    }
  }

  function renderSignaturePad() {
    if (signatureRequired()) {
      return (
        <>
          <SignaturePad
            canvasProps={{ width: 600, height: 50, className: 'sigCanvas' }}
            ref={signaturePad}
            minWidth={2}
            maxWidth={5}
          />
          {renderError()}
        </>
      );
    }
  }

  function reviewSubmitButtonText() {
    switch (props.approvalStatus) {
    case 'approve':
      return 'Approve';
    case 'reject':
      return 'Reject and halt';
    case 'feedback':
      return 'Request changes';
    default:
      throw `Unrecognized approval status ${props.approvalStatus}`;
    }
  }

  function submitButtonClass() {
    const classes = ['btn-primary', 'float-right'];
    if (submitting) { classes.push('disabled'); }
    return classes.join(' ');
  }

  function renderSignatureButtons() {
    return (
      <>
        <label className='inline-block'>Name
          <input type='text' placeholder='Enter Name' onChange={(e) => { setName(e.target.value); }} value={name} />
        </label>
        <button className='btn-primary inline-block' type='submit' onClick={addText}>Sign</button>
      </>
    );
  }

  return (
    <Modal onOutsideClick={onClose}>
      <div style={{ minWidth: '50vw' }}>
        <h1>Review</h1>
        <p dangerouslySetInnerHTML={{ __html: reviewText() }} />

        {renderSignaturePad()}
        <div className='linebreak' />

        {signatureRequired() && renderSignatureButtons()}
        <div>
          <div className='float-right'>
            <button className={submitButtonClass()} onClick={onSubmitReview} >{reviewSubmitButtonText()}</button>
            <button onClick={onCancelClick} className='btn-secondary margin-right'>Cancel</button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

SubmitReviewWidget.propTypes = {
  onReviewComplete: PropTypes.func,
  onClose: PropTypes.func,
  requireSignature: PropTypes.bool.isRequired,
  reviewGroupId: PropTypes.number.isRequired,
  activeStepId: PropTypes.number.isRequired,
  affidavit: PropTypes.string,
};

export default SubmitReviewWidget;
