import Constants from 'constants/initiated-flow-constants';
import ActiveStepConstants from 'constants/active-step-constants';
import SurveySubmissionConstants from 'constants/survey-submission-constants';
import ActivityLogConstants from 'constants/activity-log-constants';
import { findWhere, reject, findIndex, map } from 'underscore';

function initiatedFlowReducer(state, action) {
  let step = {};
  let datum = {};
  let index = null;
  let reqsDupe = null;

  switch (action.type) {
  case Constants.SET_REFRESHED:
    return { ...state, refreshing: false };
  case Constants.REQUIREMENT_UPDATED:
    index = findIndex(state.initiatedFlow.requirements, { id: action.requirement.id });
    let reqDupe = { ...state.initiatedFlow.requirements[index], ...action.requirement };
    reqsDupe = [
      ...state.initiatedFlow.requirements.slice(0, index),
      reqDupe,
      ...state.initiatedFlow.requirements.slice(index + 1),
    ];

    return { ...state, initiatedFlow: { ...state.initiatedFlow, requirements: reqsDupe }, step: reqDupe };
  case Constants.PAYMENT_UPDATED:
    step = { ...state.step, payment: action.payment, completed: action.payment.completed };
    index = findIndex(state.initiatedFlow.requirements, { id: action.payment.payable_id });
    reqsDupe = [
      ...state.initiatedFlow.requirements.slice(0, index),
      { ...state.initiatedFlow.requirements[index], payment: action.payment, completed: action.payment.completed },
      ...state.initiatedFlow.requirements.slice(index + 1),
    ];
    return { ...state, step, initiatedFlow: { ...state.initiatedFlow, requirements: reqsDupe } };
  case Constants.PAYMENT_MADE:
    step = { ...state.step, completed: action.completed, payment: { ...state.step.payment, completed: action.completed, charges: [...state.step.payment.charges, action.charge] } };
    index = findIndex(state.initiatedFlow.requirements, (requirement) => { return requirement.payment.id === action.charge.chargeable_id; });
    reqDupe = { ...state.initiatedFlow.requirements[index] };
    reqsDupe = [
      ...state.initiatedFlow.requirements.slice(0, index),
      { ...reqDupe, payment: { ...reqDupe.payment, completed: action.completed, charges: [...reqDupe.payment.charges, action.charge] }, completed: action.completed },
      ...state.initiatedFlow.requirements.slice(index + 1),
    ];

    return { ...state, step, initiatedFlow: { ...state.initiatedFlow, requirements: reqsDupe } };
  case Constants.COLLECTED_DATA_CHANGED:
    datum = findWhere(state.activeStep.collected_data, { step_datum_id: action.stepDatumId });
    if (!datum) {
      datum = { step_datum_id: action.stepDatumId, content: action.newValue };
      state.activeStep.collected_data.push(datum);
    } else {
      datum.content = action.newValue;
    }

    return { ...state, outstandingSaveRequests: state.outstandingSaveRequests + 1, changePending: false };
  case Constants.COLLECTED_DATA_WILL_CHANGE:
    return { ...state, changePending: true };
  case Constants.DELETE_ATTACHED_FILE:
    datum = findWhere(state.activeStep.collected_data, { step_datum_id: action.stepDatumId });
    datum.files = reject(datum.files, { id: action.fileId });
    return { ...state, refreshing: true };
  case ActiveStepConstants.SYNC_ACTIVE_STEP:
    return { ...state, activeStep: action.activeStep };
  case ActiveStepConstants.CURRENT_ACTIVE_STEP_UPDATED:
    return { ...state, activeStep: { ...state.activeStep, ...action.attrs }, refreshingAttachments: true };
  case ActiveStepConstants.UPDATED:
    const activeStepIndex = findIndex(state.initiatedFlow.active_steps, { id: action.activeStepId });
    const activeStepDupe = { ...state.initiatedFlow.active_steps[activeStepIndex], ...action.attrs };
    let currentActiveStep = { ...state.activeStep };
    if (state.activeStep.id === action.activeStepId) {
      currentActiveStep = { ...currentActiveStep, ...action.attrs };
    }
    return {
      ...state,
      activeStep: currentActiveStep,
      initiatedFlow: {
        ...state.initiatedFlow,
        active_steps: [
          ...state.initiatedFlow.active_steps.slice(0, activeStepIndex),
          activeStepDupe,
          ...state.initiatedFlow.active_steps.slice(activeStepIndex + 1),
        ],
      },
    };
  case ActiveStepConstants.CHANGES_PERSISTED:
    return {
      ...state,
      outstandingSaveRequests: state.outstandingSaveRequests - 1,
      refreshing: true, refreshingAttachments: true,
      reviewSubmitted: action.reviewSubmitted,
    };
  case Constants.COLLECTED_DATA_ERROR:
    const stepDatum = findWhere(state.activeStep.collected_data, {
      step_datum_id: action.stepDatumId,
    });

    if (stepDatum.data_type === 'file') {
      stepDatum.content = null;
    }
    return { ...state, outstandingSaveRequests: null };
  case SurveySubmissionConstants.INVITES_ADDED:
    return { ...state, activeStep: { ...state.activeStep, survey_invitations: state.activeStep.survey_invitations.concat(action.invites) } };
  case SurveySubmissionConstants.DESTROYED:
    return { ...state, activeStep: { ...state.activeStep, survey_submissions: reject(state.activeStep.survey_submissions, { id: action.id }) } };
  case ActivityLogConstants.LOADED:
    return { ...state, activities: action.activities };
  case Constants.TAG_ADDED: {
  // This tag already exists. At time of writing,
    // this only occurs when attempting to add a
    // renewal tag to a process that already has it.

    if (findWhere(state.initiatedFlow.taggings, { tag_name: action.tagging.tag_name })) {
      return;
    }

    const taggingsDupe = [...state.initiatedFlow.taggings, action.tagging];
    let suggestedTags = [...state.initiatedFlow.suggested_tags];
    if (!findWhere(state.initiatedFlow.suggested_tags, { id: action.tagging.tag_id })) {
      const { tag } = action.tagging;
      tag.tagging_count = 1;
      suggestedTags = [...suggestedTags, tag];
    } else {
      const tagToUpdate = findWhere(state.initiatedFlow.suggested_tags, { id: action.tagging.tag_id });
      tagToUpdate.tagging_count += 1;
      const otherSuggestedTags = reject(state.initiatedFlow.suggested_tags, { id: action.tagging.tag_id });
      suggestedTags = [...otherSuggestedTags, tagToUpdate];
    }

    return {
      ...state,
      initiatedFlow: {
        ...state.initiatedFlow,
        taggings: taggingsDupe,
        suggested_tags: suggestedTags,
      },
    };
  }
  case Constants.TAG_UPDATED: {
    const otherSuggestedTags = reject(state.initiatedFlow.suggested_tags, { id: action.tag.id });

    const taggingToUpdate = findWhere(state.initiatedFlow.taggings, { tag_id: action.tag.id });
    const otherTaggings = taggingToUpdate ? reject(state.initiatedFlow.taggings, taggingToUpdate) : state.initiatedFlow.taggings;

    if (taggingToUpdate) {
      taggingToUpdate.tag_name = action.tag.name;
      taggingToUpdate.tag.name = action.tag.name;
    }

    const updatedTaggings = taggingToUpdate ? [...otherTaggings, taggingToUpdate] : otherTaggings;

    return {
      ...state,
      initiatedFlow: {
        ...state.initiatedFlow,
        taggings: updatedTaggings,
        suggested_tags: [...otherSuggestedTags, action.tag],
      },
    };
  }
  case Constants.TAG_REMOVED: {
    const taggingToRemove = findWhere(state.initiatedFlow.taggings, { id: action.taggingId });
    const updatedTaggings = reject(state.initiatedFlow.taggings, { id: action.taggingId });
    const tagToUpdate = findWhere(state.initiatedFlow.suggested_tags, { id: taggingToRemove.tag_id });
    tagToUpdate.tagging_count -= 1;
    const otherSuggestedTags = reject(state.initiatedFlow.suggested_tags, { id: taggingToRemove.tag_id });
    const suggestedTags = [...otherSuggestedTags, tagToUpdate];

    return { ...state, initiatedFlow: { ...state.initiatedFlow, taggings: updatedTaggings, suggested_tags: suggestedTags } };
  }
  case Constants.TAG_DELETED: {
    const updatedTaggings = reject(state.initiatedFlow.taggings, { tag_id: action.tagId });
    const updatedTags = reject(state.initiatedFlow.suggested_tags, { id: action.tagId });
    return { ...state, initiatedFlow: { ...state.initiatedFlow, taggings: updatedTaggings, suggested_tags: updatedTags } };
  }
  case Constants.ASSIGNED_PEOPLE_UPDATED:
    if (action.personAttachment.persisted) {
      state.initiatedFlow.person_attachments.push(action.personAttachment);
    } else {
      state.initiatedFlow.person_attachments = reject(state.initiatedFlow.person_attachments, { id: action.personAttachment.id, type: action.personAttachment.type });
    }
    return { ...state };
  case Constants.ASSIGNMENT_UPDATED:
    index = findIndex(state.initiatedFlow.person_attachments, { id: action.personAttachment.id, type: action.personAttachment.type });
    const attachmentsDup = [
      ...state.initiatedFlow.person_attachments.slice(0, index),
      { ...state.initiatedFlow.person_attachments[index], ...action.personAttachment },
      ...state.initiatedFlow.person_attachments.slice(index + 1),
    ];
    return { ...state, initiatedFlow: { ...state.initiatedFlow, person_attachments: attachmentsDup } };
  case Constants.HALTED:
    return { ...state, initiatedFlow: { ...state.initiatedFlow, halted: true } };
  case Constants.OUTPUT_UPDATED:
    index = findIndex(state.initiatedFlow.issued_outputs, { id: action.attrs.id });
    const outputsDupe = [
      ...state.initiatedFlow.issued_outputs.slice(0, index),
      { ...state.initiatedFlow.issued_outputs[index], ...action.attrs },
      ...state.initiatedFlow.issued_outputs.slice(index + 1),
    ];
    return { ...state, initiatedFlow: { ...state.initiatedFlow, issued_outputs: outputsDupe } };
  case Constants.SUBPROCESS_REQUIREMENT_UPDATED:
    index = findIndex(state.initiatedFlow.subprocess_requirements, { id: action.attrs.id });
    const subprocessReqDupe = [
      ...state.initiatedFlow.subprocess_requirements.slice(0, index),
      { ...state.initiatedFlow.subprocess_requirements[index], ...action.attrs },
      ...state.initiatedFlow.subprocess_requirements.slice(index + 1),
    ];
    return { ...state, initiatedFlow: { ...state.initiatedFlow, subprocess_requirements: subprocessReqDupe } };
  case Constants.ADVANCE_ATTEMPTED:
    return { ...state, attemptedAdvance: true };
  case Constants.SYNC_ATTACHMENTS:
    return { ...state, initiatedFlow: { ...state.initiatedFlow, person_attachments: action.attachments }}
  case Constants.SET_ATTACHMENTS_REFRESHED:
    return { ...state, refreshingAttachments: false, reviewSubmitted: false };
  case Constants.APPLICANT_LIST_UPDATED:
    if (action.saved) {
      state.initiatedFlow.applicant_attachments.push(action.applicant);
    } else {
      state.initiatedFlow.applicant_attachments = reject(state.initiatedFlow.applicant_attachments, { id: action.applicant.id });
    }
    return { ...state };
  case Constants.PRIMARY_APPLICANT_CHANGED:
    state.initiatedFlow.applicant_attachments = map(state.initiatedFlow.applicant_attachments, (applicant) => {
      const a = { ...applicant };
      a.is_primary = a.id === action.applicant.id;
      return a;
    });
    return { ...state };
  default:
    throw new Error(`${action.type}`);
  }
}

export default initiatedFlowReducer;
