const AttachmentAPI = {
  toggle(attachableType, attachableId, subjectId, subjectType, opts) {
    const url = '/attachments/toggle';
    return $.ajax(url, {
      data: {
        subject_id: subjectId,
        subject_type: subjectType,
        attachable_id: attachableId,
        attachable_type: attachableType,
        user_to_add: opts.userToAdd,
      },
      method: 'POST',
      dataType: 'json',
    });
  },

  update(id, attrs) {
    const url = `/attachments/${id}`;
    return $.ajax(url, {
      data: {
        attachment: attrs,
      },
      method: 'PUT',
      dataType: 'json',
    });
  },

  removeAttachment(attachmentId) {
    const url = `/attachments/${attachmentId}`;
    return $.ajax(url, {
      method: 'DELETE',
      dataType: 'json',
    });
  },

  addPseudomember(email, name, attachableType, attachableId, opts) {
    const url = '/attachments/add_pseudomember';
    return $.ajax(url, {
      data: {
        email,
        name,
        attachable_type: attachableType,
        attachable_id: attachableId,
        role_id: opts.roleId,
      },
      method: 'POST',
      dataType: 'json',
    });
  },

  fetchAttachments(attachableType, attachableId) {
    const url = '/attachments/fetch_attachments';
    return $.ajax(url, {
      data: {
        attachable_type: attachableType,
        attachable_id: attachableId
      },
      method: 'GET',
      dataType: 'json'
    })
  },
};

export default AttachmentAPI;
