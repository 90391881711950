import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { formatIncoming, formatOutgoing, dateFormats } from 'modules/date-formatter';
import { isFunction } from 'underscore';

const DateRangeSelector = (props) => {
  const startDate = formatIncoming(props.startDate);
  const endDate = formatIncoming(props.endDate);

  const [ignoreClose, setIgnoreClose] = useState(!props.isStart);

  const onSelect = (date) => {
    props.onDateChange(formatOutgoing(date, props.type));
  };

  const handleOnBlur = () => {
    if (isFunction(props.onBlur)) {
      props.onBlur();
    }
  };

  const onClose = () => {
    if (!ignoreClose) {
      document.getElementById(props.endId).focus();
    }

    setIgnoreClose(!props.isStart);
  };

  const onClickOutside = () => {
    setIgnoreClose(true);
  };

  return (
    <DatePicker
      ref={props.innerRef}
      id={props.id}
      onBlur={handleOnBlur}
      selected={formatIncoming(props.value, props.type)}
      selectsStart={props.isStart}
      selectsEnd={!props.isStart}
      className={props.className}
      onCalendarClose={onClose}
      onClickOutside={onClickOutside}
      onChange={onSelect}
      dateFormat={dateFormats(props.type)}
      placeholderText='All Dates'
      showTimeSelect={props.type !== 'date'}
      showTimeSelectOnly={props.type === 'time'}
      timeIntervals={15}
      showMonthDropdown
      showYearDropdown
      dropdownMode='select'
      startDate={props.type === 'time' ? null : startDate}
      endDate={props.type === 'time' ? null : endDate}
    />
  );
};

export default DateRangeSelector;
