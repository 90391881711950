import React, { useState } from "react";
import DateSelector from 'components/shared/date-selector';


const DateRange = ({
  changeFilterValue,
  fromToValues,
  minify,
}) => {
  const [from, setFrom] = useState(fromToValues[0]);
  const [to, setTo] = useState(fromToValues[1]);

  const updateFrom = (value) => {
    setFrom(value);
    changeFilterValue([value, to]);
  };

  const updateTo = (value) => {
    setTo(value);
    changeFilterValue([from, value]);
  };

  const renderMainContent = () => {
    return (
      <>
        <div className='metricsdashboard-datepicker advanced'>
          <DateSelector
            value={from}
            onDateChange={updateFrom}
            type='date'
            placeholderText='from...'
            withPortal={false}
          />
        </div>
        <span>and</span>
        <div className='metricsdashboard-datepicker advanced'>
          <DateSelector
            value={to}
            onDateChange={updateTo}
            type='date'
            placeholderText='to...'
            withPortal={false}
          />
        </div>
      </>
    );
  };

  const renderMinify = () => {
    return (
      <>
        <span>
          {from || '...'}
        </span>
        <span className="margin-left margin-right">to</span>
        <span>
          {to || '...'}
        </span>
      </>
    );
  };

  return minify ? renderMinify() : renderMainContent();
};

export default DateRange;
