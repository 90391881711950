import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/shared/select';
import EntityConfigurationActions from 'actions/entity-configuration-actions';


const CreateDialog = ({
  teamId,
  dispatch,
  onEntityCreated,
  esriIsEnabled,
}) => {

  let options = [
    {
      value: false,
      label: 'Standard entity',
    }
  ];
  
  if (esriIsEnabled) {
    options.push({
      value: true,
      label: 'ESRI entity',
    });
  }

  const [isESRI, setIsESRI] = useState(false);
  const [serviceURL, setServiceURL] = useState();

  const onClick = () => {
    EntityConfigurationActions.createEntity(teamId, isESRI, serviceURL, dispatch)
                              .done(onEntityCreated);
  };

  return (
    <div
      className='well padded margins nomargin-top margin-bottom-more'
    >
      <Select
        defaultValue={options[0]}
        allow
        options={options}
        onChange={(newVal) => setIsESRI(newVal.value)}
      />
      {isESRI && (
        <input
          type='text'
          id='serviceurl'
          placeholder='ESRI service URL'
          onChange={(e) => { setServiceURL(e.currentTarget.value) }}
          value={serviceURL}
        />
      )}
      <button
        type='button'
        className='btn-primary margin-top'
        id='createbutton'
        onClick={onClick}
      >
        Create
      </button>
    </div>
  );
};

CreateDialog.propTypes = {
  onEntityCreated: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  teamId: PropTypes.number.isRequired,
  esriIsEnabled: PropTypes.bool.isRequired,
};

CreateDialog.defaultProps = {
  onEntityCreated: () => {},
}

export default CreateDialog;
