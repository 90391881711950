import { useState, useEffect } from 'react';

import FilterActions from 'actions/filter-actions';

const useLoadTemplates = () => {
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    FilterActions.loadTemplates()
      .done((data) => {
        setTemplates(data)
      });
  }, []);

  return templates;
}

export default useLoadTemplates;
