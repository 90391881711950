import csrfReset from './helpers/csrf-reset';

const UserAPI = {
  search(terms, opts = {}) {
    const url = '/users/search';
    const request = $.ajax(url, {
      data: {
        q: terms,
        initiated_flow_id: opts.initiatedFlowId,
        gov_only: opts.govOnly,
      },
      method: 'GET',
      dataType: 'json',
    });

    return request;
  },

  exists(email) {
    const url = '/users/exists';
    const request = $.ajax(url, {
      data: {
        q: email,
      },
      method: 'GET',
      dataType: 'json',
    });

    return request;
  },

  login(email, password) {
    const url = '/users/sign_in';
    const request = $.ajax(url, {
      data: {
        user: {
          email,
          password,
        },
      },
      method: 'POST',
      dataType: 'json',
      success: csrfReset,
    });

    return request;
  },

  signup(email, password, name, accountType) {
    const url = '/users';
    const request = $.ajax(url, {
      data: {
        user: {
          email,
          password,
          name,
          account_type: accountType,
        },
      },
      method: 'POST',
      dataType: 'json',
    });

    return request;
  },

  update(user) {
    const url = '/users/update';
    return $.ajax(url, {
      dataType: 'json',
      method: 'PATCH',
      data: {
        user,
      },
    });
  },

  updateAuthMetaData(data) {
    const url = '/auth/update';
    return $.ajax(url, {
      dataType: 'json',
      method: 'POST',
      data: {
        data,
      },
    });
  },

  uploadAvatar(file) {
    const formData = new FormData();
    formData.append('avatar', file);
    const url = '/users/update';
    return $.ajax(url, {
      dataType: 'json',
      method: 'PATCH',
      data: formData,
      processData: false,
      contentType: false,
    });
  },

  sendVerificationEmail() {
    const url = '/users/send_verification_email';
    return $.ajax(url, {
      dataType: 'json',
      method: 'POST',
    });
  },
};

export default UserAPI;
