var PaymentAPI = {
  update: function(paymentId, attrs){
    var url = '/payments/' + paymentId;
    return $.ajax(url, {
      method: 'PUT',
      dataType: 'json',
      data: {
        payment_data: attrs
      }
    });
  },

  receiveCheck: function(paymentId, attrs){
    var url = `/payments/${paymentId}/receive_check`;
    return $.ajax(url, {
      method: 'POST',
      dataType: 'json',
      data: {
        payment_data: { check_paid_amount: attrs.amount, check_number: attrs.checkNumber }
      }
    });
  },

  receiveCash: function(paymentId, amount){
    var url = `/payments/${paymentId}/receive_cash`;
    return $.ajax(url, {
      method: 'POST',
      dataType: 'json',
      data: {
        payment_data: { cash_paid_amount: amount }
      }
    });
  },

  removeCashPayment: function(paymentId){
    var url = `/payments/${paymentId}/remove_cash_payment`;
    return $.ajax(url, {
      method: 'DELETE',
      dataType: 'json',
    });
  },

  removeCheckPayment: function(paymentId){
    var url = `/payments/${paymentId}/remove_check_payment`;
    return $.ajax(url, {
      method: 'DELETE',
      dataType: 'json',
    });
  },

  getActiveCharge: function(paymentId){
    return $.ajax(`/payments/${paymentId}/get_active_charge`, {
      method: 'GET',
      dataType: 'json',
    })
  }
};

export default PaymentAPI;
