import React, { useState, useEffect } from "react";
import useOnclickOutside from 'react-cool-onclickoutside';
import { isEmpty, debounce } from 'underscore';

import FilterActions from "actions/filter-actions"
import ListOfPeople from "../list-of-people";
import UserDisc from 'components/shared/user-disc';
import Selection from 'components/shared/person-selection';


const ApplicantSearch = ({
  changeFilterValue,
  changeFilterValueOptions,
  filterState,
  minify,
}) => {
  const [showPeopleList, setShowPeopleList] = useState(false);
  const [peopleList, setPeopleList] = useState([]);
  const [applicant, setApplicant] = useState(filterState.options.applicant);
  const hidePeopleList = () => setShowPeopleList(false);

  const ref = useOnclickOutside(hidePeopleList);

  useEffect(() => {
    changeFilterValueOptions({
      applicant: applicant
    });
  }, [applicant]);

  const searchPerson = debounce(
    (search) => {
      if (isEmpty(search) || search.length < 2) {
        return;
      }

      FilterActions.searchApplicants(search).
        done((r) => setPeopleList(r)).
        fail(() => Flash.error('Something went wrong.'));
    },
    333
  );

  const onInputChanged = (e) => {
    const newTerms = e.currentTarget.value;
    setShowPeopleList(true);
    searchPerson(newTerms);
  };

  const onPersonSelect = (value) => {
    setApplicant(value);
    changeFilterValue(value.id);
    hidePeopleList();
  };

  const onPersonRemove = () => {
    setApplicant(null);
    changeFilterValue(null);
  };

  const renderPeopleList = () => {
    return (
      <div ref={ref} className='applicant-search-list'>
        <ListOfPeople
          users={peopleList}
          onSelect={onPersonSelect}
        />
      </div>
    );
  };

  const renderSearch = () => {
    return <input
      onFocus={onInputChanged}
      autoComplete='chrome-off'
      onChange={onInputChanged}
      className='search people'
      type='text'
      placeholder='Search people'
    />
  };

  const renderApplicant = () => {
    return <Selection
      selection={applicant}
      onRemove={onPersonRemove}
      smallerAvatar
    />
  };

  const renderMainContent = () => {
    return (
      <div className='metricsdashboard-filterselect'>
        {applicant ? renderApplicant() : renderSearch()}
        {showPeopleList && renderPeopleList()}
      </div>
    );
  };

  const renderMinify = () => {
    if (!applicant) { return null; }

    return <UserDisc
      user={applicant}
      className='margin-right'
      options={{
        showName: true,
      }}
    />;
  };

  return minify ? renderMinify() : renderMainContent();
};

export default ApplicantSearch;
