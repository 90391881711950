import { isLoggedIn } from 'apis/session-api';
import { throttle } from 'underscore';

export const checkForLogin = throttle(() => {
  const req = isLoggedIn();
  req.done((res) => {
    if (!res.isLoggedIn) {
      alert('You are not currently logged in');
      window.location.replace('/users/sign_in');
    }
  });
}, 5000, { trailing: false });

export const pollForLogin = () => {
  window.$(window).focus('focus', checkForLogin);
};

export default pollForLogin;
