import React from 'react';

const Well = (props) => {
  return (
    <div className={`well morepadding margin-bottom-more stepoptions ${props.className}`}>
      {props.children}
    </div>
  );
};

export default Well;
