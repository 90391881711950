import React, { useState } from 'react';

const UserPasswordManager = (props) => {
  const emptyPackage = {
    current_password: '',
    password: '',
    password_confirmation: '',
  };
  const [passwordPackage, setPasswordPackage] = useState(emptyPackage);

  function handlePasswordInputChange(field, value) {
    setPasswordPackage({ ...passwordPackage, [field]: value });
  }

  function handlePasswordSubmit() {
    props.changePassword(passwordPackage)
      .done(() => {
        setPasswordPackage(emptyPackage);
      });
  }

  // if (CityGrows.Server.currentUser?.isGov) {
  //   return (
  //     <div>
  //       <h1>Change Password</h1>
  //       {
  //         CityGrows.Server.currentUser?.isSSOAccount
  //           ? <p>Accounts using single-sign on (SSO) cannot reset passwords. Check with your SSO identity provider for password reset options.</p>
  //           : <a className='margin-top btn success' href='/users/reset_password'>Update Password</a>
  //       }
  //     </div>
  //   );
  // }

  return (
    <div>
      <h1>Change Password</h1>
      <div className='padding-bottom'>
        <label htmlFor='current_password'>Current Password</label>
        <input id='current_password' type='password' value={passwordPackage.current_password} onChange={(e) => (handlePasswordInputChange('current_password', e.currentTarget.value))} />
      </div>
      <div className='padding-bottom'>
        <label htmlFor='password'>New Password</label>
        <input id='password' type='password' value={passwordPackage.password} onChange={(e) => (handlePasswordInputChange('password', e.currentTarget.value))} />
      </div>
      <div className='padding-bottom'>
        <label htmlFor='password_confirmation'>Confirm Password</label>
        <input type='password' value={passwordPackage.password_confirmation} onChange={(e) => (handlePasswordInputChange('password_confirmation', e.currentTarget.value))} />
      </div>
      <button className='btn-primary margin-top' onClick={handlePasswordSubmit}>Update Password</button>
    </div>
  );
};

export default UserPasswordManager;
