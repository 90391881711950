import React from 'react';
import DataBox from 'components/metrics_dashboard/data-box';

const TopRow = ({formData}) => {
  function renderPercentage(numerator) {
    const percentage = (numerator / formData.launched_count ) * 100;
    const integer = formData.launched_count === 0 ? 0 : Math.round(percentage);

    return (
      <div className='inline-block margin-left-less'>
        <span className='percentage'>{`${integer}%`}</span>
        <p>of Submissions</p>
      </div>
    );
  }

  return (
    <div className='metricsdashboard-toprow'>
      <DataBox label='Launched' number={formData.launched_count} />
      <DataBox label='Completed' number={formData.completed_count}>
        {renderPercentage(formData.completed_count)}
      </DataBox>
      <DataBox label='Active'  number={formData.active_count} >
        {renderPercentage(formData.active_count)}
      </DataBox>
      <DataBox label='Overdue' number={formData.overdue_count} >
        {renderPercentage(formData.overdue_count)}
      </DataBox>
    </div>
  );
};

export default TopRow;
