import React, { useState, useRef } from 'react';
import _ from 'underscore';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useFocusTrap from 'components/hooks/use-focus-trap';

const Alertconfirm = (props) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const challengeResponse = useRef();
  const focusEl = useFocusTrap();

  function renderButtons () {
    const confirmButton = <button id='alertconfirm-confirmbutton' className='btn-primary' onClick={onConfirmClick}>Okay</button>;

    if (props.type === 'confirm') {
      return (
        <div>
          <button className='btn-secondary margin-right' onClick={props.onCancel}>Never mind</button>
          {confirmButton}
        </div>
      );
    }
    return (
      <div>
        {confirmButton}
      </div>
    );
  }

  function onConfirmClick () {
    setErrorMessage(null);

    if (!props.challenge) {
      props.onConfirm();
    } else if (props.challengeAnswer.toString() === challengeResponse.current.value) {
      props.onConfirm();
    } else {
      setErrorMessage('Invalid response');
    }
  }

  function renderErrorMessage () {
    if (errorMessage) {
      return <div className='margin-top text-alert strong align-right'>{errorMessage}</div>;
    }
  }

  function renderMessageBody () {
    const challengeHTML = props.challenge ? (
      <div className='margin-bottom'>
        <div className='linebreak' />
        <span>{props.challenge}</span>
        <input type='text' ref={challengeResponse} className='box' size='6' />
        {renderErrorMessage()}
      </div>
    ) : null;

    const bodyTag = _.isString(props.body)
      ? <div dangerouslySetInnerHTML={{ __html: props.body }} />
      : props.body;

    return (
      <div>
        {bodyTag}
        {challengeHTML}
      </div>
    );
  }

  return (
    <div id='alertconfirm' ref={focusEl} className={`alertconfirm-container ${props.klass ?? ''}`}>
      <div className='cf'>
        <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} size='2xl' className='alertconfirm-icon' />
        <dl className='alertconfirm-text margin-bottom'>
          <dt className='alertconfirm-title'>{props.title}</dt>
          <dd className='alertconfirm-message'>
            {renderMessageBody()}
          </dd>
        </dl>
      </div>
      <div className='alertconfirm-actions align-right'>
        {renderButtons()}
      </div>
    </div>
  );
};

export default Alertconfirm;
