import ActiveStepConstants from 'constants/active-step-constants';
import InitiatedFlowConstants from 'constants/initiated-flow-constants';
import {
  waive,
  unwaive,
  unlinkSubprocess as apiUnlinkSubprocess,
} from 'apis/subprocess-requirements-api';
import {
  error as flashError,
  success as flashSuccess,
} from 'components/shared/flash';

const onRequirementWaived = (dispatch, attrs) => {
  flashSuccess('Requirement waived');

  dispatch({
    type: InitiatedFlowConstants.SUBPROCESS_REQUIREMENT_UPDATED,
    attrs,
  });

  dispatch({
    type: ActiveStepConstants.CHANGES_PERSISTED,
  });
};

const onRequirementUnwaived = (dispatch, attrs) => {
  flashSuccess('Requirement unwaived');

  dispatch({
    type: InitiatedFlowConstants.SUBPROCESS_REQUIREMENT_UPDATED,
    attrs,
  });

  dispatch({
    type: ActiveStepConstants.CHANGES_PERSISTED,
  })
};

const onSubprocessUnlinked = (dispatch, attrs) => {
  flashSuccess('Subprocess unlinked');

  dispatch({
    type: InitiatedFlowConstants.SUBPROCESS_REQUIREMENT_UPDATED,
    attrs,
  });

  dispatch({
    type: ActiveStepConstants.CHANGES_PERSISTED,
  });
};

export const waiveRequirement = (dispatch, subprocessRequirementId) => {
  return waive(subprocessRequirementId)
    .done((res) => onRequirementWaived(dispatch, res))
    .fail(() => { flashError(); });
};

export const unwaiveRequirement = (dispatch, subprocessRequirementId) => {
  return unwaive(subprocessRequirementId)
    .done((res) => onRequirementUnwaived(dispatch, res))
    .fail(() => { flashError(); });
};

export const unlinkSubprocess = (dispatch, requirementId) => {
  return apiUnlinkSubprocess(requirementId)
  .done((res) => onSubprocessUnlinked(dispatch, res))
  .fail(() => { flashError(); });
};

export default {
  waiveRequirement,
  unwaiveRequirement,
};
