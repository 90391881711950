import React from 'react';
import renderer from 'modules/renderer';
import CommonProviders from 'components/common-providers';
import SubNav from '../components/subnav';
import PrimaryTabs from '../components/shared/primary-tabs';

function init() {
  renderer(<PrimaryTabs />, document.getElementById('tabs'));
  renderer(
    (
      <CommonProviders>
        <SubNav
          teamSlug={CityGrows.Server.teamSlug}
          peopleSelected={CityGrows.Server.peopleSelected}
          teamsForSelect={CityGrows.Server.teamsForSelect}
          entityTabLabel={CityGrows.Server.entityTabLabel}
        />
      </CommonProviders>
    ), document.getElementById('directory-subnav'),
  );
}

export default {
  init,
};
