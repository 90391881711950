import React from 'react';
import PropTypes from 'prop-types';
import Receipt from './receipt';

class Paid extends React.Component {
  paidStepUI() {
    if (this.props.nonRefundedCharge) {
      return this.props.renderReceiptForCharge(this.props.nonRefundedCharge);
    }
    return (
      <Receipt
        activeStep={this.props.activeStep}
        admin={this.props.admin}
        key='check/cash'
      />
    );
  }

  render() {
    return (
      this.paidStepUI()
    );
  }
}

Paid.propTypes = {
  activeStep: PropTypes.object.isRequired,
  admin: PropTypes.bool.isRequired,
  renderReceiptForCharge: PropTypes.func.isRequired,
  nonRefundedCharge: PropTypes.object,
};

export default Paid;
