import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SurveySubmissionActions from 'actions/survey-submission-actions';
import { validate } from 'modules/collected-data-validator';
import _ from 'underscore';
import EmailValidator from 'modules/email-validator';
import TimeAgo from 'react-timeago';
import Section from './survey/section';

const SUBMIT_BUTTON_LABELS = {
  new: {
    unsubmitted: 'Submit response',
    submitted: 'Response submitted',
  },

  existing: {
    unsubmitted: 'Update responses',
    submitted: 'Updates submitted',
  },
};

const Survey = (props) => {
  const [email, setEmail] = useState(props.existingSubmission ? props.existingSubmission.email : null);
  const [surveyData, setSurveyData] = useState(initialSurveyData());
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [attemptedAdvance, setAttemptedAdvance] = useState(false);

  function initialSurveyData() {
    return props.existingSubmission ? existingSubmissionData()
      : defaultValueData();
  }

  function existingSubmissionData() {
    const surveyDta = {};

    props.existingSubmission.collected_data.forEach((collectedDatum) => {
      surveyDta[collectedDatum.step_datum_id] = collectedDatum;
    });

    return surveyDta;
  }

  function defaultValueData() {
    const defaults = {};

    _.each(props.survey.default_values, (val, key) => {
      defaults[key] = { content: val };
    });

    return defaults;
  }

  function onSubmitSurvey() {
    if (!readyToSubmit()) {
      setAttemptedAdvance(true);
      return;
    }

    setSubmitting(true);

    let request;

    if (props.existingSubmission) {
      const submissionToken = props.existingSubmission.token || props.existingSubmission.id;
      request = SurveySubmissionActions.update(submissionToken, formattedDataForSubmit());
    } else {
      const { id, survey_token } = props.survey;
      request = SurveySubmissionActions.submit(id, survey_token, formattedDataForSubmit());
    }

    request.done(onSurveySubmitted)
           .always(onSubmitRequestComplete);
  }

  function onSubmitRequestComplete() {
    setSubmitting(false);
  }

  function onSurveySubmitted() {
    setSubmitted(true);
  }

  function onInputChanged(stepDatumId, value) {
    setDatumValue(stepDatumId, value);
  }

  function setDatumValue(stepDatumId, value) {
    // When collected datum already exists (on update)
    // it will contain the collectedDatumId, which
    // is required to trigger an update instead of
    // a create.

    const collectedDatum = surveyData[stepDatumId] || {};
    collectedDatum.content = value;

    const data = {
      [stepDatumId]: collectedDatum,
    };

    setSurveyData((prevState) => {
      return { ...prevState, ...data };
    });
  }

  function isNewSubmission() {
    return !props.existingSubmission;
  }

  function formattedDataForSubmit() {
    const datumIds = Object.keys(surveyData);
    const collectedDataAttrs = datumIds.map((datumId) => {
      const type = _.findWhere(allStepData(), { id: Number(datumId) }).data_type;
      return {
        field_id: datumId,
        field_type: 'StepDatum',
        content: formatContent(surveyData[datumId].content, type),
        id: surveyData[datumId].id,
      };
    });

    return {
      email,
      collectedDataAttrs,
    };
  }

  function formatContent(content, dataType) {
    if (dataType === 'checkboxes') {
      return JSON.stringify(content);
    }

    return content;
  }

  function surveyIsIdentified() {
    return props.survey.identified;
  }

  function readyToSubmit() {
    if (submitting || submitted) {
      return false;
    }

    if (surveyIsIdentified() && !EmailValidator.validate(email)) {
      return false;
    }

    if (_.isEmpty(surveyData)) { return false; }

    return allStepData().every((datum) => {
      const collectedDatum = surveyData[datum.id];
      const content = collectedDatum && collectedDatum.content;
      const isValid = !datum.required || validate(content, datum);
      return isValid;
    });
  }

  function allStepData() {
    return _.flatten(props.survey.sections.map((section) => section.step_data));
  }

  function renderSubmittedMessage() {
    return (
      <div className='survey-submittedmessage'>Your response has been submitted.</div>
    );
  }

  function renderSection(section, idx) {
    return (
      <Section
        onInputChanged={onInputChanged}
        section={section}
        surveyData={surveyData}
        disabled={submitted}
        attemptedAdvance={attemptedAdvance}
        stepData={allStepData()}
        key={idx}
        defaultValues={props.survey.default_values}
      />
    );
  }

  function renderSections() {
    return props.survey.sections.map(renderSection);
  }

  function renderContactField() {
    if (props.survey.identified) {
      return (
        <div className={`forminput-validity ${emailWrapperClass()}`}>
          <label className='bigger'>
            <span className='survey-emaillabel'>Email<i /></span>
            <input defaultValue={email} onChange={onEmailchange} className='margin-bottom-more bigger goldenwidth' type='email' placeholder='email@example.com' />
          </label>
        </div>
      );
    }
  }

  function emailWrapperClass() {
    if (attemptedAdvance && !EmailValidator.validate(email)) {
      return 'invalid';
    }
  }

  function onEmailchange(e) {
    setEmail(e.currentTarget.value);
  }

  function renderSubmitButtonText() {
    let labels;

    if (isNewSubmission()) {
      labels = SUBMIT_BUTTON_LABELS.new;
      return submitted ? labels.submitted : labels.unsubmitted;
    }

    labels = SUBMIT_BUTTON_LABELS.existing;
    return submitted ? labels.submitted : labels.unsubmitted;
  }

  function renderHeader() {
    let submissionNumber;
    let submissionDetails;

    if (props.existingSubmission) {
      submissionNumber = (
        <div className='processnumber float-right'>
          Response&nbsp;
          <span>#{props.existingSubmission.scoped_id}</span>
        </div>
      );

      submissionDetails = (
        <div className='color-text-medium margin-top-less'>
          Submitted <TimeAgo date={props.existingSubmission.created_at} /> {renderEmail()}
        </div>
      );
    }

    return (
      <header className='cf'>
        <div className='survey-title'>
          {props.survey.name}
          {submissionNumber}
        </div>
        {submissionDetails}
      </header>
    );
  }

  function renderEmail() {
    if (props.existingSubmission.email) {
      return (
        <>
          by <strong>{props.existingSubmission.email}</strong>
        </>
      );
    }
  }

  function renderInstructions() {
    return (
      <p>{props.survey.instructions}</p>
    );
  }

  return (
    <div className='survey'>
      <main className='raised'>
        {renderHeader()}
        {renderInstructions()}
        {renderContactField()}
        {renderSections()}

        <div className={`btn-primary${!readyToSubmit() ? ' disabled' : ''}`} onClick={onSubmitSurvey}>
          {renderSubmitButtonText()}
        </div>

        {submitted ? renderSubmittedMessage() : ''}
      </main>
    </div>
  );
};

Survey.propTypes = {
  survey: PropTypes.shape({
    sections: PropTypes.array.isRequired,
    instructions: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number.isRequired,
    survey_token: PropTypes.string,
    identified: PropTypes.bool.isRequired,
  }).isRequired,
  existingSubmission: PropTypes.shape({
    token: PropTypes.string.isRequired,
    collected_data: PropTypes.array.isRequired,
    email: PropTypes.string,
  }),
};

export default Survey;
