import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const AmountBreakdown = (props) => {
  function formatAmount(amount) {
    return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount.toFixed(2));
  }

  return (
    <span>
      <table className='receipttable currentstep-chargebreakdown'>
        <tbody>
          <tr>
            <td>Amount:</td>
            <td>{formatAmount(props.amount)}</td>
          </tr>

          <tr>
            <td>Processing Fee:</td>
            <td>{formatAmount(props.fee)}</td>
          </tr>

          <tr>
            <td>Total:</td>
            <td>{formatAmount(props.amount + props.fee)}</td>
          </tr>
        </tbody>
      </table>
    </span>
  );
};

AmountBreakdown.propTypes = {
  amount: PropTypes.number.isRequired,
  fee: PropTypes.number.isRequired,
};

export default AmountBreakdown;
