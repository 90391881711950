import React, { useState } from 'react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DocumentsAndFiles from '../initiated_flow/process/documents-and-files';
import Activity from '../initiated_flow/process/activity';
import Access from '../initiated_flow/process/access';
import ReviewDiscussion from '../initiated_flow/process/steps/reviews/discussion';
import Instructions from '../shared/instructions';
import IFControlNav from '../initiated_flow/process/if-control-nav';

const TasksSidebar = ({initiatedFlowState, activities, masterTemplate, discussions, currentStep, onTaskViewUpdate, onSkip}) => {
  const [openTab, setOpenTab] = useState('action');

  const {initiatedFlow} = initiatedFlowState;

  function renderAlternateOptions() {
    return (
      <button type='button' className='btn-link padded' onClick={onSkip}>Skip</button>
    )
  }

  function renderMainAction() {
    return (
      <>
        <div className='padded mytasks-instructions'>
          <Instructions admin initiatedFlow={initiatedFlow} step={currentStep} />
        </div>
        <IFControlNav
          initiatedFlowState={initiatedFlowState}
          admin
          masterTemplateId={masterTemplate.id}
          teamSlug={masterTemplate.team_friendly_id}
          masterTemplateSlug={masterTemplate.friendly_id}
          taskView
          onAdvance={onTaskViewUpdate}
        />
        {renderAlternateOptions()}
      </>
    )
  }

  function renderContent() {
    switch (openTab) {
    case 'action':
      return renderMainAction();
    case 'files':
      return (
        <div className='padded'>
          <DocumentsAndFiles
            initiatedFlowId={initiatedFlow.id}
            issuedOutputs={initiatedFlow.issued_outputs}
          />
        </div>
      )
    case 'discussion':
      return <ReviewDiscussion
              initiatedFlowId={initiatedFlow.id}
              admin
              recentlyAddedDiscussionId={null}
              discussions={discussions} />
    case 'activities':
      return   <Activity
                  activities={activities}
                  initiatedFlowId={initiatedFlow.id}
                />
    case 'users':
      return <Access
              admin
              applicantAttachments={initiatedFlow.applicant_attachments}
              initiatedFlowId={initiatedFlow.id}
              personAttachments={initiatedFlow.person_attachments}
              teamId={masterTemplate.team_id}
    />
    default:
      throw new Error(`Unhandled tab ${openTab}`);
    }
  }

  return (
    <div className='mytasks-right flexcolumn'>
      <div className='mytasks-sidebaricons'>
        <ul>
          <li>
            <button className='btn-link' onClick={() => { setOpenTab('action'); }} type='button'>
              <FontAwesomeIcon
                icon={icon({ name: 'person-chalkboard' })}
                style={{ color: '#597A91', height: '1.5rem' }}
                title='Actions'
              />
            </button>
          </li>
          <li>
            <button className='btn-link' onClick={() => { setOpenTab('discussion'); }} type='button'>
              <FontAwesomeIcon
                icon={icon({ name: 'comment' })}
                style={{ color: '#597A91', height: '1.5rem' }}
                title='Discussion'
              />
            </button>
          </li>
          <li>
            <button className='btn-link' onClick={() => { setOpenTab('files'); }} type='button'>
              <FontAwesomeIcon
                icon={icon({ name: 'file' })}
                style={{ color: '#597A91', height: '1.5rem' }}
                title='Documents and Files'
              />
            </button>
          </li>
          <li>
            <button className='btn-link' onClick={() => { setOpenTab('users'); }} type='button'>
              <FontAwesomeIcon
                icon={icon({ name: 'user' })}
                style={{ color: '#597A91', height: '1.5rem' }}
                title='Access Manager'
              />
            </button>
          </li>
          <li>
            <button className='btn-link' onClick={() => { setOpenTab('activities'); }} type='button'>
              <FontAwesomeIcon
                icon={icon({ name: 'wave-pulse' })}
                style={{ color: '#597A91', height: '1.5rem' }}
                title='Activities'
              />
            </button>
          </li>
        </ul>
      </div>
      <div className='mytasks-sidebarmain'>
        {renderContent()}
      </div>
    </div>
  );
};

export default TasksSidebar;
