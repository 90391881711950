import React, { useEffect, useState } from 'react';
import { fetchTasks } from 'apis/my-tasks-api';
import { filter, some, union, sortBy, reject, without } from 'underscore';
import PrimaryTabs from './shared/primary-tabs';
import TasksHeaderBar from './my-tasks/tasks-header-bar';
import TasksList from './my-tasks/tasks-list';
import InitiatedFlowManager from './initiated-flow-manager';
import InitiatedFlowAPI from '../apis/initiated-flow-api';
import LoadingSpinner from 'components/shared/loading-spinner';

const MyTasksContainer = () => {
  const [submissions, setSubmissions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeSubmissionId, setActiveSubmissionId] = useState(null);
  const [activeSubmission, setActiveSubmission] = useState(null);
  const [completed, setCompleted] = useState([]);
  const [skipped, setSkipped] = useState([]);
  const [sort, setSort] = useState('oldest');
  const [taskFilter, setTaskFilter] = useState('all');
  const [initialCount, setInitialCount] = useState(null);

  useEffect(() => {
    fetchTasks(null)
      .done((result) => {
        setSubmissions(result);
        setLoading(false);
        setActiveSubmissionId(filteredAndSorted(result)[0]?.id || null);
        setInitialCount(result.length);
      });
  }, []);

  useEffect(() => {
    if (!submissions) { return }

    setActiveSubmissionId(filteredAndSorted(submissions)[0]?.id || null)
  }, [skipped])

  useEffect(() => {
    if (!activeSubmissionId) { return; }

    InitiatedFlowAPI.show(activeSubmissionId)
      .done((res) => { setActiveSubmission(res); });
  }, [activeSubmissionId]);

  const filteredAndSorted = (subs) => {
    return sortSubmissions(filterSubmissions(subs))
  }

  const skippedSubmissions = () => {
    return filter(submissions, (sub) => { return skipped.includes(sub.id); });
  }

  function filterSubmissions(subs) {
    const withoutSkipped = reject(subs, (sub) => { return skipped.includes(sub.id); });
    switch (taskFilter) {
    case 'all':
      return withoutSkipped;
    case 'overdue':
      return filter(withoutSkipped, { overdue: true });
    case 'assignedInternal':
      return filter(withoutSkipped, { internal: true });
    case 'assignedExternal':
      return filter(withoutSkipped, { internal: false });
    case 'reviews':
      return filter(withoutSkipped, { review: true });
    default:
      throw new Error('Unknown Filter Typ');
    }
  }

  function sortSubmissions(subs) {
    switch (sort) {
    case 'oldest':
      return subs;
    case 'newest':
      return subs.reverse();
    case 'form':
      return sortBy(subs, 'template_name');
    case 'applicant':
      return sortBy(subs, 'primary_applicant_name');
    default:
      throw new Error('Unknown sort type');
    }
  }

  function onSkip() {
    setSkipped([...skipped, activeSubmissionId]);
  }

  function changeActiveSubmission(id) {
    if (id === activeSubmissionId) { return; }

    setActiveSubmission(null);
    setActiveSubmissionId(id);
  }

  function overdueCount() {
    if (loading) { return 0; }

    return filter(submissions, { overdue: true }).length;
  }

  function onUpdate() {
    setLoading(true);
    setSubmissions(null);
    const previousSubmission = activeSubmissionId;
    setSkipped(without(skipped, activeSubmissionId));
    setActiveSubmission(null);
    setActiveSubmissionId(null);
    fetchTasks()
        .done((result) => {
          setSubmissions(result);
          if (some(result, (submission) => { return submission.id === previousSubmission; })) {
            setActiveSubmissionId(previousSubmission);
          } else {

            setActiveSubmissionId(filteredAndSorted(result)[0]?.id || null);
            setCompleted(union(completed, [previousSubmission]));
          }
          setLoading(false);
        });
  }

  function renderMain() {
    if (activeSubmission) {
      return (
        <InitiatedFlowManager
          step={activeSubmission.step}
          currentUserId={CityGrows.Server.currentUserId}
          admin
          initiatedFlow={activeSubmission.initiated_flow}
          flowTemplate={activeSubmission.flow_template}
          masterTemplate={activeSubmission.master_template}
          activeStep={activeSubmission.active_step}
          showAdminWarning={false}
          primaryApplicant={activeSubmission.primary_applicant}
          secondaryApplicantIds={activeSubmission.secondary_applicant_ids}
          taskView
          onTaskViewUpdate={onUpdate}
          onSkip={onSkip}
        />
      );
    }

    if (!loading && filteredAndSorted(submissions).length === 0 && !activeSubmissionId) {
      return (
        <div className='mytasks-loading flexcolumn'>
          <div className='mytasks-loading-wrapper'>
            <span className='mytasks-empty'>No Current Tasks</span>
          </div>
        </div>
      )
    }

    return (
      <div className='mytasks-loading flexcolumn'>
        <div className='mytasks-loading-wrapper'>
          <LoadingSpinner size='xlarge' />
        </div>
      </div>
    );
  }

  function renderTaskList() {
    return (
      <TasksList
        submissions={filteredAndSorted(submissions)}
        loading={loading}
        activeSubmission={activeSubmissionId}
        skipped={skipped}
        skippedSubmissions={skippedSubmissions()}
        setSort={setSort}
        sort={sort}
        taskFilter={taskFilter}
        setTaskFilter={setTaskFilter}
        changeActiveSubmission={changeActiveSubmission}
      />
    );
  }

  return (
    <div className='margin-left margin-bottom margin-right flexcolumn fullheight'>
      <PrimaryTabs />
      <div id='tabheader-body' className='flexcolumn'>
        <TasksHeaderBar
          count={loading ? null : initialCount}
          overdueCount={overdueCount()}
          completed={completed.length}
        />
        <div className='my-tasks'>
          {renderTaskList()}
          {renderMain()}
        </div>
      </div>
    </div>
  );
};

export default MyTasksContainer;
