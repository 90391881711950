import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { filter, findWhere } from 'underscore';
import { error } from 'components/shared/flash';
import AttachmentActions from 'actions/attachment-actions';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import SlidingToggle from 'components/shared/sliding-toggle';
import ProcessAssignmentsActions from 'actions/process-assignment-actions';
import RoleDisc from 'components/shared/role-disc';
import UserDisc from 'components/shared/user-disc';
import XClose from 'components/shared/x-close';

const Members = ({
  personAttachments,
  initiatedFlowId,
}) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);

  const renderAdminDeleteLink = (user) => {
    return (
      <div className='accessmanager-removeuser'>
        {user.removable && <XClose onClick={(e) => { onAdminDelete(e, user); }} label='Remove user' /> }
      </div>
    );
  };

  const onAssignmentToggle = (personAttachment) => {
    if (personAttachment.assigned) {
      ProcessAssignmentsActions.unassign(initiatedFlowId, personAttachment.assignment_id)
        .done(() => {
          const attachment = { ...personAttachment, assigned: false, assignment_id: null };
          ProcessAssignmentsActions.dispatchAssignmentUpdated(dispatch, attachment);
        });
    } else {
      ProcessAssignmentsActions
        .assign(initiatedFlowId, personAttachment.id, personAttachment.type)
        .done((res) => {
          const attachment = { ...personAttachment, assigned: true, assignment_id: res.id };
          ProcessAssignmentsActions.dispatchAssignmentUpdated(dispatch, attachment);
        });
    }
  };

  const onAdminToggle = (user) => {
    const userId = user.id;
    // Verify existing users can be removed
    const existingUser = findWhere(personAttachments, { id: userId });
    if (existingUser && !existingUser.removable) {
      error('Form members cannot be removed from an individual process');
    } else if (existingUser) {
      AttachmentActions.removePerson(existingUser.attachment_id)
          .done((res) => AttachmentActions.dispatchInitiatedFlowTogglePerson(dispatch, res));
    } else {
      AttachmentActions.togglePerson(initiatedFlowId, 'InitiatedFlow', userId, user.type)
          .done((res) => AttachmentActions.dispatchInitiatedFlowTogglePerson(dispatch, res));
    }
  };

  const onAdminDelete = (e, user) => {
    e.preventDefault();
    onAdminToggle(user);
  };

  const renderAssignees = () => {
    let usersToRender = filter(personAttachments, {
      type: 'User',
      assigned: true,
    });

    usersToRender = filter(personAttachments, {
      assigned: true,
    });

    return usersToRender.map(renderAdminUser);
  };

  const renderViewers = () => {
    let usersToRender = filter(personAttachments, {
      type: 'User',
      assigned: false,
    });

    usersToRender = filter(personAttachments, {
      assigned: false,
    });

    return usersToRender.map(renderAdminUser);
  };

  const renderDisc = (user) => {
    if (user.type === 'User') {
      return (
        <UserDisc
          user={user}
          options={{
            showName: true,
            showEmail: false,
          }}
        />
      );
    }

    return <RoleDisc role={user} />;
  };

  const renderAdminUser = (user) => {
    const assignmentLabel = user.assigned ? 'Assigned' : 'Not assigned';
    const adminInviteStatus = user.pseudo ? (
      <span className='color-text-medium'> (Invited)</span>
    ) : '';

    return (
      <li key={user.id} className='cf'>
        <div title={user.name} className='float-left'>
          {renderDisc(user)}
          {adminInviteStatus}
        </div>

        <div className='float-right'>
          <SlidingToggle
            onToggle={() => { onAssignmentToggle(user); }}
            on={user.assigned}
            options={{ size: 'small' }}
            label={assignmentLabel}
          />
          {renderAdminDeleteLink(user)}
        </div>
      </li>
    );
  };

  return (
    <>
      <div className='accessmanager-label margin-bottom'>Assignees</div>
      <ul className='accessmanager-admins'>
        {renderAssignees()}
      </ul>

      <div className='accessmanager-label margin-bottom'>Viewers</div>
      <ul className='accessmanager-admins'>
        {renderViewers()}
      </ul>
    </>
  );
};

Members.propTypes = {
  personAttachments: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  initiatedFlowId: PropTypes.number.isRequired,
};

export default Members;
