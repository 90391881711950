import React from 'react';
import OutputDownloadLink from '../../output/output-download-link';

const StepOutput = (props) => {
  if (props.output.revoked) {
    return <span className='output-statustag revoked'>Revoked</span>;
  }

  return <OutputDownloadLink output={props.output} className='btn-link' />;
};

export default StepOutput;
