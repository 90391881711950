import dayjs from 'dayjs';

export const formatIncoming = (value, type) => {
  if (!value) { return null; }

  if (type === 'time') {
    return dayjs(value, 'HH-mm').toDate();
  }

  if (type === 'date_time') {
    return dayjs(value, 'YYYY-MM-DD-HH-mm').toDate();
  }

  return dayjs(value, 'YYYY-MM-DD').toDate();
};

export const formatOutgoing = (date, type) => {
  if (!date) { return ''; }

  let format = 'YYYY-MM-DD';
  if (type === 'date_time') {
    format = 'YYYY-MM-DD HH:mm';
  } else if (type === 'time') {
    format = 'HH:mm';
  }

  return dayjs(date).format(format);
};

export const dateFormats = (type) => {
  switch (type) {
  case 'date':
    return ['yyyy-MM-dd', 'yyyy-M-d', 'M/d/yyyy', 'M-d-yyyy'];
  case 'date_time':
    return ['yyyy-MM-dd h:mm aa', 'yyyy-M-d h:mm aa', 'M/d/yyyy h:mm aa', 'M-d-yyyy h:mm aa'];
  case 'time':
    return ['h:mm aa'];
  }
};
