import React from 'react';
import Flash from 'components/shared/flash';
import EditPaymentView from './payment_details/edit-payment-view';

class PaymentStepDetails extends React.Component {
  checkInstructionsBlurred = (e) => {
    const newInstructions = e.currentTarget.value;
    const { id, config } = this.props.step;

    if (config.check_instructions === newInstructions) { return; }

    this.props.updateAttrs(id, {
      config_attributes: {
        check_instructions: newInstructions,
      },
    })
    .done(() => { Flash.success('Check instructions updated'); });
  };

  cashInstructionsBlurred = (e) => {
    const newInstructions = e.currentTarget.value;
    const { id, config } = this.props.step;

    if (config.cash_instructions === newInstructions) { return; }

    this.props.updateAttrs(id, {
      config_attributes: {
        cash_instructions: newInstructions,
      },
    })
    .done(() => { Flash.success('Cash instructions updated'); });
  };

  render() {
    return (
      <EditPaymentView
        configChanged={this.configChanged}
        updateAttrs={this.props.updateAttrs}
        checkInstructionsBlurred={this.checkInstructionsBlurred}
        cashInstructionsBlurred={this.cashInstructionsBlurred}
        masterTemplateId={this.props.masterTemplateId}
        config={this.props.step.config}
        disabled={this.props.disabled}
        step={this.props.step}
      />
    );
  }
}

export default PaymentStepDetails;
