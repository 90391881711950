import React from "react";

import Select from "../select";
import { OUTPUT_STATUSES } from "./constants";

const OutputStatus = ({
  changeFilterValue,
  filterState,
}) => {
  const onStatusChange = (value) => {
    changeFilterValue(value.id);
  };

  return (
    <div className='metricsdashboard-filterselect'>
      <Select
        options={OUTPUT_STATUSES}
        onChange={onStatusChange}
        getOptionValue={(opt) => opt.id}
        value={OUTPUT_STATUSES.find((opt) => opt.id === filterState.value)}
        className='react-select-metrics advanced-filter'
      />
    </div>
  );
};

export default OutputStatus;
