import React from 'react';
import Labelbox from 'components/shared/labelbox';
import InformationalText from 'components/shared/informational-text';

const Instructions = ({admin, step, initiatedFlow}) => {
  function renderAdminInstructions() {
    if (!admin || !step.internal_instructions) { return; }

    return (
      <InformationalText
        text={step.internal_instructions}
        textType='InternalStepInstructions'
        textContainerId={step.id}
        initiatedFlow={initiatedFlow}
        classes='margin-top'
      />
    );
  }

  function renderApplicantInstructions() {
    const placeholder = admin
      ? <span className='color-text-medium'>This step has no applicant instructions</span>
      : '';

    const text = !step.external_instructions
      ? placeholder
      : (
        <InformationalText
          text={step.external_instructions}
          textType='ExternalStepInstructions'
          textContainerId={step.id}
          initiatedFlow={initiatedFlow}
        />
      );

    if (admin) {
      return (
        <Labelbox label='Applicant Instructions'>
          {text}
        </Labelbox>
      );
    }

    return <div className='margin-top'>{text}</div>;
  }

  return (
    <>
      {renderAdminInstructions()}
      {renderApplicantInstructions()}
    </>
  );
};

export default Instructions;