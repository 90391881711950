import { useEffect, useRef }  from 'react';

const KEYCODE_TAB = 9;
const KEYCODE_ESC = 27;

function useFocusTrap(onClose) {
  const elRef = useRef(null);

  function focusableEls(el) {
    return el.current?.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select',
    ) || [];
  }

  function handleFocus(e) {
    const focusable = focusableEls(elRef)
    const firstFocusableEl = focusable[0]
    const lastFocusableEl = focusable[focusable.length - 1];

    const isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB;
    const isESCPressed = e.key === 'Escape' || e.keyCode === KEYCODE_ESC

    if (isESCPressed) { return onClose(e); }

    if (!isTabPressed) {
      return;
    }

    if (e.shiftKey) {
      /* shift + tab */ if (document.activeElement === firstFocusableEl) {
        lastFocusableEl.focus();
        e.preventDefault();
      }
    } /* tab */ else {
      if (document.activeElement === lastFocusableEl) {
        firstFocusableEl.focus();
        e.preventDefault();
      }
    }
  }

  useEffect(() => {
    elRef.current.addEventListener('keydown', handleFocus);
    setTimeout(() => {focusableEls(elRef)[0]?.focus()}, 300)

    return () => {
      elRef.current?.removeEventListener('keydown', handleFocus);
    };
  }, []);

  return elRef;
}

export default useFocusTrap;