import React from "react";
import { contains } from "underscore";

import Select from "../select";


const TagSelect = ({
  changeFilterValue,
  filterState,
  tags,
}) => {
  const tagOptions = tags || [];

  const tagNumberIds = filterState.value.map((id) => Number(id));

  const onTagChange = (value) => {
    changeFilterValue(value.map(tag => tag.id));
  };

  return (
    <div className='metricsdashboard-filterselect' style={{ minWidth: '80px' }}>
      <Select
        isMulti
        options={tags}
        onChange={onTagChange}
        getOptionValue={(opt) => opt.id}
        getOptionLabel={(opt) => opt.name}
        className='react-select-metrics advanced-filter'
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        placeholder='Search tag'
        value={tagOptions.filter((tag) => contains(tagNumberIds, tag.id))}
      />
    </div>
  );
};

export default TagSelect;
