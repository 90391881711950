import {
  each, contains, filter,
} from 'underscore';

const LOGIC_ENABLED_FIELDS = ['multiple_choice', 'checkboxes', 'integer', 'float'];

class FieldManager {
  constructor(steps) {
    this.steps = steps;
  }

  availableEmailFields(stepPositionLimit) {
    const availableFields = [];

    each(this.getStepData(), ({ position, stepData }) => {
      each(stepData, (stepDatum) => {
        if (stepDatum.data_type === 'email'
            && stepDatum.required
            && position <= stepPositionLimit) {
          availableFields.push({ ...stepDatum, stepPosition: position });
        }
      });
    });

    return availableFields;
  }

  availableLogicFields() {
    const availableFields = [];

    each(this.getStepData(), ({stepData}) => {
      each(stepData, (stepDatum) => {
        if (contains(LOGIC_ENABLED_FIELDS, stepDatum.data_type)) {
          availableFields.push(stepDatum);
        }
      })
    });

    return availableFields;
  }

  getCollectInfoSteps() {
    return filter(this.steps, (step) => {
      return step.type === 'CollectInfoStep';
    });
  }

  getStepData() {
    return this.getCollectInfoSteps().map((step) => { return {stepData: step.step_data, position: step.position} });
  }

  findStepDatumByIdentity(identity) {
    let stpDtm = null;
    each(this.getStepData(), ({ position, stepData }) => {
      each(stepData, (stepDatum) => {
        if (stepDatum.identity === identity) {
          stpDtm = { ...stepDatum, stepPosition: position };
        }
      });
    });

    return stpDtm;
  }
}

export default FieldManager;
