const FormulaAPI = {
  create: function(attrs){
    const url = '/formulas/';

    return $.ajax(url, {
      method: 'POST',
      dataType: 'json',
      data: {
        formula: attrs
      }
    });
  },

  update: function(formulaId, attrs){
    const url = '/formulas/' + formulaId;
    return $.ajax(url, {
      method: 'PUT',
      dataType: 'json',
      data: {
        formula: attrs
      }
    });
  },

  delete: function(formulaId){
    const url = '/formulas/' + formulaId;
    return $.ajax(url, {
      method: 'DELETE',
      dataType: 'json'
    });
  },

  availableForStep: function(stepId){
    const url = `/steps/${stepId}/available_formulas`;
    return $.ajax(url, {
      method: 'GET',
      dataType: 'json'
    });
  }

};

export default FormulaAPI;
