import React, { useState, useEffect } from 'react';
import CurrentStep from 'components/initiated_flow/process/current-step';
import { fetchStep } from 'apis/my-tasks-api';
import PreviousStep from './task-step/previous-step';
import UpDownArrow from '../shared/buttons/up-down-arrow';
import { findWhere } from 'underscore';
import LoadingSpinner from 'components/shared/loading-spinner';

const TaskStep = ({initiatedFlow, step, activeStep, isCurrent, attemptedAdvance, position, teamFriendlyId}) => {
  const [expanded, setExpanded] = useState(isCurrent);
  const [loading, setLoading] = useState(false);
  const [stepData, setStepData] = useState(isCurrent ? step : null)

  useEffect(() => {
    if (expanded && !loading) {
      setLoading(true);
      fetchStep(step.id, step.generic_type, initiatedFlow.id)
        .done((res) => {
          setStepData(res);
          setLoading(false);
        });
    }
  }, [expanded])

  function findActivity() {
    return findWhere(initiatedFlow.step_completion_activities, { previous_step: `${step.id}` })
  }

  function renderStep() {
    if (!stepData) {
      return (
        <div className='mytasks-loading-wrapper nopadding'>
          <LoadingSpinner size='large' />
        </div>
      )
    }

    if (isCurrent) {
      return (
        <CurrentStep
          step={stepData}
          teamFriendlyId={teamFriendlyId}
          attemptedAdvance={attemptedAdvance}
          initiatedFlow={initiatedFlow}
          activeStep={activeStep}
          currentUserId={CityGrows.Server.currentUserId}
          admin
          taskView
        />
      )
    }

    if (position === 'previous') {
      return (
        <>
          {renderStepInformation()}
          <PreviousStep step={stepData} initiatedFlow={initiatedFlow} collectedData={activeStep.collected_data} />
        </>
      );
    }
  }

  function renderStepInformation() {
    let text = 'Pending completion of prior steps';

    if (position === 'previous') {
      const activity = findActivity();
      if (!activity) {
        text = '';
      } else {
        text = activity.type === 'AdvanceStep' ? `Step completed by ${activity.completer} | ${activity.date}` : `Step autoadvanced | ${activity.date}`;
      }
    }

    return <p className='mytasks-stepinformation'>{text}</p>;
  }

  function renderContent() {
    if (expanded) {
      return renderStep();
    }

    return renderStepInformation();
  }

  return (
    <li key={step.position} className={`mytasks-step ${position}`}>
      <h3 className='step-header'>{step.position}. {step.name_or_default}</h3>
      {(position === 'previous') && <div className='float-right inline-block'><UpDownArrow expanded={expanded} setExpanded={setExpanded}/></div>}
      <div>
        {renderContent()}
      </div>
    </li>
  );
}

export default TaskStep