import { findWhere, without, filter, each } from 'underscore';
import invariant from 'invariant';
import Constants from 'constants/entity-configuration-constants';

function entityConfigurationReducer(state, action) {
  let entity = null;
  switch (action.type) {
  case Constants.CUSTOM_ADDED:
    entity = findWhere(state.customEntities, { id: action.field.custom_entity_id });
    invariant(entity, 'Unable to find expected entity');

    entity.custom_entity_fields.push(action.field);
    return { ...state };
  case Constants.ENTITY_UPDATED:
    entity = findWhere(state.customEntities, { id: action.entityId });
    invariant(entity, 'Unable to find expected entity');
    Object.assign(entity, action.attrs);
    return { ...state };
  case Constants.CUSTOM_UPDATED:
    entity = findWhere(state.customEntities, { id: action.field.custom_entity_id });
    invariant(entity, 'Unable to find expected entity');

    let fieldToUpdate = findWhere(entity.custom_entity_fields, { id: action.field.id });
    Object.assign(fieldToUpdate, action.field);
    return { ...state };
  case Constants.CUSTOM_REMOVED:
    each(state.customEntities, (customEntity) => {
      const field = findWhere(customEntity.custom_entity_fields, { id: action.fieldId });
      if (field) {
        customEntity.custom_entity_fields = without(customEntity.custom_entity_fields, field);
      }
    });
    return { ...state };
  case Constants.CUSTOM_ENTITY_CREATED:
    return { ...state, customEntities: [...state.customEntities, action.newCustomEntity] };
  case Constants.CUSTOM_ENTITY_ARCHIVED:
    return { ...state, customEntities: filter(state.customEntities, (e) => e.id !== action.customEntityId) };
  default:
    throw new Error();
  }
}

export default entityConfigurationReducer
