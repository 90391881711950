import React from 'react';
import XClose from 'components/shared/x-close';
import useFocusTrap from 'components/hooks/use-focus-trap';

const SlidingPanel = (props) => {
  const focusEl = useFocusTrap(props.onCloseClick)

  return (
    <div ref={props.transitionRef} className='ifslidingpanel' >
      <div ref={focusEl} >
        <div className='ifslidingpanel-header'>
          <h2>{props.title}</h2>
          <XClose id='closepanel' onClick={props.onCloseClick} label={`Close ${props.title}`} />
        </div>
        <div className='ifslidingpanel-contentwrap'>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default SlidingPanel;
