const BatchInitiatedFlowsAPI = {
  halt(initiatedFlowIds) {
    const url = '/initiated_flows/batch/halt';
    return $.ajax(url, {
      dataType: 'json',
      method: 'POST',
      data: {
        initiated_flow_ids: initiatedFlowIds,
      },
    });
  },

  delete(items) {
    const url = '/initiated_flows/batch/delete';
    return $.ajax(url, {
      dataType: 'json',
      method: 'DELETE',
      data: {
        items,
      },
    });
  },

  export(items) {
    const url = '/initiated_flows/batch/export';
    return $.ajax(url, {
      dataType: 'json',
      method: 'GET',
      data: {
        items,
      },
    });
  },

  assign(initiatedFlowIds) {
    const url = 'initiated_flows/batch/assign';
    return $.ajax(url, {
      dataType: 'JSON',
      method: 'POST',
      data: {
        initiated_flow_ids: initiatedFlowIds,
      },
    });
  },

  unassign(initiatedFlowIds) {
    const url = 'initiated_flows/batch/unassign';
    return $.ajax(url, {
      dataType: 'JSON',
      method: 'POST',
      data: {
        initiated_flow_ids: initiatedFlowIds,
      },
    });
  },
};

export default BatchInitiatedFlowsAPI;
