import React from 'react';
import PropTypes from 'prop-types';
import AdvancedLabel from 'components/shared/advanced-label';
import SlidingToggle from 'components/shared/sliding-toggle';

const AdvancedSettings = ({
  required,
  redacted,
  lockOnRenewal,
  autofillEnabled,
  autofillOnRenewal,
  onDataChanged,
}) => {
  const onRequiredChange = () => {
    onDataChanged({ required: !required });
  };

  const onRedactedChange = () => {
    onDataChanged({ redacted: !redacted });
  };

  const onToggleLockOnRenewal = () => {
    onDataChanged({ lock_on_renewal: !lockOnRenewal });
  };

  const onToggleAutofillOnRenewal = () => {
    onDataChanged({ autofill_on_renewal: !autofillOnRenewal });
  };

  const renderLockOnRenewal = () => {
    return (
      <div className='fieldmanager-advancedsettings-lockonrenewal'>
        <SlidingToggle
          onToggle={onToggleLockOnRenewal}
          on={lockOnRenewal}
          label='Lock when renewal'
          className='margin-right'
        />
      </div>
    );
  };

  const renderAutofillOnRenewal = () => {
    const toggleValue = autofillEnabled ? autofillOnRenewal : false;
    const title = 'To enable this setting, turn on Renewal autofilling in the Outputs settings';
    return (
      <div className='fieldmanager-advancedsettings-autofillonrenewal padding-top' {...!autofillEnabled && { title }}>
        <SlidingToggle
          onToggle={onToggleAutofillOnRenewal}
          on={toggleValue}
          label='Autofill when renewal'
          className='margin-right'
          disabled={!autofillEnabled}
        />
      </div>
    );
  };

  return (
    <>
      <tr>
        <td>
          <AdvancedLabel
            label='Required'
          />
        </td>
        <td>
          <SlidingToggle
            onToggle={onRequiredChange}
            on={required}
            label='Applicant is required to enter information'
            className='margin-right'
          />
        </td>
      </tr>
      <tr>
        <td>
          <AdvancedLabel
            label='Open Data'
            required
            helperText='Defines if the field is visible to the public in your open data portal'
          />
        </td>
        <td>
          <SlidingToggle
            onToggle={onRedactedChange}
            on={!redacted}
            label='Included in public Open Data'
          />
        </td>
      </tr>
      <tr className='with-divider'>
        <td>
          <AdvancedLabel
            label='Renewals'
          />
        </td>
        <td>
          {renderLockOnRenewal()}
          {renderAutofillOnRenewal()}
        </td>
      </tr>
    </>
  );
};

AdvancedSettings.propTypes = {
  redacted: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  lockOnRenewal: PropTypes.bool.isRequired,
  autofillEnabled: PropTypes.bool.isRequired,
  autofillOnRenewal: PropTypes.bool,
  onDataChanged: PropTypes.func.isRequired,
};

export default AdvancedSettings;
