
import 'select2';

const knew = () => {
  $('select').select2();

  // PII
  $('#account-form').submit(() => {
    if ($('#pin').length === 0) { return null; }
    const $form = $(this);

    // Disable the submit button to prevent repeated clicks
    $form.find('input[type=submit]').prop('disabled', true);
    Stripe.piiData.createToken({
      personal_id_number: $('#pin').val(),
    }, stripeResponseHandler);

    // Prevent the form from submitting with the default action
    return false;
  });

  function stripeResponseHandler(status, response) {
    const $form = $('#account-form');

    if (response.error) {
      // Show the errors on the form
      $form.find('.payment-errors').text(response.error.message);
      $form.find('input[type=submit]').prop('disabled', false);
    } else {
      // response contains id and card, which contains additional card details
      const token = response.id;
      // Insert the token into the form so it gets submitted to the server
      $form.append($('<input type="hidden" name="verification[legal_entity][personal_id_number]" />').val(token));
      // and submit
      $form.get(0).submit();
    }
  }
};

export default {
  new: knew,
};
