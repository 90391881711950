import React from 'react';

class NoPaymentRequiredView extends React.Component {
  render() {
    let text = 'No Payment is required.';
    if (this.props.admin && this.props.waived) {
      text = 'Payment Waived.';
    }
    return (
      <div>
        <p>{text}</p>
      </div>
    );
  }
}

export default NoPaymentRequiredView;
