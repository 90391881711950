import React from 'react';

const DataBox = ({number, label, children}) => {
  return (
    <div className='metricsdashboard-databox'>
      <h2 className='bold padding-top'>
        {label}
      </h2>
      <p className='metricsdashboard-number'>{number}</p>
      {children}
    </div>
  );
};

export default DataBox;
