import NProgress from 'nprogress';
import API from '../apis/step-approval-api';
import SearchAPI from '../apis/search-api';
import ReviewRequestsAPI from '../apis/review-requests-api';
import Flash from '../components/shared/flash';

const ReviewActions = {
  approve(reviewGroupId, activeStepId, approvalStatus, opts) {
    NProgress.start();

    const req = API.approve(activeStepId, reviewGroupId, approvalStatus, opts)
    .fail(() => {
      Flash.error();
    })
    .always(() => { NProgress.done(); });

    return req;
  },

  requestReview(reviewGroupId, activeStepId, receivingUserId) {
    return ReviewRequestsAPI.create({
      review_group_id: reviewGroupId,
      active_step_id: activeStepId,
      receiving_user_id: receivingUserId,
    });
  },

  requestNonUserReview(reviewGroupId, activeStepId, email, name) {
    NProgress.start();

    return ReviewRequestsAPI.create({
      review_group_id: reviewGroupId,
      active_step_id: activeStepId,
      email,
      name,
    })
    .always(() => {
      NProgress.done();
    });
  },

  undoRequestReview(reviewRequestId) {
    NProgress.start();

    return ReviewRequestsAPI.destroy(reviewRequestId)
      .done(() => { Flash.success('Request canceled'); })
      .fail(() => { Flash.error(); })
      .always(() => { NProgress.done(); });
  },

  searchReviewers(terms, initiatedFlowId) {
    return SearchAPI.searchThirdPartyReviewers(terms, initiatedFlowId);
  },

  submitThirdPartyReview(token, signatureData) {
    NProgress.start();

    const request = ReviewRequestsAPI.doReview(token, signatureData)
                    .done(() => { Flash.success('Review completed successfully. You\'re all done!'); })
                    .fail(() => { Flash.error(); })
                    .always(() => { NProgress.done(); });
    return request;
  },
};

export default ReviewActions;
