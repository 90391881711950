import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'underscore';
import { CSSTransition } from 'react-transition-group';
import XClose from 'components/shared/x-close';

class AdminNotif extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
    };
  }

  onCloseClick = () => {
    if (isFunction(this.props.onCloseClick)) {
      this.props.onCloseClick();
    } else {
      this.setState({
        open: false,
      });
    }
  };

  render() {
    const { children } = this.props;
    const { open } = this.state;

    return (
      <CSSTransition
        classNames='adminnotif'
        timeout={250}
        in={open}
        mountOnEnter
        unmountOnExit
      >
        <div className={`adminnotif ${this.props.type}`}>
          {children}
          <XClose label='Close notification' onClick={this.onCloseClick} />
        </div>
      </CSSTransition>
    );
  }
}

AdminNotif.propTypes = {
  children: PropTypes.node,
  onCloseClick: PropTypes.func,
  type: PropTypes.string,
};

AdminNotif.defaultProps = {
  type: '',
};

export default AdminNotif;
