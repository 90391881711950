import React from 'react';

const HeaderDisc = ({avatar}) => {
  if (avatar) {
    const bStyle = {
        backgroundImage: `url('${avatar}')`,
        backgroundSize: 'cover',
        display: 'inline-block',
        height: '36px',
        width: '36px',
        borderRadius: '50%',
      };

    return <span style={bStyle} />
  }

  return (
    <span className='header-avatar-placeholder' />
  );
};

export default HeaderDisc;