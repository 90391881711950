import React, { useEffect, useRef } from 'react';
import {
  Chart, BarController, CategoryScale, LinearScale, BarElement,
} from 'chart.js';

Chart.register(BarController, CategoryScale, LinearScale, BarElement);

const BarChart = (props) => {
  const chart = useRef(null);

  useEffect(() => {
    const options = { ...props.options };
    new Chart(chart.current, {
      type: 'bar',
      data: props.data,
      options,
    });
  }, []);

  return (
    <canvas ref={chart} id='myChart' width='1024' height='512' />
  );
};

export default BarChart;
