const FilterAPI = {
  save(filter, dfault) {
    const url = '/filters';
    return $.ajax(url, {
      method: 'POST',
      dataType: 'JSON',
      data: {
        filter: filter,
        default: dfault
      }
    });
  },

  update(id, filter, dfault) {
    const url = `/filters/${id}`;
    return $.ajax(url, {
      method: 'PUT',
      dataType: 'JSON',
      data: {
        filter: filter,
        default: dfault
      }
    });
  },

  remove(id) {
    const url = `/filters/${id}`;
    return $.ajax(url, {
      method: 'DELETE',
      dataType: 'JSON'
    });
  },

  toggleDefault(id) {
    const url = '/filters/toggle_default';
    return $.ajax(url, {
      method: 'PUT',
      dataType: 'JSON',
      data: {id: id}
    });
  },

  templates() {
    const url = '/filters/templates';

    return $.ajax(url, {
      method: 'GET',
      dataType: 'JSON'
    });
  }
};

export default FilterAPI;
