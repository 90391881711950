import React from "react";

import ajaxDownload from 'modules/ajax-download';
import { show as outputsShow } from 'apis/outputs-api';
import { error } from 'components/shared/flash';
import { alert } from 'modules/alert-confirm';
import BackgroundedJobLink from 'components/shared/backgrounded-job-link';


const DownloadLinks = ({ outputId }) => {
  const renderDownloadIcon = () => {
    return (
      <div className='ifslidingpanel-btniconwrap'>
        <i className='vmiddle icon-download-grey margin-right-less' />
      </div>
    );
  };

  const renderSummaryWithFilesLink = () => {
    return (
      <BackgroundedJobLink
        queueFunction={() => { return outputsShow(outputId, true); }}
        onJobComplete={onSummaryFinishedGenerating}
        onFail={error}
        workingText='Downloading...'
        className='ifslidingpanel-button'
      >
        <span>
          {renderDownloadIcon()}
          Download all documents and uploaded files
        </span>
      </BackgroundedJobLink>
    );
  };

  const alertWithDownloadURL = (url) => {
    alert('Download ready', `<a target='_blank' href='${url}'>Click here to download your file</a>`);
  };

  const onSummaryFinishedGenerating = (res) => {
    const parsed = JSON.parse(res);
    const url = parsed.file_url;
    const fileName = parsed.file_name;

    ajaxDownload(url, fileName)
      .catch(() => { alertWithDownloadURL(url); });
  };

  return (
    <div id='menuoutputs'>
      {renderSummaryWithFilesLink()}
    </div>
  );
};

export default DownloadLinks;
