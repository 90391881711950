import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Popdown from 'components/shared/popdown';
import useOnclickOutside from 'react-cool-onclickoutside';

const HelpLinks = () => {
  const [isOpen, setIsOpen] = useState(false);
  const elForAnchor = useRef();
  const innerContentEl = useRef();

  useOnclickOutside(() => {
    setIsOpen(false);
  }, { refs: [elForAnchor], ignoreClass: 'popdown' });

  function renderPopdown() {
    if (!isOpen) { return; }

    return (
      <Popdown anchorRef={elForAnchor} justify='right' className='helplinks-popdown'>
        <ul className='popdown-listmenu' ref={innerContentEl}>
          <li><a href='https://support.cleargov.com/' target='_blank'>Support Center</a></li>
          <li><a href='https://cleargov.learnupon.com/' target='_blank'>Cleargov Academy</a></li>
        </ul>
      </Popdown>
    );
  };

  return (
    <>
      <button className='help-icon' ref ={elForAnchor}  onClick={() => { setIsOpen(!isOpen); }}>
        <FontAwesomeIcon icon={icon({ name: 'circle-question' })} size='xl' color='#54ADFB' />
      </button>
      {renderPopdown()}
    </>
  )
}

export default HelpLinks;
