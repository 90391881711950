import React from 'react';

const RemoveEntityButton = (props) => {
  return (
    <button className='btn-link float-right' title={`Remove ${props.name}`} onClick={props.removeEntity}>
      <span className='icon icon-trash-grey bigger' />
    </button>
  )
}

export default RemoveEntityButton;
