import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';

const PaginationButtons = (props) => {
  const {
    count, page, rowsPerPage, onChangePage,
  } = props;

  const lastPage = Math.ceil(count / rowsPerPage);

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, lastPage - 1);
  };

  const isFirstPage = () => { return page === 0; };

  const isLastPage = () => { return page === lastPage - 1; };

  return (
    <div className='pagination-actions'>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={isFirstPage()}
        aria-label='first page'
      >
        <i className='icon-skip-left bigger' />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={isFirstPage()}
        aria-label='previous page'
      >
        <i className='icon-arrow-left bigger' />
      </IconButton>
      <p>Page {page + 1} of {lastPage}</p>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={isLastPage()}
        aria-label='next page'
      >
        <i className='icon-arrow-right bigger' />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={isLastPage()}
        aria-label='last page'
      >
        <i className='icon-skip-right bigger' />
      </IconButton>
    </div>
  );
};

PaginationButtons.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default PaginationButtons;
