const TeamDomainAPI = {
  create: (teamId, domain) => {
    const url = `/teams/${teamId}/team_domains`;

    return $.ajax(url, {
      method: 'POST',
      dataType: 'json',
      data: {
        team_domain: {
          domain
        }
      }
    });
  },

  destroy: (teamDomainId) => {
    const url = `/team_domains/${teamDomainId}`;

    return $.ajax(url, {
      method: 'DELETE',
      dataType: 'json'
    });
  }
};

export default TeamDomainAPI;