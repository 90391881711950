import React, { useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import PersonSelector from 'components/shared/person-selector';
import { isEmpty } from 'underscore';
import InitiatedFlowActions from 'actions/initiated-flow-actions';
import ApplicantAttachmentActions from 'actions/applicant-attachment-actions';
import UserDisc from 'components/shared/user-disc';

const ApplicantManager = (props) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);
  const [managingApplicants, setManagingApplicants] = useState(false);

  const isPrimaryApplicant = useMemo(() => {
    const primaryApplicant = props.applicantAttachments.find((applicant) => applicant.is_primary);
    return primaryApplicant.user_id === window.CityGrows.Server.currentUserId;
  }, [props.applicantAttachments]);

  const canManage = useMemo(() => {
    return CityGrows.Server.admin || isPrimaryApplicant;
  }, [isPrimaryApplicant]);

  const searchPerson = (search) => {
    return InitiatedFlowActions.searchApplicants(search);
  };

  const onApplicantUserSelect = (user) => {
    ApplicantAttachmentActions.addApplicant(dispatch, user.id, props.initiatedFlowId)
      .done(() => {
        setManagingApplicants(!managingApplicants);
      });
  };

  function onSendInviteButtonClicked(email, name) {
    ApplicantAttachmentActions.addPseudoApplicant(dispatch, email, name, props.initiatedFlowId)
      .done(() => {
        setManagingApplicants(!managingApplicants);
      });
  }

  const renderApplicants = () => {
    if (isEmpty(props.applicantAttachments)) { return; }
    const primaryApplicant = props.applicantAttachments.find((applicant) => applicant.is_primary);
    const otherApplicants = props.applicantAttachments.filter((applicant) => { return !applicant.is_primary; });
    return (
      <>
        <div className='applicantmanager-label margin-bottom'>Primary Applicant</div>
        <ul id='primaryapplicant' className='applicantmanager-applicants'>
          {renderApplicant(primaryApplicant)}
        </ul>

        {!isEmpty(otherApplicants) && (
          <>
            <div className='applicantmanager-label margin-bottom'>Other Applicants</div>
            <ul id='applicantlist' className='applicantmanager-applicants'>
              {otherApplicants.map(renderApplicant)}
            </ul>
          </>
        )}
      </>
    );
  };

  function onApplicantDelete(e, applicant) {
    ApplicantAttachmentActions.removeApplicant(dispatch, applicant.id, props.initiatedFlowId);
  }

  function onPrimaryApplicantChange(e, applicant) {
    ApplicantAttachmentActions.makePrimary(dispatch, applicant.id, props.initiatedFlowId);
  }

  const renderApplicant = (applicant) => {
    const showMakePrimaryButton = canManage && !applicant.is_primary && !applicant.pseudo;
    const showDeleteButton = canManage && !applicant.is_primary;
    return (
      <li key={applicant.id} className='cf'>
        <div title={applicant.name} className='float-left'>
          <UserDisc
            user={applicant}
            options={{
              showName: true,
              showPseudo: true,
              showAsCurrent: applicant.user_id === CityGrows.Server.currentUserId,
            }}
          />
        </div>
        {(showMakePrimaryButton || showDeleteButton) && (
          <div className='float-right'>
            <div className='applicantmanager-actions'>
              {showMakePrimaryButton && (
                <>
                  <button className='btn-link' type='button' onClick={(e) => { onPrimaryApplicantChange(e, applicant); }}>Make primary</button>
                  &nbsp;
                </>
              )}
              {showDeleteButton && (
                <i className='icon-trash-grey small clickable' onClick={(e) => { onApplicantDelete(e, applicant); }} />
              )}
            </div>
          </div>
        )}
      </li>
    );
  };

  function onManageApplicantsClick(e) {
    e.preventDefault();
    setManagingApplicants(!managingApplicants);
  }

  const renderSearch = () => {
    return (
      <PersonSelector
        onSelect={onApplicantUserSelect}
        doSearch={searchPerson}
        allowPseudo
        hideExternal={false}
        placeholder='Search people'
        attachableType='Applicant'
        onInvite={onSendInviteButtonClicked}
      />
    );
  };

  return (
    <div className='applicantmanager-wrap'>
      <div className='applicantmanager relative'>
        <div className='applicantmanager-header cf'>
          <div className='float-left'>
            <label>
              Applicants
            </label>
          </div>
          {canManage && (
            <div className='float-right'>
              <button id='manageapplicant' className='btn-link' type='button' onClick={onManageApplicantsClick}>Manage</button>
            </div>
          )}
        </div>

        <div className='applicantmanager-body'>
          {managingApplicants && renderSearch()}

          {renderApplicants()}
        </div>
      </div>
    </div>
  );
};

ApplicantManager.propTypes = {
  applicantAttachments: PropTypes.arrayOf(PropTypes.shape({})),
  initiatedFlowId: PropTypes.number.isRequired,
};

export default ApplicantManager;
