import React, { useState } from 'react';
import Select from '../shared/select';
import DateSelector from '../shared/date-selector';
import { css } from '@emotion/react';

const Filters = ({ formsArray, teamsArray, applyFilters }) => {
  const [teams, setTeams] = useState(['all']);
  const [forms, setForms] = useState(['all']);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const teamsDataForSelect = [
    { value: 'all', label: 'All teams' },
    ...teamsArray.map(({ id, name }) => ({
      value: id,
      label: name,
    })),
  ];

  function formDataForSelect() {
    let frms = formsArray;

    if (!teams.includes('all')) {
      frms = formsArray.filter((form) => {
        return teams.includes(form.teamId);
      });
    }

    return [
      { value: 'all', label: 'All forms' },
      ...frms.map(({ master_template_id: id, name_or_default: name }) => ({
        value: id,
        label: name,
      })),
    ];
  }

  function onFormsChange(newForms) {
    let fms = [];
    newForms.every((form) => {
      if (form.value === 'all' && !forms.includes('all')) {
        fms = ['all'];
        return false;
      }

      if (form.value !== 'all') {
        fms = [...fms, Number(form.value)];
        return true;
      }

      return true;
    });
    setForms(fms);
  }

  function onTeamsChange(newTeams) {
    let tms = [];
    newTeams.every((team) => {
      if (team.value === 'all' && !teams.includes('all')) {
        tms = ['all'];
        return false;
      }

      if (team.value !== 'all') {
        tms = [...tms, Number(team.value)];
        return true;
      }

      return true;
    });
    setTeams(tms);
  }

  function onDateChange(type, date) {
    if (type === 'start') {
      return setStartDate(date);
    }

    if (type === 'end') {
      return setEndDate(date);
    }
  }

  function onFilterApply() {
    const tms = teams.includes('all') ? [] : teams;
    const template_ids = forms.includes('all') ? [] : forms;
    const filters = {
      launched_from: startDate,
      launched_to: endDate,
      team_id: tms,
      template_ids,
    };

    applyFilters(filters);
  }

  return (
    <div
      className='padded border-bottom sticky'
      css={css`
        position:sticky;
        top:-1rem;
        background-color:#fff;
      `}
    >
      <div className='metricsdashboard-filterlabel bold'>Filter</div>
      <div className='metricsdashboard-filterselect wide-25'>
        <Select
          options={teamsDataForSelect}
          isMulti
          onChange={onTeamsChange}
          value={teamsDataForSelect.filter(({ value }) => teams.includes(value))}
          className='react-select-metrics padding-top'
        />
      </div>
      <div className='metricsdashboard-filterselect wide-25'>
        <Select
          options={formDataForSelect()}
          isMulti
          value={formDataForSelect().filter(({ value }) => forms.includes(value))}
          onChange={onFormsChange}
          className='react-select-metrics padding-top'
        />
      </div>
      <div className='metricsdashboard-datepicker padding-left'>
        <DateSelector
          value={startDate}
          onDateChange={(e) => { onDateChange('start', e); }}
          type='date'
          placeholderText='From'
          withPortal={false}
        />
      </div>
      <span className='padding-left-less padding-right-less'>to</span>
      <div className='metricsdashboard-datepicker padding-right'>
        <DateSelector
          value={endDate}
          onDateChange={(e) => { onDateChange('end', e); }}
          type='date'
          placeholderText='To'
          withPortal={false}
        />
      </div>
      <button className='btn-primary' onClick={onFilterApply}>Apply Filter</button>
    </div>
  );
};

export default Filters;
