import React from 'react';
import TopRow from 'components/metrics_dashboard/finances/top-row';
import SecondRow from 'components/metrics_dashboard/finances/second-row';

const Finances = ({transactionData}) => {
  return (
    <div>
      <TopRow transactionData={transactionData} />
      <SecondRow transactionData={transactionData} />
    </div>
  )
}

export default Finances;
