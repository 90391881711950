var InitiatedFlowAPI = {
  launchProcess: function (flowTemplateId, opts) {
    const URL = '/initiated_flows/';

    return $.ajax(URL, {
      method: 'POST',
      data: {
        initiated_flow: {
          flow_template_id: flowTemplateId,
        },
        primary_applicant_id: opts.primaryApplicantId,
        secondary_applicant_ids: opts.secondaryApplicantIds,
        subprocess_requirement_id: opts.subprocessRequirementId,
      },
      dataType: 'json',
    });
  },

  previouslyLaunched: function (masterTemplateId) {
    const URL = `/initiated_flows/previously_launched/${masterTemplateId}`;

    return $.ajax(URL, {
      method: 'GET',
      dataType: 'json'
    });
  },

  update: function (id, attrs) {
    const URL = `/initiated_flows/${id}`;

    return $.ajax(URL, {
      method: 'PUT',
      data: {
        initiated_flow: attrs
      },
      dataType: 'json'
    });
  },

  destroyProcess: function (id) {
    const URL = `/initiated_flows/${id}`;

    return $.ajax(URL, {
      method: 'DELETE',
      dataType: 'json'
    });
  },

  parseVariableText: function (textType, textContainerId, id) {
    const URL = `/initiated_flows/${id}/parse_text`;

    return $.ajax(URL, {
      method: 'GET',
      data: {
        text_type: textType,
        text_container_id: textContainerId
      },
      dataType: 'json'
    });
  },

  documentsAndFiles: function (id) {
    const URL = `/initiated_flows/${id}/documents_and_files`;

    return $.ajax(URL, {
      method: 'GET',
      dataType: 'json'
    });
  },

  uploadRepoFile: function (id, file) {
    const URL = `/initiated_flows/${id}/upload_repo_file`;

    const data = new FormData();
    data.append('file', file);

    return $.ajax(URL, {
      method: 'POST',
      processData: false,
      contentType: false,
      data: data,
    });
  },

  deleteRepoFile: function (id, fileId) {
    return $.ajax(`/initiated_flows/${id}/delete_repo_file`, {
      method: 'DELETE',
      dataType: 'json',
      data: {
        file_id: fileId
      }
    });
  },

  show: function (id) {
    return $.ajax(`/initiated_flows/${id}`, {
      method: 'GET',
      dataType: 'JSON',
    })
  },

  doNextStep: function (id, stepId) {
    return $.ajax(`/initiated_flows/${id}/do_next_step`, {
      method: 'POST',
      dataType: 'json',
      data: {
        step_id: stepId,
      },
    })
  },
};

export default InitiatedFlowAPI;
