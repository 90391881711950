import API from 'apis/role-api';

export const create = (role, teamId, addUserToRole) => {
  return API.create(role, teamId, addUserToRole);
};

export const update = (role, teamId, addUserToRole) => {
  return API.update(role, teamId, addUserToRole);
};

export const destroy = (roleId, teamId) => {
  return API.destroy(roleId, teamId);
};

export const toggleAttachment = (attachmentId, attachmentType, roleId) => {
  return API.toggleAttachment(attachmentId, attachmentType, roleId);
};

export default {
  create,
  update,
  destroy,
  toggleAttachment,
};
