import DashboardAPI from 'apis/dashboard-api';

const _pageSize = 20;
let _isLoading = false;

const useLoadMoreOutputs = (index, query, addItems) => {
  if (_isLoading) { return; }

  _isLoading = true;
  const page = Math.floor(index / _pageSize) + 1;
  let params = {
    ...query,
    next_page: page
  }

  DashboardAPI.fetchOutputs(params).done((res) => {
    _isLoading = false;
    addItems(res.outputs);
  });
}

export default useLoadMoreOutputs;
