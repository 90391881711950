import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PollableJob from 'modules/pollable-job';
import LoadingSpinner from 'components/shared/loading-spinner';
import { show as outputsShow } from 'apis/outputs-api';

const IssuedOutput = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [documentURL, setDocumentURL] = useState();
  const [loadingDidFail, setLoadingDidfail] = useState(false);

  const onGenerateComplete = (res) => {
    const parsed = JSON.parse(res);
    const url = parsed.file_url;
    setDocumentURL(url);
    setIsLoaded(true);
    document.location = url;
  };

  const startDownload = () => {
    const jobRunner = new PollableJob(() => { return outputsShow(props.issuedOutput.id, false); });
    jobRunner.run()
             .done(onGenerateComplete)
             .fail(() => { setLoadingDidfail(true); });
  };

  useEffect(() => {
    startDownload();
  }, []);

  const renderContent = () => {
    if (isLoaded) {
      return (
        <a href={documentURL}>Click to download {props.issuedOutput.name}</a>
      );
    } if (loadingDidFail) {
      return <span>Unable to generate document at this time. Please try again later.</span>;
    }

    return (
      <>
        <span className='inline-block nomargin vmiddle margin-right'>Generating <strong>{props.issuedOutput.name}</strong></span> <LoadingSpinner size='medium' className='vmiddle' />
      </>
    );
  };

  return (
    <div className='well'>
      {renderContent()}
    </div>
  );
};

IssuedOutput.propTypes = {

};

export default IssuedOutput;
