import React from 'react';
import renderer from 'modules/renderer';

import 'select2';

import CommonProviders from 'components/common-providers';
import EntityPage from '../components/entity-page';
import Directory from './directory';

function show() {
  renderer(
    (
      <CommonProviders>
        <EntityPage
          entity={CityGrows.Server.entityData}
          teamSlug={CityGrows.Server.teamSlug}
        />
      </CommonProviders>
    ), document.getElementById('entity'),
  );
}

function index() {
  Directory.init();

  $('#orgstatusselect, #customentityselect').select2({
    minimumResultsForSearch: -1,
    style: 'width',
  });
}

export default {
  show,
  index,
};
