import React from 'react';
import _ from 'underscore';
import OverviewStep from './overview-step';

const ConstStepGroup = (props) => {
  const renderStep = (step) => {
    const nextStep = findStepAfter(step);
    const prevStep = findStepBefore(step);
    const medianTime = props.digest ? props.digest.median_step_times[step.id] : null;

    return (
      <OverviewStep
        step={step}
        lastStepPos={_.last(props.steps).position}
        cityName={props.cityName}
        respWillChange={!nextStep}
        respDidChange={step.position === 1 || !prevStep}
        key={step.id}
        medianTime={medianTime}
      />
    );
  };

  const findStepAfter = (step) => {
    return _.findWhere(props.steps, { position: step.position + 1 });
  };

  const findStepBefore = (step) => {
    return _.findWhere(props.steps, { position: step.position - 1 });
  };

  return props.steps.map(renderStep);
};

export default ConstStepGroup;
