import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import DiscussionDispatchContext from 'contexts/initiated-flow-dispatch-context';
import DiscussionActions from 'actions/discussion-actions';
import { success } from 'components/shared/flash';
import TimeAgo from 'react-timeago';
import dayjs from 'dayjs';
import XClose from 'components/shared/x-close';

const DiscussionComment = (props) => {
  const dispatch = useContext(DiscussionDispatchContext);
  function onDeleteClick() {
    DiscussionActions
      .deleteDiscussionComment(dispatch, props.discussionComment.id)
      .done(() => { success('Comment deleted'); });
  }

  function renderDeleteButton() {
    if (props.discussionComment.user.id === window.CityGrows.Server.currentUserId) {
      return <XClose buttonClass='float-right' onClick={onDeleteClick} label='Delete comment'/>;
    }
  }

  return (
    <div className='discussion-comment sub'>
      <div className='cf'>
        <span className='discussion-name margin-right-less'>{props.discussionComment.user.name}</span>
        <TimeAgo
          date={props.discussionComment.created_at}
          className='timeago discussion-timestamp'
          title={dayjs(props.discussionComment.created_at).format('LLL')}
        />
        {renderDeleteButton()}
      </div>

      <div className='discussion-body'>{props.discussionComment.body}</div>
    </div>
  );
};

DiscussionComment.propTypes = {
  discussionComment: PropTypes.shape({
    id: PropTypes.number,
    body: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    created_at: PropTypes.string,
  }),
};

export default DiscussionComment;
