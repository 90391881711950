import keyMirror from 'keymirror'

export default keyMirror({
	'LOADED': null,
	'UPDATED': null,
	'CREATED': null,
	'DESTROYED': null,
	'COMMENT_ADDED': null,
	'COMMENT_DELETED': null,
}, 'DISCUSSION');
