import React from 'react';
import DatePicker from 'react-datepicker';
import { formatIncoming, formatOutgoing, dateFormats } from 'modules/date-formatter';
import { isFunction } from 'underscore';

const DateSelector = (props) => {
  const onSelect = (date) => {
    props.onDateChange(formatOutgoing(date, props.type));
  };

  const handleOnBlur = () => {
    if (isFunction(props.onBlur)) {
      props.onBlur();
    }
  };

  return (
    <DatePicker
      ref={props.innerRef}
      id={props.id}
      onBlur={handleOnBlur}
      selected={formatIncoming(props.value, props.type)}
      className={props.className}
      onChange={onSelect}
      dateFormat={dateFormats(props.type)}
      placeholderText={props.placeholderText}
      showTimeSelect={props.type !== 'date'}
      showTimeSelectOnly={props.type === 'time'}
      timeIntervals={15}
      showMonthDropdown
      showYearDropdown
      dropdownMode='select'
      withPortal={props.withPortal}
    />
  );
};

export default DateSelector;
