import React from 'react';
import SignaturePrompt from 'modules/signature-prompt';
import dayjs from 'dayjs';

import _ from 'underscore';

class SignatureDetails extends React.Component {
  renderMostRecentSigning = () => {
    if (!this.wasEverSigned()) { return; }

    const signing = _.first(this.props.signings);
    const altText = `Image of ${signing.user.name}'s signature`;
    const titleText = `${signing.user.name}'s signature`;
    if (signing) {
      return (
        <div onClick={this.onSignLinkClicked} className='clickable signaturebox-sigline'>
          <span className='signaturebox-x'>x</span>
          <img src={signing.signature.url} alt={altText} title={titleText} />
        </div>
      );
    }
  };

  renderSignings = () => {
    return this.props.signings.map(this.renderSigning);
  };

  renderSigning = (signing) => {
    return (
      <div className='margin-bottom-less'>
        Signed by {signing.user.name}  <strong>{dayjs(signing.created_at).format('LLL')}</strong>
      </div>
    );
  };

  onSignLinkClicked = (e) => {
    e.preventDefault();

    if (this.props.admin) { return; }

    SignaturePrompt.requestSignature(this.props.activeStepId)
      .done((sig) => {
        if (sig) {
          window.location.reload();
        }
      });
  };

  renderInfo = () => {
    if (this.props.needsSignature) {
      let action;
      if (!this.props.admin) {
        action = <a onClick={this.onSignLinkClicked} href='#'>Sign it again</a>;
      }
      return (
        <div className='margin-bottom'>This information has been updated since it was signed for. {action}</div>
      );
    }
  };

  wasEverSigned = () => {
    const signing = _.first(this.props.signings);
    return !!signing;
  };

  render() {
    if (this.wasEverSigned()) {
      return (
        <div className='signaturebox margin-bottom'>
          <div><span className='subtitle'>Signature</span></div>
          {this.renderInfo()}
          {this.renderMostRecentSigning()}
          {this.renderSignings()}
        </div>
      );
    }

    return <div />;
  }
}

export default SignatureDetails;
