export const exportTransactions = (teamId, startDate, endDate) => {
  const url = `/teams/${teamId}/transactions/export`;

  return $.ajax(url, {
    method: 'POST',
    dataType: 'json',
    data: {
      start_date: startDate,
      end_date: endDate
    }
  });
};

export const exportCashAndChecks = (teamId, startDate, endDate) => {
  return $.ajax(`/teams/${teamId}/transactions/export_cash_and_checks`, {
    method: 'POST',
    dataType: 'json',
    data: {
      start_date: startDate,
      end_date: endDate
    }
  })
}

export default {
  exportTransactions,
  exportCashAndChecks,
};