import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import colorForApproval from 'modules/color-for-approval';
import { any, first } from 'underscore';
import InitiatedFlowConstants from 'constants/initiated-flow-constants';
import ActiveStepConstants from 'constants/active-step-constants';
import DiscussionActions from 'actions/discussion-actions';
import DiscussionDispatchContext from 'contexts/discussion-dispatch-context';
import UserDisc from 'components/shared/user-disc';
import PreviousReview from './previous-review';
import ReviewInterface from './interface';
import SubmitReviewWidget from './submit-review-widget';

const Reviewer = (props) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);
  const discussionDispatch = useContext(DiscussionDispatchContext);
  const [showPrompt, setShowPrompt] = useState(false);
  const [selectedApprovalStatus, setSelectedApprovalStatus] = useState(null);
  const [comment, setComment] = useState(null);
  const [recentlyCompletedReview, setRecentlyCompletedReview] = useState(null);

  function onReviewComplete(res) {
    setShowPrompt(false);
    setRecentlyCompletedReview(res.approval);
    setSelectedApprovalStatus(null);
    setComment(null);

    if (res.approval.approval_status === false) {
      dispatch({
        type: InitiatedFlowConstants.HALTED,
      });
    }

    DiscussionActions.loadDiscussions(props.initiatedFlow.id, discussionDispatch);

    dispatch({
      type: ActiveStepConstants.CHANGES_PERSISTED,
      reviewSubmitted: true,
    });
  }

  function onReviewStart() {
    setShowPrompt(true);
  }

  function onWidgetClose() {
    setShowPrompt(false);
  }

  function onCommentChange(newComment) {
    setComment(newComment);
  }

  function onApprovalStatusUpdate(e) {
    setSelectedApprovalStatus(e.target.value);
  }

  function submitButtonText() {
    if (any(props.reviewer.previous_reviews)) {
      return 'Submit another review';
    }

    return 'Submit review';
  }

  function renderApprovalInterface() {
    let content;

    if (recentlyCompletedReview) {
      content = (
        <div className='margin-top margin-bottom'>
          You <strong className={colorForApproval(recentlyCompletedReview)}>{recentlyCompletedReview.verb}</strong>
        </div>
      );
    } else {
      content = (
        <ReviewInterface
          onApprovalStatusUpdate={onApprovalStatusUpdate}
          onCommentChange={onCommentChange}
          onReviewStart={onReviewStart}
          submitButtonText={submitButtonText()}
          previousReviews={props.reviewer.previous_reviews}
          toName={props.initiatedFlow.primary_applicant.name}
          defaultApprovalMessage={props.defaultApprovalMessage}
          defaultRejectionMessage={props.defaultRejectionMessage}
          onOpenDiscussionClick={props.onOpenDiscussionClick}
        />
      );
    }

    return (
      <>
        <div className='linebreak nomargin-bottom' />
        {content}
      </>
    );
  }

  function renderApproval() {
    if (props.isMe) {
      return renderApprovalInterface();
    }

    return renderNonCurrentUserReview();
  }

  function mostRecentReview() {
    return first(props.reviewer.previous_reviews);
  }

  function roleIdParam() {
    if (!props.reviewer.reviewer.role_id) { return ''; }

    return `?role_id=${props.reviewer.reviewer.role_id}`;
  }

  function renderNonCurrentUserReview() {
    const approval = mostRecentReview();

    if (approval) {
      return (
        <>
          <div className='linebreak' />
          <PreviousReview approval={approval} onOpenDiscussionClick={props.onOpenDiscussionClick}/>
        </>
      );
    }

    return <strong className='margin-left text-semialert'>Review pending</strong>;
  }

  function renderReviewPrompt() {
    if (props.isMe && showPrompt) {
      return (
        <SubmitReviewWidget
          key={props.reviewer.reviewer.id}
          reviewer={props.reviewer.reviewer}
          roleId={props.reviewer.reviewer.role_id}
          reviewGroupId={props.reviewGroupId}
          activeStepId={props.activeStepId}
          affidavit={props.affidavit}
          requireSignature={props.requireSignature}
          stepId={props.stepId}
          initiatedFlowId={props.initiatedFlow.id}
          onClose={onWidgetClose}
          onReviewComplete={onReviewComplete}
          approvalStatus={selectedApprovalStatus}
          comment={comment}
        />
      );
    }
  }

  function renderReviewAppLink() {
    if (!props.isMe) { return; }

    return (
      <span className='stepapprovals-reviewapplink'>
        <span className='icon icon-external' />
        <a className='margin-left-less secondary' target='_blank' href={`/initiated_flows/${props.initiatedFlow.id}/steps/${props.stepId}/review/${props.reviewGroupId}${roleIdParam()}`} rel='noreferrer'>Open in review manager</a>
      </span>
    );
  }

  return (
    <div className={`stepapprovals-reviewerrow${props.isMe ? ' me' : ''}`}>
      <div className='stepapprovals-reviewername'>
        <UserDisc
          user={props.reviewer.reviewer}
          className='margin-right'
          options={{
            showName: true,
          }}
        />
        {renderReviewAppLink()}
        {renderApproval()}
      </div>

      {renderReviewPrompt()}
    </div>
  );
};

Reviewer.propTypes = {
  affidavit: PropTypes.string,
  initiatedFlow: PropTypes.object.isRequired,
  stepId: PropTypes.number.isRequired,
  requireSignature: PropTypes.bool.isRequired,
  isMe: PropTypes.bool.isRequired,
  reviewer: PropTypes.object.isRequired,
  reviewGroupId: PropTypes.number.isRequired,
  activeStepId: PropTypes.number.isRequired,
};

export default Reviewer;
