import React from 'react';
import PropTypes from 'prop-types';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

class LocationVisualizer extends React.Component {
  constructor(props) {
    super(props);
    this.map = React.createRef();
  }

  componentDidMount() {
    this.map = this.initMap();
    this.renderDataToMap();
  }

  renderDataToMap = () => {
    if (this.props.markerData.length === 0) { return; }

    const markers = window.L.markerClusterGroup();
    this.props.markerData.forEach((datum) => {
      const marker = window.L.marker([datum[1], datum[0]]);
      this.addPopupContentToMarker(marker, datum);
      markers.addLayer(marker);
    });

    this.map.addLayer(markers);
    this.map.fitBounds(markers.getBounds());
  };

  addPopupContentToMarker = (marker, datum) => {
    // Note: the commas look a little weird here, but it basically says
    // "ignore the first two things" and keeps eslint happy.
    const [, , title, templateName, scopedId, initiatedFlowId] = datum;
    const url = (
      this.props.public
        ? `/initiated_flows/${initiatedFlowId}/summary`
        : `/initiated_flows/manager/${initiatedFlowId}`
    );

    marker.bindPopup(`<div class="map-popup-content"><a href=${url}>#${scopedId} ${templateName}</a>${title}</div>`);
  };

  renderFeatureFromDatum = (datum) => {
    // Done directly from a pluck for performance reasons.
    // This is why data is an array and not an object.
    const [lon, lat, title, templateName, scopedId, initiatedFlowId] = datum;
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          lon,
          lat,
        ],
      },
      properties: {
        title,
        templateName,
        scopedId,
        initiatedFlowId,
      },
    };
  };

  initMap = () => {
    return window.L.mapbox.map(this.map.current, 'mapbox.streets', {
      attributionControl: false,
      zoom: 10,
      center: [34.05, -118.2],
    });
  };

  render() {
    return <div className='map' ref={this.map} />;
  }
}

LocationVisualizer.propTypes = {
  public: PropTypes.bool.isRequired,
  markerData: PropTypes.array.isRequired,
};

export default LocationVisualizer;
