import React from "react";

import Select from "../select";
import { COMPLETION_STATUSES } from "./constants";


const CompletedStatus = ({
  changeFilterValue,
  filterState,
  minify,
}) => {
  const defaultStatus = COMPLETION_STATUSES.find((opt) => opt.id == filterState.value);

  const onStatusChange = (value) => {
    changeFilterValue(value.id);
  };

  return (
    <div className='metricsdashboard-filterselect'>
      <Select
        options={COMPLETION_STATUSES}
        onChange={onStatusChange}
        getOptionValue={(opt) => opt.id}
        value={defaultStatus}
        className='react-select-metrics advanced-filter'
      />
    </div>
  );
};

export default CompletedStatus;
