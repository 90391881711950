import React from 'react';

class AddressOption extends React.Component {
  render() {
    let buttonClass = 'btn-thirdary';
    let buttonText = 'Use Submitted Address';
    let labelText = 'Submitted Address:';

    if (this.props.suggested) {
      buttonClass = 'btn-primary';
      buttonText = 'Use Suggested Address';
      labelText = 'Suggested Address:';
    }

    return (
      <div className='addressinput-option border-bottom'>
        <div className='addressinput-text'>
          <div className='bold'>
            {labelText}
          </div>
          <div>
            {this.props.value}
          </div>
        </div>
        <div className='addressinput-button align-right padded-less'>
          <button onClick={(e) => this.props.onSelect(this.props.value)} className={`${buttonClass} btn-medium`}>
            {buttonText}
          </button>
        </div>
      </div>
    );
  }
}

export default AddressOption;
