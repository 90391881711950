export function colorForApprovalStatus(approval_status) {
  switch (approval_status) {
  case true:
    return 'color-success';
  case false:
    return 'color-alert';
  case null:
    return 'text-semialert';
  default:
    throw `Unrecognized approval status ${approval_status}`;
  }
}

const colorForApproval = (approval) => {
  return colorForApprovalStatus(approval.approval_status);
};

export default colorForApproval;
