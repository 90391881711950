import PropTypes from 'prop-types';
import React, {
  useState, createRef, useRef, useCallback, useEffect, useMemo, useContext,
} from 'react';
import {
  contains, debounce, isEmpty, without,
} from 'underscore';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';

import Select from 'components/shared/select';
import usePrevious from 'components/hooks/use-previous';
import useDerivedState from 'components/hooks/use-derived-state';
import CollectInfoStepActions from 'actions/collect-info-step-actions';
import { alert } from 'modules/alert-confirm';
import { validate, validateNumberFormat, validateNumberInRange } from 'modules/collected-data-validator';
import ErrorMessages from 'constants/error-messages';
import DateSelector from 'components/shared/date-selector';
import DateRangeSelector from 'components/shared/date-range-selector';
import ActiveStepActions from 'actions/active-step-actions';
import EntityInput from './input_fields/entity-input';
import MoneyInput from './input_fields/money-input';
import AddressInput from './input_fields/address-input';
import FileInput from './input_fields/file-input';
import Map from './input_fields/address_input/map';
import AttachedFile from './input_fields/file_input/attached-file';

const getDebounceIntervalForDatum = (type) => {
  switch (type) {
  case 'address':
    return 1000;
  case 'date':
    return 0;
  case 'text':
    return 4000;
  default:
    return 500;
  }
};

const StepDataCollectorInputField = (props) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);

  const fieldCompletionBegun = () => {
    if (props.stepDatum.data_type === 'file') {
      return props.fieldValue.length > 0;
    }
    return props.fieldValue != null;
  };

  const initialStartDate = () => {
    if (props.stepDatum.data_type !== 'date_range' || !props.fieldValue) { return null; }

    return props.fieldValue.start_date;
  };

  const initialEndDate = () => {
    if (props.stepDatum.data_type !== 'date_range' || !props.fieldValue) { return null; }

    return props.fieldValue.end_date;
  };

  const ref = createRef();

  const submit = props.onInputChanged;
  const debouncedSubmit = useMemo(() => {
    return debounce(props.onInputChanged, getDebounceIntervalForDatum(props.stepDatum.data_type));
  }, [props.stepDatum.data_type]);

  const checkboxRefs = useRef([]);

  const [beganFieldCompletion, setBeganFieldCompletion] = useState(fieldCompletionBegun);
  const [version, setVersion] = useState(props.version);
  const [valid, setValid] = useState(() => !props.stepDatum.required || validate(props.fieldValue, props.stepDatum));
  // NOTE: it would be better for clarity's sake if this state only referenced one of the props,
  // but it should be okay because once fieldValue is defined, it should take precedence.
  const [localValue, setLocalValue] = useDerivedState(props.fieldValue || props.defaultValue);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const onInputChanged = (_e, sentValue, immediately = false) => {
    const findFieldValue = () => {
      const { stepDatum } = props;

      switch (stepDatum.data_type) {
      case 'checkboxes': {
        const checkboxElements = stepDatum.multiple_choice_options.map(findCheckbox);
        const values = {};

        checkboxElements.forEach((element, idx) => {
          values[stepDatum.multiple_choice_options[idx].id] = element.checked;
        });

        return values;
      }
      default: {
        const inputElement = ref.current;
        return inputElement.value;
      }
      }
    };

    const value = sentValue === undefined ? findFieldValue() : sentValue;
    validateInput(value);
    submitValue(value, immediately);
  };

  useEffect(() => {
    if (!isEmpty(props.defaultValue)) {
      onInputChanged(props.defaultValue);
    }
  }, [onInputChanged, props.defaultValue]);

  useEffect(() => {
    if (props.version > version) {
      alert('This page is outdated', 'The information on this page is out of date. Someone else may have updated it. Please refresh the page.');
    }
  }, [props.version, version]);

  const prevFieldValue = usePrevious(props.fieldValue);

  useEffect(() => {
    if (props.stepDatum.data_type !== 'date_range') { return; }
    if (!props.fieldValue) { return; }

    if (!prevFieldValue && props.fieldValue.start_date) {
      return setStartDate(initialStartDate);
    }

    if (!prevFieldValue && props.fieldValue.end_date) {
      return setEndDate(initialEndDate);
    }

    if (prevFieldValue.start_date !== props.fieldValue.start_date) {
      setStartDate(props.fieldValue.start_date);
    }

    if (prevFieldValue.end_date !== props.fieldValue.end_date) {
      setEndDate(props.fieldValue.end_date);
    }
  }, [prevFieldValue, props.stepDatum.data_type, props.fieldValue]);

  useEffect(() => {
    if (!props.stepDatum.data_type === 'file') { return; }
    if (!props.fieldValue || !prevFieldValue) { return; }

    if (props.fieldValue.length === 0 && (prevFieldValue.length > 0 || props.version > 1)) {
      setValid(!props.stepDatum.required);
      setBeganFieldCompletion(true);
    }
  }, [prevFieldValue, props.stepDatum.data_type, props.fieldValue]);

  const prevAttemptedAdvance = usePrevious(props.attemptedAdvance);
  const prevFirstError = usePrevious(props.firstError);

  useEffect(() => {
    const setFocus = () => {
      if (props.stepDatum.data_type === 'date') {
        ref.current.input.focus();
      } else if (ref.current) {
        ref.current.focus();
      }
    };

    if ((!prevAttemptedAdvance && props.firstError)
      || (props.attemptedAdvance && props.firstError && !prevFirstError)) {
      setFocus();
    }
  }, [
    prevAttemptedAdvance,
    prevFirstError,
    props.attemptedAdvance,
    props.firstError,
    props.stepDatum.data_type,
    ref,
  ]);

  const stepDatumId = () => {
    return `stepdatum-${props.stepDatum.id}`;
  };

  const basicInput = (type, placeholder = null) => {
    return (
      <input
        ref={ref}
        id={stepDatumId()}
        onBlur={completionBegun}
        disabled={props.disabled}
        onChange={onInputChanged}
        placeholder={placeholder}
        type={type}
        aria-required={props.stepDatum.required}
        defaultValue={props.fieldValue}
        maxLength={props.stepDatum.max_length}
      />
    );
  };

  const inputTagForFieldType = () => {
    switch (props.stepDatum.data_type) {
    case 'string':
      return basicInput('text');
    case 'text':
      return (
        <textarea
          ref={ref}
          id={stepDatumId()}
          onBlur={onTextAreaBlur}
          disabled={props.disabled}
          onChange={onInputChanged}
          aria-required={props.stepDatum.required}
          defaultValue={props.fieldValue}
          maxLength={props.stepDatum.max_length}
        />
      );
    case 'integer':
      return basicInput('text');
    case 'float':
      return basicInput('text');
    case 'money':
      return (
        <MoneyInput
          innerRef={ref}
          id={stepDatumId()}
          onBlur={completionBegun}
          disabled={props.disabled}
          onChange={onInputChanged}
          type='money'
          required={props.stepDatum.required}
          defaultValue={props.fieldValue}
          minValue={props.stepDatum.min_value}
          maxValue={props.max_value}
        />
      );
    case 'phone':
      return basicInput('tel');
    case 'email':
      return basicInput('email', 'awesome@example.com');
    case 'address':
      return (
        <AddressInput
          innerRef={ref}
          id={stepDatumId()}
          completionBegun={completionBegun}
          disabled={props.disabled}
          onChange={onInputChanged}
          required={props.stepDatum.required}
          defaultValue={props.fieldValue}
        />
      );
    case 'file':
      return (
        <FileInput
          innerRef={ref}
          id={stepDatumId()}
          disabled={props.disabled}
          onChange={onFileInputChanged}
        />
      );
    case 'multiple_choice':
      const options = props.stepDatum.multiple_choice_options
        .map(({ id, name }) => ({ value: id, label: name }));
      return (
        <Select
          // NOTE: This casting is because the ids are numbers on the stepDatum,
          // but the defaultValue is a string. Ideally we would do this where
          // we're setting the default for `localValue`, but because this component
          // is used for so many things, it's easier to keep it isolated here.
          innerRef={ref}
          value={options.find(({ value }) => value === Number(localValue))}
          id={stepDatumId()}
          aria-required={props.stepDatum.required}
          onChange={onMultipleChoiceChange}
          disabled={props.disabled}
          options={options}
          placeholder='Select an option'
          styles={{
            container: (provided) => ({
              ...provided,
              display: 'inline-block',
              verticalAlign: 'middle',
              marginRight: '1rem',
              width: '80%',
            }),
          }}
        />
      );
    case 'date':
      return (
        <div className='inline-block'>
          <DateSelector
            innerRef={ref}
            id={stepDatumId()}
            value={props.fieldValue}
            type={props.stepDatum.date_type}
            placeholderText='Select Date'
            onDateChange={onDateInputChanged}
            onBlur={completionBegun}
            className={`margin-right currentstep-datum-datepicker${props.disabled ? ' unclickable' : ''}`}
          />
        </div>
      );
    case 'date_range':
      const startId = `${stepDatumId()}-start`;
      const endId = `${stepDatumId()}-end`;

      return (
        <div className='well inline-block margin-right'>
          <div className='inline-block'>
            <label htmlFor={startId}>
              {props.stepDatum.date_range_start_label}
            </label>
            <DateRangeSelector
              innerRef={ref}
              id={startId}
              endId={endId}
              value={startDate}
              type={props.stepDatum.date_type}
              onDateChange={onStartDateRangeInputChanged}
              className={`margin-right currentstep-datum-datepicker${props.disabled ? ' unclickable' : ''}`}
              startDate={startDate}
              onBlur={completionBegun}
              endDate={endDate}
              isStart
            />
          </div>

          <div className='inline-block margin-left'>
            <label htmlFor={endId}>{props.stepDatum.date_range_end_label}</label>
            <DateRangeSelector
              innerRef={ref}
              id={endId}
              value={endDate}
              type={props.stepDatum.date_type}
              onDateChange={onEndDateRangeInputChanged}
              className={`margin-right currentstep-datum-datepicker${props.disabled ? ' unclickable' : ''}`}
              startDate={startDate}
              endDate={endDate}
              onBlur={completionBegun}
              isStart={false}
              minDate={startDate}
            />
          </div>
        </div>
      );
    case 'checkboxes':
      return renderCheckboxes();
    case 'entity': {
      const fieldValue = props.fieldValue ? props.fieldValue : '';
      return (
        <EntityInput
          templateId={props.templateId}
          required={props.stepDatum.required}
          entityTemplateFields={props.stepDatum.entity_fields}
          canCreateNewEntity={props.stepDatum.entity_createable}
          customEntity={props.stepDatum.custom_entity}
          stepDatumId={props.stepDatum.id}
          initiatedFlowId={props.initiatedFlowId}
          teamId={props.teamId}
          fieldValue={fieldValue}
          onChange={onEntityValueChanged}
          disabled={props.disabled}
        />
      );
    }
    default:
      throw new Error(`Unrecognized input type '${props.stepDatum.data_type}'`);
    }
  };

  const renderCheckboxes = () => {
    const initialValues = props.fieldValue;
    const checkboxesHTML = props.stepDatum.multiple_choice_options.map((option) => {
      const initialValue = (initialValues ? initialValues[option.id] : false);
      return renderCheckbox(option, initialValue);
    });

    return (
      <div className='currentstep-datum-checkboxes' aria-labelledby={stepDatumId()} role='group'>{checkboxesHTML}</div>
    );
  };

  const renderCheckbox = (checkbox, isChecked) => {
    return (
      <div key={checkbox.id}>
        <label>
          <input
            ref={(el) => { checkboxRefs.current[`checkbox-${checkbox.id}`] = el; }}
            type='checkbox'
            onChange={onInputChanged}
            disabled={props.disabled}
            defaultChecked={isChecked}
          />
          {checkbox.name}
        </label>
      </div>
    );
  };

  const findCheckbox = (option) => {
    const refName = `checkbox-${option.id}`;
    return checkboxRefs.current[refName];
  };

  const onEntityValueChanged = (value) => {
    validateInput(value);
    submitValue(value);
  };

  const onStartDateRangeInputChanged = (date) => {
    validateInput({ start_date: date, end_date: endDate });
    submitValue({ start_date: date, end_date: endDate });
  };

  const onEndDateRangeInputChanged = (date) => {
    validateInput({ end_date: date, start_date: startDate });
    submitValue({ end_date: date, start_date: startDate });
  };

  const onMultipleChoiceChange = ({ value }) => {
    // NOTE: setLocalValue is currently only used here. It would likely be worth
    // refactoring to use this approach across more of these components in the future.
    setLocalValue(value);
    validateInput(value);
    submitValue(value);
  };

  const onDateInputChanged = (date) => {
    validateInput(date);
    submitValue(date);
  };

  const onFileInputChanged = (url, file) => {
    completionBegun();
    validateInput([file, url]);
    submitValue(url);
  };

  const validateInput = useCallback((input) => {
    const isValid = !props.stepDatum.required || validate(input, props.stepDatum);

    setValid(isValid);
  }, [props.stepDatum]);

  const submitValue = useCallback((fieldValue, immediately = false) => {
    const args = [props.stepDatum.id, fieldValue, version + 1];

    if (!props.survey) {
      CollectInfoStepActions.dispatchDataWillChange(dispatch);
    }

    if (immediately) {
      submit(...args);
    } else {
      debouncedSubmit(...args);
    }

    setVersion(version + 1);
  }, [debouncedSubmit, props.stepDatum.id, submit, version]);

  const completionBegun = () => {
    setBeganFieldCompletion(true);
  };

  const onTextAreaBlur = () => {
    if (fieldValueDidChange()) {
      onInputChanged({}, ref.current.value, true);
    }

    completionBegun();
  };

  const fieldValueDidChange = () => {
    return ref.current.value !== props.fieldValue;
  };

  const statusClassName = () => {
    if (valid) {
      return 'valid';
    } if (beganFieldCompletion || props.attemptedAdvance) {
      return 'invalid';
    }
    return '';
  };

  const deleteAttachedFile = (fileId) => {
    return CollectInfoStepActions.deleteAttachedFile(fileId)
      .done(() => CollectInfoStepActions.dispatchFileDeleted(dispatch, props.stepDatum.id, fileId));
  };

  const renderStatus = () => {
    if (props.stepDatum.required) {
      return <i className={`validityindicator ${statusClassName()}`} />;
    }
    return <span>Optional</span>;
  };

  const renderExtra = () => {
    if (props.stepDatum.data_type === 'file') {
      return (
        <ul className='currentstep-datum-extra'>
          {renderFiles()}
        </ul>
      );
    } if (props.stepDatum.data_type === 'address' && props.fieldValue) {
      return <Map query={props.fieldValue} />;
    }
  };

  const renderFile = (file) => {
    return (
      <AttachedFile
        key={file.url.original}
        fileName={file.file_file_name}
        createdAt={file.created_at}
        fileId={file.id}
        url={file.url.original}
        onDeleteClick={deleteAttachedFile}
        deletable={!props.disabled}
        adminUploaded={file.admin_uploaded}
      />
    );
  };

  const renderFiles = () => {
    const files = props.fieldValue;

    if (!files || !files.length) return;

    return (files.map((file) => renderFile(file)));
  };

  const shouldShowErrorMessage = () => {
    return !valid
           && (beganFieldCompletion || props.attemptedAdvance);
  };

  const renderFormErrorMessage = () => {
    if (shouldShowErrorMessage()) {
      const message = [props.stepDatum.name, errorMessageForDatum(props.stepDatum)].join(' ');
      return (
        <span className='error-message margin-top-less'>
          {message}
        </span>
      );
    }
  };

  const errorMessageForDatum = (datum) => {
    switch (datum.data_type) {
    case 'integer':
    case 'float':
    case 'money':
      if (!validateNumberFormat(localValue, datum)) {
        return ErrorMessages.formValidation[datum.data_type];
      }

      return ErrorMessages.formValidation.number({
        numberType: datum.data_type,
        minValue: datum.min_value,
        maxValue: datum.max_value,
      });
    case 'checkboxes':
      return ErrorMessages.formValidation.checkboxes({ minNumChecks: datum.min_num_checks });
    default:
      return ErrorMessages.formValidation[props.stepDatum.data_type];
    }
  };

  const needsAriaLabelledBy = () => {
    return contains(['entity', 'checkboxes', 'date_range'], props.stepDatum.data_type);
  };

  const extraLabel = () => {
    switch (props.stepDatum.data_type) {
    case 'checkboxes':
      if (props.stepDatum.min_num_checks) {
        return <span className='block'>{`(Minimum Selection: ${props.stepDatum.min_num_checks})`}</span>
      }
      break;
    case 'money':
      if (!props.stepDatum.min_value && !props.stepDatum.max_value ) { return }

      const max = props.stepDatum.max_value ? `Max value: ${props.stepDatum.max_value}` : ' ';
      const min = props.stepDatum.min_value ? `Min value: ${props.stepDatum.min_value}` : ' ';

      return <span className='block'>{`(${min} ${max})`}</span>;
    case 'string':
      if (props.stepDatum.max_length) {
        return <span className='block'>{`(Max characters: ${props.stepDatum.max_length})`}</span>;
      }
      break;
    case 'text':
      if (props.stepDatum.max_length) {
        return <span className='block'>{`(Max characters: ${props.stepDatum.max_length})`}</span>;
      }
      break;
    default: {
      break;
    }
    }
  }

  const renderTextLabel = () => {
    const requiredIcon = props.stepDatum.required ? <i title='required' /> : '';

    if (needsAriaLabelledBy()) {
      return (
        <span id={stepDatumId()} className='currentstep-input-label'>
          {props.stepDatum.name}
          {requiredIcon}
          {extraLabel()}
        </span>
      );
    }

    return (
      <label htmlFor={stepDatumId()}>
        {props.stepDatum.name}
        {requiredIcon}
        {extraLabel()}
      </label>
    );
  };

  const onLockClick = () => {
    let lockedFields = [];

    if (props.isFieldLocked) {
      lockedFields = without(props.lockedFields, String(props.stepDatum.id));
    } else {
      const currentlyLocked = props.lockedFields || [];
      lockedFields = [...currentlyLocked, String(props.stepDatum.id)];
    }

    ActiveStepActions.update(dispatch, props.activeStepId, { locked_fields: JSON.stringify(lockedFields) });
  };

  const lockButton = () => {
    if (props.isStepLocked) { return; }

    if (props.admin) {
      return (
        <button
          type='button'
          className={`forminput-lockbutton ${props.disabled ? 'locked' : 'unlocked'}`}
          onClick={onLockClick}
        >
          <span className={`icon ${props.disabled ? 'icon-lock' : 'icon-unlocked'}`} />
        </button>
      );
    }

    return (
      <span className={`icon ${props.disabled ? 'icon-lock' : 'icon-unlocked'}`} />
    );
  };

  const renderInputField = () => {
    const ariaLabelledBy = contains(['entity'], props.stepDatum.data_type) ? stepDatumId() : null;

    return (
      <div className={`forminput-validity ${statusClassName()} ${props.disabled ? 'disabled' : ''}`}>
        {renderTextLabel()}
        <div className='forminput-wrapper' aria-labelledby={ariaLabelledBy}>
          {inputTagForFieldType()}
          <div className='currentstep-validity'>{renderStatus()}</div>
          <div className='forminput-lockwrap'>
            {lockButton()}
          </div>
        </div>

        {renderFormErrorMessage()}
      </div>
    );
  };

  return (
    <div className='currentstep-datum'>
      {renderInputField()}

      {renderExtra()}
    </div>
  );
};

StepDataCollectorInputField.defaultProps = {
  attemptedAdvance: false,
  disabled: false,
  firstError: false,
  survey: false,
};

StepDataCollectorInputField.propTypes = {
  attemptedAdvance: PropTypes.bool,
  disabled: PropTypes.bool,
  firstError: PropTypes.bool,
  survey: PropTypes.bool,
  stepDatum: PropTypes.shape({
    data_type: PropTypes.string.isRequired,
    name: PropTypes.string,
    required: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    multiple_choice_options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
    })),
    max_length: PropTypes.number,
    entity_fields: PropTypes.arrayOf(PropTypes.shape({})),
    entity_createable: PropTypes.bool,
    entity_name: PropTypes.string,
  }).isRequired,
  fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  version: PropTypes.number,
  onInputChanged: PropTypes.func,
  defaultValue: PropTypes.string,
  teamId: PropTypes.number.isRequired,
  templateId: PropTypes.number.isRequired,
  initiatedFlowId: PropTypes.number.isRequired,
};

export default StepDataCollectorInputField;
