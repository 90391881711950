const MetricsDashboardAPI = {
  fetchData(data) {
    return $.ajax('/metrics/fetch_data', {
      dataType: 'JSON',
      method: 'GET',
      data,
    });
  },
};

export default MetricsDashboardAPI;
