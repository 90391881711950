import React from 'react';
import ReviewActions from 'actions/review-actions';
import PersonSelector from 'components/shared/person-selector';
import Flash from 'components/shared/flash';
import TimeAgo from 'react-timeago';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

class ThirdPartyReviewPersonSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reviewRequest: this.props.defaultReviewRequest,
    };
  }

  approvalDate() {
    return dayjs(this.state.reviewRequest.step_approval.created_at).format('LLL');
  }

  doSearch = (newTerms) => {
    return ReviewActions.searchReviewers(newTerms, this.props.initiatedFlowId)
                        .always(() => {
                          this.setState({
                            searching: false,
                          });
                        });
  };

  onSelect = (user) => {
    ReviewActions.requestReview(this.props.reviewGroupId, this.props.activeStepId, user.id)
     .done((res) => {
       this.setState({
         reviewRequest: res,
       });
     })

      .fail(() => {
        Flash.error();
      });
  };

  onSendInvite = (email, inviteeName) => {
    ReviewActions.requestNonUserReview(this.props.reviewGroupId, this.props.activeStepId, email, inviteeName)
                 .done((res) => {
                   this.setState({
                     reviewRequest: res,
                   });
                 });
  };

  undoRequest = (e) => {
    e.preventDefault();

    if (!this.state.reviewRequest) {
      throw 'Attempted to undo nonexistant review request';
    }

    ReviewActions.undoRequestReview(this.state.reviewRequest.id)
      .done(() => {
        this.setState({
          reviewRequest: null,
        });
      });
  };

  renderReceivingUserName = () => {
    if (!this.state.reviewRequest) { return null; }

    if (!this.state.reviewRequest.receiving_user.pseudo) {
      return <strong>{this.state.reviewRequest.receiving_user.name}</strong>;
    }
    return (
      <>
        <strong>{this.state.reviewRequest.receiving_user.name}</strong> <em>({this.state.reviewRequest.receiving_user.email})</em>
      </>
    );
  };

  renderRequest = () => {
    let content;

    if (!this.state.reviewRequest.completed) {
      content = (
        <span>
          Request sent to {this.renderReceivingUserName()} <TimeAgo date={this.state.reviewRequest.created_at} />
          &nbsp;&nbsp;
          <a href='#' onClick={this.undoRequest}>Undo</a>
        </span>
      );
    } else {
      content = (
        <span>
          Reviewed by <strong>{this.state.reviewRequest.receiving_user.name}</strong> on {this.approvalDate()}
          {this.renderSignature()}
        </span>
      );
    }

    return (
      <div className='margin-bottom'>
        {content}
      </div>
    );
  };

  renderSignature = () => {
    const approval = this.state.reviewRequest.stepApproval;
    if (approval && approval.signing) {
      return (
        <div className='signaturebox-sigline vmiddle nomargin-top margin-left'>
          <span className='signaturebox-x'>x</span>
          <img src={approval.signing.signature.url} title={`Signature for ${approval.signing.user.name}`} />
        </div>
      );
    }
  };

  renderContent() {
    if (this.state.reviewRequest) {
      return this.renderRequest();
    }
    return (
      <PersonSelector
        style={{ width: '430px' }}
        allowPseudo
        pseudoNeedsRole={false}
        hideExternal
        doSearch={this.doSearch}
        onInvite={this.onSendInvite}
        onSelect={this.onSelect}
        attachableType='ThirdPartyReviewRequest'
      />
    );
  }

  renderInfo() {
    if (!this.state.reviewRequest) {
      return 'We will send a link to this party to complete their review.';
    }
  }

  render() {
    return (
      <div className='relative'>
        {this.renderContent()}

        <div className='color-text-medium text-smaller margin-top'>
          {this.renderInfo()}
        </div>
      </div>
    );
  }
}

ThirdPartyReviewPersonSelector.propTypes = {
  defaultReviewRequest: PropTypes.shape({
    id: PropTypes.number.isRequired,
    review_group_id: PropTypes.number.isRequired,
    requesting_user_id: PropTypes.number.isRequired,
    active_step_id: PropTypes.number.isRequired,
    receiving_user_id: PropTypes.number,
    created_at: PropTypes.string.isRequired,
    completed: PropTypes.bool.isRequired,
  }),
  activeStepId: PropTypes.number.isRequired,
  reviewGroupId: PropTypes.number.isRequired,
};

export default ThirdPartyReviewPersonSelector;
