import TaggingAPI from '../apis/tagging-api';
import { error } from '../components/shared/flash';
import InitiatedFlowConstants from '../constants/initiated-flow-constants';
import TagAPI from '../apis/tag-api';

const TagManagerActions = {
  createTag(dispatch, taggableType, taggableId, tagName) {
    TaggingAPI.create(taggableType, taggableId, {
      new_tag_name: tagName,
    })
      .done((tagging) => { this.onTagAdded(dispatch, tagging); })
      .fail(() => { error(); });
  },

  addTagging(dispatch, taggableType, taggableId, tagId) {
    TaggingAPI.create(taggableType, taggableId, {
      tag_id: tagId,
    })
      .done((tagging) => { this.onTagAdded(dispatch, tagging); })
      .fail(() => { error(); });
  },

  addTaggingInBulk(taggingsAttrs) {
    return TaggingAPI.createInBatch(taggingsAttrs);
  },

  removeTagging(dispatch, taggingId) {
    TaggingAPI.destroy(taggingId)
      .done(() => { this.onTaggingRemoved(dispatch, taggingId); })
      .fail(() => { error(); });
  },

  removeTaggingInBulk(taggingsAttrs) {
    return TaggingAPI.destroyInBatch(taggingsAttrs);
  },

  updateTag(dispatch, tagId, tagName) {
    return TagAPI.update(tagId, tagName)
      .done((tag) => { this.onTagUpdated(dispatch, tag); })
      .fail(() => { error(); });
  },
  destroyTag(dispatch, tagId) {
    TagAPI.destroy(tagId)
      .done((tag) => { this.onTagDeleted(dispatch, tag.id); })
      .fail(() => { error(); });
  },

  onTagAdded(dispatch, tagging) {
    dispatch({
      type: InitiatedFlowConstants.TAG_ADDED,
      tagging,
    });
  },

  onTaggingRemoved(dispatch, taggingId) {
    dispatch({
      type: InitiatedFlowConstants.TAG_REMOVED,
      taggingId,
    });
  },

  onTagDeleted(dispatch, tagId) {
    dispatch({
      type: InitiatedFlowConstants.TAG_DELETED,
      tagId,
    });
  },

  onTagUpdated(dispatch, tag) {
    dispatch({
      type: InitiatedFlowConstants.TAG_UPDATED,
      tag,
    });
  },
};

export default TagManagerActions;
