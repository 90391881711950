const EntityAPI = {
  create(attrs, initiatedFlowId, stepDatumId) {
    const url = '/entities';
    return $.ajax(url, {
      method: 'POST',
      dataType: 'json',
      data: {
        entity: attrs,
        initiated_flow_id: initiatedFlowId,
        step_datum_id: stepDatumId,
      },
    });
  },

  createEsri(esriUniqueId, initiatedFlowId, stepDatumId) {
    const url = '/entities';
    return $.ajax(url, {
      method: 'POST',
      dataType: 'json',
      data: {
        entity: {
          esri_unique_id: esriUniqueId,
        },
        initiated_flow_id: initiatedFlowId,
        step_datum_id: stepDatumId,
      },
    });
  },

  search(initiatedFlowId, stepDatumId, terms) {
    const url = '/entities/search';

    return $.ajax(url, {
      data: {
        initiated_flow_id: initiatedFlowId,
        step_datum_id: stepDatumId,
        terms,
      },
      method: 'get',
      dataType: 'json',
    });
  },

  fetchSuggested(userId, flowTemplateId) {
    const url = '/entities/suggested';
    return $.ajax(url, {
      method: 'GET',
      dataType: 'json',
      data: {
        user_id: userId,
        flow_template_id: flowTemplateId,
      },
    });
  },

  update(id, attrs) {
    const url = `/entities/${id}`;
    return $.ajax(url, {
      method: 'PUT',
      dataType: 'json',
      data: {
        entity: attrs,
      },
    });
  },

  getEntity(entityId, initiatedFlowId) {
    const url = `/entities/${entityId}`;
    return $.ajax(url, {
      method: 'GET',
      dataType: 'json',
      data: {
        initiated_flow_id: initiatedFlowId,
      },
    });
  },

  toggleDestroyed(entityId) {
    const url = `/entities/${entityId}/toggle_destroyed`;
    return $.ajax(url, {
      method: 'POST',
      dataType: 'json',
    });
  },
};

export default EntityAPI;
