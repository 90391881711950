import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'underscore';
import XClose from './x-close';

class Notification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
    };
  }

  onClose = (e) => {
    e.preventDefault();
    this.setState({ open: false });
    if (isFunction(this.props.onClose)) {
      this.props.onClose();
    }
  };

  renderDetails() {
    if (this.props.notification.details && this.props.notification.details.length > 0) {
      return (
        <ul>
          {this.props.notification.details.map((detail, index) => {
            return <li key={index}>{detail}</li>;
          })}
        </ul>
      );
    }
  }

  render() {
    if (this.state.open) {
      return (
        <div className={`fullwidthnotif ${this.props.notificationType} ${this.props.extraClasses}`}>
          <div className='fullwidthnotif-close'>
            <XClose label='close notification' onClick={this.onClose} />
          </div>
          <div className='fullwidthnotif-content'>
            <span>{this.props.notification.title}</span>
            {this.renderDetails()}
          </div>
        </div>
      );
    }

    return null;
  }
}

Notification.propTypes = {
  notification: PropTypes.object.isRequired,
  notificationType: PropTypes.string.isRequired,
  extraClasses: PropTypes.string,
};

export default Notification;
