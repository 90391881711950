import React from 'react';
import PropTypes from 'prop-types';
import Select from 'components/shared/select';
import EntityConfigurationActions from 'actions/entity-configuration-actions';
import { success } from '../../shared/flash';

const TitleFieldSelector = ({ customEntity, dispatch }) => {
  const optionsForSelect = () => {
    return customEntity.custom_entity_fields.map((f) => ({ label: f.name, value: f.id }));
  };

  const selectedNameField = () => {
    return optionsForSelect().find(f => f.value === customEntity.name_field_id);
  };

  const onChange = (newVal) => {
    const { value } = newVal;
    EntityConfigurationActions.updateEntity(customEntity.id, { name_field_id: value }, dispatch)
                              .done(() => { success('Name field updated'); });
  };

  return (
    <div className='well'>
      <p>Select a field to use to as the entity name</p>
      <Select
        id='sort-select'
        options={optionsForSelect()}
        value={selectedNameField()}
        onChange={onChange}
      />
    </div>
  );
};

export default TitleFieldSelector;
