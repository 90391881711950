import React from 'react';
import PropTypes from 'prop-types';
import AdvancedLabel from 'components/shared/advanced-label';
import Select from 'components/shared/select';

const dateFormatOptions = [
  { value: 'date', label: 'Date Only' },
  { value: 'date_time', label: 'Time & Date' },
  { value: 'time', label: 'Time Only' },
];

const dateRangeFormatOptions = [
  { value: 'date', label: 'Date Only' },
  { value: 'time', label: 'Time Only' },
];

const DateSettings = ({
  dateRangeStartLabel,
  dateRangeEndLabel,
  dataType,
  dateType,
  onDataChanged,
}) => {
  const isRange = dataType === 'date_range';
  const options = isRange ? dateRangeFormatOptions : dateFormatOptions;
  const onDateFormatChange = ({ value }) => {
    onDataChanged({ date_type: value });
  };

  const onStartDateLabelChange = (e) => {
    onDataChanged({ date_range_start_label: e.currentTarget.value });
  };

  const onEndDateLabelChange = (e) => {
    onDataChanged({ date_range_end_label: e.currentTarget.value });
  };

  const renderDateRangeLabels = () => {
    return (
      <>
        <tr>
          <td>
            <AdvancedLabel
              label='Start Date Label'
              required
            />
          </td>
          <td>
            <div className='inline-block'>
              <input
                id='start_date'
                name='start_date'
                type='text'
                defaultValue={dateRangeStartLabel || 'Start Date'}
                className='fieldmanager-dateinput'
                onChange={onStartDateLabelChange}
              />
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <AdvancedLabel
              label='End Date Label'
              required
            />
          </td>
          <td>
            <div className='inline-block'>
              <input
                id='start_date'
                name='start_date'
                type='text'
                defaultValue={dateRangeEndLabel || 'End Date'}
                className='fieldmanager-dateinput'
                onChange={onEndDateLabelChange}
              />
            </div>
          </td>
        </tr>
      </>
    );
  };

  return (
    <>
      <tr>
        <td>
          <AdvancedLabel
            label='Format'
            required
          />
        </td>
        <td>
          <div className='inline-block'>
            <Select
              value={options.find(({ value }) => value === dateType)}
              options={options}
              onChange={onDateFormatChange}
              styles={{
                container: (provided) => ({
                  ...provided,
                  display: 'inline-block',
                }),
              }}
            />
          </div>
        </td>
      </tr>
      {isRange && renderDateRangeLabels() }
    </>
  );
};

DateSettings.propTypes = {
  dateRangeStartLabel: PropTypes.string,
  dateRangeEndLabel: PropTypes.string,
  dataType: PropTypes.string.isRequired,
  dateType: PropTypes.string.isRequired,
  onDataChanged: PropTypes.func.isRequired,
};

export default DateSettings;
