import React, { useState, useRef, useEffect } from 'react';
import ExpandedSection from 'components/shared/expanded-section';

const RefundWidget = (props) => {
  const [refundReason, setRefundReason] = useState('');

  const refundReasonInput = useRef();

  useEffect(() => {
    refundReasonInput.current.focus();
  }, []);

  function authToken() {
    return $('meta[name=csrf-token]').attr('content');
  }

  return (
    <ExpandedSection>
      <textarea
        value={refundReason}
        id='paymentmanager-refundreason'
        placeholder='Why are you issuing this refund? (Optional)'
        ref={refundReasonInput}
        onChange={(e) => { setRefundReason(e.currentTarget.value); }}
      />

      <form id='form'>
        <input type='hidden' name='refund_reason' value={refundReason} />
        <input type='hidden' name='authenticity_token' value={authToken()} />
      </form>
      <div className='align-right'>
        <form id='form'>
          <input type='hidden' name='refund_reason' value={refundReason} />
          <input type='hidden' name='authenticity_token' value={authToken()} />
          <button type='button' onClick={props.onRefundCancel} className='btn-link margin-right'>Cancel</button>
          <button
            className='btn-primary'
            type='submit'
            form='form'
            formMethod='POST'
            formAction={`/charges/${props.nonRefundedCharge.id}/refunds`}
            id='issuerefundbutton'
          >
            Issue refund
          </button>
        </form>
      </div>
    </ExpandedSection>
  );
};

export default RefundWidget;
