import React, { useState } from 'react';
import PropTypes from 'prop-types';

const EditableInput = (props) => {
  const [editing, setEditing] = useState(props.editing);

  function cancel() {
    props.onCancel();
    setEditing(false);
  }

  function save() {
    props.onSave()
         .done(() => { setEditing(false); });
  }

  function renderInput() {
    return (
      <>
        <label htmlFor={props.id}>{props.label}</label>
        <input className='width-50' disabled={!editing} type={props.type} value={props.value} onChange={props.onInputChanged} id={props.id} />
      </>
    );
  }

  function renderButtons() {
    if (editing) {
      return (
        <div className='padding-top'>
          <button className='btn-link' onClick={cancel}>Cancel</button>
          <button className='margin-left small' onClick={save}>Save</button>
        </div>
      );
    }
    return <span aria-label='Edit' onClick={() => setEditing(true)} className='icon icon-pencil-grey clickable' />;
  }

  return (
    <div className='padding-bottom'>
      {renderInput()}
      {renderButtons()}
    </div>
  );
};

EditableInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onInputChanged: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

EditableInput.defaultProps = {
  type: 'text',
};

export default EditableInput;
