import React from 'react';
import dayjs from 'dayjs';
import FieldPreview from './field-preview';

const OverviewStep = (props) => {
  const NO_DATA_SYMBOL = '---';

  const [isOpen, setOpen] = React.useState(false);

  const onExpandClick = (e) => {
    e.preventDefault();
    setOpen(!isOpen);
  };

  const stepTypeName = () => {
    switch (props.step.type) {
    case 'BasicStep':
      return 'Informational Step';
    case 'CollectInfoStep':
      return 'Form';
    case 'RequireReviewStep':
      return 'Review';
    case 'SurveyStep':
      return 'Survey';
    case 'PaymentStep':
      return 'Payment';
    case 'AssignResponsibilityStep':
      return 'Assignment Step';
    default:
      throw `Unrecognized step type ${props.step.type}`;
    }
  };

  const renderExpandArrow = () => {
    if (!hasAdditionalInfo()) { return; }

    return (
      <span className={`margin-left stepbreakdown-expand icon icon-arrow-down small thin ${isOpen ? 'flip-y' : ''}`} />
    );
  };

  const hasAdditionalInfo = () => {
    const addlInfoTypes = [
      'CollectInfoStep',
      'RequireReviewStep',
      'SurveyStep',
      'PaymentStep',
    ];

    return props.step.description || addlInfoTypes.includes(props.step.type);
  };

  const renderAdditionalInfo = () => {
    if (!isOpen) { return; }

    const renderLowerContent = () => {
      switch (props.step.type) {
      case 'SurveyStep':
        return <div className='well margin-top'>You will be prompted to collect responses to an online survey.</div>;
      case 'CollectInfoStep':
        return <FieldPreview sections={props.step.sections} />;
      case 'PaymentStep':
        return <div className='well margin-top'>A payment may be required.</div>;
      default:
        return <div className='well margin-top'>No additional info available</div>;
      }
    };

    const renderDescription = () => {
      if (!props.step.description) { return; }

      return <p className='stepbreakdown-description margin-top'>{props.step.description}</p>;
    };

    return (
      <div>
        {renderDescription()}
        {renderLowerContent()}
      </div>
    );
  };

  const renderStepResponsibility = () => {
    if (!props.respDidChange) { return; }

    let respName;

    if (props.step.external) {
      respName = 'Your responsibility';
    } else {
      respName = props.cityName;
    }

    return (
      <div className='stepbreakdown-resp'>
        <div className='stepbreakdown-respname'>{respName}</div>
        <div className='stepbreakdown-stepnum'>{renderStepRange()}</div>
      </div>
    );
  };

  const renderStepRange = () => {
    const firstPos = props.step.position;
    const lastPos = props.lastStepPos;

    if (firstPos === lastPos) {
      return `Step ${firstPos}`;
    }
    return `Steps ${firstPos} - ${lastPos}`;
  };

  const renderLinebreak = () => {
    if (props.respWillChange) {
      return <div className='linebreak onlydesktop' />;
    }
  };

  const renderTimeEstimate = () => {
    const time = props.medianTime;

    if (time) {
      const duration = dayjs.duration(time, 'seconds');
      return duration.humanize();
    }

    return NO_DATA_SYMBOL;
  };

  const altText = () => {
    if (open) { return 'hide step details'; }

    return 'open step details'
  }

  return (
    <div className={`stepbreakdown-step ${props.respDidChange ? 'firstoftype' : ''}`}>
      {renderStepResponsibility()}

      <div className='stepbreakdown-stepmain'>
        <div className='stepbreakdown-stepname'>{props.step.name}</div>
        <div className='stepbreakdown-meta'>
          <div className='stepbreakdown-timeest' title='Median time to complete this step'>{renderTimeEstimate()}</div>
          <button alt={altText()} onClick={onExpandClick} className='stepbreakdown-steptype btn-link'>
            {stepTypeName()}
            {renderExpandArrow()}
          </button>
  
        </div>

        {renderAdditionalInfo()}

      </div>

      {renderLinebreak()}
    </div>
  );
};

export default OverviewStep;
