const AdvancedFilterAPI = {
  index(kind) {
    const url = '/advanced_filters';
    return $.ajax(url, {
      method: 'GET',
      dataType: 'JSON',
      data: { kind }
    });
  },

  get(id) {
    const url = `/advanced_filters/${id}`;
    return $.ajax(url, {
      method: 'GET',
      dataType: 'JSON',
    });
  },

  loadDefault(kind) {
    const url = '/advanced_filters/load_default';
    return $.ajax(url, {
      method: 'GET',
      dataType: 'JSON',
      data: { kind }
    });
  },

  create(name, kind, filters, isDefault) {
    const url = '/advanced_filters';
    return $.ajax(url, {
      method: 'POST',
      dataType: 'JSON',
      data: {
        filter: {
          name,
          filters,
          kind,
          default: isDefault,
        }
      }
    });
  },

  update(id, kind, filters, isDefault) {
    const url = `/advanced_filters/${id}`;
    return $.ajax(url, {
      method: 'PATCH',
      dataType: 'JSON',
      data: {
        filter: {
          filters,
          kind,
          default: isDefault,
        }
      }
    });
  },

  rename(id, name) {
    const url = `/advanced_filters/${id}`;
    return $.ajax(url, {
      method: 'PATCH',
      dataType: 'JSON',
      data: {
        filter: { name }
      }
    });
  },

  delete(id) {
    const url = `/advanced_filters/${id}`;
    return $.ajax(url, {
      method: 'DELETE',
      dataType: 'JSON',
    });
  },
};

export default AdvancedFilterAPI;
