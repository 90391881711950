import React, { useState } from 'react';
import PropTypes from 'prop-types';

import LinkyButton from 'components/shared/linky-button';
import Select from 'components/shared/select';

// NOTE: peopleSelected refers to whether we're on the people page
// or the organizations page
// TODO: make this pair of pages into a single page and then
// make this component use Links and history instead of <a>s and
// document.location.
// This will require refactoring this entire page to be react.
const SubNav = ({
  teamsForSelect, peopleSelected, teamSlug,
}) => {
  const [exported, setExported] = useState(false);
  const [exporting, setExporting] = useState(false);
  const onSelectChange = ({ value: slug }) => {
    const page = peopleSelected ? 'people' : 'entities';
    document.location = `/teams/${slug}/${page}`;
  };

  const onExportClick = () => {
    if (exporting) {
      return;
    }

    const exportType = peopleSelected ? 'people' : 'entities';
    // TODO: replace with apiFetch
    setExporting(true);
    window.$.ajax(
      `/teams/${teamSlug}/${exportType}/exports`,
      {
        method: 'POST',
      },
    ).done(() => setExported(true))
    .always(() => setExporting(false));
  };

  const options = teamsForSelect.map(([value, label]) => ({ value, label }));

  return (
    <>
      {exported && (
        <div className='fullwidthnotif success'>You will be emailed when your export completes. Finished exports can be found on the <a href='/exports'>Exports</a> page.</div>
      )}
      <div className='tabnav cf'>
        <ul>
          <li className={peopleSelected ? 'selected' : ''}>
            <a href={`/teams/${teamSlug}/people`}>People</a>
          </li>
          <li className={peopleSelected ? '' : 'selected'}>
            <a href={`/teams/${teamSlug}/entities`}>Entities</a>
          </li>
        </ul>
        <div className='right'>
          {!peopleSelected && <a id='addorglink' className='inline-block margin-right secondary' href={`/teams/${teamSlug}/entities/new`}>+ Create new entity</a>}
          <LinkyButton className='inline-block margin-right' disabled={exporting} onClick={onExportClick}>Export</LinkyButton>
          <Select
            id='teamselect'
            defaultValue={options.find(({ value }) => value === teamSlug)}
            options={options}
            onChange={onSelectChange}
            styles={{
              container: (provided) => ({
                ...provided,
                display: 'inline-block',
                'margin-bottom': '1rem',
                'margin-left': '1rem',
                'vertical-align': 'middle',
              }),
            }}
            isSearchable={false}
          />
        </div>
      </div>
    </>
  );
};

SubNav.propTypes = {
  teamsForSelect: PropTypes.array,
  teamSlug: PropTypes.string,
  peopleSelected: PropTypes.bool,
};

export default SubNav;
