var create = function(activeStepId, dataURI){
  var url = '/active_steps/' + activeStepId + '/signings';
  var request = $.ajax(url, {
    dataType: 'json',
    method: 'POST',
    data: {
      signing: {
        signature: dataURI
      }
    }
  });

  return request;
};

export default {
  create: create
};