import React from 'react';
import CloseIfOutsideWrapper from 'components/shared/close-if-outside-wrapper';
import InitiatedFlowActions from 'actions/initiated-flow-actions';
import AlertConfirm from 'modules/alert-confirm';

class AddlOptions extends React.Component {
  state = {
    open: false,
  };

  toggleOpen = () => {
    this.setState({ open: !this.state.open });
  };

  onClose = () => {
    this.setState({ open: false });
  };

  canHalt = () => {
    return (!this.props.initiatedFlow.halted && !this.props.initiatedFlow.finished_at);
  };

  canDelete = () => {
    return !!this.props.admin;
  };

  canUnhalt = () => {
    return (this.props.initiatedFlow.halted && !this.props.initiatedFlow.finished_at);
  };

  notClearedToUnhalt = () => {
    return (this.props.initiatedFlow.halted_by_admin && !this.props.admin);
  };

  confirmDelete = (e) => {
    e.preventDefault();

    const body = (
      <span>
        <span className='color-alert bold'>Warning: </span>
        Deleting a submission makes it unavailable to all users. This is not recommended.
      </span>
    );

    AlertConfirm.confirm('Delete submission?', body, () => { InitiatedFlowActions.deleteProcess(this.props.initiatedFlow.id); });
  };

  renderOptions = () => {
    const options = [];
    if (this.canHalt()) {
      options.push(<li key='halt'><a href={`/initiated_flows/${this.props.initiatedFlow.id}/do_halt`} data-method='post'>Halt submission</a></li>);
    } else if (this.notClearedToUnhalt()) {
      options.push(<li className='disabled' key='halt-disabled'><span className='text-alert' href='#'>Resume submission (halted by admin)</span></li>);
    } else if (this.canUnhalt()) {
      options.push(<li key='unhalt'><a className='text-success' href={`/initiated_flows/${this.props.initiatedFlow.id}/do_unhalt`} data-method='post'>Resume submission</a></li>);
    } else {
      options.push(<li className='disabled' key='halt-disabled'><span className='text-alert' href='#'>Halt submission</span></li>);
    }

    if (this.canDelete()) {
      options.push(<li key='delete'><a className='text-alert' href='#' onClick={this.confirmDelete}>Delete submission</a></li>);
    }

    return <ul className='popup-listmenu'>{options}</ul>;
  };

  render() {
    return (
      <CloseIfOutsideWrapper onClose={this.onClose}>
        <button
          onClick={this.toggleOpen}
          className={`${this.props.className} btn-link ellipsispopup-blue${this.state.open ? ' active' : ''}`}
          title='Additional Options'
          type='button'
        >
          <div className='popup'>
            <div className='popup-nub-down' />
            {this.renderOptions()}
          </div>
        </button>
      </CloseIfOutsideWrapper>
    );
  }
}

export default AddlOptions;
