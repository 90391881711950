import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

const AdvancedLabel = (props) => {
  return (
    <div className='advancedlabel'>
      <span className='advancedlabel-name'>{props.label}</span>
      {props.required && <span className='advancedlabel-required'>*</span>}
      {props.helperText && (
        <FontAwesomeIcon
          icon={icon({ name: 'circle-question', style: 'regular' })}
          className='advancedlabel-help margin-left'
          title={props.helperText}
        />
      )}
    </div>
  );
};

AdvancedLabel.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
};

export default AdvancedLabel;
