import React from 'react';
import PropTypes from 'prop-types';

const UserEmailSetting = (props) => {
  function handleChange(e) {
    const val = e.currentTarget.value;

    props.handleToggleChange(props.setting, val);
  }

  function renderSettings() {
    return (
      <div className='inline-block padding-left'>
        <form>
          <fieldset className='btn-multitoggle'>
            <div>
              <input type='radio' id={`${props.type}-on`} name='on' value='on' checked={props.setting.should_send === true} onChange={handleChange} />
              <label id={`${props.type}-onlabel`} className='outside-left' htmlFor={`${props.type}-on`}>On</label>
            </div>
            <div>
              <input type='radio' id={`${props.type}-off`} name='off' value='off' checked={props.setting.should_send === false} onChange={handleChange} />
              <label id={`${props.type}-offlabel`} className='inside' htmlFor={`${props.type}-off`}>Off</label>
            </div>
            <div>
              <input type='radio' id={`${props.type}-default`} name='default' value='default' checked={props.setting.should_send === 'default'} onChange={handleChange} />
              <label id={`${props.type}-defaultlabel`} className='outside-right' htmlFor={`${props.type}-default`}>Default</label>
            </div>
          </fieldset>
        </form>
      </div>
    );
  }

  function renderSettingsDetails() {
    const defaultSetting = props.email.should_send ? 'On' : 'Off';
    return <div className='color-text-medium'>Default Setting: {defaultSetting}</div>;
  }

  return (
    <div className='border-bottom padding-top padding-bottom'>
      <div>
        <div style={{ minWidth: '250px' }} className='inline-block bold'>{props.email.name}</div>
        {renderSettings()}
      </div>
      {renderSettingsDetails()}
    </div>
  );
};

export default UserEmailSetting;

UserEmailSetting.propTypes = {
  email: PropTypes.object.isRequired,
  handleToggleChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  setting: PropTypes.object.isRequired,
};
