import { extend } from 'underscore';

function pluralize(word, count, opts) {
  const config = extend({
    many: `${word}s`,
  }, opts);

  if (count === 1) {
    return word;
  }
  return config.many;
}

export default pluralize;
