import round from 'modules/number-round';

const STRIPE_FEE_PERCENT = 0.029;
const STRIPE_ACH_FEE_PERCENT = 0.008;
const STRIPE_FEE_FIXED = 0.3;
const CG_FEE_PERCENT = 0.02;

function calculateTotalFee(type, amount, noFee = true, customFeePercentage = null) {
  const stripeFee = calculateStripeFee(type, amount, noFee);
  const cgFee = calculateCGFee(amount, customFeePercentage, noFee);

  return stripeFee + cgFee;
}

function calculateStripeFee(type, amount, noFee) {
  // fee based on initial amount
  let stripeFee = 0;
  if (type === 'card' && noFee) {
    stripeFee = (amount + STRIPE_FEE_FIXED) / (1 - STRIPE_FEE_PERCENT) - amount;
  } else if (type === 'card') {
    stripeFee = (amount * STRIPE_FEE_PERCENT) + STRIPE_FEE_FIXED;
  } else if (type === 'us_bank_account') {
    const amnt = (amount * STRIPE_ACH_FEE_PERCENT);
    stripeFee = amnt > 5.00 ? 5.00 : amnt;
  }

  return round(stripeFee, 2);
}

function calculateCGFee(amount, customFeePercentage, noFee) {
  const feePercentage = customFeePercentage || CG_FEE_PERCENT;
  return noFee ? 0 : round(amount * feePercentage, 2);
}

export default calculateTotalFee;
