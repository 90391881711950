const MasterTemplateAuthoritiesAPI = {
  create: (userId, masterTemplateId) => {
    const url = `/master_templates/${masterTemplateId}/master_template_authorities`;
    const request = $.ajax(url, {
      dataType: 'json',
      method: 'POST',
      data: {
        user_id: userId
      }
    });

    return request;
  },

  destroy: (authorityId) => {
    const url = `/master_template_authorities/${authorityId}`;
    const request = $.ajax(url, {
      dataType: 'json',
      method: 'DELETE'
    });

    return request;
  }
};

export default MasterTemplateAuthoritiesAPI;
