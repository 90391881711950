import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { isString } from 'underscore';

const { Cell } = require('fixed-data-table-2');

import { useAdvancedFilterContext } from 'components/shared/advanced-filter/context';


const SortingHeaderCell = ({
  title,
  columnId,
  activeOrder,
  onSorting,
}) => {
  const { currentFilter } = useAdvancedFilterContext();
  const isActive = activeOrder?.by === columnId;
  const order = activeOrder?.order;

  const nextOrder = () => {
    if (!isActive) { return 'desc'; }
    if (isString(order)) {
      return (order === 'desc') ? 'asc' : 'desc';
    }
    return 'desc';
  };

  const sortByColumn = useCallback(() => {
    onSorting({ ...currentFilter, sort: { by: columnId, order: nextOrder() } });
  }, [currentFilter]);

  const renderIcon = () => {
    const iconClass = `sorting-header-icon ${isActive ? 'active' : ''}`;

    if (!isActive || order === 'desc') {
      return <FontAwesomeIcon className={iconClass} icon={icon({ name: 'arrow-down-wide-short' })} />
    } else {
      return <FontAwesomeIcon className={iconClass} icon={icon({ name: 'arrow-up-wide-short' })} />
    }
  };

  return (
    <Cell className='sorting-header-cell' onClick={sortByColumn}>
      {title}
      {renderIcon()}
    </Cell>
  );
};

export default SortingHeaderCell;

