import PropTypes from 'prop-types';
import React from 'react';
import AlertConfirm from 'modules/alert-confirm';
import dayjs from 'dayjs';
import truncate from 'truncate';

class AttachedFile extends React.Component {
  state = {
    deleting: false,
  }; 

  getUploadedByTagString = () => {
    const { adminUploaded } = this.props;
    if (adminUploaded === undefined || adminUploaded === null) {
      return;
    }

    const uploadedBy = adminUploaded ? 'admin' : 'applicant';
    return `(uploaded by ${uploadedBy})`;
  };

  onDeleteClick = (e) => {
    e.preventDefault();

    if (!this.state.deleting) {
      AlertConfirm.confirm('Delete file?', `Are you sure you want to delete ${this.props.fileName}`, () => {
        this.props.onDeleteClick(this.props.fileId)
          .fail(() => this.setDeletingState(false));

        this.setDeletingState(true);
      });
    }
  };

  setDeletingState = (boolean) => {
    this.setState({ deleting: boolean });
  };

  getTruncatedFileName = () => {
    return truncate(this.props.fileName, 35);
  };

  render() {
    return (
      <li>
        <a target='_blank' href={this.props.url} rel='noreferrer'>{this.getTruncatedFileName()}</a>
        { this.props.createdAt && (
          <span className='margin-left color-text-medium'>{dayjs(this.props.createdAt).format('LLL')}</span>
        )}
        <span className='margin-left color-text-medium'>{this.getUploadedByTagString()}</span>
        {this.props.deletable && (
          <button
            className='btn-link margin-left'
            onClick={this.onDeleteClick}
          >
            <span title='Delete File' className='margin-left icon icon-trash-grey bigger clickable'/>
          </button>
        )}
      </li>
    );
  }
}

AttachedFile.propTypes = {
  deletable: PropTypes.bool.isRequired,
  onDeleteClick: PropTypes.func.isRequired,

  url: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default AttachedFile;
