import React from 'react';
import renderer from 'modules/renderer';
import CommonProviders from 'components/common-providers';
import EditSuggestionsRequest from '../components/edit-suggestions-request';

function show() {
  renderer(
    (
      <CommonProviders>
        <EditSuggestionsRequest
          teamId={CityGrows.Server.teamId}
          request={CityGrows.Server.request}
        />
      </CommonProviders>
    ), document.getElementById('edit_suggestions_request'),
  );
}

export default {
  show,
};
