import React, { useState, useContext } from 'react';
import RequestIsGovContext from 'contexts/request-is-gov-context';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import { addYears } from 'react-datepicker/src/date_utils';
import { update } from 'actions/issued-output-actions';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

const OutputDetails = ({ output, style }) => {
  const [editingExpiration, setEditingExpiration] = useState(false);
  const [editingIssued, setEditingIssued] = useState(false);
  const isGov = useContext(RequestIsGovContext);
  const dispatch = useContext(InitiatedFlowDispatchContext);

  const shouldShowEditButtons = () => {
    return isGov && style !== 'mini';
  };

  const onEditExpirationClick = (e) => {
    e.preventDefault();

    setEditingExpiration(true);
  };

  const onEditIssuedClick = (e) => {
    e.preventDefault();

    setEditingIssued(true);
  };

  const onIssueDateUpdate = (date) => {
    update(dispatch, output.id, {
      issuedAt: date,
    });

    setEditingIssued(false);
  };

  const renderExpirationDetails = () => {
    if (!output.expires || !output.issued) { return; }

    let label;
    if (output.expired) {
      label = <div className='color-alert output-table-label'>Expired</div>;
    } else if (output.revoked) {
      label = <div className='color-alert output-table-label'>Original expiration</div>;
    } else {
      label = <div className='color-success output-table-label'>Valid through</div>;
    }

    return (
      <div className='output-expiredate'>
        {label}{renderExpirationDate()}
      </div>
    );
  };

  const renderExpirationDate = () => {
    if (editingExpiration) {
      return (
        <DatePicker
          selected={new Date(output.expires_at)}
          onChange={onExpirationDateUpdate}
          autoFocus
          onCalendarClose={() => { setEditingExpiration(false); }}
          maxDate={addYears(new Date(), 1000)}
        />
      );
    }

    const editButton = <button type='button' className='btn-link margin-left-less' onClick={onEditExpirationClick}>Edit</button>;

    return (
      <span>
        {dayjs(output.expires_at).format('LL')}
        {shouldShowEditButtons() && editButton}
      </span>
    );
  };

  const onExpirationDateUpdate = (date) => {
    update(dispatch, output.id, {
      expiresAt: date,
    });

    setEditingExpiration(false);
  };

  const renderIssueDate = () => {
    const issuedAt = output.issued ? dayjs(output.issued_at).format('LL')
      : 'Not yet issued';

    if (editingIssued) {
      return (
        <DatePicker
          selected={output.issued_at ? new Date(output.issued_at) : new Date()}
          onChange={onIssueDateUpdate}
          autoFocus
          onCalendarClose={() => { setEditingIssued(false); }}
          maxDate={addYears(new Date(), 1000)}
        />
      );
    }

    const editButton = <button type='button' className='issuedate-edit btn-link margin-left-less' onClick={onEditIssuedClick}>Edit</button>;

    return (
      <div>
        <div className='output-table-label'>Issued</div><span className='output-issuedate padding-bottom-less'>{issuedAt}</span>
        {shouldShowEditButtons() && editButton}
      </div>
    );
  };

  return (
    <div className='margin-top'>
      <div className='output-table'>
        {renderIssueDate()}
        {renderExpirationDetails()}
      </div>
    </div>
  );
};

export default OutputDetails;
