import Stapes from 'stapes';
import Dispatcher from '../appdispatcher';
import DashboardConstants from '../constants/dashboard-constants';

let _initiatedFlows = [];
let _totalCount = 0;
let _currentTemplateStepData = null;
let _currentPosition = 0;
let _batchSize = 0;
let _totalLoadedCount = 0;

const DashboardStore = Stapes.subclass({
  getInitiatedFlows() {
    return _initiatedFlows;
  },

  getTotalInitiatedFlowCount() {
    return _totalCount;
  },

  getTotalLoadedInitiatedFlowCount() {
    return _totalLoadedCount;
  },

  getStepData() {
    return _currentTemplateStepData;
  },

  getNextBatchSize() {
    if (_totalCount === 0) return 0;
    _currentPosition += _batchSize;
    const difference = _totalCount - _totalLoadedCount;
    return difference < _batchSize ? difference : _batchSize;
  },

});
const store = new DashboardStore();

const addNewItems = (items, position) => {
  // Sometimes more items get loaded right after we go
  // into loading state and _initiatedFlows gets cleared.

  if (_initiatedFlows) {
    _initiatedFlows.splice(position, items.length, ...items);
    _batchSize = items.length;
    _totalLoadedCount += items.length;
  }
};

Dispatcher.register((action) => {
  switch (action.actionType) {
  case DashboardConstants.ITEMS_LOADED:
    _initiatedFlows = new Array(action.total_count);
    _totalCount = action.total_count;
    addNewItems(action.items, 0);
    store.emit('change');
    break;
  case DashboardConstants.MORE_ITEMS_LOADED:
    addNewItems(action.items, action.index);
    store.emit('loadmore');
    break;
  case DashboardConstants.ITEMS_LOADING:
    _initiatedFlows = null;
    _batchSize = 0;
    _totalLoadedCount = 0;
    store.emit('change');
    break;
  case DashboardConstants.STEP_DATA_LOADED:
    _currentTemplateStepData = action.stepData;
    store.emit('change');
    break;
  case DashboardConstants.LOAD_FAILED:
    _initiatedFlows = [];
    _batchSize = 0;
    _totalLoadedCount = 0;
    store.emit('change');
    break;
  default:
    break;
  }
});

export default store;
