import keyMirror from 'keymirror';

// These are used by the step data collector
// drag-and-drop components
export const STEP_ROW = 'STEP_ROW';
export const STEP_SECTION = 'STEP_SECTION';

export default keyMirror({
  CUSTOM_ID_CREATED: null,
  CUSTOM_ID_DELETED: null,
  EMAIL_NOTIFICATION_ADDED: null,
  EMAIL_NOTIFICATION_DESTROYED: null,
  EMAIL_NOTIFICATION_UPDATED: null,
  FAQ_CREATED: null,
  FAQ_DESTROYED: null,
  FAQ_UPDATED: null,
  FLOW_TEMPLATE_LOADED: null,
  FLOW_TEMPLATE_UPDATED: null,
  FORMULA_DESTROYED: null,
  FORMULA_LOADED: null,
  LOGIC_CONFIG_ADDED: null,
  LOGIC_CONFIG_DESTROYED: null,
  MARK_FLOW_TEMPLATE_CHANGED: null,
  MASTER_TEMPLATE_LOADED: null,
  OUTPUT_UPDATED: null,
  RENEWAL_REMINDER_CREATED: null,
  RENEWAL_REMINDER_UPDATED: null,
  RENEWAL_REMINDER_DELETED: null,
  REVIEW_GROUP_ADDED: null,
  REVIEW_GROUP_DELETED: null,
  REVIEW_GROUP_REMOVED: null,
  REVIEW_GROUP_UPDATED: null,
  SEARCH_CONFIG_ADDED: null,
  SEARCH_CONFIG_DELETED: null,
  SEARCH_CONFIG_UPDATED: null,
  SECTION_ADDED: null,
  SECTION_COPIED: null,
  SECTION_MOVED: null,
  SECTION_REMOVED: null,
  SECTION_UPDATED: null,
  SLUG_UPDATED: null,
  STEP_ADDED: null,
  STEP_ASSIGNMENT_GROUP_CREATED: null,
  STEP_ASSIGNMENT_GROUP_DELETED: null,
  STEP_ASSIGNMENT_CREATED: null,
  STEP_ASSIGNMENT_DELETED: null,
  STEP_DATA_ADDED: null,
  STEP_DATA_MOVED: null,
  STEP_DATA_REMOVED: null,
  STEP_DATA_UPDATED: null,
  STEP_REMOVED: null,
  STEP_UPDATED: null,
  STEPS_REORDERED: null,
  SUBPROCESS_CONFIG_ADDED: null,
  SUBPROCESS_CONFIG_DELETED: null,
  SUBPROCESS_CONFIG_UPDATED: null,
  SURVEY_SECTION_ADDED: null,
  SURVEY_SECTION_COPIED: null,
  SURVEY_UPDATED: null,
  TEAM_DATA_LOADED: null,
}, 'TEMPLATE_MANAGER');
