var StepApprovalAPI = {
  approve: function(activeStepId, reviewGroupId, approvalStatus, opts){
    opts = opts || {};

    var url = '/active_steps/' + activeStepId + '/step_approvals/set_approval';
    var request = $.ajax(url, {
      data: {
        approval_status: approvalStatus,
        review_group_id: reviewGroupId,
        signature: opts.signatureData,
        comment: opts.comment,
        role_id: opts.roleId
      },
      dataType: 'json',
      method: 'POST'
    });

    return request;
  }
};

export default StepApprovalAPI;
