import PropTypes from 'prop-types';
import React from 'react';
import AttachedPeople from 'components/shared/attached-people';

const AssignResponsibilityStep = (props) => {
  const stepIsCompleted = () => {
    return props.step.position < props.initiatedFlow.current_step.position;
  };

  const renderCompletedAdminView = () => {
    return (
      <div>
        <p>This step has been completed. You can manage who is assigned using the menu in the upper right.</p>
      </div>
    );
  };

  const renderAdminView = () => {
    if (!stepIsCompleted()) {
      return (
        <AttachedPeople
          people={props.activeStep.tentative_assignments}
          attachableType='ActiveStep'
          attachableId={props.activeStep.id}
          tentativeAssignment
          searchOpts={{ initiatedFlowId: props.initiatedFlow.Id }}
          hideExternal={false}
          allowPseudo
        />
      );
    }

    return renderCompletedAdminView();
  };

  const renderConstituentView = () => {
    if (!stepIsCompleted()) {
      return (
        <p>Waiting for someone to be assigned to this submission. Nothing is needed from you at this time.</p>
      );
    }

    return <p>This step has been completed.</p>;
  };

  const renderContent = () => {
    if (props.admin) {
      return renderAdminView();
    }
    return renderConstituentView();
  };

  return (
    <div className='currentstep-stepsection'>
      {renderContent()}
    </div>
  );
};

AssignResponsibilityStep.propTypes = {
  admin: PropTypes.bool.isRequired,

  activeStep: PropTypes.object.isRequired,
  currentUserId: PropTypes.number.isRequired,

  step: PropTypes.shape({
    id: PropTypes.number.isRequired,
    position: PropTypes.number.isRequired,
  }).isRequired,

  initiatedFlow: PropTypes.shape({
    id: PropTypes.number.isRequired,
    current_step: PropTypes.object.isRequired,
  }).isRequired,
};

export default AssignResponsibilityStep;
