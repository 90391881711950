import NProgress from 'nprogress';
import { isArray } from 'underscore';
import StepAssignmentAPI from 'apis/step-assignments-api';
import Dispatcher from '../appdispatcher';
import API from '../apis/flow-step-api';
import StepAssignmentGroupAPI from '../apis/step-assignment-group-api';
import StepDataAPI from '../apis/step-data-api';
import Constants from '../constants/template-manager-constants';
import AlertConfirm from '../modules/alert-confirm';
import Flash from '../components/shared/flash';

function parseUpdates(attrs, response) {
  const updates = {};
  Object.keys(attrs).forEach((key) => {
    if (key === 'config_attributes') {
      updates.config = buildConfig(attrs.config_attributes, response.config);
    } else {
      updates[key] = response[key];
    }
  });

  return updates;
}

function buildConfig(attrsConfig, responseConfig) {
  const config = { ...responseConfig };
  Object.keys(attrsConfig).forEach((key) => {
    config[key] = responseConfig[key];
  });

  return config;
}

const FlowStepActions = {
  addStep(flowTemplateId, stepType) {
    NProgress.start();

    return API.add(flowTemplateId, stepType)
      .done((newStep) => {
        Dispatcher.dispatch({
          actionType: Constants.STEP_ADDED,
          step: newStep,
        });
      })
      .fail((res) => {
        Flash.error(res.responseJSON?.errors ? res.responseJSON.errors[0] : 'Oops, something went wrong');
      })
      .always(() => {
        NProgress.done();
      });
  },

  removeStep(stepId) {
    API.delete(stepId)
      .done(() => {
        Dispatcher.dispatch({
          actionType: Constants.STEP_REMOVED,
          stepId,
        });
      })
      .fail((res) => {
        const errorMessage = res.responseJSON.errors.base[0];
        AlertConfirm.alert('Can\'t delete this step', errorMessage);
      });
  },

  stepsReordered(id, position) {
    return API.reorder(id, position)
            .fail(this.onFail)
            .done((res) => {
              Dispatcher.dispatch({
                actionType: Constants.STEPS_REORDERED,
                stepPositions: res,
              });
            });
  },

  duplicateStep(stepId) {
    return API.duplicate(stepId)
                  .done((step) => {
                    Dispatcher.dispatch({
                      actionType: Constants.STEP_ADDED,
                      step,
                    });
                  })
                  .fail(() => { Flash.error(); });
  },

  updateStep(stepId, attrs) {
    NProgress.start();

    return API.update({ id: stepId, ...attrs })
            .done((res) => {
              const updates = parseUpdates(attrs, res);
              Dispatcher.dispatch({
                actionType: Constants.STEP_UPDATED,
                attrs: updates,
                stepId,
              });
            })
            .fail(() => {
              Flash.error('There was a problem updating your step settings');
            })
            .always(() => {
              NProgress.done();
            });
  },

  addData(sectionId, type = null) {
    NProgress.start();

    return StepDataAPI.add(sectionId, type)
      .done((datum) => {
        Dispatcher.dispatch({
          actionType: Constants.STEP_DATA_ADDED,
          sectionId,
          datum,
        });
      })
      .fail((res) => {
        res?.responseJSON?.errors ? Flash.error(res.responseJSON.errors[0]) : Flash.error();
      })
      .always(() => {
        NProgress.done();
      });
  },

  duplicateDatum(datumId) {
    NProgress.start();

    StepDataAPI.duplicate(datumId)
      .done((datum) => {
        Dispatcher.dispatch({
          actionType: Constants.STEP_DATA_ADDED,
          sectionId: datum.section_id,
          datum,
        });
        Flash.success('Field duplicated');
      })
      .fail((res) => {
        Flash.error(res.responseJSON.errors ? res.responseJSON.errors[0] : null);
      })
      .always(() => {
        NProgress.done();
      });
  },

  addSection(stepId) {
    NProgress.start();

    StepDataAPI.addSection('Step', stepId)
      .done((section) => {
        Dispatcher.dispatch({
          actionType: Constants.SECTION_ADDED,
          section,
          stepId,
        });
      })
      .always(() => {
        NProgress.done();
      })
      .fail((res) => {
        Flash.error(res.responseJSON.errors ? res.responseJSON.errors[0] : null);
      });
  },

  updateSection(sectionId, attrs) {
    return StepDataAPI.updateSection(sectionId, attrs)
      .done((section) => {
        Dispatcher.dispatch({
          actionType: Constants.SECTION_UPDATED,
          section,
        });
      })
      .fail((res) => {
        Flash.error(res.responseJSON.errors ? res.responseJSON.errors[0] : null);
      });
  },

  destroySection(sectionId) {
    AlertConfirm.confirm('Delete this section?', 'Are you sure you want to delete this section?', () => {
      StepDataAPI.destroySection(sectionId)
        .done(() => {
          Dispatcher.dispatch({
            actionType: Constants.SECTION_REMOVED,
            sectionId,
          });
        })
        .fail(this.onFail);
    });
  },

  moveSection(sectionId, position) {
    return StepDataAPI.reorderSection(sectionId, position)
      .done((sections) => {
        Dispatcher.dispatch({
          actionType: Constants.SECTION_MOVED,
          sections,
        });
      });
  },

  copySection(sectionId, stepId) {
    return StepDataAPI.copySection(sectionId)
      .done((section) => {
        Dispatcher.dispatch({
          actionType: Constants.SECTION_COPIED,
          section,
          stepId,
        });
      });
  },

  refreshStepData(stepDatumId) {
    return StepDataAPI.fetch(stepDatumId)
      .done((res) => {
        Dispatcher.dispatch({
          actionType: Constants.STEP_DATA_UPDATED,
          datumAttrs: res,
          datumId: stepDatumId,
        });
      });
  },

  moveStepData(datumId, position, sectionId) {
    return StepDataAPI.reorder(datumId, position, sectionId)
             .done((response) => {
               Dispatcher.dispatch({
                 actionType: Constants.STEP_DATA_MOVED,
                 data: response,
               });
             })
             .fail(this.onFail);
  },

  updateStepData(datumId, datumAttrs) {
    return StepDataAPI.update(datumId, datumAttrs)
            .done((res) => {
              const updates = parseUpdates(datumAttrs, res);
              Dispatcher.dispatch({
                actionType: Constants.STEP_DATA_UPDATED,
                datumAttrs: updates,
                datumId,
              });
            })
            .fail(this.onFail);
  },

  deleteStepData(stepDatumId) {
    return StepDataAPI.destroy(stepDatumId)
            .done(() => {
              Dispatcher.dispatch({
                actionType: Constants.STEP_DATA_REMOVED,
                stepDatumId,
              });
            });
  },

  changeTime(stepId, attrs) {
    return API.update({ id: stepId, ...attrs })
              .done(() => {
                const message = (!attrs.time && !attrs.time_unit ? 'Timeframe removed' : 'Timeframe updated');
                Flash.success(message);
                Dispatcher.dispatch({
                  actionType: Constants.STEP_UPDATED,
                  attrs,
                  stepId,
                });
              });
  },

  clearSpecificResponsibility(stepId) {
    const attrs = { id: stepId, responsibility_type: 'assigned' };
    return API.update(attrs)
      .done(() => {
        Dispatcher.dispatch({
          actionType: Constants.STEP_UPDATED,
          attrs: { person_attachments: [] },
          stepId,
        });
      });
  },

  createPrevAssigneesGroup(stepId) {
    return StepAssignmentGroupAPI.createPrevAssignees(stepId)
      .done((group) => {
        Dispatcher.dispatch({
          actionType: Constants.STEP_ASSIGNMENT_GROUP_CREATED,
          group,
        });
      })
      .fail(() => Flash.error());
  },

  createAssignmentGroup(stepId, assigneeId, assigneeType) {
    return StepAssignmentGroupAPI.create(stepId, assigneeId, assigneeType)
      .done((group) => {
        Dispatcher.dispatch({
          actionType: Constants.STEP_ASSIGNMENT_GROUP_CREATED,
          group,
        });
      })
      .fail(() => Flash.error());
  },

  deleteAssignmentGroup(stepAssignmentGroupId) {
    return StepAssignmentGroupAPI.destroy(stepAssignmentGroupId)
      .done(() => {
        Dispatcher.dispatch({
          actionType: Constants.STEP_ASSIGNMENT_GROUP_DELETED,
          id: stepAssignmentGroupId,
        });
      })
      .fail(() => Flash.error());
  },

  deleteAssignment(stepAssignmentId) {
    return StepAssignmentAPI.destroy(stepAssignmentId)
      .done(() => {
        Dispatcher.dispatch({
          actionType: Constants.STEP_ASSIGNMENT_DELETED,
          id: stepAssignmentId,
        });
      })
      .fail(() => Flash.error());
  },

  createAssignment(stepAssignmentGroupId, selectionId, selectionType) {
    StepAssignmentAPI.create(stepAssignmentGroupId, selectionId, selectionType)
      .done((res) => {
        Dispatcher.dispatch({
          actionType: Constants.STEP_ASSIGNMENT_CREATED,
          stepAssignment: res,
        });
      })
      .fail(() => Flash.error());
  },

  onFail(res) {
    if (isArray(res.responseJSON.errors)) {
      Flash.error(res.responseJSON.errors[0]);
    } else {
      Flash.error(res.responseJSON.errors.base[0]);
    }
  },
};

export default FlowStepActions;
