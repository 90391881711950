import React from "react";
import { contains } from "underscore";

import DashboardActions from 'actions/dashboard-actions';
import Select from "../select";
import { WORKFLOW_STATUSES } from "./constants";


const templatesBySelectedStatus = (templates, status) => {
  if (status == 'all') {
    return templates;
  }

  return templates.filter(form => form.status == status);
};

const FormSelect = ({
  changeFilterValue,
  changeFilterValueOptions,
  disableStepDataFilters,
  enableStepDataFilters,
  filterState,
  teamFilter,
  formTemplates,
}) => {
  let templates = templatesBySelectedStatus(formTemplates, filterState.options.status);

  if (teamFilter) {
    templates = templates.filter((form) => {
      return teamFilter.operator == 'is'
        ? contains(teamFilter.value, form.teamId)
        : !contains(teamFilter.value, form.teamId)
    });
  }

  const defaultStatusValue = WORKFLOW_STATUSES.find((opt) => opt.id == filterState.options.status);

  const formNumberIds = filterState.value ? filterState.value.map((id) => Number(id)) : [];
  const defaultSelectedFormsValue = templates.filter((form) => contains(formNumberIds, form.master_template_id));

  const onStatusChange = (value) => {
    changeFilterValueOptions({
      status: value.id
    });
  };

  const onFormsChange = (value) => {
    changeFilterValue(value.map(form => form.master_template_id));

    if (value.length == 1) {
      const template = value[0];
      DashboardActions.loadStepData(template.id).
        done(() => enableStepDataFilters(template.name_or_default));
    } else {
      disableStepDataFilters();
    }
  };

  return (
    <>
      <div className='metricsdashboard-filterselect'>
        <Select
          options={WORKFLOW_STATUSES}
          onChange={onStatusChange}
          getOptionValue={(opt) => opt.id}
          value={defaultStatusValue}
          className='react-select-metrics advanced-filter'
        />
      </div>
      <div className='metricsdashboard-filterselect'>
        <Select
          options={templates}
          isMulti
          onChange={onFormsChange}
          getOptionValue={(opt) => opt.master_template_id}
          getOptionLabel={(opt) => opt.name_or_default}
          value={defaultSelectedFormsValue}
          className='react-select-metrics advanced-filter'
          styles={{
            multiValue: (provided) => ({
              ...provided,
              maxWidth: '310px',
            })
          }}
        />
      </div>
    </>
  );
};

export default FormSelect;
