const FlowTemplateAPI = {
  update(id, attrs){
    const url = '/flow_templates/' + id;

    return $.ajax(url, {
      method: 'PUT',
      dataType: 'json',
      data: {
        flow_template: attrs
      }
    });
  },

  publish(flowTemplateId){
    const url = '/flow_templates/' + flowTemplateId + '/publish';

    return $.ajax(url, {
      method: 'PUT',
      dataType: 'json',
    });
  },

  clone(flowTemplateId, teamId){
    const url = '//' + window.location.host + '/flow_templates/' + flowTemplateId + '/clone';

    return $.ajax(url, {
      dataType: 'json',
      method: 'POST',
      data: {
        team_id: teamId
      }
    });
  },

  duplicate(flowTemplateId, data){
    const url = '//' + window.location.host + '/flow_templates/' + flowTemplateId + '/duplicate';

    return $.ajax(url, {
      dataType: 'json',
      method: 'POST',
      data: data
    });
  }
};

export default FlowTemplateAPI;
