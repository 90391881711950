import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import MultiSelect from 'components/shared/multi-select';
import EmbeddedTextInput from 'components/shared/embedded-text-input';
import { isEmpty, isNull } from 'underscore';
import { success, error } from 'components/shared/flash';
import SlidingToggle from 'components/shared/sliding-toggle';

const ExportTemplate = (props) => {
  const [exportTemplate, setExportTemplate] = useState(props.exportTemplate);
  const options = useMemo(() => {
    if (!props.flowTemplates || !exportTemplate) { return []; }
    return props.flowTemplates.map((flowTemplate) => {
      const selected = exportTemplate.item_ids.includes(flowTemplate.id);
      return { value: flowTemplate.id, label: flowTemplate.name || 'Untitled form', selected };
    });
  }, [props.flowTemplates, exportTemplate]);

  const isNewExportTemplate = () => {
    return isNull(exportTemplate.id);
  };

  const onSave = () => {
    props.onSave(exportTemplate)
      .done((r) => {
        setExportTemplate(r);
        if (isNewExportTemplate()) {
          success('Bulk Form Export created');
        } else {
          success('Bulk Form Export updated');
          props.onCancel();
        }
      })
      .fail(() => error());
  };

  const onSelect = (ids) => {
    const dupTemplate = { ...exportTemplate };
    dupTemplate.item_ids = ids;
    setExportTemplate(dupTemplate);
  };

  const onExportTemplateChange = (change) => {
    setExportTemplate({ ...exportTemplate, ...change });
  };

  const renderButtons = () => {
    const disabled = isEmpty(exportTemplate.name) || isEmpty(exportTemplate.item_ids);
    return (
      <div>
        <button type='button' className='btn-primary margin-right' onClick={onSave} disabled={disabled}>Save</button>
        <button type='button' className='btn-secondary' onClick={props.onCancel}>Cancel</button>
      </div>
    );
  };

  const renderEditSettings = () => {
    return (
      <div>
        <label>
          Name
          <input
            type='text'
            value={exportTemplate.name}
            id='exporttemplatename'
            placeholder='Bulk Form Export name'
            onChange={(e) => onExportTemplateChange({ name: e.target.value })}
            style={{ width: '300px' }}
          />
        </label>
        <SlidingToggle
          onToggle={() => onExportTemplateChange({ include_legacy: !exportTemplate.include_legacy })}
          on={exportTemplate.include_legacy}
          label='Include Legacy Fields'
          description='When on, export will include legacy data from deleted fields'
          className='margin-top margin-bottom'
        />
        <div className='padding-bottom'>
          <MultiSelect
            options={options}
            onSelect={onSelect}
          />
        </div>
        {renderButtons()}
      </div>
    );
  };

  const title = isNewExportTemplate ? 'New Bulk Form Export' : <span>Editing bulk form export: <strong>{exportTemplate.name}</strong></span>;

  return (
    <div className='margin-bottom-more subprocessconfig-edit'>
      <h1>{title}</h1>
      {renderEditSettings()}
    </div>
  );
};

ExportTemplate.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  exportTemplate: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    item_ids: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  }),
  flowTemplates: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

ExportTemplate.defaultProps = {
  onCancel: () => {},
  onSave: () => {},
};

export default ExportTemplate;
