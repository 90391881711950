class LogicValidator {
  constructor(action, conditions) {
    this.action = action;
    this.conditions = conditions;
  }

  validateConfig() {
    return this.action && this.allConditionsValid();
  }

  allConditionsValid() {
    return this.conditions.length
      && this.conditions.every(
        (condition) => (
          condition.source_id && condition.comparator && condition.match_value
        ),
      );
  }
}

export default LogicValidator;
