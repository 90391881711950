import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'underscore';

const RenewalManager = (props) => {
  const renderRenewalAction = () => {
    // This may eventually be many things,
    // eg. nothing required, same process required,
    // different process required, etc.
    // For now it's just same process.

    return renderRenewalProcess();
  };

  const onCancelClick = (e) => {
    e.preventDefault();

    isFunction(props.onCancelClick) && props.onCancelClick();
  };

  const renderRenewalProcess = () => {
    return (
      <div>
        <div className='margin-bottom color-text-medium'>This will start a new <strong>{props.output.current_published_workflow_name}</strong> application. </div>
        <a
          className={`btn btn-primary margin-right beginrenewalbutton ${(props.variation === 'mini') && 'small margin-bottom'}`}
          href={`/issued_outputs/${props.output.id}/renew`}
          data-method='post'
        >Begin {props.output.name} renewal
        </a>

        <a
          className={`btn btn-thirdary ${(props.variation === 'mini') && 'small'}`}
          href='#'
          onClick={onCancelClick}
        >Cancel
        </a>
      </div>
    );
  };

  return (
    <div className='renewalmanager-container'>
      {renderRenewalAction()}
    </div>
  );
};

RenewalManager.propTypes = {
  output: PropTypes.object.isRequired,
  onCancelClick: PropTypes.func,
  variation: PropTypes.string,
};

RenewalManager.defaultProps = {
  variation: 'normal',
};

export default RenewalManager;
