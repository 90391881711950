import React from 'react';
import PropTypes from 'prop-types';
import StepBreakdown from 'components/public/step-breakdown';
import _ from 'underscore';
import dayjs from 'dayjs';
import PublicOverviewHeader from './public-overview-header';

const NO_DATA_SYMBOL = '---';
const CONTENT_FOR_FREE = 'No payment required';

class ProcessOverview extends React.PureComponent {
  renderMedianTime = () => {
    const time = this.props.flowTemplateData.digest?.median_process_time;
    if (time) {
      return dayjs.duration(time, 'seconds').humanize();
    }

    return NO_DATA_SYMBOL;
  };

  renderCostRange = () => {
    const lowEnd = this.props.flowTemplateData.digest?.lowest_cost;
    const highEnd = this.props.flowTemplateData.digest?.highest_cost;

    if (!_.isNumber(lowEnd) || !_.isNumber(highEnd)) {
      return NO_DATA_SYMBOL;
    }

    if (highEnd === 0) {
      return CONTENT_FOR_FREE;
    }

    const formatter = Intl.NumberFormat('en', { style: 'currency', currency: 'USD' });

    if (lowEnd === highEnd) {
      return formatter.format(lowEnd / 100);
    }

    return `${formatter.format(lowEnd / 100)} - ${formatter.format(highEnd / 100)}`;
  };

  render() {
    return (
      <div id='publicoverview'>
        <PublicOverviewHeader
          flowTemplateData={this.props.flowTemplateData}
          user={this.props.user}
          myTeams={this.props.myTeams}
        />

        <section className='column narrow margin-bottom-more'>
          {(this.props.flowTemplateData.public_median_time
            || this.props.flowTemplateData.public_cost_range) && (
            <div id='publicoverview-stats'>
              {this.props.flowTemplateData.public_median_time && (
                <dl>
                  <dt>
                    <span className='icon icon-clock' />
                    Typically takes
                  </dt>
                  <dd>{this.renderMedianTime()}</dd>
                </dl>
              )}
              {this.props.flowTemplateData.public_cost_range && (
                <dl>
                  <dt>
                    <span className='icon icon-money-dark' />
                    Cost
                  </dt>
                  <dd>{this.renderCostRange()}</dd>
                </dl>
              )}
            </div>
          )}
          <StepBreakdown
            steps={this.props.flowTemplateData.steps}
            cityName={this.props.flowTemplateData.team.name}
            digest={this.props.flowTemplateData.digest}
          />
        </section>
      </div>
    );
  }
}

ProcessOverview.propTypes = {
  flowTemplateData: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  myTeams: PropTypes.array,
};

export default ProcessOverview;
