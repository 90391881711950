import React from 'react';
import renderer from 'modules/renderer';
import CommonProviders from 'components/common-providers';
import SearchPageContainer from 'components/search-page-container';
import Output from 'components/initiated_flow/process/output/output';

function show() {
  renderer(
    (
      <CommonProviders>
        <SearchPageContainer
          name={CityGrows.Server.name}
          searchSlug={CityGrows.Server.searchSlug}
          instructions={CityGrows.Server.instructions}
        />
      </CommonProviders>
    ), document.getElementById('search-page'),
  );
}

function renderOutput(output) {
  return <Output output={output} isCustomResult={true} />;
}

function showResult() {
  renderer(
    (
      <>
        {CityGrows.Server.outputs.map(renderOutput)}
      </>
    ), document.getElementById('outputs')
  )
}

export default {
  show,
  showResult,
};
