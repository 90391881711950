import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });

    if (CityGrows.Server.env == 'development') {
      console.log(error);
    } else {
      Rollbar.error(error, info);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='error-boundary fullheight'>
          <img src='/images/500.png' alt='It looks like something went wrong' />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
