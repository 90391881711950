import _ from 'underscore';

const ReviewRequestsAPI = {
  
  create(reviewRequestData){
    const url = '/review_requests';

    return $.ajax(url, {
      method: 'POST',
      dataType: 'json',
      data: {
        review_request: reviewRequestData 
      }
    });
  },

  destroy(reviewRequestId){
    const url = '/review_requests/' + reviewRequestId;

    return $.ajax(url, {
      method: 'DELETE',
      dataType: 'json'
    });
  },

  doReview(token, signatureData){
    const url = '/review_requests/' + token + '/do_review';

    return $.ajax(url, {
      method: 'POST',
      dataType: 'json',
      data: {
        signature: signatureData
      }
    });
  }
};

export default ReviewRequestsAPI;