// Don't use this anymore. Use popdown.jsx instead.

import PropTypes from 'prop-types';
import React from 'react';
import PopupButton from 'components/shared/popup-button';

class AutonomousPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  onToggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }

    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <PopupButton {...this.props} open={this.state.open} onToggle={this.onToggle} onClose={this.onClose} />
    );
  }
}

AutonomousPopup.propTypes = {
  nubDirection: PropTypes.oneOf(['left', 'right', 'up', 'down']),
  buttonClassName: PropTypes.string,
  wrapperClassname: PropTypes.string,
  closeOnPopupClick: PropTypes.bool,
  buttonHtml: PropTypes.node,
  onClose: PropTypes.func,
  wrapperIgnoreClass: PropTypes.arrayOf(PropTypes.string),
};

export default AutonomousPopup;
