import React from 'react';
import Map from 'components/shared/input_fields/entity-input/map';
import InlineNotification from 'components/shared/inline-notification';

const EsriFields = ({
  entity,
}) => {
  const renderFields = () => {
    return Object.entries(entity.field_data).map(([key, val]) => {
      return (
        <label>
          {key}
          <input type='text' value={val} disabled />
        </label>
      );
    });
  };

  if (!entity.field_data) {
    return (
      <InlineNotification>
        Remote entity not found.
      </InlineNotification>
    );
  }

  return (
    <div>
      <Map
        feature={entity.feature}
        />
      {renderFields()}
    </div>
  );
};

export default EsriFields;