import React, { useMemo, useState } from 'react';
import CopyableLink from 'components/shared/copyable-link';
import AutonomousPopup from 'components/shared/autonomous-popup';
import { publicHost } from 'modules/url-generator';
import PersonSelector from 'components/shared/person-selector';
import PropTypes from 'prop-types';
import InitiatedFlowActions from 'actions/initiated-flow-actions';
import Selection from 'components/shared/person-selection';

const LaunchOptions = (props) => {
  const linkURL = `${publicHost()}/${props.masterTemplate.team.friendly_id}/${props.masterTemplate.friendly_id}`;
  const isDisabled = props.masterTemplate.status === 'archived';
  const [applicant, setApplicant] = useState(props.applicant);

  const launchButtonPath = useMemo(() => {
    return `/initiated_flows/manager?flow_template_id=${props.flowTemplateId}&primary_applicant_id=${applicant?.id}`;
  }, [applicant, props.flowTemplateId]);

  const onApplicantUserSelect = (e) => {
    setApplicant(e);
  };

  const onApplicantUserRemove = () => {
    setApplicant(null);
  };

  const searchPerson = (search) => {
    return InitiatedFlowActions.searchApplicants(search);
  };

  const renderApplicant = () => {
    if (applicant) {
      return (
        <Selection
          selection={applicant}
          onRemove={onApplicantUserRemove}
          showAsCurrentUser={applicant?.id === props.applicantUser?.id}
          removeLinkText='Change'
        />
      );
    }
    return (
      <PersonSelector
        onSelect={onApplicantUserSelect}
        doSearch={searchPerson}
        allowPseudo={false}
        hideExternal={false}
        placeholder='Search people'
        attachableType='Applicant'
        personNoFoundText='Not found, the user needs to create an account first.'
      />
    );
  };

  const renderLauncher = () => {
    return (
      <AutonomousPopup buttonHtml='Start form' wrapperClassname='launchoptions' disabled={isDisabled} buttonClassName='btn-primary' onClose={() => { setApplicant(props.applicant); }}>
        <div className='launchoptions-public'>
          <h1>Share the link to start this form</h1>
          <CopyableLink linkURL={linkURL} />
        </div>
        <div className='launchoptions-public'>
          <h1>Start this form yourself</h1>
          <div className='launchoptions-admin cf'>
            <span className='label'>Applicant</span>
            <div className='applicantselector'>
              { renderApplicant() }
            </div>
            <a id='startworkflowbutton' href={launchButtonPath} className={`btn-primary start ${applicant ? '' : 'disabled'}`} data-method='post'>Start</a>
          </div>
        </div>
      </AutonomousPopup>
    );
  };

  return renderLauncher();
};

LaunchOptions.propTypes = {
  flowTemplateId: PropTypes.string.isRequired,
  masterTemplate: PropTypes.shape({
    status: PropTypes.string.isRequired,
    friendly_id: PropTypes.string.isRequired,
    team: PropTypes.shape({
      friendly_id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  applicant: PropTypes.shape({
    id: PropTypes.number,
  }),
};
export default LaunchOptions;
