import React, { useRef, useEffect } from 'react';
import {
  Chart, PieController, CategoryScale, LinearScale, Legend, Tooltip, ArcElement,
} from 'chart.js';

Chart.register(PieController, CategoryScale, LinearScale, Legend, Tooltip, ArcElement);

const PieChart = (props) => {
  const chart = useRef(null);

  useEffect(() => {
    const options = { responsive: true, ...props.options };
    new Chart(chart.current, {
      type: 'pie',
      data: props.data,
      options,
    });
  }, []);

  return (
    <canvas ref={chart} id='myChart' />
  );
};

export default PieChart;
