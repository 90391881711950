import React from 'react';
import { render } from 'react-dom';

import LaunchManager from 'components/initiated_flow/launch-manager';
import { pollForLogin } from 'modules/poll-for-login';
import CommonProviders from 'components/common-providers';
import InitiatedFlowManager from '../components/initiated-flow-manager';

const show = () => {
  pollForLogin();

  const el = (
    <CommonProviders>
      <InitiatedFlowManager
        step={CityGrows.Server.initiatedFlowData.step}
        currentUserId={CityGrows.Server.currentUserId}
        admin
        initiatedFlow={CityGrows.Server.initiatedFlowData.initiated_flow}
        flowTemplate={CityGrows.Server.initiatedFlowData.flow_template}
        masterTemplate={CityGrows.Server.initiatedFlowData.master_template}
        activeStep={CityGrows.Server.initiatedFlowData.active_step}
        notifications={CityGrows.Server.FlashMessages}
        showAdminWarning={false}
      />
    </CommonProviders>
  );

  render(el, document.getElementById('flowcontent'));
};

const start = () => {
  render(
    (
      <CommonProviders>
        <LaunchManager
          subprocessRequirement={CityGrows.Server.initiatedFlowData.subprocess_requirement}
          flowTemplate={CityGrows.Server.initiatedFlowData.flow_template}
          currentUser={CityGrows.Server.initiatedFlowData.current_user}
          primaryApplicant={CityGrows.Server.initiatedFlowData.primary_applicant}
          secondaryApplicantIds={CityGrows.Server.initiatedFlowData.secondary_applicant_ids}
          masterTemplateId={CityGrows.Server.initiatedFlowData.master_template.id}
        />
      </CommonProviders>
    ), document.getElementById('flowcontent'),
  );
};

export default {
  show,
  start,
};
