import React from 'react';
import EmbeddedTextarea from 'components/shared/embedded-text-area';
import { debounce } from 'underscore';

const RequirementDescriptionManager = (props) => {
  const updateDescription = (newDescription) => {
    if (newDescription !== props.description) {
      props.update(props.step.id, { description: newDescription });
    }
  };

  return (
    <EmbeddedTextarea
      defaultValue={props.description}
      placeholder={props.disabled ? '' : 'Add a brief description...'}
      disabled={props.disabled}
      onChange={debounce(updateDescription, 500)}
      className='stepcreator-description'
    />
  );
};

export default RequirementDescriptionManager;
