
import Flash from '../components/shared/flash';

var JobsAPI = {
  status: function(jobId){
    const URL = `/jobs/${jobId}`;

    return $.ajax(URL, {
      dataType: 'json',
      method: 'GET'
    });
  },

	destroy: function(jobID){
		const URL = `/jobs/${jobID}`;

		return $.ajax(URL, {
			method: 'DELETE',
			dataType: 'JSON'
		})
		.error(() => { Flash.error(); });
	}
};

export default JobsAPI;
