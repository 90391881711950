import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isEmpty, findWhere } from 'underscore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useAdvancedFilterContext, useAdvancedFilterDispatchContext } from './advanced-filter/context';
import FilterUnit from './advanced-filter/filter-unit';
import LoadFilterModal from './advanced-filter/modals/load-filter-modal';
import SaveFilterModal from './advanced-filter/modals/save-filter-modal';
import Select from 'components/shared/select';
import CloseIfOutsideWrapper from 'components/shared/close-if-outside-wrapper';
import Popdown from 'components/shared/popdown';
import AdvancedFilterAPI from 'apis/advanced-filter-api';
import { error } from 'components/shared/flash'
import useLoadTemplates from "components/hooks/use-load-templates";
import useLoadTags from "components/hooks/use-load-tags";

const AdvancedFilter = ({
  onFilterApply,
  menuAvailable,
  kind,
  defaultFilter,
  previousState,
  filterParams,
}) => {
  const state = useAdvancedFilterContext();
  const dispatch = useAdvancedFilterDispatchContext();
  const {
    activeFilters,
    availableFilters,
    currentFilter,
    loadedFilter,
    savedFilters,
    triggerRefresh,
  } = state;

  const [showMenuOptions, setShowMenuOptions] = useState(false);
  const [showLoadFilter, setShowLoadFilter] = useState(false);
  const [showSaveFilter, setShowSaveFilter] = useState(false);
  const [minify, setMinify] = useState(false);
  const menuAnchor = useRef(null);

  const formTemplates = useLoadTemplates();
  const tags = useLoadTags();

  useEffect(() => {
    if (filterParams && Object.keys(filterParams).length > 0) {
      const filters = {
        completed_status: { value: filterParams['completedStatus'] },
        imported: {value: filterParams['importType'], operator: 'is', options: {source_id: filterParams['importId']} }
      }

      return dispatch({
        type: 'loadFilters',
        value: { filters },
      })
    }

    if (previousState && previousState.availableFilters) {
      return dispatch({
        type: 'loadState',
        value: previousState,
      })
    }

    if (defaultFilter) {
      return dispatch({
        type: 'cacheAndLoadFilter',
        value: defaultFilter,
      });
    }

    return dispatch({
      type: 'loadDefault'
    })
  }, []);

  useEffect(() => {
    if (triggerRefresh > 0) { 
      applyFilters(); 
    }
  }, [triggerRefresh])

  function applyFilters () {
    onFilterApply(currentFilter, { ...state })
  }
 
  const addActiveFilter = (value) => {
    if (!isEmpty(value)) {
      dispatch({
        type: 'addFilter',
        value,
      });
    }
  };

  const openLoadFilterModal = () => {
    setShowMenuOptions(false);
    setShowLoadFilter(true);
  };

  const openSaveFilterModal = () => {
    setShowMenuOptions(false);
    setShowSaveFilter(true);
  };

  function onLoadFilter (filterId) {
    const filter = findWhere(savedFilters, { id: filterId })

    if (filter.filters) {
      dispatch({
        type: 'cacheAndLoadFilter',
        value: filter,
      });
      setShowLoadFilter(false);
      return null;
    }

    AdvancedFilterAPI.get(filterId)
      .done((res) => {
        dispatch({
          type: 'cacheAndLoadFilter',
          value: res.filter,
        });
      })
      .fail(() => error('Could not load the filter.'));
    setShowLoadFilter(false);
  }

  const renderFilterSelect = useCallback(() => {
    return (
      <div className='metricsdashboard-filterselect add-filter'>
        <Select
          options={availableFilters}
          onChange={addActiveFilter}
          value={null}
          className='react-select-metrics advanced-filter'
          placeholder='+ Add filter'
        />
      </div>
    );
  }, [availableFilters]);

  const renderActiveFilters = () => {
    const joiningAndSpan = (
      <span className='bold margin-left muted'>And</span>
    );

    const filters = activeFilters.map((filter, idx) => {
      const prependJoiningAnd = idx > 0;

      return (
        <>
          {prependJoiningAnd && joiningAndSpan}
          <FilterUnit
            key={filter.id}
            filter={filter}
            state={state}
            dispatch={dispatch}
            formTemplates={formTemplates}
            tags={tags}
          />
        </>
      );
    });

    return (
      <>
        {filters}
        {renderFilterSelect()}
      </>
    );
  };

  const renderMinified = () => {
    const joiningAndSpan = (
      <span className='margin-left margin-right muted'>|</span>
    );
    const filters = activeFilters.map((filter, idx) => {
      const prependJoiningAnd = idx > 0;

      return (
        <>
          {prependJoiningAnd && joiningAndSpan}
          <FilterUnit
            key={filter.id}
            filter={filter}
            state={state}
            dispatch={dispatch}
            formTemplates={formTemplates}
            tags={tags}
            minify
          />
        </>
      );
    });

    return (
      <div className='margin-left' style={{ marginTop: '4px' }}>
        {filters}
      </div>
    );
  };

  const renderLoadFilter = () => {
    return (
      <LoadFilterModal
        onLoad={onLoadFilter}
        onClose={() => {setShowLoadFilter(false)}}
        kind={kind}
        dispatch={dispatch}
        filters={savedFilters}
      />
    );
  };

  const renderSaveFilter = () => {
    return (
      <SaveFilterModal
        filters={currentFilter}
        kind={kind}
        onClose={() => setShowSaveFilter(false)}
        loadedFilter={loadedFilter}
        dispatch={dispatch}
      />
    );
  };

  const renderMenu = () => {
    return (
      <span
        ref={menuAnchor}
        className='filter-menu-options'
        id='filter-menu-options'
        onClick={() => setShowMenuOptions(true)}
      >
        <FontAwesomeIcon icon={solid('ellipsis')} size='lg' style={{ color: '#0463B7' }} />
      </span>
    );
  };

  const minifyOption = () => {
    if (minify) {
      return (
        <li onClick={() => {setMinify(false)}}>
          <FontAwesomeIcon icon={solid('eye')} size='lg' style={{ color: '#0463B7' }} />
          <span>Show filters</span>
        </li>
      )
    }

    return (
      <li onClick={() => {setMinify(true)}}>
      <FontAwesomeIcon icon={solid('eye-slash')} size='lg' style={{ color: '#0463B7' }} />
      <span>Hide filters</span>
    </li>
    )
  }

  const renderMenuOptions = () => {
    return (
      <Popdown anchorRef={menuAnchor} justify='right'>
        <CloseIfOutsideWrapper onClose={() => setShowMenuOptions(false)}>
          <div className='filter-menu-options-popdown'>
            <ul>
              <li onClick={openSaveFilterModal}>
                <FontAwesomeIcon icon={solid('floppy-disk')} size='lg' style={{ color: '#0463B7' }} />
                <span>Save current filter</span>
              </li>
              <li onClick={openLoadFilterModal}>
                <FontAwesomeIcon icon={solid('list')} size='lg' style={{ color: '#0463B7' }} />
                <span>Manage saved filters</span>
              </li>
              {minifyOption()}
            </ul>
          </div>
        </CloseIfOutsideWrapper>
      </Popdown>
    );
  };

  const filterOptions = () => {
    return savedFilters.map(({ id, name }) => ({
      value: id,
      label: name,
    }))
  }

  const renderFastFilters = () => {
    return (
      <div className='border-right' style={{ padding: '5px 10px 0 0'  }}>
        <Select
          options={filterOptions()}
          onChange={(e) => { onLoadFilter(e.value) }}
          value={filterOptions().find(({ value }) => value === loadedFilter?.id) ?? null}
          className='react-select-metrics'
          placeholder='Saved Filters'
          styles={{
            singleValue: (provided) => ({
              ...provided,
              fontWeight: 'bold',
            })
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div className='dashboard-filters'>
        <div className='metricsdashboard-fastfilter'>
          {renderFastFilters()}
        </div>
        <div style={{ flex: 1 }}>
          {minify ? renderMinified() : renderActiveFilters()}
        </div>
        <div style={{ marginLeft: 'auto' }}>
          {menuAvailable && renderMenu()}
        </div>
      </div>
      {showMenuOptions && renderMenuOptions()}
      {showLoadFilter && renderLoadFilter()}
      {showSaveFilter && renderSaveFilter()}
    </>
  );
};

export default AdvancedFilter;
