const ExportsAPI = {
  fetch(){
    return $.ajax('/exports', {
      method: 'GET',
      dataType: 'json'
    });
  },

  remove(id){
    return $.ajax(`/exports/${id}`, {
      method: 'DELETE',
      dataType: 'json'
    });
  }
};

export default ExportsAPI;
