import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { isArray, debounce } from 'underscore';
import truncate from 'truncate';
import { Draggable } from 'react-beautiful-dnd';

import InlineNotification from 'components/shared/inline-notification';
import Select from 'components/shared/select';
import { getDataTypes, fieldNameForType } from 'modules/step-data-types';
import { confirm } from 'modules/alert-confirm';
import FlowStepActions from 'actions/flow-step-actions';
import MultipleChoiceOptionActions from 'actions/multiple-choice-option-actions';
import { error as err } from 'components/shared/flash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import MultipleChoices from './multiple-choices';
import FieldSettings from './field-settings';
import DropdownMenu from '../dropdown-menu';

const StepDataCollectorRow = ({
  datum, stepType, disabled, onClick, active, index, customEntities, autofillEnabledOnTemplate,
}) => {
  const [error, setError] = useState();
  const [nameValue, setNameValue] = useState(datum.name === 'Untitled field' ? '' : datum.name);

  const onDataChanged = useCallback((attrs, onSuccess = null, onFail = null) => {
    return FlowStepActions.updateStepData(datum.id, attrs)
      .done((res) => {
        if (onSuccess) {
          onSuccess(res);
        }
      })
      .fail((res) => {
        if (onFail) {
          onFail();
        }
        handleError(res);
      });
  }, [datum]);

  const debouncedOnDataChanged = useMemo(() => debounce(onDataChanged, 500), [onDataChanged]);

  const [prevType, setPrevType] = useState(datum.data_type);
  useEffect(() => {
    if (prevType !== datum.data_type) {
      setPrevType(datum.data_type);
    }
  }, [datum, prevType]);

  const onTypeChange = ({ value }) => {
    onDataChanged({
      data_type: value, default: '', multiple_choice_options: null, custom_entity_id: null, custom_entity: null,
    });
  };

  const onDeleteButtonClick = (e) => {
    e.preventDefault();

    confirm('Are you sure?', `Are you sure you want to delete ${datum.name}?`, () => {
      FlowStepActions.deleteStepData(datum.id)
        .fail(handleError);
    });
  };

  const onMultipleChoiceOptionChange = (id, attrs) => (
    MultipleChoiceOptionActions.updateOption(id, attrs)
      .fail(handleError)
      .done(() => setError(null))
  );

  const handleError = (res) => {
    if (!res.responseJSON.errors) {
      err();
    } else if (isArray(res.responseJSON.errors)) {
      err(res.responseJSON.errors[0]);
    } else {
      setError(res.responseJSON.errors.base[0]);
    }
  };

  const dataTypeOptions = getDataTypes(stepType, { customEntities })
    .map((dataType) => ({ value: dataType.type, label: dataType.name }));

  const isDataTypeWithOptions = ['multiple_choice', 'checkboxes'].includes(datum.data_type);

  const onRowClick = () => {
    if (onClick) {
      onClick(datum.id);
    }
  };

  const onDuplicateButtonClick = () => {
    FlowStepActions.duplicateDatum(datum.id);
  };

  const renderMenu = () => {
    return (
      <DropdownMenu>
        <ul>
          <li onClick={onDuplicateButtonClick}>
            <FontAwesomeIcon
              icon={icon({ name: 'clone' })}
              style={{ color: '#0463B7' }}
            />
            <span>Clone Field</span>
          </li>
          <li onClick={onDeleteButtonClick}>
            <FontAwesomeIcon
              icon={icon({ name: 'trash' })}
              style={{ color: '#0463B7' }}
            />
            <span>Delete Field</span>
          </li>
        </ul>
      </DropdownMenu>
    );
  };

  return (
    <Draggable draggableId={`datum-${datum.id}`} index={index}>
      {({ innerRef, draggableProps, dragHandleProps }) => {
        // This is here because it needs a reference to the draggable props
        const options = (
          <div className='fieldmanager-actions'>
            <div
              title='Sort'
              className='fieldmanager-order grabbable'
              {...dragHandleProps}
            >
              <FontAwesomeIcon
                icon={icon({ name: 'arrow-down-arrow-up' })}
                style={{ color: '#0463B7' }}
              />
            </div>
            {renderMenu()}
          </div>
        );

        return (
          <div
            ref={innerRef}
            {...draggableProps}
          >
            <div id={datum.id}>
              {disabled || !active ? (
                <div onClick={onRowClick} className='fieldmanager collapsed'>
                  <input type='text' className='fieldmanager-input' disabled value={truncate(datum.name, 60)} />
                  <div className='fieldmanager-type'>
                    {fieldNameForType(datum.data_type, { customEntities, customEntityId: datum.custom_entity_id })}
                  </div>
                  <div className='fieldmanager-configcue'>
                    <FontAwesomeIcon
                      title={datum.required ? 'This field is required' : 'This field is optional'}
                      icon={icon({ name: 'lock' })}
                      style={{ opacity: datum.required ? '1' : '0.3' }}
                    />
                    <FontAwesomeIcon
                      title={datum.redacted ? 'This field is NOT visible to the public in your open data portal' : 'This field is visible to the public in your open data portal'}
                      icon={icon({ name: 'eye' })}
                      style={{ opacity: datum.redacted ? '0.3' : '1' }}
                    />
                  </div>
                  {options}
                </div>
              ) : (
                <div className={`${error ? ' error' : ''} fieldmanager`}>
                  <div>
                    <div className='cf fieldmanager-namewrap'>
                      <input
                        className='fieldmanager-nameinput'
                        onChange={(e) => {
                          const { value } = e.target;
                          setNameValue(value);
                          debouncedOnDataChanged({ name: value });
                        }}
                        value={nameValue}
                        name='fieldname'
                        placeholder='Add field title'
                        type='text'
                      />

                      <Select
                        value={dataTypeOptions.find(({ value }) => value === (datum.data_type === 'entity' ? `entity-${datum.custom_entity_id}` : datum.data_type))}
                        options={dataTypeOptions}
                        onChange={onTypeChange}
                        disabled={disabled}
                        styles={{
                          container: (provided) => ({
                            ...provided,
                            display: 'inline-block',
                            // Approximately 0.95 - 1/phi
                            width: '33%',
                          }),
                        }}
                      />
                    </div>

                    {(isDataTypeWithOptions) && (
                      <span>
                        <MultipleChoices
                          key={datum.id}
                          options={datum.multiple_choice_options}
                          stepDatum={datum}
                          disabled={disabled}
                          onDeleteFail={handleError}
                          onOptionChange={onMultipleChoiceOptionChange}
                        />
                        <br />
                      </span>
                    )}
                  </div>

                  {error && <InlineNotification className='margin-top'>{error}</InlineNotification>}
                  <FieldSettings
                    stepDatum={datum}
                    multipleChoiceOptions={datum.multiple_choice_options}
                    autofillEnabled={autofillEnabledOnTemplate}
                    onDataChanged={onDataChanged}
                    disabled={disabled}
                    fieldName={fieldNameForType(datum.data_type, { customEntities, customEntityId: datum.custom_entity_id })}
                  >
                    {options}
                  </FieldSettings>
                </div>
              )}
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

StepDataCollectorRow.propTypes = {
  active: PropTypes.bool.isRequired,
  allowedStepDatumTypes: PropTypes.array.isRequired,
  customEntities: PropTypes.array,
  datum: PropTypes.shape({
    data_type: PropTypes.string.isRequired,
    default: PropTypes.string,
    entity_createable: PropTypes.bool,
    id: PropTypes.number.isRequired,
    min_num_checks: PropTypes.number.isRequired,
    multiple_choice_options: PropTypes.array.isRequired,
    name: PropTypes.string,
    redacted: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  stepType: PropTypes.string.isRequired,
  index: PropTypes.number,
  autofillEnabledOnTemplate: PropTypes.bool.isRequired,
};

export default StepDataCollectorRow;
