import React from 'react';

const TextEditorButtons = (props) => {
  function renderCancelButton() {
    return (
      <button onClick={props.onClose} className='btn-thirdary'>
        Cancel
      </button>
    );
  }

  return (
    <div className='texteditor-buttons'>
      {props.onClose && renderCancelButton()}
      <button onClick={props.onSave} disabled={props.saveDisabled} className={`btn-primary ${props.saveDisabled ? 'disabled' : ''}`}>
        Save {props.type}
      </button>
    </div>
  );
};

TextEditorButtons.defaultProps = {
  saveDisabled: false,
};

export default TextEditorButtons;
