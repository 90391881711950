import invariant from 'invariant';
import { reject, findWhere } from 'underscore';

const fixedDataTypes = [
  {
    name: 'Text',
    type: 'string',
  },
  {
    name: 'Paragraph text',
    type: 'text',
  },
  {
    name: 'Email',
    type: 'email',
  },
  {
    name: 'Number (no decimal)',
    type: 'integer',
  },
  {
    name: 'Number (with decimal)',
    type: 'float',
  },
  {
    name: 'Money',
    type: 'money',
  },
  {
    name: 'Address',
    type: 'address',
  },
  { name: 'Date Range',
    type: 'date_range'
  },
  {
    name: 'Date/Time',
    type: 'date',
  },
  {
    name: 'Multiple choice (choose one)',
    type: 'multiple_choice',
  },
  {
    name: 'Checkboxes (choose many)',
    type: 'checkboxes',
  },
  {
    name: 'File',
    type: 'file',
  },
  {
    name: 'Phone number',
    type: 'phone',
  },
];

const buildCustomEntityField = (customEntity) => {
  return {
    name: customEntity.name,
    type: `entity-${customEntity.id}`,
    customEntityId: customEntity.id,
  };
};

const allDataTypes = (entityTypes = []) => {
  let types = fixedDataTypes;

  entityTypes.forEach((customEntity) => {
    types = types.concat(buildCustomEntityField(customEntity));
  });

  return types;
};

export function getDataTypes(dataCollectible, opts = {}) {
  switch (dataCollectible) {
  case 'CollectInfoStep':
    invariant(opts.customEntities, 'Must provide custom entities for CollectInfoStep');

    return allDataTypes(opts.customEntities);
  case 'SurveyStep':
    return reject(fixedDataTypes, (dataType) => ['file', 'entity'].includes(dataType.type));
  case 'Entity':
    return reject(fixedDataTypes, (dataType) => ['multiple_choice', 'checkboxes'].includes(dataType.type));
  default:
    throw `Invalid dataCollectible ${dataCollectible}`;
  }
}

export function fieldNameForType(type, opts = {}) {
  if (type === 'entity') {
    if (opts.customEntityName) {
      // Probably we should just be passing this everywhere instead
      // of overcomplicating things below.
      return opts.customEntityName;
    }
    
    invariant(opts.customEntities, 'Must provide custom entities');
    invariant(opts.customEntityId, `Must provide customEntityId. Got: ${opts.customEntityId}`);

    type = `entity-${opts.customEntityId}`;
  }

  const dataType = findWhere(allDataTypes(opts.customEntities), { type });
  invariant(dataType, 'Data type does not exist');

  return dataType.name;
}

const StepDataTypes = {
  getDataTypes,
  fieldNameForType,
};

export default StepDataTypes;
