import React from 'react';
import PropTypes from 'prop-types';

import Pie from 'components/shared/chart/pie';

const MultipleChoiceVisualizer = ({ data }) => (
  <Pie data={data} />
);

MultipleChoiceVisualizer.propTypes = {
  data: PropTypes.object,
};

export default MultipleChoiceVisualizer;
