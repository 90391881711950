import RequirementAPI from '../apis/requirement-api';
import Constants from '../constants/initiated-flow-constants';
import { error } from '../components/shared/flash';

const RequirementActions = {
  updateAttrs(dispatch, requirementId, attrs, type) {
    return RequirementAPI.update(requirementId, attrs, type).done((res) => {
      dispatch({
        type: Constants.REQUIREMENT_UPDATED,
        requirement: res,
      });
    });
  },

  // NOT CURRENTLY BEING USED
  // delete: function(requirementId, type){
  //   return RequirementAPI.delete(requirementId, type)
  //                        .done(function(res){
  //                          Dispatcher.dispatch({
  //                            actionType: Constants.REQUIREMENT_DELETED,
  //                            requirementId: requirementId,
  //                            currentStep: res.step,
  //                            activeStep: res.active_step
  //                        });
  //   });
  // },

  send(dispatch, requirementId, type) {
    return RequirementAPI.update(requirementId, { sent: true }, type).done((res) => {
      dispatch({
        type: Constants.REQUIREMENT_UPDATED,
        requirement: res,
      });
    }).fail((res) => {
      error(res.responseText);
    });
  },
};

export default RequirementActions;
