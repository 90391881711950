import React from "react";

import PrimaryTabs from "./shared/primary-tabs";
import OutputsTab from "components/dashboard/outputs-tab";

const DashboardOutputs = (props) => {
  return (
    <>
      <PrimaryTabs />
      <div id='tabheader-body' className='dashboard-borderwrap'>
        <OutputsTab {...props} />
      </div>
    </>
  );
};

export default DashboardOutputs;
