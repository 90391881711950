const SurveyAPI = {
  update: function(surveyId, attrs){
    const url = '/surveys/' + surveyId;
    return $.ajax(url, {
      method: 'PUT',
      dataType: 'json',
      data: {
        survey: attrs
      }
    });
  },

  submit: function(surveyId, surveyToken, data){
    const url = '/surveys/' + surveyId;
    return $.ajax(url, {
      method: 'POST',
      dataType: 'json',
      data: {
        survey_token: surveyToken,
        data
      }
    });
  }
};

export default SurveyAPI;
