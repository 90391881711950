import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import StepDataCollectorInputField from 'components/shared/step-data-collector-input-field';
import CollectInfoStepActions from 'actions/collect-info-step-actions';
import { validate } from 'modules/collected-data-validator';
import InformationalText from 'components/shared/informational-text';
import { filter, any, contains } from 'underscore';

const CollectInfoSection = (props) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);

  function firstErrorId() {
    const firstDatumWithError = props.stepData.find((stepDatum) => {
      const { fieldValue } = collectedDatumAttrsForInputField(stepDatum);
      return !fieldValid(fieldValue, stepDatum);
    });

    if (firstDatumWithError === undefined) {
      return null;
    }

    return firstDatumWithError.id;
  }

  function fieldValid(input, stepDatum) {
    return !stepDatum.required || validate(input, stepDatum);
  }

  function getCollectedDatum(stepDatumId) {
    return props.collectedData.find((collectedDatum) => {
      return collectedDatum.step_datum_id === stepDatumId;
    });
  }

  function collectedDatumAttrsForInputField(stepDatum) {
    const collectedDatum = getCollectedDatum(stepDatum.id);

    return {
      fieldValue: collectedDatumValue(stepDatum.data_type, collectedDatum),
      version: collectedDatumVersion(collectedDatum),
    };
  }

  function collectedDatumVersion(collectedDatum) {
    return collectedDatum ? collectedDatum.version : 1;
  }

  function collectedDatumValue(dataType, collectedDatum) {
    if (dataType === 'file') {
      return collectedDatum ? collectedDatum.files : [];
    }
    return collectedDatum ? collectedDatum.content : null;
  }

  function onFieldDataChanged(stepDatumId, fieldValue, version) {
    CollectInfoStepActions.dataChanged(
      dispatch,
      stepDatumId,
      props.initiatedFlow.id,
      fieldValue,
      version,
      props.step.generic_type,
    );
  }

  function isStepLocked() {
    return props.locked || (!props.external && !props.admin);
  }

  function isFieldLocked(fieldId) {
    return contains(props.lockedFields, String(fieldId));
  }

  function renderField(stepDatum) {
    const { fieldValue, version } = collectedDatumAttrsForInputField(stepDatum);
    const firstError = !!(stepDatum.id === firstErrorId);

    return (
      <StepDataCollectorInputField
        key={stepDatum.id}
        admin={props.admin}
        onInputChanged={onFieldDataChanged}
        stepDatum={stepDatum}
        templateId={props.step.flow_template_id}
        initiatedFlowId={props.initiatedFlow.id}
        teamId={props.teamId}
        fieldValue={fieldValue}
        firstError={firstError}
        version={version}
        attemptedAdvance={props.attemptedAdvance}
        persistBeforeSubmit
        lockedFields={props.lockedFields}
        isStepLocked={isStepLocked()}
        isFieldLocked={isFieldLocked(stepDatum.id)}
        disabled={isStepLocked() || isFieldLocked(stepDatum.id)}
        activeStepId={props.activeStepId}
      />
    );
  }

  function renderFields() {
    return fieldsToRender().map((stepDatum, index) => renderField(stepDatum, index));
  }

  function fieldsToRender() {
    return filter(props.section.step_data, (stepDatum) => {
      if (!props.external && stepDatum.redacted && !props.admin) { return false; }

      return true;
    });
  }

  function noViewableInstructions() {
    if (!props.section.instructions) { return true; }

    return (!props.external && !props.admin);
  }

  function renderInstructions() {
    if (noViewableInstructions()) { return; }

    return (
      <InformationalText
        text={props.section.instructions}
        textType='SectionInstructions'
        textContainerId={props.section.id}
        initiatedFlow={props.initiatedFlow}
      />
    );
  }

  if (!any(fieldsToRender()) && noViewableInstructions()) {
    return null;
  }

  return (
    <div className='collectinfosection'>
      <div className='collectinfosection-title'>{props.section.name_or_default}</div>

      {renderInstructions()}

      <div className='collectinfosection-body'>
        {renderFields()}
      </div>
    </div>
  );
};

CollectInfoSection.propTypes = {
  locked: PropTypes.bool.isRequired,
  stepData: PropTypes.array.isRequired,
  collectedData: PropTypes.array.isRequired,
  initiatedFlow: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  section: PropTypes.shape({
    step_data: PropTypes.array.isRequired,
    name_or_default: PropTypes.string.isRequired,
    instructions: PropTypes.string,
    id: PropTypes.number,
  }),
  step: PropTypes.shape({
    flow_template_id: PropTypes.number.isRequired,
    generic_type: PropTypes.string.isRequired,
  }).isRequired,
  teamId: PropTypes.number.isRequired,
  admin: PropTypes.bool.isRequired,
  external: PropTypes.bool.isRequired,
};

export default CollectInfoSection;
