import PropTypes from 'prop-types';
import React, { useRef, useLayoutEffect } from 'react';

const EmbeddedTextInput = (props) => {
  const input = useRef();
  const measure = useRef();

  const onChange = () => {
    resize();
    props.onChange(input.current.value);
  };

  useLayoutEffect(() => {
    if (!props.disabled) {
      resize();
    }
  });

  const onKeyPress = (e) => {
    const keynum = e.which;
    if (keynum === 13) {
      input.current.blur();
    }
  };

  const resize = () => {
    const fontSize = window.getComputedStyle(input.current, null).getPropertyValue('font-size');
    const fontWeight = window.getComputedStyle(input.current, null).getPropertyValue('font-weight');
    let paddingLeft = window.getComputedStyle(input.current, null).getPropertyValue('padding-left').split('px')[0];
    paddingLeft = paddingLeft ? parseFloat(paddingLeft) : 5;
    let paddingRight = window.getComputedStyle(input.current, null).getPropertyValue('padding-right').split('px')[0];
    paddingRight = paddingRight ? parseFloat(paddingRight) : 5;
    measure.current.style.fontSize = fontSize;
    measure.current.style.fontWeight = fontWeight;
    measure.current.innerHTML = (input.current.value || props.placeholder).replace(/\s/g, '&nbsp;');
    // Adding 5 px extra spacing
    const width = measure.current.clientWidth + paddingLeft + paddingRight + 5;
    const newWidth = (props.maxWidth && (width > props.maxWidth))
      ? props.maxWidth
      : width;

    input.current.style.width = `${newWidth}px`;
  };

  const className = () => {
    const klass = props.className;
    return `embeddedtextinput ${classForSize()}${klass ? ` ${klass}` : ''}`;
  };

  const classForSize = () => {
    switch (props.size) {
    case 'small':
      return 'smaller';
    default:
      return '';
    }
  };

  const renderEditIcon = () => {
    if (!props.disabled) {
      return (
        <span
          className='icon icon-pencil-grey clickable'
          onClick={() => { input.current.select(); }}
        />
      );
    }
  };

  const renderContent = () => {
    if (props.disabled) {
      return <h1>{props.disabledValue}</h1>;
    }
    return (
      <span>
        <input
          type='text'
          defaultValue={props.defaultValue || ''}
          placeholder={props.placeholder || ''}
          onChange={onChange}
          ref={input}
          onKeyDown={onKeyPress}
          id={props.idName}
        />

        {renderEditIcon()}

        <span
          className='embeddedtextinput-measure'
          ref={measure}
        />
      </span>
    );
  };

  return (
    <div tabIndex={props.tabindex} className={className()}>
      {renderContent()}
    </div>
  );
};

EmbeddedTextInput.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  disabledValue: PropTypes.string,
  idName: PropTypes.string,
  maxWidth: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  size: PropTypes.string,
};

EmbeddedTextInput.defaultProps = {
  idName: false,
  className: '',
  size: '',
  maxWidth: null,
  placeholder: 'Start typing...',
};

export default EmbeddedTextInput;
