import React from 'react';
import PropTypes from 'prop-types';

const MoneyVisualizer = ({ data }) => (
  <div className='tablevisualizer'>
    <table>
      <tbody>
        <tr>
          <td>
            Mean amount
          </td>
          <td>
            {data.mean}
          </td>
        </tr>

        <tr>
          <td>
            Median amount
          </td>
          <td>
            {data.median}
          </td>
        </tr>

        <tr>
          <td>
            Standard deviation
          </td>
          <td>
            {data.standard_deviation}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

MoneyVisualizer.propTypes = {
  data: PropTypes.object,
};

export default MoneyVisualizer;
