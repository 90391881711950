import React, { useState } from 'react';
import dayjs from 'dayjs';
import ajaxDownload from 'modules/ajax-download';
import { confirm } from 'modules/alert-confirm';
import InitiatedFlowAPI from 'apis/initiated-flow-api';

const File = ({ file, isStepFile, initiatedFlowId, removeFile }) => {
  const [expanded, setExpanded] = useState(false);

  const onDelete = () => {
    confirm('Delete this file?', 'Caution: This will permanently delete the file', () => {
      InitiatedFlowAPI.deleteRepoFile(initiatedFlowId, file.id)
        .done(() => { removeFile(file.id); });
    });
  };

  const fileName = isStepFile ? file.file_file_name : file.filename

  function toggleDetails() {
    setExpanded(!expanded);
  };

  const onClickDownload = () => {
    ajaxDownload(file.url.original, fileName);
  }

  function renderDownload() {
    if (isStepFile) {
      return (
        <a target='_blank' href={file.url.original}>Download</a>
      );
    }

    return <a href={file.url}>Download</a>
  }

  function renderDelete() {
    if (isStepFile || !CityGrows.Server.admin) { return };

    return <button className='btn-link alert margin-left' onClick={onDelete}>Delete</button>
  }

  function renderSteDetails() {
    return (
      <>
        <div>
          <span className='bold'>Step</span>
          <span className='margin-left'>{file.step}</span>
        </div>
        <div>
          <span className='bold'>Field</span>
          <span className='margin-left'>{file.field}</span>
        </div>
      </>
    )
  }

  function renderExpanded() {
    return (
      <div className='file-details expand'>
        <div>
          {isStepFile && renderSteDetails()}
          <div>
            <span className='bold'>Upload date</span>
            <span className='margin-left'>{dayjs(file.updated_at).format('LL')}</span>
          </div>
          <div>
            <span className='bold'>Uploaded by</span>
            <span className='margin-left'>{file.admin_uploaded ? 'Admin' : 'Applicant'}</span>
          </div>
        </div>
        <div className='file-actions'>
          {renderDownload()}
          {renderDelete()}
        </div>
      </div>
    )
  }

  return (
    <div className='ifslidingpanel-file'>
      <button type='button' className='btn-link file-header' onClick={toggleDetails}>
        <b>
          {fileName}
        </b>
        <i className={`icon-arrow-left bigger ${expanded ? 'open' : 'ß'}`}/>
      </button>
      {expanded && renderExpanded()}
    </div>
  );
};

export default File;
