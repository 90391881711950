import React, { useRef, useEffect, useMemo } from 'react';

type MapTypes = {
  feature: {
    geometry: object;
  }
}

declare const L: { mapbox: {
  featureLayer: (layer: object) => any;
  map: any;
} }

const Map = ({
  feature,
}: MapTypes) => {
  
  const mapContainerRef: React.RefObject<null> = useRef();
  const mapRef: any = useRef();

  useEffect(() => {
    if(!feature) { return }

    mapRef.current = L.mapbox.map(mapContainerRef.current, 'mapbox.streets', {
      zoom: 15,
      attributionControl: { compact: false },
    });

    const marker = L.mapbox.featureLayer({
      type: 'Feature',
      geometry: feature.geometry,
    }).addTo(mapRef.current);
    
    mapRef.current.invalidateSize();
  
    mapRef.current.fitBounds(marker.getBounds());
  }, [])

  if (!feature) {
    return <span>Feature data not found</span>;
  }
  
  return (
    <div className={`map-wrap`}>
      <div className='map' ref={mapContainerRef} />
    </div>
  );
}

export default Map;
