import React, {
  lazy, Suspense, useState, useEffect, useReducer,
} from 'react';
import PropTypes from 'prop-types';
import { Route, HashRouter } from 'react-router-dom';
import { Switch } from 'react-router';
import TemplateManagerStore from 'stores/template-manager-store';
import { CSSTransition } from 'react-transition-group';
import ErrorBoundary from 'components/shared/error-boundary';
import _ from 'underscore';
import LoadingSpinner from 'components/shared/loading-spinner';
import NotifBanner from 'components/shared/notif-banner';
import TemplateManagerActions from 'actions/template-manager-actions';
import accessManagerReducer from 'reducers/access-manager-reducer';
import AccessManagerDispatchContext from 'contexts/access-manager-dispatch-context';
import FlowTemplateHeader from './edit_flow/flow-template-header';
import Stats from './flow_data/stats';
import UnpublishedChangesBanner from './template_manager/unpublished-changes-banner';
import PrimaryTabs from './shared/primary-tabs';

const FlowStepManager = lazy(() => { return import('./edit_flow/flow-step-manager'); });
const Outputs = lazy(() => { return import('./edit_flow/outputs'); });
const Configuration = lazy(() => { return import('./edit_flow/configuration'); });
const Modules = lazy(() => { return import('./edit_flow/modules'); });
const Actions = lazy(() => { return import('./edit_flow/actions'); });

const SUSPENSE_HTML = (
  <div className='workflowconfig-progresswrap'>
    <LoadingSpinner size='large' />
  </div>
);

const TemplateManager = (props) => {
  const initialFlowTemplate = TemplateManagerStore.getFlowTemplate();
  const initialMasterTemplate = TemplateManagerStore.getMasterTemplate();
  const [flowTemplate, setFlowTemplate] = useState(initialFlowTemplate);
  const [masterTemplate, setMasterTemplate] = useState(initialMasterTemplate);
  const [showBanner, setShowBanner] = useState(shouldShowBanner(initialFlowTemplate, initialMasterTemplate));

  useEffect(() => {
    TemplateManagerActions.loadModificationStatus(flowTemplate.master_template_id);
    TemplateManagerStore.on('change', onTemplateChanged);

    if (props.location.pathname === '/') {
      // redirect if not on any child route
      redirectToDefaultPath();
    }
  }, [flowTemplate.master_template_id]);

  const [accessManagerState, accessManagerDispatch] = useReducer(
    accessManagerReducer,
    {
      memberUsers: props.userAccessData.masterTemplate.memberships,
      authorityUsers: props.userAccessData.masterTemplate.authorities,
    },
  );

  function permissionType() {
    const membership = _.findWhere(accessManagerState.memberUsers, { user_id: CityGrows.Server.currentUserId });
    if (!membership) { return; }

    if (membership.permission_level_id === 2) {
      return 'write';
    }

    return 'read';
  }

  function isReadOnly() {
    return permissionType() === 'read';
  }

  function isArchived() {
    return masterTemplate.status === 'archived';
  }

  function redirectToDefaultPath() {
    // default to stats path if not first unpublished version

    const path = flowTemplate.version === 1 ? '/edit' : '/stats';
    props.history.replace(path);
  }

  function onTemplateChanged() {
    const newFlowTemplate = TemplateManagerStore.getFlowTemplate();
    const newMasterTemplate = TemplateManagerStore.getMasterTemplate();
    setFlowTemplate(newFlowTemplate);
    setMasterTemplate(newMasterTemplate);
    setShowBanner(shouldShowBanner(newFlowTemplate, newMasterTemplate));
  }

  function onPublish() {
    setShowBanner(false);
    props.history.push('/stats');
  }

  function shouldShowBanner(ft, mt) {
    return ft.is_modified
            && ft.version > 1
            && mt.status !== 'archived';
  }

  function renderFlowStepManager(routerProps) {
    if (isReadOnly()) {
      props.history.replace('/stats');
      return;
    }

    return (
      <Suspense fallback={SUSPENSE_HTML}>
        <FlowStepManager
          disabled={permissionType() !== 'write'}
          allowedStepDatumTypes={props.allowedStepDatumTypes}
          {...routerProps}
        />
      </Suspense>
    );
  }

  function renderConfiguration(routerProps) {
    if (isReadOnly()) {
      props.history.replace('/stats');
      return;
    }

    return (
      <Suspense fallback={SUSPENSE_HTML}>
        <Configuration
          masterTemplate={masterTemplate}
          permissionType={permissionType()}
          flowTemplate={flowTemplate}
          accessManagerState={accessManagerState}
          permissionLevels={props.userAccessData.permissionLevels}
          {...routerProps}
        />
      </Suspense>
    );
  }

  function renderOutputs() {
    return (
      <Suspense fallback={SUSPENSE_HTML}>
        <Outputs
          flowTemplate={flowTemplate}
          masterTemplate={masterTemplate}
        />
      </Suspense>
    );
  }

  function renderModules(routerProps) {
    if (isReadOnly()) {
      props.history.replace('/stats');
      return;
    }

    return (
      <Suspense fallback={SUSPENSE_HTML}>
        <Modules flowTemplate={flowTemplate} masterTemplate={masterTemplate} {...routerProps} />
      </Suspense>
    );
  }

  function renderActions(routerProps) {
    if (isReadOnly() && routerProps.location.pathname !== '/actions/export') {
      props.history.replace('/stats');
      return;
    }

    return (
      <Suspense fallback={SUSPENSE_HTML}>
        <Actions
          flowTemplate={flowTemplate}
          masterTemplate={masterTemplate}
          myTeams={props.myTeams}
          {...routerProps}
        />
      </Suspense>
    );
  }

  const hasNotifs = () => {
    return !!renderNotification();
  };

  function renderNotification() {
    let banner = null;
    if (isArchived()) {
      banner = renderArchivedNotif();
    } else if (isReadOnly() && flowTemplate.version === 1) {
      banner = renderReadOnlyNotif();
    } else if (paymentsDisabled() && masterTemplate.ever_published) {
      banner = renderDisabledPaymentNotif();
    }

    if (!banner) { return; }

    return banner;
  }

  function renderDisabledPaymentNotif() {
    return (
      <CSSTransition
        classNames='fullwidthnotif'
        timeout={330}
        in={paymentsDisabled() && masterTemplate.ever_published}
        mountOnEnter
        unmountOnExit
      >
        <NotifBanner style='warning'>
          <i className='icon-exclaim margin-right-less' />
          <span>This form's payment step(s) cannot accept charges. To enable, add a payout account </span>
          <a href={`/teams/${flowTemplate.team.friendly_id}/merchant_accounts`}>here</a>
        </NotifBanner>
      </CSSTransition>
    );
  }

  function renderReadOnlyNotif() {
    return (
      <CSSTransition
        classNames='fullwidthnotif'
        timeout={330}
        in={isReadOnly() && flowTemplate.version === 1}
        mountOnEnter
        unmountOnExit
      >
        <NotifBanner style='warning'>
          <i className='icon-exclaim margin-right-less' />
          This form is read only. To edit, a team member will need to modify your permissions.
        </NotifBanner>
      </CSSTransition>
    );
  }

  function renderArchivedNotif() {
    return (
      <CSSTransition
        classNames='fullwidthnotif'
        timeout={330}
        in={isArchived()}
        mountOnEnter
        unmountOnExit
      >
        <NotifBanner style='warning'>
          <i className='icon-exclaim margin-right-less' />
          This form has been archived.
        </NotifBanner>
      </CSSTransition>
    );
  }

  function paymentsDisabled() {
    return flowTemplate.has_payment_step && !flowTemplate.team.charges_enabled;
  }

  function renderTabContent() {
    if (_.isUndefined(flowTemplate.is_modified)) {
      return SUSPENSE_HTML;
    }

    return (
      <Switch>
        <Route path='/edit' render={renderFlowStepManager} />
        <Route path='/stats' component={Stats} />
        <Route path='/configuration' render={renderConfiguration} />
        <Route path='/outputs' render={renderOutputs} />
        <Route path='/powerups' render={renderModules} />
        <Route path='/actions' render={renderActions} />
      </Switch>
    );
  }

  return (
    <AccessManagerDispatchContext.Provider value={accessManagerDispatch}>
      {renderNotification()}

      <UnpublishedChangesBanner
        flowTemplate={flowTemplate}
        disabled={permissionType() !== 'write'}
        onPublish={onPublish}
        show={showBanner}
      />

      <FlowTemplateHeader
        permissionType={permissionType()}
        flowTemplate={flowTemplate}
        masterTemplate={masterTemplate}
      />

      {renderTabContent()}
      <div id='sidemenusetting' />
    </AccessManagerDispatchContext.Provider>
  );
};

TemplateManager.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  allowedStepDatumTypes: PropTypes.array,
  myTeams: PropTypes.array,
};

const TemplateManagerRouter = (props) => {
  const renderer = (routerProps) => {
    return (
      <ErrorBoundary>
        <div className='margins flexcolumn'>
          <PrimaryTabs />
          <div id='tabheader-body' className='workfloweditor'>
            <TemplateManager
              {...routerProps}
              {...props}
            />
          </div>
        </div>
      </ErrorBoundary>
    );
  };

  return (
    <HashRouter>
      <Route path='/' render={renderer} />
    </HashRouter>
  );
};

export default TemplateManagerRouter;
