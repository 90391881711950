import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import DiscussionDispatchContext from 'contexts/discussion-dispatch-context';
import Textarea from 'react-textarea-autosize';

import DiscussionActions from 'actions/discussion-actions';
import { error } from 'components/shared/flash';
import DiscussionItem, { DISCUSSION_SELECT_STYLES } from 'components/shared/discussions/item';
import Select from 'components/shared/select';
import useKeyDown from 'components/hooks/use-keydown';

const DISCUSSION_OPTIONS = [
  { value: 'PublicDiscussion', label: 'Discussion', description: 'Discussion can be seen by applicant and by admins. Applicant and admins are notified via email.' },
  { value: 'InternalDiscussion', label: 'Internal discussion', description: 'Only submission admins will see this discussion' },
];
const DEFAULT_DISCUSSION_OPTION = DISCUSSION_OPTIONS[0];

const AddNewDiscussion = ({ admin, onDiscussionOpened, initiatedFlowId }) => {
  const dispatch = useContext(DiscussionDispatchContext);
  const [discussionContent, setDiscussionContent] = useState();
  const [discussionOption, setDiscussionOption] = useState(DEFAULT_DISCUSSION_OPTION);
  const onDiscussionContentUpdated = (e) => {
    setDiscussionContent(e.target.value);
  };

  const onStartDiscussion = () => {
    if (!discussionContent || discussionContent.length < 1) {
      error('Type a message in order to start a discussion');
      return;
    }

    DiscussionActions.startDiscussion(dispatch, initiatedFlowId, {
      body: discussionContent,
      type: discussionOption.value,
    })
    .done(onDiscussionOpened);
  };

  const onKeyDown = (e) => {
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      // CMD-Enter
      onStartDiscussion();
    }
  };

  const keydownTargetRef = useKeyDown(onKeyDown);

  return (
    <div className='discussion-new-dialog'>
      {admin && (
        <>
          <div className='discussion-new-meta margin-top cf'>
            <Select
              options={DISCUSSION_OPTIONS}
              value={discussionOption}
              onChange={setDiscussionOption}
              isSearchable={false}
              menuPosition='fixed'
              styles={DISCUSSION_SELECT_STYLES}
            />
          </div>

          <div className='discussion-typedescription'>{discussionOption.description}</div>
        </>
      )}

      <Textarea
        ref={keydownTargetRef}
        onChange={onDiscussionContentUpdated}
        value={discussionContent}
        className='margin-bottom margin-top'
        placeholder='Start typing...'
        title='New discussion'
      />

      <button type='button' onClick={onStartDiscussion} className='btn-primary fullwidth'>Start discussion</button>
    </div>
  );
};

AddNewDiscussion.propTypes = {
  initiatedFlowId: PropTypes.number.isRequired,
  onDiscussionOpened: PropTypes.func,
  admin: PropTypes.bool.isRequired,
};

const ShowDiscussions = ({
  admin, recentlyAddedDiscussionId, onNewDiscussionClick, discussions,
}) => {
  const [activeDiscussionId, setActiveDiscussionId] = useState();

  return (
    <div>
      <ul className='discussion-items'>
        {discussions.map((discussion) => (
          <DiscussionItem
            active={activeDiscussionId === discussion.id}
            key={discussion.id}
            discussion={discussion}
            onMakeActive={() => setActiveDiscussionId(discussion.id)}
            onMakeInactive={() => setActiveDiscussionId(null)}
            discussionOptions={DISCUSSION_OPTIONS}
            userIsAdmin={admin}
            flash={recentlyAddedDiscussionId === discussion.id}
          />
        ))}
      </ul>

      <div className='discussion-new'>
        <button type='button' onClick={onNewDiscussionClick} className='btn-secondary fullwidth'>New discussion</button>
      </div>
    </div>
  );
};

ShowDiscussions.propTypes = {
  onNewDiscussionClick: PropTypes.func.isRequired,
  recentlyAddedDiscussionId: PropTypes.number,
  admin: PropTypes.bool.isRequired,
};

const DiscussionContent = ({
  onNewDiscussionClick,
  admin,
  initiatedFlowId,
  recentlyAddedDiscussionId,
  onDiscussionOpened,
  addingNewDiscussion,
  discussions,
}) => {
  return addingNewDiscussion
    ? (
      <AddNewDiscussion
        admin={admin}
        initiatedFlowId={initiatedFlowId}
        onDiscussionOpened={onDiscussionOpened}
      />
    )
    : (
      <ShowDiscussions
        onNewDiscussionClick={onNewDiscussionClick}
        recentlyAddedDiscussionId={recentlyAddedDiscussionId}
        admin={admin}
        discussions={discussions}
      />
    );
};

DiscussionContent.propTypes = {
  addingNewDiscussion: PropTypes.bool.isRequired,
  initiatedFlowId: PropTypes.number.isRequired,
  onNewDiscussionClick: PropTypes.func.isRequired,
  onDiscussionOpened: PropTypes.func,
  recentlyAddedDiscussionId: PropTypes.number,
  admin: PropTypes.bool.isRequired,
};

DiscussionContent.defaultProps = {
  onDiscussionOpened: () => {},
};

export default DiscussionContent;
