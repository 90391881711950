import React from 'react';
import PropTypes from 'prop-types';
import useOnclickOutside from 'react-cool-onclickoutside';

const CloseIfOutsideWrapper = (props) => {
  const ref = useOnclickOutside(
    props.onClose,
    {
      ignoreClass: props.wrapperIgnoreClass,
    },
  );

  return (
    <div ref={ref} className='contents inherit-height'>
      {props.children}
    </div>
  );
};

CloseIfOutsideWrapper.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  wrapperIgnoreClass: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default CloseIfOutsideWrapper;
