import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import PropTypes from 'prop-types';
import Popdown from 'components/shared/popdown';
import CloseIfOutsideWrapper from 'components/shared/close-if-outside-wrapper';

const DropdownMenu = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuAnchorRef = useRef();

  const onMenuOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <div className='dropdownmenu'>
      <span onClick={onMenuOpen} ref={menuAnchorRef} className='dropdownmenu-button'>
        <FontAwesomeIcon
          icon={icon({ name: 'ellipsis' })}
          style={{ color: props.iconColor }}
        />
      </span>
      { isOpen && (
        <Popdown
          anchorRef={menuAnchorRef}
          justify={props.justify}
        >
          <CloseIfOutsideWrapper onClose={onMenuOpen}>
            <div className='contents inherit-height'>
              <div className='dropdownmenu-body' onClick={onMenuOpen}>
                {props.children}
              </div>
            </div>
          </CloseIfOutsideWrapper>
        </Popdown>
      )}
    </div>
  );
};

DropdownMenu.propTypes = {
  iconColor: PropTypes.string,
  justify: PropTypes.string,
};

DropdownMenu.defaultProps = {
  iconColor: '#0463B7',
  justify: 'right',
};

export default DropdownMenu;
