import UserAPI from '../apis/user-api';
import Flash from '../components/shared/flash';

const UserActions = {
  login(email, password) {
    return UserAPI.login(email, password)
            .fail((res) => {
              Flash.error(res.responseJSON.error);
            });
  },

  signup(email, password, name, accountType) {
    return UserAPI.signup(email, password, name, accountType)
            .fail((res) => {
              Flash.error(res.responseText);
            });
  },
};

export default UserActions;
