import NProgress from 'nprogress';
import CustomEntitiesAPI from 'apis/custom-entities-api';
import API from 'apis/custom-entity-fields-api';
import Constants from '../constants/entity-configuration-constants';
import { success, error } from '../components/shared/flash';
import { alert } from 'modules/alert-confirm';

const EntityConfigurationActions = {
  createEntity(teamId, isESRI, serviceURL, dispatch) {
    return CustomEntitiesAPI.create(teamId, isESRI, serviceURL)
                            .done((customEntity) => {
                              dispatch({
                                type: Constants.CUSTOM_ENTITY_CREATED,
                                newCustomEntity: customEntity,
                              });

                              success('Entity created');
                            })
                            .fail((res) => {
                              error(res.responseJSON.errors[0]);
                            });
  },

  updateEntity(customEntityId, attrs, dispatch) {
    return CustomEntitiesAPI.update(customEntityId, attrs)
              .done(() => {
                dispatch({
                  type: Constants.ENTITY_UPDATED,
                  entityId: customEntityId,
                  attrs,
                });
              })
              .fail((res) => { error(res.responseText); })
              .always(() => { NProgress.done(); });
  },

  addField(customEntityId, attrs, dispatch) {
    NProgress.start();

    return API.create(customEntityId, attrs)
              .done((res) => {
                dispatch({
                  type: Constants.CUSTOM_ADDED,
                  field: res,
                });
              })
              .fail((res) => { error(res.responseText); })
              .always(() => { NProgress.done(); });
  },

  removeField(fieldId, dispatch) {
    return API.delete(fieldId)
              .done(() => {
                dispatch({
                  type: Constants.CUSTOM_REMOVED,
                  fieldId,
                });
              })
              .fail(() => { error(); })
              .always(() => { NProgress.done(); });
  },

  updateField(fieldId, attrs, dispatch) {
    return API.update(fieldId, attrs)
              .done((res) => {
                dispatch({
                  type: Constants.CUSTOM_UPDATED,
                  field: res,
                });
              })
              .fail(() => { error(); })
              .always(() => { NProgress.done(); });
  },

  archive(customEntityId, dispatch) {
    NProgress.start();

    return CustomEntitiesAPI.destroy(customEntityId)
                            .done(() => {
                              dispatch({
                                type: Constants.CUSTOM_ENTITY_ARCHIVED,
                                customEntityId,
                              });

                              success('Entity archived');
                            })
                            .fail((res) => {
                              if (res.status === 422) {
                                alert("Entity in-use", res.responseJSON.errors[0]);
                              } else {
                                error();
                              }
                            })
                            .always(() => { NProgress.done(); });
  },
};

export default EntityConfigurationActions;
