import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { isEmpty, debounce } from 'underscore';
import truncate from 'truncate';

import DashboardActions from 'actions/dashboard-actions';
import CloseIfOutsideWrapper from 'components/shared/close-if-outside-wrapper';
import SearchBar from 'components/shared/search-bar';
import MasterSearchResults from './master-search-results';
import KEYS from '../../constants/keys';

const MasterSearch = ({ onChange }) => {
  const [masterSearchResults, setMasterSearchResults] = useState([]);
  const [masterSearchTerms, setMasterSearchTerms] = useState('');
  const [open, setOpen] = useState(false);
  const [chipPresent, setChipPresent] = useState(false);
  const [searchBarInFocus, setSearchBarInFocus] = useState(false);
  const [typeInFocus, setTypeInFocus] = useState(false);
  const [searchType, setSearchType] = useState('contains');

  const currentSearchRef = useRef();
  const masterSearchRef = useRef();

  const debouncedMasterSearchChanged = useMemo(
    () => debounce(
      (terms, type) => {
        setMasterSearchResults([]);
        setOpen(!isEmpty(terms));

        if (isEmpty(terms)) { return; }

        if (currentSearchRef.current) { currentSearchRef.current.abort(); }
        currentSearchRef.current = DashboardActions
          .runSearch(terms, type)
          .done((res) => setMasterSearchResults(res.items));
      },
      333,
    ),
    [],
  );

  const shouldShowSearchResults = open && !isEmpty(masterSearchTerms);

  const updateTerms = (e) => {
    const { value } = e.currentTarget;
    setMasterSearchTerms(value);
    debouncedMasterSearchChanged(value, searchType);
  };

  const onEnterPressed = () => {
    setChipPresent(true);
    setOpen(false);
    sendSearch(masterSearchTerms, searchType);
  };

  const sendSearch = (term, type) => {
    if (onChange) {
      onChange(term, type);
    }
  };

  const onInputKeyUp = (e) => {
    if (e.keyCode === KEYS.ENTER_KEY) {
      e.preventDefault();
      onEnterPressed();
    }
  };

  const onSearchTypeChange = ({ value }) => {
    setSearchType(value);
    if (chipPresent) {
      sendSearch(masterSearchTerms, value);
    } else {
      debouncedMasterSearchChanged(masterSearchTerms, value);
    }
  };

  const onDeleteClick = () => {
    onChange(null);
    setMasterSearchTerms('');
    setChipPresent(false);
  };

  const onChipClick = () => {
    setChipPresent(false);
  };

  const onSearchBarBlur = () => {
    if (isEmpty(masterSearchTerms)) {
      setSearchBarInFocus(false);
    }
  };

  const onOutsideClick = () => {
    if (isEmpty(masterSearchTerms)) {
      setSearchBarInFocus(false);
      setTypeInFocus(false);
    }
  };

  return (
    <CloseIfOutsideWrapper
      onClose={() => setOpen(false)}
      wrapperIgnoreClass='dashboard-mastersearchresult'
    >
      <div className='dashboard-filters-searchwrap'>
        <SearchBar
          searchTypeChanged={onSearchTypeChange}
          onFocus={() => setTypeInFocus(true)}
          searchType={searchType}
          search={onEnterPressed}
          isActive={searchBarInFocus || typeInFocus}
          onOutsideClick={onOutsideClick}
        >
          {chipPresent
            ? (
              <div className='dashboard-search-chipcontainer'>
                <Chip
                  clickable
                  label={truncate(masterSearchTerms, 15)}
                  onDelete={onDeleteClick}
                  onClick={onChipClick}
                />
              </div>
            )
            : (
              <>
                <input
                  onKeyUp={onInputKeyUp}
                  type='text'
                  ref={masterSearchRef}
                  onChange={updateTerms}
                  value={masterSearchTerms}
                  onFocus={() => setSearchBarInFocus(true)}
                  onBlur={onSearchBarBlur}
                  placeholder='Search'
                />
                {shouldShowSearchResults && (
                  <MasterSearchResults
                    searchTerms={masterSearchTerms}
                    anchorRef={masterSearchRef}
                    results={masterSearchResults}
                  />
                )}
              </>
            )}
        </SearchBar>
      </div>
    </CloseIfOutsideWrapper>
  );
};

MasterSearch.propTypes = {
  onChange: PropTypes.func,
};

export default MasterSearch;
