import InitiatedFlowAPI from '../apis/initiated-flow-api';

const resolveTags = function (htmlString, textType, textContainerId, initiatedFlow) {
  const parsedHTML = $.parseHTML(htmlString);
  const $html = $('<div>').append($(parsedHTML));
  const $formulaTags = $html.find('*[data-object-type=Formula]');
  const $fieldTags = $html.find('*[data-object-type=Field]');
  const $keywordTags = $html.find('*[data-object-type=Keyword]');
  if ($formulaTags.length == 0 && $fieldTags.length == 0 && $keywordTags.length == 0) {
    return Promise.resolve($html.html());
  }

  return InitiatedFlowAPI.parseVariableText(textType, textContainerId, initiatedFlow.id)
                         .then((res) => { return res.text; });
};

export default resolveTags;
