import PropTypes from 'prop-types';
import React, { Component } from 'react';

class MoneyInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.defaultValue || '',
    };
  }

  handleChange = (e) => {
    const { value } = e.currentTarget;
    if (isFinite(value)) {
      this.setState({ value: e.currentTarget.value });
      this.props.onChange(e, e.currentTarget.value);
    }
  };

  render() {
    return (
      <div className='moneyinput'>
        <input
          ref={this.props.innerRef}
          id={this.props.id}
          type='text'
          onBlur={this.props.onBlur}
          onChange={this.handleChange}
          value={this.state.value}
          disabled={this.props.disabled}
          aria-required={this.props.required}
        />
        <i />
      </div>
    );
  }
}

MoneyInput.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  id: PropTypes.string,
  required: PropTypes.bool,
};

export default MoneyInput;
