import React, { useState, useContext } from 'react';
import { contains } from 'underscore';
import PropTypes from 'prop-types';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import PersonSelector from 'components/shared/person-selector';
import UserAccessActions from 'actions/user-access-actions';
import AttachmentActions from 'actions/attachment-actions';
import RoleSearch from './role-search';

const AttachPerson = (props) => {
  const [person, setPerson] = useState();
  const [viaRole, setViaRole] = useState(false);
  const dispatch = useContext(InitiatedFlowDispatchContext);

  function onSendInviteButtonClicked(email, name, role) {
    props.togglePerson({
      email, name, isPseudo: true, role, type: 'User',
    });
  }

  function handlePersonSelect(selectedPerson) {
    if (props.canAttachRoles) {
      setPerson(selectedPerson);
    } else {
      toggleAttachment(selectedPerson);
    }
  }

  function toggleAttachment(prsn) {
    const attachment = { isPseudo: false, ...prsn, type: 'User' };

    props.togglePerson(attachment)
         .done(() => {
           setPerson();
           setViaRole(true);
         });
  }

  function onToggleOff(removedPerson) {
    if (props.tentativeAssignment) {
      return AttachmentActions.removeTentativePerson(removedPerson.attachment_id)
          .done((res) => {
            AttachmentActions.onTentativeDone(dispatch);
            onAttachedPeopleChange(res);
          });
    }

    return AttachmentActions.removePerson(removedPerson.attachment_id)
                                  .done(onAttachedPeopleChange);
  }

  function attachViaRole(role) {
    const attachment = { isPseudo: false, ...person, type: 'User' };

    attachment.role = role;
    props.togglePersonViaRole(attachment);
  }

  function doSearch(terms) {
    return UserAccessActions.searchUsers(terms, props.searchOpts);
  }

  function toggleViaRoleOff() {
    setViaRole(false);
  }

  function onAttachedPeopleChange(data) {
    // onChange prop is optional
    if (props.onChange) {
      props.onChange(data);
    }

    setPerson();
    setViaRole(true);
  }

  function personAttachedViaRole() {
    return props.attachedRoles.find((r) => { return contains(r.user_ids, person.id); });
  }

  function reset(e) {
    e.preventDefault();
    setPerson();
    setViaRole(true);
  }

  function renderConfirmButton() {
    return (
      <button
        className='margin-right btn-primary'
        type='button'
        onClick={() => { toggleAttachment(person); }}
      >
        Attach
      </button>
    );
  }

  function renderPersonStepTwo() {
    if (personAttachedViaRole()) {
      return (
        <div className='margin-bottom'>
          <span>
            This user is already attached through the {personAttachedViaRole().name} role
          </span>
          <a className='margin-left' href='#' onClick={reset}>Go Back</a>
        </div>
      );
    }

    return (
      <div className='well margin-bottom'>
        <div className='padding-bottom'>
          Adding <strong>{person.name}</strong>
        </div>
        {/* <form className='margin-bottom'>
          <div className='radiotoggle'>
            <label>
              <input type='radio' checked={!viaRole} onChange={toggleViaRoleOff} />
              <span>Assign this person directly</span>
            </label>
            <label>
              <input type='radio' checked={viaRole} onChange={() => { setViaRole(true); }} />
              <span>Assign them through a role</span>
            </label>
          </div>
        </form>
        {viaRole && (
          <RoleSearch
            teamId={props.teamId}
            teamSlug={props.teamSlug}
            referrer={props.referrer}
            toggleRole={attachViaRole}
            userId={person.id}
            rolesToFilter={[]}
          />
        )} */}
        <div className='margin-top'>
          {renderConfirmButton()}
          <button className='btn-link' onClick={reset}>Cancel</button>
        </div>
      </div>
    );
  }

  function renderSearchManager() {
    return (
      <div className='attachedpeople-inputcontainer'>
        <PersonSelector
          doSearch={doSearch}
          onSelect={handlePersonSelect}
          onInvite={onSendInviteButtonClicked}
          allowPseudo={props.allowPseudo}
          allowToggle={props.allowToggle}
          teamId={props.teamId}
          onToggleOff={onToggleOff}
          memberUsers={props.people}
          attachableType={props.attachableType}
          hideExternal={props.hideExternal}
          idForInput={props.idForInput}
        />
      </div>
    );
  }

  if (person && props.canAttachRoles) {
    return renderPersonStepTwo();
  }

  return renderSearchManager();
};

AttachPerson.propTypes = {
  canAttachRoles: PropTypes.bool.isRequired,
  searchOpts: PropTypes.shape({}),
};

AttachPerson.defaultProps = {
  idForInput: '',
  searchOpts: {},
};

export default AttachPerson;
