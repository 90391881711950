const CustomEntityFieldsAPI = {
  create(customEntityId, attrs){
    const url = `/custom_entities/${customEntityId}/custom_entity_fields`;
    return $.ajax(url, {
      method: 'POST',
      dataType: 'json',
      data: {
        custom_entity_field: attrs
      }
    });
  },

  delete(fieldId){
    const url = `/custom_entity_fields/${fieldId}`;
    return $.ajax(url, {
      method: 'DELETE',
      dataType: 'json',
    });
  },

  update(fieldId, attrs){
    const url = `/custom_entity_fields/${fieldId}`;
    return $.ajax(url, {
      method: 'PUT',
      dataType: 'json',
      data: {
        custom_entity_field: attrs
      }
    });
  }


};

export default CustomEntityFieldsAPI;
