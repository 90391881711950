import DiscussionConstants from 'constants/discussion-constants';
import { findIndex, reject } from 'underscore';

function discussionReducer(state, action) {
  let index = null;

  switch (action.type) {
  case DiscussionConstants.LOADED:
    return { discussions: action.discussions };
  case DiscussionConstants.CREATED:
    return { discussions: [...state.discussions, action.discussion] };
  case DiscussionConstants.UPDATED:
    index = findIndex(state.discussions, { id: action.discussion.id });
    return {
      discussions: [
        ...state.discussions.slice(0, index),
        { ...state.discussions[index], ...action.discussion },
        ...state.discussions.slice(index + 1),
      ],
    };
  case DiscussionConstants.DESTROYED:
    const discussionsDupe = [...state.discussions];
    return { discussions: reject(discussionsDupe, { id: action.discussion.id }) }
  case DiscussionConstants.COMMENT_ADDED:
    index = findIndex(state.discussions, { id: action.discussionComment.discussion_id });
    return {
      discussions: [
        ...state.discussions.slice(0, index),
        { ...state.discussions[index], discussion_comments: [ ...state.discussions[index].discussion_comments, action.discussionComment ] },
        ...state.discussions.slice(index + 1)
      ],
    };
  case DiscussionConstants.COMMENT_DELETED:
    index = findIndex(state.discussions, (d) => {
      return findIndex(d.discussion_comments, { id: action.discussionCommentId }) !== -1;
    });
    const discussionCommentsDupe = [...state.discussions[index].discussion_comments];

    return {
      discussions: [
        ...state.discussions.slice(0, index),
        {
          ...state.discussions[index],
          discussion_comments: reject(discussionCommentsDupe, { id: action.DiscussionCommentId }),
        },
        ...state.discussions.slice(index + 1),
      ],
    };
  default:
    throw new Error();
  }
}

export default discussionReducer;
