const RefundAPI = {
  cancelRequest: (chargeId) => {
    const url = `/refunds/${chargeId}/cancel_request`;
    return $.ajax(url, {
      dataType: 'json',
      method: 'post'
    });
  },

  approveRequest: (chargeId) => {
    const url = `/refunds/${chargeId}/approve_request`;
    return $.ajax(url, {
      dataType: 'json',
      method: 'post'
    });
  }
};

export default RefundAPI;