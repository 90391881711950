import React from 'react';
import Modal from 'components/shared/modal';
import { publicHost } from 'modules/url-generator';

class Support extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      questionViewOpen: false,
      technicalIssueViewOpen: false,
    };
  }

  showQuestionView = (e) => {
    e.preventDefault();
    this.setState({ questionViewOpen: true });
  };

  closeSupportWindow() {
    this.props.onOutsideClick();
  }

  onOpenDiscussionClick = (e) => {
    e.preventDefault();

    /* TODO use router to open discussion instead of callback.
      ** adding discussion component to router will require react-css-transition upgrade
      in order to add css transitions to router components
    */
    this.props.onOpenDiscussionClick(e);
    this.closeSupportWindow();
  };

  onSupportRequested = (e) => {
    e.preventDefault();
    this.setState({ technicalIssueViewOpen: true });
  };

  renderQuestionView() {
    return (
      <section className='align-center'>
        <h1>Has your question already been answered?</h1>

        <div>
          <a id='checkthefaqbutton' className='btn-primary' target='_blank' href={`${publicHost()}/${this.props.teamSlug}/${this.props.masterTemplateSlug}/faqs`} rel='noreferrer'>
            Check the FAQs
            <i className='icon-external-white' />
          </a>
        </div>

        <div className='color-text-medium'>Can&apos;t find what you&apos;re looking for?</div>
        <a href='#' className='support-discussionlink' onClick={this.onOpenDiscussionClick}>Try opening a discussion</a>
      </section>
    );
  }

  renderTechnicalIssueView() {
    return (
      <section className='support-technicalissueview align-center'>
        <h1>Have a technical question about the ClearForms website?</h1>

        <div>
          <span>Contact <a href='mailto:support@cleargov.com'>support@cleargov.com</a> and our Support team will be happy to assist you.</span>
        </div>
      </section>
    );
  }

  renderInitialView() {
    return (
      <section className='align-center'>
        <h1>What do you need help with?</h1>

        <ul className='support-menu' style={{ display: 'inline-block' }}>
          <li onClick={this.showQuestionView} id='processquestionbutton'>
            <i className='icon-building-primary' />
            <span>I have a question about this form</span>
          </li>

          <li onClick={this.onSupportRequested}>
            <i className='icon-wrench-primary' />
            <span>The website isn&apos;t working properly</span>
          </li>
        </ul>
      </section>
    );
  }

  renderView() {
    if (this.state.questionViewOpen) {
      return this.renderQuestionView();
    }
    if (this.state.technicalIssueViewOpen) {
      return this.renderTechnicalIssueView();
    }
    return this.renderInitialView();
  }

  render() {
    return (
      <Modal className='support' onOutsideClick={this.props.onOutsideClick}>
        { this.renderView() }
      </Modal>
    );
  }
}

export default Support;
