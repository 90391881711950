import NProgress from 'nprogress';
import { any } from 'underscore';
import SurveyInvitationsAPI from '../../apis/survey-invitations-api';
import { error, success } from '../../components/shared/flash';
import SurveySubmissionConstants from '../../constants/survey-submission-constants';

function onInvitesSent(dispatch, invites) {
  dispatch({
    type: SurveySubmissionConstants.INVITES_ADDED,
    invites,
  });

  success('Invitations sent');
}

function onInviteFailed(res) {
  const response = res.responseJSON;
  const errorMessage = (response && any(response.errors)) ? response.errors[0] : '';
  error(errorMessage);
}

function onInviteComplete() {
  NProgress.done();
}

const SurveyActions = {
  inviteByList(dispatch, surveyToken, listOfEmails, message) {
    NProgress.start();

    return SurveyInvitationsAPI.doSend(surveyToken, listOfEmails, message)
                               .done((res) => { onInvitesSent(dispatch, res); })
                               .fail(onInviteFailed)
                               .always(onInviteComplete);
  },

  inviteByCSV(dispatch, surveyToken, file, message) {
    return SurveyInvitationsAPI.doSend(surveyToken, file, message)
                               .done((res) => { onInvitesSent(dispatch, res); })
                               .fail(onInviteFailed)
                               .always(onInviteComplete);
  },
};

export default SurveyActions;
