import React from 'react';
import AttachedPeople from 'components/shared/attached-people';
import { some } from 'underscore';
import { TR, TD } from 'components/shared/manageable-table';

const RoleRow = (props) => {
  function allowedToEdit() {
    switch (props.role.member_mgmt_level) {
    case 'anyone':
      return true;
    case 'members':
      return props.userIsOwner || userIsAMember();
    case 'owners':
      return props.userIsOwner;
    default:
      throw new Error(`unhandled managment level: ${props.role.member_mgmt_level}`);
    }
  }

  function userIsAMember() {
    return some(props.role.users, (user) => { return user.id === CityGrows.Server.currentUserId; });
  }

  function renderRoleRow() {
    return (
      <TR expandedContent={renderExpandedSection()}>
        <TD className='name' aria-labelledby='role-name'>{props.role.name}</TD>
        <TD className='' aria-labelledby='role-members'>{props.role.users.length} Members</TD>
        <TD className='permissions' aria-labelledby='role-permissions'>
          {renderPermissions()}
        </TD>
      </TR>
    );
  }

  function renderEditLink() {
    if (['owner', 'member'].includes(props.role.role_type) || !allowedToEdit()) {
      return <span className='float-right'>Uneditable</span>;
    }

    return (
      <button className='float-right btn-link strong' type='button' onClick={() => { props.onEdit(); }}>
        <span className='icon icon-pencil-grey' style={{ position: 'relative', top: '-4px', marginRight: '5px' }} />
        Edit Role Details
      </button>
    );
  }

  function renderExpandedSection() {
    return (
      <>
        {renderEditLink()}
        {renderUsers()}
      </>
    );
  }

  function renderUsers() {
    let lockedUserIds = [];
    const additionalText = '';
    if (props.role.role_type === 'owner') {
      // additionalText = '(must have at least one member)';
      // lockedUserIds = props.role.users.length === 1 ? [props.role.users[0].id] : [];
      lockedUserIds = [props.role.team_creator_id];
    }

    const labelId = `membersinput-${props.role.id}`;

    return (
      <div>
        <label htmlFor={labelId}>Role Members {additionalText}</label>
        <AttachedPeople
          people={props.role.users}
          attachableId={props.role.id}
          attachableType='AdminRole'
          allowPseudo
          canAttachRoles={false}
          hideExternal
          lockedUserIds={lockedUserIds}
          disabled={!allowedToEdit()}
          pseudoNeedsRole={false}
          onChange={(res) => { props.onAttachedPeopleChange(res, props.role.id); }}
          idForInput={labelId}
        />
      </div>
    );
  }

  function renderPermissions() {
    return (
      <ul>
        {props.role.finance_manager && <li>Manage team finances and banking details</li>}
        {!props.role.finance_manager && props.role.finance_member && <li>View Transactions and Manage Charge Disputes</li>}
        {props.role.workflow_creator && <li>Create Forms</li>}
        {props.role.team_manager && <li>Manage Team Settings</li>}
        {props.role.role_type === 'owner' && <li>Edit Roles</li>}
      </ul>
    );
  }

  return (
    renderRoleRow(props.role)
  );
};

export default RoleRow;
