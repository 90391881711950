import React from 'react';
import renderer from 'modules/renderer';

import { confirm } from '../modules/alert-confirm';
import CommonProviders from '../components/common-providers';
import DateFilters from '../components/shared/date-filters';
import FinanceSubnav from '../components/shared/finance-subnav';
import PrimaryTabs from '../components/shared/primary-tabs';

const index = () => {
  renderer(<PrimaryTabs />, document.getElementById('tabs'));
  
  let confirmed = false;

  $('.refundlink').click((e) => {
    if (!confirmed) {
      e.preventDefault();
      e.stopPropagation();

      confirm('Are you sure?', 'Are you sure you want to refund this payment?', () => {
        confirmed = true;
        $(e.currentTarget).click();
      });
    }
  });

  renderer(
    (
      <FinanceSubnav teamSlug={CityGrows.Server.teamId} activeLink={CityGrows.Server.type}/>
    ), document.getElementById('finance-left-nav')
  );

  renderer(
    <CommonProviders><DateFilters teamId={CityGrows.Server.teamId} type={CityGrows.Server.type}/></CommonProviders>, document.getElementById('dateselects')
  );
};

export default {
  index,
  cashAndChecks: index,
};
