var FlowStepAPI = {
  add: function(flowTemplateId, type){
    const url = `/flow_templates/${flowTemplateId}/steps`;
    const request = $.ajax(url, {
      data: {
        step: { type }
      },
      method: 'POST',
      dataType: 'json'
    });

    return request;
  },

  update: function(attrs){
    var url = '/steps/' + attrs.id;
    delete attrs.id;
    var request = $.ajax(url, {
      data: {
        step: attrs
      },
      method: 'PUT',
      dataType: 'json'
    });

    return request;
  },

  reorder: function(stepId, position) {
    const url = `/steps/${stepId}/reorder`;
    return $.ajax(url, {
      method: 'PUT',
      dataType: 'json',
      data: {
        step: {
          position
        }
      }
    });
  },

  delete: function(stepId){
    var url = '/steps/' + stepId;
    var request = $.ajax(url, {
      dataType: 'json',
      method: 'DELETE'
    });

    return request;
  },

  duplicate: function(stepId){
    const URL = `/steps/${stepId}/duplicate`;

    return $.ajax(URL, {
      method: 'POST',
      dataType: 'json'
    });
  },

  index: function(flowTemplateId){
    var url = '/flow_templates/' + flowTemplateId + '/steps';
    var request = $.ajax(url, {
      method: 'GET'
    });

    return request;
  }
};

export default FlowStepAPI;
