import React, { useEffect, useState } from 'react';

const DelayedRedirect = (props) => {
  const [count, setCount] = useState(props.waitFor);
  useEffect(() => {
    const interval = setInterval(() => {
      // update the state after 1000ms
      setCount((currentCount) => currentCount - 1);
    }, 1000);
    // when count is 0, navigate
    if (count === 1) {
      window.location = '/';
    }
    // clean up the interval
    return () => clearInterval(interval);
  }, [count]);
  return (
    <div>
      <p>Redirecting you in {count} sec </p>
    </div>
  );
};

export default DelayedRedirect;
