import React, { useState, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { filter, isEmpty, isNull, sortBy, find } from 'underscore';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ReactDOM from 'react-dom';
import TemplateList from './new_form/template-list';
import NewFormContainer from './new_form/new-form-container';
import NotifBanner from 'components/shared/notif-banner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon as i } from '@fortawesome/fontawesome-svg-core/import.macro';

const NewForm = (props) => {
  const noId = -1;
  const templates = [
    {
      id: noId,
      name: 'Blank Form',
      icon: 'fa-file',
      description: 'Start a blank form',
      stepNames: [],
    },
    ...sortBy(CityGrows.Server.templates, (template) => template.name).map(({
      name,
      icon,
      masterTemplateId,
      templateCategoryId,
      description,
      stepNames,
    }) => ({
      id: masterTemplateId,
      name,
      icon,
      categoryId: templateCategoryId,
      description,
      stepNames,
    })),
  ];

  const templateCategories = [
    { id: noId, name: 'All Templates' },
    ...sortBy(CityGrows.Server.templateCategories, (category) => category.name),
  ];

  const [template, setTemplate] = useState(templates[0]);
  const [categoryId, setCategoryId] = useState(noId);
  const [filterTerm, setFilterTerm] = useState('');
  const [showContent, setShowContent] = useState(false);
  const [isLaunching, setIsLaunching] = useState(false);

  const transitionRef = useRef(null);

  const launchUrl = useMemo(() => {
    if (isNull(template)) {
      return null;
    }

    if (template.id === noId) {
      return `/flow_templates?team_id=${props.teamId}`;
    }

    return `/master_templates/${template.id}/imports?team_id=${props.teamId}`;
  }, [props.teamId, template]);

  const filteredTemplates = useMemo(() => {
    if (categoryId === noId && isEmpty(filterTerm)) {
      return templates;
    }

    const results = filter(templates, (tmpl) => {
      const matchCategory = categoryId === noId
        ? true
        : tmpl.categoryId === categoryId;
      let matchName = true;
      if (!isEmpty(filterTerm)) {
        matchName = tmpl.name.toLowerCase().includes(filterTerm.toLowerCase());
      }
      return matchCategory && matchName;
    });

    if (!find(results, (tmpl) => tmpl.id === template?.id)) {
      setTemplate(null);
    }

    return results;
  }, [categoryId, filterTerm]);

  useEffect(() => {
    // to run transition
    setTimeout(() => setShowContent(true));
  }, []);

  const onCategoryIdChange = (id) => {
    setCategoryId(id);
  };

  const onTemplateChange = (tmpl) => {
    setTemplate(tmpl);
  };

  const onShowTemplateModal = (tmpl) => {
    onTemplateChange(tmpl);
  };

  const closeMainContainer = () => {
    setShowContent(false);
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(document.getElementById('newformcontainer'));
    }, 1500);
  };

  const renderTemplateInformation = () => {
    const bigger = isEmpty(template.description);
    let icon;
    if (template.icon === 'fa-file') {
      icon = <FontAwesomeIcon icon={i({ name: 'file' })} size='4x' style={{ color: '#0463B7' }} />;
    } else {
      icon = (
        <div className={`iconcontainer ${bigger ? 'bigger' : ''}`}>
          <img src={template.icon} width={bigger ? '100' : '60'} height={bigger ? '100' : '60'} alt='' />
        </div>
      );
    }
    return (
      <div className='informationcontainer'>
        <div style={{ display: 'flex' }}>
          {icon}
          <div className='margin-left'>
            <div>
              <strong>{template.name}</strong>
              {!isEmpty(template.description) && (
                <p>{template.description}</p>
              )}
            </div>
            {!isEmpty(template.stepNames) && (
              <div>
                <strong>Form Steps</strong>
                <p>
                  {template.stepNames.map((step, it) => {
                    return `${it + 1}. ${step} ${it < template.stepNames.length - 1 ? ' -> ' : ''}`;
                  })}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className='informationcontainer-actions'>
          <button type='button' onClick={closeMainContainer} className='btn-secondary'>Cancel</button>
          {renderLaunchButton()}
        </div>
      </div>
    );
  };

  const renderMenuBar = () => {
    return (
      <div className='menutab'>
        <ul className='categorylist'>
          {templateCategories.map((category) => {
            return (
              <li className={`clickable ${category.id === categoryId ? 'active' : ''}`} onClick={() => { onCategoryIdChange(category.id); }}>
                <span>{category.name}</span>
              </li>
            );
          })}
        </ul>
        <NotifBanner className='advanced' style='notice'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'inline-flex' }}>
              <div style={{ marginRight: '1rem', display: 'flex', alignItems: 'top' }}>
                <FontAwesomeIcon icon={i({ name: 'square-info' })} />
              </div>
              <div>
                <strong>Save time and simplify your experience with ClearForms using our pre-built form templates</strong>
                <p>Designed to help kickstart your form building process, these templates provide foundations for common local government processes.</p>
                <p>Simply select a template, and you&apos;ll have a fully functional form at your fingertips. Customize and edit to fit your specific needs, making it your own in a just a few clicks.</p>
              </div>
            </div>
          </div>
        </NotifBanner>
      </div>
    );
  };

  const renderLaunchButton = () => {
    if (isLaunching) {
      return (
        <button
          className='btn-primary disabled margin-left'
          type='button'
          disabled
        >
          Launching...
        </button>
      );
    }
    return (
      <a
        className='btn-primary'
        href={launchUrl}
        data-method='post'
        onClick={() => setIsLaunching(true)}
      >
        Create New Form
      </a>
    );
  };

  return (
    <div className='newform'>
      <TransitionGroup>
        { showContent && (
          <CSSTransition
            classNames='anim'
            timeout={250}
            nodeRef={transitionRef}
          >
            <NewFormContainer
              title='Create New Form'
              onClose={closeMainContainer}
              fullSize
              transitionRef={transitionRef}
            >
              <div className='newform-header'>
                {renderMenuBar()}
                <div className='searchbar'>
                  <label key='search'>
                    Search Templates
                  </label>
                  <input onChange={(e) => { setFilterTerm(e.target.value); }} value={filterTerm} type='text' />
                </div>
                {template && renderTemplateInformation()}
              </div>
              <div className='newform-body'>
                <TemplateList
                  templateId={template?.id}
                  templateList={filteredTemplates}
                  onTemplateChange={onTemplateChange}
                  onShowTemplateModal={onShowTemplateModal}
                />
              </div>
            </NewFormContainer>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

NewForm.propTypes = {
  teamId: PropTypes.string.isRequired,
};

export default NewForm;
