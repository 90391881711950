import React, { useMemo, useState, createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  uniqueId as generateUniqueId, any, isEmpty, isFunction,
} from 'underscore';
import InlineNotification from 'components/shared/inline-notification';
import PreviousReview from './previous-review';

const ReviewInterface = ({
  previousReviews,
  onOpenDiscussionClick,
  onReviewStart,
  defaultApprovalMessage,
  defaultRejectionMessage,
  toName,
  onCommentChange,
  onApprovalStatusUpdate,
  submitButtonText,
}) => {
  const form = createRef();
  const uniqueId = useMemo(() => generateUniqueId('review-interface-'), []);

  const [showErrors, setShowErrors] = useState(false);
  const [selectedApprovalStatus, setSelectedApprovalStatus] = useState(null);
  const [comment, setComment] = useState(null);

  const renderPreviousReviews = () => {
    if (any(previousReviews)) {
      const reviews = previousReviews.map((stepApproval) => {
        return (
          <PreviousReview
            key={stepApproval.id}
            approval={stepApproval}
            onOpenDiscussionClick={onOpenDiscussionClick}
          />
        );
      });

      return (
        <>
          <div className='linebreak margin-bottom' />
          {reviews}
        </>
      );
    }
  };

  const isReadyToSubmit = () => {
    return !any(submissionIssues());
  };

  const commentContent = () => {
    return comment;
  };

  const submissionIssues = () => {
    const issues = [];

    if (isEmpty(selectedApprovalStatus)) {
      issues.push('no_selection');
    }

    if (['reject', 'feedback'].includes(selectedApprovalStatus) && isEmpty(commentContent())) {
      issues.push('missing_comment');
    }

    return issues;
  };

  const renderErrorMessage = () => {
    if (showErrors) {
      return (
        <InlineNotification className='margin-top'>
          {invalidSubmissionReason()}
        </InlineNotification>
      );
    }
  };

  const invalidSubmissionReason = () => {
    if (!any(submissionIssues())) {
      return;
    }

    const issue = submissionIssues()[0];

    switch (issue) {
    case 'no_selection':
      return 'You must choose an option';
    case 'missing_comment':
      return 'You must include a comment';
    default:
      throw `Unhandled submission issue: '${issue}'`;
    }
  };

  const onSubmitClick = (e) => {
    e.preventDefault();

    if (!isReadyToSubmit()) {
      setShowErrors(true);
      return;
    }

    onReviewStart();
  };

  const focusComment = () => {
    const { comment } = form.current;
    comment.focus();
  };

  const commentIsDefault = () => {
    if (isEmpty(comment)) { return false; }
    return [defaultApprovalMessage, defaultRejectionMessage].includes(comment);
  };

  const onApprovalStatusDidUpdate = (e) => {
    if (commentIsDefault() || isEmpty(comment)) {
      setComment(defaultMessage(e.currentTarget.value));
    }

    setShowErrors(false);
    setSelectedApprovalStatus(e.currentTarget.value);

    if (isFunction(onApprovalStatusUpdate)) { onApprovalStatusUpdate(e); }
  };

  useEffect(() => {
    focusComment();
    if (isFunction(onCommentChange)) { onCommentChange(comment); }
  }, [selectedApprovalStatus]);

  const onCommentDidChange = (e) => {
    setShowErrors(false);
    setComment(e.currentTarget.value);
  };

  useEffect(() => {
    if (isFunction(onCommentChange)) { onCommentChange(comment); }
  }, [comment, onCommentChange]);

  const defaultMessage = (status) => {
    switch (status || selectedApprovalStatus) {
    case 'approve':
      if (!isEmpty(defaultApprovalMessage)) {
        return defaultApprovalMessage;
      }
      break;
    case 'reject':
      if (!isEmpty(defaultRejectionMessage)) {
        return defaultRejectionMessage;
      }
      break;
    default:
      return '';
    }
  };

  return (
    <div id='review-actions' className='padded'>
      <form ref={form}>
        <ul>
          <li>
            <div>
              <input
                id={`approveoption-${uniqueId}`}
                type='radio'
                onChange={onApprovalStatusDidUpdate}
                name='outcome'
                value='approve'
              />
            </div>

            <label htmlFor={`approveoption-${uniqueId}`}>
              <dl>
                <dt>Approve</dt>
                <dd>Allow this submission to move forward</dd>
              </dl>
            </label>
          </li>

          <li>
            <div>
              <input
                id={`feedbackoption-${uniqueId}`}
                type='radio'
                onChange={onApprovalStatusDidUpdate}
                name='outcome'
                value='feedback'
              />
            </div>

            <label htmlFor={`feedbackoption-${uniqueId}`}>
              <dl>
                <dt>Request changes</dt>
                <dd>Ask for a change to the submitted information</dd>
              </dl>
            </label>
          </li>

          <li>
            <div>
              <input
                id={`rejectoption-${uniqueId}`}
                type='radio'
                onChange={onApprovalStatusDidUpdate}
                name='outcome'
                value='reject'
              />
            </div>

            <label htmlFor={`rejectoption-${uniqueId}`}>
              <dl>
                <dt>Reject and halt</dt>
                <dd>Do not allow this submission to continue</dd>
              </dl>
            </label>
          </li>
        </ul>

        <textarea id='review-commentbox' name='comment' onChange={onCommentDidChange} className='review-actions-commentbox margin-bottom' placeholder={`Add a message for ${toName}...`} value={comment} />

        <button
          type='button'
          id='review-submitbutton'
          className={`btn-primary fullwidth ${!isReadyToSubmit() ? 'disabled' : ''}`}
          onClick={onSubmitClick}
        >
          {submitButtonText}
        </button>

        {renderErrorMessage()}

        <div>
          {renderPreviousReviews()}
        </div>
      </form>
    </div>
  );
};

ReviewInterface.propTypes = {
  onApprovalStatusUpdate: PropTypes.func,
  onSubmitClick: PropTypes.func,
  onCommentChange: PropTypes.func,
  submitButtonText: PropTypes.string,
  previousReviews: PropTypes.array,
  toName: PropTypes.string,
  onReviewStart: PropTypes.func,
  defaultApprovalMessage: PropTypes.string,
  defaultRejectionMessage: PropTypes.string,
};

ReviewInterface.defaultProps = {
  toName: 'the applicant',
};

export default ReviewInterface;
