import React from 'react';
import renderer from 'modules/renderer';
import MyTasksContainer from 'components/my-tasks-container';
import CommonProviders from 'components/common-providers';

export const index = () => {
  renderer(
    (
      <CommonProviders>
        <MyTasksContainer />
      </CommonProviders>
    ), document.getElementById('mytasks'),
  );
};

export default {
  index,
};