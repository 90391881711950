import React from 'react';
import renderer from 'modules/renderer';
import CommonProviders from 'components/common-providers';
import BatchFormExportTemplates from '../components/batch-form-export-templates';
import PrimaryTabs from '../components/shared/primary-tabs';

function index() {
  renderer(<PrimaryTabs />, document.getElementById('tabs'));
  renderer(
    (
      <CommonProviders>
        <BatchFormExportTemplates
          exportTemplates={CityGrows.Server.exportTemplatesData}
          flowTemplates={CityGrows.Server.flowTemplates}
        />
      </CommonProviders>
    ), document.getElementById('exporttemplates'),
  );
}

export default {
  index,
};
