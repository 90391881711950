import SignatureAPI from '../apis/signing-api';

const sign = function (activeStepId, dataURI) {
  const request = SignatureAPI.create(activeStepId, dataURI);
  return request;
};

export default {
  sign,
};
