import _ from 'underscore';

import 'select2';
import renderer from 'modules/renderer';
import CommonProviders from 'components/common-providers';
import MerchantAccountsManager from 'components/merchant-accounts-manager';
import FinanceSubnav from '../components/shared/finance-subnav';
import PrimaryTabs from '../components/shared/primary-tabs';

function hideAllExtraFields() {
  const allExtraFields = ['#weeklyanchor', '#monthlyanchor'];

  _.each(allExtraFields, (id) => {
    $(id).hide();
  });
}

function showExtraFields(schedule) {
  hideAllExtraFields();

  switch (schedule) {
    case 'daily':
      // No extra fields
      break;
    case 'weekly':
      $('#weeklyanchor').show();
      break;
    case 'monthly':
      $('#monthlyanchor').show();
      break;
    default:
      break;
  }
}

function manage() {
  renderer(
    (
      <CommonProviders>
        <MerchantAccountsManager  processors={[]}
                                  teamId={CityGrows.Server.team.id}
                                  teamSlug={CityGrows.Server.team.slug}
                                  currentUserId={CityGrows.Server.currentUserId}/>
      </CommonProviders>
    ), document.getElementById('merchant_accounts_manager'),
  );
}

const index = () => {
  renderer(<PrimaryTabs />, document.getElementById('tabs'));
  $('select').select2();

  $('#payoutschedule').on('change', (e) => {
    showExtraFields(e.currentTarget.value);
  });

  $('#payoutschedule').trigger('change', $('#payoutschedule').val());

  renderer(
    (
      <FinanceSubnav teamSlug={CityGrows.Server.team.slug} activeLink='payouts'/>
    ), document.getElementById('finance-left-nav')
  )
};

export default {
  index,
};
