import React from 'react';
import PropTypes from 'prop-types';

const NoNegativesInput = (props) => {
  const {
    onChange, id, style, value, name, klass, allowZero,
  } = props;

  const onInputChange = (e) => {
    // Prevent negatives
    let { value } = e.target;
    if (!!e.target.value && Math.abs(e.target.value) >= 1) {
      value = Math.abs(e.target.value);
    } else if (!!e.target.value && e.target.value == 0) {
      value = 0;
    } else {
      value = '';
    }

    onChange(value);
  };

  return (
    <input
      type='number'
      className={klass}
      min={`${allowZero ? 0 : 1}`}
      value={value}
      style={style}
      id={id}
      onChange={onInputChange}
      name={name}
    />
  );
};

NoNegativesInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  style: PropTypes.shape({}),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  name: PropTypes.string,
  klass: PropTypes.string,
  allowZero: PropTypes.bool,
};

NoNegativesInput.defaultProps = {
  id: '',
  style: {},
  value: '',
  klass: '',
};

export default NoNegativesInput;
