import React from 'react';
import PropTypes from 'prop-types';
import AccessManager from './menu/access-manager';
import ApplicantManager from './menu/applicant-manager';

const Access = ({
  admin,
  applicantAttachments,
  initiatedFlowId,
  personAttachments,
  teamId,
}) => {
  const renderAccessManager = () => {
    if (admin) {
      return (
        <AccessManager
          personAttachments={personAttachments}
          initiatedFlowId={initiatedFlowId}
          teamId={teamId}
        />
      );
    }
  }

  const renderApplicantManager = () => {
    return (
      <ApplicantManager
        applicantAttachments={applicantAttachments}
        initiatedFlowId={initiatedFlowId}
      />
    );
  }

  return (
    <>
      {renderAccessManager()}
      {renderApplicantManager()}
    </>
  );
};

Access.propTypes = {
  initiatedFlowId: PropTypes.number.isRequired,
  onCloseClick: PropTypes.func,
  transitionRef: PropTypes.object,
};

export default Access;
