import API from 'apis/applicant-attachment-api';
import InitiatedFlowConstants from 'constants/initiated-flow-constants';
import { error } from 'components/shared/flash';

const ApplicantAttachmentActions = {
  dispatchPrimaryApplicantChanged(dispatch, initiatedFlowId, applicant) {
    dispatch({
      type: InitiatedFlowConstants.PRIMARY_APPLICANT_CHANGED,
      initiatedFlowId,
      applicant,
    });
  },

  dispatchApplicantListUpdated(dispatch, initiatedFlowId, applicant, saved) {
    dispatch({
      type: InitiatedFlowConstants.APPLICANT_LIST_UPDATED,
      initiatedFlowId,
      applicant,
      saved,
    });
  },

  addApplicant(dispatch, userId, initiatedFlowId) {
    return API.addApplicant(userId, initiatedFlowId)
      .done((res) => {
        this.dispatchApplicantListUpdated(dispatch, initiatedFlowId, res, true);
      })
      .fail((res) => {
        error(res.responseJSON.error ? res.responseJSON.error : null);
      });
  },

  addPseudoApplicant(dispatch, email, name, initiatedFlowId) {
    return API.addPseudoApplicant(email, name, initiatedFlowId)
      .done((res) => {
        this.dispatchApplicantListUpdated(dispatch, initiatedFlowId, res, true);
      })
      .fail(() => { error(); });
  },

  makePrimary(dispatch, applicantId, initiatedFlowId) {
    API.makePrimary(applicantId)
      .done((res) => {
        this.dispatchPrimaryApplicantChanged(dispatch, initiatedFlowId, res);
      })
      .fail(() => { error(); });
  },

  removeApplicant(dispatch, applicantId, initiatedFlowId) {
    API.removeApplicant(applicantId)
      .done((res) => {
        this.dispatchApplicantListUpdated(dispatch, initiatedFlowId, res, false);
      })
      .fail(() => { error(); });
  },
};

export default ApplicantAttachmentActions;
