import React, {useState, useRef, useEffect } from 'react';
import SignaturePad from 'react-signature-canvas';
import Flash from 'components/shared/flash';
import SignatureActions from 'actions/signature-actions';
import _ from 'underscore';
import NProgress from 'nprogress';
import useFocusTrap from 'components/hooks/use-focus-trap';

const SignaturePrompt = (props) => {
  const [submitting, setSubmitting] = useState(false);
  const [signed, setSigned] = useState(false);
  const [error, setError] = useState(false);
  const [name, setName] = useState('');
  const signaturePad = useRef();
  const focusEl = useFocusTrap(onCancelClick);

  useEffect(() => {
    if (signaturePad.current) {
      signaturePad.current.off();
    }
  }, []);

  function addText() {
    setSigned(false);
    const canvas = signaturePad.current.getCanvas();
    signaturePad.current.clear();

    const ctx = canvas.getContext('2d');
    ctx.font = '30px Brush Script MT, cursive';
    ctx.fillText(name, 200, 40);
    if (name.length > 0) { 
      setError(false); 
      setSigned(true);
    }
  }

  function onSignClick(e) {
    e.preventDefault();
   
    if (!signed) {
      setError(true);
      return;
    }

    setSubmitting(true);
    NProgress.start();

    SignatureActions.sign(props.activeStepId, signaturePad.current.getTrimmedCanvas().toDataURL())
      .done(onSuccess)
      .fail(onFail)
      .always(() => {
        setSubmitting(false);
        NProgress.done();
      });
  }

  function onCancelClick (e) {
    e.preventDefault();
    props.onCancel();
  }

  function onSuccess (res) {
    if (_.isFunction(props.onSuccess)) {
      props.onSuccess(res);
    }
  }

  function onFail () {
    Flash.render({
      type: 'error',
      message: 'Oops, that didn\'t work. If you continue to experience problems, please contact support.',
    });
  }

  function renderError() {
    if (error) {
      return <div className='text-alert margin-top-less'>A signature is required in order to continue</div>;
    }
  }

  return (
    <div id='signaturerequester' className='popup' ref={focusEl}>
      <h1>Signature</h1>
      <label><span>Please sign. By signing, you certify that the information you're about to submit is true and correct to the best of your knowledge.</span></label>
      <SignaturePad
        canvasProps={{ width: 600, height: 50, className: 'sigCanvas' }}
        ref={signaturePad}
        minWidth={2}
        maxWidth={5}
      />
      {renderError()}
      <div className='signaturerequester-footer cf'>
        <label className='inline-block'>Name
          <input type='text' placeholder='Enter Name' onChange={(e) => { setName(e.target.value); }} value={name} />
        </label>
        <button className='btn-primary inline-block' type='submit' onClick={addText}>Sign</button>
        <div>
          <div className='float-right'>
            <button onClick={onCancelClick} className='btn-secondary signaturerequester-cancelbutton margin-right'>Cancel</button>
            <button onClick={onSignClick} disabled={submitting} className={`btn-primary margin-left ${submitting ? 'disabled' : ''}`}>Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignaturePrompt;
