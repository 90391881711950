import React, { useEffect, useState } from 'react';
import Select from 'components/shared/select';
import { findWhere } from 'underscore';
import TasksListItem from './tasks-list-item';
import UpDownArrow from '../shared/buttons/up-down-arrow';

const FILTER_OPTIONS = [
  {label: 'All', value: 'all'},
  {label: 'Overdue', value: 'overdue'},
  {label: 'Reviews', value: 'reviews'},
  {label: 'Internal Step', value: 'assignedInternal'},
  {label: 'Applicant Step', value: 'assignedExternal'},
]

const SORT_OPTIONS = [
  {label: 'Sort by oldest first', value: 'oldest' },
  {label: 'Sort by newest first', value: 'newest' },
  {label: 'Sort by form name', value: 'form' },
  {label: 'Sort by applicant', value: 'applicant' },
]

const TasksList = (props) => {
  const [skippedExpanded, setSkippedExpanded] = useState(false);

  useEffect(() => {
    if (props.skipped.includes(props.activeSubmission)) {
      props.changeActiveSubmission(props.submissions[0])
    }
  }, [props.skipped])


  function updateSort({ value }) {
    props.setSort(value);
  }

  function updateFilter({ value }) {
    props.setTaskFilter(value);
  }

  function tabContent() {
    return (
      <>
        <Select
          options={FILTER_OPTIONS}
          value={findWhere(FILTER_OPTIONS, { value: props.taskFilter })}
          onChange={updateFilter}
          styles={{
            container: (provided) => ({
              ...provided,
              marginTop: '10px',
              width: '300px',
              backgroundColor: '#FFF',
              borderRadius: '4px',
            }),
          }}
        />
        <Select
          options={SORT_OPTIONS}
          value={findWhere(SORT_OPTIONS, { value: props.sort })}
          onChange={updateSort}
          styles={{
            container: (provided) => ({
              ...provided,
              marginTop: '10px',
              width: '300px',
              backgroundColor: '#FFF',
              borderRadius: '4px',
            }),
          }}
        />
      </>
    )
  }

  function renderTask(task) {
    const active = task.id === props.activeSubmission

    return <TasksListItem active={active} task={task} changeActiveSubmission={props.changeActiveSubmission} />;
  }

  function renderSkipped() {
    return (
      <div className={`mytasks-tasklist ${skippedExpanded ? 'skipped-expanded' : 'skipped'}`}>
        <div>
          <h3 className='padded-less inline-block bold'>{`Skipped (${props.skippedSubmissions.length})`}</h3>
          <div className='float-right inline-block padded-less'><UpDownArrow expanded={skippedExpanded} setExpanded={setSkippedExpanded} /></div>
        </div>
        {skippedExpanded && (
           <ul>
           {props.skippedSubmissions.map(renderTask)}
          </ul>
        )}
      </div>
    )
  }

  function renderTasks() {
    if (props.loading) {
      return (
        <ul className='mytasks-tasklist-loading'>

        </ul>
      )
    }

    return (
      <ul className='mytasks-tasklist'>
        {props.submissions.map(renderTask)}
      </ul>
    );
  }

  return (
    <div className='mytasks-tasklist-container'>
      <div className='padded'>
        <div className='tabnav'>
          <ul>
            <li className='selected'>
              Filter
            </li>
          </ul>
        </div>
        <div>
          {tabContent()}
        </div>
      </div>
      <div>
        <h3 className='padded-less inline-block bold'>{`Active Tasks (${props.submissions.length})`}</h3>
      </div>
      {renderTasks()}
      {renderSkipped()}
    </div>
  );
};

export default TasksList;
