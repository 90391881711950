import React, { useCallback, useMemo, createRef,  } from 'react';
import { debounce, each, map } from 'underscore';

const StandardFields = ({
  entity,
  updateEntity,
  onNoteChange,
}) => {
  const customFieldValues = () => {
    const values = {};

    each(fields, (fieldRef, key) => {
      values[key] = fieldRef.value;
    });

    return values;
  };

  const fields = useMemo(() => {
    return entity.fields.reduce((output, field) => {
      output[field.slug] = createRef();
      return output;
    }, {});
  }, []);

  const onFormChange = useCallback(debounce(() => {
    updateEntity({ custom_entity_field_values: customFieldValues() });
  }, 500), []);

  const renderField = (field) => {
    const fieldName = field.name.replace(/_/g, ' ');
    return (
      <label key={field.slug}>
        <span>{fieldName}</span>
        <input type='text' ref={(ref) => { fields[field.slug] = ref; }} defaultValue={field.value} placeholder={`Add ${fieldName}`} onChange={onFormChange} />
      </label>
    );
  };

  return (
    <>
      {map(entity.fields, renderField)}
      <textarea
        defaultValue={entity.note}
        placeholder='Notes...'
        onChange={(e) => onNoteChange(e.currentTarget.value)}
      />
    </>
  );
};

export default StandardFields;