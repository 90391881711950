var ActivityAPI = {
	index(initiatedFlowId){
		const url = '/initiated_flows/' + initiatedFlowId + '/activities';
		var request = $.ajax(url, {
			dataType: 'json',
			method: 'GET'
		});

		return request;
	}
};

export default ActivityAPI;