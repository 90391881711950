import React, {
  memo, useCallback, useEffect, useState, useContext,
} from 'react';
import PropTypes from 'prop-types';
import ActiveStepActions from 'actions/active-step-actions';
import { success } from 'components/shared/flash';
import pluralize from 'modules/pluralize';
import {
  where, filter, isEmpty, isNull,
} from 'underscore';
import truncate from 'truncate';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import StepOutput from './step_in_list/step-output';

const LOCKABLE_STEP_TYPES = ['CollectInfoStep'];

const StepInList = memo((props) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);
  const [shouldAnimate, setAnimate] = useState(false);

  useEffect(() => {
    return (() => {
      setAnimate(true);
    });
  });

  const timelineStepClass = (step, flow) => {
    const klasses = [];

    if ((flow.finished_at && !isSupplementalRequirement()) || step.position < flow.current_step.position || step.completed) {
      klasses.push('done');
    } else if (step.position === flow.current_step.position) {
      if (flow.halted_at) {
        klasses.push('halted');
      } else {
        klasses.push('current');
      }
    } else {
      klasses.push('notdone');
    }

    if (step.sent) {
      klasses.push('flowtimeline-activerequirement');
    }

    if (isSupplementalRequirement()) {
      klasses.push('requirement');
    }

    if (shouldAnimate) {
      klasses.push('animate');
    }

    return klasses.join(' ');
  };

  const renderIcon = () => {
    return <span className={`flowtimeline-stepicon icon ${props.step.icon_class}`} />;
  };

  const renderStepName = () => {
    const typeForURL = props.step.generic_type === 'Step' ? 'steps' : 'supplemental_requirement';
    const prefix = props.step.generic_type === 'Step' ? `${props.step.position}. ` : '';
    return (
      <div className='flowtimeline-stepnamewrap'>
        <a href={`/initiated_flows/${props.initiatedFlow.id}/${typeForURL}/${props.step.id}`}>
          {`${prefix}${props.step.name_or_default}`}{renderRequirementStatus(props.step)}
        </a>
      </div>
    );
  };

  const renderChangeRequests = () => {
    const pendingChanges = filter(props.discussions, (discussion) => {
      return !discussion.resolved
        && discussion.step_approval
        && isNull(discussion.step_approval.approval_status)
        && props.activeStep.id === discussion.step_approval.active_step_id;
    });

    if (!isEmpty(pendingChanges)) {
      return (
        <div className='flowtimeline-changesrequested'>
          Changes requested
          <div className='text-right'>
            <a className='secondary' href='#' onClick={props.onOpenDiscussionClick}>View</a>
          </div>
        </div>
      );
    }
  };

  const isSupplementalRequirement = () => {
    return props.step.generic_type === 'SupplementalRequirement';
  };

  const onLockClick = () => {
    ActiveStepActions.update(dispatch, props.activeStep.id, {
      locked: !props.activeStep.locked,
    })
    .done(() => { success(`Step ${!props.activeStep.locked ? 'locked' : 'unlocked'}`); });
  };

  const doesNotLock = () => {
    return !LOCKABLE_STEP_TYPES.includes(props.step.type) || isSupplementalRequirement();
  };

  const renderLock = () => {
    if (doesNotLock()) { return; }

    if (props.admin) {
      return renderAdminLock();
    }

    return renderConstituentLock();
  };

  const renderConstituentLock = () => {
    return (
      <div className='flowtimeline-disabledlockwrap'>
        <span className={`icon ${props.activeStep.locked_at ? 'icon-lock' : 'icon-unlocked'}`} />
      </div>
    );
  };

  const renderAdminLock = useCallback(() => {
    return (
      <button
        type='button'
        className={`flowtimeline-lockbutton ${props.activeStep.locked_at ? 'locked' : 'unlocked'}`}
        href='#'
        onClick={onLockClick}
      >
        <span className={`icon ${props.activeStep.locked_at ? 'icon-lock' : 'icon-unlocked'}`} />
      </button>
    );
  }, [props.initiatedFlow]);

  const renderRequirementStatus = () => {
    if (props.step.generic_type === 'Step') { return; }

    if (props.step.canceled) {
      return <span className='text-alert bold'> - Canceled</span>;
    }

    if (props.step.sent) { return; }

    return (<span className='text-semialert bold'> - Unsent</span>);
  };

  const renderOutput = (output) => {
    let klass = 'valid';
    if (output.expired) {
      klass = 'expired';
    } else if (output.revoked) {
      klass = 'revoked';
    }

    return (
      <li className={`output ${klass}`}>
        <div>{truncate(output.name, 20)}</div>
        <div><StepOutput output={output} /></div>
      </li>
    );
  };

  const renderOutputs = () => {
    const outputs = where(props.initiatedFlow.issued_outputs, { issued_on_step_identity: props.step.identity, issued: true });
    if (outputs.length === 0) { return; }

    return (
      <div className='flowtimeline-outputs'>
        <div className='flowtimeline-outputname'>{`${pluralize('Output', outputs.length)} issued`}</div>
        <ul>
          {outputs.map(renderOutput)}
        </ul>
      </div>
    );
  };

  return (
    <li className={`flowtimeline-step ${props.viewing ? 'viewing' : ''}`}>
      <div className={`flowtimeline-steptopline ${timelineStepClass(props.step, props.initiatedFlow)}`}>
        {renderIcon()}
        {renderStepName()}
        <div className='flowtimeline-lockwrap'>{renderLock()}</div>
      </div>
      {renderOutputs()}
      {renderChangeRequests()}
    </li>
  );
});

StepInList.displayName = 'StepInList';

StepInList.propTypes = {
  activeStep: PropTypes.shape(),
  admin: PropTypes.bool.isRequired,
  step: PropTypes.object.isRequired,
  initiatedFlow: PropTypes.object.isRequired,
  viewing: PropTypes.bool.isRequired,
  onOpenDiscussionClick: PropTypes.func,
  discussions: PropTypes.arrayOf(PropTypes.shape({})),
};

export default StepInList;
