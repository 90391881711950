import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';

const TemplateNavBar = memo((props) => {
  const activeClass = 'active';

  function renderOverviewLink() {
    if (props.masterTemplate.ever_published) {
      return (
        <li><NavLink
          to='/stats'
          activeClassName={activeClass}
          id='overviewtab'
        >Overview
        </NavLink>
        </li>
      );
    }
  }

  function renderWriteOnlyLinks() {
    if (props.permissionType === 'write') {
      return (
        <>
          <li><NavLink
            to='/edit'
            activeClassName={activeClass}
            tabIndex='1'
          >Steps
          </NavLink>
          </li>
          <li><NavLink
            to='/configuration/settings'
            activeClassName={activeClass}
            isActive={(match, location) => { return location.pathname.match(/^\/configuration/); }}
            id='settingstab'
            tabIndex='1'
          >Settings
          </NavLink>
          </li>
          <li><NavLink
            to='/outputs'
            activeClassName={activeClass}
            isActive={(match, location) => { return location.pathname.match(/^\/outputs/); }}
            id='formoutputstab'
            tabIndex='1'
          >Outputs
          </NavLink>
          </li>
          <li><NavLink
            to='/powerups/formulas'
            activeClassName={activeClass}
            isActive={(match, location) => { return location.pathname.match(/^\/powerups/); }}
            tabIndex='1'
            id='powerupslink'
          >Power-Ups
          </NavLink>
          </li>
        </>
      );
    }
  }

  return (
    <div className='templatenavbar'>
      <div className='nav-bar'>
        <ul>
          {renderOverviewLink()}
          {renderWriteOnlyLinks()}
          <li>
            <NavLink
              to='/actions/duplicate'
              id='actionslink'
              activeClassName={activeClass}
              isActive={(match, location) => { return location.pathname.match(/^\/actions/); }}
            >
              Actions
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
});

TemplateNavBar.displayName = 'TemplateNavBar';

TemplateNavBar.propTypes = {
  permissionType: PropTypes.string.isRequired,
  flowTemplate: PropTypes.shape({
    id: PropTypes.number.isRequired,
    version: PropTypes.number.isRequired,
  }).isRequired,
  masterTemplate: PropTypes.object.isRequired,
};

export default withRouter(TemplateNavBar);
