var DiscussionAPI = {
	index(initiatedFlowId){
		const url = '/initiated_flows/' + initiatedFlowId + '/discussions';
		var request = $.ajax(url, {
			dataType: 'json',
			method: 'GET'
		});

		return request;
	},

	create(initiatedFlowId, attrs){
		const url = '/initiated_flows/' + initiatedFlowId + '/discussions';
		
		return $.ajax(url, {
			data: {
				discussion: attrs
			},
			dataType: 'json',
			method: 'POST'
		});
	},

	update(discussionId, attrs){
		const url = '/discussions/' + discussionId;
		return $.ajax(url, {
			data: {
				discussion: attrs
			},
			dataType: 'json',
			method: 'PUT'
		});
	},

	resolve(discussionId, resolved){
		const url = `/discussions/${discussionId}/resolve`;
		return $.ajax(url, {
			data: {
				discussion: {
					resolved: resolved
				}
			},
			dataType: 'json',
			method: 'POST'
		});
	},

	destroy(discussionId){
		const url = '/discussions/' + discussionId;

		return $.ajax(url, {
			method: 'DELETE',
			dataType: 'json'
		});
	}
};

export default DiscussionAPI;