import jstz from 'jstimezonedetect';


function knew() {
  const $input = $('<input type="hidden" name="user[time_zone]" />');
  $input.val(jstz.determine().name());

  const $form = $('#new-user-form');
  $form.append($input);
}

export default {
  new: knew,
};
