import NProgress from 'nprogress';
import DiscussionConstants from '../constants/discussion-constants';
import DiscussionAPI from '../apis/discussion-api';
import DiscussionCommentAPI from '../apis/discussion-comment-api';
import { success, error } from '../components/shared/flash';

function onDiscussionDeleted(dispatch, discussion) {
  success('Discussion deleted');

  dispatch({
    type: DiscussionConstants.DESTROYED,
    discussion,
  });
}

function onDiscussionUpdated(dispatch, discussionAttrs) {
  dispatch({
    type: DiscussionConstants.UPDATED,
    discussion: discussionAttrs,
  });
}

const DiscussionActions = {

  onDiscussionCreate(dispatch, discussion) {
    dispatch({
      type: DiscussionConstants.CREATED,
      discussion,
    });
  },

  startDiscussion(dispatch, initiatedFlowId, discussionAttrs) {
    NProgress.start();

    return DiscussionAPI.create(initiatedFlowId, discussionAttrs)
      .done((res) => { this.onDiscussionCreate(dispatch, res); })
      .fail(() => { error(); })
      .always(() => { NProgress.done(); });
  },

  saveDiscussionComment(dispatch, discussionId, commentBody) {
    const commentAttrs = { body: commentBody };
    return DiscussionCommentAPI.create(discussionId, commentAttrs)
      .done((comment) => {
        dispatch({
          type: DiscussionConstants.COMMENT_ADDED,
          discussionComment: comment,
        });
      })
      .fail(() => { error(); });
  },

  deleteDiscussionComment(dispatch, discussionCommentId) {
    return DiscussionCommentAPI.destroy(discussionCommentId)
      .done(() => {
        dispatch({
          type: DiscussionConstants.COMMENT_DELETED,
          discussionCommentId,
        });
      })
      .fail(() => { error(); });
  },

  update(dispatch, discussionId, attrs) {
    // Update immediately
    dispatch({
      type: DiscussionConstants.UPDATED,
      discussion: { ...attrs, id: discussionId },
    });

    return DiscussionAPI.update(discussionId, attrs)
      .done((res) => { onDiscussionUpdated(dispatch, res); })
      .fail(() => { error(); });
  },

  resolve(dispatch, discussionId, resolved) {
    // Update immediately
    dispatch({
      type: DiscussionConstants.UPDATED,
      discussion: {
        id: discussionId,
        resolved,
      },
    });

    return DiscussionAPI.resolve(discussionId, resolved)
              .done((res) => { onDiscussionUpdated(dispatch, res); })
              .fail(() => { error(); });
  },

  loadDiscussions(initiatedFlowId, dispatch) {
    DiscussionAPI.index(initiatedFlowId)
      .done((res) => {
        dispatch({
          type: DiscussionConstants.LOADED,
          discussions: res,
        });
      })
      .fail(() => { error('Unable to load discussions'); });
  },

  deleteDiscussion(dispatch, discussionId) {
    DiscussionAPI.destroy(discussionId)
    .done((res) => { onDiscussionDeleted(dispatch, res); })
    .fail(() => { error(); });
  },
};

export default DiscussionActions;
