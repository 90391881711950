const TagAPI = {
  index() {
    var url = '/tags';
    var request = $.ajax(url, {
      dataType: 'JSON',
      method: 'GET',
    });

    return request;
  },

  destroy(tagId) {
    const url = `/tags/${tagId}`;
    const request = $.ajax(url, {
      dataType: 'JSON',
      method: 'DELETE',
    });

    return request;
  },

  update(tagId, tagName) {
    const url = `/tags/${tagId}`;
    const request = $.ajax(url, {
      dataType: 'JSON',
      method: 'PUT',
      data: {
        name: tagName,
      },
    });

    return request;
  },
};

export default TagAPI;
