import PropTypes from 'prop-types';
import React, { memo } from 'react';
import _ from 'underscore';

const SlidingToggle = memo((props) => {
  const DEFAULT_OPTIONS = {
    size: 'normal',
  };

  const options = _.extend(DEFAULT_OPTIONS, props.options);

  const handleClick = () => {
    if (!props.disabled) {
      props.onToggle();
    }
  };

  const labelClass = () => {
    return props.on ? 'on' : '';
  };

  const renderDescription = () => {
    if (!props.description) { return; }

    return (
      <div className='slidingtoggle-description'>
        {props.description}
      </div>
    );
  };

  const classes = () => {
    let classes = 'slidingtoggle cf';

    if (props.on) {
      classes += ' on';
    }

    if (props.disabled) {
      classes += ' disabled';
    }

    return classes;
  };

  const renderNestedContent = () => {
    if (!props.on || !props.children) { return; }

    return (
      <div className='stepoptions-nestedoption'>
        {props.children}
      </div>
    );
  };

  return (
    <>
      <label className={`slidingtoggle-wrap inline-block ${props.className} ${labelClass()}`}>
        <div>
          <span id={props.id} onClick={handleClick} className={`${props.disabled ? '' : 'clickable'} slidingtoggle-label`}>{props.label}</span>
          <span onClick={handleClick} className={classes()}>
            <span />
          </span>
          {renderDescription()}
        </div>
      </label>

      {renderNestedContent()}
    </>
  );
});

SlidingToggle.displayName = 'SlidingToggle';

SlidingToggle.defaultProps = {
  disabled: false,
  label: '',
  className: '',
  id: '',
};

SlidingToggle.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  on: PropTypes.bool.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.shape({
    size: PropTypes.string,
  }),
};

export default SlidingToggle;
