var LogicConfigAPI = {
	create(logicableId, logicableType, action, conditions){
		const url = `/logic_configs`;

		return $.ajax(url, {
			method: 'POST',
			dataType: 'json',
			data: {
				logic_config: {
					logicable_id: logicableId,
					logicable_type: logicableType,
					action,
					logic_conditions_attributes: conditions
				}
			}
		});
	},

  update(id, action, conditions){
    const url = `/logic_configs/${id}`;

    return $.ajax(url, {
      method: 'PUT',
      dataType: 'json',
      data: {
        logic_config: {
          action,
          logic_conditions_attributes: conditions
        }
      }
    });
  },

	destroy(logicConfigId){
		const url = `/logic_configs/${logicConfigId}`;

		return $.ajax(url, {
			method: 'DELETE',
			dataType: 'json'
		});
	},
};

export default LogicConfigAPI;
