import API from 'apis/user-preference-api';
import { error } from 'components/shared/flash';

const UserPreferenceActions = {
  starForm(masterTemplateId) {
    return API.starForm(masterTemplateId)
      .fail(() => {
        error();
      });
  },

  unstarForm(masterTemplateId) {
    return API.unstarForm(masterTemplateId)
      .fail(() => {
        error();
      });
  },
};

export default UserPreferenceActions;
