import PhoneValidator from 'phone';
import {
  filter, isArray, isEmpty, isFinite, isNumber, isNull,
} from 'underscore';
import EmailValidator from './email-validator';

function validate(value, field) {
  if (!value || value.length < 1) {
    return false;
  }

  // Now let's make sure it's legit.
  //
  switch (field.data_type) {
  case 'integer':
  case 'float':
  case 'money':
    return validateNumber(value, field);
  case 'email':
    return EmailValidator.validate(value);
  case 'phone':
    return !isEmpty(PhoneValidator(value));
  case 'multiple_choice':
    return Number(value) !== 0 && isNumber(Number(value));
  case 'checkboxes':
    if (!field.hasOwnProperty('min_num_checks')) {
      throw 'Checkbox field is missing required min_num_checks property';
    }

    return checkboxResponseTally(value) >= field.min_num_checks;
  case 'file':
    return isArray(value) && value.length > 0;
  case 'date_range':
    if (!value.start_date || !value.end_date) { return false; }

    return value.start_date.length > 0 && value.end_date.length > 0;
  default:
    return true;
  }
}

function isFloat(value) {
  return isFinite(value) && !isNull(value.match(/^-?[0-9]+(\.[0-9]+)?$/));
}

function checkboxResponseTally(checkboxFieldContent) {
  const checked = filter(checkboxFieldContent, (v, k) => {
    return !!v;
  });

  return checked.length;
}

function validateNumber(value, field) {
  return validateNumberFormat(value, field) && validateNumberInRange(value, field);
}

function validateNumberFormat(value, field) {
  switch (field.data_type) {
  case 'integer':
    return isFinite(value) && (value % 1 === 0) && !isNull(value.trim().match(/^-?[0-9]+$/));
  default:
    return isFloat(value);
  }
}

function validateNumberInRange(value, field) {
  const parsedValue = parseFloat(value);
  let min = parseFloat(field.min_value);
  min = isFinite(min) ? min : parsedValue;
  let max = parseFloat(field.max_value);
  max = isFinite(max) ? max : parsedValue;

  return min <= parsedValue && parsedValue <= max;
}

export { validate, validateNumberFormat, validateNumberInRange };
