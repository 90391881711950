import React, { useState, useMemo, useEffect } from 'react';

import PropTypes from 'prop-types';

import Select from 'components/shared/select';
import {
  STANDARD_COMPARATORS, CHECKBOX_COMPARATORS, CONNECTIVES, DEFAULT_CONNECTIVE, NUMERIC_COMPARATORS,
} from 'constants/logic-constants';
import {css} from "@emotion/react";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// NOTE: this is a cumbersome function, but it works better as something that happens in
// response to events, rather than making it an effect (because of the fact that it both
// depends on the logicConfig and modifies the logicConfig). It's difficult to prevent
// it from looping otherwise.
// A better way to approach this might be to do partial updates and only update the bits
// that are changing in each handler.
// Validation logic makes that a bit harder.
const sendChange = ({
  onChange,
  selectedFieldId,
  comparator,
  matchValue,
  connective,
  index,
}) => {
  if (onChange) {
    // This is causing problems. A valid configuration doesn't start as valid.
    // The useEffect used to take care of that, but it's no longer using it.
    onChange({
      source_id: Number(selectedFieldId),
      comparator,
      match_value: matchValue,
      connective,
    }, index);
  }
};

const LogicCondition = ({
  condition, index, onChange, availableFields, disabled, isLast, onRemoveClick,
}) => {
  const initialConnective = () => {
    if (isLast) { return null; }

    return condition.connective || DEFAULT_CONNECTIVE;
  };

  const [selectedFieldId, setSelectedFieldId] = useState(condition.source_id);
  const [comparator, setComparator] = useState(condition.comparator);
  const [matchValue, setMatchValue] = useState(condition.match_value);

  const [connective, setConnective] = useState(initialConnective);

  const selectedField = useMemo(
    () => availableFields.find(({ id }) => id === Number(selectedFieldId)),
    [availableFields, selectedFieldId],
  );
  const selectedFieldType = selectedField ? selectedField.data_type : null;

  const onConnectiveChange = ({ value }) => {
    setConnective(value);
    sendChange({
      onChange,
      selectedFieldId,
      comparator,
      matchValue,
      connective: value,
      index,
    });
  };

  useEffect(() => {
    if (!isLast && !connective) {
      onConnectiveChange({ value: DEFAULT_CONNECTIVE });
    }
  }, [isLast, connective, onConnectiveChange]);

  const comparatorsForFieldType = (fieldType) => {
    switch (fieldType) {
    case 'integer':
    case 'float':
      return NUMERIC_COMPARATORS;
    case 'multiple_choice':
      return STANDARD_COMPARATORS;
    case 'checkboxes':
      return CHECKBOX_COMPARATORS;
    default:
      return [];
    }
  };

  const defaultComparitorForFieldType = (fieldType) => {
    const comparators = comparatorsForFieldType(fieldType);

    if (comparators.length) {
      return comparators[0].value;
    }

    return null;
  };

  const comparatorOptions = comparatorsForFieldType(selectedFieldType);

  const matchOptions = selectedFieldId
    ? selectedField.multiple_choice_options.map((option) => ({
      value: option.id,
      label: option.name,
    }))
    : [];

  const onSelectedFieldChange = ({ value }) => {
    const fieldId = value;
    // NOTE: duplicating this here for now.
    // This lookup needs to happen because the selectedField is changing,
    // so we can't just use the selectedField that we already have in the
    // component.
    const type = availableFields?.find(({ id }) => id === Number(fieldId))?.data_type ?? null;
    const newComparator = defaultComparitorForFieldType(type);
    const newMatchValue = '';

    setSelectedFieldId(fieldId);
    setComparator(newComparator);
    setMatchValue(newMatchValue);

    sendChange({
      onChange,
      selectedFieldId: fieldId,
      comparator: newComparator,
      matchValue: newMatchValue,
      connective,
      index,
    });
  };

  const onComparatorChange = ({ value }) => {
    setComparator(value);
    sendChange({
      onChange,
      selectedFieldId,
      comparator: value,
      matchValue,
      connective,
      index,
    });
  };

  const onMatchValueChange = (value) => {
    setMatchValue(value);
    sendChange({
      onChange,
      selectedFieldId,
      comparator,
      matchValue: value,
      connective,
      index,
    });
  };

  const onInputMatchValueChange = (e) => onMatchValueChange(e.target.value);
  const onSelectMatchValueChange = ({ value }) => onMatchValueChange(value);

  function renderMatchValue() {
    const isNumeric = ['float', 'integer'].includes(selectedFieldType);
    if (isNumeric) {
      return (
        <input
          name='matchvalue'
          type='text'
          value={matchValue}
          onChange={onInputMatchValueChange}
          placeholder='Type a number'
        />
      );
    }

    return (
      <Select
        // Number cast is here because even if matchValue is a numeric ID (matching
        // the structure of multiple_choice_options), it comes from the backend as a string.
        // This ensures that it's picked up correctly.
        value={matchOptions.find(({ value }) => value === Number(matchValue)) ?? null}
        onChange={onSelectMatchValueChange}
        options={matchOptions}
        disabled={disabled}
        name='matchvalue'
        styles={{
          container: (provided) => ({
            ...provided,
            width: '100%',
            maxWidth: '100%',
            display: 'inline-block',
            verticalAlign: 'middle',
          }),
        }}
      />
    );
  }

  const onRemove = () => {
    if (onRemoveClick) {
      onRemoveClick(index);
    }
  };

  const availableFieldOptions = availableFields
    .map((field) => ({ value: field.id, label: field.name }));

  return (
    <>
      <div className='logicconfig-condition'>
        <div>
          <Select
            options={availableFieldOptions}
            onChange={onSelectedFieldChange}
            disabled={disabled}
            value={availableFieldOptions.find(({ value }) => value === selectedFieldId)}
            menuShouldScrollIntoView={false}
            menuPosition='fixed'
            styles={{
              container: (provided) => ({
                ...provided,
                width: '100%',
                maxWidth: '100%',
                display: 'inline-block',
                verticalAlign: 'middle',
              }),
            }}
            name='source'
          />
        </div>

        <div>
          <Select
            value={comparatorOptions.find(({ value }) => value === comparator) ?? null}
            onChange={onComparatorChange}
            options={comparatorOptions}
            disabled={disabled}
            isSearchable={false}
            menuShouldScrollIntoView={false}
            menuPosition='fixed'
            styles={{
              container: (provided) => ({
                ...provided,
                width: '100%',
                maxWidth: '100%',
                display: 'inline-block',
                verticalAlign: 'middle',
              }),
            }}
            name='comparator'
          />
        </div>

        <div>
          {renderMatchValue()}
        </div>
        <FontAwesomeIcon
          css={css`
            opacity:0.5;
            color: #7C8285;
            &:hover {
              opacity:1;
            }
          `}
          icon={icon({ name: 'trash' })}
          className='clickable'
          title='Remove'
          onClick={onRemove}
        />
      </div>

      {!isLast && (
        <div className='logicconfig-connective'>
          <Select
            options={CONNECTIVES}
            onChange={onConnectiveChange}
            disabled={disabled}
            value={CONNECTIVES.find(({ value }) => value === connective) ?? null}
            isSearchable={false}
            menuShouldScrollIntoView={false}
            menuPosition='fixed'
            styles={{
              container: (provided) => ({
                ...provided,
                width: 'auto',
                display: 'inline-block',
                verticalAlign: 'middle',
              }),
              control: (provided) => ({
                ...provided,
                background: 'inherit !important',
              }),
              singleValue: (provided) => ({
                ...provided,
                color: '#00b9ad',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontSize: '0.83rem',
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                color: '#00b9ad',
              }),
            }}
            name='connective'
          />
        </div>
      )}

    </>
  );
};

LogicCondition.propTypes = {
  condition: PropTypes.object,
  index: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onRemoveClick: PropTypes.func,
  isLast: PropTypes.bool,
  availableFields: PropTypes.array,
};

export default LogicCondition;
