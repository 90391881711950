import FormData from 'form-data';
import {
  update,
  create,
  destroy,
  fetchMappables as apiFetchMappables,
  publish,
  unpublish,
  replace
} from 'apis/document-api';
import { isEmpty } from 'underscore';

export const submitConfig = (documentId, masterTemplateSlug, configuredMappings, name, startVersion, stopVersion, latest) => {

  const formData = new FormData();

  if (!isEmpty(configuredMappings)) {
    let index = 0;
    Object.entries(configuredMappings).forEach(([_key, value]) => {
      const mappingName = `master_template_document[document_field_mappings_attributes][${index}]`;
      if (value.mappingId) {
        formData.append(`${mappingName}[id]`, value.mappingId);
      }
      formData.append(`${mappingName}[field_name]`, value.fieldName);
      formData.append(`${mappingName}[mappable]`, value.mappable);

      index++;

    });
  }

  formData.append('master_template_document[name]', name);
  formData.append('master_template_document[start_version]', startVersion);
  formData.append('master_template_document[stop_version]', stopVersion);
  formData.append('master_template_document[main]', latest);

  return update(documentId, masterTemplateSlug, formData);
};

export const createDoc = (masterTemplateId, docFile) => {
  const formData = new FormData();
  formData.append('master_template_document[document]', docFile[0]);

  return create(masterTemplateId, formData);
};

export const replaceDoc = (documentId, masterTemplateId, docFile) => {
  const formData = new FormData();
  formData.append('master_template_document[document]', docFile[0]);

  return replace(documentId, masterTemplateId, formData);
};

export const fetchMappables = (documentId, masterTemplateId, query) => {
  return apiFetchMappables(documentId, masterTemplateId, query);
};

export const publishDocument = (masterTemplateId, documentId) => {
  return publish(masterTemplateId, documentId);
};

export const unpublishDocument = (masterTemplateId, documentId) => {
  return unpublish(masterTemplateId, documentId);
};

export const deleteDocument = (masterTemplateId, documentId) => {
  return destroy(masterTemplateId, documentId);
};
