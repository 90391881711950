import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const ManageableTable = ({ children }) => {
  return (
    <div className='manageabletable minimalcontentcontainer nopadding bg-white'>
      <div>
        {children}
      </div>
    </div>
  );
};

export const THead = ({ children }) => {
  return (
    <div className='manageabletable-rowheader'>
      {children}
      <TH />
    </div>
  );
};

export const TH = ({ id, children }) => {
  return (
    <div id={id}>
      {children}
    </div>
  );
};

export const TBody = ({ children }) => {
  return (
    <ul>
      {children}
    </ul>
  );
};

export const TD = (props) => {
  return (
    <div {...props} className={['manageabletable-rowdata', props.className].join(' ')}>
      {props.children}
    </div>
  );
};

export const TR = ({ children, onClick, expandedContent }) => {
  const [expanded, setExpanded] = useState(false);

  function rowClass() {
    let classes = 'manageabletable-row';

    if (false/* props.isLast */) {
      classes += ' last';
    }

    if (expanded) {
      classes += ' open';
    } else {
      classes += ' clickable';
    }

    return classes;
  }

  function closeExpansion(e) {
    e.stopPropagation();
    setExpanded(false);
  }

  function renderButton() {
    if (expanded) {
      return <span onClick={closeExpansion} className='icon flip-y icon-arrow-down open thin clickable' />;
    }

    return <span className='icon icon-arrow-down thin' />;
  }

  const renderExpandedContent = () => {
    return (
      <ExpandedSection>
        {expandedContent}
      </ExpandedSection>
    );
  };

  return (
    <li onClick={() => { setExpanded(true); }} className={rowClass()}>
      {children}

      <div className='manageabletable-rowdata align-right'>
        {renderButton()}
      </div>

      { expanded
        && renderExpandedContent()}
    </li>
  );
};

const ExpandedSection = ({ children }) => {
  return (
    <div className='manageabletable-expandedsection'>
      {children}
    </div>
  );
};

export default ManageableTable;
