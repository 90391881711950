import FormData from 'form-data';
import { isObject } from 'underscore';
import AlertConfirm from 'modules/alert-confirm';
import { checkForLogin } from 'modules/poll-for-login';

var CollectedDataAPI = {
  update: function(initiatedFlowId, stepDatumId, newValue, version){
    const url = `/initiated_flows/${initiatedFlowId}/collected_data/create_or_update`;
    const formData = new FormData();
    formData.append('step_datum_id', stepDatumId);
    formData.append('version', version);

    if(isObject(newValue)){
      newValue = JSON.stringify(newValue);
    }

    formData.append('content', newValue);

    const request = $.ajax(url, {
      data: formData,
      dataType: 'json',
      method: 'POST',
      processData: false,
      cache: false,
      contentType: false
    })
    .fail(checkForLogin);

    return request;
  },

  visualizationData: function(stepDatumId){
    var url = '/step_data/' + stepDatumId + '/collected_data/visualizable';
    var request = $.ajax(url, {
      dataType: 'json',
      method: 'GET'
    });

    return request;
  },

  deleteAttachedFile: function(fileId){
    var url = '/collected_datum/datum_files/' + fileId;
    var request = $.ajax(url, {
      dataType: 'json',
      method: 'DELETE'
    });

    return request;
  },

  s3Preauth: function(){
    var url = '/collected_datum/s3_preauth';
    var request = $.ajax(url, {
      dataType: 'json',
      method: 'POST'
    });

    return request;
  }
};

export default CollectedDataAPI;
