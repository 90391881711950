export function adminHost() {
  const domainParts = window.location.host.split('.');
  if (domainParts[0] === 'go') {
    domainParts[0] = 'admin';
  }

  return `${window.location.protocol}//${domainParts.join('.')}`;
}

export function publicHost() {
  const domainParts = window.location.host.split('.');
  if (domainParts[0] === 'admin') {
    domainParts[0] = 'go';
  }

  return `${window.location.protocol}//${domainParts.join('.')}`;
}

export default {
  publicHost,
  adminHost,
};
