const truncate = (string, maxlength) => {
  if (!string || string === '' || maxlength < 0) {
    return '';
  }
  if (string.length <= maxlength) return string;
  const substring = string.substring(0, maxlength);
  return `${substring}...`;
};

export default truncate;
