import React, { useState } from 'react';
import DiscussionContent from 'components/shared/discussion-content';
import PropTypes from 'prop-types';

const ReviewDiscussion = (props) => {
  const [addingNewDiscussion, setAddingNewDiscussion] = useState(false);

  function onBackButtonClick(e) {
    e.preventDefault();

    setAddingNewDiscussion(false);
  }

  function onNewDiscussionOpened() {
    setAddingNewDiscussion(false);
  }

  function renderBackButton() {
    return (
      <div className='text-center margin-top margin-bottom'>
        <a onClick={onBackButtonClick} href='#' className='secondary'>{'< Back'}</a>
      </div>
    );
  }

  return (
    <div id='discussion' className='review-discussion'>
      <DiscussionContent
        initiatedFlowId={props.initiatedFlowId}
        admin={props.admin}
        onNewDiscussionClick={() => { setAddingNewDiscussion(true); }}
        addingNewDiscussion={addingNewDiscussion}
        recentlyAddedDiscussionId={props.recentlyAddedDiscussionId}
        onDiscussionOpened={onNewDiscussionOpened}
        discussions={props.discussions}
      />

      {addingNewDiscussion && renderBackButton()}
    </div>
  );
};

ReviewDiscussion.propTypes = {
  initiatedFlowId: PropTypes.number.isRequired,
  admin: PropTypes.bool.isRequired,
  recentlyAddedDiscussionId: PropTypes.number,
};

export default ReviewDiscussion;
