import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import AdvancedFilterAPI from 'apis/advanced-filter-api';

const FilterRow = ({
  filter,
  onRowClick,
  onDeleteClick,
  isActiveRow,
  dispatch,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [displayName, setDisplayName] = useState(filter.name);
  const [newName, setNewName] = useState('');
  const rowClass = `load-filter-table-row ${isActiveRow ? 'active' : undefined}`;
  const showEditMode = isActiveRow && editMode;

  useEffect(() => {
    if (!isActiveRow) {
      setEditMode(false);
    }
  }, [isActiveRow]);

  const OnNameEdit = (e) => {
    const value = e.target.value;
    setNewName(value);
  };

  const ApplyNameEdit = () => {
    if (newName.length > 2) {
      AdvancedFilterAPI.rename(filter.id, newName)
        .done((res) => {
          dispatch({
            type: 'updateSavedFilter',
            value: res.filter,
          });
          setDisplayName(newName);
        })
    }

    setEditMode(false);
  };

  const renderFilterName = () => {
    return (
      <>
        {displayName}
        {filter.default && <em style={{ marginLeft: '4px' }}>(default)</em>}
        <FontAwesomeIcon
          className='load-filter-active-icon'
          icon={solid('pencil')}
          size='lg'
          onClick={() => setEditMode(true)}
        />
      </>
    );
  };

  const renderFilterNameEditMode = () => {
    return (
      <>
        <input type='text' defaultValue={displayName} onChange={OnNameEdit}/>
        <FontAwesomeIcon
          className='load-filter-active-icon'
          icon={solid('circle-check')}
          size='lg'
          onClick={ApplyNameEdit}
        />
      </>
    );
  };

  const renderDeleteConfirmation = () => {
    return (
      <>
        <div className='load-filter-table-cell' style={{ flexGrow: 1 }}>
          {`Are you sure you want to delete "${filter.name}" saved filter?`}
        </div>
        <div className='load-filter-table-cell' style={{ minWidth: '140px' }}>
          <button className='btn-secondary x-small' onClick={() => setDeleteMode(false)}>Cancel</button>
          <button className='btn-primary x-small margin-left' onClick={() => onDeleteClick(filter)}>Okay</button>
        </div>
      </>
    );
  };

  const renderMainContent = () => {
    return (
      <>
        <div className='load-filter-table-cell' style={{ flexGrow: 1 }}>
          {showEditMode ? renderFilterNameEditMode() : renderFilterName()}
        </div>
        <div className='load-filter-table-cell' style={{ flexBasis: '160px' }}>
          {dayjs(filter.created_at).format('MM/DD/YYYY hh:mm a')}
        </div>
        <div className='load-filter-table-cell' style={{ flexBasis: '65px' }}>
          <FontAwesomeIcon
            className='load-filter-active-icon'
            icon={solid('trash')}
            size='lg'
            onClick={() => setDeleteMode(true)}
          />
        </div>
      </>
    );
  };


  return (
    <div className={rowClass} onClick={() => onRowClick(filter)}>
      {deleteMode ? renderDeleteConfirmation() : renderMainContent()}
    </div>
  );
};

export default FilterRow;