import React, { useState, useEffect } from 'react';
import Role from 'components/roles/role';
import RoleActions from 'actions/role-actions';
import {
  findIndex, reject, findWhere, some,
} from 'underscore';
import RoleRow from 'components/roles/role-row';
import ManageableTable, { THead, TH, TBody } from 'components/shared/manageable-table';
import { HashRouter, Route, useHistory } from 'react-router-dom';
import { Switch } from 'react-router';

const RolesRouter = (props) => {
  const renderer = (routerProps) => {
    let prps = { ...props };
    if (routerProps.match.params.id) {
      const roleId = routerProps.match.params.id;
      const role = findWhere(props.roles, { id: parseInt(roleId) });
      prps = { ...prps, role };
    }

    return (<RolesContainer {...routerProps} {...prps} />);
  };

  return (
    <HashRouter>
      <Switch>
        <Route exact path='/' render={renderer} />
        <Route path='/:id/edit' render={renderer} />
        <Route path='/new' render={renderer} />
      </Switch>
    </HashRouter>
  );
};

const RolesContainer = (props) => {
  const [roles, setRoles] = useState(props.roles);
  const [defaultName, setDefaultName] = useState(props.roleName ? props.roleName : '');
  const [role, setRole] = useState(props.role ? props.role : null);

  const history = useHistory();

  useEffect(() => {
    // On first load, if there's a refferal link, it goes to new
    // but if there's a referal link and they've gone to edit a different role, and refreshed, dont do anything
    if (props.referrerLink && !props.role) {
      history.replace('/new');
    }
  }, []);

  const newRole = {
    name: defaultName,
    team_manager: false,
    finance_manager: false,
    finance_member: false,
    workflow_creator: false,
    member_mgmt_level: 'anyone',
    role_type: 'custom',
  };

  function userIsOwner() {
    const ownerRole = findWhere(roles, { role_type: 'owner' });

    return some(ownerRole.users, (user) => {
      return user.id === props.currentUserId;
    });
  }

  function onAttachedPeopleChange(attachment, roleId) {
    const updatedRoles = [...roles];
    const roleIndex = findIndex(updatedRoles, (r) => { return r.id == roleId; });

    updatedRoles[roleIndex].users = updateAttachedPeople(attachment, updatedRoles[roleIndex].users);
    setRoles(updatedRoles);
  }

  function updateAttachedPeople(changedPerson, attachments) {
    if (changedPerson.persisted) {
      attachments.push(changedPerson);
      return attachments;
    }

    return reject(attachments, (p) => {
      return p.id === changedPerson.id;
    });
  }

  function onRoleCreate(createdRole) {
    setRoles([createdRole, ...roles]);

    return createdRole;
  }

  function onRoleUpdate(updatedRole) {
    const updatedRoles = [...roles];

    const roleToUpdate = findIndex(updatedRoles, (r) => { return updatedRole.id == r.id; });
    updatedRoles[roleToUpdate] = updatedRole;

    setRoles(updatedRoles);

    return updatedRole;
  }

  function onSave(savedRole, addUserToRole) {
    if (savedRole.id) {
      return RoleActions.update(savedRole, props.teamId, addUserToRole)
                        .done((response) => { onRoleUpdate(response); });
    }

    return RoleActions.create(savedRole, props.teamId, addUserToRole)
                      .done((response) => { onRoleCreate(response); });
  }

  function onCancel() {
    setRole(null);
    history.push('/');
  }

  function onNewClick() {
    setDefaultName('');
    history.push('/new');
  }

  function onEdit(r) {
    setRole(r);
    history.push(`/${r.id}/edit`);
  }

  function renderRole() {
    const addingNew = !role;
    const key = addingNew ? 'new' : role.id;
    return (
      <Role
        key={key}
        newRole={addingNew}
        role={addingNew ? newRole : role}
        userIsOwner={userIsOwner()}
        onAttachedPeopleChange={onAttachedPeopleChange}
        onSave={onSave}
        onNewClosed={() => { setAddingNew(false); }}
        onCancel={onCancel}
      />
    );
  }

  function renderRolesList() {
    return roles.map((r, i) => {
      const isLast = roles.length - 1 === i;
      return (
        <RoleRow
          role={r}
          key={r.id}
          onEdit={() => { onEdit(r); }}
          referrerLink={props.referrerLink}
          userIsOwner={userIsOwner()}
          isLast={isLast}
          onAttachedPeopleChange={onAttachedPeopleChange}
        />
      );
    });
  }

  function renderRefererLink() {
    return <div className='fullwidth align-center padded'><a className='btn-secondary' href={props.referrerLink}>{'< Back to what I was working on'}</a></div>;
  }

  function renderRolesIndex() {
    return (
      <ManageableTable>
        <div className='role-indexheader'>
          <h2 className='inline-block nomargin-bottom'>Roles</h2>
          <a target='__blank' href='https://help.citygrows.com/en/articles/5655275-how-can-i-manage-administrative-access-for-individuals-and-groups-on-citygrows' className='secondary margin-left'> Learn about roles</a>
          <button type='button' onClick={onNewClick} className='btn-link strong float-right'>+ Add New Role</button>
        </div>
        <THead>
          <TH id='role-name'>
            Role Name
          </TH>
          <TH id='role-members'>
            Members
          </TH>
          <TH id='role-permissions'>
            Permissions
          </TH>
        </THead>

        <TBody>
          {renderRolesList()}
        </TBody>
      </ManageableTable>
    );
  }

  function renderMainContent() {
    return (
      <Switch>
        <Route exact path='/' render={() => renderRolesIndex()} />
        <Route path={['/:id/edit', '/new']} render={() => renderRole()} />
      </Switch>
    );
  }

  return (
    <>
      {props.referrerLink && renderRefererLink()}
      {renderMainContent()}
    </>
  );
};

export default RolesRouter;
