import React from 'react';
import LeftMenu from 'components/shared/left-menu';

const FinanceSubnav = (props) => {
  function renderManager() {
    return (
      <li>
        <a
          href={`/teams/${props.teamSlug}/merchant_accounts/manage`}
          className={props.activeLink === 'manage' ? 'active' : ''}
        >
          Payment Processors
        </a>
      </li>
    );
  }

  function renderPayoutPreferences() {
    return (
      <li>
        <a
          href={`/teams/${props.teamSlug}/merchant_accounts`}
          className={props.activeLink === 'payouts' ? 'active' : ''}
        >
          Payout Preferences
        </a>
      </li>
    );
  }

  return (
    <div className='finance-leftnav'>
      <LeftMenu>
        {false && renderManager()}
        {window.CityGrows.Server.isFinanceManager === true && renderPayoutPreferences()}
        <li>
          <a
            href={`/teams/${props.teamSlug}/transactions`}
            className={props.activeLink === 'transactions' ? 'active' : ''}
          >
            Transactions
          </a>
        </li>
        <li>
          <a
            href={`/teams/${props.teamSlug}/transactions/cash_and_checks`}
            className={props.activeLink === 'cash_and_checks' ? 'active' : ''}
          >
            Cash & Checks
          </a>
        </li>
        <li>
          <a
            href={`/teams/${props.teamSlug}/charge_disputes`}
            className={props.activeLink === 'disputes' ? 'active' : ''}
          >
            Charge Disputes
          </a>
        </li>
      </LeftMenu>
    </div>
  );
};

export default FinanceSubnav;
