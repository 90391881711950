import keyMirror from 'keymirror'

export default keyMirror({
  ENTITY_UPDATED: null,
  CUSTOM_ADDED: null,
  CUSTOM_UPDATED: null,
  CUSTOM_REMOVED: null,
  CUSTOM_ENTITY_CREATED: null,
  CUSTOM_ENTITY_ARCHIVED: null,
}, 'ENTITY_CONFIGURATION');
