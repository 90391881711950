import React from 'react';
import titleize from 'modules/titleize';

const EntityField = ({
  field,
  newEntity,
  editing,
  entity,
  fieldState,
  dispatch,
}) => {
  return (
    <li key={field.slug}>
      <label>
        {titleize(field.name)}
        {(newEntity || (editing && (entity.admin || field.edit_suggestable))) ? (
          <input
            key={`edit_${field.slug}`}
            type='text'
            value={fieldState[field.slug]}
            onChange={(e) => {
              dispatch({ type: 'update', field: field.slug, value: e.target.value });
            }}
            name={`entity-${field.slug}`}
          />
        )
          : (
            <input
              key={`read_${field.slug}`}
              type='text'
              readOnly
              tabIndex='-1'
              value={field.value}
            />
          )}
      </label>
    </li>
  );
};

export default EntityField;
