import React, {
  useRef, useState, useEffect, useCallback,
} from 'react';
import Popdown from 'components/shared/popdown';
import QuicklaunchActions from 'actions/quicklaunch-actions';
import LoadingSpinner from 'components/shared/loading-spinner';
import truncate from 'truncate';
import { filter, isEmpty } from 'underscore';
import useOnclickOutside from 'react-cool-onclickoutside';

const NO_TEMPLATES_CONTENT = (
  <div>No forms available</div>
);

const Quicklaunch = () => {
  const innerContentEl = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [masterTemplates, setMasterTemplates] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const inputEl = useRef();
  const elForAnchor = useRef();

  useOnclickOutside(() => {
    setIsOpen(false);
  }, { refs: [elForAnchor], ignoreClass: 'popdown' });

  const onMasterTemplatesLoaded = useCallback((res) => {
    setMasterTemplates(res.master_templates);

    // If we queued a request to refresh the data
    // set it when it loads.

    if (res.loadRequest) {
      res.loadRequest.done(onMasterTemplatesLoaded);
    }
  }, []);

  useEffect(() => {
    if (masterTemplates || !isOpen) { return; }

    QuicklaunchActions.loadAndCacheMasterTemplates()
                      .done(onMasterTemplatesLoaded);
  }, [isOpen, masterTemplates, onMasterTemplatesLoaded]);

  useEffect(() => {
    if (isOpen && inputEl.current) { inputEl.current.select(); }
  }, [isOpen]);

  const onSearchChange = (e) => {
    setSearchTerm(e.currentTarget.value);
  };

  const filteredMasterTemplates = () => {
    return filter(masterTemplates, (mt) => {
      return mt.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
  };

  const renderQuicklaunchContent = () => {
    return (
      <>
        <div className='quicklaunch-search'>
          <input onChange={onSearchChange} ref={inputEl} className='fullwidth margin-bottom' type='text' placeholder='Filter forms' />
        </div>
        {renderTemplateContent()}
      </>
    );
  };

  const renderTemplateContent = () => {
    if (isEmpty(masterTemplates)) {
      return NO_TEMPLATES_CONTENT;
    }
    return (
      <ul className='quicklaunch-list'>
        {filteredMasterTemplates().map(renderMasterTemplate)}
      </ul>
    );
  };

  const renderMasterTemplate = (mt) => {
    const launchButtonPath = `/initiated_flows/manager?flow_template_id=${mt.flow_template_id}&primary_applicant_id=${CityGrows.Server.currentUserId}`;

    return (
      <li key={`mt-${mt.id}`}>
        <div title={mt.name}>
          {truncate(mt.name, 35)}
          <span title={mt.team_name} className='color-text-medium'>
            ({truncate(mt.team_name, 25)})
          </span>
        </div>
        <a href={launchButtonPath} data-method='POST' className='btn btn-primary small'>Start</a>
      </li>
    );
  };

  const renderLoading = () => {
    return <div className='align-center'><LoadingSpinner size='medium' className='vmiddle margin-top' /></div>;
  };

  const renderPopdown = () => {
    if (!isOpen) { return; }

    return (
      <Popdown anchorRef={elForAnchor} justify='right' className='quicklaunch-popdown'>
        <div className='quicklaunch-content' ref={innerContentEl}>
          {!masterTemplates ? renderLoading() : renderQuicklaunchContent()}
        </div>
      </Popdown>
    );
  };

  const buttonClass = () => {
    if (!isOpen) { return ''; }

    return 'quicklaunch-active';
  };

  return (
    <>
      <button
        type='button'
        className={buttonClass()}
        title='Quick launch'
        ref={elForAnchor}
        onClick={() => { setIsOpen(!isOpen); }}
      >
        <span>+</span>
      </button>

      {renderPopdown()}
    </>
  );
};

export default Quicklaunch;
