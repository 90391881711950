import PropTypes from 'prop-types';
import React from 'react';
import truncate from 'truncate';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import pluralize from 'modules/pluralize';

dayjs.extend(localizedFormat);

const LaunchInfo = (props) => {
  const renderTimestamp = () => {
    return dayjs(props.initiatedFlow.created_at).format('l');
  };

  const renderIdentifier = () => {
    if (!props.initiatedFlow.identifier_field_value) { return; }

    return (
      <span
        title={props.initiatedFlow.identifier_field_value}
      >
        {truncate(props.initiatedFlow.identifier_field_value, 75)}
      </span>
    );
  };

  const getApplicantText = () => {
    const text = props.initiatedFlow.primary_applicant.name;
    const otherApplicantsCount = props.initiatedFlow.applicants_count - 1;
    if (otherApplicantsCount > 0) {
      return `${text} and ${otherApplicantsCount} ${pluralize('other', otherApplicantsCount, { many: 'others' })}`;
    }
    return text;
  };

  return (
    <>
      <li>
        <div>Applicant</div>
        <div>{getApplicantText()}</div>
      </li>

      {!!props.initiatedFlow.identifier_field_value && (
        <li>
          <div>Identifier</div>
          <div>{renderIdentifier()}</div>
        </li>
      )}

      <li>
        <div>Started</div>
        <div>{renderTimestamp()}</div>
      </li>
    </>
  );
};

LaunchInfo.propTypes = {
  flowTemplateId: PropTypes.number.isRequired,
  initiatedFlow: PropTypes.object.isRequired,
  admin: PropTypes.bool.isRequired,
};

export default LaunchInfo;
