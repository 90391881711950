import PropTypes from 'prop-types';
import {
  contains, isEmpty, filter, findWhere, any, intersection,
} from 'underscore';
import React, {
  useState, useCallback, useContext,
} from 'react';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import AttachmentActions from 'actions/attachment-actions';
import { error } from 'components/shared/flash';
import AttachedPerson from './attached_people/attached-person';
import AttachPerson from './attached_people/attach-person';
import RoleSearch from './attached_people/role-search';

const NO_USERS_ADDED_CONTENT = <div className='well text-center'>No users added</div>;
const NO_ROLES_ADDED_CONTENT = <div className='well text-center'>No roles added</div>;

const AttachedPeople = (props) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);
  const attachedPeople = useCallback(() => {
    return filter(props.people, (attachment) => { return attachment.type === 'User'; });
  }, [props.people]);

  const attachedRoles = useCallback(() => {
    return filter(props.people, (a) => { return a.type === 'AdminRole'; });
  }, [props.people]);

  const [attachment, setAttachment] = useState();
  const [legacyPersonAddOpen, setLegacyPersonAddOpen] = useState(any(attachedPeople()));

  const onToggleEvent = useCallback((res) => {
    if (!contains(['Step', 'ReviewGroup'], props.attachableType)) { return; }

    AttachmentActions.dispatchTogglePersonEvent(props.attachableType, props.attachableId, res);
  }, [props.attachableId, props.attachableType]);

  const onAttachmentPersisted = useCallback((data) => {
    setAttachment();

    if (props.onChange) {
      props.onChange(data);
    }
  }, [props]);

  const onPersonAttachmentPersisted = useCallback((data) => {
    onToggleEvent(data);

    if (props.onChange) {
      props.onChange(data);
    }
  }, [props]);

  const roleIsAttached = useCallback((id) => {
    return findWhere(attachedRoles(), { id });
  }, [attachedRoles]);

  function roleUsersAlreadyAttached() {
    const roleUserIds = attachment.users.map((u) => { return u.id; });
    const directAttachedUserIds = props.people.map((p) => {
      if (p.type === 'User') {
        return p.id;
      }

      return null;
    });

    return intersection(roleUserIds, directAttachedUserIds);
  }

  const attachViaRole = useCallback(() => {
    if (roleIsAttached(attachment.role.id)) {
      return AttachmentActions
        .togglePersonViaAttachedRole(
          props.attachableId,
          props.attachableType,
          attachment.role.id,
          attachment.id,
        )
        .done(onAttachmentPersisted);
    }

    return AttachmentActions.toggleRole(
      props.attachableId,
      props.attachableType,
      attachment.role.id,
      attachment.id,
      { userToAdd: props.tentativeAssignment },
    )
      .done((res) => {
        onToggleEvent(res);
        onAttachmentPersisted(res);
      });
  }, [
    attachment?.id,
    attachment?.role?.id,
    onAttachmentPersisted,
    onToggleEvent,
    props.attachableId,
    props.attachableType,
    props.tentativeAssignment,
    roleIsAttached,
  ]);

  const attachPersonHandler = useCallback((userAttachment) => {
    if (userAttachment.isPseudo) {
      if (props.tentativeAssignment) {
        return AttachmentActions
                 .addPseudomemberTentative(userAttachment.email, userAttachment.name, props.attachableId)
                 .done((res) => {
                   AttachmentActions.onTentativeDone(dispatch);
                   onAttachmentPersisted(res);
                 });
      }

      const roleId = userAttachment.role ? userAttachment.role.id : null;
      const options = { roleId };

      return AttachmentActions
               .addPseudomember(userAttachment.email, userAttachment.name, props.attachableType, props.attachableId, options)
               .done(onPersonAttachmentPersisted);
    }

    if (userAttachment.role) {
      return attachViaRole();
    }

    if (props.tentativeAssignment) {
      return AttachmentActions.toggleTentativePerson(props.attachableId, userAttachment.id)
       .done(() => AttachmentActions.onTentativeDone(dispatch));
    }

    return AttachmentActions
             .togglePerson(props.attachableId, props.attachableType, userAttachment.id, 'User')
             .done(onPersonAttachmentPersisted);
  }, [
    attachViaRole,
    dispatch,
    onAttachmentPersisted,
    onPersonAttachmentPersisted,
    onToggleEvent,
    props.attachableId,
    props.attachableType,
    props.tentativeAssignment,
  ]);

  const togglePerson = useCallback((newPerson) => {
    return attachPersonHandler(newPerson);
  }, [attachPersonHandler]);

  function togglePersonRoleAttachment(newAttachment) {
    setAttachment(newAttachment);
  }

  function cleanUpDuplicates(duplicateAttachments) {
    duplicateAttachments.forEach((userId) => {
      AttachmentActions
        .dispatchTogglePersonEvent(props.attachableType, props.attachableId, {
          id: userId, persisted: false,
        });
    });
  }

  function attach() {
    switch (attachment.type) {
    case 'AdminRole': {
      const duplicateAttachments = roleUsersAlreadyAttached();
      return AttachmentActions.toggleRole(props.attachableId, props.attachableType, attachment.id)
          .done((res) => {
            onToggleEvent(res);
            cleanUpDuplicates(duplicateAttachments);
            onAttachmentPersisted(res);
          });
    }
    case 'User':
      return attachPersonHandler(attachment);
    default:
      throw new Error(`Unhandled attachmentType: '${attachment.type}'`);
    }
  }

  const onToggleOff = useCallback((attchmnt) => {
    if (props.tentativeAssignment) {
      AttachmentActions
        .removeTentativePerson(attchmnt.attachment_id)
        .done((res) => {
          AttachmentActions.onTentativeDone(dispatch);
          props.onChange(res);
        })
        .fail((res) => {
          error(res.responseJSON.error);
        });
    } else {
      AttachmentActions.removePerson(attchmnt.attachment_id, props.attachableType)
        .done((res) => {
          onToggleEvent(res);
          props.onChange(res);
        })
        .fail((res) => {
          error(res.responseJSON.error);
        });
    }
  }, [onToggleEvent, props]);

  function buildPerson(attchmnt) {
    const isDisabled = props.disabled || contains(props.lockedUserIds, attchmnt.id);
    let { showEmail } = props;

    if (attchmnt.type === 'AdminRole') {
      showEmail = false;
    }

    return (
      <AttachedPerson
        key={attchmnt.id}
        person={attchmnt}
        showEmail={showEmail}
        onDeleteClick={onToggleOff}
        disabled={isDisabled}
      />
    );
  }

  function renderPersonAdd() {
    return (
      <AttachPerson
        attachableId={props.attachableId}
        attachableType={props.attachableType}
        pseudoNeedsRole={props.pseudoNeedsRole}
        referrer={props.referrer}
        allowPseudo={props.allowPseudo}
        tentativeAssignment={props.tentativeAssignment}
        attachedRoles={attachedRoles()}
        togglePerson={togglePerson}
        togglePersonViaRole={togglePersonRoleAttachment}
        canAttachRoles={props.canAttachRoles}
        hideExternal={props.hideExternal}
        teamSlug={props.teamSlug}
        teamId={props.teamId}
        idForInput={props.idForInput}
        searchOpts={props.searchOpts}
      />
    );
  }

  function renderPeopleList() {
    if (any(attachedPeople())) {
      const people = attachedPeople().map(buildPerson);
      return (
        <ul className='attachedpeople-list'>
          {people}
        </ul>
      );
    }

    return NO_USERS_ADDED_CONTENT;
  }

  function renderRolesList() {
    const roles = attachedRoles();
    if (isEmpty(roles)) {
      return NO_ROLES_ADDED_CONTENT;
    }

    const rolesList = roles.map(buildPerson);

    return (
      <div>
        <ul className='attachedpeople-list'>
          {rolesList}
        </ul>
      </div>
    );
  }

  function displayRoleUser(user) {
    return <li>{user.name} - {user.email}</li>;
  }

  function roleUsers() {
    if (attachment.users.length === 0) { return 'This role has no users'; }
    const willRemoveDirectAttachments = roleUsersAlreadyAttached().length > 0;

    return (
      <div className='margin-bottom'>
        {willRemoveDirectAttachments && (
          <>
            <div className='padding-bottom-less'><span className='bold'>Note:</span>
              Some of this role&aposs users are already directly assigned as reviewers. Attaching this role will replace their direct assignment.
            </div>
            <br />
          </>
        )}
        This will attach the following users:
        <ul className='attachedpeople-list margin-top'>
          {attachment.users.map(displayRoleUser)}
        </ul>
      </div>
    );
  }

  function userConfirmation() {
    const actionText = attachment.isPseudo ? 'Inviting and attaching' : 'Attaching';
    if (attachment.role && (roleIsAttached(attachment.role.id) || attachment.role.users.length == 0)) {
      return (
        <div className='margin-bottom'>
          {actionText} {attachment.name}({attachment.email}) via {attachment.role.name} role
        </div>
      );
    }

    if (attachment.role && attachment.role.users.length > 0) {
      return (
        <div className='margin-bottom'>
          <div className='margin-bottom'>
            {actionText} {attachment.name}({attachment.email}) via {attachment.role.name} role.
            This will also attach the following users:
          </div>

          <ul className='attachedpeople-list'>
            {attachment.role.users.map(displayRoleUser)}
          </ul>
        </div>
      );
    }

    const email = attachment.type === 'User' ? ` (${attachment.email})` : '';
    return (
      <div className='margin-bottom'>
        {actionText} {attachment.name}{email}
      </div>
    );
  }

  function confirmAttachmentText() {
    switch (attachment.type) {
    case 'AdminRole':
      return (
        <div className='well'>
          <span className='strong'>Attaching {attachment.name} Role</span>
          <div className='padding-top'>
            {roleUsers()}
          </div>
        </div>
      );
    case 'User':
      return userConfirmation();
    default:
      throw new Error(`Unrecognized attachment type ${attachment.type}`);
    }
  }

  function confirmAttachment() {
    return (
      <>
        {confirmAttachmentText()}
        <div className='margin-top'>
          <button type='button' id='attach' className='btn-primary margin-right' onClick={attach}>Attach</button>
          <button type='button' className='btn-link' onClick={() => { return setAttachment(); }}>Cancel</button>
        </div>
      </>
    );
  }

  function renderLegacyPersonAdd() {
    if (legacyPersonAddOpen) {
      return (
        <>
          <div className='linebreak' />
          <div>
            <label className='inline-block bold'>Add people</label>
            {renderPersonAdd()}
          </div>

          <div>
            {renderPeopleList()}
          </div>
        </>
      );
    }

    return <button className='btn-link margin-top-more' onClick={() => { return setLegacyPersonAddOpen(true); }}>Need to add an individual person?</button>;
  }

  function renderRoleSelector() {
    return (
      <div>
        <label className='bold'>Add roles (Recommended)</label>
        <RoleSearch
          teamId={props.teamId}
          teamSlug={props.teamSlug}
          toggleRole={(role) => { return setAttachment({ ...role, type: 'AdminRole' }); }}
          referrer={props.referrer}
          rolesToFilter={attachedRoles()}
        />
      </div>
    );
  }

  function renderRolesWidget() {
    return (
      <>
        {!props.disabled && renderRoleSelector()}
        {renderRolesList()}
        {!props.disabled && renderLegacyPersonAdd()}
      </>
    );
  }

  function renderPersonOnlyWidget() {
    return (
      <>
        {!props.disabled && renderPersonAdd()}
        {renderPeopleList()}
      </>
    );
  }

  function renderMainContent() {
    if (attachment) { return confirmAttachment(); }

    if (props.canAttachRoles) { return renderRolesWidget(); }

    return renderPersonOnlyWidget();
  }

  return (
    <div className='attachedpeople'>
      {renderMainContent()}
    </div>
  );
};

AttachedPeople.propTypes = {
  allowPseudo: PropTypes.bool,
  attachableId: PropTypes.number.isRequired,
  attachableType: PropTypes.string.isRequired,
  canAttachRoles: PropTypes.bool,
  disabled: PropTypes.bool,
  hideExternal: PropTypes.bool,
  idForInput: PropTypes.string,
  lockedUserIds: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  people: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pseudoNeedsRole: PropTypes.bool,
  referrer: PropTypes.shape({}).isRequired,
  searchOpts: PropTypes.shape({}),
  showEmail: PropTypes.bool,
  teamId: PropTypes.number,
  teamSlug: PropTypes.string.isRequired,
  tentativeAssignment: PropTypes.bool,
};

AttachedPeople.defaultProps = {
  lockedUserIds: [],
  hideExternal: true,
  tentativeAssignment: false,
  searchOpts: { govOnly: true },
  disabled: false,
  canAttachRoles: false,
  onChange: () => {},
  idForInput: '',
};

export default AttachedPeople;
