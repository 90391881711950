import React from 'react';
import DataBox from '../data-box';

const TopRow = ({transactionData}) => {
  function formattedNumber(number) {
    return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number)
  }

  return (
    <div className='metricsdashboard-toprow border-top'>
      <DataBox label='Total Revenue Collected' number={`${formattedNumber(transactionData.total_revenue)}`}>
        <div>Excludes cash and checks</div>
      </DataBox>
      <DataBox label='Renewal Revenue' number={`${formattedNumber(transactionData.renewal_revenue)}`}/>
      <DataBox label='Refunded' number={`${formattedNumber(transactionData.total_refunded)}`}/>
      <DataBox label='Disputes' number={`${formattedNumber(transactionData.total_disputed)}`}/>
    </div>
  );
};

export default TopRow;
