import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ThirdPartyReviewPersonSelector from './third-party-review-person-selector';
import SpecificPeopleReview from './specific-people-review';

class ActiveReviewGroup extends Component {
  renderReviewTypeInfo = () => {
    switch (this.props.reviewGroup.review_type) {
    case 'specific_people':
      return this.renderSpecificPeopleReviewInfo();
    case 'third_party':
      return this.renderThirdPartyReviewInfo();
    default:
      throw (`Can't render review info for unknown review type ${this.props.reviewGroup.review_type}`);
    }
  };

  renderSpecificPeopleReviewInfo = () => {
    return this.props.admin ? this.renderAdminView()
      : <div>{this.renderConstituentView()}</div>;
  };

  renderConstituentView = () => {
    if (this.props.reviewGroup.completed) {
      return 'Complete';
    }
    return 'Not reviewed yet';
  };

  renderAdminView = () => {
    const logicWord = (this.props.reviewGroup.logic_type === 'and' ? 'All' : 'Any');

    return (
      <div>
        <div className='margin-bottom'>{logicWord} of the following reviewers must approve</div>
        <SpecificPeopleReview
          currentUserId={this.props.currentUserId}
          activeReviewGroup={this.props.reviewGroup}
          initiatedFlow={this.props.initiatedFlow}
          stepId={this.props.stepId}
          onOpenDiscussionClick={this.props.onOpenDiscussionClick}
        />
      </div>
    );
  };

  renderThirdPartyReviewInfo = () => {
    return (
      <div>
        <ThirdPartyReviewPersonSelector
          reviewGroupId={this.props.reviewGroup.review_group_id}
          activeStepId={this.props.reviewGroup.active_step_id}
          defaultReviewRequest={this.props.reviewGroup.review_request}
          initiatedFlowId={this.props.initiatedFlow.id}
        />
      </div>
    );
  };

  renderStatus = () => {
    let innerHTML;

    const { completed } = this.props.reviewGroup;

    if (completed) {
      innerHTML = <span className='color-success'><i className='icon-check-green' style={{ position: 'relative', marginRight: '5px', bottom: '1px' }} />Completed</span>;
    } else {
      innerHTML = 'Pending';
    }

    return (
      <div className={`reviewgroup-status ${completed ? 'success' : 'alert'}`}>
        {innerHTML}
      </div>
    );
  };

  render() {
    const optional = !this.props.reviewGroup.required
      ? <span className='margin-left color-text-medium text-smaller'>Optional</span>
      : null;

    return (
      <div className='raised padded bg-grey margin-bottom'>
        <div className='subtitle'>{this.props.reviewGroup.name_or_default}</div> {optional}
        {this.renderStatus()}
        {this.renderReviewTypeInfo()}
      </div>
    );
  }
}

ActiveReviewGroup.propTypes = {
  admin: PropTypes.bool.isRequired,
  currentUserId: PropTypes.number.isRequired,
  initiatedFlow: PropTypes.object.isRequired,
  reviewGroup: PropTypes.object.isRequired,
  stepId: PropTypes.number.isRequired,
  onOpenDiscussionClick: PropTypes.func.isRequired,
};

export default ActiveReviewGroup;
