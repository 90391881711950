/*
  Note January 2021

  This component replaces the older and crappier popup-button.jsx
*/

import { useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

const Popdown = ({
  anchorRef,
  justify,
  className,
  scrollableElementId,
  scrollAgainstWindow,
  stretchAgainstAnchor,
  topOffset,
  children,
}) => {
  const el = useMemo(() => {
    return document.createElement('div');
  }, []);

  const setPosition = useCallback(() => {
    // TODO: rename to anchorRef?
    const coords = anchorRef.current.getBoundingClientRect();
    el.style.top = `${coords.top + coords.height + topOffset}px`;

    let offset;
    switch (justify) {
    case 'left':
      offset = coords.left;
      break;
    case 'right':
      offset = window.innerWidth - coords.right;
      break;
    default:
      throw Error('Justify must be either left or right');
    }

    if (stretchAgainstAnchor) {
      el.style.width = `${coords.width}px`;
    }

    el.style[justify] = `${offset}px`;
  }, [anchorRef, el, justify]);

  const scrollableElement = useMemo(() => {
    if (!scrollableElementId) { return null; }
    return document.getElementById(scrollableElementId);
  }, [scrollableElementId]);

  useEffect(() => {
    window.addEventListener('resize', setPosition);

    if (scrollAgainstWindow) {
      window.addEventListener('scroll', setPosition);
    }

    if (scrollableElement) {
      scrollableElement.addEventListener('scroll', setPosition);
    }

    setPosition();

    return () => {
      window.removeEventListener('resize', setPosition);

      if (scrollAgainstWindow) {
        window.removeEventListener('scroll', setPosition);
      }

      if (scrollableElement) {
        scrollableElement.removeEventListener('scroll', setPosition);
      }
    };
  }, [setPosition]);

  useEffect(() => {
    el.classList.add('popdown');
    if (className) {
      className.split(' ').forEach((fragment) => { el.classList.add(fragment); });
    }
  }, [el, className]);

  useEffect(() => {
    if (!document.body.contains(el)) {
      document.body.appendChild(el);
    }
    return () => {
      // TODO: does this just remove it from the DOM,
      // or does this also destroy the element?
      // This might need to be a ref.
      el.remove();
    };
  }, [el]);

  return createPortal(children, el);
};

Popdown.propTypes = {
  anchorRef: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  justify: PropTypes.string,
  children: PropTypes.node,
  scrollableElementId: PropTypes.string,
  scrollAgainstWindow: PropTypes.bool,
  stretchAgainstAnchor: PropTypes.bool,
  topOffset: PropTypes.number,
};

Popdown.defaultProps = {
  justify: 'left',
  topOffset: 10,
};

export default Popdown;
