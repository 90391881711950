import React, { useState } from 'react';
import Select from 'components/shared/select';
import { findWhere} from 'underscore';

const EntityImport = ({options}) => {
  const [entity, setEntity] = useState(null);

  function optionsForSelect () {
    return options.map((option) => { return {value: option.id, label: option.name } })
  }

  function onValueChange ({ value }) {
    setEntity(value)
  }
 return (
  <div>
    <Select 
      options={optionsForSelect()}
      onChange={onValueChange}
      value={findWhere(optionsForSelect(), { value: entity })}
    />
    {entity && <a className='btn-primary margin-top' href={`entity_imports/new?entity=${entity}`}>Next</a> }
  </div>
 )
}

export default EntityImport