import PropTypes from 'prop-types';
import React from 'react';
import AddressValidatorAPI from 'apis/address-validator-api';
import CloseIfOutsideWrapper from 'components/shared/close-if-outside-wrapper';
import SmartyStreetsClient from 'modules/smarty-streets-client';
import _ from 'underscore';
import AutocompleteSuggestions from './autocomplete-suggestions';
import AddressOption from './address-option';

class AddressValidator extends React.Component {
  constructor(props) {
    super(props);

    this.input = React.createRef();

    this.getSuggestions = _.debounce(this.getSuggestions, 250);

    this.state = {
      addressSuggestions: [],
      validatedAddresses: [],
      userInputtedAddress: this.props.defaultValue,
      errors: null,
      searched: false,
      fetching: false,
      autocompleteRequestNumber: 0,
    };
  }

  componentDidMount() {
    this.focusInputAndSelect();
    this.smartyStreetsClient = new SmartyStreetsClient();
  }

  focusInputAndSelect = () => {
    this.input.focus();
    this.input.select();
  };

  onClose = () => {
    this.props.handleCancel();
  };

  hasValidatedAddresses() {
    return this.state.validatedAddresses.length > 0;
  }

  handleAddressSelect = (address) => {
    this.setState({ userInputtedAddress: address }, () => this.validateAddress());
  };

  handleKeyPress = (key, address) => {
    if (key === 'Enter') {
      this.handleAddressSelect(address);
    }
  };

  getSuggestions(requestNumber) {
    this.smartyStreetsClient.getSuggestions(this.state.userInputtedAddress)
      .then((res) => { this.resolveAddressSuggestions(res, requestNumber); })
      .catch(this.handleSuggestionError);
  }

  handleChange = () => {
    const requestNumber = this.state.autocompleteRequestNumber + 1;

    this.setState({ userInputtedAddress: this.input.value, autocompleteRequestNumber: requestNumber }, this.getSuggestions(requestNumber));
  };

  handleSuggestionError = (res) => {
    if (CityGrows.Server.env == 'development') {
      console.log(res.error);
    } else {
      Rollbar.error('AddressSuggestionsError', res.error);
    }
  };

  goBack = (e) => {
    e.preventDefault();

    this.setState({
      addressSuggestions: [],
      validatedAddresses: [],
      errors: null,
      searched: false,
      fetching: false,
    }, this.focusInputAndSelect);
  };

  validateAddress() {
    this.setState({ fetching: true, addressSuggestions: [] });
    AddressValidatorAPI.validateAddress(this.state.userInputtedAddress).done(
      (res) => {
        this.resolveAddressValidation(res);
      },
    );
  }

  resolveAddressSuggestions(response, requestNumber) {
    if (requestNumber < this.state.autocompleteRequestNumber) {
      return;
    }

    let addressSuggestions = [];
    if (response.result.length > 0) {
      addressSuggestions = response.result.map((r) => {
        return `${r.streetLine} ${r.secondary} ${r.city} ${r.state} ${r.zipcode}`
      })
    }

    this.setState({ addressSuggestions });
  }

  resolveAddressValidation(response) {
    let validatedAddresses = [];
    let selected = 'original';

    if (response.results.length > 0) {
      validatedAddresses = response.results;
      selected = 'suggested_0';
    }

    this.setState({
      validatedAddresses,
      searched: true,
      errors: response.error,
      selected,
      fetching: false,
    });
  }

  renderInput() {
    return (
      <>
        <div className='addressinput-searchinput'>
          <label>Search</label>
          <input
            className='padded-less'
            ref={(input) => { this.input = input; }}
            type='text'
            defaultValue={this.state.userInputtedAddress}
            onKeyPress={(e) => this.handleKeyPress(e.key, this.state.userInputtedAddress)}
            onChange={this.handleChange}
            placeholder='Type an Address...'
          />
        </div>

        <AutocompleteSuggestions
          className='addressinput-suggestions'
          userInput={this.state.userInputtedAddress ? this.state.userInputtedAddress : ''}
          suggestions={this.state.addressSuggestions}
          onKeyPress={this.handleKeyPress}
          onClick={this.handleAddressSelect}
        />

      </>
    );
  }

  renderWait() {
    return (
      <div className='padded align-center'>
        <div className='progress'>
          <div>Loading</div>
        </div>
      </div>
    );
  }

  renderContainer() {
    if (!this.state.searched) {
      return this.renderInput();
    } if (this.hasValidatedAddresses()) {
      return this.renderValidatedAddresses();
    }
    return this.noResults();
  }

  noResults() {
    let instructions = 'We couldn\'t find any validated matches for that address. You may use this address anyway if you wish.';
    let backText = 'Use a Different Address';
    if (this.state.errors) {
      instructions = 'We\'re sorry, the validation attempt failed due to a technical error. You can go back and try again or use your unvalidated address.';
      backText = 'Go Back';
    }
    return (
      <>
        <div className='padded'>{instructions}</div>
        {this.renderSubmittedAddressOption()}
        <div className='align-center padded'>
          <a onClick={this.goBack} href='#'>{backText}</a>
        </div>
      </>
    );
  }

  renderSubmittedAddressOption() {
    return (
      <AddressOption
        key='submitted'
        suggested={false}
        value={this.state.userInputtedAddress}
        onSelect={this.props.handleSave}
      />
    );
  }

  renderValidatedAddresses() {
    return (
      <>
        {this.state.validatedAddresses.map((address, index) => (
          <AddressOption
            key={index}
            suggested
            value={address}
            onSelect={this.props.handleSave}
          />
        ))}
        {this.renderSubmittedAddressOption()}
        <div className='align-center padded'>
          <a href='#' className='secondary' onClick={this.goBack}>Use a Different Address</a>
        </div>
      </>
    );
  }

  render() {
    const content = this.state.fetching
      ? this.renderWait()
      : this.renderContainer();
    return (
      <CloseIfOutsideWrapper onClose={this.onClose}>
        <div className='addressinput-expanded raised'>
          {content}
        </div>
      </CloseIfOutsideWrapper>
    );
  }
}

AddressValidator.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
};

export default AddressValidator;
