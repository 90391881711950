import React from 'react';
import renderer from 'modules/renderer';
import EntityImport from 'components/entity-import';
import CommonProviders from 'components/common-providers';

function index() {
  renderer(
    (
      <CommonProviders>
        <EntityImport options={CityGrows.Server.entityOptions} />
      </CommonProviders>
    ), document.getElementById('entity-select'),
  );
}

export default {
  index,
};