// Don't use this anymore. Use popdown instead.

import React from 'react';
import PropTypes from 'prop-types';
import CloseIfOutsideWrapper from 'components/shared/close-if-outside-wrapper';

class PopupButton extends React.Component {
  onClose = () => {
    this.props.onClose();
  };

  onClick = (e) => {
    e.stopPropagation();
    if (!this.props.disabled) {
      this.props.onToggle();
    }
  };

  buttonClassName = () => {
    return `${(this.props.buttonClassName || '')
             + (this.props.open ? ' active' : '')
             + (this.props.disabled ? ' disabled' : '')
    } popupbutton`;
  };

  wrapperClassName = () => {
    return `${(this.props.wrapperClassname || '') + (this.props.open ? ' active' : '')} popupbuttonwrapper`;
  };

  popupNubClassName() {
    return `popup-nub${this.props.nubDirection ? `-${this.props.nubDirection} popup-nub` : ''}`;
  }

  renderPopup() {
    return (
      <CloseIfOutsideWrapper onClose={this.onClose} wrapperIgnoreClass={this.props.wrapperIgnoreClass}>
        <div className='popup smallpadding' onClick={this.props.closeOnPopupClick ? this.props.onClose : null}>
          <div className={this.popupNubClassName()} />
          {this.props.children}
        </div>
      </CloseIfOutsideWrapper>
    );
  }

  render() {
    return (
      <div className={this.wrapperClassName()}>
        <div className={this.buttonClassName()} onClick={this.onClick}>{this.props.buttonHtml}</div>
        {this.props.open && this.renderPopup()}
      </div>
    );
  }
}

PopupButton.propTypes = {
  nubDirection: PropTypes.oneOf(['left', 'right', 'up', 'down']),
  buttonClassName: PropTypes.string,
  wrapperClassname: PropTypes.string,
  closeOnPopupClick: PropTypes.bool,
  disabled: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  buttonHtml: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  children: PropTypes.node.isRequired,
  wrapperIgnoreClass: PropTypes.arrayOf(PropTypes.string),
};

PopupButton.defaultProps = {
  nubDirection: 'up',
};

export default PopupButton;
