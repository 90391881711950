import React from 'react';
import PropTypes from 'prop-types';

const DEFAULT_OPTIONS = {
  paddingChars: 15,
};

const TRUNCATION_SYMBOL = '...';

const Excerpt = ({ input, phrase, options }) => {
  const lowerInput = input.toLowerCase();
  const lowerPhrase = phrase.toLowerCase();

  const config = {
    ...DEFAULT_OPTIONS,
    ...options,
  };

  const phrasePos = lowerInput.indexOf(lowerPhrase);
  if (phrasePos === -1) {
    return null;
  }

  const startPos = (phrasePos > config.paddingChars) ? (phrasePos - config.paddingChars) : 0;
  const startCap = startPos > 0 ? TRUNCATION_SYMBOL : '';
  const startChunk = lowerInput.slice(startPos, phrasePos);

  const candidateEndPos = phrasePos + lowerPhrase.length + config.paddingChars;
  const endPos = (candidateEndPos < input.length - 1) ? candidateEndPos : input.length;

  const endCap = (candidateEndPos < input.length - 1) ? TRUNCATION_SYMBOL : '';
  const endChunk = lowerInput.slice(phrasePos + lowerPhrase.length, endPos);

  return <>{startCap}{startChunk}<strong>{phrase}</strong>{endChunk}{endCap}</>;
};

Excerpt.propTypes = {
  input: PropTypes.string.isRequired,
  phrase: PropTypes.string.isRequired,
  options: PropTypes.object,
};

export default Excerpt;
