import React from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import Reviewer from './reviewer';

const SpecificPeopleReview = (props) => {
  const renderReviewer = (reviewer) => {
    return (
      <Reviewer
        key={reviewer.reviewer.id}
        reviewer={reviewer}
        isMe={isMe(reviewer.reviewer)}
        reviewGroupId={props.activeReviewGroup.review_group_id}
        activeStepId={props.activeReviewGroup.active_step_id}
        affidavit={props.activeReviewGroup.affidavit}
        defaultApprovalMessage={props.activeReviewGroup.default_approval_message}
        defaultRejectionMessage={props.activeReviewGroup.default_rejection_message}
        requireSignature={props.activeReviewGroup.require_signature}
        stepId={props.stepId}
        initiatedFlow={props.initiatedFlow}
        onOpenDiscussionClick={props.onOpenDiscussionClick}
      />
    );
  };

  const isMe = (reviewer) => {
    if (!reviewer.is_role) {
      return reviewer.id == props.currentUserId;
    }
    return _.contains(reviewer.member_ids, props.currentUserId);
  };

  // Sort by you first
  const sortedReviewers = _.sortBy(props.activeReviewGroup.reviewers, (reviewer) => {
    return !isMe(reviewer);
  });

  return (
    <div className='stepapprovals'>
      {sortedReviewers.map(renderReviewer)}
    </div>
  );
};

SpecificPeopleReview.propTypes = {
  activeReviewGroup: PropTypes.object.isRequired,
  currentUserId: PropTypes.number.isRequired,
  initiatedFlow: PropTypes.object.isRequired,
  stepId: PropTypes.number.isRequired,
};

export default SpecificPeopleReview;
