const BatchExportTemplateAPI = {
  create: (attrs) => {
    return $.ajax('/batch_export_templates', {
      dataType: 'json',
      method: 'POST',
      data: {
        batch_export_template: attrs,
      },
    });
  },
  update: (id, attrs) => {
    return $.ajax(`/batch_export_templates/${id}`, {
      dataType: 'json',
      method: 'PUT',
      data: {
        batch_export_template: attrs,
      },
    });
  },
  remove: (id) => {
    return $.ajax(`/batch_export_templates/${id}`, {
      method: 'DELETE',
      dataType: 'json',
    });
  },

  export: (id) => {
    return $.ajax(`/batch_export_templates/${id}/export`, {
      method: 'POST',
      dataType: 'json',
    });
  },
};

export default BatchExportTemplateAPI;
