/*
 * Adapted from react-minimalist-portal by Léo Pradel
*/
import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

const Portal = ({ children }) => {
  const containerRef = useRef(document.createElement('div'));
  useEffect(() => {
    // This copy is recommended by eslint rules to prevent
    // a stale reference to `current` in the cleanup logic.
    // It's unlikely a problem here (the ref shouldn't change),
    // but it's good form.
    const div = containerRef.current;
    document.body.appendChild(div);
    return () => {
      document.body.removeChild(div);
    };
  });

  return createPortal(children, containerRef.current);
};

Portal.propTypes = {
  children: PropTypes.node,
};

export default Portal;
