import Dispatcher from '../appdispatcher';
import Constants from '../constants/initiated-flow-constants';
import API from '../apis/initiated-flow-api';
import Flash from '../components/shared/flash';
import SearchAPI from '../apis/search-api';

const InitiatedFlowActions = {
  receiveInitiatedFlow(initiatedFlow) {
    Dispatcher.dispatch({
      actionType: Constants.LOADED,
      initiatedFlow,
    });
  },

  receiveStep(step) {
    Dispatcher.dispatch({
      actionType: Constants.STEP_LOADED,
      step,
    });
  },

  receiveActiveStep(activeStep) {
    Dispatcher.dispatch({
      actionType: Constants.ACTIVE_STEP_LOADED,
      activeStep,
    });
  },

  update(id, attrs) {
    const req = API.update(id, attrs);

    req.done(this.receiveInitiatedFlow);

    return req;
  },

  launchProcess(flowTemplateId, opts = {}) {
    const req = API.launchProcess(flowTemplateId, opts);

    req.done((data) => {
      window.location.replace(`/initiated_flows/manager/${data.id}`);
    })
       .fail(() => { Flash.error('There was a problem launching the submission.'); });

    return req;
  },

  deleteProcess(initiatedFlowId) {
    API.destroyProcess(initiatedFlowId)
      .done(() => {
        window.location = '/';
      })
      .fail(() => { Flash.error('There was a problem deleting the submission.'); });
  },

  searchApplicants(terms) {
    return SearchAPI.searchApplicants(terms, true);
  },
};

export default InitiatedFlowActions;
