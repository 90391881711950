import { CONNECTIVES } from '../constants/logic-constants';

// NOTE: This logic expects CONNECTIVES to be in the react-select format.
export default (connectiveId) => {
  const connective = CONNECTIVES.find(({ value }) => value === connectiveId);

  if (!connective) { throw Error('Connective not found'); }

  return connective.label;
};
