import React, { useState } from 'react';
import MoneyInput from 'components/shared/input_fields/money-input';

const Amount = (props) => {
  const [amount, setAmount] = useState(props.amount);

  function calculateAmount() {
    if (amount) {
      return Number(amount).toFixed(2);
    }

    if (props.config.calculated) {
      // when formula can't be evaluated
      return 'N/A';
    }

    return '0.00';
  }

  function onAmountChange(_e, amnt) {
    setAmount(amnt);
  }

  function renderActionButtons() {
    if (!props.disabled) {
      return (
        <button onClick={() => { props.onAmountSave(amount); }}>Confirm amount</button>
      );
    }

    return (<button className='btn-link' onClick={props.onEditClick}>Edit</button>);
  }

  return (
    <div className='margin-bottom currentstep-paymentamount'>
      <label htmlFor='payment-box' className='margin-top subtitle'>Payment amount</label>
      <div>
        <MoneyInput
          onChange={onAmountChange}
          disabled={props.disabled}
          id='payment-box'
          defaultValue={calculateAmount()}
        />
        {!props.config.fixed && renderActionButtons()}
      </div>
    </div>
  );
};

export default Amount;
