const TaggingAPI = {
  create(taggableType, taggableId, tagAttrs) {
    const url = '/taggings';
    const mergedAttrs = {
      ...tagAttrs,
      taggable_type: taggableType,
      taggable_id: taggableId,
    };
    const request = $.ajax(url, {
      dataType: 'JSON',
      method: 'POST',
      data: {
        tagging: mergedAttrs,
      },
    });

    return request;
  },

  createInBatch(taggingsAttrs) {
    const url = '/taggings/create_in_batch';

    const request = $.ajax(url, {
      dataType: 'JSON',
      method: 'POST',
      data: {
        taggings: JSON.stringify(taggingsAttrs),
      },
    });

    return request;
  },

  destroy(taggingId) {
    const url = `/taggings/${taggingId}`;
    const request = $.ajax(url, {
      dataType: 'JSON',
      method: 'DELETE',
    });

    return request;
  },

  destroyInBatch(taggingsAttrs) {
    const url = '/taggings/destroy_in_batch';

    const request = $.ajax(url, {
      dataType: 'JSON',
      method: 'DELETE',
      data: {
        taggings: JSON.stringify(taggingsAttrs),
      },
    });

    return request;
  },
};

export default TaggingAPI;
