import React, { memo } from 'react';
import PropTypes from 'prop-types';

const InlineNotification = memo((props) => {
  const renderChildren = () => {
    if (React.isValidElement(props.children)) {
      return <span>{props.children}</span>;
    }

    return <span dangerouslySetInnerHTML={{ __html: props.children }} />;
  };

  const classForType = () => {
    switch (props.type) {
    case 'notice':
      return 'inlinenotification-notice';
    default:
    }
  };

  const renderIcon = () => {
    switch (props.type) {
    case 'notice':
      return <i className='icon-exclaim-white' />;
    default:
      return <i className='icon-exclaim' />;
    }
  };

  return (
    <div className={`inlinenotification ${classForType()} ${props.className}`}>
      {renderIcon()}
      {renderChildren()}
    </div>
  );
});

InlineNotification.propTypes = {
  type: PropTypes.string,
};

InlineNotification.defaultProps = {
  type: 'error',
};

export default InlineNotification;
