import keyMirror from 'keymirror';

export default keyMirror({
  ADVANCE_ATTEMPTED: null,
  ACTIVE_STEP_LOADED: null,
  ACTIVE_STEP_UPDATED: null,
  APPROVAL_CHANGED: null,
  ASSIGNED_PEOPLE_UPDATED: null,
  ASSIGNMENT_UPDATED: null,
  COLLECTED_DATA_CHANGED: null,
  COLLECTED_DATA_ERROR: null,
  COLLECTED_DATA_WILL_CHANGE: null,
  DELETE_ATTACHED_FILE: null,
  ENTITY_LOADED: null,
  HALTED: null,
  LOADED: null,
  OUTPUT_UPDATED: null,
  PAYMENT_MADE: null,
  PAYMENT_UPDATED: null,
  SET_ATTACHMENTS_REFRESHED: null,
  SET_REFRESHED: null,
  SUBPROCESS_REQUIREMENT_UPDATED: null,
  SYNC_ATTACHMENTS: null,
  REQUIREMENT_DELETED: null,
  REQUIREMENT_UPDATED: null,
  STEP_APPROVAL_CHANGED: null,
  STEP_LOADED: null,
  TAG_ADDED: null,
  TAG_REMOVED: null,
  TAG_DELETED: null,
  TAG_UPDATED: null,
  APPLICANT_LIST_UPDATED: null,
  PRIMARY_APPLICANT_CHANGED: null,
}, 'INITIATED_FLOW');
