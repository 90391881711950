import React from 'react';
import CollectInfoStepSummary from '../../shared/collect-info-step-summary';

const PreviousStep = ({ step, collectedData }) => {
  function renderMainContent() {
    if (step.type === 'CollectInfoStep') {
      return (
        <CollectInfoStepSummary step={step} collectedData={collectedData} />
      );
    }

    return (
      <div className='padded'>
        No data to display for this section
      </div>
    );
  }

  return renderMainContent()
}

export default PreviousStep;