var MultipleChoiceOptionAPI = {
  create: function(stepDatumId){
    var url = '/step_data/' + stepDatumId + '/step_datum/multiple_choice_options';
    var request = $.ajax(url, {
      method: 'POST',
      dataType: 'json'
    });

    return request;
  },

  destroy: function(optionId){
    var url = '/step_datum/multiple_choice_options/' + optionId;
    var request = $.ajax(url, {
      method: 'DELETE',
      dataType: 'json'
    });

    return request;
  },

  destroyAll: (stepDatumId) => {
    const url = `/step_data/${stepDatumId}/multiple_choice_options/batch/`;
    const request = $.ajax(url, {
      method: 'DELETE',
      dataType: 'json'
    });

    return request;
  },

  update: function(optionId, optionAttrs){
    var url = '/step_datum/multiple_choice_options/' + optionId;
    var request = $.ajax(url, {
      data: {
        multiple_choice_option: optionAttrs
      },
      dataType: 'json',
      method: 'PUT'
    });

    return request;
  },

  reorder: function(optionId, position) {
    const url = `/step_datum/multiple_choice_options/${optionId}/reorder`;

    return $.ajax(url, {
      dataType: 'json',
      method: 'PUT',
      data: {
        multiple_choice_option: {
          position
        }
      }
    });
  },

  upload: function(stepDatumId, optionFile){
    let url = `/step_data/${stepDatumId}/multiple_choice_options/batch/`;
    let formData = new FormData();

    formData.append('upload', optionFile, optionFile.name);

    let request = $.ajax(url, {
      data: formData,
      dataType: 'json',
      method: 'POST',
      contentType: false,
      processData: false
    });

    return request;
  }
};

export default MultipleChoiceOptionAPI;
