import React from 'react';
import PropTypes from 'prop-types';
import SmallFlowTable from 'components/shared/small-flow-table';

const ExistingProcessesStep = (props) => {
  return (
    <div className='launchmanager-processes'>
      <h1>Looking for a submission you already started?</h1>
      <span>Choose one of the following submissions to renew or pick up where you left off.</span>

      <div className='launchmanager-processes-tablewrap'>
        <SmallFlowTable initiatedFlows={props.initiatedFlows} subprocessRequirementId={props.subprocessRequirementId} />
      </div>

      <nav className='margin-top'>
        <button id='forrealbutton' type='button' className='btn-primary' onClick={props.onNextClick}>Nope, I really want to start another one</button>
      </nav>
    </div>
  );
};

ExistingProcessesStep.propTypes = {
  initiatedFlows: PropTypes.arrayOf(PropTypes.shape({})),
  onNextClick: PropTypes.func.isRequired,
  subprocessRequirementId: PropTypes.number,
};

ExistingProcessesStep.defaultProps = {
  initiatedFlows: [],
};

export default ExistingProcessesStep;
