import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import stickybits from 'stickybits';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import DiscussionActions from 'actions/discussion-actions';
import InformationalText from 'components/shared/informational-text';
import discussionReducer from 'reducers/discussions-reducer';
import DiscussionDispatchContext from 'contexts/discussion-dispatch-context';
import pluralize from 'modules/pluralize';
import SubmitReviewWidget from './initiated_flow/process/steps/reviews/submit-review-widget';
import ReviewDiscussion from './initiated_flow/process/steps/reviews/discussion';
import ReviewInterface from './initiated_flow/process/steps/reviews/interface';
import CollectInfoStepSummary from './shared/collect-info-step-summary';

const DISCUSSION_TAB = 'discussion';
const REVIEW_TAB = 'review';

const ReviewApp = (props) => {
  const [discussionState, discussionDispatch] = useReducer(discussionReducer, { discussions: [] });

  useEffect(() => {
    DiscussionActions.loadDiscussions(props.initiatedFlow.id, discussionDispatch);
    stickybits('.review-stepinfo-header');
  }, [props.initiatedFlow.id]);

  const [submitting, setSubmitting] = useState(false);
  const [selectedApprovalStatus, setSelectedApprovalStatus] = useState(null);
  const [comment, setComment] = useState(null);
  const [tab, setTab] = useState('review');
  const [recentlyAddedDiscussionId, setRecentlyAddedDiscussionId] = useState(null);
  const [outstandingReviewCount,
    setOutstandingReviewcount] = useState(props.outstandingReviewCount);
  const [previousReviews, setPreviousReviews] = useState(props.previousReviews);
  const [wasJustReviewed, setWasJustReviewed] = useState(false);
  const [reviewOutcome, setReviewOutcome] = useState(null);

  const renderAllStepData = () => {
    return props.flowTemplate.steps.map(renderStepData);
  };

  const renderStepData = (step) => {
    return (
      <li key={step.id} className='review-stepinfo'>
        <div className='review-stepinfo-header'> {step.position}. {step.name}</div>
        <CollectInfoStepSummary step={step} collectedData={props.initiatedFlow.collected_data} />
      </li>
    );
  };

  const onReviewStart = () => {
    setSubmitting(true);
  };

  const onReviewExit = () => {
    setSubmitting(false);
  };

  const onReviewComplete = (res) => {
    setOutstandingReviewcount(res.review_data.outstanding_review_count);
    setPreviousReviews(res.review_data.previous_reviews);
    setWasJustReviewed(true);
    setReviewOutcome(res.approval.approval_status);

    if (res.approval.discussion) {
      const { discussion } = res.approval;
      DiscussionActions.onDiscussionCreate(discussionDispatch, discussion);

      setTab(DISCUSSION_TAB);
      setRecentlyAddedDiscussionId(discussion.id);
    }

    if (res.approval.resolved_pending_requests) {
      DiscussionActions.loadDiscussions(props.initiatedFlow.id, discussionDispatch);
    }
  };

  const onTabClick = (e, newTab) => {
    e.preventDefault();
    setTab(newTab);
  };

  const renderSubmitWidget = () => {
    if (submitting) {
      return (
        <SubmitReviewWidget
          key={props.reviewer.reviewer.id}
          roleId={props.activeReviewGroup.role_id}
          reviewer={props.reviewer.reviewer}
          requireSignature={props.activeReviewGroup.require_signature}
          reviewGroupId={props.activeReviewGroup.review_group_id}
          activeStepId={props.activeReviewGroup.active_step_id}
          affidavit={props.activeReviewGroup.affidavit}
          stepId={props.stepId}
          onClose={onReviewExit}
          onReviewComplete={onReviewComplete}
          approvalStatus={selectedApprovalStatus}
          comment={comment}
        />
      );
    }
  };

  const renderRoleNotif = () => {
    return <div id='review-rolenotif'>You are reviewing as a member of the {props.activeReviewGroup.role_name} Role</div>;
  };

  const onApprovalStatusUpdate = (e) => {
    setSelectedApprovalStatus(e.target.value);
  };

  const onCommentChange = (newComment) => {
    setComment(newComment);
  };

  const submitButtonText = () => {
    if (previousReviews.length > 0) {
      return 'Submit another review';
    }

    return 'Submit review';
  };

  const tabClassName = (tb) => {
    return tb === tab ? 'selected' : null;
  };

  const renderNextURL = () => {
    if (props.nextURL) {
      const verb = wasPreviouslyReviewed() ? 'Continue' : 'Skip';
      return <a href={props.nextURL}>{`${verb} to next review >`}</a>;
    }
  };

  const renderPrevURL = () => {
    if (props.prevURL) {
      return <a href={props.prevURL}>{'< Back to previous review'}</a>;
    }
  };

  const renderFirstURL = () => {
    if (props.firstURL) {
      return <a href={props.firstURL}>{`Continue to ${props.outstandingReviewCount} other reviews >`}</a>;
    }
  };

  const renderReviewNumber = () => {
    if (props.currentReviewNumber > 0) {
      return (
        <>
          <strong>{props.currentReviewNumber}</strong> of&nbsp;
        </>
      );
    }
  };

  const wasPreviouslyReviewed = () => {
    return previousReviews.length > 0;
  };

  const completedReviewInfo = () => {
    switch (reviewOutcome) {
    case true:
      return 'You approved this submission.';
    case false:
      return 'You rejected this submission. It has been halted.';
    case null:
      return 'You requested changes.';
    default:
      throw `Unrecognized approval status ${reviewOutcome}`;
    }
  };

  const renderNavBarContent = () => {
    if (wasJustReviewed) {
      return (
        <div id='review-navmiddle'>{completedReviewInfo()}&nbsp; {renderNextURL()}</div>
      );
    } if (props.completedForCurrentUser) {
      return (
        <div id='review-navmiddle'>You already reviewed. {renderFirstURL()}</div>
      );
    }
    return (
      <>
        <div>{renderPrevURL()}</div>
        <div id='review-navmiddle'>{renderReviewNumber()}<strong>{outstandingReviewCount}</strong> items to review</div>
        <div id='review-nextlink'>{renderNextURL()}</div>
      </>
    );
  };

  const bannerColorClass = () => {
    if (!wasJustReviewed) { return; }

    switch (reviewOutcome) {
    case true:
      return 'approved';
    case false:
      return 'rejected';
    case null:
      return 'commented';
    default:
      throw `Unrecognized approval status ${reviewOutcome}`;
    }
  };

  const renderAdminInstructions = () => {
    if (!props.reviewStepInternalInstructions) { return; }

    return (
      <InformationalText
        text={props.reviewStepInternalInstructions}
        textType='InternalStepInstructions'
        textContainerId={props.stepId}
        initiatedFlow={props.initiatedFlow}
        classes='color-text-dark'
      />
    );
  };

  function getApplicantText() {
    const text = props.initiatedFlow.primary_applicant.name;
    const otherApplicantsCount = props.initiatedFlow.applicants_count - 1;
    if (otherApplicantsCount > 0) {
      return `${text} and ${otherApplicantsCount} ${pluralize('other', otherApplicantsCount, { many: 'others' })}`;
    }
    return text;
  }

  function renderReviewInterface() {
    return (
      <>
        {props.activeReviewGroup.role_name && renderRoleNotif()}
        <ReviewInterface
          onApprovalStatusUpdate={onApprovalStatusUpdate}
          onReviewStart={onReviewStart}
          onCommentChange={onCommentChange}
          submitButtonText={submitButtonText()}
          previousReviews={previousReviews}
          toName={getApplicantText()}
          defaultApprovalMessage={props.activeReviewGroup.default_approval_message}
          defaultRejectionMessage={props.activeReviewGroup.default_rejection_message}
          onOpenDiscussionClick={(e) => { onTabClick(e, 'discussion'); }}
        />
      </>
    );
  };

  return (
    <DiscussionDispatchContext.Provider value={discussionDispatch}>
      {renderSubmitWidget()}

      <nav id='review-nav' className={bannerColorClass()}>
        {renderNavBarContent()}
      </nav>
      <div id='review-maincontent'>
        <div id='review-cityname'>{props.flowTemplate.team_name}</div>

        <div className='margin-top-less margin-bottom-less fixed'>
          <div id='review-templatename' className='inline-block margin-right'><a title='View full submission' href={`/initiated_flows/${props.initiatedFlow.id}`}>{props.initiatedFlow.template_name}</a></div>
          <div className='processnumber inline-block'>
            Submission&nbsp;
            <span>#{props.initiatedFlow.scoped_id}</span>
          </div>
        </div>
        <div id='review-columnwrap'>
          <div id='review-left'>
            <div id='review-metainfo'>
              <div id='review-metainfo-left'>
                <div className='margin-right-less'>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <strong>Review group</strong>
                        </td>
                        <td>
                          {props.activeReviewGroup.name_or_default}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Applicant</strong>
                        </td>

                        <td>
                          {getApplicantText()}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Instructions</strong>
                        </td>

                        <td>
                          {renderAdminInstructions()}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <ul id='review-processdata'>
              {renderAllStepData()}
            </ul>
          </div>

          <div id='review-right'>
            <div id='review-actionbox' className='raised'>
              <div className='tabnav align-center'>
                <ul>
                  <li className={tabClassName('review')}>
                    <button
                      type='button'
                      className='btn-link'
                      onClick={(e) => { onTabClick(e, 'review'); }}
                    >
                      Review
                    </button>
                  </li>
                  <li className={tabClassName('discussion')}>
                    <button
                      type='button'
                      className='btn-link'
                      onClick={(e) => { onTabClick(e, 'discussion'); }}
                    >
                      Discussion
                    </button>
                  </li>
                </ul>
              </div>

              <div id='review-overflowwrap'>
                <TransitionGroup>
                  {tab === REVIEW_TAB && (
                    <CSSTransition
                      classNames='review-reviewtab'
                      timeout={{ enter: 150 }}
                    >
                      {renderReviewInterface()}
                    </CSSTransition>
                  )}
                  {tab === DISCUSSION_TAB && (
                    <CSSTransition
                      classNames='review-discussiontab'
                      timeout={{ enter: 150 }}
                    >
                      <ReviewDiscussion
                        initiatedFlowId={props.initiatedFlow.id}
                        admin
                        recentlyAddedDiscussionId={recentlyAddedDiscussionId}
                        discussions={discussionState.discussions}
                      />
                    </CSSTransition>
                  )}
                </TransitionGroup>
              </div>

            </div>
          </div>
        </div>
      </div>
    </DiscussionDispatchContext.Provider>
  );
};

ReviewApp.propTypes = {
  initiatedFlow: PropTypes.shape({
    id: PropTypes.number.isRequired,
    collected_data: PropTypes.array.isRequired,
    primary_applicant: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    applicants_count: PropTypes.number.isRequired,
    scoped_id: PropTypes.number.isRequired,
    template_name: PropTypes.string.isRequired,
  }).isRequired,
  activeReviewGroup: PropTypes.shape({}).isRequired,
  completedForCurrentUser: PropTypes.bool.isRequired,
  currentReviewNumber: PropTypes.number.isRequired,
  firstURL: PropTypes.string.isRequired,
  flowTemplate: PropTypes.shape({
    steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  nextURL: PropTypes.string.isRequired,
  outstandingReviewCount: PropTypes.number.isRequired,
  previousReviews: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  prevURL: PropTypes.string.isRequired,
  reviewer: PropTypes.shape({}).isRequired,
  stepId: PropTypes.number.isRequired,
  reviewStepInternalInstructions: PropTypes.string,
};

export default ReviewApp;
