import PropTypes from 'prop-types';
import React, { useReducer, useEffect } from 'react';
import ErrorBoundary from 'components/shared/error-boundary';
import initiatedFlowReducer from 'reducers/initiated-flow-reducer';
import discussionReducer from 'reducers/discussions-reducer';
import DiscussionActions from 'actions/discussion-actions';
import ActiveStepConstants from 'constants/active-step-constants';
import InitiatedFlowConstants from 'constants/initiated-flow-constants';
import AttachmentsAPI from 'apis/attachment-api';
import ActiveStepAPI from 'apis/active-step-api';
import { debounce } from 'underscore';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import DiscussionDispatchContext from 'contexts/discussion-dispatch-context';
import ActiveProcess from './initiated_flow/active-process';
import TasksMainContent from './my-tasks/tasks-main-content';
import TasksSidebar from './my-tasks/tasks-sidebar';

const InitiatedFlowManager = ({
  currentUserId,
  admin,
  flowTemplate,
  showAdminWarning,
  masterTemplate,
  notifications,
  initiatedFlow,
  step,
  activeStep,
  taskView,
  onTaskViewUpdate,
  onSkip,
}) => {
  const [initiatedFlowState, initiatedFlowDispatch] = useReducer(initiatedFlowReducer, {
    initiatedFlow, step, activeStep, activities: [], refreshing: false, refreshingAttachments: false, outstandingSaveRequests: null, changePending: false,
  });
  const [discussionState, discussionDispatch] = useReducer(discussionReducer, { discussions: [] });

  useEffect(() => {
    DiscussionActions.loadDiscussions(initiatedFlowState.initiatedFlow.id, discussionDispatch);
  }, [initiatedFlowState.initiatedFlow.id]);

  const sync = debounce(() => {
    ActiveStepAPI.show(initiatedFlowState.activeStep.id)
      .done((aS) => {
        initiatedFlowDispatch({
          type: ActiveStepConstants.SYNC_ACTIVE_STEP,
          activeStep: aS,
        });
      })
      .always(() => {
        initiatedFlowDispatch({
          type: InitiatedFlowConstants.SET_REFRESHED,
        });
      });
  }, 1000);

  const syncAttachments = debounce(() => {
    AttachmentsAPI.fetchAttachments('InitiatedFlow', initiatedFlowState.initiatedFlow.id)
      .done((attachments) => {
        initiatedFlowDispatch({
          type: InitiatedFlowConstants.SYNC_ATTACHMENTS,
          attachments,
        });
      })
      .always(() => {
        initiatedFlowDispatch({
          type: InitiatedFlowConstants.SET_ATTACHMENTS_REFRESHED,
        });
      });
  }, 1000);

  useEffect(() => {
    if (initiatedFlowState.refreshing) {
      sync();
    }
  }, [initiatedFlowState.refreshing]);

  useEffect(() => {
    if (initiatedFlowState.refreshingAttachments) {
      if (initiatedFlowState.reviewSubmitted && taskView) {
        return onTaskViewUpdate();
      }

      syncAttachments();
    }
  }, [initiatedFlowState.refreshingAttachments]);

  function renderProcess() {
    if (taskView) {
      return (
        <>
          <div className='mytasks-main flexcolumn'>
            <TasksMainContent
              initiatedFlow={initiatedFlowState.initiatedFlow}
              teamFriendlyId={masterTemplate.team_friendly_id}
              discussions={discussionState.discussions}
              flowTemplateName={flowTemplate.name}
              activeStep={initiatedFlowState.activeStep}
              currentStep={initiatedFlowState.step}
              />
          </div>
          <TasksSidebar
            initiatedFlowState={initiatedFlowState}
            activities={initiatedFlowState.activities}
            discussions={discussionState.discussions}
            currentStep={initiatedFlowState.step}
            masterTemplate={masterTemplate}
            onTaskViewUpdate={onTaskViewUpdate}
            onSkip={onSkip}
          />
        </>
      );
    }

    return (
      <ActiveProcess
        currentUserId={currentUserId}
        admin={admin}
        flowTemplate={flowTemplate}
        showAdminWarning={showAdminWarning}
        masterTemplate={masterTemplate}
        notifications={notifications}
        initiatedFlowState={initiatedFlowState}
        discussions={discussionState.discussions}
      />
    )
  }

  return (
    <ErrorBoundary>
      <InitiatedFlowDispatchContext.Provider value={initiatedFlowDispatch}>
        <DiscussionDispatchContext.Provider value={discussionDispatch}>
          {renderProcess()}
        </DiscussionDispatchContext.Provider>
      </InitiatedFlowDispatchContext.Provider>
    </ErrorBoundary>
  );
};

InitiatedFlowManager.propTypes = {
  admin: PropTypes.bool.isRequired,
  showAdminWarning: PropTypes.bool.isRequired,
  masterTemplate: PropTypes.object.isRequired,
  notifications: PropTypes.array,
  taskView: PropTypes.bool,

  currentUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),

  flowTemplate: PropTypes.object.isRequired,
};

export default InitiatedFlowManager;
