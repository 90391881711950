import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import Popdown from 'components/shared/popdown';
import LoadingSpinner from 'components/shared/loading-spinner';
import { isEmpty } from 'underscore';
import MasterTemplateAPI from 'apis/master-template-api';
import Flash from 'components/shared/flash';
import dayjs from 'dayjs';
import useOnclickOutside from 'react-cool-onclickoutside';

const VersionNumber = ({ onClick, version, masterTemplateId }) => {
  const innerContentEl = useRef();
  const elForAnchor = useRef();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [versions, setVersions] = useState(null);

  const loadVersions = useCallback(() => {
    MasterTemplateAPI.versions(masterTemplateId)
      .done((responseVersions) => {
        setVersions(responseVersions);
      })
      .fail(() => { Flash.error('Couldn\'t load previous form versions'); })
      .always(() => { setLoading(false); });
  }, [masterTemplateId]);

  const onButtonClick = () => {
    setOpen((currentOpen) => !currentOpen);
    onClick();
  };

  useEffect(() => {
    if (open && !loading && !versions) {
      setLoading(true);
      loadVersions();
    }
  }, [open, loading, versions, loadVersions]);

  useEffect(() => {
    // If a new version of the template comes down
    // to this component, it probably means that there's
    // a new version that we're not aware of.
    setVersions(null);
  }, [version]);

  useOnclickOutside(() => {
    setOpen(false);
  }, { refs: [elForAnchor] });

  const renderVersion = (templateVersion) => (
    <li key={templateVersion.id}>
      <div className='versionnumber-label'>
        Version {templateVersion.version}
      </div>
      <div className='versionnumber-date'>
        Published {dayjs(templateVersion.published_at).format('l')}
      </div>
      <dl className='versionnumber-stats'>
        <dt>Active</dt>
        <dd>{templateVersion.active}</dd>
        <dt>Complete</dt>
        <dd>{templateVersion.completed}</dd>
      </dl>
    </li>
  );

  const renderPopupContent = () => {
    if (!versions) {
      return <LoadingSpinner />;
    }

    if (isEmpty(versions)) {
      return 'This is the only version of this form';
    }

    return (
      <ul ref={innerContentEl} className='versionnumber-versionlist'>
        {versions.map(renderVersion)}
      </ul>
    );
  };

  const renderPopdown = () => {
    if (!open) { return null; }

    return (
      <Popdown
        anchorRef={elForAnchor}
        className='padded versionnumber-popdown'
      >
        {renderPopupContent()}
      </Popdown>
    );
  };

  return (
    <>
      <div ref={elForAnchor} title='Template version' className='versionnumber'>
        <button type='button' onClick={onButtonClick}>
          Version {version}
        </button>
      </div>

      {renderPopdown()}
    </>
  );
};

VersionNumber.defaultProps = {
  onClick: () => {},
};

VersionNumber.propTypes = {
  onClick: PropTypes.func,
  masterTemplateId: PropTypes.number.isRequired,
  version: PropTypes.number.isRequired,
};

export default VersionNumber;
