import NProgress from 'nprogress';
import Dispatcher from '../appdispatcher';
import API from '../apis/multiple-choice-option-api';
import Constants from '../constants/multiple-choice-option-constants';
import FlowStepActions from './flow-step-actions';
import Flash from '../components/shared/flash';
import AlertConfirm from '../modules/alert-confirm';

function dispatchOptionAdd(stepDatumId, optionAttrs) {
  Dispatcher.dispatch({
    actionType: Constants.ADDED,
    stepDatumId,
    optionAttrs,
  });
}

function dispatchOptionDestroy(optionId) {
  Dispatcher.dispatch({
    actionType: Constants.DESTROYED,
    optionId,
  });
}

function dispatchOptionUpdate(optionId, optionAttrs) {
  Dispatcher.dispatch({
    actionType: Constants.UPDATED,
    optionId,
    optionAttrs,
  });
}

const MultipleChoiceOptionActions = {

  addOption(stepDatumId) {
    return API.create(stepDatumId)
            .done((res) => dispatchOptionAdd(stepDatumId, res));
  },

  removeOption(optionId) {
    return API.destroy(optionId)
              .done(() => dispatchOptionDestroy(optionId));
  },

  updateOption(optionId, optionAttrs) {
    NProgress.start();

    return API.update(optionId, optionAttrs)
              .done(() => dispatchOptionUpdate(optionId, optionAttrs))
              .fail((response) => { Flash.error(response.responseJSON.error); })
              .always(() => { NProgress.done(); });
  },

  reorderOption(optionId, newPos) {
    return API.reorder(optionId, newPos)
              .done((res) => {
                Dispatcher.dispatch({
                  actionType: Constants.MOVED,
                  options: res,
                });
              })
            .fail(() => { Flash.error(); });
  },

  destroyAll(stepDatumId) {
    NProgress.start();

    AlertConfirm.confirm('Delete all options', 'Are you sure you want to delete all multiple choice options for this field?', () => {
      return API.destroyAll(stepDatumId)
              .done(() => {
                FlowStepActions.refreshStepData(stepDatumId).done(NProgress.done);
              })
              .fail(() => { Flash.error(); });
    });
  },

  uploadOptions(stepDatumId, optionFile) {
    NProgress.start();

    return API.upload(stepDatumId, optionFile)
            .done((res) => {
              Flash.success(`Successfully added ${res.length} options`);
              FlowStepActions.refreshStepData(stepDatumId).done(NProgress.done);
            })
            .fail(() => {
              AlertConfirm.alert('Upload Failed', 'We were unable to process your upload. Click <a target="__blank" href="https://support.cleargov.com/hc/en-us/articles/20914284325651-Uploading-a-CSV-file-of-Multiple-Choice-Options-in-a-Collect-Record-Information-Step-">here</a> to learn how to properly format your CSV file.');
              NProgress.done();
            });
  },
};

export default MultipleChoiceOptionActions;
