import PropTypes from 'prop-types';
import React, { Component } from 'react';

class ActionButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activated: false,
    };
  }

  onClick = () => {
    if (this.isDisabled()) return;

    const deferred = this.props.onClick();

    if (!deferred.always) {
      throw new Error('onClick prop must return a deferred object');
    }

    deferred.always(() => { return this.setState({ activated: false }); });

    this.setState({ activated: true });
  };

  isDisabled() {
    return this.state.activated || this.props.disabled;
  }

  render() {
    return (
      <a
        onClick={this.onClick}
        className={this.props.className + (this.isDisabled() ? ' disabled' : '')}
        id={this.props.htmlID}
      >
        {this.props.children}
      </a>
    );
  }
}

ActionButton.defaultProps = {
  className: 'btn-primary',
  htmlID: '',
  disabled: false,
};

ActionButton.propTypes = {
  htmlID: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default ActionButton;
