const EmailSettingsAPI = {
  create: function(emailSetting) {
    const url = '/email_settings';
    return $.ajax(url, {
      dataType: 'json',
      method: 'POST',
      data: {
        email_setting: emailSetting
      }
    });
  },

  update: function(id, shouldSend) {
    const url = `/email_settings/${id}`;
    return $.ajax(url, {
      dataType: 'json',
      method: 'PUT',
      data: {
        should_send: shouldSend
      }
    });
  },

  destroy: function(id) {
    const url = `/email_settings/${id}`;
    return $.ajax(url, {
      dataType: 'json',
      method: 'DELETE'
    });
  },

  delete_all: function(typeParentId, typeParentType) {
    const url = '/email_settings/delete_all';
    return $.ajax(url, {
      dataType: 'json',
      method: 'DELETE',
      data: {
        type_parent_id: typeParentId,
        type_parent_type: typeParentType
      }
    });
  }

};

export default EmailSettingsAPI;
