import React from 'react';
import PropTypes from 'prop-types';
import AutonomousPopup from 'components/shared/autonomous-popup';
import { findWhere, any } from 'underscore';
import Modal from 'components/shared/modal';
import Select from 'components/shared/select';
import TemplateExtrasActions from 'actions/template-extras-actions';

class OverviewTabs extends React.PureComponent {
  activeClass = (tab) => {
    const classes = ['tab'];

    if (CityGrows.Server.actionName === tab) {
      classes.push('active');
    }

    return classes.join(' ');
  };

  moreHTML = (
    <a id='morelink' href='#' className='tab' onClick={(e) => { e.preventDefault(); }}>
      More
      <span className=' margin-left-less icon icon-arrow-down thin dark small' />
    </a>
  );

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      selectedTeamId: CityGrows.Server.currentUser?.isGov ? this.props.myTeams[0]?.id : null,
    };
  }

  onCloneProcessClick = (e) => {
    e.preventDefault();

    this.setState({
      modalOpen: true,
    });
  };

  onClose = () => {
    this.setState({
      modalOpen: false,
    });
  };

  onDoClone = (e) => {
    e.preventDefault();
    TemplateExtrasActions.cloneTemplate(this.props.flowTemplateId, this.state.selectedTeamId)
                         .done((res) => { window.location = res.url; });
  };

  updateSelectedTeam = (option) => {
    const selectedTeamId = option.value;
    this.setState({
      selectedTeamId,
    });
  };

  renderTeamSelector() {
    if (this.state.modalOpen) {
      const teamOptions = this.props.myTeams.map((team) => ({ value: team.id, label: team.name }));
      return (
        <Modal className='padded-more cf' onOutsideClick={this.onClose} scroll={false}>
          <h2>Clone form</h2>
          <p className='color-text-medium margin-bottom'>Select a team to clone this form into</p>
          <Select
            options={teamOptions}
            style={{ width: '100%' }}
            value={teamOptions.find((option) => this.state.selectedTeamId === option.value)}
            onChange={this.updateSelectedTeam}
          />
          <a href='#' className='btn-primary margin-top float-right' onClick={this.onDoClone}>Clone form</a>
        </Modal>
      );
    }
  }

  renderMoreMenu = () => {
    const menuItems = [];
    const { currentUser } = CityGrows.Server;

    if (currentUser && currentUser.isGov && this.props.forkAllowed) {
      menuItems.push(
        <li key='clone'><a onClick={this.onCloneProcessClick} href='#'>Clone this form</a></li>,
      );
    }

    if (!any(menuItems)) { return; }

    return (
      <li className='tab'>
        <AutonomousPopup buttonHtml={this.moreHTML}>
          <ul className='popup-listmenu'>
            {menuItems}
          </ul>
        </AutonomousPopup>
      </li>
    );
  };

  renderPublicRecordSearch = () => {
    if (!this.props.searchEnabled) { return; }

    return (
      <li className={this.activeClass('search')}>
        <a href={`/${this.props.teamSlug}/${this.props.templateSlug}/search`}>
          <span className='icon icon-magnifyingglass' />
          Public record search
        </a>
      </li>
    );
  };

  renderStatsTab = () => {
    if (!this.props.canPublicRead || this.props.templatePrivate) { return; }

    return (
      <li className={this.activeClass('data')}>
        <a id='statslink' href={`/${this.props.teamSlug}/${this.props.templateSlug}/data`}>
          <span className='icon icon-stats' />
          Stats
        </a>
      </li>
    );
  };

  render() {
    return (
      <>
        <nav id='publicoverview-tabs'>
          <ul className='column narrow'>
            <li className={this.activeClass('show')}>
              <a href={`/${this.props.teamSlug}/${this.props.templateSlug}`}>
                <span className='icon icon-lines-medium' />
                Form details
              </a>
            </li>
            <li className={this.activeClass('faqs')}>
              <a id='faqslink' href={`/${this.props.teamSlug}/${this.props.templateSlug}/faqs`}>
                <span className='icon icon-speechballoon-medium' />
                FAQs
              </a>
            </li>
            {this.renderStatsTab()}
            {this.renderPublicRecordSearch()}
            {this.renderMoreMenu()}
          </ul>
        </nav>

        {this.renderTeamSelector()}
      </>
    );
  }
}

OverviewTabs.propTypes = {
  searchEnabled: PropTypes.bool,
  teamSlug: PropTypes.string.isRequired,
  templateSlug: PropTypes.string.isRequired,
  flowTemplateId: PropTypes.number.isRequired,
  myTeams: PropTypes.array,
  forkAllowed: PropTypes.bool,
};

OverviewTabs.defaultProps = {
  searchEnabled: false,
  forkAllowed: false,
};

export default OverviewTabs;
