const PaymentIntentsApi = {
  create(paymentId, amount, paymentType) {
    return $.ajax(`/payments/${paymentId}/payment_intents`, {
      method: 'POST',
      dataType: 'JSON',
      data: {
        amount,
        payment_type: paymentType,
      },
    });
  },

  update(paymentId, amount, paymentType) {
    return $.ajax(`/payments/${paymentId}/payment_intents`, {
      method: 'PUT',
      dataType: 'JSON',
      data: {
        amount,
        payment_type: paymentType,
      },
    });
  },

  confirmPayment(paymentId, amount, paymentType) {
    return $.ajax(`/payments/${paymentId}/payment_intents/confirm_payment`, {
      method: 'GET',
      dataType: 'JSON',
      data: {
        amount,
        payment_type: paymentType,
      },
    });
  },
};

export default PaymentIntentsApi;
