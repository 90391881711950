import React, { useReducer, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  uniqueId, findWhere, isNumber, reject,
} from 'underscore';
import {
  Link, useParams,
} from 'react-router-dom';
import invariant from 'invariant';
import { DocumentDispatchContext } from 'contexts/document-dispatch-context';
import DocumentConfig from './document_manager/document-config';

const DocumentManager = ({
  defaultMappables,
  masterTemplate,
}) => {
  const { document_id: documentId } = useParams();

  useEffect(() => {
    dispatch({
      type: 'setDocumentFocus',
      documentId,
    });
  }, [documentId]);

  const documentReducer = (state, action) => {
    switch (action.type) {
    case 'setDocumentFocus':
      return { ...state, currentDocument: findFocusedDocument(state.documents) };
    case 'create':
      return { ...state, documents: [...state.documents, action.document] };
    case 'update':
      const docs = [...state.documents];
      const docIndex = docs.findIndex((doc) => {
        return doc.id === action.document.id;
      });

      invariant(docIndex >= 0, `Expected to find document with id ${action.document.id}`);
      docs[docIndex] = action.document;

      return {
        ...state,
        documents: docs,
        currentDocument: findFocusedDocument(docs),
      };
    case 'destroy':
      const docsDupe = [...state.documents];
      return {
        ...state,
        documents: reject(docsDupe, { id: action.documentId }),
      };
    default:
      throw new Error('Unrecognized action type');
    }
  };

  const findFocusedDocument = (documents) => {
    if (documentId === 'new') {
      return {
        tempId: uniqueId('document'),
        master_template_id: masterTemplate.id,
        mappings: [],
      };
    }

    if (isNumber(parseInt(documentId))) {
      return findWhere(documents, { id: parseInt(documentId) });
    }

    throw new Error(`Unexpected document id: ${documentId}`);
  };

  const [state, dispatch] = useReducer(documentReducer, { documents: masterTemplate.documents, currentDocument: findFocusedDocument(masterTemplate.documents) });

  const renderVersionInfo = (doc) => {
    const text = doc.published_at ? <>Versions {doc.start_version} - { doc.main ? 'Latest' : doc.stop_version}</>
      : 'Draft';

    return (
      <div className='documentmanager-listingversion'>
        {text}
      </div>
    );
  };

  const renderDocumentInList = (documentInList, index, arr) => {
    return (
      <li key={documentInList.id} className={documentInList.id === state.currentDocument.id ? 'active' : ''}>
        <Link
          to={`${documentInList.id}`}
        >
          {documentInList.name || 'Untitled document'}
          {renderVersionInfo(documentInList)}
        </Link>
      </li>
    );
  };

  const sortedDocs = useMemo(() => {
    return state.documents.sort((doc1, doc2) => {
      if (!!doc1.published_at && !doc2.published_at) { return -1; }
      if (doc1.stop_version > doc2.stop_version) { return -1; }
      return 1;
    });
  }, [state.documents]);

  const renderDocumentListing = () => {
    return (
      <>
        <li key='new' className={documentId === 'new' ? 'active' : ''}>
          <Link
            to='new'
            id='addnewdoclink'
          >
            Add new document
          </Link>
        </li>
        {sortedDocs.map(renderDocumentInList)}
      </>
    );
  };

  return (
    <DocumentDispatchContext.Provider value={dispatch}>
      <div id='documentmanager'>
        <ul id='documentmanager-listing'>
          {renderDocumentListing()}
        </ul>

        <DocumentConfig
          key={state.currentDocument.id}
          document={state.currentDocument}
          defaultMappables={defaultMappables}
          latestTemplateVersionNumber={masterTemplate.latest_version_number}
        />
      </div>
    </DocumentDispatchContext.Provider>
  );
};

DocumentManager.propTypes = {

};

export default DocumentManager;
