import React from 'react';

const AutocompleteSuggestions = props => {
  if (props.userInput.length > 0) {
    return (
      <ul className={[props.className, 'optionlist'].join(' ')}>
        {props.suggestions.map((suggestion, index) => (
          <li key={index}>
            <a
              className="block"
              tabIndex="0"
              onKeyPress={e => props.onKeyPress(e.key, suggestion)}
              onClick={() => props.onClick(suggestion)}
            >
              {suggestion}
            </a>
          </li>
        ))}
				<li className='addressinput-usecustom' key='userInput'>
					<a className="block"
						 tabIndex="0"
						 onKeyPress={e => props.onKeyPress(e.key, props.userInput)}
						 onClick={() => props.onClick(props.userInput)}>

						Use {props.userInput}
					</a>
				</li>
      </ul>
    );
  } else {
    return null;
  }
};

export default AutocompleteSuggestions;
