import React, { memo, useState, useRef } from 'react';
import DomainManagerActions from 'actions/domain-manager-actions';
import validDomain from 'modules/domain-validator';
import Flash from 'components/shared/flash';
import { isEmpty, union, reject } from 'underscore';
import AlertConfirm from 'modules/alert-confirm';

const DomainManager = memo((props) => {
  const newDomainField = useRef();
  const [addingDomain, setAddingDomain] = useState(false);
  const [domains, setDomains] = useState(props.domains);

  const onAddDomainClick = (e) => {
    e.preventDefault();

    setAddingDomain(true);
  };

  const onSaveDomainClick = (e) => {
    const newDomain = newDomainField.current.value.trim();

    if (!validateDomain(newDomain)) { return; }

    DomainManagerActions.addDomain(props.teamId, newDomain)
                        .done(onDomainAdded);
  };

  const onDomainAdded = (res) => {
    const newDomain = res;
    newDomainField.current.value = '';
    setDomains(union(domains, [newDomain]));
    Flash.success('Domain added');
  };

  const validateDomain = (domain) => {
    if (isEmpty(domain)) {
      Flash.error('Type a domain name to add');
      return false;
    }

    if (!validDomain(domain)) {
      Flash.error(`${domain} is not a valid domain.`);
      return false;
    }

    if (domains.includes(domain)) {
      Flash.error('That domain has already been added.');
      return false;
    }

    return true;
  };

  const renderDomainInput = () => {
    if (!addingDomain) { return; }

    return (
      <li>
        <input id='adddomaininput' className='inline-block' placeholder='example.com' type='text' ref={newDomainField} />
        <button id='savedomainbutton' className='btn-primary small' onClick={onSaveDomainClick}>Save</button>
      </li>
    );
  };

  const renderAddLink = () => {
    if (props.disabled) { return; }
    if (addingDomain) { return; }

    return (
      <li>
        <a id='adddomainbutton' href='#' role='button' onClick={onAddDomainClick}>+ Add domain</a>
      </li>
    );
  };

  const renderDomains = () => {
    return domains.map(renderDomain);
  };

  const renderDomain = (teamDomain) => {
    return (
      <li key={teamDomain.id}>
        {teamDomain.domain}
        <span
          role='button'
          aria-label='Delete domain'
          className='icon icon-trash-grey margin-left-less vmiddle clickable'
          onClick={() => { onDomainDeleteClick(teamDomain.id); }}
        />
      </li>
    );
  };

  const onDomainDeleteClick = (teamDomainId) => {
    AlertConfirm.confirm(
      'Delete domain?', (
        <span>
          Are you sure you want to delete this domain? &nbsp;
          <a target='_blank' href='https://help.citygrows.com/en/articles/4382220-internal-domains' rel='noreferrer'>Learn more about how this might affect your users.</a>
        </span>
      ), () => {
        DomainManagerActions.deleteDomain(teamDomainId)
                          .done(() => { onDomainDestroyed(teamDomainId); });
      },
    );
  };

  const onDomainDestroyed = (teamDomainId) => {
    setDomains(reject(domains, (teamDomain) => {
      return teamDomain.id === teamDomainId;
    }));
  };

  return (
    <>
      <label className='margin-top-more'>
        <span>Team domains</span>
      </label>
      <ul id='teamdomains'>
        {renderDomains()}
        {renderDomainInput()}
        {renderAddLink()}
      </ul>
    </>
  );
});

export default DomainManager;
