import React from 'react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UpDownArrow = ({ expanded, setExpanded }) => {
  if (expanded) {
    return (
      <button className='btn-link' onClick={() => {setExpanded(false)}}>
        <FontAwesomeIcon
          icon={icon({ name: 'chevron-up' })}
          style={{ color: '#0463B7', height: '1rem' }}
        /> 
      </button>
    )
  }

  return ( 
    <button className='btn-link' onClick={() => {setExpanded(true)}}>
      <FontAwesomeIcon
        icon={icon({ name: 'chevron-down' })}
        style={{ color: '#0463B7', height: '1rem' }}
      /> 
    </button>
  )
};

export default UpDownArrow;
