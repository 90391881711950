import RefundAPI from 'apis/refund-api';
import TeamAPI from 'apis/team-api';
import ChargeAPI from 'apis/charge-api';
import ActiveStepAPI from '../apis/active-step-api';
import PaymentAPI from '../apis/payment-api';
import ActiveStepConstants from '../constants/active-step-constants';
import Constants from '../constants/initiated-flow-constants';
import Flash from '../components/shared/flash';

function api(type) {
  return type === 'PaymentStep' ? ActiveStepAPI : PaymentAPI;
}

function action(type, action) {
  if (type === 'PaymentStep') {
    return ActiveStepConstants.CHANGES_PERSISTED;
  }

  return Constants.PAYMENT_UPDATED;
}

const PaymentStepActions = {
  submitPayment(dispatch, paymentId, data) {
    ChargeAPI.create(paymentId, data);
    // TODO: Dispatch
  },

  update(dispatch, paymentId, attrs, type) {
    return api(type).update(paymentId, attrs).done((res) => {
      dispatch({
        type: action(type, 'updating'),
        payment: res,
      });
    });
  },

  checkReceived(dispatch, activeStepId, paymentFormAttrs, type) {
    api(type).receiveCheck(activeStepId, paymentFormAttrs)
      .done((res) => {
        dispatch({
          type: action(type, 'receiving'),
          payment: res,
        });
      });
  },

  cashReceived(dispatch, activeStepId, amount, type) {
    api(type).receiveCash(activeStepId, amount)
      .done((res) => {
        dispatch({
          type: action(type, 'receiving'),
          payment: res,
        });
      });
  },

  waivePayment(dispatch, activeStepId, type) {
    api(type).waivePayment(activeStepId)
      .done((res) => {
        dispatch({
          type: action(type, 'waiving'),
          payment: res,
        });
      });
  },

  unwaivePayment(dispatch, activeStepId, type) {
    api(type).unwaivePayment(activeStepId)
      .done((res) => {
        dispatch({
          type: action(type, 'unwaiving'),
          payment: res,
        });
      });
  },

  removeCheckPayment(dispatch, activeStepId, type) {
    api(type).removeCheckPayment(activeStepId)
      .done((res) => this.onCheckPaymentRemoved(dispatch, res, type))
      .fail(() => { Flash.error(); });
  },

  removeCashPayment(dispatch, activeStepId, type) {
    api(type).removeCashPayment(activeStepId)
      .done((res) => this.onCashPaymentRemoved(dispatch, res, type))
      .fail(() => { Flash.error(); });
  },

  onCheckPaymentRemoved(dispatch, activeStep, type) {
    Flash.success('Check payment removed');

    dispatch({
      type: action(type, 'removing'),
      activeStep: activeStep,
      payment: activeStep,
    });
  },

  onCashPaymentRemoved(dispatch, activeStep, type) {
    Flash.success('Cash payment removed');

    dispatch({
      type: action(type, 'removing'),
      activeStep: activeStep,
      payment: activeStep,
    });
  },

  approveRefundRequest(chargeId) {
    RefundAPI.approveRequest(chargeId)
             .done((res) => {
               if (res.errors.length > 0) {
                 Flash.error(res.errors[0]);
               } else {
                 window.location.reload();
               }
             })
             .fail(() => { Flash.error(); });
  },

  cancelRefundRequest(chargeId) {
    RefundAPI.cancelRequest(chargeId)
             .done(() => { window.location.reload(); });
  },

  pollForCharge(dispatch, id, type) {
    return PaymentAPI.getActiveCharge(id)
      .done((res) => {
        const charge = res.charges[0];

        if (!charge) { return; }

        switch (charge.status) {
          case 'pending':
            Flash.success('Payment Processing')
            break;
          case 'paid':
            Flash.success('Payment completed')
            break;
          default:
            throw new Error(`Unhandled status ${charge.status}`);
        }

        if(type === 'PaymentStep') {
          dispatch({
            type: ActiveStepConstants.CHANGES_PERSISTED
          })
        } else if(type === 'PaymentRequirement') {
          dispatch({
            type: Constants.PAYMENT_MADE,
            charge,
            completed: charge.status === 'paid'
          })
        }
      });
  },

  checkChargesEnabled(initiatedFlowId) {
    return TeamAPI.checkChargesEnabled(initiatedFlowId)
  },
//
//
};

export default PaymentStepActions;
