import React from 'react';
import colorForApproval from 'modules/color-for-approval';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { isNull } from 'underscore';

const PreviousReview = (props) => {
  const subject = () => {
    if (CityGrows.Server.currentUserId === props.approval.user_id) {
      return 'you previously';
    }
    return props.approval.user_name;
  };

  const renderSignature = () => {
    if (props.approval.signature_url) {
      return (
        <div className='signaturebox-sigline vmiddle'>
          <span className='signaturebox-x'>x</span>
          <img src={props.approval.signature_url} title={`Signature for ${props.approval.user_name}`} />
        </div>
      );
    }
  };

  return (
    <div className='review-previous capitalize-first'>
      <span className='margin-right'>
        {subject()}&nbsp;
        <strong className={colorForApproval(props.approval)}>{props.approval.verb}</strong> on {dayjs(props.approval.created_at).format('l')}&nbsp;
      </span>
      {isNull(props.approval.approval_status) && (
        <button className='clickable secondary btn-link' type='button' onClick={props.onOpenDiscussionClick}>
          View
        </button>
      )}
      {renderSignature()}
    </div>
  );
};

PreviousReview.propTypes = {
  approval: PropTypes.shape({
    id: PropTypes.number.isRequired,
    verb: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    signature_url: PropTypes.string.isRequired,
    user_name: PropTypes.string.isRequired,
    user_id: PropTypes.number.isRequired,
    approval_status: PropTypes.bool,
  }).isRequired,
  onOpenDiscussionClick: PropTypes.func,
};
export default PreviousReview;
