import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import TemplateManagerActions from 'actions/template-manager-actions';
import EmbeddedTextInput from 'components/shared/embedded-text-input';
import { debounce } from 'underscore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import UserPreferenceActions from 'actions/user-preference-actions';

const DEFAULT_NAME = 'Untitled form';

const FlowTemplateName = (props) => {
  const [isStarredForm, setIsStarredForm] = useState(props.flowTemplate.is_starred_form);
  const onNameChange = (newName) => {
    TemplateManagerActions.updateFlowTemplate(props.flowTemplate.id, {
      name: newName,
    });
  };

  const starForm = (id) => {
    UserPreferenceActions.starForm(id)
      .done(() => {
        setIsStarredForm(true);
      });
  };

  const unstarForm = (id) => {
    UserPreferenceActions.unstarForm(id)
      .done(() => {
        setIsStarredForm(false);
      });
  };

  const renderStarIcon = () => {
    if (isStarredForm) {
      return (
        <div className='stariconcontainer starred'>
          <FontAwesomeIcon
            icon={icon({ name: 'star', style: 'solid' })}
            onClick={() => unstarForm(props.flowTemplate.master_template_id)}
            className='staricon starred'
            size='lg'
            title='Click to unstar this form'
          />
        </div>
      );
    }
    return (
      <div className='stariconcontainer'>
        <FontAwesomeIcon
          icon={icon({ name: 'star', style: 'regular' })}
          onClick={() => starForm(props.flowTemplate.master_template_id)}
          className='staricon'
          size='lg'
          title='Click to star this form'
        />
      </div>
    );
  };

  return (
    <div className='viewall-container' style={{ marginTop: '1.5rem' }}>
      <EmbeddedTextInput
        defaultValue={props.flowTemplate.name}
        disabledValue={props.flowTemplate.name || DEFAULT_NAME}
        placeholder={DEFAULT_NAME}
        disabled={props.disabled}
        onChange={useCallback(debounce(onNameChange, 500), [])}
        className='templateheader-title'
        maxWidth={700}
      />
      {renderStarIcon()}
      &nbsp;&nbsp;|&nbsp;&nbsp;<a href='/workflows'>View All Forms</a>
    </div>
  );
};

FlowTemplateName.propTypes = {
  flowTemplate: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    master_template_id: PropTypes.number.isRequired,
    is_starred_form: PropTypes.bool.isRequired,
  }).isRequired,
};

export default FlowTemplateName;
