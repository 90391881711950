import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import TablePagination from '@material-ui/core/TablePagination';
import PaginationButtons from 'components/shared/pagination-buttons';

const SearchResults = (props) => {
  const link = (flow) => {
    if (props.type === 'custom') {
      return `/search_pages/${props.slug}/results/${flow.id}?type=${flow.dashboardable_type}`;
    }

    return `/flows/${flow.id}/summary?type=${flow.dashboardable_type}`;
  };

  const renderFlowRow = (flow) => {
    const id = flow.dashboardable_type === 'ImportedFlow' ? '-' : flow.scoped_id;
    const identifierContent = flow.identifier_redacted
      ? <span className='color-text-medium'>Redacted</span>
      : flow.identifier;
    return (
      <tr key={flow.id}>
        <td>{`#${id}`}</td>
        <td>{identifierContent}</td>
        <td>{dayjs(flow.created_at).format('l')}</td>
        <td><a href={link(flow)}>View</a></td>
      </tr>
    );
  };

  const handleChangePage = (_, newPage) => {
    props.searchHandler(_, newPage + 1);
  };

  const labelDisplayedRows = ({ from, to, count }) => {
    return `Showing ${from}-${to} of ${count}`;
  };

  if (props.searching) {
    return (
      <div className='margin-top-more text-center'>
        <div className='progress-wrap'>
          <div className='progress smaller'>
            <div />
          </div>
        </div>
      </div>
    );
  }

  if (!props.results) { return null; }

  if (props.results.length > 0) {
    return (
      <div className='margin-top-more'>
        <table className='datatable highlightable'>
          <thead>
            <tr>
              <th>Submission ID</th>
              <th>Identifier</th>
              <th>Launched</th>
              <th>Summary</th>
            </tr>
          </thead>
          <tbody>
            {props.results.map(renderFlowRow)}
            {props.paginated
              && (
                <tr className='pagination-row'>
                  <TablePagination
                    rowsPerPageOptions={[props.rowsPerPage]}
                    colSpan={4}
                    count={props.totalResults}
                    rowsPerPage={props.rowsPerPage}
                    page={props.currentPage - 1} // mui pagination is zero based
                    labelDisplayedRows={labelDisplayedRows}
                    onChangePage={handleChangePage}
                    ActionsComponent={PaginationButtons}
                  />
                </tr>
              )}
          </tbody>
        </table>
      </div>
    );
  }
  return (
    <div className='margin-top-more text-center'>
      <p className='color-text-medium'>No results found.</p>
    </div>
  );
};

SearchResults.propTypes = {
  searching: PropTypes.bool,
  results: PropTypes.array,
  currentPage: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalResults: PropTypes.number,
  paginated: PropTypes.bool,
  slug: PropTypes.string,
  type: PropTypes.string,
  searchHandler: PropTypes.func,
};

export default SearchResults;
