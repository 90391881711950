import React from "react";

import SlidingToggle from "../sliding-toggle";


const ToggleOption = ({
  on,
  changeFilterValue,
}) => {
  const toggledOn = on === 'true' || on === true;

  return (
    <SlidingToggle
      on={toggledOn}
      onToggle={() => changeFilterValue(!toggledOn)}
    />
  );
};

export default ToggleOption;
