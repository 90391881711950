import React from 'react';
import PropTypes from 'prop-types';
import UserAccessActions from 'actions/user-access-actions';
import AutonomousPopup from 'components/shared/autonomous-popup';

class MemberUserPseudoInfo extends React.Component {
  onResendInviteClick = (e) => {
    e.preventDefault();
    UserAccessActions.resendInvite(this.props.invitationId);
  };

  render() {
    return (
      <span className='useraccess-pseudo'>
        <em> (Invited)</em>

        <AutonomousPopup closeOnPopupClick buttonClassName='ellipsispopup-grey' nubDirection='left'>
          <ul className='popup-listmenu'>
            <li>
              <a onClick={this.onResendInviteClick} href='#'>Resend invite</a>
            </li>
          </ul>
        </AutonomousPopup>
      </span>
    );
  }
}

MemberUserPseudoInfo.propTypes = {
  invitationId: PropTypes.number.isRequired,
};

export default MemberUserPseudoInfo;
