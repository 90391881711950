import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Flash from 'components/shared/flash';
import EmailValidator from 'modules/email-validator';
import ListOfPeople from 'components/shared/list-of-people';
import CloseIfOutsideWrapper from 'components/shared/close-if-outside-wrapper';
import { isEmpty, findWhere, debounce } from 'underscore';

const PersonSelector = (props) => {
  const [active, setActive] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searching, setSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [inviteeName, setInviteeName] = useState('');

  function onClose() {
    setInviteeName('');
    setActive(false);
  }

  function onSendInvite() {
    if (inviteeName.length < 1) {
      Flash.error('Please enter a name');
      return;
    }

    sendInvite();
  }

  function sendInvite() {
    const email = searchInput;
    props.onInvite(email, inviteeName);

    onClose();
  }

  function onInviteeNameChanged(e) {
    setInviteeName(e.currentTarget.value);
  }

  function onInputChanged(e) {
    const newTerms = e.currentTarget.value;
    setSearchInput(newTerms);
    setSearching(true);

    if (isEmpty(newTerms) || newTerms.length < 2) {
      onClose();
      return;
    }

    setActive(true);

    search(newTerms);
  }

  const search = useCallback(debounce((newTerms) => {
    props.doSearch(newTerms)
         .done((r) => {
           setSearchResults(r);
           setSearching(false);
         })
         .fail(() => { Flash.error('Something went wrong.'); });
  }, 333), []);

  function existingMember(person) {
    if (props.attachableType == 'MasterTemplateMembership') {
      return findWhere(props.memberUsers, { user_id: person.id });
    }

    return findWhere(props.memberUsers, { id: person.id });
  }

  function onPersonSelect(person) {
    const existingAttachment = existingMember(person);
    if (!props.allowToggle && existingAttachment) {
      Flash.error('Member already added.');
      return;
    } if (existingAttachment) {
      props.onToggleOff(existingAttachment);
    } else {
      props.onSelect(person);
    }

    onClose();
  }

  function renderContent() {
    return (
      <CloseIfOutsideWrapper onClose={onClose}>
        <div id={props.id}>
          {renderSearchInput()}
          {active && renderSearchResults()}
        </div>
      </CloseIfOutsideWrapper>
    );
  }

  function renderSearchInput() {
    return (
      <input
        onFocus={onInputChanged}
        autoComplete='chrome-off'
        onChange={onInputChanged}
        style={props.inputStyle}
        className='search people margin-bottom'
        type='text'
        placeholder={props.placeholder}
        id={props.idForInput}
      />
    );
  }

  function renderSearchResults() {
    let content;
    if (searchResults.length > 0) {
      content = (
        <ListOfPeople
          users={searchResults}
          onSelect={onPersonSelect}
          hideExternal={props.hideExternal}
        />
      );
    } else if (searching) {
      content = 'Searching...';
    } else if (EmailValidator.validate(searchInput) && props.allowPseudo) {
      content = invitePrompt();
    } else {
      content = props.personNoFoundText || 'Nothing matches those search terms';
    }

    return (
      <div className='attachedpeople-manager search-results'>
        {content}
      </div>
    );
  }

  function invitePrompt() {
    return (
      <div>
        <p>We can't find that email address in our system. Add a name and we'll send them an invite.</p>
        <label>Name</label>
        <input type='text' value={inviteeName} onChange={onInviteeNameChanged} />
        <div className='btn-primary' onClick={onSendInvite}>
          Send invite to {inviteeName}
        </div>
      </div>
    );
  }

  return renderContent();
};

PersonSelector.propTypes = {
  hideExternal: PropTypes.bool.isRequired,
  allowPseudo: PropTypes.bool.isRequired,
  memberUsers: PropTypes.array,
  allowToggle: PropTypes.bool,
  onToggleOff: PropTypes.func,
  onClose: PropTypes.func,
  onInvite: PropTypes.func,
  placeholder: PropTypes.string,
  inputStyle: PropTypes.object,
  id: PropTypes.string,
  personNoFoundText: PropTypes.string,
};

PersonSelector.defaultProps = {
  placeholder: 'Type or paste an email, name or ClearForms username',
};

export default PersonSelector;
