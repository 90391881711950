import React from 'react';
import PropTypes from 'prop-types';
import UserDisc from './user-disc';
import XClose from './x-close';

const Selection = (props) => {
  const renderRemoveLink = () => {
    if (props.disableRemoval) {
      return;
    }

    if (props.removeLinkText) {
      return (
        <span onClick={props.onRemove} className='personselection-custom-remove-link clickable'>{props.removeLinkText}</span>
      );
    }

    return (
      <XClose onClick={props.onRemove} buttonClass='margin-left inline-block' label='Remove person'/>
    );
  };

  return (
    <div className='personselection'>
      <UserDisc
        user={props.selection}
        className='margin-right'
        options={{
          showName: true,
          showAsCurrent: props.showAsCurrentUser,
          smallerAvatar: props.smallerAvatar,
        }}
      />

      {renderRemoveLink()}
    </div>
  );
};

Selection.propTypes = {
  selection: PropTypes.shape({}),
  showAsCurrentUser: PropTypes.bool,
  removeLinkText: PropTypes.string,
  disableRemoval: PropTypes.bool,
  smallerAvatar: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
};

export default Selection;
