import UserAPI from '../apis/user-api';
import InitiatedFlowAPI from '../apis/initiated-flow-api';

const LaunchManagerActions = {
  checkIfAccountExists(email) {
    return UserAPI.exists(email);
  },

  previouslyLaunched(masterTemplateId) {
    return InitiatedFlowAPI.previouslyLaunched(masterTemplateId);
  },
};

export default LaunchManagerActions;
