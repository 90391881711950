import React from 'react';
import _ from 'underscore';
import AdminStepGroup from './admin-step-group';
import ConstStepGroup from './const-step-group';

const StepBreakdown = (props) => {
  const renderSteps = () => {
    // Group steps by change of responsibility
    let currentGroup = [];
    const grouped = [];

    _.each(props.steps, (step) => {
      if (_.isEmpty(currentGroup) || (step.external === _.last(currentGroup).external)) {
        currentGroup.push(step);
      } else {
        grouped.push(currentGroup);
        currentGroup = [step];
      }
    });

    grouped.push(currentGroup);

    return grouped.map(renderGroup);
  };

  const renderGroup = (group) => {
    switch (group[0].external) {
    case true:
      return (
        <ConstStepGroup
          steps={group}
          cityName={props.cityName}
          key={group[0].id}
          digest={props.digest}
        />
      );
    case false:
      return (
        <AdminStepGroup
          steps={group}
          cityName={props.cityName}
          key={group[0].id}
          digest={props.digest}
        />
      );
    default:
      throw `Unhandled group type ${group[0].external}`;
    }
  };

  return (
    <div id='stepbreakdown'>
      {renderSteps()}
    </div>
  );
};

export default StepBreakdown;
