import React from 'react';
import renderer from 'modules/renderer';
import Dashboard from 'components/dashboard';
import CommonProviders from 'components/common-providers';
import DashboardOutputs from 'components/dashboard-outputs';

function index() {
  renderer(
    (
      <CommonProviders>
        <Dashboard
          savedFilters={CityGrows.Server.savedFilters}
          defaultWasInaccessible={CityGrows.Server.defaultWasInaccessible}
          defaultFilter={CityGrows.Server.defaultFilter}
          userId={CityGrows.Server.userId}
          teams={CityGrows.Server.teams}
          hasTemplates={CityGrows.Server.hasTemplates}
          tagNames={CityGrows.Server.tagNames}
        />
      </CommonProviders>
    ), document.getElementById('dashboard'),
  );
}

function outputs() {
  renderer(
    (
      <CommonProviders>
        <DashboardOutputs
          savedFilters={CityGrows.Server.savedFilters}
          defaultFilter={CityGrows.Server.defaultFilter}
        />
      </CommonProviders>
    ), document.getElementById('dashboard'),
  );
}

export default {
  index,
  outputs
};
