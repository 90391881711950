import React from 'react';
import warn from 'warning';

let hasLocalStorage = true;
const testKey = 'react-localstorage.mixin.test-key';
let ls;
try {
  // Access to global `localStorage` property must be guarded as it
  // fails under iOS private session mode.
  ls = global.localStorage;
  ls.setItem(testKey, 'foo');
  ls.removeItem(testKey);
} catch (e) {
  hasLocalStorage = false;
}

function fetchStateFromLocalStorage(key) {
  if (!hasLocalStorage) return;
  if (key === false) return;
  try {
    const storedState = JSON.parse(ls.getItem(key));
    if (storedState) {
      return storedState;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    if (console) console.warn(e);
  }
}

function saveStateToLocalStorage(key, saveState) {
  if (!hasLocalStorage) return;
  if (key === false) return;
  ls.setItem(key, JSON.stringify(saveState));
}

export default {
  fetch: fetchStateFromLocalStorage,
  save: saveStateToLocalStorage,
  exists: hasLocalStorage,
};
