import PropTypes from 'prop-types';
import React, { Component } from 'react';
import EmailValidator from 'modules/email-validator';

class EmailStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: this.props.defaultValue,
    };
  }

  emailValid = () => {
    return EmailValidator.validate(this.state.email);
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.props.onSubmit(this.refs.input.value.trim());
  };

  onEmailChange = (e) => {
    this.setState({
      email: e.currentTarget.value,
    });
  };

  render() {
    return (
      <div>
        <h1>Let's get started</h1>
        <span className='launchmanager-instruction'>First things first, what's a good email address for us to contact you at?</span>

        <form className='center' onSubmit={this.onSubmit}>
          <label>Email
            <input type='email' name='email' ref='input' autoFocus defaultValue={this.state.email} onChange={this.onEmailChange} placeholder='example@email.com' />
          </label>

          <button className='btn-primary' type='submit' disabled={!this.emailValid()}>{'Next >'}</button>
        </form>

        <a href='/users/sign_in'>Want to resume a submission you already started?</a>
      </div>
    );
  }
}

EmailStep.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
};

export default EmailStep;
