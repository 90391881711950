import React, { useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import AlertConfirm from 'modules/alert-confirm';
import PaymentStepActions from 'actions/payment-step-actions';

const CashForm = (props) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);
  const amountReceived = useRef();

  function cashReceivedClicked() {
    const amount = amountReceived.current.value;

    if (!$.isNumeric(amount) || amount <= 0) {
      AlertConfirm.alert('Whoops', 'Cash amount must be a valid number. Check your input and try again.');
      return;
    }

    PaymentStepActions.cashReceived(dispatch, props.activeStep.id, amount, props.type);
  }

  function adminView() {
    return (
      <div>
        <input name='amountreceived' className='margin-top' type='text' placeholder='Amount received' ref={amountReceived} />
        <div className='margin-top'>
          <button
            type='submit'
            id='cashreceivedbutton'
            onClick={cashReceivedClicked}
            className='btn-primary'
          >Cash received
          </button>
        </div>
      </div>
    );
  }

  function publicView() {
    return (
      <>
        <p>{props.cashInstructions}</p>
        <p className='color-text-medium'><strong>Note:</strong> This page will automatically update and you will receive an email notification when your cash is received.</p>
      </>
    );
  }

  return (
    <div className='currentstep-ckecktools'>
      {props.admin ? adminView() : publicView()}
    </div>
  );
};

CashForm.propTypes = {
  admin: PropTypes.bool.isRequired,
  cashInstructions: PropTypes.string.isRequired,
  activeStep: PropTypes.object.isRequired,
};

export default CashForm;
