import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ClipboardButton from 'react-clipboard.js';
import Flash from 'components/shared/flash';

const CopyableLink = (props) => {
  const linkField = useRef();

  const selectURL = () => {
    linkField.current.select();
  };

  const onCopy = () => {
    if (!props.copyEnabled) { return; }

    selectURL();

    Flash.success(props.successMsg);
  };

  return (
    <div className='copyablelink'>
      <input id={props.inputId} ref={linkField} onClick={props.copyEnabled && selectURL} type='text' readOnly value={props.linkURL} />
      <ClipboardButton
        onSuccess={onCopy}
        component='a'
        data-clipboard-text={props.linkURL}
        className={props.copyEnabled ? '' : 'disabled'}
      >
        <i className='icon-copy' />
      </ClipboardButton>
    </div>
  );
};

CopyableLink.propTypes = {
  linkURL: PropTypes.string.isRequired,
  copyEnabled: PropTypes.bool,
  successMsg: PropTypes.string,
};

CopyableLink.defaultProps = {
  copyEnabled: true,
  successMsg: 'URL copied',
};

export default CopyableLink;
