import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { filter, isEmpty } from 'underscore';
import ListOfPeoplePerson from './list-of-people/person';

const ListOfPeople = (props) => {
  const [showExternal, setShowExternal] = useState(false);

  const buildPerson = (person) => {
    return (
      <ListOfPeoplePerson
        person={person}
        onSelect={onPersonSelect}
        key={person.id}
      />
    );
  };

  const filteredInternalPeople = useMemo(() => {
    if (!props.hideExternal) { return props.users; }

    const filterFunc = (user) => {
      // This group should include those who are internal _as well as_
      // those who are already attached to the process.
      return user.internal === true;
    };

    return filter(props.users, filterFunc);
  }, [props.hideExternal, props.users]);

  const filteredExternalPeople = useMemo(() => {
    if (!props.hideExternal) { return []; }

    const filterFunc = (user) => {
      return user.internal === false;
    };

    return filter(props.users, filterFunc);
  }, [props.hideExternal, props.users]);

  const onPersonSelect = (person) => {
    props.onSelect(person, 'User');
  };

  const renderInternalPeople = () => {
    if (!isEmpty(filteredInternalPeople)) {
      return filteredInternalPeople.map(buildPerson);
    }
    return (
      <div className='margin-left margin-top margin-bottom'>No results within your teams</div>
    );
  };

  const renderExternalPeople = () => {
    if (!isEmpty(filteredExternalPeople)) {
      if (showExternal) {
        return (
          <>
            <div className='attachedpeople-manager-resultlabel'>External results</div>
            <ul className='attachedpeople-manager-peoplelist'>
              {filteredExternalPeople.map(buildPerson)}
            </ul>
          </>
        );
      }
      return (
        <button onClick={() => { setShowExternal(true); }}>Show {filteredExternalPeople.length} matches from outside your teams</button>
      );
    }
  };

  const externalClassMargin = () => {
    return showExternal ? 'margin-top-less' : '';
  };

  return (
    <div>
      <div className='attachedpeople-manager-internalresults'>
        <ul className='attachedpeople-manager-peoplelist'>
          {renderInternalPeople()}
        </ul>
      </div>

      <div className={`attachedpeople-manager-externalresults ${externalClassMargin()}`}>
        {renderExternalPeople()}
      </div>
    </div>
  );
};

ListOfPeople.propTypes = {
  hideExternal: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  users: PropTypes.shape({}).isRequired,
};

ListOfPeople.defaultProps = {
  hideExternal: false,
  onSelect() {},
  users: [],
};

export default ListOfPeople;
