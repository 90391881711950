import React from 'react';

const XClose = ({label, onClick, buttonClass, id}) => {
  return (
    <button type='button' className={`btn-link close ${buttonClass}`} onClick={onClick} id={id}>
      <i title={label} className='icon-x' />
      <span className='hidden'>Close</span>
    </button>
  );
};


export default XClose;
