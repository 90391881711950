export const primary = '#5FAE7F'; // CityGrows green
export const secondary = '#597A91'; // Slate blue
export const color1 = primary;
export const color2 = secondary;
export const color3 = '#677A7E'; // Primary Grey
export const color4 = '#5E9998'; // Teal
export const color5 = '#BCD0D9'; // Pale blue
export const color6 = '#4F6A78'; // Dark blue
export const color7 = '#00B9AD'; // Cyan
export const color8 = '#1B3A3E'; // Almost black
export const color9 = '#D0E8CF'; //Light green
export const colorBackground = '#f2f2f2';
export const colorNeutralLight = 'rgba(0, 0, 0, 0.08)';
export const colorTextMedium = 'rgba(0, 0, 0, .54)';
