const ApplicantAttachmentAPI = {
  addApplicant(userId, initiatedFlowId) {
    const url = '/applicant_attachments/';
    return $.ajax(url, {
      data: {
        user_id: userId,
        initiated_flow_id: initiatedFlowId,
      },
      method: 'POST',
      dataType: 'json',
    });
  },

  makePrimary(applicantId) {
    const url = '/applicant_attachments/make_primary';
    return $.ajax(url, {
      data: {
        id: applicantId,
      },
      method: 'POST',
      dataType: 'json',
    });
  },

  removeApplicant(applicantId) {
    const url = `/applicant_attachments/${applicantId}`;
    return $.ajax(url, {
      method: 'DELETE',
      dataType: 'json',
    });
  },

  addPseudoApplicant(email, name, initiatedFlowId) {
    const url = '/applicant_attachments/add_pseudoapplicant';
    return $.ajax(url, {
      data: {
        email,
        name,
        initiated_flow_id: initiatedFlowId,
      },
      method: 'POST',
      dataType: 'json',
    });
  },
};

export default ApplicantAttachmentAPI;
