import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PollableJob from 'modules/pollable-job';
import LoadingSpinner from 'components/shared/loading-spinner';

const BackgroundedJobLink = (props) => {
  let timeout;

  const [isWorking, setIsWorking] = useState(false);
  const [showPatienceWarning, setShowPatienceWarning] = useState(false);

  const schedulePatienceWarning = () => {
    timeout = setTimeout(() => { setShowPatienceWarning(true); }, 10000);
  };

  const resetDownloadButton = () => {
    clearTimeout(timeout);
    setIsWorking(false);
    setShowPatienceWarning(false);
  };

  const onDownloadClick = (e) => {
    e.preventDefault();

    if (isWorking) { return; }

    setIsWorking(true);
    schedulePatienceWarning();

    const jobRunner = new PollableJob(props.queueFunction);
    jobRunner.run()
             .done(props.onJobComplete)
             .fail(props.onFail)
             .always(resetDownloadButton);
  };

  const buttonText = () => {
    if (showPatienceWarning) {
      return props.patienceWarning;
    } if (isWorking) {
      return props.workingText;
    }

    return props.children;
  };

  return (
    <button onClick={onDownloadClick} disabled={props.disabled} className={props.className}>
      {isWorking && <LoadingSpinner className='margin-right-less vmiddle' colorScheme='dark' />}
      <span className='vmiddle'>{buttonText()}</span>
    </button>
  );
};

BackgroundedJobLink.propTypes = {
  queueFunction: PropTypes.func.isRequired,
  onJobComplete: PropTypes.func,
  onFail: PropTypes.func,
  patienceWarning: PropTypes.string,
  workingText: PropTypes.string,
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

BackgroundedJobLink.defaultProps = {
  patienceWarning: 'Still working...',
  className: '',
  disabled: false,
};

export default BackgroundedJobLink;
