import TeamDomainAPI from '../apis/team-domain-api';
import Flash from '../components/shared/flash';

const TeamDomainActions = {
  addDomain: (teamId, domain) => {
    return TeamDomainAPI.create(teamId, domain)
                        .fail(() => {
                          Flash.error('Unable to add domain. Try again later.');
                        });
  },

  deleteDomain: (teamDomainId) => {
    return TeamDomainAPI.destroy(teamDomainId)
                        .fail(() => {
                          Flash.error('Unable to delete domain. Try again later.');
                        });
  },
};

export default TeamDomainActions;
