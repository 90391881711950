import React, { useState } from 'react';
import DiscussionContent from 'components/shared/discussion-content';
import { isFunction } from 'underscore';
import PropTypes from 'prop-types';
import XClose from 'components/shared/x-close';
import useFocusTrap from 'components/hooks/use-focus-trap';

const ProcessDiscussion = (props) => {
  const [addingNewDiscussion, setAddingNewDiscussion] = useState(false);
  const focusEl = useFocusTrap(props.onCloseClick);

  function onBackButtonClick(e) {
    e.preventDefault();
    setAddingNewDiscussion(false);
  }

  function panelTitle() {
    return addingNewDiscussion
      ? 'New discussion'
      : 'Discussion';
  }

  function renderHeaderFunction() {
    return addingNewDiscussion
      ? <a onClick={onBackButtonClick.bind(this)} href='#' className='secondary'>{'< Back'}</a>
      : <XClose onClick={onCloseClick} label='Close discussions' />;
  }

  function onCloseClick() {
    if (isFunction(props.onCloseClick)) {
      props.onCloseClick();
    }

    setAddingNewDiscussion(false);
  }

  function onDiscussionOpened() {
    setAddingNewDiscussion(false);
  }

  return (
    <div id='discussion' className='process' ref={props.transitionRef}>
      <div ref={focusEl}>
        <div className='ifslidingpanel-header'>
          <h2>{panelTitle()}</h2>
          {renderHeaderFunction()}
        </div>

        <div className='ifslidingpanel-contentwrap'>
          <DiscussionContent
            initiatedFlowId={props.initiatedFlowId}
            admin={props.admin}
            onNewDiscussionClick={() => { setAddingNewDiscussion(true); }}
            addingNewDiscussion={addingNewDiscussion}
            onDiscussionOpened={onDiscussionOpened}
            discussions={props.discussions}
          />
        </div>
      </div>
    </div>
  );
};

ProcessDiscussion.propTypes = {
  initiatedFlowId: PropTypes.number.isRequired,
  admin: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func,
};

export default ProcessDiscussion;
