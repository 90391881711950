import React, { useState } from 'react';
import PropTypes from 'prop-types';
import truncate from 'truncate';

const NUM_TAGS_TO_SHOW = 3;
const TAG_CHAR_LENGTH = 25;

const TagList = ({ taggings }) => {
  const [showAll, setShowAll] = useState(false);

  const renderTagging = (tagging) => {
    return (
      <li
        className='tag'
        key={tagging.id}
        title={tagging.tag.name}
      >
        {truncate(tagging.tag.name, TAG_CHAR_LENGTH)}
      </li>
    );
  };

  const firstTaggings = () => {
    const numToShow = showAll ? taggings.length : NUM_TAGS_TO_SHOW;
    return taggings.slice(0, numToShow)
                   .map(renderTagging);
  };

  const moreTaggings = () => {
    const numTaggings = taggings.length;
    if (numTaggings <= NUM_TAGS_TO_SHOW) {
      return;
    }

    let content;
    if (showAll) {
      content = 'Show less';
    } else {
      content = (
        <>Show {taggings.length - NUM_TAGS_TO_SHOW} more</>
      );
    }

    return (
      <button
        className='tag tag-more margin-right'
        onClick={() => {
          setShowAll(!showAll);
        }}
      >
        {content}
      </button>
    );
  };

  return (
    <>
      {firstTaggings()}
      {moreTaggings()}
    </>
  );
};

TagList.propTypes = {
  taggings: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    tag: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  })).isRequired,
};

export default TagList;
