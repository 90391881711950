export const isLoggedIn = () => {
  const url = '/sessions/is_logged_in';

  return window.$.ajax(url, {
    method: 'GET',
    dataType: 'json',
  });
};

export default isLoggedIn;
