import React from 'react';
import renderer from 'modules/renderer';
import CommonProviders from 'components/common-providers';
import ReviewApp from '../components/review-app';
import PrimaryTabs from '../components/shared/primary-tabs';

function index() {
  renderer(<PrimaryTabs />, document.getElementById('tabs'));
}

function show() {
  renderer(<PrimaryTabs />, document.getElementById('tabs'));
  renderer(
    (
      <CommonProviders>
        <ReviewApp
          initiatedFlow={CityGrows.Server.reviewData.initiated_flow}
          flowTemplate={CityGrows.Server.reviewData.flow_template}
          reviewer={CityGrows.Server.reviewData.reviewer}
          stepId={CityGrows.Server.reviewData.step_id}
          activeReviewGroup={CityGrows.Server.reviewData.active_review_group}
          reviewStepInternalInstructions={CityGrows.Server.reviewData.review_step_internal_instructions}
          currentReviewNumber={CityGrows.Server.reviewData.current_review_number}
          outstandingReviewCount={CityGrows.Server.reviewData.outstanding_review_count}
          previousReviews={CityGrows.Server.reviewData.previous_reviews}
          nextURL={CityGrows.Server.reviewData.next_url}
          prevURL={CityGrows.Server.reviewData.prev_url}
          firstURL={CityGrows.Server.reviewData.first_url}
          completedForCurrentUser={CityGrows.Server.reviewData.completed_for_current_user}
        />
      </CommonProviders>
    ), document.getElementById('review'),
  );
}

export default {
  index,
  show,
};
