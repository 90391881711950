import PropTypes from 'prop-types';
import React, { Component } from 'react';

class PersonalInfoStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.defaultNameValue,
    };
  }

  isIncomplete = () => {
    return !this.state.name;
  };

  onSubmit = (e) => {
    e.preventDefault();

    const formData = {
      name: this.refs.name.value,
    };

    this.props.onSubmit(formData);
  };

  onBackClick = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  onChange = () => {
    this.setState({
      name: this.refs.name.value,
    });
  };

  render() {
    return (
      <div>
        <h1><i className='icon-person-dark' /> Personal Info</h1>

        <form onChange={this.onChange}>
          <label>Name
            <input type='text' ref='name' autoFocus value={this.state.name} />
          </label>

          <nav>
            <a href='#' onClick={this.props.onBackClick}>{'< Go back'}</a>
            <button className='btn-primary' disabled={this.isIncomplete()} onClick={this.onSubmit}>{'Next >'}</button>
          </nav>
        </form>
      </div>
    );
  }
}

PersonalInfoStep.propTypes = {
  onBackClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultNameValue: PropTypes.string.isRequired,
};

export default PersonalInfoStep;
