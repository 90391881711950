import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  TR, TD,
} from 'components/shared/manageable-table';
import EmbeddedTextInput from 'components/shared/embedded-text-input';
import EntityConfigurationActions from 'actions/entity-configuration-actions';
import { debounce } from 'underscore';
import EntityConfigurationDispatchContext from 'contexts/entity-configuration-dispatch-context';
import LinkyButton from 'components/shared/linky-button';
import CustomEntityField from './custom-entity-field';
import { confirm } from '../../modules/alert-confirm';
import TitleFieldSelector from './custom-entity/title-field-selector';

const CustomEntity = (props) => {
  const dispatch = useContext(EntityConfigurationDispatchContext);
  const [newField, setNewField] = useState(false);

  const nameField = () => {
    const field = {
      viewable: true,
      edit_suggestable: true,
      name: 'Name',
    };

    return (
      <CustomEntityField
        key='name'
        new={false} 
        field={field}
        isEsri={isEsri()}
        disabled
      />
    );
  };

  const customFields = (customEntity) => {
    return customEntity.custom_entity_fields.map((field) => {
      return (
        <CustomEntityField
          key={field.id}
          new={false}
          field={field}
          isEsri={isEsri()}
        />
      );
    });
  };

  const newFieldSection = () => {
    const customEntity = props.customEntity;

    if (newField) {
      const field = {
        name: '', data_type: 'string', viewable: false, edit_suggestable: false,
      };

      return (
        <CustomEntityField
          key='new'
          field={field}
          new
          customEntityId={customEntity.id}
          isEsri={isEsri()}
          cancelNewField={() => { setNewField(false); }}
          onNewFieldAdded={() => { setNewField(false); }}
        />
      );
    }

    return newFieldButton();
  };

  const newFieldButton = () => {
    return (
      <li className='entityconfiguration-add-field'>
        <button
          type='button'
          onClick={() => { setNewField(true); }}
          id='addfieldbutton'
        >
          + Add field
        </button>
      </li>
    );
  };

  const onEntityNameChanged = debounce((newName) => {
    EntityConfigurationActions.updateEntity(props.customEntity.id, {
      name: newName,
    }, dispatch);
  }, 500);

  const onArchiveClick = () => {
    confirm(`Archive ${props.customEntity.name}?`, 'Are you sure you want to archive this entity?', () => {
      EntityConfigurationActions.archive(props.customEntity.id, dispatch);
    });
  };

  const isEsri  = () => {
    return props.customEntity.type === 'EsriCustomEntity';
  };
  
  const renderEntityTypeInfo = () => {
    if (isEsri()) {
      return "ESRI";
    }
  };
  
  const expandedContent = (
    <>
      {isEsri() && (
        <TitleFieldSelector
          customEntity={props.customEntity}
          dispatch={dispatch}
        />
      )}
      <ul className='entityconfiguration'>
        {nameField()}
        {customFields(props.customEntity)}
        {!isEsri() && (
          newFieldSection()
        )}
      </ul>
      <>
        <div className='linebreak' />
        <LinkyButton
          className='text-alert'
          onClick={onArchiveClick}
        >
          Archive this entity
        </LinkyButton>
      </>
    </>
  );

  return (
    <TR expandedContent={expandedContent}>
      <TD>
        <EmbeddedTextInput
          onChange={onEntityNameChanged}
          defaultValue={props.customEntity.name}
          size='small'
          placeholder='Entity name'
        />
        <span
          className='vmiddle bold margin-left color-text-medium typelabel'
        >
          {renderEntityTypeInfo()}
        </span>
      </TD>
    </TR>
  );
};

CustomEntity.propTypes = {
  customEntity: PropTypes.shape({}).isRequired,
};

export default CustomEntity;
