

function index() {
  // Sticky nav
  //
  const $window = $(window);
  const $nav = $('nav.home-nav');
  const scrolledClassName = 'scrolled';

  $window.scroll(() => {
    if ($window.scrollTop() > 0) {
      $nav.addClass(scrolledClassName);
    } else {
      $nav.removeClass(scrolledClassName);
    }
  });

  // Word spinner
  //
  const words = document.querySelectorAll('#wordspinner span');
  const wordCount = words.length;
  let currentIdx = 0;

  function run() {
    words[currentIdx % wordCount].className = '';
    words[(currentIdx + 1) % wordCount].className = 'entering';

    currentIdx += 1;

    setTimeout(run, 2500);
  }

  setTimeout(run, 2500);
}

export default {
  index,
};
