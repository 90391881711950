import React from 'react';
import PropTypes from 'prop-types';
import { any, where, filter } from 'underscore';
import pluralize from 'modules/pluralize';
import ActiveReviewGroup from './reviews/active-review-group';
import ConstituentReviewInfo from './reviews/constituent-review-info';

const RequireReviewStep = (props) => {
  function anyThirdPartyReview() {
    return any(requiredReviewGroups(), (g) => {
      return g.review_type === 'third_party';
    });
  }

  function renderReviewGroups() {
    return requiredReviewGroups().map(renderReviewGroup);
  }

  function reviewGroupRequired(group) {
    return group.should_show;
  }

  function renderReviewGroup(group) {
    return (
      <ActiveReviewGroup
        reviewGroup={group}
        currentUserId={props.currentUserId}
        key={group.review_group_id}
        admin={props.admin}
        initiatedFlow={props.initiatedFlow}
        stepId={props.stepId}
        discussions={props.discussions}
        onOpenDiscussionClick={props.onOpenDiscussionClick}
      />
    );
  }

  function completedCount() {
    return where(props.activeStep.review_groups, { completed: true }).length;
  }

  function requiredReviewGroups() {
    return filter(props.activeStep.review_groups, (group) => { return reviewGroupRequired(group); });
  }

  function unrequiredReviewGroups() {
    return filter(props.activeStep.review_groups, (group) => { return !reviewGroupRequired(group); });
  }

  function requiredReviewCount() {
    return requiredReviewGroups().length;
  }

  function unrequiredReviewCount() {
    return unrequiredReviewGroups().length;
  }

  function unrequiredReviewGroupHTML() {
    if (props.admin) {
      const count = unrequiredReviewCount();

      if (count > 0) {
        return <div className='color-text-medium margin-top-less'>{count} {pluralize('review', count)} not required</div>;
      }
    }
  }

  function renderReviewInfo() {
    const requiredCount = requiredReviewCount();
    if (requiredCount > 0) {
      return (
        <>
          <p className='bigger nomargin'><strong>{completedCount()}</strong> of <strong>{requiredCount}</strong> parties have completed their review</p>
          {unrequiredReviewGroupHTML()}
        </>
      );
    }

    if (props.admin) {
      return <p className='nomargin'>No review is required at this time. Please continue on to the next step.</p>;
    }
  }

  function renderConstituentAction() {
    if (!props.admin) {
      return (
        <ConstituentReviewInfo
          activeStep={props.activeStep}
          discussions={props.discussions}
          onOpenDiscussionClick={props.onOpenDiscussionClick}
          hasThirdPartyReview={anyThirdPartyReview()}
        />
      );
    }
  }

  return (
    <div className='currentstep-stepsection'>
      <div className='margin-bottom'>
        {renderConstituentAction()}
        {renderReviewInfo()}
      </div>

      {renderReviewGroups()}
    </div>
  );
};

RequireReviewStep.propTypes = {
  activeStepId: PropTypes.number.isRequired,
  currentUserId: PropTypes.number.isRequired,
  reviewGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  admin: PropTypes.bool.isRequired,
  collectedData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RequireReviewStep;
