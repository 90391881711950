import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import FormsList from './forms-container/forms-list';

const FormsContainer = ({ teams, starredFormIds, teamlessForms, archivedMode }) => {
  const renderForms = () => {
    if (isEmpty(teams) && archivedMode) {
      return <div className='margin-top'>No archived forms</div>;
    }

    if (isEmpty(teams) && isEmpty(teamlessForms)) {
      return (
        <>
          <div className='minimalcontentcontainer'>
            You dont belong to any teams. Ask a coworker to invite you to a team, or&nbsp;
            <a href='/teams/new'>create a new one.</a>
          </div>
          <br />
          You must belong to a team in order to create new forms.
        </>
      );
    }

    return (
      <>
        {teams.map((team) => {
          return (
            <FormsList
              teamName={team.name}
              teamFriendlyId={team.friendly_id}
              starredFormIds={starredFormIds}
              masterTemplates={team.master_templates}
              enableFormCreation={team.enable_form_creation}
              formsPagination={7}
            />
          );
        })}
        {!isEmpty(teamlessForms) && (
          <div className='cf padding-top'>
            <FormsList
              teamName='Other forms'
              masterTemplates={teamlessForms}
              starredFormIds={starredFormIds}
              enableFormCreation={false}
            />
          </div>
        )}
      </>
    );
  };
  return renderForms();
};

FormsContainer.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.shape({
    friendly_id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    master_templates: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    enable_form_creation: PropTypes.bool.isRequired,
  })),
  archivedMode: PropTypes.bool.isRequired,
  starredFormIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default FormsContainer;
