import React, { useMemo, useRef, useState } from 'react';
import Bar from 'components/shared/chart/bar';
import { isNumber } from 'underscore';
import Popdown from '../shared/popdown';

const OverviewStats = (props) => {
  const elForAnchor = useRef();
  const [showRevenueDetail, setShowRevenueDetail] = useState(false);
  const lowestFlowId = useMemo(() => {
    return props.stats?.revenue_digest?.lowest_revenue_info?.flow_id;
  }, [props.stats]);
  const highestFlowId = useMemo(() => {
    return props.stats?.revenue_digest?.highest_revenue_info?.flow_id;
  }, [props.stats]);
  const lowestAmount = useMemo(() => {
    return props.stats?.revenue_digest?.lowest_revenue_info?.amount;
  }, [props.stats]);
  const highestAmount = useMemo(() => {
    return props.stats?.revenue_digest?.highest_revenue_info?.amount;
  }, [props.stats]);
  const lowestProcessId = useMemo(() => {
    return props.stats?.revenue_digest?.lowest_revenue_info?.process_id;
  }, [props.stats]);
  const highestProcessId = useMemo(() => {
    return props.stats?.revenue_digest?.highest_revenue_info?.process_id;
  }, [props.stats]);

  const canShowRevenueRange = () => {
    return isNumber(lowestAmount) && isNumber(highestAmount);
  };
  const renderRevenueRange = () => {
    if (canShowRevenueRange()) {
      const formatter = Intl.NumberFormat('en', { style: 'currency', currency: 'USD' });
      return (
        <dl
          className='divider'
          onMouseEnter={() => setShowRevenueDetail(true)}
          onMouseLeave={() => setShowRevenueDetail(false)}
        >
          <dt>
            {formatter.format(lowestAmount / 100)} - {formatter.format(highestAmount / 100)}
          </dt>
          <dd ref={elForAnchor}>
            Revenue Range
            {showRevenueDetail && (
              <Popdown
                anchorRef={elForAnchor}
              >
                <div>
                  <div className='padded'>
                    Low&nbsp;&nbsp;
                    <a href={`/initiated_flows/manager/${lowestFlowId}/`}>
                      {formatter.format(lowestAmount / 100)} in #{lowestProcessId}
                    </a>
                  </div>
                </div>
                <div>
                  <div className='padded'>
                    High&nbsp;&nbsp;
                    <a href={`/initiated_flows/manager/${lowestFlowId}/`}>
                      {formatter.format(highestAmount / 100)} in #{highestProcessId}
                    </a>
                  </div>
                </div>
              </Popdown>
            )}
          </dd>
        </dl>
      );
    }
    return (
      <dl className='divider'>
        <dt>---</dt>
        <dd>Revenue Range</dd>
      </dl>
    );
  };

  return (
    <div className='overviewstats'>
      <div className='overviewstats-quick cf'>
        <dl className='divider'>
          <dt>{props.stats.last_30_days}</dt>
          <dd>Launched last 30 days</dd>
        </dl>

        <dl>
          <dt>{props.stats.revenue_last_30_days}</dt>
          <dd>30 day revenue</dd>
        </dl>

        {renderRevenueRange()}

        <dl>
          <dt>{props.stats.currently_open}</dt>
          <dd>Currently active</dd>
        </dl>

        <dl>
          <dt>{props.stats.completed}</dt>
          <dd>Completed</dd>
        </dl>

        <dl className='divider'>
          <dt>{props.stats.halted}</dt>
          <dd>Halted</dd>
        </dl>

        <dl>
          <dt>{props.stats.median_completion_time_in_words}</dt>
          <dd>Median time to complete</dd>
        </dl>
      </div>

      <Bar
        data={props.launchData}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          backgroundColor: '#597A91',
        }}
      />
    </div>
  );
};

export default OverviewStats;
