import React, { useRef } from 'react';
import Well from 'components/shared/well';
import FixedPaymentSettings from './fixed-payment-settings';

const EditPaymentView = (props) => {
  const flatRef = useRef();
  const calculatedRef = useRef();
  const cashAllowedCheckbox = useRef();
  const checkAllowedCheckbox = useRef();
  const constituentFeeCheckbox = useRef();

  function configChanged() {
    // calculated ref does not exist when this is being used for supplemental payment
    const fixed = !!(flatRef.current.checked || (calculatedRef.current && calculatedRef.current.checked));

    const calculated = (calculatedRef.current && calculatedRef.current.checked);
    props.updateAttrs(props.step.id, {
      config_attributes: {
        accepts_cash: cashAllowedCheckbox.current.checked,
        accepts_checks: checkAllowedCheckbox.current.checked,
        constituent_pays_fee: constituentFeeCheckbox.current.checked,
        fixed,
        calculated,
      },
    });
  }

  function renderCheckDetails() {
    if (props.config.accepts_checks) {
      return (
        <textarea
          defaultValue={props.config.check_instructions}
          placeholder='Add instructions for how to pay by check...'
          onBlur={props.checkInstructionsBlurred}
          className='bg-white margin-top-less margin-bottom'
        />
      );
    }
  }

  function renderCashDetails() {
    if (props.config.accepts_cash) {
      return (
        <textarea
          defaultValue={props.config.cash_instructions}
          placeholder='Add instructions for how to pay with cash...'
          onBlur={props.cashInstructionsBlurred}
          className='bg-white margin-top-less margin-bottom'
        />
      );
    }
  }

  function renderPaymentSettings() {
    return (
      <section>
        <h3 className='stepcreator-sectiontitle'>Payment settings</h3>

        <Well>
          <label>
            <input
              disabled={props.disabled}
              ref={cashAllowedCheckbox}
              type='checkbox'
              defaultChecked={props.config.accepts_cash}
              onChange={configChanged}
              id='cashpaymentcheckbox'
            />
            Allow cash payment
          </label>

          {renderCashDetails()}

          <label>
            <input
              disabled={props.disabled}
              ref={checkAllowedCheckbox}
              type='checkbox'
              defaultChecked={props.config.accepts_checks}
              onChange={configChanged}
              id='checkpaymentcheckbox'
            />
            Allow payment by check
          </label>

          {renderCheckDetails()}

          <label>
            <input
              disabled={props.disabled}
              ref={constituentFeeCheckbox}
              type='checkbox'
              defaultChecked={!!props.config.constituent_pays_fee}
              onChange={configChanged}
            />
            Pass transaction fee on to constituent
          </label>
        </Well>
      </section>
    );
  }

  function renderFixedPaymentSettings() {
    if (props.config.fixed) {
      return (
        <FixedPaymentSettings
          disabled={props.disabled}
          config={props.config}
          calculated={props.config.calculated}
          step={props.step}
          masterTemplateId={props.masterTemplateId}
          updateAttrs={props.updateAttrs}
        />
      );
    }
  }

  function renderCalculateOption() {
    if (props.step.type === 'PaymentRequirement') { return; }

    const calculated = props.config.fixed && props.config.calculated;

    return (
      <div className='stepoptions-settinggroup margin-bottom'>
        <input
          disabled={props.disabled || props.step.type === 'PaymentRequirement'}
          key={`${props.step.id}-calculated`}
          type='radio'
          name='amount'
          defaultChecked={calculated}
          onChange={configChanged}
          ref={calculatedRef}
          id='calculatedoption'
        />

        <dl className='optionselect'>
          <dt><label htmlFor='fixedoption'>Calculated</label></dt>
          <dd><label htmlFor='fixedoption'>Payer will pay an amount calculated by a formula</label></dd>
        </dl>

        {calculated && renderFixedPaymentSettings()}
      </div>
    );
  }

  function renderPaymentAmount() {
    const flat = props.config.fixed && !props.config.calculated;

    return (
      <section>
        <h3 className='stepcreator-sectiontitle'>Payment amount</h3>

        <Well>
          <div className='stepoptions-settinggroup margin-bottom'>
            <input
              disabled={props.disabled}
              key={`${props.step.id}-flat`}
              type='radio'
              name='amount'
              defaultChecked={flat}
              onChange={configChanged}
              ref={flatRef}
              id='flatoption'
            />
            <dl className='optionselect'>
              <dt><label htmlFor='fixedoption'>Flat</label></dt>
              <dd><label htmlFor='fixedoption'>Payer will pay a flat amount</label></dd>
            </dl>

            {flat && renderFixedPaymentSettings()}
          </div>
          {renderCalculateOption()}

          <div className='stepoptions-settinggroup'>
            <input
              disabled={props.disabled}
              key={`${props.step.id}-variable`}
              type='radio'
              name='amount'
              defaultChecked={!props.config.fixed}
              onChange={configChanged}
              id='variableoption'
            />

            <dl className='optionselect'>
              <dt><label htmlFor='variableoption'>Payer determined</label></dt>
              <dd><label htmlFor='variableoption'>Payer will choose the amount they pay</label></dd>
            </dl>

          </div>
        </Well>
      </section>
    );
  }

  return (
    <div className='stepoptions'>
      <p>Applicant will be prompted to submit a payment.</p>

      {renderPaymentAmount()}
      {renderPaymentSettings()}
    </div>
  );
};

export default EditPaymentView;
