import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import AdvancedFilterAPI from 'apis/advanced-filter-api';
import Modal from 'components/shared/modal';
import FilterRow from './filter-row';

const LoadFilterModal = ({
  onClose,
  onLoad,
  dispatch,
  filters,
}) => {
  const [activeFilterId, setActiveFilterId] = useState(null);

  const onRowClick = (item) => {
    setActiveFilterId(item.id);
  };

  const onLoadFilter = () => {
    if (!activeFilterId) { return null; }

    onLoad(activeFilterId)
  };

  const onDeleteFilter = (filter) => {
    AdvancedFilterAPI.delete(filter.id).
      done(() => {
        dispatch({
          type: 'deleteSavedFilter',
          value: filter
        });
      });
  };

  const renderFilters = () => {
    return filters.map((filter) =>
      <FilterRow
        key={filter.id}
        filter={filter}
        onRowClick={onRowClick}
        onDeleteClick={onDeleteFilter}
        isActiveRow={activeFilterId === filter.id}
        dispatch={dispatch}
      />
    );
  };

  const loadFilterButtonClass = `btn-primary ${activeFilterId ? undefined : 'disabled'}`;

  return (
    <Modal
      className='manage-filter-modal'
      onOutsideClick={onClose}
    >
      <div className='manage-filter-modal-header'>
        <h1>Manage Saved Filters</h1>
      </div>
      <div>
        <p>
          When you choose a filter, its rules and conditions are quickly used to sort through your data.
          This helps you narrow down the information to just what you need, making your analysis more focused.
          You can also rename or delete previously created filters.
        </p>
      </div>
      <div className='manage-filter-modal-form'>
        <div className='load-filter-table'>
          <div className='load-filter-table-header'>
            <div className='load-filter-table-header-cell' style={{ flexGrow: 1 }}>Filter Name</div>
            <div className='load-filter-table-header-cell' style={{ flexBasis: '140px' }}>Created On</div>
            <div className='load-filter-table-header-cell' style={{ flexBasis: '70px' }}>Action</div>
          </div>
          {renderFilters()}
        </div>
      </div>
      <div className='manage-filter-modal-footer'>
        <button onClick={onClose} className='btn-secondary'>Cancel</button>
        <button id='load-filter-button' onClick={onLoadFilter} className={loadFilterButtonClass}>Load Filter</button>
      </div>
    </Modal>
  );
};

export default LoadFilterModal;
