import React from 'react';
import dayjs from 'dayjs';

const SmallFlowTable = ({ initiatedFlows, subprocessRequirementId }) => {
  const renderRow = (flow) => {
    const params = subprocessRequirementId ? `?subprocess_requirement_id=${subprocessRequirementId}` : '';

    const processURL = `/initiated_flows/manager/${flow.id}${params}`;

    return (
      <tr key={flow.id}>
        <td>
          <a
            href={processURL}
            title
          >
            #{flow.scoped_id}
          </a>
        </td>
        <td>
          <span>{flow.identifier_field}</span>
        </td>
        <td>
          {dayjs(flow.created_at).format('LLL')}
        </td>
        <td className='strong'>
          <span dangerouslySetInnerHTML={{ __html: flow.status }} />
          { flow.is_renewable
            && (
              <button
                onClick={() => { document.location = processURL; }}
                type='button'
                className='nomargin inline-block margin-left-more'
              >
                Renew
              </button>
            )}
        </td>
      </tr>
    );
  };

  const renderRows = () => {
    return initiatedFlows.map(renderRow);
  };

  return (
    <table className='datatable highlightable margin-bottom middle fullwidth'>
      <thead>
        <tr>
          <th>ID</th>
          <th>Identifier</th>
          <th>Started</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {renderRows()}
      </tbody>
    </table>
  );
};

export default SmallFlowTable;
