import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'underscore';
import titleize from '../../../../modules/titleize';

const SearchResult = ({
  result,
  onSelectEntityClick,
  isEsri,
}) => {
  const renderEsriRestult = () => {
    return map(result, (val, key) => {
      return (
        <li>
          <span className='strong'>{titleize(key)}: </span>
          <span className='entityinput-resultvalue'>{val}</span>
        </li>
      );
    });
  };

  const renderStandardResult = () => {
    return (
      <>
        <li>
          <span className='strong'>{result.name}</span>
        </li>
        {result.fields.map((field) => {
          return (
            <li key={field.slug}>
              <span className='strong'>{titleize(field.name)}: </span>
              <span id={field.slug} className='entityinput-resultvalue'>{field.value}</span>
            </li>
          );
        })}
      </>
    );
  };

  return (
    <li key={result.id} className='border-bottom padding-bottom margin-bottom'>
      <button className='entityinput-button' onClick={onSelectEntityClick(result.id)} type='button'>
        <ul>
          {isEsri ? renderEsriRestult() : renderStandardResult()}
        </ul>
      </button>
    </li>
  );
};

SearchResult.propTypes = {
  result: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  searchTerm: PropTypes.string.isRequired,
  onSelectEntityClick: PropTypes.func.isRequired,
};

export default SearchResult;
