

import JobsAPI from '../apis/jobs-api';

class PollableJob {
  constructor(queueFunction) {
    this.queueFunction = queueFunction;
    this._promise = null;
  }

  run = () => {
    this._promise = $.Deferred();

    this.queueFunction()
        .done(this.onSuccessfulQueue)
        .fail(this.onJobFailed);

    return this._promise;
  }

  onSuccessfulQueue = (res) => {
    this.poll(res.job_id);
  }

  poll = (jobId) => {
    JobsAPI.status(jobId)
           .fail(this.onJobFailed)
           .done((res) => { this.processJobStatus(res, jobId); });
  }

  processJobStatus = (res, jobId) => {
    if (res.status === 'complete') {
      this.onPollingComplete(res.result);
    } else if (res.status === 'failed') {
      this.onJobFailed();
    } else if (['working', 'queued'].includes(res.status)) {
      setTimeout(() => { this.poll(jobId); }, 1000);
    } else {
      this.onJobFailed();
    }
  }

  onJobFailed = () => {
    this._promise.reject();
    this._promise = null;
  }

  onPollingComplete = (status) => {
    this._promise.resolve(status);
  }
}

export default PollableJob;
