import PropTypes from 'prop-types';
import React from 'react';

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
  }

  componentDidMount() {
    this.map = this.initMap();
    this.geocoder = this.initGeocoder();
    this.setPosition();
    this.map.invalidateSize();
    window.invalidate = this.map.invalidateSize;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.query !== this.props.query) {
      this.setPosition();
    }
  }

  initMap = () => {
    const map = L.mapbox.map(this.findMapElement(), 'mapbox.streets', {
      zoom: 15,
      zoomControl: false,
      attributionControl: { compact: false },
      scrollWheelZoom: false,
    });

    return map;
  };

  initGeocoder = () => {
    return L.mapbox.geocoder('mapbox.places');
  };

  setPosition = () => {
    if (this.props.query) {
      this.geocoder.query(this.props.query, this.handleQueryResponse);
    }
  };

  handleQueryResponse = (err, data) => {
    if (this.marker) {
      this.map.removeLayer(this.marker);
    }

    if (data.latlng && data.latlng.length == 2) {
      this.marker = L.mapbox.featureLayer({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [
            data.latlng[1],
            data.latlng[0],
          ],
        },
        properties: {
          'marker-color': '#0277BD',
        },
      }).addTo(this.map);

      this.map.panTo(data.latlng);
      this.map.invalidateSize();
    }
  };

  isOpen = () => {
    return (!!this.props.query);
  };

  findMapElement = () => {
    return this.mapRef.current;
  };

  render() {
    const statusClass = (this.isOpen() ? '' : 'closed');
    return (
      <div className={`currentstep-addywrap ${statusClass}`}>
        <div className='currentstep-addymap' ref={this.mapRef} />
      </div>
    );
  }
}

Map.propTypes = {
  query: PropTypes.string,
};

export default Map;
