import React from 'react';
import renderer from 'modules/renderer';
import Survey from 'components/shared/survey';
import CommonProviders from 'components/common-providers';

const edit = () => {
  renderer(
    (
      <CommonProviders>
        <Survey
          survey={CityGrows.Server.surveyData}
          existingSubmission={CityGrows.Server.submissionData}
        />
      </CommonProviders>
    ), document.getElementById('survey'),
  );
};

export default {
  edit,
};
