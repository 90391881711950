import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import entityConfigurationReducer from 'reducers/entity-configuration-reducer';
import EntityConfigurationDispatchContext from 'contexts/entity-configuration-dispatch-context';
import ManageableTable, {
  THead, TH, TBody,
} from 'components/shared/manageable-table';
import CustomEntity from './entity-configuration/custom-entity';
import CreateDialog from './entity-configuration/create-dialog';

const EntityConfiguration = ({
  customEntities,
  teamId,
  esriIsEnabled,
}) => {
  const [entityConfigurationState, entityConfigurationDispatch] = useReducer(entityConfigurationReducer, { customEntities });
  const [creatingNewEntity, setCreatingNewEntity] = useState(false);

  const renderCustomEntity = (customEntity) => {
    return (
      <CustomEntity key={customEntity.id} customEntity={customEntity} />
    );
  };

  const renderCustomEntities = () => {
    return entityConfigurationState.customEntities.map(renderCustomEntity);
  };

  const onCreateEntityClick = () => {
    setCreatingNewEntity(true);
  };

  return (
    <ManageableTable>
      <div className='role-indexheader'>
        <h2 className='inline-block nomargin-bottom'>Entities</h2>
        <a target='__blank' href='https://support.cleargov.com/hc/en-us/articles/14699585627923-All-about-Entities-' className='secondary margin-left'>Learn about entities</a>
        <button
          type='button'
          className='btn-link strong float-right'
          onClick={onCreateEntityClick}
          id='createnewentity'
        >
          + Create new entity
        </button>
      </div>
      { creatingNewEntity && (
        <CreateDialog
          teamId={teamId}
          dispatch={entityConfigurationDispatch}
          onEntityCreated={() => { setCreatingNewEntity(false); }}
          esriIsEnabled={esriIsEnabled}
        />
      )}
      <THead>
        <TH>
          Entity name
        </TH>
      </THead>

      <TBody>
        <EntityConfigurationDispatchContext.Provider value={entityConfigurationDispatch}>
          {renderCustomEntities()}
        </EntityConfigurationDispatchContext.Provider>
      </TBody>
    </ManageableTable>
  );
};

EntityConfiguration.propTypes = {
  teamId: PropTypes.number.isRequired,
  customEntities: PropTypes.arrayOf(PropTypes.shape({
    custom_entity_fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    id: PropTypes.number.isRequired,
  })),
  esriIsEnabled: PropTypes.bool.isRequired,
};

export default EntityConfiguration;
