import React, { memo } from 'react';
import PropTypes from 'prop-types';

const LoadingSpinner = memo((props) => {
  const displayName = () => {
    switch (props.size) {
    case 'tiny':
      return 'smallest';
    case 'small':
      return 'smaller';
    case 'medium':
      return 'small';
    case 'large':
      return '';
    case 'xlarge':
      return 'large';
    default:
      return '';
    }
  };

  const colorScheme = () => {
    switch (props.colorScheme) {
    case 'dark':
      return 'dark';
    default:
      return '';
    }
  };

  return <div className={`progress ${colorScheme()} ${displayName()} ${props.className}`}><div>Loading</div></div>;
});

LoadingSpinner.displayName = 'LoadingSpinner';

LoadingSpinner.propTypes = {
  size: PropTypes.string,
  colorScheme: PropTypes.string,
  className: PropTypes.string,
  center: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
  size: 'small',
};

export default LoadingSpinner;
