import { error, success } from '../components/shared/flash';
import Constants from '../constants/survey-submission-constants';
import { alert } from '../modules/alert-confirm';
import SurveySubmissionAPI from '../apis/survey-submission-api';
import SurveyInvitationsAPI from '../apis/survey-invitations-api';

const SurveySubmissionActions = {
  submit(surveyId, surveyToken, data) {
    return SurveySubmissionAPI.create(surveyId, surveyToken, data)
                              .done(() => { success('Your response has been submitted'); })
                              .fail(() => { error(); });
  },

  update(submissionToken, data) {
    return SurveySubmissionAPI.update(submissionToken, data)
                              .done(() => { success('Your response has been updated'); })
                              .fail(() => { error(); });
  },

  destroy(submissionId) {
    const p = $.Deferred();

    SurveySubmissionAPI.destroy(submissionId)
      .done(() => {
        p.resolve();
        success('Survey submission deleted');
      })

      .fail(() => {
        this.onFail();
        p.reject();
      });

    return p;
  },

  sendReminder(surveyInvitationId) {
    return SurveyInvitationsAPI.remind(surveyInvitationId)
                               .done(() => { success('Reminder sent successfully.'); })
                               .fail(() => { error(); });
  },

  sendAllReminder(activeStepId) {
    return SurveyInvitationsAPI.remindAll(activeStepId)
                               .done(this.onRemindAllComplete)
                               .fail(() => { error(); });
  },

  onRemindAllComplete() {
    alert('Reminder sent successfully.', 'A reminder was sent to all outstanding invitations. Note that we will not send more than one reminder to a contact every 6 hours.');
  },

  dispatchSubmissionDestroyed(dispatch, id) {
    dispatch({
      type: Constants.DESTROYED,
      id,
    });
  },
};

export default SurveySubmissionActions;
