/*
  Embedded is probably the wrong word for this class.
  It's pretty much just a text area that submits
  debounced changes while typing. It's also styled
  (at time of writing) without a border or background
  so it's sort of 'embedded' in the page.
*/

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Textarea from 'react-textarea-autosize';

const EmbeddedTextarea = ({
  defaultValue, placeholder, className, disabled, onChange,
}) => {
  const [value, setValue] = useState(defaultValue);
  const submitChange = (e) => {
    const { value: newValue } = e.target;
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <div className={`embeddedtextarea${className ? ` ${className}` : ''}`}>
      <Textarea
        readOnly={disabled}
        onChange={submitChange}
        value={value}
        placeholder={placeholder}
      />
    </div>
  );
};

EmbeddedTextarea.propTypes = {
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default EmbeddedTextarea;
