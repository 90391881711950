import PropTypes from 'prop-types';
import React from 'react';
import InlineNotification from 'components/shared/inline-notification';
import CollectInfoSection from './collect-info/collect-info-section';
import SignatureDetails from './collect-info/signature-details';
import { findWhere } from 'underscore'

const CollectInfoStep = (props) => {
  function stepData() {
    let steps = [];
    props.step.sections.forEach((section) => {
      const array = [...section.step_data];
      steps = [...steps, ...array];
    });
    return steps;
  }

  function renderSectionIfVisible(section) {
    if (shouldRenderSection(section)) {
      return (
        <CollectInfoSection
          lockedFields={props.activeStep.locked_fields}
          activeStepId={props.activeStep.id}
          key={section.id}
          section={section}
          collectedData={props.collectedData}
          initiatedFlow={props.initiatedFlow}
          step={props.step}
          stepData={stepData()}
          teamId={props.teamId}
          admin={props.admin}
          locked={props.activeStep.locked}
          attemptedAdvance={props.attemptedAdvance}
          external={props.step.external}
        />
      );
    }
  }

  function shouldRenderSection(section) {
    if (!section.logic_config) { return true; }

    const evaluatedLogicConfig = findWhere(
      props.activeStep.logic_configs,
      { id: section.logic_config.id },
    );

    return evaluatedLogicConfig.show;
  }

  function renderSections() {
    return props.step.sections.map(renderSectionIfVisible)
  }

  function renderAdmin() {
    return (
      <>
        {renderAdminLockInfo()}
        {renderSections()}
      </>
    );
  }

  function renderAdminLockInfo() {
    if (!props.activeStep.locked) { return; }

    return (
      <InlineNotification
        type='notice'
        className='margin-bottom-more'
      >
        This step is locked. To make edits, unlock it by clicking the lock icon in the step listing on the left.
      </InlineNotification>
    );
  }

  function renderPublicLockInfo() {
    if (!props.activeStep.locked) { return; }

    return (
      <InlineNotification
        type='notice'
        className='margin-bottom-more'
      >
        This step is locked and cannot be edited. If you need to make edits, you can request that it be unlocked using the discussion feature in the upper right.
      </InlineNotification>
    );
  }

  function renderPublic() {
    if (props.step.external) {
      return (
        <>
          {renderPublicLockInfo()}
          {renderSections()}
        </>
      );
    }

    return (
      <>
        <p>Submission is in progress. No additional information is needed from you at this time.</p>
        {renderSections()}
      </>
    );
  }

  function renderSignatures() {
    if (!props.signaturesAllowed) { return; }

    return (
      <SignatureDetails
        signings={props.activeStep.signings}
        needsSignature={props.activeStep.needs_signature}
        activeStepId={props.activeStep.id}
        admin={props.admin}
      />
    );
  }

  return (
    <div className='currentstep-stepsection'>
      {props.admin ? renderAdmin() : renderPublic()}
      {renderSignatures()}
    </div>
  );
};

CollectInfoStep.propTypes = {
  activeStep: PropTypes.shape(),
  admin: PropTypes.bool.isRequired,
  collectedData: PropTypes.array.isRequired,
  initiatedFlow: PropTypes.shape().isRequired,
  locked: PropTypes.bool.isRequired,
  signaturesAllowed: PropTypes.bool.isRequired,
  step: PropTypes.shape().isRequired,
  teamId: PropTypes.number.isRequired,
};

CollectInfoStep.defaultProps = {
  signaturesAllowed: true,
  locked: false,
};

export default CollectInfoStep;
