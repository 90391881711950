import React from 'react';
import NProgress from 'nprogress';
import PropTypes from 'prop-types';

const Menu = (props) => {
  function renderAdditionaPaymentButton() {
    if (props.admin) {
      return (
        <a
          className='ifslidingpanel-button'
          onClick={onRequestPaymentClicked}
          href={`/initiated_flows/${props.initiatedFlowId}/supplemental_requirements?type=PaymentRequirement`}
          data-method='POST'
        >

          <div className='ifslidingpanel-btniconwrap'>
            <i className='vmiddle icon-money-grey vbottom bigger' />
          </div>
          Request additional payment
        </a>
      );
    }
  }

  function onRequestPaymentClicked() {
    NProgress.start();
  }

  return (
    <div className='margin-top'>
      {renderAdditionaPaymentButton()}
    </div>
  );
};

Menu.propTypes = {
  initiatedFlowId: PropTypes.number.isRequired,
  admin: PropTypes.bool.isRequired,
  activeFlow: PropTypes.bool.isRequired,
  personAttachments: PropTypes.array.isRequired,
  onCloseClick: PropTypes.func,
  teamId: PropTypes.number,
};

export default Menu;
