import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import randomcolor from 'randomcolor';
import truncate from 'truncate';
import { disc as discStyle, buildDisc } from 'theme/discs';
import { css } from '@emotion/react';
import UserPseudoInfo from './user-disc/user-pseudo-info';

const UserDisc = ({ user, className, options }) => {
  const backgroundStyle = useMemo(() => {
    if (user.avatar_url) {
      return {
        backgroundImage: `url('${user.avatar_url}')`,
        backgroundSize: 'cover',
      };
    }

    return {
      backgroundColor: randomcolor({
        hue: 'random',
        seed: user.id * 4,
        luminosity: 'dark',
      }),
    };
  }, [user.avatar_url, user.id]);

  return (
    <div className='inline-block'>
      <span
        title={user.name}
        css={options.smallerAvatar ? buildDisc(25) :discStyle}
        className={className}
        style={backgroundStyle}
      >
        <span>{user.avatar_url ? null : truncate(user.initials, 2, { ellipsis: null })}</span>
      </span>

      { options.showName && (
        <span
          css={css`
            text-transform: capitalize;
          `}
        >
          {user.name} {options.showAsCurrent && '(You)'}
        </span>
      )}

      { options.showEmail && (
        <span className='margin-left color-text-medium'>{user.email}</span>
      )}

      { options.showPseudo && user.pseudo && user.invitation.id && (
        <UserPseudoInfo
          invitationId={user.invitation.id}
        />
      )}
    </div>
  );
};

UserDisc.propTypes = {
  options: PropTypes.shape({
    showName: PropTypes.bool,
    showEmail: PropTypes.bool,
    showPseudo: PropTypes.bool,
    showAsCurrent: PropTypes.bool,
    smallerAvatar: PropTypes.bool,
  }),
  className: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    avatar_url: PropTypes.string,
    email: PropTypes.string.isRequired,
    pseudo: PropTypes.bool.isRequired,
    invitation: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    name: PropTypes.string.isRequired,
    initials: PropTypes.string.isRequired,
  }).isRequired,
};

UserDisc.defaultProps = {
  className: '',
  options: {
    showName: false,
    showEmail: false,
    showPseudo: true,
  },
};

export default UserDisc;
