import React from "react";
import { contains } from "underscore";

import Select from "../select";

const TeamSelect = ({
  changeFilterValue,
  filterState,
}) => {
  const { teams } = CityGrows.Server;

  const teamNumberIds = filterState.value.map((id) => Number(id));

  const onChangeTeams = (value) => {
    changeFilterValue(value.map(team => team.id));
  };

  return (
    <div className='metricsdashboard-filterselect'>
      <Select
        options={teams}
        isMulti
        onChange={onChangeTeams}
        getOptionValue={(opt) => opt.id}
        getOptionLabel={(opt) => opt.name}
        value={teams.filter((team) => contains(teamNumberIds, team.id))}
        className='react-select-metrics advanced-filter'
      />
    </div>
  );
};

export default TeamSelect;
