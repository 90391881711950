import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Actions from 'actions/survey-submission-actions';
import TimeAgo from 'react-timeago';

class SurveyInvitationLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reminded: false,
    };
  }

  onResendClick = (e) => {
    e.preventDefault();

    if (this.state.reminded) { return; }

    this.setState({ reminded: true }, () => {
      Actions.sendReminder(this.props.invitation.id)
             .fail(this.onRemindFailed);
    });
  };

  onRemindFailed = () => {
    this.setState({
      reminded: false,
    });
  };

  remindLink = () => {
    if (this.state.reminded) {
      return <span className='margin-left color-success'>Reminded</span>;
    }
    return <a href='#' className='secondary margin-left' onClick={this.onResendClick}>Remind</a>;
  };

  render() {
    return (
      <li>
        {this.props.invitation.email}
        <div className='float-right cf'>
          <TimeAgo
            date={this.props.invitation.created_at}
            className='color-text-medium'
          />
          {this.remindLink()}
        </div>
      </li>
    );
  }
}

SurveyInvitationLine.propTypes = {
  invitation: PropTypes.object.isRequired,
};

export default SurveyInvitationLine;
