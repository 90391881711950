import { findWhere, without } from 'underscore';
import invariant from 'invariant';
import UserAccessConstants from 'constants/user-access-constants';

function accessManagerReducer(state, action) {
  switch (action.type) {
  case UserAccessConstants.PERMISSION_CHANGED:
    const membershipToUpdate = findWhere(state.memberUsers, {
      id: action.membershipId,
    });

    membershipToUpdate.permission_level_id = action.permissionLevelId;
    return { ...state }
  case UserAccessConstants.AUTHORITY_REMOVED:
    invariant(action.authorityId, 'authorityId missing');
    const authorityToRemove = findWhere(state.authorityUsers, {
      id: action.authorityId,
    });

    invariant(authorityToRemove, 'authorityToRemove was not found');

    state.authorityUsers = without(state.authorityUsers, authorityToRemove);
    return { ...state }
  case UserAccessConstants.AUTHORITY_ADDED:
    state.authorityUsers.unshift(action.membership)
    return { ...state }
  case UserAccessConstants.MEMBERSHIP_REMOVED:
    const membershipToRemove = findWhere(state.memberUsers, {
      id: action.membershipId,
    });

    return { ...state, memberUsers: without(state.memberUsers, membershipToRemove) };
  case UserAccessConstants.MEMBERSHIP_ADDED:
    state.memberUsers.unshift(action.membership)
    return { ...state }
  case UserAccessConstants.MEMBERSHIP_LIST:
    return { ...state, memberUsers: action.memberships }
  default:
    throw new Error();
  }
}

export default accessManagerReducer;
