// NOTE: all of these are formatted according to the format of a
// react-select option.
export const STEP_ASSIGNMENT_GROUP_ACTIONS = [
  { value: 'show', label: 'Add this assignee' },
  { value: 'hide', label: 'Do not add this assignee' },
];

export const FORM_ACTIONS = [
  { value: 'show', label: 'Show this section' },
  { value: 'hide', label: 'Hide this section' },
];

export const REVIEW_ACTIONS = [
  { value: 'show', label: 'Require this review' },
  { value: 'hide', label: 'Don\'t require this review' },
];

export const SUBPROCESS_ACTIONS = [
  { value: 'show', label: 'Require this sub-submission' },
  { value: 'hide', label: 'Don\'t require this sub-submission' },
];

export const LOGICABLE_TYPES = {
  section: 'StepDatum::Section',
  reviewGroup: 'ReviewGroup',
  subprocess: 'Subprocess',
  stepAssignmentGroup: 'StepAssignmentGroup',
};

export const STANDARD_COMPARATORS = [
  { value: 'is', label: 'is' },
  { value: 'is_not', label: 'is not' },
];

export const NUMERIC_COMPARATORS = [
  { value: 'is', label: 'is' },
  { value: 'is_not', label: 'is not' },
  { value: 'gt', label: 'is greater than' },
  { value: 'lt', label: 'is less than' },
  { value: 'gte', label: 'is equal or greater than' },
  { value: 'lte', label: 'is equal or less than' },
];

export const CHECKBOX_COMPARATORS = [
  { value: 'contains', label: 'includes' },
  { value: 'excludes', label: 'does not include' },
];

export const ALL_COMPARATORS = STANDARD_COMPARATORS.concat(NUMERIC_COMPARATORS)
                                                   .concat(CHECKBOX_COMPARATORS);

export const CONNECTIVES = [
  { value: 'and', label: 'and' },
  { value: 'or', label: 'or' },
];

export const DEFAULT_CONNECTIVE = CONNECTIVES[0].value;

export function actionsForLogicableType(type) {
  switch (type) {
  case LOGICABLE_TYPES.stepAssignmentGroup:
    return STEP_ASSIGNMENT_GROUP_ACTIONS;
  case LOGICABLE_TYPES.section:
    return FORM_ACTIONS;
  case LOGICABLE_TYPES.reviewGroup:
    return REVIEW_ACTIONS;
  case LOGICABLE_TYPES.subprocess:
    return SUBPROCESS_ACTIONS;
  }
}
