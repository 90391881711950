import 'select2';
import React from 'react';
import renderer from 'modules/renderer';
import DocumentManager from 'components/document-manager';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import PrimaryTabs from 'components/shared/primary-tabs';

const show = () => {
  renderer(<PrimaryTabs />, document.getElementById('tabs'));
  renderer(
    <BrowserRouter>
      <Switch>
        <Route exact path='/master_templates/:master_template_id/documents'>
          <Redirect to={CityGrows.Server.masterTemplate.documents.length > 0 ? CityGrows.Server.masterTemplate.documents[0].id : 'new'} />
        </Route>

        <Route exact path='/master_templates/:master_template_id/documents/:document_id'>
          <DocumentManager
            masterTemplate={CityGrows.Server.masterTemplate}
            defaultMappables={CityGrows.Server.defaultMappables}
          />
        </Route>
      </Switch>
    </BrowserRouter>
    , document.getElementById('documentmanagerwrap'));
};

export default {
  show,
};
