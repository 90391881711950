import Flash from '../components/shared/flash';

var MasterTemplateDataImportAPI = {
  submitCSV: function(masterTemplateId, file) {
    const url = `/workflows/${masterTemplateId}/data_imports`;
    let formData = new FormData();
    formData.append('upload', file);

    return $.ajax(url, {
      dataType: 'json',
      method: 'POST',
      data: formData,
      processData: false,
      contentType: false
    });
  },

	index: function(){
		const url = '/master_template_data_imports';

		var request = $.ajax(url, {
			dataType: 'json',
			method: 'GET'
		})
		.fail(() => { Flash.error(); });

		return request;
	},

	queueImport: function(masterTemplateDataImportId, dataMapping){
		const URL = `/data_imports/${masterTemplateDataImportId}/queue_import`;

		var request = $.ajax(URL, {
			method: 'POST',
			dataType: 'json',
			data: {
				mappings: {
					initiated_flow: dataMapping.initiatedFlow,
					step_data: dataMapping.stepData,
				}
			}
		})
		.fail(() => { Flash.error(); });

		return request;
	},

	queueTest: function(masterTemplateDataImportId, dataMapping){
		const URL = `/data_imports/${masterTemplateDataImportId}/queue_test`;

		var request = $.ajax(URL, {
			method: 'POST',
			dataType: 'json',
			data: {
				mappings: {
					initiated_flow: dataMapping.initiatedFlow,
					step_data: dataMapping.stepData,
				}
			}
		})
		.fail(() => { Flash.error(); });

		return request;
	}
};

export default MasterTemplateDataImportAPI;
