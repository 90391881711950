import API from '../apis/active-step-api';
import Constants from '../constants/active-step-constants';

const ActiveStepActions = {
  updateCurrentActiveStep(dispatch, activeStepId, attrs) {
    dispatch({
      type: Constants.CURRENT_ACTIVE_STEP_UPDATED,
      attrs,
    });

    return API.update(activeStepId, attrs)
              .done((res) => {
                dispatch({
                  type: Constants.CURRENT_ACTIVE_STEP_UPDATED,
                  attrs: res,
                });
              });
  },

  update(dispatch, activeStepId, attrs) {
    const attrsForDispatch = { ...attrs };
    if (attrsForDispatch.locked_fields) {
      attrsForDispatch.locked_fields = JSON.parse(attrs.locked_fields);
    }

    dispatch({
      type: Constants.UPDATED,
      activeStepId,
      attrs: attrsForDispatch,
    });

    return API.update(activeStepId, attrs)
              .done((res) => {
                dispatch({
                  type: Constants.UPDATED,
                  activeStepId,
                  attrs: res,
                });
              });
  },
};

export default ActiveStepActions;
