import React, {
  useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import EntityActions from 'actions/entity-actions';
import AttachedPeople from 'components/shared/attached-people';
import EmbeddedTextInput from 'components/shared/embedded-text-input';
import AutonomousPopup from 'components/shared/autonomous-popup';
import StandardFields from './entity-page/standard-fields';
import EsriFields from './entity-page/esri-fields';
import {
  debounce, reject, isEmpty,
} from 'underscore';
import truncate from 'truncate';
import dayjs from 'dayjs';

const EntityPage = ({
  teamSlug,
  entity: entityFromProps,
}) => {
  const [entity, setEntity] = useState(entityFromProps);
  const [contacts, setContacts] = useState(entity.contacts);

  const updateContacts = (contact) => {
    let updatedContacts = [...contacts];
    if (contact.persisted) {
      updatedContacts.push(contact);
    } else {
      updatedContacts = reject(updatedContacts, (c) => {
        return c.id === contact.id;
      });
    }
    setContacts(updatedContacts);
  };

  const updateEntity = (attrs) => {
    EntityActions.update(entity.id, attrs)
      .done((res) => { setEntity(res); });
  };

  const onNameChange = useCallback(debounce((newName) => {
    updateEntity({ name: newName });
  }, 500), []);

  const onNoteChange = useCallback(debounce((newNote) => {
    updateEntity({ note: newNote });
  }, 500), []);

  const toggleEntityDestroyed = () => {
    return EntityActions.toggleDestroyed(entity.id)
      .done(setEntity);
  };

  const renderEntityFields = () => {
    if (entity.is_esri) {
      return (
        <EsriFields
          entity={entity}
        />
      );
    } else {
      return (
        <StandardFields
          entity={entity}
          updateEntity={updateEntity}
          onNoteChange={onNoteChange}
        />
      );
    }
  };

  const renderEditForm = () => {
    const searchOpts = { govOnly: false };
    return (
      <form className='raised bg-white'>
        <section className='entitypage-contacts'>
          <h3>Point of contact</h3>

          <AttachedPeople
            people={contacts}
            showEmail
            hideExternal={false}
            allowPseudo={false}
            attachableType='Entity'
            onChange={updateContacts}
            attachableId={entity.id}
            searchOpts={searchOpts}
          />
        </section>

        <section>
          <h3>{entity.custom_entity_name} info</h3>
          {renderEntityFields()}
        </section>
      </form>
    );
  };

  const renderFlow = (flow, idx) => {
    return (
      <tr key={idx} className='activeflowline cf'>
        <td style={{ color: (`#${flow.template_color}`) }} title={flow.template_name} className='flowtemplatename nowrap'>
          <a className='spanlink' href={`/initiated_flows/manager/${flow.id}`} />
          {truncate(flow.template_name, 25)}  &nbsp;#{flow.scoped_id}
        </td>
        <td>
          {dayjs(flow.created_at).format('LLL')}
          <a className='spanlink' href={`/initiated_flows/manager/${flow.id}`} />
        </td>
        <td>
          <span dangerouslySetInnerHTML={{ __html: flow.launched_by }} />
          <a className='spanlink' href={`/initiated_flows/manager/${flow.id}`} />
        </td>
        <td className='nowrap'>
          <a className='spanlink' href={`/initiated_flows/manager/${flow.id}`} />
          <span dangerouslySetInnerHTML={{ __html: flow.status }} />
        </td>
      </tr>
    );
  };

  const renderFlows = () => {
    if (!isEmpty(entity.initiated_flows)) {
      return (
        <table>
          <tbody>
            {entity.initiated_flows.map(renderFlow)}
          </tbody>
        </table>
      );
    }
    return (
      <div>
        This {entity.custom_entity_name} does not have any associated submissions.
      </div>
    );
  };

  const renderFlowTable = () => {
    return (
      <div className='entitypage-flowtablewrap goldenwidth'>
        <h2>Submissions</h2>
        {renderFlows()}
      </div>
    );
  };

  const renderExtras = () => {
    // ESRI entities can't be archived (because they would still exist on remote)
    if (entity.is_esri) { return; }
    
    return (
      <AutonomousPopup closeOnPopupClick buttonClassName='ellipsispopup-grey' nubDirection='up'>
        <ul className='popup-listmenu'>
          <li>
            <a onClick={toggleEntityDestroyed} href='#'>{entity.deleted_at ? 'Unarchive' : 'Archive'}</a>
          </li>
        </ul>
      </AutonomousPopup>
    );
  };

  const renderArchivedMessage = () => {
    return (
      <span className='entitypage-archived vertical-align-absolute'>Archived</span>
    );
  };

  return (
    <div className='entitypage'>
      <header>
        <div className='entity-name'>{entity.custom_entity_name}<span className='margin-left-less relative' style={{ top: '1px' }}>{renderExtras()}</span></div>
        <div className='viewall-container entitypage-name'>
          <EmbeddedTextInput
            className='entitypage-name'
            onChange={onNameChange}
            defaultValue={entity.name}
            disabled={entity.is_esri}
            disabledValue={entity.name}
          />
          {entity.deleted_at ? renderArchivedMessage() : ''}
          &nbsp;&nbsp;|&nbsp;&nbsp;<a href={`/teams/${teamSlug}/entities`}>View All Entities</a>
        </div>
      </header>

      <main className='cf'>
        {renderEditForm()}
        {renderFlowTable()}
      </main>
    </div>
  );
};

EntityPage.propTypes = {
  teamSlug: PropTypes.string.isRequired,
  entity: PropTypes.shape({}).isRequired,
};

export default EntityPage;
