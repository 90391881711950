import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { findWhere, filter } from 'underscore';
import SubprocessRequirement from './timeline/subprocess-requirement';
import StepInList from './timeline/step-in-list';

const Timeline = ({
  initiatedFlowState, admin, desktop, location, onOpenDiscussionClick, discussions,
}) => {
  const { activeStep, step, initiatedFlow } = initiatedFlowState;
  const [expanded, setExpanded] = useState(false);

  function onCompletionStep() {
    return location.pathname.match('completed');
  }

  function containerClassName() {
    if (expanded) {
      return 'mobile expanded';
    }

    if (!desktop) {
      return 'mobile unexpanded';
    }

    return '';
  }

  function toggleExpansion() {
    setExpanded(!expanded);
  }

  function renderCompletionStep() {
    return (
      <li key='complete' className={`completion${onCompletionStep() ? ' viewing' : ''}`}>
        <a className='margin-left' href='#/completed'>
          Submission completed
        </a>
      </li>
    );
  }

  function activeStepForStep(stepId) {
    return findWhere(initiatedFlow.active_steps, { steppable_id: stepId, steppable_type: 'Step' });
  }

  function activeSubprocessRequirements() {
    return filter(initiatedFlow.subprocess_requirements, (req) => {
      const evaluation = findWhere(activeStep.subprocess_requirement_evaluations, {id: req.id })
      return evaluation.required;
    });
  }

  function noRequirements() {
    return (!initiatedFlow.requirements || initiatedFlow.requirements.length == 0) && activeSubprocessRequirements().length == 0;
  }

  function renderStep(stp) {
    return (
      <StepInList
        step={stp}
        initiatedFlow={initiatedFlow}
        viewing={step.generic_type === stp.generic_type && step.id === stp.id && !onCompletionStep()}
        activeStep={activeStepForStep(stp.id)}
        key={`step-${stp.id}`}
        admin={admin}
        onOpenDiscussionClick={onOpenDiscussionClick}
        discussions={discussions}
      />
    );
  }

  function renderRequirement(requirement) {
    const viewing = step.generic_type === requirement.generic_type && step.id === requirement.id && !onCompletionStep();

    return (
      <StepInList
        step={requirement}
        initiatedFlow={initiatedFlow}
        viewing={viewing}
        key={`requirement-${requirement.id}`}
        admin={admin}
      />
    );
  }

  function renderSubprocessRequirement(requirement) {
    return (
      <SubprocessRequirement
        requirement={requirement}
        primaryApplicant={initiatedFlow.primary_applicant}
        key={requirement.id}
        collectedData={activeStep.collected_data}
        sections={step.sections}
        applicants={initiatedFlow.applicants}
      />
    );
  }

  function renderRequirements() {
    if (noRequirements()) { return; }

    return (
      <div>
        <div className='flowtimeline-sectionname first'>
          Additional Requirements
        </div>
        <ul className='flowtimeline-steplist'>
          {activeSubprocessRequirements().map(renderSubprocessRequirement)}
          {initiatedFlow.requirements.map(renderRequirement)}
        </ul>
      </div>
    );
  }

  function renderSteps() {
    const steps = initiatedFlow.steps.map(renderStep);

    if (initiatedFlow.finished_at) {
      steps.push(renderCompletionStep());
    }

    return (
      <div>
        <div className='flowtimeline-sectionname'>
          Steps
        </div>
        <ul className='flowtimeline-steplist'>
          {steps}
        </ul>
      </div>
    );
  }

  function renderMobileExpandIcon() {
    const extraClass = expanded ? 'open' : 'closed';
    return (
      <div className={`flowtimeline-expandwrap clickable ${extraClass}`} onClick={toggleExpansion}>
        <span className='flowtimeline-expandarrow' href='#' />
      </div>
    );
  }

  function renderListArea() {
    if (expanded || desktop) {
      return (
        <div>
          {renderRequirements()}
          {renderSteps()}
        </div>
      );
    }
  }

  return (
    <div className={`flowtimeline ${containerClassName()}`}>
      {renderListArea()}
      {!desktop && renderMobileExpandIcon()}
    </div>
  );
};

Timeline.propTypes = {
  admin: PropTypes.bool.isRequired,
  collectedData: PropTypes.array,
  location: PropTypes.object.isRequired,
  desktop: PropTypes.bool.isRequired,
  initiatedFlow: PropTypes.shape({
    active_steps: PropTypes.array.isRequired,
    primary_applicant: PropTypes.object.isRequired,
    subprocess_requirements: PropTypes.array.isRequired,
    requirements: PropTypes.array.isRequired,
    id: PropTypes.number.isRequired,
    finished_at: PropTypes.string,
    halted_at: PropTypes.string,

    steps: PropTypes.arrayOf(
      PropTypes.shape({
        icon_class: PropTypes.string.isRequired,
        position: PropTypes.number.isRequired,
        name_or_default: PropTypes.string.isRequired,
      }),
    ).isRequired,

    current_step: PropTypes.shape({
      position: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,

  step: PropTypes.shape({
    id: PropTypes.number.isRequired,
    generic_type: PropTypes.string.isRequired,
    sections: PropTypes.array,
  }).isRequired,
  onOpenDiscussionClick: PropTypes.func,
  discussions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withRouter(Timeline);
