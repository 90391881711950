import React from 'react';

const LeftMenu = (props) => {
  return (
    <div className='config-left'>
      <ul>
        {props.children}
      </ul>
    </div>
  );
};

export default LeftMenu;
