var DiscussionCommentAPI = {
	create(discussionId, attrs){
		const url = '/discussions/' + discussionId + '/discussion_comments';

		return $.ajax(url, {
			dataType: 'json',
			method: 'POST',
			data: {
				discussion_comment: attrs
			}
		});
	},

	destroy(disucssionCommentId){
		const url = '/discussion_comments/' + disucssionCommentId;

		return $.ajax(url, {
			dataType: 'json',
			method: 'DELETE'
		});
	}
};

export default DiscussionCommentAPI;