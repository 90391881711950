import { useState } from 'react';

const useDerivedState = (propValue) => {
  const [prevPropValue, setPrevPropValue] = useState(null);
  const [stateValue, setStateValue] = useState(propValue);

  if (propValue !== prevPropValue) {
    // NOTE: setting state directly in the render cycle looks
    // weird, but this is from the react docs:
    // https://reactjs.org/docs/hooks-faq.html#how-do-i-implement-getderivedstatefromprops
    setStateValue(propValue);
    setPrevPropValue(propValue);
  }
  return [stateValue, setStateValue];
};

export default useDerivedState;
