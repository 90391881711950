function postData(emailsOrFile, message){
  let formData = new FormData();

  formData.append('message', message);

  switch(typeof emailsOrFile){
    case 'string': {
      let emails = emailsOrFile;
      formData.append('emails', emails);
      break;
    }

    case 'object': {
      let file = emailsOrFile;
      formData.append('upload', file, file.name);
      break;
    }

    default:
      throw `Unhandled input type ${typeof emailsOrFile}`;
  }

  return formData;
}

const SurveyInvitationsAPI = {
  doSend: function(surveyToken, emails, message){
    const URL = `/survey_invitations/${surveyToken}/do_send`;

    return $.ajax(URL, {
      method: 'POST',
      contentType: false,
      processData: false,
      data: postData(emails, message)
    });
  },

  remind: function(surveyInvitationId){
    const URL = `/survey_invitations/${surveyInvitationId}/remind`;

    return $.ajax(URL, {
      method: 'post',
      dataType: 'json'
    });
  },

  remindAll: function(activeStepId){
    const URL = `/active_steps/${activeStepId}/survey_invitations/remind_all`;

    return $.ajax(URL, {
      method: 'post',
      dataType: 'json'
    });
  }
};

export default SurveyInvitationsAPI;
