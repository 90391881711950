import React from "react";

import DashboardStore from 'stores/dashboard-store';
import Select from "../select";

const StepSelect = ({
  changeFilterValue,
  filterState,
  stepDataForSearch,
}) => {
  const onStatusChange = (value) => {
    changeFilterValue(value.id);
  };

  const options = stepDataForSearch ? stepDataForSearch.steps : DashboardStore.getStepData().steps;
  const defaultOptionValue = options.find(opt => opt.id == Number(filterState.value));

  return (
    <div className='metricsdashboard-filterselect'>
      <Select
        options={options}
        onChange={onStatusChange}
        getOptionValue={(opt) => opt.id}
        getOptionLabel={(opt) => opt.name}
        className='react-select-metrics advanced-filter'
        value={options.find(opt => opt.id === Number(filterState.value))}
      />
    </div>
  );
};

export default StepSelect;
