import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import StepDataCollectorInputField from 'components/shared/step-data-collector-input-field';
import { validate } from 'modules/collected-data-validator';
import { debounce } from 'underscore';

const Section = ({
  attemptedAdvance, section, surveyData, disabled, onInputChanged, defaultValues, stepData,
}) => {
  const [firstErrorId, setFirstErrorId] = useState(null);
  useEffect(() => {
    if (attemptedAdvance) {
      const firstDatumWithError = stepData.find((stepDatum) => {
        const collectedDatum = surveyData[stepDatum.id];
        const content = collectedDatum && collectedDatum.content;
        const isValid = !stepDatum.required || validate(content, stepDatum);
        return !isValid;
      });

      if (firstDatumWithError === undefined) {
        setFirstErrorId(null);
      } else {
        setFirstErrorId(firstDatumWithError.id);
      }
    }
  }, [attemptedAdvance, stepData, surveyData]);

  const addressOnInputChanged = useMemo(() => debounce(onInputChanged, 1000), [onInputChanged]);

  return (
    <section>
      <span className='collectinfosection-title'>{section.name_or_default}</span>

      {section.instructions && (
        <div className='currentstep-instructions simplerich' dangerouslySetInnerHTML={{ __html: section.instructions }} />
      )}

      {section.step_data.map((stepDatum) => (
        <StepDataCollectorInputField
          stepDatum={stepDatum}
          disabled={disabled}
          attemptedAdvance={attemptedAdvance}
          fieldValue={surveyData?.[stepDatum.id]?.content}
          firstError={stepDatum.id === firstErrorId}
          onInputChanged={stepDatum.data_type === 'address' ? addressOnInputChanged : onInputChanged}
          key={stepDatum.id}
          // This is how optional chaining works with object access
          defaultValue={defaultValues?.[stepDatum.id]}
          survey
        />
      ))}
    </section>
  );
};

Section.propTypes = {
  section: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  attemptedAdvance: PropTypes.bool.isRequired,
  stepData: PropTypes.array.isRequired,
  surveyData: PropTypes.object,
  onInputChanged: PropTypes.func,
  defaultValues: PropTypes.object,
};

export default Section;
