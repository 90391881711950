import React from 'react';
import PropTypes from 'prop-types';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NewFormContainer = (props) => {
  return (
    <div className={`newform-container ${props.fullSize ? 'fullsize' : ''}`} ref={props.transitionRef}>
      <div className='newform-container-header'>
        <div className='title'>
          <h1>{props.title}</h1>
          <FontAwesomeIcon
            className='clickable'
            icon={icon({ name: 'x' })}
            style={{ color: '#0463B7', height: '1rem' }}
            onClick={props.onClose}
          />
        </div>
      </div>
      <div className='newform-container-body'>
        {props.children}
      </div>
    </div>
  );
};

NewFormContainer.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  fullSize: PropTypes.bool,
  transitionRef: PropTypes.shape({}).isRequired,
};

export default NewFormContainer;
