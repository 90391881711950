import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNull, findIndex, reject } from 'underscore';
import BatchExportTemplateAPI from 'apis/batch-export-template-api';
import ExportTemplateRow from './batch-form-export-templates/export-template-row';
import ExportTemplate from './batch-form-export-templates/export-template';

const BatchFormExportTemplates = (props) => {
  const [exportTemplates, setExportTemplates] = useState(props.exportTemplates);
  const [exportTemplate, setExportTemplate] = useState(null);

  const onSave = (template) => {
    if (template.id) {
      return BatchExportTemplateAPI.update(template.id, template)
        .done((res) => {
          setExportTemplate(null);
          const dupTemplates = [...exportTemplates];
          const index = findIndex(dupTemplates, (t) => { return t.id === template.id; });
          dupTemplates[index] = res;
          setExportTemplates(dupTemplates);
        });
    }

    return BatchExportTemplateAPI.create(template)
      .done((res) => {
        setExportTemplate(null);
        setExportTemplates([...exportTemplates, res]);
      });
  };

  const onDelete = (template) => {
    return BatchExportTemplateAPI.remove(template.id)
      .done((res) => {
        const dupTemplates = reject(exportTemplates, { id: res.id });
        setExportTemplates(dupTemplates);
      });
  };

  const onExport = (template) => {
    return BatchExportTemplateAPI.export(template.id);
  };

  const onCancel = () => {
    setExportTemplate(null);
  };

  const onNewClick = () => {
    setExportTemplate({ name: '', item_ids: [] });
  };

  const onEdit = (template) => {
    setExportTemplate(template);
  };

  const renderExportTemplateRow = (template) => {
    return (
      <ExportTemplateRow
        exportTemplate={template}
        onEdit={() => onEdit(template)}
        onDelete={() => onDelete(template)}
        onExport={() => onExport(template)}
      />
    );
  };

  const renderNewExportTemplate = () => {
    return (
      <ExportTemplate
        key={isNull(exportTemplate.id) ? 'new' : exportTemplate.id}
        newExportTemplate={isNull(exportTemplate.id)}
        exportTemplate={exportTemplate}
        onSave={onSave}
        onCancel={onCancel}
        flowTemplates={props.flowTemplates}
      />
    );
  };

  const renderExportTemplates = () => {
    if (!isNull(exportTemplate)) {
      return renderNewExportTemplate();
    }
    let content = (
      <p>This is where export templates that you create are shown.</p>
    );
    if (!isEmpty(exportTemplates)) {
      content = exportTemplates.map((template) => renderExportTemplateRow(template));
    }
    return (
      <div>
        <button
          type='button'
          className='btn-primary margin-bottom'
          onClick={onNewClick}
          id='createnewtemplate'
        >
          + Create new Bulk Form Export
        </button>
        <div>
          {content}
        </div>
      </div>
    );
  };

  return renderExportTemplates();
};

BatchFormExportTemplates.propTypes = {
  exportTemplates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  flowTemplates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default BatchFormExportTemplates;
