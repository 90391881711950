import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'underscore';

import LogicActions from 'actions/logic-actions';
import { actionsForLogicableType } from 'constants/logic-constants';
import LogicWidget from './logic-widget';
import LogicValidator from './logic_widget/logic-validator';

const StepLogic = (props) => {
  const initialLogicConfig = () => {
    // Note that this shape matches react-select's option shape
    // TODO: Having this defined this far from where it's used is
    // potentially a problem.
    const defaultAction = actionsForLogicableType(props.logicableType)[0].value;
    return (
      props.logicConfig
        || {
          action: defaultAction,
          conditions: [{ tempId: uniqueId() }],
        }
    );
  };

  const [editing, setEditing] = useState(!props.logicConfig);
  const [logicConfig, setLogicConfig] = useState(initialLogicConfig);
  const [error, setError] = useState(null);

  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      setLogicConfig(initialLogicConfig());
      setEditing(false);
    } else {
      didMountRef.current = true;
    }
  }, [props.logicConfig]);

  const onDelete = () => {
    if (props.logicConfig) {
      LogicActions
        .destroy(props.logicConfig.id)
        .done(() => {
          if (props.onLogicOff) {
            props.onLogicOff();
          }
        });
    }
  };

  function saveLogic() {
    let promise;
    if (logicConfig.id) {
      promise = LogicActions.update(logicConfig.id, logicConfig.action, logicConfig.conditions);
    } else {
      promise = LogicActions.create(
        props.logicableId,
        props.logicableType,
        logicConfig.action,
        logicConfig.conditions,
      );
    }
    if (props.onLogicSave) {
      promise.done((res) => props.onLogicSave(res));
    }
  }

  const onCancel = () => {
    setEditing(false);
    setLogicConfig(initialLogicConfig());

    if (props.onLogicOff) {
      props.onLogicOff();
    }
  };

  const onSave = (e) => {
    e.preventDefault();

    setError(null);
    const validator = new LogicValidator(logicConfig.action, logicConfig.conditions);

    if (validator.validateConfig()) {
      saveLogic();
    } else {
      setError('Invalid logic configuration');
    }
  };

  return (
    <div className='stepdatagroup-configgroup'>
      <LogicWidget
        flowTemplate={props.flowTemplate}
        logicableType={props.logicableType}
        logicableId={props.logicableId}
        logicConfig={logicConfig}
        editing={editing}
        setEditing={setEditing}
        setLogicConfig={setLogicConfig}
        onDelete={onDelete}
        disabled={props.disabled}
        error={error}
        showTitle={props.showTitle}
      >
        <div className='float-right'>
          <button type='button' onClick={onCancel} className='btn-thirdary margin-right-less'>
            Cancel
          </button>
          <button type='button' onClick={onSave} className='btn-primary'>
            Save logic configuration
          </button>
        </div>
      </LogicWidget>
    </div>
  );
};

StepLogic.propTypes = {
  flowTemplate: PropTypes.shape({}).isRequired,
  logicableType: PropTypes.string,
  logicableId: PropTypes.number,
  logicConfig: PropTypes.shape({
    id: PropTypes.number,
  }),
  disabled: PropTypes.bool,
  onLogicOff: PropTypes.func,
  onLogicSave: PropTypes.func,
  showTitle: PropTypes.bool,
};

StepLogic.defaultProps = {
  disabled: false,
  showTitle: true,
};
export default StepLogic;
