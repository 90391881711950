import React from 'react';
import ReactDOM from 'react-dom';
import renderer from 'modules/renderer';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import _ from 'underscore';
import ErrorMessages from 'constants/error-messages';
import FlashItem from './flash-item';

const MESSAGE_DURATION = 4500;

// TODO: refactor this so that we're not reaching in and modifying
// this component from outside the component
class Flash extends React.Component {
  state = {
    items: [],
  };

  render() {
    const { items } = this.state;
    return (
      <TransitionGroup>
        {items.map((item, index) => (
          <CSSTransition
            classNames='flash'
            timeout={600}
            key={item.content.message}
          >
            <FlashItem
              position={index}
              type={item.content.type}
              message={item.content.message}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    );
  }
}

let _component = null;
const _queue = [];

export function render(input) {
  if (_.isEmpty(input)) { return; }

  if (!_component) {
    renderComponent();
  }

  addItem(input);
}

export function success(message) {
  render({ type: 'success', message });
}

export const error = (message = ErrorMessages.flash.actionFailed) => {
  render({ type: 'error', message });
};

export const notice = (message = ErrorMessages.flash.actionFailed) => {
  render({ type: 'notice', message });
};

function expireOldest() {
  _queue.shift();
  setComponentState();
}

function setComponentState() {
  _component.setState({
    items: _queue,
  });
}

function addItem(item) {
  const matchingItem = _.find(_queue, (potentialMatch) => {
    return potentialMatch.content.message === item.message;
  });

  if (!matchingItem) {
    const timer = setTimeout(expireOldest, MESSAGE_DURATION);
    _queue.push({ content: item, timer });

    setComponentState();
  }
}

function renderComponent() {
  _component = ReactDOM.render(
    <Flash />,
    document.getElementById('flash'),
  );
}

export default {
  render,
  success,
  notice,
  error,
};
