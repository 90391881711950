var TentativeAssignmentsAPI = {
  toggleAssignment: function(activeStepId, userId){
    const url = '/tentative_assignments/toggle';
    return $.ajax(url, {
      method: 'POST',
      dataType: 'json',
      data: {
        active_step_id: activeStepId,
        user_id: userId
      }
    });
  },

  addPseudomember: function(email, name, activeStepId){
    const  url = '/tentative_assignments/add_pseudomember';
    return $.ajax(url, {
      data: {
        email: email,
        name: name,
        active_step_id: activeStepId
      },
      method: 'POST',
      dataType: 'json'
    });
  },

  removeAssignment: function(id) {
    const url = `/tentative_assignments/${id}`
    return $.ajax(url, {
      method: 'DELETE',
      dataType: 'json'
    })
  }
};

export default TentativeAssignmentsAPI;
