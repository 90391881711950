import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'underscore';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import FlowStepActions from 'actions/flow-step-actions';
import SurveyActions from 'actions/survey-actions';
import { STEP_ROW } from 'constants/template-manager-constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import DropdownMenu from 'components/shared/dropdown-menu';
import SideMenu from 'components/shared/side-menu';
import NotifBanner from 'components/shared/notif-banner';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import NewFieldPicker from './new-field-picker';
import StepDataCollectorRow from './step-data-collector-row';
import SectionSettings from './settings/section-settings';

const STEP_TYPE_MAP = {
  Step: 'CollectInfoStep',
  Survey: 'SurveyStep',
};
const StepDataSection = ({
  section,
  disabled,
  sectionableId,
  sectionableType,
  stepId,
  flowTemplate,
  stepData,
  onDatumClick,
  index,
  activeDatumId,
  allowedStepDatumTypes,
}) => {
  const [showSectionSettings, setShowSectionSettings] = useState(false);
  const [sectionName, setSectionName] = useState(section.name);
  const [showFieldMenu, setShowFieldMenu] = useState(false);
  const sidemenuRef = useRef(null);

  const isLogicDisabled = section.sectionable_type !== 'Step' || stepData.some((s) => { return s.used_in_formula; })

  const sectionNameChanged = useMemo(
    () => debounce((name) => FlowStepActions.updateSection(section.id, { name }), 500),
    [section],
  );

  const copy = () => {
    switch (sectionableType) {
    case 'Step':
      FlowStepActions.copySection(section.id, sectionableId);
      break;

    case 'Survey':
      SurveyActions.copySection(section.id, stepId);
      break;

    default:
      throw Error(`Invalid sectionableType ${sectionableType}`);
    }
  };

  const destroySectionClicked = () => {
    FlowStepActions.destroySection(section.id);
  };

  const autofillEnabledOnTemplate = flowTemplate?.configured_outputs?.[0]?.autofill;

  const renderBannerLogic = () => {
    return (
      <div style={{ margin: '1rem' }}>
        <NotifBanner className='advanced' style='warning'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'inline-flex' }}>
              <div style={{ marginRight: '1rem', display: 'flex', alignItems: 'center' }}>
                <FontAwesomeIcon icon={icon({ name: 'square-info' })} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>
                  Conditional logic has been applied to this section.&nbsp;
                </span>
                <button
                  type='button'
                  onClick={() => setShowSectionSettings(true)}
                  className='btn-link'
                >
                  View conditional logic
                </button>
              </div>
            </div>
          </div>
        </NotifBanner>
      </div>
    );
  };

  const renderSectionSettings = () => {
    return (
      <SideMenu
        title='Section Configuration'
        onClose={() => setShowSectionSettings(false)}
        transitionRef={sidemenuRef}
      >
        <SectionSettings
          section={section}
          flowTemplate={flowTemplate}
          disabled={disabled}
          logicDisabled={isLogicDisabled}
        />
      </SideMenu>
    );
  };

  const renderMenu = () => {
    return (
      <DropdownMenu iconColor='#fff'>
        <ul>
          <li onClick={() => setShowSectionSettings(true)}>
            <FontAwesomeIcon
              icon={icon({ name: 'gear' })}
              style={{ color: '#0463B7' }}
            />
            Configuration
          </li>
          {!disabled && (
            <>
              <li onClick={copy}>
                <FontAwesomeIcon
                  icon={icon({ name: 'clone' })}
                  style={{ color: '#0463B7' }}
                />
                Clone Section
              </li>
              <li onClick={destroySectionClicked}>
                <FontAwesomeIcon
                  icon={icon({ name: 'trash' })}
                  style={{ color: '#0463B7' }}
                />
                Delete Section
              </li>
            </>
          )}
        </ul>
      </DropdownMenu>
    );
  };

  return (
    <>
      <Draggable draggableId={`section-${section.id}`} index={index}>
        {({ innerRef, draggableProps, dragHandleProps }) => (
          <div className='stepdatagroup' ref={innerRef} {...draggableProps}>
            <div className='stepdatagroup-header'>
              <input
                className='stepdatagroup-header-title'
                type='text'
                placeholder='Untitled section'
                value={sectionName}
                onChange={(e) => {
                  const { value } = e.target;
                  setSectionName(value);
                  sectionNameChanged(value);
                }}
                disabled={disabled}
              />

              <div className='stepdatagroup-actions'>
                {!disabled && (
                  <>
                    <div
                      className='sorthandle grabbable'
                      {...dragHandleProps}
                    >
                      <FontAwesomeIcon
                        icon={icon({ name: 'arrow-down-arrow-up' })}
                        style={{ color: '#fff', marginRight: '0.5rem' }}
                        className='action'
                      />
                    </div>
                    {renderMenu()}
                  </>
                )}
              </div>
            </div>

            {!!section.logic_config && renderBannerLogic()}

            <Droppable
              droppableId={`datum-droppable-${section.id}`}
              type={STEP_ROW}
            >
              {({ innerRef: datumInnerRef, droppableProps, placeholder }) => (
                <div
                  ref={datumInnerRef}
                  {...droppableProps}
                  className='stepdatagroup-rowswrap grouped-sort'
                  data-cy={`fields-${index}`}
                >
                  {stepData.map((datum, rowIndex) => (
                    <StepDataCollectorRow
                      key={datum.id}
                      index={rowIndex}
                      datum={datum}
                      stepType={STEP_TYPE_MAP[section.sectionable_type]}
                      allowedStepDatumTypes={allowedStepDatumTypes}
                      disabled={disabled}
                      active={datum.id === activeDatumId}
                      onClick={onDatumClick}
                      customEntities={flowTemplate?.team?.custom_entities}
                      autofillEnabledOnTemplate={autofillEnabledOnTemplate}
                    />
                  ))}
                  {placeholder}
                </div>
              )}
            </Droppable>

            {!disabled && (
              <div className='padded'>
                <button type='button' onClick={() => setShowFieldMenu(true)} className='stepdatagroup-adddata'>
                  + Add new field
                </button>
              </div>
            )}
            {showFieldMenu && (
              <NewFieldPicker
                sectionId={section.id}
                stepType={STEP_TYPE_MAP[section.sectionable_type]}
                onClose={() => setShowFieldMenu(false)}
                customEntities={flowTemplate?.team?.custom_entities}
              />
            )}
          </div>
        )}
      </Draggable>
      <TransitionGroup>
        { showSectionSettings && (
          <CSSTransition
            classNames='sidemenu'
            timeout={250}
            nodeRef={sidemenuRef}
          >
            {renderSectionSettings()}
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
};

StepDataSection.propTypes = {
  disabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  section: PropTypes.object.isRequired,
  allowedStepDatumTypes: PropTypes.array.isRequired,
  activeDatumId: PropTypes.number,
  onDatumClick: PropTypes.func,
  flowTemplate: PropTypes.object,
  stepData: PropTypes.array,
  sectionableId: PropTypes.number,
  sectionableType: PropTypes.string.isRequired,
  stepId: PropTypes.number,
};

export default StepDataSection;
