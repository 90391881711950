import React, {
  useRef, useState, useCallback, useEffect, useContext,
} from 'react';
import PropTypes from 'prop-types';
import Textarea from 'react-textarea-autosize';
import TimeAgo from 'react-timeago';
import DiscussionDispatchContext from 'contexts/discussion-dispatch-context';
import dayjs from 'dayjs';

import DiscussionActions from 'actions/discussion-actions';
import Select from 'components/shared/select';
import Flash from 'components/shared/flash';
import useKeyDown from 'components/hooks/use-keydown';
import { confirm } from 'modules/alert-confirm';
import DiscussionComment from './comment';

const ApprovalStatus = ({ className, verb, groupName }) => (
  <div className={`discussion-context margin-bottom ${className}`}>
    {verb} - <span className='light'>{groupName}</span>
  </div>
);

ApprovalStatus.propTypes = {
  className: PropTypes.string,
  verb: PropTypes.string,
  groupName: PropTypes.string,
};

const EditResponse = ({ discussion, close }) => {
  const dispatch = useContext(DiscussionDispatchContext);

  const [editedText, setEditedText] = useState(discussion.body);
  const inputRef = useRef();
  const onUpdateDiscussionClick = () => {
    if (!editedText || editedText.length === 0) {
      Flash.error('Discussion can\'t be empty');
    }

    DiscussionActions.update(dispatch, discussion.id, { body: editedText })
      .done(() => {
        Flash.success('Discussion updated');
        close();
      });
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 27) {
      // Esc
      close();
    } else if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      // CMD-Enter
      onUpdateDiscussionClick();
    }
  };

  const keydownTargetRef = useKeyDown(onKeyDown);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className='cf' ref={keydownTargetRef}>
      <Textarea className='margin-top' ref={inputRef} value={editedText} onChange={(e) => setEditedText(e.target.value)} placeholder='Start typing...' />
      <div className='float-right margin-top-less'>
        <button type='button' onClick={close} className='margin-right btn-thirdary small'>Cancel</button>
        <button type='button' onClick={onUpdateDiscussionClick} className='btn-primary small'>Save</button>
      </div>
    </div>
  );
};

EditResponse.propTypes = {
  discussion: PropTypes.object,
  close: PropTypes.func,
};

// TODO: this is really similar to EditResponse. They could probably be deduped.
const ReplyResponse = ({ discussion, close }) => {
  const dispatch = useContext(DiscussionDispatchContext);
  const inputRef = useRef();
  const [reply, setReply] = useState('');

  const onSaveComment = () => {
    if (!reply || reply.length < 1) {
      Flash.error('Comment can\'t be blank');
      return;
    }

    DiscussionActions.saveDiscussionComment(dispatch, discussion.id, reply)
      .done(() => setReply(''));
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const onKeyDown = (e) => {
    if (e.keyCode === 27) {
      // Esc
      close();
    } else if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      // CMD-Enter
      onSaveComment();
    }
  };

  const keydownTargetRef = useKeyDown(onKeyDown);

  return (
    <div className='cf padding-left' ref={keydownTargetRef}>
      <Textarea value={reply} ref={inputRef} onChange={(e) => setReply(e.target.value)} className='margin-top' placeholder='Start typing...' />
      <div className='float-right margin-top-less'>
        <button type='button' onClick={close} className='btn-thirdary small margin-right-less'>Cancel</button>
        <button type='button' onClick={onSaveComment} className='btn-primary small'>Submit response</button>
      </div>
    </div>
  );
};

ReplyResponse.propTypes = {
  discussion: PropTypes.object,
  close: PropTypes.func,
};

const DISCUSSION_SELECT_ID = 'discussionOptionContainer';
export const DISCUSSION_SELECT_STYLES = {
  container: (provided) => ({
    ...provided,
    display: 'inline-block',
  }),
  menu: (provided) => ({
    // Make the menu wide enough to accomodate the longest
    // option without widening the container
    ...provided,
    minWidth: '10rem',
  }),
  control: (provided) => ({
    ...provided,
    // This is to whack the background provided by
    // CSS, which is higher-specificity
    background: 'inherit !important',
  }),
  valueContainer: (provided) => ({
    ...provided,
    // Make it left-aligned with everything else
    paddingLeft: '5px',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '0.83rem',
    lineHeight: '20px',
  }),
};

const DiscussionItem = ({
  active,
  flash,
  discussion,
  onMakeActive,
  onMakeInactive,
  discussionOptions,
  userIsAdmin,
}) => {
  const dispatch = useContext(DiscussionDispatchContext);
  const manageDiscussionRef = useRef();
  const deleteDiscussionRef = useRef();
  const resolvedWrapperRef = useRef();

  // Status
  const [editing, setEditing] = useState(false);
  const [replying, setReplying] = useState(false);
  const [highlighted, setHighlighted] = useState(false);

  // Input states
  const closeCommentBox = useCallback(() => {
    setReplying(false);
    if (!editing) {
      onMakeInactive();
    }
  }, [editing, onMakeInactive]);

  const closeEditBox = useCallback(() => {
    setEditing(false);
    if (!replying) {
      onMakeInactive();
    }
  }, [onMakeInactive, replying]);

  useEffect(() => {
    if (!active) {
      setEditing(false);
      setReplying(false);
    }
  }, [active]);

  // Flash if necessary
  useEffect(() => {
    setTimeout(() => {
      if (flash) {
        setHighlighted(true);
        setTimeout(() => {
          setHighlighted(false);
        }, 3000);
      }
    }, 200);
  }, [flash]);

  const onResolvedChange = (e) => {
    const { checked } = e.currentTarget;
    if (discussion.step_approval) {
      const text = `This will also mark the requested changes as ${checked ? '' : 'in'}complete. Do you want to proceed?`;
      confirm(`${checked ? 'Close' : 'Reopen'} discussion?`, text, () => {
        DiscussionActions.resolve(dispatch, discussion.id, checked)
          .done(() => { Flash.success('Discussion updated'); });
      }, () => {
        e.target.checked = !checked;
      });
    } else {
      DiscussionActions.resolve(dispatch, discussion.id, checked)
        .done(() => { Flash.success('Discussion updated'); });
    }
  };

  // TODO: revisit this
  const discussionHTMLName = `checkbox${discussion.id}`;

  const onReplyClick = (e) => {
    e.preventDefault();
    setReplying(true);
    // TODO: see what this is doing and if it makes sense
    onMakeActive();
  };

  const isResolvedClick = (target) => {
    return resolvedWrapperRef.current.contains(target) || resolvedWrapperRef.current === target;
  };
  const isSelectClick = (target) => {
    const select = document.querySelector(`#${DISCUSSION_SELECT_ID}`);
    // NOTE: this won't be in the DOM if the user isn't an admin, so we
    // check that it exists before we assert against it.
    return select && (select.contains(target) || select === target);
  };

  const canManageDiscussion = (discussion.user.id === window.CityGrows.Server.currentUserId);

  const isManageOrDeleteClick = (target) => {
    if (!canManageDiscussion) { return false; }

    return manageDiscussionRef.current === target || deleteDiscussionRef.current === target;
  };

  // TODO: see what the intent is here - is it necessary?
  const onContainerClick = (e) => {
    if (isResolvedClick(e.target)) { return; }
    if (isManageOrDeleteClick(e.target)) { return; }
    if (isSelectClick(e.target)) { return; }
    if (editing || replying) { return; }

    onMakeActive();
    setReplying(true);
  };

  const renderModeContent = () => {
    if (replying) {
      return (
        <div className='discussion-activewrap'>
          {/* NOTE: this empty div is here to allow the .sub styling to use last-child to remove
          the bottom border on the last comment. */}
          {/* TODO: rewrite those styles to decouple this */}
          <div>
            {discussion.discussion_comments.map((discussionComment) => (
              <DiscussionComment key={discussionComment.id} discussionComment={discussionComment} />
            ))}
          </div>
          <ReplyResponse discussion={discussion} close={closeCommentBox} />
        </div>
      );
    }

    const numComments = discussion.discussion_comments.length;
    const actionWord = numComments > 0 ? `Show ${numComments} comments & reply` : 'Reply';

    return <a onClick={onReplyClick} href='#' className='margin-top inline-block secondary'>{actionWord}</a>;
  };

  const onTypeChange = ({ value }) => {
    // TODO: figure out if this needs local state or if it needs to just track
    // the props from the parent
    DiscussionActions.update(dispatch, discussion.id, { type: value })
      .done(() => { Flash.success('Discussion updated'); });
  };

  const onEditClick = (e) => {
    e.preventDefault();
    setEditing(true);
    onMakeActive();
  };

  const onDeleteClick = (e) => {
    e.preventDefault();
    let text = 'Are you sure you want to delete this comment?';
    if (discussion.step_approval) {
      text = 'This will mark the change request as complete. Do you want to proceed?';
    }
    e.preventDefault();
    confirm('Delete comment?', text, () => {
      DiscussionActions.deleteDiscussion(dispatch, discussion.id);
    });
  };

  const renderContext = () => {
    const approval = discussion.step_approval;

    if (approval) {
      switch (approval.approval_status) {
      case true:
        return <ApprovalStatus verb='Approved' className='approval' groupName={approval.review_group_name} />;
      case false:
        return <ApprovalStatus verb='Rejected' className='rejection' groupName={approval.review_group_name} />;
      case null:
        return <ApprovalStatus verb='Change requested' className='changerequest' groupName={approval.review_group_name} />;
      default:
        throw Error(`Unhandled approval_status ${approval.approval_status}`);
      }
    }
  };

  const wrapperClass = [
    active && 'active',
    highlighted && 'flash',
  ].filter(Boolean).join(' ');

  return (
    <li onClick={onContainerClick} className={wrapperClass}>
      {renderContext()}

      <div className={`color-left ${discussion.resolved ? 'success' : 'neutral'}`}>
        <div className='discussion-options margin-bottom-less'>
          {/* TODO: Refactor this out into its own component */}
          {/* NOTE: This is all based around styling our own checkbox. */}
          <div className='discussion-resolvedcheckwrapper'>
            <label ref={resolvedWrapperRef} className='checkbox inline-block vmiddle'>
              <input onChange={onResolvedChange} id={discussionHTMLName} type='checkbox' defaultChecked={discussion.resolved} />
              {/* TODO: what is this span for? */}
              <span />
            </label>
            <label className='inline-block nomargin' htmlFor={discussionHTMLName}>Resolved</label>
          </div>

          {userIsAdmin && (
            <Select
              // This would be better to do with a ref, but since there's not a way
              // to put a ref on the container directly, this will do.
              id={DISCUSSION_SELECT_ID}
              options={discussionOptions}
              value={discussionOptions.find(({ value }) => value === discussion.type)}
              onChange={onTypeChange}
              disabled={!canManageDiscussion}
              // TODO: clicking this triggers the "active" click handler thing
              isSearchable={false}
              // Fixing the position on this one is a hack to prevent it from overflowing
              // and forcing a scroll bar
              menuPosition='fixed'
              styles={DISCUSSION_SELECT_STYLES}
            />
          )}
        </div>

        <div className='discussion-comment'>
          <div className='relative'>
            <span className='discussion-name margin-right-less'>{discussion.user.name}</span>
            <TimeAgo
              className='timeago discussion-timestamp'
              date={discussion.created_at}
              title={dayjs(discussion.created_at).format('LLL')}
            />

            {canManageDiscussion && (
              <div className='discussion-comment-manage'>
                <a title='Edit Comment' href='#' onClick={onEditClick}>
                  <i ref={manageDiscussionRef} className='clickable icon-pencil-grey small' />
                </a>
                <a title='Delete comment' href='#' onClick={onDeleteClick}>
                  <i ref={deleteDiscussionRef} className='clickable icon-trash-grey small margin-left-less' />
                </a>
              </div>
            )}
          </div>

          <div className='discussion-body'>
            {(active && editing)
              ? (
                <EditResponse discussion={discussion} close={closeEditBox} />
              )
              : discussion.body}
          </div>
        </div>

        {renderModeContent()}
      </div>
    </li>
  );
};

DiscussionItem.propTypes = {
  discussion: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
  onMakeActive: PropTypes.func.isRequired,
  onMakeInactive: PropTypes.func.isRequired,
  discussionOptions: PropTypes.array,
  flash: PropTypes.bool,
};

DiscussionItem.defaultProps = {
  flash: false,
};

export default DiscussionItem;
