import React, { memo, useContext, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RequestIsGovContext from 'contexts/request-is-gov-context';
import PersonSelector from 'components/shared/person-selector';
import { isEmpty, isNull } from 'underscore';
import InitiatedFlowActions from 'actions/initiated-flow-actions';
import UserDisc from 'components/shared/user-disc';
import Selection from 'components/shared/person-selection';
import Options from './subprocess_requirement/options';

const SubprocessRequirement = memo((props) => {
  const isGov = useContext(RequestIsGovContext);
  const defaultApplicant = props.applicants.find((user) => user.id === CityGrows.Server.currentUserId) || props.primaryApplicant;
  const [primaryApplicant, setPrimaryApplicant] = useState(defaultApplicant);
  const [isLaunching, setIsLaunching] = useState(false);
  const [applicants, setApplicants] = useState(props.applicants);
  const [selectedApplicants, setSelectedApplicants] = useState([]);

  useEffect(() => {
    if (isLaunching) {
      document.getElementById('subprocess-start').focus()
    }
  }, [isLaunching])

  const onApplicantSelect = (applicant) => {
    setApplicants(props.applicants.filter((user) => { return user.id !== applicant.id; }));
    setSelectedApplicants(selectedApplicants.filter((user) => { return user.id !== applicant.id; }));
    setPrimaryApplicant(applicant);
  };

  const onSelectedApplicantChange = (e, applicant) => {
    if (e.currentTarget.checked) {
      setSelectedApplicants([...selectedApplicants, applicant]);
    } else {
      setApplicants(selectedApplicants.filter((user) => { return user.id !== applicant.id; }));
    }
  };

  const onApplicantRemove = () => {
    setPrimaryApplicant(null);
    setApplicants(props.applicants);
  };

  const onApplicantSearchCancel = () => {
    setPrimaryApplicant(defaultApplicant);
  };

  const onPrimaryApplicantChange = (applicant) => {
    setApplicants(props.applicants.filter((user) => { return user.id !== applicant.id; }));
    setSelectedApplicants(selectedApplicants.filter((user) => { return user.id !== applicant.id; }));
    setPrimaryApplicant(applicant);
  };

  const linkPath = useMemo(() => {
    if (props.requirement.subprocess_id) {
      return `/initiated_flows/manager/${props.requirement.subprocess_id}`;
    }

    let applicantsParams = `&primary_applicant_id=${primaryApplicant?.id}`;
    if (!isEmpty(selectedApplicants)) {
      applicantsParams += selectedApplicants.map((applicant) => { return `&secondary_applicant_ids[]=${applicant.id}`; }).join('');
    }

    return `/initiated_flows/manager?flow_template_id=${props.requirement.subprocess_workflow.id}&subprocess_requirement_id=${props.requirement.id}${applicantsParams}`;
  }, [props.requirement, primaryApplicant, selectedApplicants]);

  function status() {
    if (props.requirement.subprocess_workflow_not_published) { return 'done'; }

    if (props.requirement.completed && props.requirement.subprocess_id) { return 'done'; }

    return 'notdone';
  }

  function allowedToStart() {
    return !props.requirement.subprocess_workflow_not_published;
  }

  const renderLink = () => {
    if (props.requirement.subprocess_workflow_not_published) {
      return (
        <div className='flowtimeline-stepnamewrap'>
          <span className='flowtimeline-stepname'>
            {props.requirement.subprocess_workflow.name}
            <span className='block text-smaller color-text-medium'>Legacy Requirement. No action necessary</span>
          </span>
        </div>
      );
    }

    const showAsLink = !!props.requirement.subprocess_id;
    const linkMethod = props.requirement.subprocess_id ? 'GET' : 'POST';
    if (allowedToStart() && showAsLink) {
      return (
        <a href={linkPath} data-method={linkMethod} disabled={!allowedToStart()}>
          {props.requirement.subprocess_workflow.name}
        </a>
      );
    }

    return <span className='flowtimeline-stepnamewrap-title'>{props.requirement.subprocess_workflow.name}</span>;
  };

  const renderStartButton = () => {
    if (isLaunching) {
      if (primaryApplicant) {
        return <a id='subprocess-start' href={linkPath} data-method='POST'>Okay</a>;
      }
      return <span className='flowtimeline-stepnamewrap-title color-text-medium'>Okay</span>;
    }

    return <button type='button' className='btn-link' onClick={() => setIsLaunching(true)}>Start &gt;</button>;
  };

  const searchPerson = (search) => {
    return InitiatedFlowActions.searchApplicants(search);
  };

  const renderApplicants = () => {
    const filteredApplicants = isNull(primaryApplicant)
      ? applicants
      : applicants.filter((applicant) => {
        return applicant.id !== primaryApplicant.id;
      });

    if (!isEmpty(filteredApplicants)) {
      return (
        <>
          <span className='title'>Applicants</span>
          <ul id='applicantlist' className='applicantmanager-applicants margin-top'>
            {filteredApplicants.map(renderApplicant)}
          </ul>
        </>
      );
    }
  };

  const renderApplicant = (applicant) => {
    const showMakePrimaryButton = !applicant.pseudo;
    return (
      <li key={applicant.id} className='cf'>
        <div title={applicant.name} className='float-left' style={{ marginTop: '4px' }}>
          <input title={applicant.name} value='true' type='checkbox' name='carry' onClick={(e) => { onSelectedApplicantChange(e, applicant); }} />
        </div>
        <div  title={applicant.name} className='float-left'>
          <UserDisc
            user={applicant}
            options={{
              showName: true,
              showPseudo: true,
              showAsCurrent: applicant.id === CityGrows.Server.currentUserId,
            }}
          />
        </div>
        <div className='float-right'>
          <div className='applicantmanager-actions' style={{ marginTop: '6px' }}>
            {showMakePrimaryButton && (
              <>
                <button className='btn-link' type='button' onClick={() => { onPrimaryApplicantChange(applicant); }}>Make primary</button>
                &nbsp;
              </>
            )}
          </div>
        </div>
      </li>
    );
  };

  const renderPrimaryApplicant = () => {
    if (primaryApplicant) {
      return (
        <Selection
          selection={primaryApplicant}
          onRemove={onApplicantRemove}
          className='personselection-icon'
          showAsCurrentUser={primaryApplicant.id === CityGrows.Server.currentUserId}
          removeLinkText='Change'
          disableRemoval={!CityGrows.Server.admin}
        />
      );
    }
    return (
      <>
        <PersonSelector
          onSelect={onApplicantSelect}
          doSearch={searchPerson}
          allowPseudo={false}
          hideExternal={false}
          placeholder='Search people'
          attachableType='Applicant'
          personNoFoundText='Not found, the user needs to create an account first.'
        />
        <button className='btn-link' onClick={onApplicantSearchCancel} type='button'>Cancel</button>;
      </>
    );
  };

  const renderApplicantContainer = () => {
    return (
      <div className='applicantmanager-wrap'>
        <span className='title'>Launching sub-submission as:</span>
        <div className='margin-top margin-bottom'>
          {renderPrimaryApplicant()}
        </div>
        {renderApplicants()}
      </div>
    );
  };

  const renderExtra = () => {
    if (props.requirement.waived_at) {
      return <span className='bold'>Requirement waived</span>;
    }

    if (!props.requirement.subprocess_id) {
      if (allowedToStart()) {
        return (
          <div className='padding-right'>
            {isLaunching && renderApplicantContainer()}
            {renderStartButton()}
          </div>
        );
      }
      return <span className='bold color-text-light'>Start &gt;</span>;
    }

    return (
      <>
        <span className='margin-right-less'>Linked sub-submission:</span>
        <a href={linkPath}>
          {props.requirement.subprocess_workflow.name} #{props.requirement.subprocess_scoped_id}
        </a>
      </>
    );
  };

  const renderOptions = () => {
    if (isGov || !!props.requirement.subprocess_id) {
      return (
        <Options
          requirementId={props.requirement.id}
          isWaived={!!props.requirement.waived_at}
          unlinkable={!!props.requirement.subprocess_id}
          isGov={isGov}
        />
      );
    }
  };

  return (
    <li className='flowtimeline-activerequirement'>
      <div className={`flowtimeline-steptopline ${status()}`}>
        <span className='flowtimeline-stepicon icon icon-arrow-down-right' title='Sub-submission' />
        <div className='flowtimeline-stepnamewrap'>
          <div>
            {renderLink()}
            <div className='flowtimeline-activerequirement-extra'>
              {renderExtra()}
            </div>
          </div>
        </div>
        {renderOptions()}
      </div>
    </li>
  );
});

SubprocessRequirement.displayName = 'SubprocessRequirement';

SubprocessRequirement.propTypes = {
  primaryApplicant: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  requirement: PropTypes.shape({
    id: PropTypes.number.isRequired,
    completed: PropTypes.bool.isRequired,
    subprocess_id: PropTypes.number,
    subprocess_scoped_id: PropTypes.number,
    subprocess_workflow_not_published: PropTypes.bool.isRequired,
    subprocess_workflow: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }),
  applicants: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })),
};

export default SubprocessRequirement;
