import React from 'react';
import PropTypes from 'prop-types';

const Labelbox = (props) => {
  const themeClass = () => {
    switch (props.theme) {
    case 'alert':
      return 'alert';
      break;
    case 'default':
      return '';
      break;
    default:
      return '';
    }
  };

  return (
    <div className={`labelbox margin-top ${themeClass()}`}>
      <span className='labelbox-label'>{props.label}</span>
      <div className='labelbox-box'>
        {props.children}
      </div>
    </div>
  );
};

Labelbox.propTypes = {
  theme: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default Labelbox;
