import React, { useState, useEffect } from 'react';
import truncate from 'truncate';

const UserMenu = (props) => {
  const [open, setOpen] = useState(false);

  function checkTab(e) {
    if (e.keyCode === 9 && !e.shiftKey) {
      setOpen(false);
    }
  }

  return (
    <li className='nav-profilelink public'>
      <button onClick={() => {setOpen(!open)}} className='btn-link nav-profilelink-email dashboard-link'>
        {truncate(props.currentUser.name, 25)}
      </button>
      <div className={`popup nopadding ${open ? 'open' : ''}`}>
        <div className='popup-nub' />
        <ul className='popup-listmenu'>
          <li className='popup-listmenu-addendum'>
            <div> Logged in as</div>
            <div title={props.currentUser.email}>{truncate(props.currentUser.email, 25)}</div>
          </li>
          <li><a href='/account'>My profile</a></li>
          <li>
            <a href='/users/sign_out' data-method='delete' onKeyDown={checkTab}>
              Sign out
            </a>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default UserMenu;
