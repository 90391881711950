import API from '../apis/activity-api';
import Constants from '../constants/activity-log-constants';

const ActivityLogActions = {
  loadActivities(initiatedFlowId, dispatch) {
    return API.index(initiatedFlowId)
      .done((res) => {
        dispatch({
          type: Constants.LOADED,
          activities: res,
        });
      });
  },
};

export default ActivityLogActions;
