export const update = (id, attrs) => {
  const url = `/issued_outputs/${id}`;

  return $.ajax(url, {
    data: {
      issued_output: attrs,
    },
    dataType: 'json',
    method: 'PUT',
  });
};

export const revoke = (id) => {
  const url = `/issued_outputs/${id}/revoke`;

  return $.ajax(url, {
    dataType: 'json',
    method: 'POST',
  });
};

export const unrevoke = (id) => {
  const url = `/issued_outputs/${id}/unrevoke`;

  return $.ajax(url, {
    dataType: 'json',
    method: 'POST',
  });
};

export const renewalOptIn = (id) => {
  const url = `/issued_outputs/${id}/renewal_opt_in`;

  return $.ajax(url, {
    dataType: 'json',
    method: 'POST',
  });
};

export const renewalOptOut = (id) => {
  const url = `/issued_outputs/${id}/renewal_opt_out`;

  return $.ajax(url, {
    dataType: 'json',
    method: 'POST',
  });
};

export default {
  update,
  revoke,
  unrevoke,
  renewalOptIn,
  renewalOptOut,
};
