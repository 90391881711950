import React, {
  useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { success } from 'components/shared/flash';
import MultipleChoiceOptionActions from 'actions/multiple-choice-option-actions';
import Select from 'components/shared/select';
import { validate } from 'modules/collected-data-validator';
import SlidingToggle from 'components/shared/sliding-toggle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import AdvancedLabel from 'components/shared/advanced-label';
import { debounce } from 'underscore';
import NumericSettings from './settings/numeric-settings';
import AdvancedSettings from './settings/advanced-settings';
import TextSettings from './settings/text-settings';
import DateSettings from './settings/date-settings';
import CheckboxSettings from './settings/checkbox-settings';

const TYPES_WITH_DEFAULTS = [
  'string',
  'integer',
  'float',
  'email',
  'phone',
  'money',
  'multiple_choice',
];

const TYPES_WITH_TYPE_SPECIFIC_SETTINGS = [
  'string',
  'text',
  'checkboxes',
  'integer',
  'float',
  'money',
  'date',
  'date_range',
];

const FieldSettings = ({
  stepDatum,
  onDataChanged,
  multipleChoiceOptions,
  fieldName,
  autofillEnabled,
  children,
}) => {
  const canConfigureDefault = TYPES_WITH_DEFAULTS.includes(stepDatum.data_type);
  const hasTypeSpecificSettings = TYPES_WITH_TYPE_SPECIFIC_SETTINGS.includes(stepDatum.data_type);

  const [defaultValue, setDefaultValue] = useState(stepDatum.default || '');
  const [defaultOptionId, setDefaultOptionId] = useState(multipleChoiceOptions
    .find((option) => option.is_default === true)?.id || null);
  const [entityCreateable, setEntityCreateable] = useState(stepDatum.entity_createable);
  const [open, setOpen] = useState(false);

  const canAllowNewEntity = useMemo(() => {
    return stepDatum.data_type.startsWith('entity')
      && stepDatum.custom_entity
      && stepDatum.custom_entity.type !== 'EsriCustomEntity';
  }, [stepDatum.data_type, stepDatum.custom_entity]);

  const debouncedOnDataChanged = debounce(onDataChanged, 500);

  const validateDefault = () => {
    if ([null, ''].includes(defaultValue)) { return true; }
    return validate(defaultValue, stepDatum);
  };

  const defaultInput = () => {
    const options = multipleChoiceOptions
      .map(({ id, name }) => ({ value: id, label: name }));
    const input = (stepDatum.data_type === 'multiple_choice')
      ? (
        <Select
          id='default'
          options={options}
          // the || null here lets it be clearable.
          value={options.find(({ value }) => value === defaultOptionId) || null}
          onChange={onDefaultSelect}
          placeholder='Select default option'
          styles={{
            container: (provided) => ({
              ...provided,
              minWidth: '12rem',
            }),
          }}
        />
      )
      : (
        <input
          id='default'
          className={`fieldmanager-defaultfield ${!validateDefault() ? 'invalid' : ''}`}
          type={getDefaultInputType()}
          name='default'
          value={defaultValue}
          placeholder='Add a default'
          onChange={onDefaultChange}
        />
      );

    return (
      <>
        <div className='inline-block'>{input}</div>
        <FontAwesomeIcon
          icon={icon({ name: 'trash' })}
          className='clear-button margin-left'
          onClick={onDefaultClear}
          title='Clear Default'
        />
      </>
    );
  };

  const toggleAdvancedSettings = () => {
    setOpen(!open);
  };

  const onDefaultChange = (e) => {
    const { value } = e.currentTarget;
    setDefaultValue(value);
    debouncedOnDataChanged({ default: value });
  };

  const onDefaultClear = (e) => {
    e.preventDefault();

    // Multiple choice fields don't use the same
    // database info to determine defaults

    if (stepDatum.data_type === 'multiple_choice') {
      if (!defaultOptionId) { return; }

      MultipleChoiceOptionActions.updateOption(Number(defaultOptionId), {
        is_default: false,
      }).done(() => {
        setDefaultOptionId(null);
        success('Default option cleared');
      });
    } else {
      setDefaultValue('');
      onDataChanged({ default: '' });
    }
  };

  const getDefaultInputType = () => {
    switch (stepDatum.data_type) {
    case 'email':
      return 'email';
    case 'phone':
      return 'tel';
    default:
      return 'text';
    }
  };

  const onDefaultSelect = ({ value }) => {
    MultipleChoiceOptionActions.updateOption(Number(value), {
      is_default: true,
    }).done(() => {
      setDefaultOptionId(multipleChoiceOptions
        .find((option) => option.id === value).id);
      success('Default option updated');
    });
  };
  const renderDefaultIsInvalidWarning = () => {
    if (!validateDefault()) {
      return (
        <div className='fieldmanager-invalidfield'>
          Invalid default value
        </div>
      );
    }
  };

  const onEntityCreatableChanged = () => {
    onDataChanged(
      { entity_createable: !entityCreateable },
      () => {
        setEntityCreateable(!entityCreateable);
      },
    );
  };

  const renderTypeSpecificSettings = () => {
    switch (stepDatum.data_type) {
    case 'text':
    case 'string':
      return (
        <TextSettings
          maxLength={stepDatum.max_length || ''}
          onDataChanged={debouncedOnDataChanged}
        />
      );
    case 'checkboxes':
      return (
        <CheckboxSettings
          numberOfOptions={multipleChoiceOptions.length}
          minNumberOfChecks={stepDatum.min_num_checks}
          onDataChanged={onDataChanged}
        />
      );
    case 'integer':
    case 'float':
    case 'money':
      return (
        <NumericSettings
          stepDatum={stepDatum}
          onDataChanged={onDataChanged}
          onLimitChanged={validateDefault}
        />
      );
    case 'date':
    case 'date_range':
      return (
        <DateSettings
          dateRangeStartLabel={stepDatum.date_range_start_label}
          dateRangeEndLabel={stepDatum.date_range_end_label}
          dateType={stepDatum.date_type}
          dataType={stepDatum.data_type}
          onDataChanged={onDataChanged}
        />
      );
    default:
      throw Error(`Unhandled data type ${stepDatum.data_type}`);
    }
  };
  const renderCustomEntitySettings = () => {
    return (
      <tr>
        <td>
          <AdvancedLabel
            label='Custom Entity'
          />
        </td>
        <td>
          <div className='margin-top'>
            <SlidingToggle
              onToggle={onEntityCreatableChanged}
              on={entityCreateable}
              label={`Allow applicant to create a new ${fieldName}`}
            />
          </div>
        </td>
      </tr>
    );
  };

  function renderToggle () {
    const iconClass = open ? 'flip-y' : '';

    return (
      <div className='fieldmanager-advancedsettingstoggle'>
        <button
          onClick={toggleAdvancedSettings}
          type='button'
          className='btn-link strong'
        >
          Advanced Settings
          <span className={`${iconClass} icon icon-arrow-down small color2 margin-left-less`} />
        </button>
      </div>
    );
  };

  function renderAdvancedSettings () {
    return (
      <div className='fieldmanager-advancedsettings'>
        <table>
          <tbody>
            {canConfigureDefault && (
              <tr>
                <td>
                  <AdvancedLabel
                    label='Default Value'
                  />
                </td>
                <td>
                  {defaultInput()}
                  {renderDefaultIsInvalidWarning()}
                </td>
              </tr>
            )}
            {(hasTypeSpecificSettings) && renderTypeSpecificSettings()}
            {canAllowNewEntity && renderCustomEntitySettings()}
            <AdvancedSettings
              redacted={stepDatum.redacted}
              required={stepDatum.required}
              lockOnRenewal={stepDatum.lock_on_renewal}
              autofillEnabled={autofillEnabled}
              autofillOnRenewal={stepDatum.autofill_on_renewal}
              onDataChanged={onDataChanged}
            />
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div className='cf'>
       {children}
      {renderToggle()}
      {open && renderAdvancedSettings()}
    </div>
  );
};

FieldSettings.displayName = 'FieldSettings';

FieldSettings.propTypes = {
  stepDatum: PropTypes.shape({
    data_type: PropTypes.string.isRequired,
    default: PropTypes.string,
    min_num_checks: PropTypes.number.isRequired,
    id: PropTypes.number,
    max_length: PropTypes.number,
    min_value: PropTypes.string,
    max_value: PropTypes.string,
    custom_entity: PropTypes.shape({
      type: PropTypes.string,
    }),
    required: PropTypes.bool,
    redacted: PropTypes.bool,
    date_type: PropTypes.string,
    date_range_start_label: PropTypes.string,
    date_range_end_label: PropTypes.string,
    autofill_on_renewal: PropTypes.bool,
    lock_on_renewal: PropTypes.bool,
    entity_creatable: PropTypes.bool,
  }).isRequired,
  multipleChoiceOptions: PropTypes.arrayOf(PropTypes.shape({})),
  autofillEnabled: PropTypes.bool.isRequired,
  onDataChanged: PropTypes.func.isRequired,
  fieldName: PropTypes.string,
  transitionRef: PropTypes.shape({}),
};

export default FieldSettings;
