import PropTypes from 'prop-types';
import React, {
  useRef, useState, useEffect, useMemo,
} from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import FlowStepActions from 'actions/flow-step-actions';
import SurveyActions from 'actions/survey-actions';
import { reorder } from 'modules/dnd-utils';
import { STEP_ROW, STEP_SECTION } from 'constants/template-manager-constants';
import StepDataSection from './data_collector/step-data-section';
import useDerivedState from '../hooks/use-derived-state';

const positionSort = (a, b) => a.position - b.position;
const transformStepData = (stepData, sections) => (
  Object.fromEntries(Object.entries(stepData.reduce(
    // This is kind of janky, but it basically takes an array and fans it out into an object
    // keyed by step_id
    (acc, datum) => ({
      ...acc,
      [datum.section_id]: [...acc[datum.section_id], datum],
    }),
    Object.fromEntries(sections.map((section) => [section.id, []])),
  )).map(([datumStepId, datumArray]) => [datumStepId, datumArray.sort(positionSort)]))
);

const getSectionId = (droppable) => (
  Number(droppable.droppableId.split('-')[2])
);

const StepDataCollector = ({
  disabled,
  sectionableType,
  sectionableId,
  stepId,
  flowTemplate,
  allowedStepDatumTypes,
  sections,
  stepData,
}) => {
  const sectionsBottomRef = useRef();
  const [activeDatumId, setActiveDatumId] = useState(null);

  // NOTE: we use `useMemo` here so that if sections is a stable reference, so
  // is the sorted section, which makes it play nicely with `useDerivedState`
  const sortedSectionsFromProps = useMemo(() => sections.sort(positionSort), [sections]);
  const [orderedSections, setOrderedSections] = useDerivedState(sortedSectionsFromProps);

  // NOTE: stepData aren't associated with sections directly
  // - they're not properties, but rather their own array
  const sortedStepDatFromProps = useMemo(
    () => transformStepData(stepData, sections),
    [stepData, sections],
  );
  const [orderedStepData, setOrderedStepData] = useDerivedState(sortedStepDatFromProps);

  const [prevLength, setPrevLength] = useState(sections.length);
  useEffect(() => {
    if (sections.length !== prevLength) {
      if (sections.length > prevLength) {
        // If we're removing a section, we don't want to
        // jump them to the bottom.
        sectionsBottomRef.current.scrollIntoView();
      }
      setPrevLength(sections.length);
    }
  }, [sections, prevLength]);

  const addSectionClicked = () => {
    switch (sectionableType) {
    case 'Step':
      return FlowStepActions.addSection(sectionableId);
    case 'Survey':
      return SurveyActions.addSection(sectionableId, stepId);
    default:
      return null;
    }
  };

  const onDragEnd = ({
    source, destination, type, draggableId,
  }) => {
    // We didn't drop in the draggable
    if (!destination) {
      return;
    }
    // We dropped it in the same place
    if (destination.index === source.index
      && source.droppableId === destination.droppableId) {
      return;
    }
    if (type === STEP_SECTION) {
      setOrderedSections((oldSections) => reorder(
        oldSections,
        source.index,
        destination.index,
      ));
      const draggedSectionId = orderedSections[source.index].id;
      const newPosition = destination.index + 1;
      FlowStepActions.moveSection(draggedSectionId, newPosition)
        .fail(() => setOrderedSections(orderedSections));
    }
    if (type === STEP_ROW) {
      // TODO: this is kind of magical. It'd probably be better to do this without
      // string manipulation.
      const sourceSectionId = getSectionId(source);
      const destSectionId = getSectionId(destination);
      if (sourceSectionId === destSectionId) {
        // They're in the same list and we'll reorder
        // that list
        setOrderedStepData((oldStepData) => ({
          ...oldStepData,
          [sourceSectionId]: reorder(oldStepData[sourceSectionId], source.index, destination.index),
        }));
      } else {
        const draggedDatum = orderedStepData[sourceSectionId][source.index];
        setOrderedStepData((oldStepData) => {
          // TODO: there's gotta be a better way to do this
          const sourceArray = [...oldStepData[sourceSectionId]];
          sourceArray.splice(source.index, 1);
          const destArray = [...oldStepData[destSectionId]];
          destArray.splice(destination.index, 0, draggedDatum);
          return {
            ...oldStepData,
            [sourceSectionId]: sourceArray,
            [destSectionId]: destArray,
          };
        });
      }
      // TODO: also magical
      const datumId = Number(draggableId.split('-')[1]);
      const newPosition = destination.index + 1;
      FlowStepActions.moveStepData(datumId, newPosition, destSectionId)
        .fail(() => setOrderedStepData(orderedStepData));
    }
  };
  return (
    <div>
      <DragDropContext
        onDragEnd={onDragEnd}
      >
        <Droppable
          droppableId='section-droppable'
          type={STEP_SECTION}
        >
          {({ innerRef, droppableProps, placeholder }) => (
            <div ref={innerRef} {...droppableProps}>
              <TransitionGroup>
                {orderedSections.map((section, index) => {
                  return (
                    <CSSTransition
                      classNames='zoom-in'
                      timeout={300}
                      key={section.id}
                    >
                      <StepDataSection
                        index={index}
                        section={section}
                        sectionableType={sectionableType}
                        sectionableId={sectionableId}
                        stepId={stepId}
                        stepData={orderedStepData[section.id]}
                        allowedStepDatumTypes={allowedStepDatumTypes}
                        disabled={disabled}
                        activeDatumId={activeDatumId}
                        onDatumClick={setActiveDatumId}
                        flowTemplate={flowTemplate}
                      />
                    </CSSTransition>
                  );
                })}
              </TransitionGroup>
              {placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div ref={sectionsBottomRef} />
      {!disabled && (
        <div className='stepcreator-addnewsection'>
          <button type='button' id='addsectionbutton' className='btn-secondary fullwidth' onClick={addSectionClicked}>+ Add new section</button>
        </div>
      )}
    </div>
  );
};

StepDataCollector.propTypes = {
  sections: PropTypes.array.isRequired,
  stepId: PropTypes.number.isRequired,
  sectionableId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  flowTemplate: PropTypes.object,
  allowedStepDatumTypes: PropTypes.array.isRequired,
  sectionableType: PropTypes.string.isRequired,
  stepData: PropTypes.array,
};

export default StepDataCollector;
