import React from 'react';
import PropTypes from 'prop-types';
import SurveySubmissionActions from 'actions/survey-submission-actions';
import SurveyInvitationLine from './survey-invitation-line';

class SurveyInvitations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reminded: false,
    };
  }

  rendersurveyInvitations = () => {
    const invitations = this.props.surveyInvitations;

    if (invitations.length) {
      return (
        <ul className='surveystep-responses'>
          {invitations.map(this.renderSurveyInvitationLine)}
        </ul>
      );
    }
  };

  renderSurveyInvitationLine(invitation) {
    return (
      <SurveyInvitationLine
        invitation={invitation}
        key={invitation.id}
      />
    );
  }

  onRemindAllClick = (e) => {
    e.preventDefault();

    if (this.state.reminded) { return; }

    this.setState({ reminded: true }, () => {
      SurveySubmissionActions.sendAllReminder(this.props.activeStepId)
             .fail(this.onRemindFailed);
    });
  };

  onRemindFailed = () => {
    this.setState({
      reminded: false,
    });
  };

  render() {
    if (this.props.surveyInvitations.length === 0) { return null; }

    return (
      <section className='margin-top-more'>
        <div className='surveystep-label inline-block padding-bottom'>Invites ({this.props.surveyInvitations.length})</div>
        <a
          href={`/active_steps/${this.props.activeStepId}/survey_invitations/export`}
          data-method='POST'
          className='float-right secondary'
        >
          Export invitations
        </a>
        <a
          href='#'
          onClick={this.onRemindAllClick}
          className='float-right secondary margin-right'
        >
          { this.state.reminded ? 'Reminder sent' : 'Remind all' }
        </a>
        {this.rendersurveyInvitations()}
      </section>
    );
  }
}

SurveyInvitations.propTypes = {
  surveyInvitations: PropTypes.array.isRequired,
  activeStepId: PropTypes.number.isRequired,
};

export default SurveyInvitations;
