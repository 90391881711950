import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import fetchRoles from 'modules/fetch-roles';
import CloseIfOutsideWrapper from 'components/shared/close-if-outside-wrapper';
import LoadingSpinner from 'components/shared/loading-spinner';
import { reject, contains, debounce } from 'underscore';

const RoleSearch = (props) => {
  const [active, setActive] = useState(false);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const loadRoles = useCallback(debounce((newSearchTerm) => {
    setLoading(true);
    fetchRoles(props.teamId, { searchTerm: newSearchTerm, userId: props.userId })
      .done((response) => {
        setLoading(false);
        setRoles(response.roles);
      });
  }, 500), []);

  useEffect(() => {
    loadRoles(searchTerm);
  }, [searchTerm, loadRoles]);

  function onClose() {
    setActive(false);
  }

  function onRolesSearchChange(e) {
    setSearchTerm(e.currentTarget.value);
  }

  function rolesToDisplay() {
    const attachedRoleIds = props.rolesToFilter.map((r) => { return r.id; });
    return reject(roles, (role) => { return contains(attachedRoleIds, role.id); });
  }

  function renderRoles() {
    if (loading) {
      return <div className='text-center'><LoadingSpinner size='small' className='vmiddle' /></div>;
    }

    if (roles.length > 0 && rolesToDisplay().length > 0) {
      return (
        <div className='attachedpeople-manager search-results'>
          <ul className='attachedpeople-manager-rolelist'>
            {rolesToDisplay().map(renderRole)}
          </ul>
        </div>
      );
    }

    const referrerLink = `/teams/${props.teamSlug}/roles/new?referrer_type=${props.referrer.type}&referrer_id=${props.referrer.id}`;
    return (
      <div className='attachedpeople-manager no-results'>
        No matching roles found. Clear your search and try again or <a href={referrerLink}>create a new role</a>
      </div>
    );
  }

  function renderRole(role) {
    const label = props.userId ? `(${role.label})` : null;
    return (
      <li key={role.id} onClick={(e) => { e.preventDefault(); props.toggleRole(role); }}>
        {role.name}
        <span className='margin-left'>
          {label}
        </span>
      </li>
    );
  }

  return (
    <div>
      <CloseIfOutsideWrapper onClose={onClose}>
        <input
          className='search people nomargin-bottom'
          type='text'
          onChange={onRolesSearchChange}
          placeholder='Type a role name to search available roles'
          onFocus={() => { setActive(true); }}
        />
        <div className='margin-top'>{active && renderRoles()}</div>
      </CloseIfOutsideWrapper>
    </div>
  );
};

RoleSearch.propTypes = {
  toggleRole: PropTypes.func.isRequired,
  teamId: PropTypes.number.isRequired,
  userId: PropTypes.number,
  rolesToFilter: PropTypes.array,
  teamSlug: PropTypes.string.isRequired,
  referrer: PropTypes.shape({
    type: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default RoleSearch;
