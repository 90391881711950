import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AttachedPeople from 'components/shared/attached-people';
import SlidingToggle from 'components/shared/sliding-toggle';
import { confirm } from 'modules/alert-confirm';
import Notification from 'components/shared/notification';
import EmbeddedTextInput from 'components/shared/embedded-text-input';
import Select from 'components/shared/select';

import { some } from 'underscore';

const Role = (props) => {
  const [role, setRole] = useState(props.role);
  const [newRole, setNewRole] = useState(props.newRole);
  const [tab, setTab] = useState('settings');
  const [addToRole, setAddToRole] = useState(false);
  const [notification, setNotification] = useState({});

  function onSave() {
    if (role.member_mgmt_level === 'members' && !userIsOwnerOrWillBeAdded()) {
      confirm('Added to Role', 'Because this role\'s edit abilities is set to \'Role Members and & Owners\', you will be automatically added to this role to maintain your editing abilities.', () => {
        setAddToRole(true);
        sendSave();
      });
    } else {
      sendSave();
    }
  }

  // function onDelete () {
  //   props.onDelete(props.role.id)
  //       .fail((e) => {setError(e.responseJSON.errors)})
  // }

  function sendSave() {
    props.onSave(role, addToRole)
         .done((r) => {
           setRole(r);
           if (newRole) {
             setNewRole(false);
             setTab('users');
             setNotification({ type: 'success', message: 'Role created. Next, add some users.' });
           } else {
             setNotification({ type: 'success', message: 'Role updated' });
             props.onCancel();
           }
         });
  }

  function onRoleChange(change) {
    setNotification({});
    setRole({ ...role, ...change });
  }

  function userIsOwnerOrWillBeAdded() {
    return props.userIsOwner || addToRole;
  }

  function allowedToEdit() {
    switch (role.member_mgmt_level) {
    case 'anyone':
      return true;
    case 'members':
      return props.userIsOwner || userIsAMember();
    case 'owners':
      return props.userIsOwner;
    default:
      throw new Error(`Unhandled management level ${role.member_mgmt_level}`);
    }
  }

  function userIsAMember() {
    return some(role.users, (user) => { return user.id === CityGrows.Server.currentUserId; });
  }

  function renderNonOwnerPermissions() {
    const can = <span><i className='icon-check-green smaller' /> Can</span>;
    const cannot = <span><i className='icon-x-red smaller' /> Cannot</span>;
    const workflowCreatorPermission = role.workflow_creator ? can : cannot;
    const teamSettingsPermission = role.team_manager ? can : cannot;
    const financeManagerPermission = role.finance_manager ? can : cannot;
    const financeMemberPermission = role.finance_member ? can : cannot;
    return (
      <div>
        <label htmlFor='permissions-list'>Permissions (Only editable by team owners)</label>
        <ul id='permissions-list'>
          <li>
            {workflowCreatorPermission} create new team workflows
          </li>
          <li>
            {teamSettingsPermission} manage team settings
          </li>
          <li>
            {financeManagerPermission} manage team finances and banking details
          </li>
          {!role.finance_manager && (
            <li>
              {financeMemberPermission} view transaction history and manage charge disputes
            </li>
          )}
        </ul>
      </div>
    );
  }

  function renderPermissions() {
    if (props.userIsOwner) {
      return (
        <div className='role-section'>
          <label>
            Role permissions
            <div style={{ display: 'grid' }}>
              <SlidingToggle
                onToggle={() => { onRoleChange({ workflow_creator: !role.workflow_creator }); }}
                on={role.workflow_creator}
                label='Team Form Creator'
                description='When on, role members will be able to create new team forms.'
                className='margin-top margin-bottom'
              />
              <SlidingToggle
                onToggle={() => { onRoleChange({ team_manager: !role.team_manager }); }}
                on={role.team_manager}
                label='Team Settings Manager'
                description="When on, role members will be able to manage the team's settings, roles, and members."
                className='margin-top margin-bottom'
              />
              <SlidingToggle
                onToggle={() => { onRoleChange({ finance_manager: !role.finance_manager }); }}
                on={role.finance_manager}
                label='Team Finance Manager'
                description='When on, role members will be able to access and manage team finance settings.'
                className='margin-top margin-bottom'
              />
              <SlidingToggle
                onToggle={() => { onRoleChange({ finance_member: !role.finance_member }); }}
                on={role.finance_member}
                label='Team Finance Member'
                description="When on, role members will be able to access team's transaction history and manage charges disputes."
                className='margin-top margin-bottom'
              />
            </div>
          </label>

          <div className='role-instruction'>
            Note that only team owners can manage these settings
          </div>
        </div>
      );
    }

    return renderNonOwnerPermissions();
  }

  function userPermissionLevelSelect() {
    let membersDisabled = !(props.userIsOwner || userIsAMember());

    // If new role, then this isnt disabled, it just forces the user to be added to the role
    if (newRole) {
      membersDisabled = false;
    }

    const options = [
      { value: 'anyone', label: 'Team Members' },
      { value: 'members', label: 'Role Members & Team Owners', disabled: membersDisabled },
      { value: 'owners', label: 'Team Owners', disabled: !props.userIsOwner },
    ];

    return (
      <Select
        options={options}
        value={options.find(({ value }) => value === role.member_mgmt_level)}
        onChange={({ value }) => { onRoleChange({ member_mgmt_level: value }); }}
        styles={{
          container: (provided) => ({
            ...provided,
            display: 'inline-block',
            verticalAlign: 'middle',
          }),
        }}
      />
    );
  }

  function renderButtons() {
    const disabled = role.name.length === 0;
    return (
      <div className='role-section'>
        <button type='button' className='btn-primary margin-right' onClick={onSave} disabled={disabled}>Save</button>
        <button type='button' className='btn-secondary' onClick={props.onCancel}>Cancel</button>
      </div>
    );
  }

  function renderUsers() {
    let lockedUserIds = [];
    let additionalText = '';
    if (role.role_type === 'owner') {
      additionalText = '(must have at least one member)';
      lockedUserIds = role.users.length === 1 ? [role.users[0].id] : [];
    }

    return (
      <div className='role-section'>
        <label htmlFor='rolemembersinput'>Role Members {additionalText}</label>

        <AttachedPeople
          people={role.users}
          attachableId={role.id}
          attachableType='AdminRole'
          allowPseudo
          canAttachRoles={false}
          hideExternal
          lockedUserIds={lockedUserIds}
          disabled={!allowedToEdit()}
          pseudoNeedsRole={false}
          onChange={(res) => { props.onAttachedPeopleChange(res, role.id); }}
          idForInput='rolemembersinput'
        />

        <div className='role-instruction'>
          Only add users who need access to the role's permissions
          or who will be completing the actions assigned to the role
          <br />
          <br />
          <strong>
            Ex: On a reviewer role, add the people who will be
            completing the reviews that get assigned to that role
          </strong>
        </div>
      </div>
    );
  }

  function renderAddMeToRole() {
    return (
      <div className='role-section'>
        <label>
          <input type='checkbox' checked={addToRole} onChange={() => { setAddToRole(!addToRole); }} />
          Add me to the role
        </label>
      </div>
    );
  }

  function renderPermissionLevelSelect() {
    if (role.role_type !== 'custom') { return; }

    return (
      <div className='role-section'>
        Who can edit this role: {userPermissionLevelSelect()}
        <div className='role-instruction'>
          Options that would exclude you from being able to edit this role are disabled.
        </div>
      </div>
    );
  }

  function renderEditSettings() {
    return (
      <div>
        <div className='role-section'>
          <label className='inline-block'>Name
            <EmbeddedTextInput
              defaultValue={role.name}
              placeholder='Role name'
              onChange={(value) => { onRoleChange({ name: value }); }}
              idName='rolename'
            />
          </label>
          <div className='role-instruction'>
            Choose a name that your teammates will recognize.
            <br />
            <br />
            <strong>Ex: Finance manager, WTOP reviewer</strong>
          </div>
        </div>
        {renderPermissions()}
        {renderPermissionLevelSelect()}
        {newRole && renderAddMeToRole()}

        {renderButtons()}
      </div>
    );
  }

  function renderNotifications() {
    if (notification === {}) { return; }

    if (notification.type === 'success') {
      return <Notification notification={{ title: notification.message }} notificationType='success' onClose={() => { setNotification({}); }} />;
    }
  }

  const renderTabs = () => {
    return (
      <div className='tabnav'>
        <ul>
          <li className={tab === 'settings' ? 'selected' : ''}>
            <a
              href='#'
              onClick={(e) => { e.preventDefault(); setTab('settings'); }}
            >Settings
            </a>
          </li>
          <li className={tab === 'users' ? 'selected' : ''}>
            <a
              href='#'
              onClick={(e) => { e.preventDefault(); setTab('users'); }}
            >Users
            </a>
          </li>
        </ul>
      </div>
    );
  };

  const title = newRole ? 'Create a new role' : <span>Editing role: <strong>{role.name}</strong></span>;

  return (
    <div className='role-manager'>
      {renderNotifications()}
      <div className='margin-top margin-left-less'>
        <button className='btn-link' type='button' onClick={() => { props.onCancel(); }}>{'< Roles overview'}</button>
        <h2>{title}</h2>
      </div>

      <div className='role-editor raised bg-white'>
        {!newRole && renderTabs()}
        {tab === 'settings' && renderEditSettings()}
        {tab === 'users' && renderUsers()}
      </div>
    </div>
  );
};

Role.propTypes = {
  onAttachedPeopleChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  userIsOwner: PropTypes.bool.isRequired,
  role: PropTypes.object,
  newRole: PropTypes.object,
};

Role.defaultProps = {
  onAttachedPeopleChange: () => {},
  onCancel: () => {},
  onSave: () => {},
};

export default Role;
