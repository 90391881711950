export const create = (teamId, isESRI, serviceURL) => {
  const url = `/teams/${teamId}/custom_entities/`;
  return $.ajax(url, {
    dataType: 'json',
    method: 'POST',
    data: {
      custom_entity: {
        name: 'Untitled entity',
        esri_service_url: serviceURL,
      },
      is_esri: isESRI,
    }
  });
};

export const update = (customEntityId, attrs) => {
  const url = `/custom_entities/${customEntityId}`;

  return $.ajax(url, {
    dataType: 'json',
    method: 'PUT',
    data: {
      custom_entity: attrs,
    },
  });
};

export const destroy = (customEntityId) => {
  const url = `/custom_entities/${customEntityId}`;

  return $.ajax(url, {
    dataType: 'json',
    method: 'DELETE',
  });
};

export default {
  update,
  create,
  destroy,
};
