const StepDataAPI = {
  addSection(sectionableType, sectionableId) {
    const url = '/step_datum/sections';
    const request = $.ajax(url, {
      method: 'POST',
      dataType: 'json',
      data: {
        section: {
          sectionable_type: sectionableType,
          sectionable_id: sectionableId,
        },
      },
    });

    return request;
  },

  copySection(sectionId) {
    const url = `/step_datum/sections/${sectionId}/duplicate`;
    return $.ajax(url, {
      method: 'POST',
      dataType: 'json',
    });
  },

  updateSection(sectionId, attrs) {
    const url = `/step_datum/sections/${sectionId}`;
    const request = $.ajax(url, {
      method: 'PUT',
      dataType: 'json',
      data: {
        section: attrs,
      },
    });

    return request;
  },

  reorderSection(sectionId, position) {
    const url = `/step_datum/sections/${sectionId}/reorder`;
    return $.ajax(url, {
      method: 'PUT',
      dataType: 'json',
      data: {
        section: {
          position,
        },
      },
    });
  },

  destroySection(sectionId) {
    const url = `/step_datum/sections/${sectionId}`;
    const request = $.ajax(url, {
      method: 'DELETE',
      dataType: 'json',
    });

    return request;
  },

  add(sectionId, type) {
    const url = `/step_datum/sections/${sectionId}/step_data`;
    const data = type ? { type } : {};
    return $.ajax(url, {
      method: 'POST',
      dataType: 'json',
      data,
    });
  },

  duplicate(datumId) {
    const url = `/step_data/${datumId}/duplicate`;
    const request = $.ajax(url, {
      method: 'POST',
      dataType: 'json',
      data: {
      },
    });

    return request;
  },

  update(datumId, attrs) {
    const {
      name,
      date_type,
      data_type,
      required,
      position,
      redacted,
      section_id,
      entity_createable,
      lock_on_renewal,
      autofill_on_renewal,
      min_num_checks,
      max_length,
      min_value,
      max_value,
      date_range_start_label,
      date_range_end_label,
    } = attrs;

    const url = `/step_data/${datumId}`;
    const request = $.ajax(url, {
      method: 'PUT',
      dataType: 'json',
      data: {
        step_datum: {
          name,
          date_config_attributes: {
            date_type,
            range_end_label: date_range_end_label,
            range_start_label: date_range_start_label
          },
          data_type,
          lock_on_renewal,
          autofill_on_renewal,
          required,
          position,
          redacted,
          default: attrs.default,
          section_id,
          entity_config_attributes: {
            entity_createable,
          },
          min_num_checks,
          max_length,
          min_value,
          max_value,
        },
      },
    });

    return request;
  },

  reorder(datumId, position, sectionId) {
    const url = `/step_data/${datumId}/reorder`;
    return $.ajax(url, {
      method: 'PUT',
      dataType: 'json',
      data: {
        step_datum: {
          position,
          section_id: sectionId,
        },
      },
    });
  },

  fetch(stepDatumId) {
    const url = `/step_data/${stepDatumId}`;
    return $.ajax(url, {
      method: 'GET',
      dataType: 'json',
    });
  },

  destroy(stepDatumId) {
    const url = `/step_data/${stepDatumId}`;
    return $.ajax(url, {
      method: 'DELETE',
      dataType: 'json',
    });
  },

  availableForSectionConfig(sectionId) {
    const url = `/step_datum/sections/${sectionId}/step_data/available_for_section_config`;
    const request = $.ajax(url, {
      method: 'GET',
      dataType: 'json',
    });

    return request;
  },
};

export default StepDataAPI;
