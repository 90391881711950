import React from 'react';
import PropTypes from 'prop-types';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TemplateList = (props) => {
  const renderIcon = (template) => {
    if (template.icon === 'fa-file') {
      return <FontAwesomeIcon icon={icon({ name: 'file' })} size='4x' style={{ color: '#0463B7' }} />;
    }
    return (
      <img src={template.icon} width='60' height='60' alt='' />
    );
  };
  return (
    <ul className='flowtemplatebox-wrap'>
      {props.templateList.map((template) => {
        const selected = template.id === props.templateId;
        return (
          <li className='templatebox' onClick={() => { props.onTemplateChange(template); }}>
            <div className='container'>
              <div className='iconcontainer'>
                {renderIcon(template)}
              </div>
              {selected && (
                <div className='selectionbox'>
                  <label className='checkbox'>
                    <input value='true' type='checkbox' name='selected' checked={selected} />
                    <span />
                  </label>
                </div>
              )}
            </div>
            <div className='templatename'>
              <span>{template.name}</span>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

TemplateList.propTypes = {
  onTemplateChange: PropTypes.func.isRequired,
  templateId: PropTypes.number,
  templateList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })),
  onShowTemplateModal: PropTypes.func.isRequired,
};

export default TemplateList;
