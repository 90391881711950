import React, { useEffect, useContext } from 'react';
import PaymentStepActions from 'actions/payment-step-actions';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import Receipt from './receipt';

const Pending = (props) => {
  // const dispatch = useContext(InitiatedFlowDispatchContext);
  // useEffect(() => {
  //   const interval = setInterval(checkStatus, 20000);
  //
  //   return function cleanup() {
  //     clearInterval(interval)
  //   }
  // }, []);
  //
  // function checkStatus() {
  //   PaymentStepActions.checkPendingCharge(dispatch, props.charge.id, props.stepType);
  // }

  return (
    <Receipt
      charge={props.charge}
      activeStep={props.activeStep}
      admin={props.admin}
      key={props.charge.id}
    />
  );
};

export default Pending;
