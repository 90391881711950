
import Shield from '../modules/shield';

function show() {
  $('#launchbutton').click(() => {
    Shield.on();
    $('#popup').fadeIn();
  });
}

export default {
  show,
};
