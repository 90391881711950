import React from 'react';
import PropTypes from 'prop-types';

const CreateButton = ({
  customEntity, createNewEntity,
}) => {
  return (
    <div className='border-top padding-top text-center'>
      or <a className='secondary clickable' href='#' onClick={createNewEntity}>Create a new {customEntity.name}</a>
    </div>
  );
};

CreateButton.propTypes = {
  customEntity: PropTypes.shape({}).isRequired,
  createNewEntity: PropTypes.func,
};

CreateButton.defaultProps = {
  createNewEntity: () => {},
};

export default CreateButton;
