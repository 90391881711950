import React, { useContext } from 'react';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import RequirementActions from 'actions/requirement-actions';

const RequirementButtons = (props) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);

  function sendRequest(e) {
    e.preventDefault();
    RequirementActions.send(dispatch, props.requirementId, props.type);
  }

  function deleteButton() {
    if (props.requirementSent) { return; }

    return <a className='secondary clickable padding-right' href={`/supplemental_requirements/${props.requirementId}?type=${props.type}`} data-method='delete'>Delete request</a>;
  }

  function sendButton() {
    if (props.requirementSent) { return; }

    return <a className='btn-primary' href='#' onClick={sendRequest}>Send request</a>;
  }

  function cancelButton() {
    if (!props.requirementSent || props.requirementCompleted || props.requirementCanceled) { return; }

    return <a className='secondary clickable padding-right' href={`/supplemental_requirements/${props.requirementId}/cancel?type=${props.type}`} data-method='put'>Cancel request</a>;
  }

  return (
    <div className='fullwidth align-right'>
      {cancelButton()}
      {deleteButton()}
      {sendButton()}
    </div>
  );
};

export default RequirementButtons;
