import 'timeago';
import React from 'react';
import renderer from 'modules/renderer';
import LaunchManager from 'components/initiated_flow/launch-manager';
import InitiatedFlowManager from '../../components/initiated-flow-manager';
import OrgHeader from '../../components/shared/org-header';
import InitiatedFlowActions from '../../actions/initiated-flow-actions';
import CommonProviders from '../../components/common-providers';

const initStore = () => {
  if (CityGrows.Server.initiatedFlowData) {
    InitiatedFlowActions.receiveInitiatedFlow(CityGrows.Server.initiatedFlowData.initiated_flow);
    InitiatedFlowActions.receiveActiveStep(CityGrows.Server.initiatedFlowData.active_step);
    InitiatedFlowActions.receiveStep(CityGrows.Server.initiatedFlowData.step);
  }
};

const show = () => {
  initStore();

  renderer(
    (
      <CommonProviders>
        <OrgHeader
          currentUser={CityGrows.Server.currentUser}
          team={CityGrows.Server.initiatedFlowData.team}
        />
      </CommonProviders>
    ), document.getElementById('topnav'),
  );

  renderer(
    (
      <CommonProviders>
        <InitiatedFlowManager
          currentUser={{ id: CityGrows.Server.currentUserId, email: CityGrows.Server.currentUser.email }}
          admin={false}
          initiatedFlow={CityGrows.Server.initiatedFlowData.initiated_flow}
          activeStep={CityGrows.Server.initiatedFlowData.active_step}
          step={CityGrows.Server.initiatedFlowData.step}
          flowTemplate={CityGrows.Server.initiatedFlowData.flow_template}
          masterTemplate={CityGrows.Server.initiatedFlowData.master_template}
          showAdminWarning={CityGrows.Server.showAdminWarning}
          subprocessRequirement={CityGrows.Server.initiatedFlowData.subprocess_requirement}
          team={CityGrows.Server.initiatedFlowData.team}
        />
      </CommonProviders>
    ), document.getElementById('flowcontent'),
  );
};

const start = () => {
  renderer(
    (
      <CommonProviders>
        <LaunchManager
          subprocessRequirement={CityGrows.Server.initiatedFlowData.subprocess_requirement}
          flowTemplate={CityGrows.Server.initiatedFlowData.flow_template}
          currentUser={CityGrows.Server.initiatedFlowData.current_user}
          masterTemplateId={CityGrows.Server.initiatedFlowData.master_template.id}
          primaryApplicant={CityGrows.Server.initiatedFlowData.primary_applicant}
          secondaryApplicantIds={CityGrows.Server.initiatedFlowData.secondary_applicant_ids}
        />
      </CommonProviders>
    ), document.getElementById('flowcontent')
  );
};

export default {
  show,
  start,
};
