const MasterTemplateAPI = {
  checkSlugAvailable(masterTemplateId, slug){
    const url = '/workflows/check_slug_available';
    return $.ajax(url, {
      dataType: 'json',
      method: 'get',
      data: {
        slug: slug,
        master_template_id: masterTemplateId
      }
    });
  },

  update: function(id, attrs){
    const url = '/master_templates/' + id;

    return $.ajax(url, {
      method: 'PUT',
      dataType: 'json',
      data: {
        master_template: attrs
      }
    });
  },

  index: function(teamId){
    const url = `/teams/${teamId}/master_templates`;

    return $.ajax(url, {
      method: 'GET',
      dataType: 'json',
      data: {
        only_published: true
      }
    });
  },

  stepData: function(id){
    const url = `/master_templates/${id}/step_data`;

    return $.ajax(url, {
      method: 'GET',
      dataType: 'json'
    });
  },

  versions: function(id){
    const url = `/workflows/${id}/versions`;

    return $.ajax(url, {
      method: 'GET',
      dataType: 'json'
    });
  },

  modified: function(id){
    const url = `/workflows/${id}/modified`;

    return $.ajax(url, {
      method: 'GET',
      dataType: 'json'
    });
  }

};

export default MasterTemplateAPI;
