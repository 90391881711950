import API from 'apis/attachment-api';
import Constants from 'constants/attached-people-constants';
import InitiatedFlowConstants from 'constants/initiated-flow-constants';
import ActiveStepConstants from 'constants/active-step-constants';
import TentativeAssignmentsAPI from 'apis/tentative-assignments-api';
import RolesAPI from 'apis/role-api';
import { error } from 'components/shared/flash';
import Dispatcher from '../appdispatcher';

const AttachmentActions = {

  dispatchInitiatedFlowTogglePerson(dispatch, response) {
    dispatch({
      type: InitiatedFlowConstants.ASSIGNED_PEOPLE_UPDATED,
      personAttachment: response,
    });
  },

  dispatchTogglePersonEvent(attachableType, attachableId, response) {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_PERSON,
      attachedPerson: response,
      attachableId,
      attachableType,
    });
  },

  onAssignmentToggled(dispatch, personAttachment) {
    dispatch({
      type: InitiatedFlowConstants.ASSIGNMENT_UPDATED,
      personAttachment,
    });
  },

  onTentativeDone(dispatch) {
    dispatch({
      type: ActiveStepConstants.CHANGES_PERSISTED,
    });
  },

  addPseudomemberTentative(email, name, activeStepId) {
    return TentativeAssignmentsAPI.addPseudomember(email, name, activeStepId);
  },

  addPseudomember(email, name, attachableType, attachableId, opts) {
    return pseudoAdd(email, name, attachableType, attachableId, opts);
  },

  toggleTentativePerson(activeStepId, userId) {
    return TentativeAssignmentsAPI.toggleAssignment(activeStepId, userId);
  },

  togglePerson(attachableId, attachableType, userId, type) {
    return toggle(attachableType, attachableId, userId, type);
  },

  togglePersonViaAttachedRole(attachableId, attachableType, roleId, userId) {
    return toggle('AdminRole', roleId, userId, 'User')
           .done((response) => {
             Dispatcher.dispatch({
               actionType: Constants.UPDATE_ATTACHMENT,
               attachment: response,
               roleId,
               attachableId,
               attachableType,
             });
           });
  },

  removeTentativePerson(personAttachmentId) {
    return TentativeAssignmentsAPI.removeAssignment(personAttachmentId);
  },

  removePerson(personAttachmentId) {
    return API.removeAttachment(personAttachmentId);
  },

  toggleAssigned(personAttachment) {
    const toggledAttachment = { ...personAttachment, assigned: !personAttachment.assigned };
    return API.update(personAttachment.attachment_id, {
      assigned: toggledAttachment.assigned,
    });
  },

  fetchRoles(teamId, opts) {
    return RolesAPI.search(teamId, opts);
  },

  toggleRole(attachableId, attachableType, roleId, opts = { userToAdd: null }) {
    return toggle(attachableType, attachableId, roleId, 'AdminRole', { userToAdd: opts.userToAdd });
  },
};

function toggle(attachableType, attachableId, subjectId, subjectType, opts = {}) {
  return API.toggle(attachableType, attachableId, subjectId, subjectType, opts)
    .fail(() => { error(); });
}

function pseudoAdd(email, name, attachableType, attachableId, opts) {
  return API.addPseudomember(email, name, attachableType, attachableId, opts);
}

export default AttachmentActions;
