import React, { useState, useRef } from 'react';
import { debounce, isEmpty } from 'underscore';
import useOnclickOutside from 'react-cool-onclickoutside';
import Popdown from 'components/shared/popdown';
import TeamAPI from 'apis/team-api';
import Flash from 'components/shared/flash';

const ProcessSearch = props => {
  const [open, setOpen] = useState(false);
  const [results, setResults] = useState([]);
  const closeResults = () => { setOpen(false); }
  const elForAnchor = useRef();

  useOnclickOutside(
    closeResults,
    { refs: [elForAnchor], ignoreClass: 'popdown' }
  );

  const onQueryType = debounce(
    (target) => {
      const { value } = target;

      if (isEmpty(value)) {
        setResults([]);
        setOpen(false);
        return;
      }

      TeamAPI.processListing(props.team.friendly_id, value)
        .done((res) => {
          setResults(res.flow_templates);
          setOpen(true);
        })
        .fail(() => { Flash.error(); })
    },
    333
  );

  const renderProcess = (p) => {
    return (
      <li key={p.id}>
        <a href={p.public_path} style={{ width: '100%' }}>
          {p.name}
        </a>
      </li>
    )
  };

  const renderProcesses = (items) => {
    if (items.length) {
      return items.map(renderProcess);
    } else {
      return 'No results';
    }
  }

  return (
    <>
      <input
        ref={elForAnchor}
        id='form-search'
        onClick={() => { setOpen(true && !isEmpty(results)); }}
        onKeyUp={(e) => { onQueryType(e.currentTarget); }}
        placeholder="Ex: Building permit..."
        type="text"
        style={{ marginRight: '0px' }}
      />
      {open && (
          <Popdown anchorRef={elForAnchor}
            justify='left'
            topOffset={1}
            scrollAgainstWindow
            stretchAgainstAnchor
          >
            <ul className='public-portal-search-list'>
              {renderProcesses(results)}
            </ul>
          </Popdown>
        )
      }
    </>
  );
};

export default ProcessSearch;
