import React, { useState, useEffect} from 'react';
import DonutChart from '../shared/chart/donut';
import BarChart from 'components/shared/chart/bar';
import COLORS from 'constants/chart-colors';
import NoDataPlaceholder from '../shared/no-data-placeholder';

const SecondRow = ({formData}) => {
  const [fLabels, setFLabels] = useState([]);
  const [fValues, setFValues] = useState([]);
  const [oLabels, setOLabels] = useState([]);
  const [oValues, setOValues] = useState([]);

  useEffect(() => {
    const [fData, fLabels] = parseData(formData.forms_chart_data)
    const [oData, oLabels] = parseData(formData.outputs_chart_data)

    setFLabels(fData);
    setFValues(fLabels);
    setOLabels(oData);
    setOValues(oLabels);
  }, [])


  function parseData(data) {
    let lbls = [];
    let vls = [];
    let otherTotal = 0;

    const topFive = data.slice(0,5);
    const other = data.slice(5);

    topFive.map((datum) => {
      lbls = [...lbls, ...Object.keys(datum)];
      vls = [...vls, ...Object.values(datum)];
    })

    other.map((datum) => {
      const num = Object.values(datum)[0];
      otherTotal += num;
    });

    lbls = [...lbls, 'Other'];
    vls = [...vls, otherTotal];

    return [lbls, vls]
  }

  const colors = COLORS.slice(0,6);

  function percentage(numerator) {
    const percentage = (numerator / formData.launched_count) * 100;
    return Math.round(percentage);
  }

  function renderLegend() {
    return fLabels.map((label, index) => {
      return (
        <li>
          <span className='dot' style={{backgroundColor: colors[index]}}/>
          <span>{label}</span>
          <span className='percentage'><strong>{`${fValues[index]}`}</strong>{` (${percentage(fValues[index])}%)`}</span>
        </li>
      );
    });
  }

  function renderFormSubmissionChart() {
    if (fValues.length === 0) { return null; }

    return (
      <div className='chart'>
        <h2 className='align-center'>Submissions Breakdown</h2>
        {renderChart()}
      </div>
    )
  }

  function renderChart() {
    if (fValues.length === 1) { 
      return <NoDataPlaceholder type='donut' />}

    const data = {
      fLabels,
      datasets: [{
        data: fValues,
        backgroundColor: colors,
      }],
    }

    return (
      <div className='flex'>
        <div className='piechart'>
          <DonutChart data={data} options={{plugins: {legend: {display: false}}}} />
        </div>
        <div className='pielegend'>
          <ul>
            {renderLegend()}
          </ul>
        </div>
      </div>
    )
  }

  function renderOutputChart() {
    if ( oValues.length === 0) { return null;}

    const dta = {
      labels: oLabels.slice(0,5),
      datasets: [{
        indexAxis: 'y',
        data: oValues.slice(0,5),
        backgroundColor: colors.slice(0,5)
      }]
    }
    return (
      <div className='chart'>
        <h2 className='align-center'>Top 5 Issued Outputs by Type</h2>
        <div className='barchart'>
          <BarChart data={dta} 
          options={{
            plugins: {
              legend: { display: false },
            },}}/>
        </div>
      </div>
    )

  }

  return (
    <div className='metricsdashboard-secondrow'>
      {renderFormSubmissionChart()}
      {renderOutputChart()}
    </div>
  )
}

export default SecondRow;
