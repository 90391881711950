export const create = (stepAssignmentGroupId, assigneeId, assigneeType) => {
  const url = `/step_assignment_groups/${stepAssignmentGroupId}/step_assignments`;
  return $.ajax(url, {
    dataType: 'json',
    method: 'POST',
    data: {
      step_assignment: {
        assignee_id: assigneeId,
        assignee_type: assigneeType,
      },
    },
  });
};

export const destroy = (stepAssignmentId) => {
  const url = `/step_assignments/${stepAssignmentId}`;
  return $.ajax(url, {
    dataType: 'json',
    method: 'DELETE',
  });
};

export default {
  create,
  destroy,
};
