import AlertConfirm from 'modules/alert-confirm';
import SignaturePrompt from 'modules/signature-prompt';


class AdvancementHandler {
  constructor(isAdmin, step, currentStepId, activeStep){
    this.isAdmin = isAdmin;
    this.step = step;
    this.currentStepId = currentStepId;
    this.activeStep = activeStep;

    this.promise = $.Deferred();
  }

  attemptAdvance(){
    this.isAdmin ?  this.attemptAdminAdvance() :
                    this.attemptConstituentAdvance();

    return this.promise;
  }

  attemptAdminAdvance(){
    if(this.shouldShowNoPaymentWarning()){
      AlertConfirm.confirm('Continue without payment?', 'No payment has been made. Are you sure you want to continue?', () => {
        this.resolveAdvance();
      });
    } else if(this.requiresSignature()) {
      AlertConfirm.confirm('Skip signature?', 'This step asks for a signature from the applicant. If you continue, the applicant will not be prompted to sign.', () => {
        this.resolveAdvance();
      });
    } else {
      this.resolveAdvance();
    }
  }

  attemptConstituentAdvance(){
    if(this.shouldShowCantModifyWarning()){
      AlertConfirm.confirm('This info cannot be modified', 'This information cannot be modified after it has been submitted. Are you sure you want to continue?', () => {
        if(this.requiresSignature()){
          this.requestSignature();
        } else {
          this.resolveAdvance();
        }
      });
    } else if(this.requiresSignature()){
      this.requestSignature();
    } else if(this.shouldShowNoPaymentWarning()){
      AlertConfirm.confirm('Continue without payment?', 'No payment has been made. Are you sure you want to continue?', () => {
        this.resolveAdvance();
      });
    } else {
      this.resolveAdvance();
    }
  }

  resolveAdvance(){
    this.promise.resolve(true);
  }

  shouldShowCantModifyWarning = () => {
    return (this.step.type === 'CollectInfoStep') &&
             !this.step.revisable &&
             this.currentStepId === this.step.id;
  }

  requiresSignature = () => {

    // When you make a step internal, the
    // settings for signatures go away.

    return  this.step.requires_signature &&
              this.activeStep.needs_signature &&
              this.step.external;
  }

  requestSignature = () => {
    SignaturePrompt.requestSignature(this.activeStep.id)
      .done((sig) => {
        if(sig){
          this.resolveAdvance();
        }
      });
  }

  shouldShowNoPaymentWarning = () => {
    return (this.step.type === 'PaymentStep')
            && !this.activeStep.paid
            && this.currentStepId === this.step.id;
  };
}

export default AdvancementHandler;
