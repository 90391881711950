import React from 'react';
import PropTypes from 'prop-types';
import SearchResult from './search-result';

const SearchResults = ({
  searchResults,
  customEntity,
  onSelectEntityClick,
  searchTerm,
}) => {
  if (!searchResults) {
    return (
      <div className='padding-top-less padding-bottom-less color-alert'>Sorry, no {customEntity.name} matches.</div>
    );
  }

  return (
    <ul className='entityinput-searchresults padding-top'>
      {searchResults.map((result) => {
        return (
          <SearchResult
            result={result}
            resultType={customEntity.type}
            onSelectEntityClick={onSelectEntityClick}
            searchTerm={searchTerm}
            isEsri={customEntity.type === 'EsriCustomEntity'}
          />
        );
      })}
    </ul>
  );
};

SearchResults.propTypes = {
  searchResults: PropTypes.arrayOf(PropTypes.shape({})),
  customEntity: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  onSelectEntityClick: PropTypes.func,
  searchTerm: PropTypes.string,
};

SearchResults.defaultProps = {
  onSelectEntityClick: () => {},
};

export default SearchResults;
