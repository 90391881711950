const ChargeAPI = {
  get(id) {
    return $.ajax(`/charges/${id}`, {
      method: 'GET',
      dataType: 'JSON'
    });
  },

  create(paymentId, data) {
    return $.ajax(`/charges`, {
      method: 'POST',
      dataType: 'JSON',
      data: {
        cc: {
          first: data.first,
          last: data.last,
          number: data.number,
          month: data.month,
          year: data.year,
          verification: data.verification,
        },
        amount: data.amount,
        payment_id: paymentId,
      }
    })
  }
};

export default ChargeAPI;
