import AuthoritiesAPI from 'apis/master-template-authorities-api';
import UserAPI from '../apis/user-api';
import MembershipAPI from '../apis/membership-api';
import { success, error } from '../components/shared/flash';
import UserAccessConstants from '../constants/user-access-constants';

const UserAccessActions = {
  fetchAll(flowTemplateId, dispatch) {
    return MembershipAPI.index(flowTemplateId)
                        .done((res) => { dispatchMembershipList(res.memberships, dispatch); });
  },

  searchUsers(terms, opts) {
    return UserAPI.search(terms, opts);
  },

  addUser(userId, flowTemplateId, dispatch) {
    return MembershipAPI.create(userId, flowTemplateId)
                        .done((membership) => { dispatchMembershipAdd(membership, dispatch); })
                        .fail(() => error('There was a problem adding that user. Please try again later.'));
  },

  toggleAuthorityOn: (userId, flowTemplateId, dispatch) => {
    return AuthoritiesAPI.create(userId, flowTemplateId)
                         .done((membership) => dispatchAuthorityAdd(membership, dispatch));
  },

  toggleAuthorityOff: (authorityId, dispatch) => {
    return AuthoritiesAPI.destroy(authorityId)
                         .done(() => { dispatchAuthorityRemove(authorityId, dispatch); });
  },

  removeUser: (membershipId, dispatch) => {
    return MembershipAPI.destroy(membershipId)
            .done(() => dispatchMembershipRemove(membershipId, dispatch))
            .fail((res) => {
              error(res.responseJSON.errors.base[0]);
            });
  },

  updateUserPermission(membershipId, newPermissionId, dispatch) {
    onPermissionUpdated(membershipId, newPermissionId, dispatch);
    MembershipAPI.update(membershipId, {
      permission_level_id: newPermissionId,
    }).fail((r) => onPermissionUpdateFailed(r, dispatch))
      .done(() => success('Permission level updated'));
  },

  sendInvite(email, name, flowTemplateId, dispatch) {
    MembershipAPI.sendInvite(flowTemplateId, name, email)
      .done((res) => {
        dispatchMembershipAdd(res, dispatch);
      });
  },

  resendInvite(invitationId) {
    MembershipAPI.resendInvite(invitationId)
      .done(() => {
        success('Reminder sent');
      })
      .error(() => {
        error();
      });
  },

};

function dispatchMembershipRemove(membershipId, dispatch) {
  dispatch({
    type: UserAccessConstants.MEMBERSHIP_REMOVED,
    membershipId,
  });
}

function dispatchMembershipAdd(membership, dispatch) {
  dispatch({
    type: UserAccessConstants.MEMBERSHIP_ADDED,
    membership,
  });
}

function dispatchMembershipList(memberships, dispatch) {
  dispatch({
    type: UserAccessConstants.MEMBERSHIP_LIST,
    memberships,
  });
}

function dispatchAuthorityAdd(membership, dispatch) {
  dispatch({
    type: UserAccessConstants.AUTHORITY_ADDED,
    membership,
  });
}

function dispatchAuthorityRemove(authorityId, dispatch) {
  dispatch({
    type: UserAccessConstants.AUTHORITY_REMOVED,
    authorityId,
  });
}

function onPermissionUpdated(membershipId, permissionLevelId, dispatch) {
  dispatch({
    type: UserAccessConstants.PERMISSION_CHANGED,
    membershipId,
    permissionLevelId,
  });
}

function onPermissionUpdateFailed(res, dispatch) {
  const membership = res.responseJSON;

  dispatch({
    type: UserAccessConstants.PERMISSION_CHANGED,
    membershipId: membership.id,
    permissionLevelId: membership.permission_level_id_was,
  });

  error(membership.errors ? membership.errors[0] : null);
}

export default UserAccessActions;
