import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import resolveTags from 'modules/tag-resolver';

const InformationalText = ({
  classes,
  text,
  textType,
  initiatedFlow,
  textContainerId,
}) => {
  const [textState, setTextState] = useState(null);

  useEffect(() => {
    resolveTags(text, textType, textContainerId, initiatedFlow)
      .then((t) => { setTextState(t) });
  }, []);
  
  if (text) {
    return (
      <div className={`informationaltext simplerich ${classes}`} dangerouslySetInnerHTML={{ __html: textState }} />
    );
  }

  return (
    <div className={`${classes} currentstep-loading`}>
      <div className='progress small'>
        <div />
      </div>
    </div>
  );
};

InformationalText.propTypes = {
  classes: PropTypes.string,
  text: PropTypes.string,
  initiatedFlow: PropTypes.shape({}).isRequired,
  textContainerId: PropTypes.string.isRequired,
};

export default InformationalText;
