function valid(step) {
  const noErrors = errors(step).length === 0;

  if (step.type === 'CollectInfoStep') {
    return noErrors;
  }
  return noErrors;
}

function errors(step) {
  return step.problems;
}

const ActiveStepValidator = {
  valid(step) {
    return valid(step);
  },

  errors(step) {
    return errors(step);
  },
};

export default ActiveStepValidator;
