import React, { useRef, useEffect } from 'react';
import {
  Chart, DoughnutController, CategoryScale, LinearScale, Legend, Tooltip, ArcElement,
} from 'chart.js';

Chart.register(DoughnutController, CategoryScale, LinearScale, Legend, Tooltip, ArcElement);

const DonutChart = (props) => {
  const chart = useRef(null);

  useEffect(() => {
    const options = { responsive: true, ...props.options };
    new Chart(chart.current, {
      type: 'doughnut',
      data: props.data,
      options,
    });
  }, []);

  return (
    <canvas ref={chart} id='myChart' />
  );
};

export default DonutChart;
