import PropTypes from 'prop-types';
import React from 'react';
import TemplateManagerActions from 'actions/template-manager-actions';
import { CSSTransition } from 'react-transition-group';
import ActionButton from 'components/shared/action-button';

class UnpublishedChangesBanner extends React.Component {
  onPublishClick = () => {
    if (!this.props.show) return; // prevents clicks during transition

    return TemplateManagerActions.publish(this.props.flowTemplate.id)
                                 .done(this.props.onPublish);
  };

  renderPublishButton() {
    if (!this.props.disabled) {
      return (
        <div className='flowtemplatebanner-right'>
          <ActionButton onClick={this.onPublishClick} className='btn-primary' htmlID='publishchangesbutton'>
            Publish changes
          </ActionButton>
        </div>
      );
    }
  }

  renderBannerContents() {
    return (
      <div className='flowtemplatebanner cf'>
        <div className='flowtemplatebanner-left'>
          <p>This form has changes that are not yet live.</p>
          <p className='small'>Newly published changes will not apply to submissions that are already in progress.</p>
        </div>

        {this.renderPublishButton()}
      </div>
    );
  }

  render() {
    const { show } = this.props;
    return (
      <CSSTransition
        classNames='flowtemplatebanner'
        timeout={330}
        in={show}
        mountOnEnter
        unmountOnExit
      >
        {this.renderBannerContents()}
      </CSSTransition>
    );
  }
}

UnpublishedChangesBanner.propTypes = {
  show: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  onPublish: PropTypes.func.isRequired,
  flowTemplate: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};
UnpublishedChangesBanner.defaultProps = {
  show: false,
};

export default UnpublishedChangesBanner;
