import React from 'react';
import DonutSvg from '/app/assets/images/personnel-costs-disabled-chart.svg';
import VChartSvg from '/app/assets/images/funds-disabled-chart.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const NoDataPlaceholder = (props) => {
  function renderImg() {
    switch(props.type) {
      case 'donut':
        return <img src={DonutSvg} />
      case 'vertical-chart':
        return <img src={VChartSvg} />
    }
  }

  return (
    <div>
      {renderImg()}
      <div class="flexcolumn align-center text-semialert padding-top-less">
        <FontAwesomeIcon icon={solid("circle-exclamation")} size='xl' style={{color: "#fb8e28",}} />
        <div className='italic bold'>No data available yet</div>
      </div>
    </div>
  );
}

export default NoDataPlaceholder;