import React from "react";
import { contains } from "underscore";

import {
  COMPLETION_STATUSES,
  DATE_TERMS,
  IMPORTED_OPTIONS,
  OUTPUT_STATUSES,
  RENEWAL_STATUSES,
  WORKFLOW_STATUSES
} from "./constants";
import DashboardStore from 'stores/dashboard-store';
import SlidingToggle from "../sliding-toggle";
import UserDisc from 'components/shared/user-disc';


const MinifiedFilterUnit = ({
  filterType,
  filterState,
  formTemplates,
  tags,
  stepDataForSearch,
}) => {
  switch (filterType) {
    case 'applicantSearch': {
      const applicant = filterState.options.applicant;

      if (!applicant) { return null; }

      return <UserDisc
        user={applicant}
        className='margin-right'
        options={{
          showName: true,
        }}
      />;
    }

    case 'assignedToggle':
    case 'outputRenewalsEnabled': {
      const toggledOn = filterState.value === 'true' || filterState.value === true;

      return (
        <SlidingToggle
          on={toggledOn}
          disabled
        />
      );
    }

    case 'completedDate':
    case 'launchedAt':
    case 'outputIssued': {
      const [from, to] = filterState.value;
      return (
        <>
          <span>{from || '...'}</span>
          <span className="margin-left margin-right">to</span>
          <span>{to || '...'}</span>
        </>
      );
    }

    case 'currentStepTime': {
      const defaultTerm = DATE_TERMS.find((opt) => opt.id == filterState.options.term);
      return <span>{`${filterState.value} ${defaultTerm.label}`}</span>
    }

    case 'importedSelect': {
      const importedSelect = IMPORTED_OPTIONS.find((opt) => opt.id == filterState.value);
      return <span>{importedSelect.label}</span>
    }

    case 'currentStep': {
      const currentStepOptions = stepDataForSearch ? stepDataForSearch.steps : DashboardStore.getStepData().steps;
      const currentStep = currentStepOptions.find(opt => opt.id == Number(filterState.value));

      return <span>{currentStep?.name}</span>
    }

    case 'tagSelect': {
      const tagOptions = tags || [];
      const tagNumberIds = filterState.value.map((id) => Number(id));
      const defaultSelectedTagsValue = tagOptions.filter((tag) => contains(tagNumberIds, tag.id));
      const tagsContent = defaultSelectedTagsValue.map(tag => tag.name).join(', ');
      return <span>{tagsContent}</span>
    }

    case 'completedStatus': {
      const completedStatus = COMPLETION_STATUSES.find((opt) => opt.id == filterState.value);
      return <span>{completedStatus.label}</span>
    }

    case 'outputStatus': {
      const outputStatus = OUTPUT_STATUSES.find((opt) => opt.id == filterState.value);
      return <span>{outputStatus.label}</span>
    }

    case 'renewalStatus': {
      const renewalStatus = RENEWAL_STATUSES.find((opt) => opt.id == filterState.value);
      return <span>{renewalStatus.label}</span>
    }

    case 'submission': {
      const submissionText = filterState.value ? `#${filterState.value}` : null;
      return <span>{submissionText}</span>
    }

    case 'teamSelect': {
      const teams = CityGrows.Server.teams;

      const teamNumberIds = filterState.value.map((id) => Number(id));
      const defaultSelectedTeamsValue = teams.filter((team) => contains(teamNumberIds, team.id));

      const teamsContent = defaultSelectedTeamsValue.map(team => team.name).join(', ');
      return <span>{teamsContent}</span>
    }

    case 'formSelect': {
      const defaultStatusValue = WORKFLOW_STATUSES.find((opt) => opt.id == filterState.options.status);

      const formNumberIds = filterState.value ? filterState.value.map((id) => Number(id)) : [];
      const defaultSelectedFormsValue = formTemplates.filter((form) => contains(formNumberIds, form.master_template_id));

      const content = defaultSelectedFormsValue.length
        ? defaultSelectedFormsValue.map(form => form.name_or_default).join(', ')
        : defaultStatusValue.label

      return <span>{content}</span>
    }

    default:
      return null;
  }
};

export default MinifiedFilterUnit;
