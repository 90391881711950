import 'select2';
import React from 'react';
import renderer from 'modules/renderer';
import PrimaryTabs from '../components/shared/primary-tabs';
import FormsContainer from '../components/forms-container';
import CommonProviders from '../components/common-providers';

const index = () => {
  $('select').select2({
    minimumResultsForSearch: -1,
    width: 'style',
  });

  $('#templateselect').change((e) => {
    document.location = `?status=${e.currentTarget.value}`;
  });

  renderer(<PrimaryTabs />, document.getElementById('tabs'));
  renderer(
    (
      <CommonProviders>
        <FormsContainer
          teams={CityGrows.Server.teams}
          archivedMode={CityGrows.Server.archivedMode}
          starredFormIds={CityGrows.Server.starredFormIds}
          teamlessForms={CityGrows.Server.teamlessForms}
        />
      </CommonProviders>
    ), document.getElementById('formscontainer'),
  );
};

export default {
  index,
};
