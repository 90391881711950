import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ExpandableContent = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = (e) => {
    e.preventDefault();

    setIsOpen(!isOpen);
  };

  return (
    <div className={props.className}>
      <a
        className='secondary margin-right-less'
        href='#'
        onClick={onClick}
      >{props.linkText}
      </a>
      <a
        className={`clickable stepbreakdown-expand icon icon-arrow-down small thin ${isOpen ? 'flip-y' : ''}`}
        onClick={onClick}
        href='#'
      />

      <div className='margin-top-less'>
        {isOpen && props.children}
      </div>
    </div>
  );
};

ExpandableContent.propTypes = {
  className: PropTypes.string,
};

ExpandableContent.defaultProps = {
  className: 'margin-bottom',
};

export default ExpandableContent;
