import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  default: '',
  alert: 'flowbanner-alert',
  warning: 'flowbanner-warning',
  notice: 'flowbanner-notice',
};

const NotifBanner = (props) => {
  const styleClass = () => {
    return styles[props.style || 'default'];
  };

  return (
    <div className={`flowbanner ${styleClass()} ${props.className ? props.className : ''}`}>
      {props.children}
    </div>
  );
};

NotifBanner.propTypes = {
  className: PropTypes.string,
};

export default NotifBanner;
