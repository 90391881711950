import FlowConstants from '../constants/initiated-flow-constants';
import CollectedDataAPI from '../apis/collected-data-api';
import ActiveStepConstants from '../constants/active-step-constants';
import { error } from '../components/shared/flash';
import jsonResponseMessage from '../modules/json-response-message';

const CollectInfoStepActions = {
  dataChanged(dispatch, stepDatumId, initiatedFlowId, newValue, version) {
    dispatch({
      type: FlowConstants.COLLECTED_DATA_CHANGED,
      stepDatumId,
      newValue,
    });

    this.sync(dispatch, stepDatumId, initiatedFlowId, newValue, version);
  },

  sync(dispatch, stepDatumId, initiatedFlowId, newValue, version) {
    const ajaxRequest = CollectedDataAPI.update(initiatedFlowId, stepDatumId, newValue, version);
    ajaxRequest.done(() => {
      dispatch({
        type: ActiveStepConstants.CHANGES_PERSISTED,
      });
    });

    ajaxRequest.fail((response) => {
      dispatch({
        type: FlowConstants.COLLECTED_DATA_ERROR,
        stepDatumId,
      });

      const message = jsonResponseMessage(response);

      error(message);
    });
  },

  dispatchDataWillChange(dispatch) {
    dispatch({
      type: FlowConstants.COLLECTED_DATA_WILL_CHANGE,
    });
  },

  dispatchFileDeleted(dispatch, stepDatumId, fileId) {
    dispatch({
      type: FlowConstants.DELETE_ATTACHED_FILE,
      stepDatumId,
      fileId,
    });
  },

  deleteAttachedFile(fileId) {
    return CollectedDataAPI.deleteAttachedFile(fileId)
      .fail((response) => {
        const message = jsonResponseMessage(response);

        error(message);
      });
  },
};

export default CollectInfoStepActions;
