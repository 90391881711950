import {
  extend,
  contains,
  isEmpty,
  chain,
} from 'underscore';
import { success as flashSuccess, error as flashError } from 'components/shared/flash';
import Dispatcher from '../appdispatcher';
import DashboardConstants from '../constants/dashboard-constants';
import { alert } from '../modules/alert-confirm';
import BatchInitiatedFlowsAPI from '../apis/batch-initiated-flows-api';
import DashboardAPI from '../apis/dashboard-api';

let _mostRecentQueryParams = null;
let _pagesRequested = [];
const _pageSize = 20;
let _mostRecentRequest;
let _loadingNewPage = false;

const filteredSearch = (params) => {
  _pagesRequested = [1];

  if (!params) {
    params = (extend({}, _mostRecentQueryParams));
  } else {
    _mostRecentQueryParams = params;
  }

  setLoading();

  _mostRecentRequest = DashboardAPI.fetch(params)
    .done((res, textStatus, jqXHR) => {
      if (_mostRecentRequest !== jqXHR) { return; }
      Dispatcher.dispatch({
        actionType: DashboardConstants.ITEMS_LOADED,
        items: res.initiated_flows,
        total_count: res.total_count,
      });
    })
    .fail(onLoadFailed);

  return _mostRecentRequest;
};

// Exports flows for the most recent query. Will not work
// until the dashboard content has been fetched at least once.
//
const filteredExport = () => {
  return DashboardAPI.export(_mostRecentQueryParams).fail(() => { flashError(); });
};

const exportSpecific = (items) => {
  return BatchInitiatedFlowsAPI.export(items).fail(() => { flashError(); });
};

const nullCellRequested = (index) => {
  const page = Math.floor(index / _pageSize) + 1;
  if (_loadingNewPage || contains(_pagesRequested, page)) {
    return;
  }

  _pagesRequested.push(page);

  return loadMoreItems(page);
};

const loadMoreItems = (page) => {
  const requestParams = extend({}, _mostRecentQueryParams, { page, include_count: false });
  _loadingNewPage = true;
  return DashboardAPI.fetch(requestParams)
                     .done((res) => {
                       Dispatcher.dispatch({
                         actionType: DashboardConstants.MORE_ITEMS_LOADED,
                         items: res.initiated_flows,
                         index: (page - 1) * _pageSize,
                       });
                     })
                     .fail(onLoadFailed)
                     .always(() => {
                       _loadingNewPage = false;
                     });
};

const onLoadFailed = () => {
  alert('Can\'t load submissions', 'We\'re having trouble loading your submissions. Please try again in a few moments.');

  Dispatcher.dispatch({
    actionType: DashboardConstants.LOAD_FAILED,
  });
};

const setLoading = () => {
  Dispatcher.dispatch({
    actionType: DashboardConstants.ITEMS_LOADING,
  });
};

const loadStepData = (flowTemplateId) => {
  const url = `/flow_templates/${flowTemplateId}/step_data_for_search`;
  const request = window.$.ajax(url, {
    dataType: 'json',
    method: 'GET',
  });

  return request.done((res) => {
    Dispatcher.dispatch({
      actionType: DashboardConstants.STEP_DATA_LOADED,
      stepData: res,
    });
  });
};

const selectInitiatedFlowDashboardIds = (items) => {
  return chain(items).where({ type: 'InitiatedFlow' })
              .map((f) => { return f.id; })
              .value();
};

const assignFlows = (items) => {
  const dashboardIds = selectInitiatedFlowDashboardIds(items);

  return BatchInitiatedFlowsAPI.assign(dashboardIds);
};

const unassignFlows = (items) => {
  const dashboardIds = selectInitiatedFlowDashboardIds(items);

  return BatchInitiatedFlowsAPI.unassign(dashboardIds);
};

const haltFlows = (items) => {
  const dashboardIds = selectInitiatedFlowDashboardIds(items);

  BatchInitiatedFlowsAPI.halt(dashboardIds)
    .done(onSuccessfulHalt.bind(null, dashboardIds))
    .fail(onFailedHalt);
};

const deleteFlows = (items) => {
  BatchInitiatedFlowsAPI.delete(items)
    .done(onSuccessfulDelete.bind(null, items))
    .fail(onFailedDelete);
};

// Deletes everything that matches the previous search query
// As a safety check, confirm count must match the number
// of returned results

const deleteMatchingFlows = (confirmCount) => {
  DashboardAPI.delete(_mostRecentQueryParams, confirmCount)
              .done(() => { flashSuccess(`${confirmCount} submissions are being deleted. This may take some time.`); })
              .fail(() => { flashError(); });
};

const onFailedHalt = () => {
  alert('Something went wrong', 'We weren\'t able to halt those submissions. Please try again later, or if the issue persists, contact support using the chat icon the in the lower right hand corner of the screen.');
};

const onFailedDelete = () => {
  alert('Something went wrong', 'We weren\'t able to delete those submissions. Please try again later, or if the issue persists, contact support using the chat icon the in the lower right hand corner of the screen.');
};

const onSuccessfulHalt = (initiatedFlowIds) => {
  const count = initiatedFlowIds.length;
  flashSuccess(`${count} submissions were halted successfully.`);

  filteredSearch();
};

const onSuccessfulDelete = (initiatedFlowIds) => {
  const count = initiatedFlowIds.length;
  flashSuccess(`${count} submissions were deleted successfully.`);

  filteredSearch();
};

const runSearch = (terms, type) => {
  const URL = `/dashboard/search/${terms}`;

  const request = window.$.ajax(URL, {
    method: 'GET',
    dataType: 'json',
    data: {
      search_type: type,
    },
  })
  .fail((req) => {
    if (req.statusText === 'abort') { return; }
    flashError();
  });

  return request;
};

export default {
  filteredSearch,
  filteredExport,
  exportSpecific,
  nullCellRequested,
  loadStepData,
  assignFlows,
  unassignFlows,
  haltFlows,
  deleteFlows,
  deleteMatchingFlows,
  runSearch,
};
