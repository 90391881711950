import React, { Component } from 'react';
import FlowTemplateAPI from 'apis/flow-template-api';
import _ from 'underscore';
import Flash from 'components/shared/flash';
import NProgress from 'nprogress';

class OverviewDescriptionEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      description: this.props.description,
    };
  }

  renderEditView() {
    return (
      <div className='cf'>
        <textarea ref='description' defaultValue={this.state.description} />
        <a href='#' onClick={this.onSaveClick.bind(this)} className='float-right btn-secondary'>Save description</a>
      </div>
    );
  }

  onSaveClick(e) {
    e.preventDefault();

    NProgress.start();

    const newDescription = this.refs.description.value;

    FlowTemplateAPI.update(this.props.flowTemplateId, {
      description: newDescription,
    })
		.done(() => {
		  this.setState({
		    editing: false,
		    description: newDescription,
		  }, () => {
		    if (_.isFunction(this.props.onChange)) { this.props.onChange(newDescription); }
		  });
		})
		.fail(() => { Flash.error(); })
		.always(() => { NProgress.done(); });
  }

  onEditClick(e) {
    e.preventDefault();

    this.setState({
      editing: true,
    }, () => {
      this.refs.description.select();
    });
  }

  editLinkText() {
    const desc = this.state.description;
    if (!desc || desc.length === 0) {
      return 'Add description';
    }
    return 'Edit';
  }

  renderEditDescriptionButton = () => {
    if (this.props.admin) {
      return (
        <a id='publicoverview-desceditlink' href='#' onClick={this.onEditClick.bind(this)}>{this.editLinkText()}</a>
      );
    }
  };

  renderDescription() {
    return (
      <p>{this.state.description} {this.renderEditDescriptionButton()}</p>
    );
  }

  render() {
    return this.state.editing ? this.renderEditView() : this.renderDescription();
  }
}

export default OverviewDescriptionEditor;
