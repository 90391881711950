import React from 'react';
import truncate from 'truncate';
import dayjs from 'dayjs';
import DiscGroup from 'components/shared/disc-group';
import { findWhere } from 'underscore';

const { Cell } = require('fixed-data-table-2');

const DashboardCell = ({
  item, cellName, onFlowBoxClick, selectedItems,
}) => {
  function urlForItem() {
    switch (item.dashboardable_type) {
    case 'InitiatedFlow':
      return `/initiated_flows/${item.dashboardable_id}`;
    case 'ImportedFlow':
      return `/imported_flows/${item.dashboardable_id}`;
    default:
      throw new Error(`Unrecognized dashboardable type ${item.dashboardable_type}`);
    }
  }

  function buildId() {
    const id = item.dashboardable_type === 'InitiatedFlow'
      ? `#${item.scoped_id}`
      : '–';

    return (
      <Cell>
        <a
          href={urlForItem()}
          className='vmiddle'
          dangerouslySetInnerHTML={{ __html: id }}
        />
      </Cell>
    );
  }

  function buildRenewal() {
    if (!item.is_renewal) { return null; }

    return (
      <Cell>
        <span title='Renewal' className='tag tag-icon small inline'><div className='icon icon-renew' /></span>
      </Cell>
    );
  }

  function buildIdentifier() {
    return (
      <Cell>
        <span dangerouslySetInnerHTML={{ __html: truncate(item.identifier, 13) }} />
      </Cell>
    );
  }

  function buildCheckbox() {
    return (
      <input
        type='checkbox'
        checked={!!findWhere(selectedItems, { id: item.dashboardable_id, type: item.dashboardable_type })}
        onChange={(e) => { onFlowBoxClick(item, e); }}
      />
    );
  }

  function buildTemplateName() {
    return (
      <Cell className='flowtemplatename' title={item.template_name}>
        {truncate(item.template_name, 25)}
      </Cell>
    );
  }

  function buildApplicant() {
    const applicantName = item.dashboardable_type === 'InitiatedFlow'
      ? item.applicant
      : '(Imported)';

    return (
      <Cell>
        <span className='dashboard-applicant' dangerouslySetInnerHTML={{ __html: applicantName }} />
      </Cell>
    );
  }

  function buildAction() {
    return (
      <Cell>
        <span className='dashboard-actionlink' dangerouslySetInnerHTML={{ __html: item.action }} />
      </Cell>
    );
  }

  function buildLaunched() {
    return (
      <Cell>
        {dayjs(item.created_at).format('l')}
      </Cell>
    );
  }

  function buildStatus() {
    return (
      <Cell>
        <span dangerouslySetInnerHTML={{ __html: item.status }} />
      </Cell>
    );
  }

  function buildCurrentStep() {
    return (
      <Cell>
        <div className='cellCurrentStep'>
          {truncate(item.current_step_name, 25)}
          <div dangerouslySetInnerHTML={{ __html: item.current_step_elapsed_time_string }} />
        </div>
      </Cell>
    );
  }

  function buildResponsible() {
    return (
      <Cell>
        <DiscGroup groupMembers={item.assigned} />
      </Cell>
    );
  }

  function buildLastEdited() {
    return (
      <Cell>
         {dayjs(item.last_edited).format('LLL')}
      </Cell>
    )
  }

  function buildLastActivity() {
    return (
      <Cell>
        {dayjs(item.last_activity_at).format('l')}
      </Cell>
    );
  }

  switch (cellName) {
  case 'id':
    return buildId();
  case 'renewal':
    return buildRenewal();
  case 'identifier':
    return buildIdentifier();
  case 'checkbox':
    return buildCheckbox();
  case 'template_name':
    return buildTemplateName();
  case 'applicant':
    return buildApplicant();
  case 'action':
    return buildAction();
  case 'launched_at':
    return buildLaunched();
  case 'status':
    return buildStatus();
  case 'current_step':
    return buildCurrentStep();
  case 'responsible':
    return buildResponsible();
  case 'last_edited':
    return buildLastEdited();
  case 'last_activity':
    return buildLastActivity();
  default:
    throw new Error(`Unhandled cell name ${cellName}`);
  }
};

export default DashboardCell;
