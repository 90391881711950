import PropTypes from 'prop-types';
import React, {
  useRef, useMemo, useState, useEffect,
} from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { debounce } from 'underscore';

import Actions from 'actions/multiple-choice-option-actions';

const MultipleChoiceOption = ({
  option,
  optionNumber,
  shouldFocus,
  formulaValuesEnabled,
  onOptionChange,
  onDeleteFail,
  canDelete,
  disabled,
  index,
}) => {
  const displayValueInputRef = useRef();
  const [displayValue, setDisplayValue] = useState(option.name || '');
  const [numericValue, setNumericValue] = useState(option.numeric_value ?? '');

  const debouncedSendValueUpdate = useMemo(
    () => debounce((id, attrs) => {
      onOptionChange(id, attrs).fail(() => setDisplayValue(option.name || ''));
    }, 500),
    [option, onOptionChange],
  );
  const debouncedSendNumericUpdate = useMemo(
    () => debounce((id, attrs) => {
      onOptionChange(id, attrs).fail(() => setNumericValue(option.numeric_value ?? ''));
    }, 500),
    [option, onOptionChange],
  );

  useEffect(() => {
    if (shouldFocus) {
      displayValueInputRef.current.focus();
    }
  }, [shouldFocus]);

  useEffect(() => {
    // TODO: this might flap
    setNumericValue(option.numeric_value ?? '');
  }, [option]);

  const deleteClicked = () => {
    Actions.removeOption(option.id)
           .fail(onDeleteFail);
  };

  const onDisplayValueChange = (e) => {
    const { value } = e.target;
    setDisplayValue(value);
    debouncedSendValueUpdate(option.id, { name: value });
  };

  const onNumericValueChange = (e) => {
    const { value } = e.target;
    setNumericValue(value);
    debouncedSendNumericUpdate(option.id, { numeric_value: value });
  };

  return (
    <Draggable draggableId={`option-${option.id}`} index={index}>
      {({ innerRef, draggableProps, dragHandleProps }) => (
        <div className='fieldmanager-inputwrap' ref={innerRef} {...draggableProps}>
          <div className='fieldmanager-multiplechoice-left'>
            <input
              type='text'
              ref={displayValueInputRef}
              onChange={onDisplayValueChange}
              value={displayValue}
              disabled={disabled}
              placeholder={`Option ${optionNumber}`}
              name={`option-${option.id}`}
            />
          </div>
          <div className='fieldmanager-multiplechoice-right flex'>
            {formulaValuesEnabled && (
              <div className='fieldmanager-numericvalue'>
                <input
                  value={numericValue}
                  onChange={onNumericValueChange}
                  disabled={disabled}
                  type='number'
                />
              </div>
            )}
            <div className='fieldmanager-choicetools'>
              <a className='fieldmanager-mcoption-sort' {...dragHandleProps} />
              {canDelete && (
                <a className='fieldmanager-mcoption-delete' onClick={deleteClicked} />
              )}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

MultipleChoiceOption.propTypes = {
  onDeleteFail: PropTypes.func.isRequired,
  canDelete: PropTypes.bool.isRequired,
  option: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  optionNumber: PropTypes.number.isRequired,
  shouldFocus: PropTypes.bool.isRequired,
  onOptionChange: PropTypes.func.isRequired,
  formulaValuesEnabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
};

export default MultipleChoiceOption;
