import React, { useMemo, useCallback } from 'react';
import { uniqueId } from 'underscore';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Select from 'components/shared/select';
import { LOGICABLE_TYPES, actionsForLogicableType } from 'constants/logic-constants';
import FieldManager from 'modules/field-manager';
import AdvancedLabel from 'components/shared/advanced-label';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirm } from 'modules/alert-confirm';
import NotifBanner from 'components/shared/notif-banner';
import ConfiguredLogicCondition from './logic_widget/configured-logic-condition';
import LogicCondition from './logic_widget/logic-condition';

const LogicWidget = (props) => {
  const availableFields = useMemo(
    () => (
      props.flowTemplate
        ? new FieldManager(props.flowTemplate.steps).availableLogicFields()
        : []
    ),
    [props.flowTemplate],
  );
  const { setLogicConfig, logicConfig } = props;
  const onActionChanged = useCallback(
    ({ value }) => {
      setLogicConfig((prevConfig) => ({ ...prevConfig, action: value }));
    },
    [setLogicConfig],
  );

  const updateCondition = useCallback((conditionAttrs, index) => {
    const conditions = [...logicConfig.conditions];
    const condition = {
      ...conditions[index],
      ...conditionAttrs,
    };
    conditions[index] = condition;

    setLogicConfig((prevConfig) => ({ ...prevConfig, conditions }));
  }, [setLogicConfig, logicConfig.conditions]);

  const onRemoveClick = (conditionIndex) => {
    const conditions = [...props.logicConfig.conditions];
    conditions.splice(conditionIndex, 1);

    sendConditions(conditions);
  };

  const onAddConditionClick = (e) => {
    e.preventDefault();

    const conditions = [
      ...props.logicConfig.conditions,
      { tempId: uniqueId() },
    ];
    sendConditions(conditions);
  };

  function sendConditions(conditions) {
    props.setLogicConfig({ ...props.logicConfig, conditions });
  }

  function renderEditView() {
    const options = actionsForLogicableType(props.logicableType);
    return (
      <table>
        <tbody>
          <tr>
            <td>
              <AdvancedLabel
                label='Outcome'
                required
              />
            </td>
            <td>
              <div className='logicconfig-section'>
                <Select
                  value={options.find(({ value }) => value === props.logicConfig.action)}
                  options={options}
                  onChange={onActionChanged}
                  disabled={props.disabled}
                  isSearchable={false}
                  menuShouldScrollIntoView={false}
                  menuPosition='fixed'
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      display: 'inline-block',
                      verticalAlign: 'middle',
                      width: '100%',
                    }),
                  }}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <AdvancedLabel
                label='Conditions'
                required
              />
            </td>
            <td>
              <div className='logicconfig-section'>
                <div className='logicconfig-conditionwrap'>
                  {renderConditions()}

                  <div className='logicconfig-addcondition'>
                    <button
                      onClick={onAddConditionClick}
                      type='button'
                      className='btn-link'
                    >
                      + Add condition
                    </button>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan='2'>
              {props.children}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  function renderConditions() {
    return props.logicConfig.conditions.map((condition, index, array) => {
      const isLast = index === (array.length - 1);
      const id = condition.tempId ?? condition.id;
      return (
        <LogicCondition
          key={id}
          condition={condition}
          isLast={isLast}
          index={index}
          availableFields={availableFields}
          disabled={props.disabled}
          onChange={updateCondition}
          onRemoveClick={onRemoveClick}
        />
      );
    });
  }

  function renderConfiguredView() {
    return (
      <div className='logicconfig-configuredwrap'>
        <div className='logicconfig-configuredwrap-conditions'>
          <span className='margin-right-less'>{actionsForLogicableType(props.logicableType).find(({ value }) => value === props.logicConfig.action).label}</span>
          <span className='margin-right-less'>if</span>
          {renderConfiguredConditions()}
        </div>
        <div className='logicconfig-configuredwrap-actions'>
          {renderEditButton()}
          {renderXButton()}
        </div>
      </div>
    );
  }

  function renderConfiguredConditions() {
    return props.logicConfig.conditions.map((condition) => {
      const sourceField = availableFields.find(({ id }) => id === condition.source_id);
      const multipleChoiceMatchValue = sourceField.multiple_choice_options
        .find(({ id }) => id === Number(condition.match_value))?.name;

      return (
        <ConfiguredLogicCondition
          condition={condition}
          sourceField={sourceField}
          multipleChoiceMatchValue={multipleChoiceMatchValue}
          key={condition.id}
        />
      );
    });
  }

  function renderXButton() {
    if (props.logicableType === LOGICABLE_TYPES.subprocess) { return; }

    return (
      <FontAwesomeIcon
        icon={icon({ name: 'trash' })}
        className='clickable'
        onClick={onDelete}
        title='Remove logic configuration'
      />
    );
  }

  function onDelete() {
    confirm('Delete this logic configuration?', 'Are you sure you want to delete this logic configuration?', () => {
      props.onDelete();
    }, null, 'deletelogicconfig');
  }

  function renderEditButton() {
    if (props.editing) { return; }
    if (props.logicConfig.tempId) { return; }
    if (props.logicableType === 'Subprocess') { return; }

    return (
      <FontAwesomeIcon
        icon={icon({ name: 'pencil' })}
        className='clickable'
        title='Edit logic configuration'
        onClick={() => { props.setEditing(true); }}
      />
    );
  }

  return (
    <dl style={{ width: '100%' }}>
      {props.showTitle && (
        <dt>
          <span
            className='icon icon-pathsplit-cyan margin-right-less'
            css={css`
              top:-3px;
              width:18px;
              position:relative;
            `}
          />
          <span>Logic</span>
        </dt>
      )}
      <dd>
        {props.error && (
          <NotifBanner style='alert'>{props.error}</NotifBanner>
        )}
        {props.editing ? renderEditView() : renderConfiguredView()}
      </dd>
    </dl>
  );
};

LogicWidget.propTypes = {
  logicConfig: PropTypes.shape({
    id: PropTypes.number,
    tempId: PropTypes.string,
    conditions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  setEditing: PropTypes.func,
  flowTemplate: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool.isRequired,
  logicableType: PropTypes.string,
  setLogicConfig: PropTypes.func,
  editing: PropTypes.bool,
  error: PropTypes.node,
  onDelete: PropTypes.func,
  children: PropTypes.node,
  showTitle: PropTypes.string,
};

LogicWidget.defaultProps = {
  setEditing: () => {},
};

export default LogicWidget;
