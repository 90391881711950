import React, { useState, useEffect } from 'react';
import TopRow from 'components/metrics_dashboard/top-row';
import SecondRow from 'components/metrics_dashboard/second-row';
import Filters from 'components/metrics_dashboard/filters';
import Finances from 'components/metrics_dashboard/finances';
import MetricsDashboardAPI from 'apis/metrics-dashboard-api';
import DashboardAPI from 'apis/dashboard-api';
import SubmissionsTable from 'components/metrics_dashboard/submissions-table';
import PollableJob from 'modules/pollable-job';
import LoadingSpinner from 'components/shared/loading-spinner';
import PrimaryTabs from './shared/primary-tabs';

const MetricsDashboard = (props) => {
  const [data, setData] = useState({formData: {flows: []}, revenueData: {}});
  const [forms, setForms] = useState(null)
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    applyFilters({});
    getForms({});
  }, []);

  function getForms(filters) {
    setForms(null);
    DashboardAPI.fetchAssigned(filters)
      .done((result) => {
        setForms(result);
      });
  }

  function applyFilters(filters) {
    setLoading(true);
    getForms(filters);
    const jobRunner = new PollableJob(() => { return MetricsDashboardAPI.fetchData(filters); });
    jobRunner.run()
            .done((result) => { setData(JSON.parse(result)); setLoading(false); })
            .fail(() => { setLoading(false); });
  }

  function renderMetrics() {
    if (loading) {
      return (
        <div className='metricsdashboard-loading vmiddle'>
          <LoadingSpinner size='xlarge' className='vmiddle' />
        </div>
      );
    }

    return (
      <div className='padding-bottom-more'>
        <TopRow formData={data.form_data} />
        <SecondRow formData={data.form_data} />
        <Finances transactionData={data.revenue_data} />
      </div>
    );
  }

  return (
    <div className='margin-left margin-bottom margin-right flexcolumn' style={{ height: '100%' }}>
      <PrimaryTabs />
      <div id='tabheader-body' className='metrics scroll'>
        <Filters teamsArray={props.teams} formsArray={props.forms} applyFilters={applyFilters} />
        {renderMetrics()}
        <SubmissionsTable initiatedFlows={forms} totalCount={forms?.length} />
      </div>
    </div>
  );
};

export default MetricsDashboard;
