export function fetchTasks(searchTerm) {
  return $.ajax('/my_tasks/fetch_tasks', {
    method: 'GET',
    dataType: 'json',
    data: {
      master_search_terms: searchTerm,
    },
  });
}

export function fetchStep(id, type, initiatedFlowId) {
  return $.ajax('my_tasks/fetch_step', {
    method: 'GET',
    dataType: 'json',
    data: {
      id,
      type,
      initiated_flow_id: initiatedFlowId,
    },
  });
}

export default {
  fetchTasks,
  fetchStep,
};