import React, { useState } from 'react';
import Select from 'components/shared/select';
import ProcessorActions from 'actions/processor-actions';
import { findWhere, reject, contains } from 'underscore';
import FinanceSubnav from './shared/finance-subnav';

const DEFAULT_PROCESSOR_OPTIONS = [
  { label: 'Third party', value: 'third_party' },
];

const MerchantAccountsManager = ({ processors, teamId, teamSlug }) => {
  const [processor, setProcessor] = useState(findActiveProcessor);
  const [addingNew, setAddingNew] = useState(false);
  const [newProcessor, setNewProcessor] = useState(null);

  function findActiveProcessor() {
    const prcssr = findWhere(processors, { active: true });
    if (!prcssr) { return { id: null, processor: 'stripe' }; }

    return prcssr;
  }

  function findProcessor(id) {
    const prcssr = findWhere(processors, { id });

    return prcssr;
  }

  function onNewProcessorChange({ value }) {
    setNewProcessor({ processor: value });
  }

  function onProcessorChange({ value }) {
    setProcessor(findProcessor(value));
    ProcessorActions.setProcessor(value, teamId);
  }

  function createProcessor() {
    ProcessorActions.createProcessor(newProcessor, teamId);
  }

  function onProcessorDataChange(value, key) {
    setNewProcessor({ ...newProcessor, [key]: value });
  }

  function processorOptions() {
    const processorOpts = processors.map((p) => { return { label: p.processor, value: p.id }; });

    return [{ label: 'Stripe', value: null }, ...processorOpts];
  }

  function newProcessorOptions() {
    const processorValues = processors.map((p) => { return p.processor; });
    return reject(DEFAULT_PROCESSOR_OPTIONS, (p) => { return contains(processorValues, p.value); });
  }

  function thirdPartyDetails() {
    return (
      <div>
        <label>
          API key
          <input type='text' disabled={!addingNew} value={processor.api_key} onChange={(e) => { onProcessorDataChange(e.currentTarget.value, 'api_key'); }} />
        </label>
        {addingNew && renderSubmitButton()}
      </div>
    );
  }

  function renderSubmitButton() {
    return <button onClick={createProcessor}>Save Processor</button>;
  }

  function renderStripeContent() {
    return (
      <div>
        This account is using Stripe, the default payment processor for ClearForms.
        <br />
        To use an alternate third party processor, first add a new processor. You will then be able to set it as your processor.
        <br />
        <strong>Note:</strong> Using a processor other than Stripe will disable the transaction reporting features of ClearGov.
      </div>
    );
  }

  function renderProcessorContent() {
    switch (processor.processor) {
    case 'third_party':
      return thirdPartyDetails();
    case 'stripe':
      return renderStripeContent();
    }
  }

  function newProcessorSelect() {
    if (newProcessorOptions().length === 0) {
      return <div>All available processors have been created.</div>;
    }

    return (
      <label>
        Processor
        <Select
          options={newProcessorOptions()}
          onChange={onNewProcessorChange}
        />
      </label>
    );
  }

  function renderMainContent() {
    if (addingNew) {
      return (
        <div>
          <button className='btn-link' type='button' onClick={() => setAddingNew(false)}>{'< Back'}</button>
          <h2>New Processor</h2>
          {newProcessorSelect()}

          {newProcessor && renderProcessorDetails()}
        </div>
      );
    }

    return (
      <div>
        <h2 className='inline-block nomargin-bottom'>Payment Processors</h2>
        <button className='btn-link strong float-right' onClick={() => setAddingNew(true)}>+ Add New Processor</button>
        <label>
          Active Payment Processor
          <Select
            value={processorOptions().find(({ value }) => value === processor.id)}
            options={processorOptions()}
            onChange={onProcessorChange}
            disabled={processors.length === 0}
          />
        </label>

        {renderProcessorContent()}

      </div>
    );
  }

  return (
    <div className='finance-container'>
      <div id='finance-left-nav'>
        <FinanceSubnav teamSlug={teamSlug} activeLink='manage' />
      </div>
      <div className='finance-main'>
        <div className='minimalcontentcontainer'>
          {renderMainContent()}
        </div>
      </div>
    </div>
  );
};

export default MerchantAccountsManager;
