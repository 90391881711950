import React from 'react';
import _ from 'underscore';
import { fieldNameForType } from 'modules/step-data-types';

const FieldPreview = (props) => {
  if (!_.any(props.sections)) { return <></>; }

  const renderSections = (sections) => {
    return props.sections.map(renderSection);
  };

  const renderSection = (section) => {
    return (
      <React.Fragment key={section.id}>
        <tr className='collecteddatatable-sectionname'>
          <td>{section.name}</td><td />
        </tr>
        {renderStepData(section.step_data)}
      </React.Fragment>
    );
  };

  const renderStepData = (stepData) => {
    return stepData.map(renderStepDatum);
  };

  const renderStepDatum = (stepDatum) => {
    return (
      <tr key={`datum-${stepDatum.id}`}>
        <td>{stepDatum.name}{renderAsterisk(stepDatum)}</td>
        <td>{fieldNameForType(stepDatum.data_type, { customEntityName: stepDatum.custom_entity_name })}</td>
      </tr>
    );
  };

  const renderAsterisk = (stepDatum) => {
    if (!stepDatum.required) { return; }

    return (
      <span className='color-alert'>*</span>
    );
  };

  return (
    <div className='well margin-top collecteddatatable-wrap'>
      <table>
        <tbody>
          {renderSections()}
        </tbody>
      </table>
    </div>
  );
};

export default FieldPreview;
