const UserPreferenceAPI = {
  starForm(masterTemplateId) {
    const url = '/user_preferences/star_form';
    return $.ajax(url, {
      data: {
        master_template_id: masterTemplateId,
      },
      method: 'POST',
      dataType: 'json',
    });
  },

  unstarForm(masterTemplateId) {
    const url = '/user_preferences/unstar_form';
    return $.ajax(url, {
      data: {
        master_template_id: masterTemplateId,
      },
      method: 'POST',
      dataType: 'json',
    });
  },
};

export default UserPreferenceAPI;
