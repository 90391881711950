import React from 'react';
import PropTypes from 'prop-types';

// TODO: dedupe with moneyvisualizer?
const NumberVisualizer = ({ data }) => (
  <div className='tablevisualizer'>
    <div>
      <div>
        Mean
      </div>
      <div>
        {data.mean}
      </div>
    </div>

    <div>
      <div>
        Median
      </div>
      <div>
        {data.median}
      </div>
    </div>

    <div>
      <div>
        Standard deviation
      </div>
      <div>
        {data.standard_deviation}
      </div>
    </div>
  </div>
);

NumberVisualizer.propTypes = {
  data: PropTypes.object,
};

export default NumberVisualizer;
