import React from 'react';
import PropTypes from 'prop-types';

const ExportsMessage = (props) => {
  return (
    <div className={`fullwidthnotif success export ${props.className}`}>
      <span>Export queued. You will be emailed when your export completes. Finished exports can be found on the <a href='/exports'>Exports</a> page.</span>
    </div>
  );
};

ExportsMessage.propTypes = {
  className: PropTypes.string,
};

ExportsMessage.defaultProps = {
  className: '',
};

export default ExportsMessage;
