import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import PersonSelector from 'components/shared/person-selector';
import UserAPI from 'apis/user-api';
import AttachmentActions from 'actions/attachment-actions';
import { findWhere, filter, isEmpty } from 'underscore';
import { error } from 'components/shared/flash';
import truncate from 'truncate';
import Members from './members';

const AccessManager = (props) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);
  const [managingAdmins, setManagingAdmins] = useState(false);

  function onAdminSearchTermsChanged(terms) {
    return UserAPI.search(terms, { initiatedFlowId: props.initiatedFlowId });
  }

  function onAdminToggle(user) {
    const userId = user.id;
    // Verify existing users can be removed
    const existingUser = findWhere(props.personAttachments, { id: userId });
    if (existingUser && !existingUser.removable) {
      error('Form members cannot be removed from an individual submission');
    } else if (existingUser) {
      AttachmentActions.removePerson(existingUser.attachment_id)
        .done((res) => AttachmentActions.dispatchInitiatedFlowTogglePerson(dispatch, res));
    } else {
      AttachmentActions.togglePerson(props.initiatedFlowId, 'InitiatedFlow', userId, 'User')
        .done((res) => AttachmentActions.dispatchInitiatedFlowTogglePerson(dispatch, res));
    }
  }

  function onSendInviteButtonClicked(email, name) {
    AttachmentActions.addPseudomember(email, name, 'InitiatedFlow', props.initiatedFlowId, {})
      .done((res) => AttachmentActions.dispatchInitiatedFlowTogglePerson(dispatch, res));
  }

  function renderAdminRoles() {
    const rolesToRender = filter(props.personAttachments, { type: 'AdminRole' });
    if (isEmpty(rolesToRender)) { return; }

    return (
      <>
        <div className='accessmanager-label'>Roles</div>
        <ul className='accessmanager-admins'>
          {rolesToRender.map(renderAdminRole)}
        </ul>

        <div className='linebreak' />
      </>
    );
  }

  function renderAdminRole(user) {
    return (
      <li key={user.id} className='cf'>
        <div title={user.name} className='float-left'>
          <span>{truncate(user.name, 14)}</span>
        </div>
      </li>
    );
  }

  function onManageAdminsClick(e) {
    e.preventDefault();
    setManagingAdmins(!managingAdmins);
  }

  function renderSearch() {
    return (
      <PersonSelector
        doSearch={onAdminSearchTermsChanged}
        hideExternal
        allowPseudo
        teamId={props.teamId}
        onSelect={onAdminToggle}
        onInvite={onSendInviteButtonClicked}
        placeholder='Enter email, name, or ClearForms username'
        attachableType='InitiatedFlow'
      />
    );
  }

  return (
    <div className='accessmanager-wrap'>
      <div className='accessmanager relative'>
        <div className='accessmanager-header cf'>
          <div className='float-left'>
            <label>
              Access
            </label>
          </div>

          <div className='float-right'>
            <button className='btn-link' type='button' onClick={onManageAdminsClick}>Manage</button>
          </div>
        </div>

        <div className='accessmanager-body'>
          {managingAdmins && renderSearch()}

          {renderAdminRoles()}

          <Members
            personAttachments={props.personAttachments}
            initiatedFlowId={props.initiatedFlowId}
          />
        </div>
      </div>
    </div>
  );
};

AccessManager.propTypes = {
  teamId: PropTypes.number.isRequired,
  personAttachments: PropTypes.arrayOf(PropTypes.shape({})),
  initiatedFlowId: PropTypes.number.isRequired,
};

export default AccessManager;
