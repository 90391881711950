function getTemplateVariables(formulas, fields, type) {
  const formattedFormulas = formulas.map((formula, idx) => {
    return {
      id: formula.identity,
      name: formula.name,
      displayName: formula.name,
      mentionType: 'Formula',
      selectedIconClass: 'icon-formula-primary-2',
      iconClass: 'icon-formula-black',
    };
  });

  const stepData = fields.map((field) => {
    return {
      mentionType: 'Field',
      id: field.identity,
      name: field.name,
      displayName: `${field.section_name}-${field.name}`,
      selectedIconClass: 'icon-field-primary-2',
      iconClass: 'icon-field-black',
    };
  });

  const constantVariables = [
    {
      name: 'launcher_name',
      displayName: 'launcher_name',
      mentionType: 'Keyword',
      id: 'launcher_name',
      selectedIconClass: 'icon-keyword-primary-2',
      iconClass: 'icon-keyword-black',
    },
  ];

  const baseVariables = [...formattedFormulas, ...stepData, ...constantVariables];

  switch (type) {
  case 'Instructions':
    return baseVariables;
  case 'CompletionMessage':
    return baseVariables;
  case 'ReviewMessages':
    return baseVariables;
  case 'Email':
    baseVariables.push({
      name: 'process_link',
      displayName: 'process_link',
      mentionType: 'Keyword',
      id: 'process_link',
      selectedIconClass: 'icon-keyword-primary-2',
      iconClass: 'icon-keyword-black',
    });
    return baseVariables;
  }
}

export default getTemplateVariables;
