import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import truncate from 'truncate';

import Select from "../select";
import ApplicantSearch from "./applicant-search";
import CompletedStatus from "./completed-status";
import DateRange from "./date-range";
import DateTerm from "./date-term";
import DatumFieldFilter from "./datum-field-filter";
import FormSelect from "./form-select";
import ImportedSelect from "./imported-select";
import MinifiedFilterUnit from "./minified-filter-unit";
import OutputStatus from "./output-status";
import RenewalStatus from "./renewal-status";
import StepSelect from "./step-select";
import SubmissionFilter from "./submission-filter";
import TagSelect from "./tag-select";
import TeamSelect from "./team-select";
import ToggleOption from "./toggle-option";

const FilterUnit = ({
  filter,
  state,
  dispatch,
  formTemplates,
  tags,
  minify,
}) => {
  const onDatumFieldsChange = (value) => {
    let datumFields = state.currentFilter['datum_fields'].value || {};
    datumFields[filter.id] = value;

    dispatch({
      type: 'changeFilterValue',
      filter: { id: 'datum_fields' },
      value: datumFields,
    });
  };

  const onFilterDelete = () => {
    dispatch({
      type: 'deleteFilter',
      value: filter,
    });
  };

  const onOperatorChange = (value) => {
    dispatch({
      type: 'changeFilterOperator',
      filter: filter,
      operator: value,
    });
  };

  const onFilterValueChange = (value) => {
    dispatch({
      type: 'changeFilterValue',
      filter: filter,
      value: value,
    });
  };

  const onFilterValueOptionsChange = (value) => {
    dispatch({
      type: 'changeFilterValueOptions',
      filter: filter,
      value: value,
    });
  };

  const enableStepDataFilters = (value) => {
    dispatch({
      type: 'enableStepDataFilters'
    });
  };

  const disableStepDataFilters = () => {
    dispatch({
      type: 'disableStepDataFilters'
    });
  };

  const label = () => {
    return truncate(filter.label, 26);
  }

  const renderSubUnit = (minified) => {
    switch (filter.type) {
      case 'applicantSearch': {
        return (
          <ApplicantSearch
            changeFilterValue={onFilterValueChange}
            changeFilterValueOptions={onFilterValueOptionsChange}
            filterState={state.currentFilter[filter.id]}
            minify={minified}
          />
        );
      }

      case 'assignedToggle':
      case 'outputRenewalsEnabled': {
        return (
          <div style={{ display: 'inline-block', marginTop: '8px' }}>
            <ToggleOption
              changeFilterValue={onFilterValueChange}
              on={state.currentFilter[filter.id].value}
            />
          </div>
        );
      }

      case 'completedStatus': {
        return <CompletedStatus
          changeFilterValue={onFilterValueChange}
          filterState={state.currentFilter[filter.id]}
          minify={minified}
        />
      }

      case 'currentStep': {
        return <StepSelect
          changeFilterValue={onFilterValueChange}
          filterState={state.currentFilter[filter.id]}
          stepDataForSearch={state.stepDataForSearch}
          minify={minified}
        />
      }

      case 'currentStepTime': {
        return <DateTerm
          changeFilterValue={onFilterValueChange}
          changeFilterValueOptions={onFilterValueOptionsChange}
          filterState={state.currentFilter[filter.id]}
          minify={minified}
        />
      }

      case 'datumField': {
        if (minified) {
          return null;
        } else {
          return <DatumFieldFilter
            onDatumFieldsChange={onDatumFieldsChange}
            fieldType={filter.fieldType}
            multipleChoiceOptions={filter.multipleChoiceOptions}
            datumFields={state.currentFilter.datum_fields}
            datumId={filter.id}
          />
        }
      }

      case 'formSelect': {
        return <FormSelect
          changeFilterValue={onFilterValueChange}
          changeFilterValueOptions={onFilterValueOptionsChange}
          disableStepDataFilters={disableStepDataFilters}
          enableStepDataFilters={enableStepDataFilters}
          filterState={state.currentFilter[filter.id]}
          teamFilter={state.currentFilter['team']}
          formTemplates={formTemplates}
        />
      }

      case 'importedSelect': {
        return <ImportedSelect
          changeFilterValue={onFilterValueChange}
          changeFilterValueOptions={onFilterValueOptionsChange}
          filterState={state.currentFilter[filter.id]}
          minify={minified}
        />
      }

      case 'completedDate':
      case 'launchedAt':
      case 'outputIssued': {
        return <DateRange
          changeFilterValue={onFilterValueChange}
          fromToValues={state.currentFilter[filter.id].value}
          minify={minified}
        />
      }

      case 'outputStatus': {
        return <OutputStatus
          changeFilterValue={onFilterValueChange}
          filterState={state.currentFilter[filter.id]}
          minify={minified}
        />
      }

      case 'renewalStatus': {
        return <RenewalStatus
          changeFilterValue={onFilterValueChange}
          filterState={state.currentFilter[filter.id]}
          minify={minified}
        />
      }

      case 'submission': {
        return <SubmissionFilter
          changeFilterValue={onFilterValueChange}
          filterState={state.currentFilter[filter.id]}
          minify={minified}
        />
      }

      case 'tagSelect': {
        return <TagSelect
          changeFilterValue={onFilterValueChange}
          filterState={state.currentFilter[filter.id]}
          tags={tags}
        />
      }

      case 'teamSelect': {
        return <TeamSelect
          changeFilterValue={onFilterValueChange}
          filterState={state.currentFilter[filter.id]}
          minify={minified}
        />
      }

      default:
        return null;
    }
  };

  const renderOperatorSelect = () => {
    return (
      <div className='metricsdashboard-filterselect'>
        <Select
          options={filter.operators}
          onChange={onOperatorChange}
          getOptionValue={(opt) => opt.id}
          value={filter.operators.find((opt) => opt.id == state.currentFilter[filter.id].operator)}
          className='react-select-metrics advanced-filter'
        />
      </div>
    );
  };

  const renderMainContent = () => {
    const unitId = `filter-unit-${filter.id}`;
    return (
      <div key={filter.id} className='metricsdashboard-filter-unit' id={unitId}>
        <div
          className='metricsdashboard-filterlabel margin-left bold'
          title={filter.label}
        >
            {label()}
        </div>
        {!!filter.operators.length && renderOperatorSelect()}
        {renderSubUnit()}
        <FontAwesomeIcon
          id='cancel-filter-icon'
          className='margin-right padding'
          style={{ color: '#cc1010', cursor: 'pointer', marginLeft: '8px' }}
          icon={icon({ name: 'xmark' })}
          onClick={onFilterDelete}
        />
      </div>
    );
  };

  const renderMinified = () => {
    return (
      <>
        <div className='metricsdashboard-filterlabel margin-right bold' title={filter.label}>{label()}</div>
        <MinifiedFilterUnit
          filterType={filter.type}
          filterState={state.currentFilter[filter.id]}
          formTemplates={formTemplates}
          tags={tags}
          stepDataForSearch={state.stepDataForSearch}
        />
      </>
    );
  };

  return minify ? renderMinified() : renderMainContent();
};

export default FilterUnit;
