import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CollectedDataAPI from 'apis/collected-data-api';
import FormData from 'form-data';
import _ from 'underscore';
import { render } from 'components/shared/flash';
import LoadingSpinner from 'components/shared/loading-spinner';
import FileDropzone from 'components/shared/file-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const FileInput = ({onChange, disabled, id }) => {
  const [uploading, setUploading] = useState(false);

  function onFileAccept(file) {
    setUploading(true);

    uploadDirectToS3(file)
      .done((url) => onChange(url, file))
      .fail(onUploadError)
      .always(() => setUploading(false));
  }

  function onUploadError() {
    render({
      message: 'There was an error uploading your file. Please try again.',
      type: 'error',
    });
  }

  function uploadDirectToS3(file) {
    const d = $.Deferred();

    fetchPreauth(file.name)
      .done((res) => {
        postToS3(res.url, res.form_data, file)
          .done((s3Res) => {
            const url = findFileURL(s3Res);
            d.resolve(url);
          })
          .fail(d.reject);
      })
      .fail(d.reject);

    return d;
  }

  function findFileURL(res) {
    const $xml = $(res);
    return $xml.find('Location').text();
  }

  function fetchPreauth() {
    return CollectedDataAPI.s3Preauth();
  }

  function postToS3(url, authFields, file) {
    const formData = new FormData();
    formData.append('key', authFields.key);
    delete authFields.key;
    _.each(authFields, (k, v) => formData.append(v, k));
    formData.append('file', file);

    const request = $.ajax(url, {
      contentType: false,
      data: formData,
      method: 'POST',
      dataType: 'XML',
      processData: false,
    });

    return request;
  }

  function loadingHTML() {
    return (
      <div className='dropzone-content-container'>
        <LoadingSpinner size='medium' />;
      </div>
    );
  }

  function disabledHTML() {
    return (
      <div className='dropzone-content-container'>
        <span className='icon icon-lock biggest' />
      </div>
    );
  }

  function blockedContent() {
    if (disabled) { return disabledHTML(); }
    if (uploading) { return loadingHTML(); }
  }

  function mainContent() {
    return (
      <div className='dropzone-content-container'>
        <div><FontAwesomeIcon icon={solid('cloud-arrow-up')} size='2xl' style={{ color: '#7d99ac' }} /></div>
        <div>Drag and drop file here</div>
        <div className='padding-top'>
          <hr />
          <div className='inline-block'>or</div>
          <hr />
        </div>
        <div className='padding-top'>Click to browse files</div>
      </div>
    );
  }

  function isBlocked() {
    return uploading || disabled;
  }

  return (
    <FileDropzone onFileAccept={onFileAccept} disabled={disabled} inputId={id}>
      {isBlocked() ? blockedContent() : mainContent()}
    </FileDropzone>
  );
};

FileInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default FileInput;
