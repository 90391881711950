const TeamAPI = {
  update(teamId, attrs) {
    const url = `/teams/${teamId}`;

    return $.ajax(url, {
      method: 'PUT',
      dataType: 'json',
      data: {
        team: attrs,
      },
    });
  },

  checkSlugAvailable(teamId, slug) {
    const url = '/teams/check_slug_available';
    return $.ajax(url, {
      dataType: 'json',
      method: 'get',
      data: {
        slug,
        team_id: teamId,
      },
    });
  },

  checkChargesEnabled(initiatedFlowId) {
    const url = `/teams/check_charges_enabled`;
    return $.ajax(url, {
      dataType: 'JSON',
      method: 'GET',
      data: {
        initiated_flow_id: initiatedFlowId,
      },
    });
  },

  processListing(teamId, query) {
    const url = `/${teamId}/process_listing`;
    return $.ajax(url, {
      dataType: 'JSON',
      method: 'GET',
      data: {
        q: query,
      },
    });
  },
};

export default TeamAPI
