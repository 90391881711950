import React from 'react';
import PropTypes from 'prop-types';
import { show as outputsShow } from 'apis/outputs-api';
import { alert } from 'modules/alert-confirm';
import BackgroundedJobLink from 'components/shared/backgrounded-job-link';
import ajaxDownload from 'modules/ajax-download';
import { error as flashError } from 'components/shared/flash';

const OutputDownloadLink = (props) => {
  const onOutputFinishedGenerating = (res) => {
    const parsed = JSON.parse(res);
    const url = parsed.file_url;
    const fileName = parsed.file_name;

    ajaxDownload(url, fileName)
      .catch(() => { alertWithDownloadURL(url); });
  };

  const alertWithDownloadURL = (url) => {
    alert('Download ready', `<a target='_blank' href='${url}'>Click here to download your file</a>`);
  };

  return (
    <BackgroundedJobLink
      queueFunction={() => { return outputsShow(props.output.id, false); }}
      onJobComplete={onOutputFinishedGenerating}
      onFail={flashError}
      workingText='Downloading'
      className={props.className}
    >
      Download
    </BackgroundedJobLink>
  );
};

OutputDownloadLink.propTypes = {
  className: PropTypes.string.isRequired,
  output: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

OutputDownloadLink.defaultProps = {
  className: 'output-downloadlink',
};

export default OutputDownloadLink;
