import React, { useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import { alert } from 'modules/alert-confirm';
import PaymentStepActions from 'actions/payment-step-actions';

const CheckForm = (props) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);
  const amountReceived = useRef();
  const checkNumber = useRef();

  function checkReceivedClicked() {
    const amount = amountReceived.current.value;

    if (!$.isNumeric(amount) || amount <= 0) {
      alert('Whoops', 'Check amount must be a valid number. Check your input and try again.');
      return;
    }

    const paymentFormAttrs = {
      amount,
      checkNumber: checkNumber.current.value,
    };

    PaymentStepActions.checkReceived(dispatch, props.activeStep.id, paymentFormAttrs, props.type);
  }

  function adminView() {
    return (
      <div>
        <div className='margin-top-more'><div className='subtitle'>Payment details</div></div>
        <label>Amount Received
          <input type='text' ref={amountReceived} />
        </label>

        <label>Check Number
          <input type='text' ref={checkNumber} />
        </label>
        <div className='margin-top'>
          <button
            type='submit'
            onClick={checkReceivedClicked}
            className='btn-primary'
          >Check received
          </button>
        </div>
      </div>
    );
  }

  function publicView() {
    return (
      <>
        <p>{props.checkInstructions}</p>
        <p className='color-text-medium'><strong>Note:</strong> This page will automatically update and you will receive an email notification when your check is received.</p>
      </>
    );
  }

  return (
    <div className='currentstep-ckecktools'>
      {props.admin ? adminView() : publicView() }
    </div>
  );
};

CheckForm.propTypes = {
  admin: PropTypes.bool.isRequired,
  checkInstructions: PropTypes.string.isRequired,
  activeStep: PropTypes.object.isRequired,
};

export default CheckForm;
