import React from 'react';
import PropTypes from 'prop-types';
import UserDisc from 'components/shared/user-disc';
import { css } from '@emotion/react';
import ManyDisc from './disc-group/many-disc';
// import RoleDisc from 'components/shared/role-disc';

export const NUM_TO_SHOW = 3;

const DiscGroup = ({ groupMembers }) => {
  const count = groupMembers.length;

  const baseStyle = css`
    border:2px solid #fff;
  `;

  const stackedStyle = css`
    border:2px solid #fff;
    margin-left:-15px;
  `;

  const renderFirst3 = () => {
    return groupMembers.slice(0, NUM_TO_SHOW).map((member, i) => {
      return (
        <span
          key={i}
          style={{
            position: 'relative',
            zIndex: count - i,
          }}
        >
          <UserDisc
            user={member}
            css={css`
              ${baseStyle}
              ${i !== 0 && stackedStyle}
            `}
            options={{
              showPseudo: false,
            }}
          />
        </span>
      );
    });
  };

  const renderEveryoneElse = () => {
    if (count <= 3) { return; }

    return (
      <ManyDisc
        groupMembers={groupMembers}
      />
    );
  };

  return (
    <>
      {renderFirst3()}
      {renderEveryoneElse()}
    </>
  );
};

export default DiscGroup;
