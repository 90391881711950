import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import 'timeago';

import Actions from 'actions/activity-log-actions';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';

$.timeago.settings.cutoff = 1000 * 60 * 60 * 24;

const Activity = ({
  activities,
  initiatedFlowId,
}) => {
  const [loadFailed, setLoadFailed] = useState(false);
  const dispatch = useContext(InitiatedFlowDispatchContext);

  useEffect(() => {
    Actions.loadActivities(initiatedFlowId, dispatch)
           .fail(() => { setLoadFailed(true); });
  }, []);

  useEffect(() => {
    $('time.timeago').each((_, item) => {
      if (!item.title) {
        item.title = dayjs(item.dateTime).format('LLL');
        $(item).timeago();
      }
    });
  });

  const renderActivities = () => {
    if (loadFailed) {
      return <div className='margin-top'>Unable to load activity log</div>;
    }

    return activities.map(renderActivity);
  }

  const renderActivity = (activity) => {
    return (
      <li key={activity.id} dangerouslySetInnerHTML={{ __html: activity.html }} />
    );
  }

  return (
    <div id='activitylog'>
      <ul>
        {renderActivities()}
      </ul>
    </div>
  );
};

Activity.propTypes = {
  initiatedFlowId: PropTypes.number.isRequired,
};

export default Activity;
