import React from 'react';
import PropTypes from 'prop-types';
import { debounce, isNull } from 'underscore';

import FlowStepActions from 'actions/flow-step-actions';
import FormulaAPI from 'apis/formula-api';
import MoneyInput from 'components/shared/input_fields/money-input';
import Select from 'components/shared/select';
import SlidingToggle from 'components/shared/sliding-toggle';

class FixedPaymentSettings extends React.Component {
  constructor(props) {
    super(props);

    this.calculated = React.createRef();
    this.fixed = React.createRef();
    this.debouncedAmountChanged = debounce(this.amountChanged, 500);

    this.state = {
      availableFormulas: (this.props.step.type === 'PaymentRequirement' ? [] : null),
      formulaLoadError: false,
    };
  }

  componentDidMount() {
    this.loadAvailableFormulas();
  }

  componentDidUpdate(prevProps) {
    if (this.props.step.type === 'PaymentRequirement') { return; }

    if (prevProps.step.position === this.props.step.position) { return; }

    this.loadAvailableFormulas();
  }

  onAutoadvanceToggle = () => {
    FlowStepActions.updateStep(this.props.step.id, {
      autoadvance: !this.props.step.autoadvance,
    });
  };

  loadAvailableFormulas = () => {
    // TODO: This is a crap workaround. This component needs its
    // presentation extracted for happier sharing between
    // Payment requirements and template editor usage

    if (this.props.step.type !== 'PaymentRequirement') {
      FormulaAPI.availableForStep(this.props.step.id)
                .done(this.onFormulaLoadComplete)
                .fail(this.onFormulaLoadFailed);
    }
  };

  onFormulaLoadComplete = (res) => {
    this.setState({
      availableFormulas: res,
    });
  };

  onFormulaLoadFailed = () => {
    this.setState({
      formulaLoadError: true,
    });
  };

  onCalculatedChanged = () => {
    this.updateConfig('calculated', this.calculated.current.checked);
  };

  onFormulaChanged = ({ value }) => {
    this.updateConfig('formula_id', value);
  };

  amountChanged = (_e, newAmount) => {
    this.updateConfig('amount', newAmount);
  };

  updateConfig = (attrName, value) => {
    const attrs = {
      config_attributes: {
        [attrName]: value,
      },
    };

    return this.props.updateAttrs(this.props.step.id, attrs);
  };

  renderAmountOption = () => {
    const { config, disabled, step } = this.props;

    return (
      <div className={`stepoptions-settinggroup${disabled ? ' disabled' : ''}`}>
        <label>
          <span>Enter amount</span>
          <MoneyInput
            onChange={this.debouncedAmountChanged}
            disabled={config.calculated}
            defaultValue={config.amount}
            key={step.id}
            id='paymentamountinput'
          />
        </label>
      </div>
    );
  };

  loadingFormulaOptions = () => {
    return isNull(this.state.availableFormulas) && !this.state.formulaLoadError;
  };

  noFormulasAvailable = () => {
    const formulas = this.state.availableFormulas;
    return !isNull(formulas) && formulas.length === 0;
  };

  defaultFormulaOptionText = () => {
    if (this.state.formulaLoadError) {
      return 'Error loading formulas';
    }
    if (isNull(this.state.availableFormulas)) {
      return 'Loading formulas...';
    }
    if (this.noFormulasAvailable()) {
      return 'No available formulas';
    }
    return 'Choose a formula';
  };

  getFormulaOptions = () => {
    const defaultOptions = [
      { value: null, label: this.defaultFormulaOptionText() },
    ];
    if (!isNull(this.state.availableFormulas)) {
      return [
        ...defaultOptions,
        ...this.state.availableFormulas.map((formula) => ({
          value: formula.id,
          label: formula.name,
        })),
      ];
    }
    return defaultOptions;
  };

  renderCalcValueOption = () => {
    const disabled = !this.props.config.calculated
      || this.loadingFormulaOptions()
      || this.noFormulasAvailable()
      || this.state.formulaLoadError;
    const formulaOptions = this.getFormulaOptions();
    return (
      <div className={`stepoptions-settinggroup${this.props.disabled ? ' disabled' : ''}`}>

        <label>
          <span>Formula <a href={`${this.props.masterTemplateId}#/powerups/formulas/editor`}>Create a formula</a></span>

          <Select
            value={formulaOptions.find(({ value }) => value === this.props.config.formula_id)}
            options={formulaOptions}
            isDisabled={disabled}
            onChange={this.onFormulaChanged}
            styles={{
              container: (provided) => ({
                ...provided,
                maxWidth: '15rem',
              }),
            }}
          />

        </label>
      </div>
    );
  };

  supportsAutoadvance = () => {
    // SupplementalRequirements don't "advance"
    return this.props.step.generic_type !== 'SupplementalRequirement';
  };

  renderAutoadvanceToggle = () => {
    if (!this.supportsAutoadvance()) { return; }

    return (
      <div className='stepoptions-nestedoption'>
        <SlidingToggle
          description='Automatically complete this step if payment amount is $0'
          disabled={this.props.disabled}
          onToggle={this.onAutoadvanceToggle}
          on={this.props.step.autoadvance}
          label='Auto-advance'
        />
      </div>
    );
  };

  render() {
    return (
      <>
        <div className='stepoptions-nestedoption stepoptions-fixedpayment'>
          {!this.props.config.calculated && this.renderAmountOption()}
          {this.props.config.calculated && this.renderCalcValueOption()}
        </div>

        {this.renderAutoadvanceToggle()}
      </>
    );
  }
}

FixedPaymentSettings.propTypes = {
  disabled: PropTypes.bool.isRequired,
  config: PropTypes.shape({
    formula_id: PropTypes.number,
    calculated: PropTypes.bool.isRequired,
    amount: PropTypes.string.isRequired,
    payable_id: PropTypes.number,
  }).isRequired,

  step: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string,
    position: PropTypes.number,
    autoadvance: PropTypes.bool,
    generic_type: PropTypes.string,
  }).isRequired,
  updateAttrs: PropTypes.func,
};

export default FixedPaymentSettings;
