import React, { useState, useEffect, useReducer, useMemo } from 'react';
import EntityActions from 'actions/entity-actions';
import LoadingSpinner from 'components/shared/loading-spinner';
import PropTypes from 'prop-types';
import { error as flashError } from 'components/shared/flash';
import EntitySelector from './entity-input/entity-selector';
import SelectedStandardEntity from './entity-input/selected-standard-entity';
import SelectedEsriEntity from './entity-input/selected-esri-entity';
import NewEntity from './entity-input/new-entity';


const fieldReducer = (state, action) => {
  switch (action.type) {
  case 'update':
    return { ...state, [action.field]: action.value };
  case 'reset':
    return action.newState;
  default:
    return state;
  }
};

const getEntityFields = (entity) => Object.fromEntries(
  entity.fields.map((field) => [field.slug, field.value]),
);


const EntityInput = ({
  fieldValue, initiatedFlowId, onChange,
  stepDatumId, entityTemplateFields, canCreateNewEntity, required, customEntity, disabled
}) => {
  const [fetchingEntity, setFetchingEntity] = useState(false);
  const [editing, setEditing] = useState(false);
  const [entityName, setEntityName] = useState();
  const [entity, setEntity] = useState();
  const [creatingNewEntity, setCreatingNewEntity] = useState(false);
  const [fieldState, dispatch] = useReducer(fieldReducer, {});
  const [creatingEntity, setCreatingEntity] = useState(false);

  useEffect(() => {
    const fieldValueAbsent = !fieldValue || fieldValue === '';
    if (!fieldValueAbsent) {
      setFetchingEntity(true);
      EntityActions.getEntity(fieldValue, initiatedFlowId)
        .done(setEntity)
        .fail(() => { flashError('Cannot load entity information. Refreshing may help.'); })
        .always(() => {
          setFetchingEntity(false);
        });
    } else {
      setEntity(null);
      dispatch({ type: 'reset', newState: {} });
    }
    setCreatingNewEntity(false);
    setEditing(false);
  }, [fieldValue]);

  // Update the entity name when the entity changes
  useEffect(() => {
    if (entity && !entityIsEsri) {
      // Might push this up into the above.
      setEntityName(entity.name);
      dispatch({ type: 'reset', newState: getEntityFields(entity) });
    }
  }, [entity, customEntity.type]);

  const entityIsEsri = useMemo(() => {
    return customEntity.type === 'EsriCustomEntity';
  }, [customEntity.type]);

  const onSelectEntityClick = (resultId) => (e) => {
    e.preventDefault();
    if (entityIsEsri) {
      doCreateNewEsriEntity(resultId);
    } else {
      onChange(resultId);
    }
  };

  const removeEntity = () => {
    onChange('');
  };

  const entityValues = () => {
    return {
      name: entityName,
      custom_entity_field_values: fieldState,
    };
  };

  const updateEntity = () => {
    EntityActions.update(entity.id, entityValues()).done((thisEntity) => {
      setEditing(false);
      setEntity(thisEntity);
    });
  };

  const doCreateNewEsriEntity = (esriUniqueId) => {
    EntityActions.createEsriEntity(esriUniqueId,
                                   initiatedFlowId,
                                   stepDatumId)
                 .done((res) => { return onChange(res.id); });
  };

  const doCreateNewEntity = () => {
    setCreatingEntity(true);
    EntityActions.createEntity(entityValues(), initiatedFlowId, stepDatumId)
                  .done((res) => { return onChange(res.id); })
                  .always(() => setCreatingEntity(false));
  };

  const suggestEdits = () => {
    const entityFieldMap = Object.fromEntries(
      entity.fields.map((field) => { return [field.slug, field]; }),
    );
    const suggestions = Object.entries(fieldState)
    // Filter out the values that haven't changed
    .filter(([slug, value]) => { return value !== entityFieldMap[slug].value; })
      .map(([slug, value]) => { return { field_id: entityFieldMap[slug].id, content: value }; });

    if (suggestions.length === 0) { return; }

    EntityActions.suggestEdits(entity.id, suggestions)
      .done(() => { return setEditing(false); });
  };

  const onCreateNewEntityClick = (e) => {
    e.preventDefault();

    setCreatingNewEntity(true);
  };

  const cancelNewEntity = () => {
    setCreatingNewEntity(false);
  };

  const edit = (e) => {
    e.preventDefault();

    setEditing(true);
  };

  const cancelEdit = (e) => {
    e.preventDefault();

    setEditing(false);
  };

  if (fetchingEntity) {
    return <LoadingSpinner size='medium' />;
  }

  if (entity) {
    if (entityIsEsri) {
      return (
        <SelectedEsriEntity
          entity={entity}
          removeEntity={removeEntity}
          customEntity={customEntity}
          disabled={disabled}
        />
      );
    }

    return (
      <SelectedStandardEntity
        entity={entity}
        editing={editing}
        entityName={entityName}
        setEntityName={setEntityName}
        customEntity={customEntity}
        removeEntity={removeEntity}
        cancelEdit={cancelEdit}
        updateEntity={updateEntity}
        suggestEdits={suggestEdits}
        edit={edit}
        fieldState={fieldState}
        dispatch={dispatch}
        disabled={disabled}
      />
    );
  }

  if (creatingNewEntity) {
    return (
      <NewEntity
        setEntityName={setEntityName}
        entityName={entityName}
        customEntity={customEntity}
        entityTemplateFields={entityTemplateFields}
        cancelNewEntity={cancelNewEntity}
        createEntity={doCreateNewEntity}
        creatingEntity={creatingEntity}
        fieldState={fieldState}
        dispatch={dispatch}
      />
    );
  }

  return (
    <EntitySelector
      disabled={disabled}
      initiatedFlowId={initiatedFlowId}
      customEntity={customEntity}
      onSelectEntityClick={onSelectEntityClick}
      required={required}
      stepDatumId={stepDatumId}
      canCreateNewEntity={canCreateNewEntity}
      createNewEntity={onCreateNewEntityClick}
      fieldValue={fieldValue}
      doCreateNewEntity={doCreateNewEntity}
    />
  );
};

EntityInput.propTypes = {
  canCreateNewEntity: PropTypes.bool,
  customEntity: PropTypes.shape({
    type: PropTypes.string.isRequired,
    esri_unique_id: PropTypes.string,
  }),
  entityTemplateFields: PropTypes.arrayOf(PropTypes.shape({})),
  fieldValue: PropTypes.string,
  initiatedFlowId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  stepDatumId: PropTypes.number.isRequired,
};

export default EntityInput;
