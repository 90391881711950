import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import PaymentStepActions from 'actions/payment-step-actions';
import AlertConfirm from 'modules/alert-confirm';
import { any } from 'underscore';

const ActionLinks = (props) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);

  function togglePaymentWaiver(e) {
    e.preventDefault();
    if (props.activeStep.payment_waived) {
      PaymentStepActions.unwaivePayment(dispatch, props.activeStep.id, props.stepType);
    } else {
      PaymentStepActions.waivePayment(dispatch, props.activeStep.id, props.stepType);
    }
  }

  function onRefundClick(e) {
    e.preventDefault();
    props.onRefund();
  }

  function onRemoveCheckClick(e) {
    e.preventDefault();
    PaymentStepActions.removeCheckPayment(dispatch, props.activeStep.id, props.stepType);
  }

  function onRemoveCashClick(e) {
    e.preventDefault();
    PaymentStepActions.removeCashPayment(dispatch, props.activeStep.id, props.stepType);
  }

  function paymentWaiverLink() {
    const text = props.activeStep.payment_waived ? 'Make payment required' : 'Waive payment';

    return (
      <li key='waivePayment'><a href='#' onClick={togglePaymentWaiver}>{text}</a></li>
    );
  }

  function removeCheckLink() {
    if (props.activeStep.check_paid_amount) {
      return (
        <li key='removecheck'><a href='#' onClick={onRemoveCheckClick}>Remove received check</a></li>
      );
    }
  }

  function removeCashLink() {
    if (props.activeStep.cash_paid_amount) {
      return (
        <li key='removecash'><a href='#' onClick={onRemoveCashClick}>Remove received cash</a></li>
      );
    }
  }

  const onCancelRefundRequest = () => {
    PaymentStepActions.cancelRefundRequest(props.nonRefundedCharge.id);
  };

  const onApproveRefundClick = () => {
    AlertConfirm.confirm('Issue refund', 'Are you sure you want to issue this refund?', () => {
      PaymentStepActions.approveRefundRequest(props.nonRefundedCharge.id);
    });
  };

  const currentUserIsAuthority = () => {
    return any(props.authorities, (a) => {
      return a.id === CityGrows.Server.currentUserId;
    });
  };

  const renderRefundApproveButton = () => {
    if (!currentUserIsAuthority()) { return; }

    return (
      <button type='submit' className='btn-link margin-left' onClick={onApproveRefundClick}>
        Approve refund
      </button>
    );
  };

  const refundPaymentLink = () => {
    if (!props.nonRefundedCharge) { return; }
    if (props.nonRefundedCharge.status === 'pending') { return; }
    if (props.nonRefundedCharge.status === 'refund_pending') { return; }

    if (props.nonRefundedCharge.refund_requested_at) {
      if (props.nonRefundedCharge.refund_authorized_at) {
        return <span>Refunded</span>;
      }
      return (
        <span className='text-semialert bold'>
          Refund authorization requested
          {renderRefundApproveButton()}
          <button type='button' className='btn-link margin-left' onClick={onCancelRefundRequest}>Cancel</button>
        </span>
      );
    }

    if (!props.refunding) {
      return (
        <li key='refundcharge'>
          <a
            onClick={onRefundClick}
            href='#'
            id='refundpaymentlink'
          >
            Refund payment
          </a>
        </li>
      );
    }
  };

  return (
    <div className='paymentmanager-actions'>
      <ul>
        {props.showPaymentWaive && paymentWaiverLink()}
        {removeCheckLink()}
        {removeCashLink()}
        {refundPaymentLink()}
      </ul>
    </div>
  );
};

ActionLinks.propTypes = {
  showPaymentWaive: PropTypes.bool.isRequired,
  activeStep: PropTypes.object.isRequired,
  stepType: PropTypes.string.isRequired,
  nonRefundedCharge: PropTypes.object,
};

export default ActionLinks;
