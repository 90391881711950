const AddressValidatorAPI = {
  validateAddress(address) {
    const url = '/address/validate';
    const request = $.ajax(url, {
      method: 'POST',
      dataType: 'json',
      data: {
        address
      }
    });

    return request;
  }
};

export default AddressValidatorAPI;
