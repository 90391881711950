import NProgress from 'nprogress';
import TeamAPI from '../apis/team-api';
import Flash from '../components/shared/flash';

const TeamActions = {
  update(teamId, attrs) {
    NProgress.start();

    return TeamAPI.update(teamId, attrs)
            .fail(() => { Flash.error(); })
            .always(NProgress.done);
  },

  onSlugSave(teamId, slug) {
    NProgress.start();

    return TeamAPI.update(teamId, {
      slug,
    })
    .done(() => { Flash.success('Team URL updated'); })
    .fail(() => { Flash.error(); })
    .always(NProgress.done);
  },

};

export default TeamActions;
