import React from "react";


const SubmissionFilter = ({
  changeFilterValue,
  filterState,
}) => {
  const onValueChange = (e) => {
    changeFilterValue(e.target.value);
  };

  return (
    <div className='metricsdashboard-filterselect'>
      <input
        className='number-field'
        type='number'
        onChange={onValueChange}
        placeholder='ID'
        value={filterState.value}
      />
    </div>
  );
};

export default SubmissionFilter;
