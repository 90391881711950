
var ActiveStepAPI = {
  show(id) {
    const url = `/active_steps/${id}`
    return $.ajax(url, {
      method: 'GET',
      dataType: 'json'
    })
  },

  update(id, attrs){
    const url = `/active_steps/${id}`
    return $.ajax(url, {
      method: 'PUT',
      dataType: 'json',
      data: {
        active_step: attrs
      }
    });
  },

  waivePayment(id){
    var url = '/active_steps/' + id + '/waive_payment';
    return $.ajax(url, {
      method: 'PUT',
      dataType: 'json'
    });
  },

  unwaivePayment(id){
    var url = '/active_steps/' + id + '/unwaive_payment';
    return $.ajax(url, {
      method: 'PUT',
      dataType: 'json'
    });
  },

  receiveCheck(id, paymentAttrs){
    var url = '/active_steps/' + id + '/receive_check';
    return $.ajax(url, {
      method: 'POST',
      dataType: 'json',
      data: {
        check_paid_amount: paymentAttrs.amount,
        check_number: paymentAttrs.checkNumber
      }
    });
  },

  receiveCash(id, amount){
    var url = '/active_steps/' + id + '/receive_cash';
    return $.ajax(url, {
      method: 'POST',
      dataType: 'json',
      data: {
        cash_paid_amount: amount
      }
    });
  },

  removeCheckPayment(id){
    var url = '/active_steps/' + id + '/remove_check_payment';
    return $.ajax(url, {
      method: 'DELETE',
      dataType: 'json'
    });
  },

  removeCashPayment(id){
    var url = '/active_steps/' + id + '/remove_cash_payment';
    return $.ajax(url, {
      method: 'DELETE',
      dataType: 'json'
    });
  }
};

export default ActiveStepAPI;
