import PropTypes from 'prop-types';
import React from 'react';

const LaunchingLoader = ({ flowTemplate }) => {
  return (
    <div>
      <h3 className='strong'>Launching</h3>
      <h2>{flowTemplate.name}</h2>

      <div className='progress'><div /></div>
    </div>
  );
};

LaunchingLoader.propTypes = {
  flowTemplate: PropTypes.object.isRequired,
};

export default LaunchingLoader;
