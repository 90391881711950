

export function show(outputId, includeFiles = false){
  const URL = `/issued_outputs/${outputId}`;

  return $.ajax(URL, {
    method: 'GET',
    dataType: 'json',
    data: {
      with_files: includeFiles
    }
  });
}

export default {
  show
};