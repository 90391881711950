import keyMirror from 'keymirror'

export default keyMirror({
  MANAGER_LOADED: null,
  SEARCH_RESULTS_CHANGED: null,
  MEMBERSHIP_REMOVED: null,
  MEMBERSHIP_ADDED: null,
  MEMBERSHIP_LIST: null,
  AUTHORITY_ADDED: null,
  AUTHORITY_REMOVED: null,
  PERMISSION_CHANGED: null,
}, 'USER_ACCESS');
