import { isNaN } from 'underscore';

const numberErrorMessage = (keys) => {
  if (!('minValue' in keys) && !('maxValue' in keys)) {
    switch (keys.numberType) {
    case 'integer':
      return 'must be an integer';
    case 'float':
      return 'must be a number e.g. 1.0, 2, 0.5';
    case 'money':
      return 'must be a number e.g. 1.00, 2, 0.50';
    }
  }

  const minValue = parseFloat(keys.minValue);
  const maxValue = parseFloat(keys.maxValue);

  if (!isNaN(minValue) && !isNaN(maxValue)) {
    return ` must be between ${keys.minValue} and ${keys.maxValue}`;
  }

  if (!isNaN(minValue)) {
    return ` must be greater than or equal to  ${keys.minValue}`;
  }

  if (!isNaN(maxValue)) {
    return ` must be less than or equal to  ${keys.maxValue}`;
  }
};

const ErrorMessages = {
  flash: {
    actionFailed: 'Oops, that didn\'t work.',
  },

  formValidation: {
    string: 'cannot be left blank',
    text: 'cannot be left blank',
    address: 'cannot be left blank',
    integer: numberErrorMessage({ numberType: 'integer' }),
    float: numberErrorMessage({ numberType: 'float' }),
    money: numberErrorMessage({ numberType: 'money' }),
    number: (keys) => { return numberErrorMessage(keys); },
    entity: 'cannot be left blank',
    email: 'must look like an email',
    phone: 'must be a valid phone number with an area code',
    multiple_choice: 'must be a valid option',
    checkboxes: (keys) => { return `must check at least ${keys.minNumChecks} boxes`; },
    date: 'must be a valid date',
    file: 'must be a valid file',
  },
};

export default ErrorMessages;
