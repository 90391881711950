import React from 'react';
import ReactDOM from 'react-dom';
import {
  isEmpty, isEqual, debounce, pick, extend, reject,
} from 'underscore';
import MediaQuery from 'react-responsive';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import AdvancedFilter from './shared/advanced-filter';
import AdvancedFilterSortBy from './shared/advanced-filter-sort-by';
import AdvancedFilterClearButton from './shared/advanced-filter-clear-button';
import { AdvancedFilterProvider } from './shared/advanced-filter/context';
import { getSubmissionDimensions } from './shared/advanced-filter/constants';
import LocalStorage from 'modules/local-storage';
import DashboardActions from 'actions/dashboard-actions';
import DashboardStore from 'stores/dashboard-store';
import AlertConfirm, { alert, confirm } from 'modules/alert-confirm';
import ErrorBoundary from 'components/shared/error-boundary';
import { parse, stringify } from 'query-string';
import FilterDefaults from './dashboard/filters/filter-defaults';
import MasterSearch from './dashboard/master-search';
import MainDashboard from './dashboard/main-dashboard';
import PrimaryTabs from './shared/primary-tabs';
import TagManager from './dashboard/tag-manager';

// this is used when you import to show you the files you just imported
const VALID_URL_PARAMS = [
  'importType',
  'importId',
  'completedStatus',
];

function fetchStateFromURL() {
  const updatedURLParams = pick(parse(window.location.search.substr(1)), ['key']);
  window.history.replaceState({}, 'Dashboard', `/processes?${stringify(updatedURLParams)}${window.location.hash}`);
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.loadItems = debounce(this.loadItems, 333);

    this.state = {
      // NULL state should only ever persist when
      // flows have not yet been loaded.
      currentFilterId: null,
      dashboardResizeCount: 0,
      filters: {},
      cachedState: {},
      initiatedFlows: null,
      masterSearchTerms: '',
      savedFilters: this.props.savedFilters,
      searchType: null,
      selectedItems: [],
      // Note that this matches the shape of react-select options
      sort: null,
      totalInitiatedFlowCount: null,
      rowsCount: null,
      showTagManager: false,
      tagManagerDeleteMode: false,
      tagNames: this.props.tagNames,
    };
  }

  componentDidMount() {
    if (this.props.defaultWasInaccessible) {
      alert('Default filter removed', 'Your default saved filter used a form that you no longer have permission to view. We have removed it as your default.');
    }

    // Bind first
    this.bindToUnload();
    this.bindToStore();

    fetchStateFromURL();
  }

  componentWillUnmount() {
    this.unBindToStore();
    LocalStorage.save(this.getLocalStorageKey(), this.getSyncState());

    // Remove beforeunload handler if it exists.
    if (this.__react_localstorage_beforeunload) {
      global.removeEventListener('beforeunload', this.__react_localstorage_beforeunload);
    }
  }

  bindToUnload = () => {
    // We won't get a componentWillUnmount event if we close the tab or refresh, so add a listener
    // and synchronously populate LS.

    if (LocalStorage.exists && global.addEventListener) {
      this.__react_localstorage_beforeunload = this.componentWillUnmount.bind(this);
      global.addEventListener('beforeunload', this.__react_localstorage_beforeunload);
    }
  };

  getSyncState = (inputState) => {
    const state = inputState || this.state;
    return { sort: state.sort, cachedState: state.cachedState };
  };

  changeCurrentFilter = (id) => {
    const [filters, currentFilterId] = loadSavedFilterState(id, this.state.savedFilters);
    const filtersOpen = getOpenState(filters);
    this.setState({ filters, currentFilterId, filtersOpen });
    if (filters.templateId) { DashboardActions.loadStepData(filters.templateId); }
    this.loadItems();
  };

  getLocalStorageKey = () => {
    // If local storage doesn't work...
    if (!window.localStorage) { return ''; }

    const params = parse(window.location.search.substr(1));
    if (params.key) { return params.key; }

    return this.createLocalStorageKey(params);
  };

  createLocalStorageKey = (params) => {
    const key = Date.now();
    const newParams = extend(params, { key });
    const paramString = stringify(newParams);
    window.history.replaceState({}, 'Dashboard', `/?${paramString}${window.location.hash}`);
    return key;
  };

  loadItems = () => {
    // This function is debounced on mount
    //
    const searchParams = {
      master_search_terms: this.state.masterSearchTerms,
      master_search_type: this.state.searchType,
    };
    DashboardActions.filteredSearch({ ...this.state.filters, ...searchParams });
  };

  cacheAndApplyAdvancedFilter = (filter, state) => {
    this.setState({ cachedState: state })
    return this.applyAdvancedFilter(filter)
  }

  applyAdvancedFilter = (filter) => {
    this.setState({filters: filter})
    return DashboardActions.filteredSearch(filter);
  };

  bindToStore = () => {
    DashboardStore.on('change', this.onStoreChange);
    DashboardStore.on('loadmore', this.onLoadMore);
  };

  unBindToStore = () => {
    DashboardStore.off('change', this.onStoreChange);
    DashboardStore.off('loadmore', this.onLoadMore);
  };

  onLoadMore = () => {
    const initiatedFlows = DashboardStore.getInitiatedFlows();
    const rowsCount = DashboardStore.getTotalLoadedInitiatedFlowCount() + DashboardStore.getNextBatchSize();
    this.setState({
      initiatedFlows,
      totalInitiatedFlowCount: DashboardStore.getTotalInitiatedFlowCount(),
      rowsCount,
    });
  };

  onStoreChange = () => {
    const initiatedFlows = DashboardStore.getInitiatedFlows();
    const rowsCount = DashboardStore.getTotalLoadedInitiatedFlowCount() + DashboardStore.getNextBatchSize();
    this.setState({
      initiatedFlows,
      totalInitiatedFlowCount: DashboardStore.getTotalInitiatedFlowCount(),
      rowsCount,
      selectedItems: [],
    });

    if (this.state.filters.templateId) {
      const stepData = DashboardStore.getStepData();

      // The store doesn't have the template data
      // when the page first loads. Don't unset it
      // from the component.
      //
      // This is only an issue because the template
      // state doesn't fully integrate with the store,
      // which should be fixed.
      //
      if (isEmpty(stepData)) {
        return;
      }

      if (stepData !== this.state.filters.stepData) {
        this.setState(({ filters }) => ({ filters: { ...filters, stepData } }));
      }
    }
  };

  masterSearchChanged = (newTerms, type) => {
    this.setState({
      masterSearchTerms: newTerms,
      searchType: type,
    }, this.loadItems);
  };

  flowBoxClicked = (item, e) => {
    const isOn = e.currentTarget.checked;

    this.setState(({ selectedItems }) => {
      let currentSelectedItems = [...selectedItems];
      if (isOn) {
        currentSelectedItems.push({
          id: item.dashboardable_id,
          type: item.dashboardable_type,
          unique_id: item.unique_id,
          master_template_id: item.master_template_id,
        });
      } else {
        currentSelectedItems = reject(currentSelectedItems, (i) => {
          return (i.id === item.dashboardable_id
            && i.type === item.dashboardable_type);
        });
      }
      return ({
        selectedItems: currentSelectedItems,
      });
    });
  };

  visibleItemsForAction = () => {
    const visibleItems = [];
    this.state.initiatedFlows.forEach((i) => {
      if (i !== null) {
        visibleItems.push(
          {
            id: i.dashboardable_id,
            type: i.dashboardable_type,
            master_template_id: i.master_template_id,
          },
        );
      }
    });

    return visibleItems;
  };

  handleHalt = () => {
    // Operate on all processes if none checked.
    // Otherwise operate on checked.
    //
    if (this.state.selectedItems.length === 0) {
      const items = this.visibleItemsForAction();

      confirm(`Halt ${items.length} processes?`, `Are you sure you want to halt these ${items.length} matching submissions?`, () => {
        DashboardActions.haltFlows(items);
      });
    } else {
      DashboardActions.haltFlows(this.state.selectedItems);
    }
  };

  handleDelete = () => {
    if (this.state.selectedItems.length === 0) {
      this.deleteMatching();
    } else {
      this.deleteSpecific();
    }
  };

  confirmDelete = (itemCount) => {
    const d = $.Deferred();

    const title = `Delete ${itemCount} submissions?`;
    const body = (
      <span>
        <span className='color-alert bold'>Warning: </span>
        Deleting a submission makes it unavailable to all users. This is not recommended.
      </span>
    );
    const challengeResponse = itemCount;
    const challenge = 'Type the number of submissions you\'re about to delete:';

    AlertConfirm.strongConfirm(title, body, challenge, challengeResponse)
                .done(d.resolve);

    return d;
  };

  deleteSpecific = () => {
    const items = this.state.selectedItems;
    this.confirmDelete(items.length)
        .done(() => { DashboardActions.deleteFlows(items); });
  };

  deleteMatching = () => {
    const items = this.state.initiatedFlows;
    this.confirmDelete(items.length)
        .done(() => { DashboardActions.deleteMatchingFlows(items.length); });
  };

  onActionClicked = (action) => {
    if (!this.state.initiatedFlows) { return; }

    if (this.state.totalInitiatedFlowCount === 0) {
      alert(`Nothing to ${action}`, 'No submissions match your search criteria. Try changing the filters.');
      return;
    }

    switch (action) {
    case 'assign':
      return this.handleAssign();
    case 'unassign':
      return this.handleUnassign();
    case 'halt':
      return this.handleHalt();
    case 'delete':
      return this.handleDelete();
    case 'export':
      return this.handleExport();
    case 'add tag':
      return this.handleAddTag();
    case 'remove tag':
      return this.handleRemoveTag();
    default:
      throw new Error(`Unexpected action ${action}`);
    }
  };

  handleAssign = () => {
    if (this.state.selectedItems.length === 0) {
      const items = this.visibleItemsForAction();

      confirm(`Self assign ${items.length} submissions?`, `Are you sure you want to self assign these ${items.length} matching submissions?`, () => {
        DashboardActions.assignFlows(items)
        .done(this.loadItems);
      });
    } else {
      DashboardActions.assignFlows(this.state.selectedItems)
      .done(this.loadItems);
    }
  };

  handleUnassign = () => {
    if (this.state.selectedItems.length === 0) {
      const items = this.visibleItemsForAction();

      confirm(`Unassign ${items.length} submissions?`, `Are you sure you want to unassign these ${items.length} matching submissions?`, () => {
        DashboardActions.unassignFlows(items)
          .done(this.loadItems);
      });
    } else {
      DashboardActions.unassignFlows(this.state.selectedItems)
        .done(this.loadItems);
    }
  };

  handleExport = () => {
    if (this.state.selectedItems.length === 0) {
      DashboardActions.filteredExport()
        .done(() => { AlertConfirm.alert('Export queued.', "You will be emailed when your export completes. Finished exports can be found on the <a href='/exports'>Exports</a> page."); });
    } else {
      DashboardActions.exportSpecific(this.state.selectedItems)
        .done(() => { AlertConfirm.alert('Export queued.', "You will be emailed when your export completes. Finished exports can be found on the <a href='/exports'>Exports</a> page."); });
    }
  };

  toggleTagManager = () => {
    this.setState(({ showTagManager }) => ({
      showTagManager: !showTagManager,
    }));
  };

  handleAddTag = () => {
    this.setState(() => ({
      tagManagerDeleteMode: false,
    }));
    this.toggleTagManager();
  };

  handleRemoveTag = () => {
    this.setState(() => ({
      tagManagerDeleteMode: true,
    }));
    this.toggleTagManager();
  };

  ontagNamesUpdate = (tagNames) => {
    this.setState({ tagNames });
  };

  sortChanged = (filters) => {
    this.applyAdvancedFilter(filters);
  };

  renderSelectedProcessesMessage() {
    if (this.state.initiatedFlows === null) {
      return 'Loading...';
    }

    if (this.state.selectedItems.length === 0) {
      return `(${this.state.totalInitiatedFlowCount} matching)`;
    }

    return `(${this.state.selectedItems.length} selected)`;
  }

  renderTagManager() {
    if (this.state.showTagManager) {
      const items = this.state.selectedItems.length === 0 ? this.visibleItemsForAction() : this.state.selectedItems;
      return (
        <TagManager
          selectedItems={items}
          onTagCreate={this.ontagNamesUpdate}
          onClose={this.toggleTagManager}
          tagNames={this.state.tagNames}
          deleteMode={this.state.tagManagerDeleteMode}
        />
      );
    }
  }

  renderProcessesTab() {
    const storedState = LocalStorage.fetch(this.getLocalStorageKey()) || {};
    const filterParams = pick(CityGrows.Server.params, VALID_URL_PARAMS);
    return (
      <ErrorBoundary>
        <MasterSearchWrapper>
          <MasterSearch onChange={this.masterSearchChanged} />
        </MasterSearchWrapper>

        <AdvancedFilterProvider initial={{ availableFilters: getSubmissionDimensions(), activeFilters: [], currentFilter: {}, savedFilters: this.props.savedFilters, defaultFilters: ['assigned_to_me', 'completed_status'], triggerRefresh: 0 }}>
          <div className='padded border-bottom'>
            <AdvancedFilter
              onFilterApply={this.cacheAndApplyAdvancedFilter}
              kind='submission'
              menuAvailable
              defaultFilter={this.props.defaultFilter}
              previousState={ storedState?.cachedState }
              filterParams={filterParams}
            />
          </div>

          <div className='dashboard-maincontent'>
            <div className='dashboard-actionbar cf'>
              <div className='left'>
                <h1>Submissions</h1> <span className='dashboard-processcount'>{this.renderSelectedProcessesMessage()}</span>
                <AdvancedFilterClearButton
                  className='margin-left-less btn-thirdary inverted'
                  defaultFilterIds={['assigned_to_me', 'completed_status']}
                >
                  <span className='icon icon-x-white smaller margin-right' />Clear Filters
                </AdvancedFilterClearButton>
              </div>

              <div className='right'>
                <ul>
                  <li>
                    <button type='button' className='unstyled' onClick={() => { this.onActionClicked('add tag'); }}>
                      <FontAwesomeIcon icon={icon({ name: 'tags' })} />
                      <span>Add tags</span>
                    </button>
                  </li>
                  <li>
                    <button type='button' className='unstyled' onClick={() => { this.onActionClicked('remove tag'); }}>
                      <FontAwesomeIcon icon={icon({ name: 'tag', style: 'regular' })} />
                      <span>Remove tags</span>
                    </button>
                  </li>
                  <li>
                    <button type='button' className='unstyled' onClick={() => { this.onActionClicked('export'); }}>
                      <FontAwesomeIcon icon={icon({ name: 'file-export' })} />
                      <span>Export</span>
                    </button>
                  </li>
                  <li>
                    <button type='button' className='unstyled' onClick={() => { this.onActionClicked('assign'); }}>
                      <FontAwesomeIcon icon={icon({ name: 'user-plus' })} />
                      <span>Self assign</span>
                    </button>
                  </li>
                  <li>
                    <button type='button' className='unstyled' onClick={() => { this.onActionClicked('unassign'); }}>
                      <FontAwesomeIcon icon={icon({ name: 'user-minus' })} />
                      <span>Unassign</span>
                    </button>
                  </li>
                  <li>
                    <button type='button' className='unstyled' onClick={() => { this.onActionClicked('halt'); }}>
                      <FontAwesomeIcon icon={icon({ name: 'hand' })} />
                      <span>Halt</span>
                    </button>
                  </li>
                  <li>
                    <button type='button' className='unstyled' onClick={() => { this.onActionClicked('delete'); }}>
                    <FontAwesomeIcon icon={icon({ name: 'trash' })} />
                      <span>Delete</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <MainDashboard
              hasTemplates={this.props.hasTemplates}
              initiatedFlows={this.state.initiatedFlows}
              rowsCount={this.state.rowsCount}
              onFlowBoxClick={this.flowBoxClicked}
              selectedItems={this.state.selectedItems}
              resizeCount={this.state.dashboardResizeCount}
              noTeams={this.props.teams.length === 0}
              activeOrder={this.state.filters.sort}
              sortingFn={this.sortChanged}
            />
          </div>
        </AdvancedFilterProvider>
      </ErrorBoundary>
    );
  }

  render() {
    return (
      <>
        <PrimaryTabs />
        <div id='tabheader-body' className='dashboard-borderwrap'>
          {this.renderProcessesTab()}
        </div>
        {this.renderTagManager()}
      </>
    );
  }
}

Dashboard.propTypes = {
  defaultFilterId: PropTypes.number,
  defaultWasInaccessible: PropTypes.bool,
  hasTemplates: PropTypes.bool.isRequired,
  savedFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userId: PropTypes.number.isRequired,
  tagNames: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Dashboard.defaultProps = {
  defaultWasInaccessible: false,
};

const MasterSearchWrapper = ({ children }) => (
  <MediaQuery minWidth={769}>
    {(matches) => {
      return (
        matches
          ? ReactDOM.createPortal(children, document.getElementById('search-box'))
          : ReactDOM.createPortal(
            <div>
              {children}
            </div>,
            document.getElementById('search-box-mobile'),
          )
      );
    }}
  </MediaQuery>
);

MasterSearchWrapper.propTypes = {
  children: PropTypes.node,
};

export default Dashboard;
