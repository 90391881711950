import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InstructionsManager from 'components/shared/instructions-manager';
import StepLogic from 'components/shared/step-logic';
import TemplateManagerStore from 'stores/template-manager-store';
import getTemplateVariables from 'modules/template-variables';
import FlowStepActions from 'actions/flow-step-actions';
import AdvancedLabel from 'components/shared/advanced-label';

const SectionSettings = ({
  section,
  flowTemplate,
  disabled,
  logicDisabled,
}) => {
  const hasLogic = ['Step'].includes(section.sectionable_type);
  const [isEditorActive, setIsEditorActive] = useState(false);
  const [addingLogic, setAddingLogic] = useState(false);
  const onInstructionsSave = (value) => {
    FlowStepActions.updateSection(section.id, { instructions: value })
      .done(() => {
        setIsEditorActive(false);
      });
  };

  const onClose = (e) => {
    e.stopPropagation();
    setIsEditorActive(false);
  };

  const renderInstructions = () => {
    let content = (
      <div className='instruction-container' onClick={() => setIsEditorActive(true)}>
        <InstructionsManager
          instructions={section.instructions}
          templateVariables={templateVariables()}
          isActive={false}
          key='inactive-intruction-manager'
        />
      </div>
    );
    if (disabled) {
      if (section.instructions) {
        content = (
          <span className='simplerich' dangerouslySetInnerHTML={{ __html: section.instructions }} />
        );
      } else {
        content = (
          <strong>
            Instructions disabled.
          </strong>
        );
      }
    } else if (isEditorActive) {
      content = (
        <div className='instruction-container'>
          <InstructionsManager
            onClose={onClose}
            onSave={onInstructionsSave}
            instructions={section.instructions}
            templateVariables={templateVariables()}
            isActive
            key='active-intruction-manager'
          />
        </div>
      );
    }
    return (

      <tr>
        <td>
          <AdvancedLabel
            label='Instructions'
          />
        </td>
        <td>
          {content}
        </td>
      </tr>
    );
  };

  const renderLogicConfig = () => {
    if (!hasLogic) { return; }
    let content = (
      <button
        type='button'
        className='flex btn-link secondary'
        onClick={() => setAddingLogic(true)}
      >
        Add logic
      </button>
    );
    if (logicDisabled) {
      content = (
        <span>
          Logic is disabled. This section cannot be conditional because a field in this section is used in a formula.
        </span>
      );
    } else if (section.logic_config || addingLogic) {
      content = (
        <StepLogic
          logicConfig={section.logic_config}
          disabled={disabled}
          logicableId={section.id}
          logicableType='StepDatum::Section'
          flowTemplate={flowTemplate}
          onLogicOff={() => setAddingLogic(false)}
          showTitle={false}
        />
      );
    }
    return (
      <tr className='with-divider'>
        <td>
          <AdvancedLabel
            label='Logic'
          />
        </td>
        <td>
          {content}
        </td>
      </tr>
    );
  };

  const templateVariables = () => {
    if (section.sectionable_type === 'Step') {
      return getTemplateVariables(flowTemplate.formulas, TemplateManagerStore.getStepData(), 'Instructions');
    }

    // Not allowing variables to be used in Surveys or Supplemental DataCollection
    return [];
  };
  return (
    <div className='sectionsettings'>
      <table>
        <tbody>
          {renderInstructions()}
          {renderLogicConfig()}
        </tbody>
      </table>
    </div>
  );
};

SectionSettings.propTypes = {
  section: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sectionable_type: PropTypes.string.isRequired,
    logic_config: PropTypes.shape({}),
    instructions: PropTypes.string,
  }).isRequired,
  flowTemplate: PropTypes.shape({
    formulas: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  disabled: PropTypes.bool,
  logicDisabled: PropTypes.bool,
  hasLogic: PropTypes.bool.isRequired,
};

export default SectionSettings;
