import React from 'react';
import renderer from 'modules/renderer';
import ReactDOM from 'react-dom'
import { isFunction } from 'underscore';
import AlertConfirm from '../components/module_views/alert-confirm';
import Shield from './shield';
import Logger from './logger';

let el;

export function alert(title, body, onConfirm) {
  const onConfirmWithClose = function () {
    Shield.off();

    if (isFunction(onConfirm)) {
      onConfirm();
    }
    unmount();
  };

  Shield.on();

  el = renderer(
    <AlertConfirm title={title} body={body} onConfirm={onConfirmWithClose} type='alert' />,
    document.getElementById('alertconfirm-container'),
  );
}

export function confirm(title, body, onConfirm = () => {}, onCancel = () => {}, klass = null) {
  const promise = $.Deferred();

  const onConfirmWithClose = () => {
    Shield.off();

    try {
      promise.resolve();
      onConfirm();
    } catch (e) {
      Logger.log(e);
    }

    unmount();
  };

  const onCancelWithClose = () => {
    Shield.off();

    try {
      promise.reject();
      onCancel();
    } catch (e) {
      Logger.log(e);
    }

    unmount();
  };

  Shield.on();

  el = renderer(
    <AlertConfirm
      title={title}
      body={body}
      onConfirm={onConfirmWithClose}
      onCancel={onCancelWithClose}
      type='confirm'
      klass={klass}
    />,
    document.getElementById('alertconfirm-container'),
  );

  return promise;
}

function strongConfirm(title, body, challenge, challengeAnswer) {
  const promise = $.Deferred();

  const onConfirmWithClose = function () {
    Shield.off();
    promise.resolve();
    unmount();
  };

  const onCancelWithClose = function () {
    Shield.off();
    promise.reject();
    unmount();
  };

  Shield.on();

  el = renderer(
    <AlertConfirm
      title={title}
      body={body}
      onConfirm={onConfirmWithClose}
      onCancel={onCancelWithClose}
      challenge={challenge}
      challengeAnswer={challengeAnswer}
      type='confirm'
    />,
    document.getElementById('alertconfirm-container'),
  );

  return promise;
}

function unmount() {
  ReactDOM.unmountComponentAtNode(document.getElementById('alertconfirm-container'));
}

export default {
  alert,
  confirm,
  strongConfirm,
};
