import React from 'react';
import { isEmpty } from 'underscore';
import ExportsAPI from 'apis/exports-api';
import TimeAgo from 'react-timeago';
import XClose from 'components/shared/x-close';

class Exports extends React.Component {
  constructor(props) {
    super(props);

    this.state = { exports: this.props.exports };
  }

  componentDidMount() {
    this.interval = setInterval(this.fetchUpdates, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  loadExports(exports) {
    this.setState({ exports });
  }

  fetchUpdates = () => {
    ExportsAPI.fetch().done((res) => { this.loadExports(res.exports); });
  };

  getFileLink(id, status) {
    if (status == 'finished') {
      return (
        <a className='btn-primary' href={`exports/${id}`}>Download</a>
      );
    }

    return '-';
  }

  statusColor(status) {
    switch (status) {
    case 'running':
      return 'text-semialert';
    case 'finished':
      return 'text-success';
    case 'failed':
      return 'text-alert';
    }
  }

  renderExportRow = (xport) => {
    return (
      <tr key={xport.id}>
        <td>{xport.export_type}</td>
        <td scope='row'>{xport.name}</td>
        <td>{this.getFileLink(xport.id, xport.status)}</td>
        <td className={`${this.statusColor(xport.status)} bold`}>{xport.status}</td>
        <td><TimeAgo date={xport.created_at} /></td>
      </tr>
    );
  };

  remove = (id) => {
    ExportsAPI.remove(id).done(this.fetchUpdates);
  };

  // Not being used for now, but might be used for a removal column later
  removalLink(status, id) {
    if (status == 'running') {
      return '';
    }

    return <XClose onClick={() => { this.remove(id); }} label='remove' />
  }

  renderExportsTable(exports) {
    if (exports) {
      return (
        <table className='datatable middle'>
          <thead>
            <tr>
              <th width='115' scope='col'>Export type</th>
              <th scope='col'>Name</th>
              <th scope='col'>File</th>
              <th scope='col'>Status</th>
              <th scope='col'>Date</th>
            </tr>
          </thead>
          <tbody>
            {exports.map(this.renderExportRow)}
          </tbody>
        </table>
      );
    }
  }

  renderExportTables() {
    if (isEmpty(this.state.exports)) {
      return (
        <div>
          <p>This is where data export files that you create are stored.</p>
        </div>
      );
    }

    return this.renderExportsTable(this.state.exports);
  }

  render() {
    return (
      <div style={{ padding: '2rem' }}>
        {this.renderExportTables()}
      </div>
    );
  }
}

export default Exports;
