import React from 'react';
import renderer from 'modules/renderer';
import IssuedOutput from 'components/issued-output';
import CommonProviders from 'components/common-providers';

export const show = () => {
  renderer(
    (
      <CommonProviders>
        <IssuedOutput issuedOutput={CityGrows.Server.issuedOutput} />
      </CommonProviders>
    ), document.getElementById('issuedoutput'),
  );
};

export default {
  show,
};
