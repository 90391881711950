import React from 'react';

import { useAdvancedFilterContext, useAdvancedFilterDispatchContext } from 'components/shared/advanced-filter/context';

const AdvancedFilterClearButton = ({
  className,
  children,
}) => {
  const state = useAdvancedFilterContext();
  const dispatch = useAdvancedFilterDispatchContext();

  const onClick = () => {
    dispatch({
      type: 'loadDefault',
    });
  };

  if (state.isDefault) { return null; }

  return (
    <button type='button'
      className={className}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default AdvancedFilterClearButton;
