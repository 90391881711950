import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { colorBackground, colorTextMedium } from 'theme/colors';
import { disc as discStyle } from 'theme/discs';
import Popdown from 'components/shared/popdown';
import ListOfPeople from 'components/shared/list-of-people';
import useOnclickOutside from 'react-cool-onclickoutside';
import { NUM_TO_SHOW } from '../disc-group';

const ManyDisc = ({ groupMembers }) => {
  const count = groupMembers.length;
  const [showMembers, setShowMembers] = useState(false);
  const membersContentRef = useRef();
  const elForAnchor = useRef();

  useOnclickOutside(() => {
    setShowMembers(false);
  }, { refs: [elForAnchor] });

  const onShowMoreClick = () => {
    setShowMembers(true);
  };

  return (
    <span
      onClick={onShowMoreClick}
      ref={elForAnchor}
      css={css`
        ${discStyle}
        background-color: ${colorBackground};
        color: ${colorTextMedium};
        border:2px solid #fff;
        margin-left:-15px;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      `}
    >
      {count - NUM_TO_SHOW}

      { showMembers && (
        <Popdown anchorRef={elForAnchor}>
          <div className='margins' ref={membersContentRef}>
            <ListOfPeople users={groupMembers} />
          </div>
        </Popdown>
      )}
    </span>
  );
};

export default ManyDisc;
