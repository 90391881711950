import React, { useState, useRef, useContext } from 'react';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import PropTypes from 'prop-types';
import StepValidator from 'modules/active-step-validator';
import FlowConstants from 'constants/initiated-flow-constants';
import { CSSTransition } from 'react-transition-group';
import AddlOptions from './if_control_nav/addl-options';
import Support from './if_control_nav/support';
import AdvancementHandler from './if_control_nav/advancement-handler';
import InitiatedFlowAPI from '../../../apis/initiated-flow-api';
import { some } from 'underscore';

const IFControlNav = (props) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);
  const {
    initiatedFlow, activeStep, step, refreshing,
  } = props.initiatedFlowState;
  const submitButton = useRef();
  const [supportOpen, setSupportOpen] = useState(false);
  const [advancing, setAdvancing] = useState(false);

  function hasUnfinishedSubprocesses() {
    return some(initiatedFlow.subprocess_requirements, (sub) => { return !sub.completed && !sub.waived_at });
  }

  function readyToAdvance() {
    return !initiatedFlow.halted
      && !initiatedFlow.finished_at
      && StepValidator.valid(activeStep);
  }

  function renderAddlOptions() { 
    return (
      <AddlOptions
        className='ifcontrolnav-addloptions'
        admin={props.admin}
        initiatedFlow={initiatedFlow}
      />
    );
  }

  function nextStepButtonContent() {
    const spinner = <div className='progress white'><div>Loading</div></div>;
    return refreshing ? spinner : nextStepButtonText();
  }

  function nextStepButtonText() {
    if (initiatedFlow.halted) {
      return 'Submission halted';
    }

    if (initiatedFlow.finished_at) {
      if (isSubprocess()) {
        return (
          <span><span className='mobile-hidden'>Sub-submission complete. </span>Return to parent submission.</span>
        );
      }

      return (
        <span>This submission was completed</span>
      );
    }

    if (step.is_last) { return 'Complete this submission'; }

    if (props.taskView) { return 'Complete Step'}

    return 'Go to next step >';
  }

  function onLastStepOfIncompleteProcess() {
    const onLastStep = initiatedFlow.steps.length === step.position;
    const onCurrentStep = initiatedFlow.current_step.position === step.position;
    return onLastStep && !onCurrentStep;
  }

  function userCanAdvance() {
    return ((props.admin
      || initiatedFlow.current_step.external
      || step.position < initiatedFlow.current_step.position)
        && !onLastStepOfIncompleteProcess());
  }

  function handleAdvanceAttempt() {
    dispatch({
      type: FlowConstants.ADVANCE_ATTEMPTED,
    });
  }

  function requiredActions() {
    return activeStep.required_actions.map((action) => {
      const names = (action.users.length
        ? action.users.map((user) => { return user.name; }).join(', ')
        : 'An admin');

      return {
        solution: action.solution,
        names,
      };
    });
  }

  function taskViewSubmit() {
    return InitiatedFlowAPI.doNextStep(initiatedFlow.id, activeStep.step_id)
      .done(() => {
        props.onAdvance();
      })
  }

  function onTaskViewSubmit() {
    // Prevent advanceattempts
    if (advancing) { return; }

    const { admin } = props;

    const advancer = new AdvancementHandler(admin, step, initiatedFlow.current_step.id, activeStep);

    advancer.attemptAdvance()
            .done((willAdvance) => {
              if (willAdvance) {
                setAdvancing(true);
                taskViewSubmit();
              }
            });
  }

  function onSubmitClick(e) {
    e.preventDefault();

    // Prevent advanceattempts
    if (advancing) { return; }

    const { admin } = props;

    const advancer = new AdvancementHandler(admin, step, initiatedFlow.current_step.id, activeStep);

    advancer.attemptAdvance()
            .done((willAdvance) => {
              if (willAdvance) {
                setAdvancing(true);
                submitButton.current.click();
              }
            });
  }

  function onSupportButtonClick(e) {
    e.preventDefault();
    setSupportOpen(!supportOpen);
  }

  function onSupportMessageSent() {
    setTimeout(closeSupportWindow.bind(this), 2000);
  }

  function closeSupportWindow() {
    setSupportOpen(false);
  }

  function renderSupportModal() {
    if (supportOpen) {
      return (
        <Support
          masterTemplateId={props.masterTemplateId}
          masterTemplateSlug={props.masterTemplateSlug}
          teamSlug={props.teamSlug}
          onMessageSent={onSupportMessageSent}
          onOutsideClick={closeSupportWindow}
          onOpenDiscussionClick={props.onOpenDiscussionClick}
        />
      );
    }
  }

  function renderSupportButton() {
    return <a id='supportbutton' onClick={onSupportButtonClick} className='btn-thirdary left' href='#'>Support</a>;
  }

  function renderButton() {
    if(props.taskView) {
      return (
        <button className='btn btn-primary' onClick={onTaskViewSubmit}>{nextStepButtonContent()}</button>
      )
    }

    return (
      <span>
        <a id='advancebutton' href='#' onClick={onSubmitClick} className='btn-primary'>
          {nextStepButtonContent()}
        </a>

        {/* TODO: why isn't this a window.location.href = assignment up above? */}
        <a
          ref={submitButton}
          className='hidden'
          data-method='post'
          href={`/initiated_flows/${initiatedFlow.id}/do_next_step?step_id=${activeStep.step_id}`}
        />
      </span>
    )
  }

  function renderSubmitButton() {
    if (userCanAdvance() && step.is_last && props.taskView && initiatedFlow.unfinished_requirements) {
      return (
        <div className='text-alert bold'>
          This submission has incomplete additional requirements. <a className='mytasks-submissionlink btn-link' href={`initiated_flows/${initiatedFlow.id}`} target='_blank'>View Submission</a>
        </div>
      )
    }

    if (userCanAdvance() && step.is_last && props.taskView && hasUnfinishedSubprocesses()) {
      return (
        <div className='text-alert bold'>
          This submission has incomplete sub-submissions. <a className='mytasks-submissionlink btn-link' href={`initiated_flows/${initiatedFlow.id}`} target='_blank'>View Submission</a>
        </div>
      )
    }

    if (userCanAdvance() && readyToAdvance()) {
      return renderButton()
    }

    if (userCanAdvance() && isCompletedSubprocess()) {
      return (
        <a id='returntoparentbutton' href={`/initiated_flows/${initiatedFlow.parent_process.id}`} className='btn-primary'>
          {nextStepButtonContent()}
        </a>
      );
    }

    return <span id='submitbutton' className='btn-primary disabled' onClick={handleAdvanceAttempt}>{nextStepButtonContent()}</span>;
  }

  function isSubprocess() {
    return !!initiatedFlow.parent_process.id;
  }

  function isCompletedSubprocess() {
    return initiatedFlow.finished_at && isSubprocess();
  }

  function renderCurrentStepButton() {
    return (
      <span>
        <a href={`/initiated_flows/${initiatedFlow.id}`} className='btn-primary'>
          Go to Current Step
        </a>
      </span>
    );
  }

  const waitingOnConditional = (
    !props.admin
      && initiatedFlow.current_step.external
      && props.initiatedFlowState.advanceAttempted
      && !!requiredActions().length
  );

  function renderButtonExtra() {
    return (
      <>
        <CSSTransition
          classNames='waiting-on'
          timeout={618}
          in={waitingOnConditional}
          mountOnEnter
          unmountOnExit
        >
          <span className='ifcontrolnav-waiting-on popup noshadow'>
            <span className='popup-nub-left' />
            <div>
              <i className='icon-x-red smaller' />
              <strong className='text-smaller'><span className='capitalize'>{requiredActions()[0]?.names}</span> must {requiredActions[0]?.solution}</strong>
            </div>
          </span>
        </CSSTransition>
      </>
    )
  }

  if (props.taskView) { 
    return (
      <div className='ifcontrolnav align-center padded'>
        {renderSubmitButton()}
        {renderButtonExtra()}
      </div>
    )
  }

  return (
    <>
      <div className='ifcontrolnav'>
        <div className='float-left mobile-hidden'>
          {renderSupportButton()}
        </div>
        <div className='float-right'>
          {renderAddlOptions()}
          <span className='margin-left-more'>
          {step.generic_type === 'Step' ? renderSubmitButton() : renderCurrentStepButton()}
          </span>

          {renderButtonExtra()}
        </div>
      </div>

      {renderSupportModal()}
    </>
  );
};

IFControlNav.propTypes = {
  admin: PropTypes.bool,
  initiatedFlow: PropTypes.object,
  step: PropTypes.object,
  masterTemplateId: PropTypes.number,
  masterTemplateSlug: PropTypes.string,
  initialStepInstance: PropTypes.object,
  teamSlug: PropTypes.string,
  onOpenDiscussionClick: PropTypes.func,
  taskView: PropTypes.bool,
};

export default IFControlNav;
