import React, { useState } from 'react';
import CloseIfOutsideWrapper from 'components/shared/close-if-outside-wrapper';
import MediaQuery from 'react-responsive';
import UserMenu from './user-menu';

const PublicNavMenu = (props) => {
  const [activeClass, setActiveClass] = useState('');

  function onClose() {
    setActiveClass('');
  }

  function toggleHamburger() {
    const newActiveClass = activeClass == '' ? 'is-active' : '';
    setActiveClass(newActiveClass);
  }

  function renderDashboardLink() {
    return <a className='dashboard-link' href='/'>Dashboard</a>;
  }

  function renderSignInLink(mobile) {
    const className = `dashboard-link ${mobile ? 'color-standout' : ''}`;
    return (
      <a
        href='/users/sign_in?public=true'
        className={className}
      >
        Sign in
      </a>
    );
  }

  function renderSignUpLink() {
    return (
      <a className='dashboard-link' href='/users/sign_up?public=true'>Create a ClearForms account</a>
    );
  }

  function renderMenuLinks() {
    if (props.currentUser) {
      return [renderDashboardLink()];
    }
    return [renderSignUpLink()];
  }

  function renderMenuItems() {
    return renderMenuLinks().map((link, idx) => {
      return <li className='menulink' key={idx}>{link}</li>;
    });
  }

  function renderUserMenu() {
    if (props.currentUser) {
      return <UserMenu currentUser={props.currentUser} />;
    }
    return <li className='menulink'>{renderSignInLink()}</li>;
  }

  function renderMobileMenuPopup() {
    return (
      <div className='popup'>
        <ul className='popup-listmenu'>
          {renderMenuItems()}
        </ul>
      </div>
    );
  }

  function renderMobileMenu() {
    return (
      <ul className='nav-menu'>
        <li className={`hamburger orgheader-mobilemenu ${activeClass} margin-right`} type='button' onClick={toggleHamburger}>
          <CloseIfOutsideWrapper onClose={onClose}>
            <span className='hamburger-box'>
              <span className='hamburger-inner'>
                {renderMobileMenuPopup()}
              </span>
            </span>
          </CloseIfOutsideWrapper>
        </li>
        {renderUserMenu()}
      </ul>

    );
  }

  function renderDesktopMenu() {
    return (
      <ul className='orgheader-desktopmenu nav-menu'>
        {renderMenuItems()}
        {renderUserMenu()}
      </ul>
    );
  }

  function renderMenu() {
    return (
      <MediaQuery minWidth={769}>
        {(matches) => {
          if (matches) { return renderDesktopMenu(); }
          return renderMobileMenu();
        }}
      </MediaQuery>
    );
  }

  return (
    <div className={`nav-navlinks ${props.light ? 'light' : ''}`}>
      {renderMenu()}
    </div>
  );
};

export default PublicNavMenu;
