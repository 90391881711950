const _shieldEnabled = false;
let originalOverflow;

function addShield() {
  let $shield = $('#shield');
  if ($shield.length === 0) {
    $shield = $('<div id="shield"></div>');
    $('body').append($shield);
  }

  $shield.addClass('visible');

  const $body = $('body');
  if (!$body.hasClass('shield-visable')) {
    $body.addClass('shield-visable');
  }
}

function removeShield() {
  const $shield = $('#shield');
  $('body').removeClass('shield-visable');
  $shield.removeClass('visible');
}

function toggleShield() {
  _shieldEnabled ? removeShield() : addShield();
}

export default {
  on: addShield,
  off: removeShield,
  toggle: toggleShield,
};
