import React from 'react';
import renderer from 'modules/renderer';
import CommonProviders from 'components/common-providers';
import Exports from '../components/exports';
import PrimaryTabs from '../components/shared/primary-tabs';

function index() {
  renderer(<PrimaryTabs />, document.getElementById('tabs'));
  renderer(
    (
      <CommonProviders>
        <Exports exports={CityGrows.Server.exportsData} />
      </CommonProviders>
    ), document.getElementById('exports'),
  );
}

export default {
  index,
};
