import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import PropTypes from 'prop-types';
import AdvancedLabel from 'components/shared/advanced-label';
import NoNegativesInput from 'components/shared/no-negatives-input';

const TextSettings = ({ maxLength, onDataChanged }) => {
  const [maxLengthValue, setMaxLengthValue] = useState(maxLength);

  const onMaxLengthChanged = (value) => {
    setMaxLengthValue(value);
    // If its 0 we dont want to send it because we assume they are editing and it shouldnt be saved
    if (value === 0) { return; }
    onDataChanged({ max_length: value });
  };

  const onMaxLengthClear = (e) => {
    e.preventDefault();
    setMaxLengthValue('');
    onDataChanged({ max_length: '' });
  };

  return (
    <tr>
      <td>
        <AdvancedLabel
          label='Max Length'
        />
      </td>
      <td>
        <div className='inline-block'>
          <NoNegativesInput
            type='number'
            min='1'
            klass='fieldmanager-maxlength'
            onChange={onMaxLengthChanged}
            value={maxLengthValue}
            name='maxlength'
          />
        </div>
        <FontAwesomeIcon
          icon={icon({ name: 'trash' })}
          className='clear-button margin-left'
          onClick={onMaxLengthClear}
          title='Clear Max Length'
        />
      </td>
    </tr>
  );
};

TextSettings.propTypes = {
  maxLength: PropTypes.number,
  onDataChanged: PropTypes.func.isRequired,
};

export default TextSettings;
