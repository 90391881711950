const CustomIdAPI = {

  create: (masterTemplateId, pattern) => {
    const url = `/master_templates/${masterTemplateId}/custom_ids`;

    return $.ajax(url, {
      dataType: 'json',
      method: 'POST',
      data: {
        custom_id: {
          pattern: pattern
        }
      }
    });
  },

  destroy: (customIdId) => {
    const url = `/custom_ids/${customIdId}`;

    return $.ajax(url, {
      dataType: 'json',
      method: 'DELETE'
    });
  }

};

export default CustomIdAPI;