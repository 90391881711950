import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { any } from 'underscore';
import MediaQuery from 'react-responsive';
import NotifBanner from 'components/shared/notif-banner';
import InformationalText from 'components/shared/informational-text';
import Activity from './process/activity';
import Access from './process/access';
import HeaderMeta from './process/header-meta';
import Discussion from './process/process-discussion';
import DocumentsAndFiles from './process/documents-and-files';
import Menu from './process/menu';
import IfControlNav from './process/if-control-nav';
import CurrentStep from './process/current-step';
import Timeline from './process/timeline';
import OutputsContainer from './process/outputs-container';
import SlidingPanel from '../shared/sliding-panel';

const Process = (props) => {
  const [accessIsOpen, setAccessIsOpen] = useState(false);
  const [activityIsOpen, setActivityIsOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [discussionIsOpen, setDiscussionIsOpen] = useState(false);
  const [documentsIsOpen, setDocumentsIsOpen] = useState(false);

  const accessNode = useRef(null);
  const activityNode = useRef(null);
  const documentsNode = useRef(null);
  const discussionNode = useRef(null);
  const menuNode = useRef(null);

  useEffect(() => {
    if (shouldRedirectToCompleted()) {
      props.history.replace('/completed');
    }
  }, []);

  function onOpenMenuClick(e) {
    e.preventDefault();
    setMenuIsOpen(true);
  }

  function saveState() {
    if (props.initiatedFlowState.changePending) { return 'saving'; }

    if (props.initiatedFlowState.outstandingSaveRequests === 0) {
      return 'saved';
    } if (props.initiatedFlowState.outstandingSaveRequests > 0) {
      return 'saving';
    }

    return null;
  }

  function shouldRedirectToCompleted() {
    // redirect to completed view if no step is specified in route and flow is finished
    const onDefaultStep = !location.pathname.match('/steps/') && !location.pathname.match('/supplemental_requirement/');
    return onDefaultStep && props.initiatedFlow.finished_at;
  }

  function renderCompleted() {
    return (
      <div className='currentstep'>
        <div className='currentstep-stepsection cf'>
          <div className='text-center screenmin-less'>
            <div className='goldenwidth margin-top margin-bottom color-text-medium marginauto'>
              {renderCompletionMessage()}
            </div>

            <OutputsContainer outputs={props.initiatedFlow.issued_outputs} />
          </div>
        </div>
      </div>
    );
  }

  function renderCurrentStep() {
    const {
      currentUserId, initiatedFlowState, admin,
    } = props;
    return (
      <CurrentStep
        currentUserId={currentUserId}
        step={initiatedFlowState.step}
        initiatedFlow={initiatedFlowState.initiatedFlow}
        activeStep={initiatedFlowState.activeStep}
        attemptedAdvance={initiatedFlowState.attemptedAdvance}
        admin={admin}
        teamId={props.masterTemplate.team_id}
        viewingNonCurrent={(initiatedFlowState.step.id !== initiatedFlow.current_step_id)}
        onOpenDiscussionClick={onOpenDiscussionClick}
        authorities={props.masterTemplate.authorities}
        discussions={props.discussions}
        teamFriendlyId={CityGrows.Server.initiatedFlowData.team.friendly_id}
      />
    );
  }

  function onOpenDiscussionClick(e) {
    e.preventDefault();
    setDiscussionIsOpen(true);
  }

  function onCloseDiscussionClick() {
    setDiscussionIsOpen(false);
  }

  function onCloseMenuClick() {
    setMenuIsOpen(false);
  }

  function onOpenDocumentsClick(e) {
    e.preventDefault();
    setDocumentsIsOpen(true);
  }

  function onCloseDocumentsClick() {
    setDocumentsIsOpen(false);
  }

  function onOpenActivityClick(e) {
    e.preventDefault();
    setActivityIsOpen(true);
  }

  function onCloseActivityClick() {
    setActivityIsOpen(false);
  }

  function onOpenAccessClick(e) {
    e.preventDefault();
    setAccessIsOpen(true);
  }

  function onCloseAccessClick() {
    setAccessIsOpen(false);
  }

  function renderCompletionMessage() {
    if (props.initiatedFlow.completion_message) {
      return (
        <InformationalText
          text={props.initiatedFlow.completion_message}
          textType='CompletionMessage'
          textContainerId={props.flowTemplate.id}
          initiatedFlow={props.initiatedFlow}
          classes='completion-message'
        />
      );
    }
  }

  function renderTimeline() {
    return (
      <MediaQuery minWidth={769}>
        {(matches) => {
          /* TODO: push this mediaquery down into Timeline */
          if (matches) {
            return (
              <Timeline
                desktop
                admin={props.admin}
                initiatedFlowState={props.initiatedFlowState}
                onOpenDiscussionClick={onOpenDiscussionClick}
                discussions={props.discussions}
              />
            );
          }
          return (
            <Timeline
              desktop={false}
              admin={props.admin}
              initiatedFlowState={props.initiatedFlowState}
              onOpenDiscussionClick={onOpenDiscussionClick}
              discussions={props.discussions}
            />
          );
        }}
      </MediaQuery>
    );
  }

  function renderSaveStatus() {
    if (saveState() == null) { return; }

    const text = saveState() === 'saved' ? 'All Changes Saved.' : 'Saving Changes...';

    return (
      <div className={`activeflowheader-savetag margin-right ${saveState()}`}>
        {text}
      </div>
    );
  }

  function renderProcessNumber() {
    return (
      <div className='activeflowheader-processnumber'>
        Submission <span>#{props.initiatedFlow.scoped_id}</span>
        {renderCustomId()}
      </div>
    );
  }

  function renderCustomId() {
    if (!props.initiatedFlow.custom_id) { return; }

    return (
      <span className='processnumber-customid'>{props.initiatedFlow.custom_id}</span>
    );
  }

  function renderBannerNotifs() {
    if (props.initiatedFlow.halted) {
      return (
        <NotifBanner style='alert'>
          This submission has been <strong>halted</strong>
        </NotifBanner>
      );
    }

    if (props.admin && any(props.initiatedFlow.subprocess_requirements, (requirement) => {
      return requirement.subprocess_workflow_not_published;
    })) {
      return (
        <div className='flowbanner alert'>
          This submission is skipping a subprocess requirement because the sub-submission form has been unpublished.
        </div>
      );
    }
  }

  function renderSubprocessInfo() {
    if (props.initiatedFlow.with_access_to_parent && props.initiatedFlow.parent_process.id) {
      return (
        <div className='activeflowheader-subprocessinfo'>
          <span className='tag margin-right'>
            <span className='icon icon-arrow-down-right-white' /> Sub-submission
          </span>
          <span className='onlydesktop margin-right-less'>
            This submission is a requirement of
          </span>
          {textLinkToParent()}
        </div>
      );
    }
  }

  function textLinkToParent() {
    return (
      <a id='parent-process-link' href={`/initiated_flows/manager/${props.initiatedFlow.parent_process.id}`}>
        {props.initiatedFlow.parent_process.template_name} #{props.initiatedFlow.parent_process.scoped_id}
      </a>
    );
  }

  function masterTemplateUrl() {
    if (props.admin) {
      return `/workflows/${props.masterTemplate.id}`;
    }
    return `/${props.masterTemplate.team_friendly_id}/${props.masterTemplate.friendly_id}`;
  }

  function dashboardUrl() {
    if (props.admin) {
      return '/processes';
    }
    return '/dashboard';
  }

  function renderTemplateVersion() {
    return (
      <div className='versionnumber margin-right'>
        Version {props.flowTemplate.version}
      </div>
    );
  }

  const { initiatedFlow, admin, initiatedFlowState } = props;

  const transitionClassNames = admin ? 'ifslidingpanel al' : 'public ifslidingpanel al';

  return (
    <>
      <TransitionGroup component={null}>
        {menuIsOpen && (
          <CSSTransition
            classNames={transitionClassNames}
            timeout={250}
            nodeRef={menuNode}
          >
            <SlidingPanel transitionRef={menuNode} onCloseClick={onCloseMenuClick} title='Menu'>
              <Menu
                initiatedFlowId={initiatedFlow.id}
                issuedOutputs={initiatedFlow.issued_outputs}
                activeFlow={!initiatedFlow.finished_at}
                personAttachments={initiatedFlow.person_attachments}
                teamId={props.masterTemplate.team_id}
                hasPublicFiles={initiatedFlow.has_public_files}
                hasFiles={initiatedFlow.has_files}
                admin={admin}
                activities={initiatedFlowState.activities}
                applicantAttachments={initiatedFlow.applicant_attachments}
              />
            </SlidingPanel>
          </CSSTransition>
        )}
        {discussionIsOpen && (
          <CSSTransition
            classNames={transitionClassNames}
            timeout={250}
            nodeRef={discussionNode}
          >
            <Discussion
              transitionRef={discussionNode}
              initiatedFlowId={initiatedFlow.id}
              admin={admin}
              discussions={props.discussions}
              onCloseClick={onCloseDiscussionClick}
            />
          </CSSTransition>
        )}
        {documentsIsOpen && (
          <CSSTransition
            classNames={transitionClassNames}
            timeout={250}
            nodeRef={documentsNode}
          >
            <SlidingPanel transitionRef={documentsNode} onCloseClick={onCloseDocumentsClick} title='Documents and Files' >
              <DocumentsAndFiles
                initiatedFlowId={initiatedFlow.id}
                issuedOutputs={initiatedFlow.issued_outputs}
              />
            </SlidingPanel>
          </CSSTransition>
        )}
        {activityIsOpen && (
          <CSSTransition
            classNames={transitionClassNames}
            timeout={250}
            nodeRef={activityNode}
          >
            <SlidingPanel transitionRef={activityNode} onCloseClick={onCloseActivityClick} title='Activity'>
              <Activity
                activities={initiatedFlowState.activities}
                initiatedFlowId={initiatedFlow.id}
              />
            </SlidingPanel>
          </CSSTransition>
        )}
        {accessIsOpen && (
          <CSSTransition
            classNames={transitionClassNames}
            timeout={250}
            nodeRef={accessNode}
          >
            <SlidingPanel transitionRef={accessNode} onCloseClick={onCloseAccessClick} title='Access'>
              <Access
                admin={admin}
                applicantAttachments={initiatedFlow.applicant_attachments}
                initiatedFlowId={initiatedFlow.id}
                personAttachments={initiatedFlow.person_attachments}
                teamId={props.masterTemplate.team_id}
              />
            </SlidingPanel>
          </CSSTransition>
        )}
      </TransitionGroup>

      {renderBannerNotifs()}

      <div className='activeflowheader'>
        {renderSubprocessInfo()}

        <div className='activeflowheader-mainwrap'>
          <div className='activeflowheader-left'>
            <div className='viewall-container'>
              <h1><a id='templatetitle' href={masterTemplateUrl()}>{props.flowTemplate.name}</a></h1>
              &nbsp;&nbsp;|&nbsp;&nbsp;<a href={dashboardUrl()}>View All Submissions</a>
            </div>
            <HeaderMeta
              initiatedFlow={props.initiatedFlow}
              admin={props.admin}
              flowTemplateId={props.flowTemplate.id}
              teamId={props.masterTemplate.team_id}
            />
          </div>

          <div className='activeflowheader-right'>
            <ul className='activeflowheader-menus'>
              <li id='discussions' className='activeflowheader-icon-item'>
                <button className='btn-link' onClick={onOpenDiscussionClick} title='Discussions'>
                  <span className='icon icon-speechballoon margin-right-less' />
                  {props.discussions.length}
                  <span className='tooltip'>Discussions</span>
                </button>
              </li>
              <li id='documents-and-files' className='activeflowheader-icon-item'>
                <button className='btn-link' onClick={onOpenDocumentsClick}title='Documents and files'>
                  <span className='icon icon-doc' />
                  {/* Temporary hide files count */}
                  {/* {initiatedFlow.total_documents_count} */}
                  <span className='tooltip'>Documents and files</span>
                </button>
              </li>
              <li id='access' className='activeflowheader-icon-item'>
              <button className='btn-link' onClick={onOpenAccessClick} title='Access' >
                <span className='icon icon-person-primary-2' />
                <span className='tooltip'>Access</span>
              </button>
              </li>
              <li id='activity' className='activeflowheader-icon-item'>
              <button className='btn-link' onClick={onOpenActivityClick} title='Activity'>
                <span className='icon icon-pulse' />
                <span className='tooltip'>Activity</span>
              </button>
              </li>
              <li id='menu' className='activeflowheader-icon-item'>
                <button className='btn-link' onClick={onOpenMenuClick} title='More options'>
                  <span className='icon icon-dots-horizontal' />
                  <span className='tooltip'>More options</span>
                </button>
              </li>
            </ul>

            <div className='activeflowheader-rightlowerwrap'>
              {renderSaveStatus()}
              {props.admin && renderTemplateVersion()}
              {renderProcessNumber()}
            </div>
          </div>
        </div>
      </div>

      <div className='flowcontent-wrap'>
        {renderTimeline()}
        <div className='flowcontent'>
          <Switch>
            <Route exact path='/' render={renderCurrentStep} />
            <Route exact path='/completed' render={renderCompleted} />
          </Switch>
        </div>
      </div>

      <IfControlNav
        initiatedFlowState={props.initiatedFlowState}
        admin={props.admin}
        masterTemplateId={props.masterTemplate.id}
        onOpenDiscussionClick={onOpenDiscussionClick}
        teamSlug={props.masterTemplate.team_friendly_id}
        masterTemplateSlug={props.masterTemplate.friendly_id}
      />
    </>
  );
};

Process.propTypes = {
  admin: PropTypes.bool.isRequired,
  masterTemplate: PropTypes.shape({
    authorities: PropTypes.arrayOf(PropTypes.shape({})),
    friendly_id: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    team_friendly_id: PropTypes.string,
    team_id: PropTypes.number.isRequired,
  }).isRequired,
  step: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  match: PropTypes.shape({}).isRequired,
  initiatedFlow: PropTypes.shape({
    completion_message: PropTypes.string,
    current_step_id: PropTypes.number.isRequired,
    custom_id: PropTypes.string,
    finished_at: PropTypes.string,
    halted: PropTypes.bool.isRequired,
    has_files: PropTypes.bool.isRequired,
    has_public_files: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    issued_outputs: PropTypes.arrayOf(PropTypes.shape({})),
    parent_process: PropTypes.shape({
      id: PropTypes.number,
      template_name: PropTypes.string,
    }),
    person_attachments: PropTypes.arrayOf(PropTypes.shape({})),
    scoped_id: PropTypes.number.isRequired,
    subprocess_requirements: PropTypes.arrayOf(PropTypes.shape({})),
    with_access_to_parent: PropTypes.bool,
  }).isRequired,
  activeStep: PropTypes.shape({
    collected_data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  currentUserId: PropTypes.number.isRequired,
  flowTemplate: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

export default Process;
