import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import CloseIfOutsideWrapper from 'components/shared/close-if-outside-wrapper';
import Shield from 'modules/shield';

const SideMenu = (props) => {
  useEffect(() => {
    Shield.on();
  }, []);

  const onClose = () => {
    if (props.onClose) {
      Shield.off();
      props.onClose();
    }
  };
  return (
    <CloseIfOutsideWrapper
      onClose={onClose}
      wrapperIgnoreClass={props.wrapperIgnoreClass}
    >
      <div className='sidemenu' ref={props.transitionRef}>
        <div className={`sidemenu-container ${props.position}`}>
          <div className='sidemenu-header'>
            <FontAwesomeIcon
              className='clickable margin-right'
              icon={icon({ name: 'xmark' })}
              style={{ color: '#0463B7', height: '1rem' }}
              onClick={onClose}
            />
            <h1>{props.title}</h1>
          </div>
          <div className='sidemenu-body'>
            {props.children}
          </div>
          <div className='sidemenu-footer'>
            <button type='button' className='btn-primary' onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    </CloseIfOutsideWrapper>
  );
};

SideMenu.propTypes = {
  title: PropTypes.string.isRequired,
  position: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  wrapperIgnoreClass: PropTypes.arrayOf(PropTypes.string),
  transitionRef: PropTypes.shape({}),
};

SideMenu.defaultProps = {
  position: 'right',
};

export default SideMenu;
