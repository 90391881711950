import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/shared/select';
import { findWhere, reject } from 'underscore';
import UserEmailSetting from './email-setting';
import EmailSettingsAPI from '../../apis/email-settings-api';
import Flash from '../shared/flash';

const formatForSelect = (object) => {
  return {
    value: object.id,
    label: object.name,
  };
};

const UserEmailSettings = (props) => {
  const [workflowId, setWorkflowId] = useState(initialWorkflowSetting());
  const [teamId, setTeamId] = useState(initialTeamSetting());
  const [settings, setSettings] = useState(props.emailSettings);

  function initialWorkflowSetting() {
    if (!props.initialType || props.initialType.type !== 'MasterTemplate') { return null; }

    return props.initialType.id;
  }

  function initialTeamSetting() {
    if (!props.initialType || props.initialType.type !== 'Team') { return null; }

    return props.initialType.id;
  }

  function findEmailSetting(type, typeParentType) {
    const typeParentId = (typeParentType === 'MasterTemplate') ? workflowId : teamId;
    const setting = findWhere(
      settings,
      { email_type: type, type_parent_id: Number(typeParentId), type_parent_type: typeParentType },
    );
    if (!setting) {
      return {
        email_type: type,
        type_parent_id: typeParentId,
        type_parent_type: typeParentType,
        owner_type: 'User',
        owner_id: props.currentUserId,
        should_send: 'default',
      };
    }

    return setting;
  }

  function addSetting(setting) {
    setSettings([...settings, setting]);
  }

  function removeSetting(id) {
    const newSettings = reject(settings, (setting) => { return setting.id === Number(id); });
    setSettings(newSettings);
  }

  function updateSetting(setting) {
    const dupSettings = [...settings];
    const index = dupSettings.findIndex((s) => { return s.id === setting.id; });
    dupSettings[index] = setting;
    setSettings(dupSettings);
  }

  function removeSettings(typeParentId, typeParentType) {
    const newSettings = reject(
      settings,
      (setting) => {
        return (setting.type_parent_id === Number(typeParentId)
        && setting.type_parent_type === typeParentType);
      },
    );
    setSettings(newSettings);
  }

  const handleToggleChange = (setting, toggleValue) => {
    if (toggleValue !== 'default') {
      const shouldSend = toggleValue === 'on';
      updateOrCreateSetting(shouldSend, setting);
    } else if (setting.id) {
      EmailSettingsAPI.destroy(setting.id)
        .done((r) => {
          Flash.success('Setting updated');
          removeSetting(r.id);
        })
        .fail(() => { Flash.error('Oops, something went wrong'); });
    }
  };

  function updateOrCreateSetting(shouldSend, setting) {
    if (!setting.id) {
      const emailSetting = { ...setting, should_send: shouldSend };
      EmailSettingsAPI.create(emailSetting)
        .done((r) => {
          addSetting(r.setting);
          Flash.success('Setting updated');
        })
        .fail(() => { Flash.error('Oops, something went wrong'); });
    } else {
      EmailSettingsAPI.update(setting.id, shouldSend)
        .done((r) => {
          updateSetting(r.setting);
          Flash.success('Setting updated');
        })
        .fail(() => { Flash.error('Oops, something went wrong'); });
    }
  }

  function revertToDefault(type) {
    const typeParentId = type === 'MasterTemplate' ? workflowId : teamId;
    EmailSettingsAPI.delete_all(typeParentId, type)
      .done((r) => { removeSettings(r.type_parent_id, r.type_parent_type); });
  }

  function renderWorkflowSelect() {
    const workflowOptionsForSelect = props.workflows ? props.workflows.map(formatForSelect) : [];
    const handleWorkflowSelect = (option) => {
      setWorkflowId(option.value);
    };

    return (
      <label>
        <div>Form</div>
        <Select
          id='workflow-select'
          options={workflowOptionsForSelect}
          style={{ width: '100%' }}
          placeholder='Choose a form'
          onChange={handleWorkflowSelect}
          value={workflowOptionsForSelect.find((option) => workflowId === option.value)}
          menuPosition='fixed'
        />
      </label>
    );
  }

  function renderEmails(emails, typeParentType) {
    return (
      <div>
        <button type='button' className='btn-link margin-top' onClick={() => { revertToDefault(typeParentType); }}>Revert all to default</button>
        <ul>
          {Object.keys(emails).map((emailType) => {
            const setting = findEmailSetting(emailType, typeParentType);
            return (
              <UserEmailSetting
                key={emailType}
                type={emailType}
                email={emails[emailType]}
                setting={setting}
                handleToggleChange={handleToggleChange}
              />
            );
          })}
        </ul>
      </div>
    );
  }

  function renderTeamSelect() {
    const teamOptionsForSelect = props.teams ? props.teams.map(formatForSelect) : [];

    const handleTeamSelect = (option) => {
      setTeamId(option.value);
    };

    return (
      <label>
        <div>Team</div>
        <Select
          id='team-select'
          options={teamOptionsForSelect}
          style={{ width: '100%' }}
          placeholder='Choose a team'
          onChange={handleTeamSelect}
          value={teamOptionsForSelect.find((option) => teamId === option.value)}
          menuPosition='fixed'
        />
      </label>
    );
  }

  return (
    <div>
      <h1>Email Settings</h1>
      <p>Adjust the types of emails you receive. Each team and form has its own email settings. <a target='_blank' href='https://help.citygrows.com/en/articles/3972561-what-status-emails-does-citygrows-generate-automatically' rel='noreferrer'>Descriptions of the types of emails we send.</a></p>
      <div>
        <h2>Form Emails</h2>
        {renderWorkflowSelect()}
        {workflowId && renderEmails(props.emails.processSpecific, 'MasterTemplate')}
      </div>
      <div className='padding-top'>
        <h2>Team Emails</h2>
        {renderTeamSelect()}
        {teamId && renderEmails(props.emails.teamSpecific, 'Team')}
      </div>
    </div>
  );
};

UserEmailSettings.propTypes = {
  initialType: PropTypes.object,
  emailSettings: PropTypes.array,
  workflows: PropTypes.array,
  teams: PropTypes.array,
  emails: PropTypes.object,
  currentUserId: PropTypes.string,
};

export default UserEmailSettings;
