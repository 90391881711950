import React, { createContext, useContext, useReducer } from 'react';
import { contains, flatten, map, reject, sortBy, findIndex } from 'underscore';

import DashboardStore from 'stores/dashboard-store';
import { CURRENT_STEP_SELECT, getSubmissionDimensions } from './constants';

const AdvancedFilterContext = createContext({
  activeFilters: [],
  availableFilters: [],
  currentFilter: {},
  savedFilters: [],
  triggerRefresh: 1,
  defaultFilters: [],
});
const AdvancedFilterDispatchContext = createContext(null);

export const AdvancedFilterProvider = ({
  initial,
  children
}) => {
  const [state, dispatch] = useReducer(advancedFilterReducer, initial);

  return (
    <AdvancedFilterContext.Provider value={state}>
      <AdvancedFilterDispatchContext.Provider value={dispatch}>
        {children}
      </AdvancedFilterDispatchContext.Provider>
    </AdvancedFilterContext.Provider>
  );
};

export const useAdvancedFilterContext = () => {
  return useContext(AdvancedFilterContext);
};

export const useAdvancedFilterDispatchContext = () => {
  return useContext(AdvancedFilterDispatchContext);
};

const buildDatumField = (field, groupName, sectionName) => {
  return {
    id: field.identity,
    label: `${sectionName}: ${field.name}`,
    type: 'datumField',
    filterGroup: groupName,
    fieldType: field.data_type,
    multipleChoiceOptions: field.multiple_choice_options,
    operators: [],
  }
};

const addToFiltersGroup = (filters, item) => {
  return map(filters, (group) => {
    if (group.id === item.filterGroup) {
      group.options = sortBy([...group.options, item], 'label');
    }
    return group;
  });
};

const removeFromFiltersGroup = (filters, item) => {
  return map(filters, (group) => {
    if (group.id == item.filterGroup) {
      group.options = reject(group.options, (filter) => filter.id === item.id);
    }
    return group;
  });
}

function loadFilter (state, savedFilter) {
  const { filters, step_data_for_search } = savedFilter;

  if (!filters) { return state };

  const filterIds = Object.keys(filters);

  const datumFiltersIds = filters.datum_fields
    ? Object.entries(filters.datum_fields.value).map(([id, _]) => id)
    : [];
  // Current step is counted as datum field
  if (filters.current_step) {
    datumFiltersIds.push('current_step');
  }

  let filtersGroups = getSubmissionDimensions();

  if (step_data_for_search) {
    let datumFields = Object.entries(step_data_for_search.data).map(([sectionName, fields]) => {
      return fields.map(field => buildDatumField(field, 'formFields', sectionName));
    });

    let legacyDatumFields = Object.entries(step_data_for_search.legacy_data).map(([sectionName, fields]) => {
      return fields.map(field => buildDatumField(field, 'legacyFormFields', sectionName));
    });

    const formFields = {
      id: 'formFields',
      label: 'Form Fields',
      options: [CURRENT_STEP_SELECT, ...flatten(datumFields)]
    };

    const legacyFormFields = {
      id: 'legacyFormFields',
      label: 'Legacy Fields',
      options: [...flatten(legacyDatumFields)]
    };

    filtersGroups = [...filtersGroups, formFields, legacyFormFields];
  }

  let availableFilters = map(filtersGroups, (group) => {
    if (group.id == 'main') {
      return {
        ...group,
        options: group.options.filter((filter) => !contains(filterIds, filter.id)),
      };
    }
    if (group.id == 'formFields') {
      return {
        ...group,
        options: group.options.filter((filter) => !contains(datumFiltersIds, filter.id)),
      };
    }
    if (group.id == 'legacyFormFields') {
      return {
        ...group,
        options: group.options.filter((filter) => !contains(datumFiltersIds, filter.id)),
      };
    }
    return group;
  });

  const activeFilters = flatten(map(filtersGroups, (group) => {
    if (group.id == 'main') {
      return group.options.filter((filter) => contains(filterIds, filter.id))
    }
    if (group.id == 'formFields') {
      return group.options.filter((filter) => contains(datumFiltersIds, filter.id))
    }
    if (group.id == 'legacyFormFields') {
      return group.options.filter((filter) => contains(datumFiltersIds, filter.id))
    }
    return [];
  }));

  return {
    ...state,
    activeFilters,
    availableFilters,
    currentFilter: filters,
    stepDataForSearch: savedFilter.step_data_for_search,
    triggerRefresh: state.triggerRefresh + 1,
    isDefault: false,
  };
}

const advancedFilterReducer = (state, action) => {
  switch (action.type) {
  case 'addFilter': {
    const newState = {
      ...state,
      activeFilters: [...state.activeFilters, action.value],
      availableFilters: removeFromFiltersGroup(state.availableFilters, action.value),
    };

    if (action.value.type === 'datumField') {
      return {
        ...newState,
        triggerRefresh: state.triggerRefresh + 1,
        currentFilter: {
          ...newState.currentFilter,
          'datum_fields': newState.currentFilter.datum_fields || {}
        },
      }
    }

    return {
      ...newState,
      triggerRefresh: state.triggerRefresh + 1,
      isDefault: false,
      currentFilter: {
        ...newState.currentFilter,
        [action.value.id]: { ...action.value.defaults }
      },
    };
  }

  case 'changeFilterOperator': {
    let currentFilter = state.currentFilter;
    currentFilter[action.filter.id].operator = action.operator.id;

    return {
      ...state,
      currentFilter: currentFilter,
      triggerRefresh: state.triggerRefresh + 1,
      isDefault: false,
    };
  }

  case 'changeFilterValue': {
    let currentFilter = state.currentFilter;
    currentFilter[action.filter.id].value = action.value;

    return {
      ...state,
      currentFilter: currentFilter,
      triggerRefresh: state.triggerRefresh + 1,
      isDefault: false,
    };
  }

  case 'changeFilterValueOptions': {
    let currentFilter = state.currentFilter;
    currentFilter[action.filter.id].options = action.value;

    return {
      ...state,
      currentFilter: currentFilter,
      triggerRefresh: state.triggerRefresh + 1,
      isDefault: false,
    };
  }

  case 'deleteFilter': {
    const newState = {
      ...state,
      activeFilters: reject(state.activeFilters, (filter) => filter.id == action.value.id),
      availableFilters: addToFiltersGroup(state.availableFilters, action.value),
    };

    if (action.value.type == 'datumField') {
      let datumFields = {...newState.currentFilter.datum_fields.value};
      delete datumFields[action.value.id];

      return {
        ...newState,
        triggerRefresh: state.triggerRefresh + 1,
        currentFilter: {
          ...newState.currentFilter,
          'datum_fields': {
            value: datumFields
          }
        },
      }
    }

    let currentFilter = newState.currentFilter;
    delete currentFilter[action.value.id];

    return {
      ...newState,
      currentFilter: currentFilter,
      isDefault: false,
      triggerRefresh: state.triggerRefresh + 1,
    };
  }

  case 'disableStepDataFilters': {
    const inDatumFields = id => contains(['formFields', 'legacyFormFields'], id);

    return {
      ...state,
      activeFilters: reject(state.activeFilters, filter => inDatumFields(filter.filterGroup)),
      availableFilters: reject(state.availableFilters, group => inDatumFields(group.id)),
      currentFilter: {
        ...state.currentFilter,
        'current_step': null,
        'datum_fields': null,
      },
      stepDataForSearch: null,
    };
  }

  case 'enableStepDataFilters': {
    let datumFields = Object.entries(DashboardStore.getStepData().data).map(([sectionName, fields]) => {
      return fields.map(field => buildDatumField(field, 'formFields', sectionName));
    });

    let legacyDatumFields = Object.entries(DashboardStore.getStepData().legacy_data).map(([sectionName, fields]) => {
      return fields.map(field => buildDatumField(field, 'legacyFormFields', sectionName));
    });

    const formFields = {
      id: 'formFields',
      label: 'Form Fields',
      options: [CURRENT_STEP_SELECT, ...flatten(datumFields)]
    };

    const legacyFormFields = {
      id: 'legacyFormFields',
      label: 'Legacy Fields',
      options: [...flatten(legacyDatumFields)]
    };

    return {
      ...state,
      availableFilters: [...state.availableFilters, formFields, legacyFormFields],
      stepDataForSearch: null,
    }
  }

  case 'loadFilter': {
    const savedFilter = JSON.parse(JSON.stringify(state.savedFilters.find(filter => filter.id === action.value)));

    return {
    ...loadFilter(state, savedFilter),
      loadedFilter: {
        id: savedFilter.id,
        name: savedFilter.name,
        default: savedFilter.default,
      }
    }
  }

  case 'loadState': {
    //  When loading the state we dont want to overwrite the saved filters with potentially cached and outdated data
    return { ...action.value, savedFilters: [...state.savedFilters] }
  }

  case 'loadDefault': {
    const groups = getSubmissionDimensions();

    const mainGroup = groups.find(group => group.id == 'main');
    let currentFilters = {}
    
    state.defaultFilters.forEach((filterId) => {
      const filter = mainGroup.options.find(item => item.id == filterId);
      if (filter) {
        currentFilters[filterId] = { ...filter.defaults };
      }
    });

    return {
      ...state,
      activeFilters: flatten(map(groups, (group) => {
        if (group.id == 'main') {
          return group.options.filter((filter) => contains(state.defaultFilters, filter.id))
        }
        return [];
      })),

      availableFilters: map(groups, (group) => {
        if (group.id == 'main') {
          return {
            ...group,
            options: group.options.filter((filter) => !contains(state.defaultFilters, filter.id)),
          };
        }
        return group;
      }),
      currentFilter: currentFilters,
      loadedFilter: null,
      triggerRefresh: state.triggerRefresh + 1,
      isDefault: true,
    };
  }

  case 'addSavedFilter': {
    let updatedSaved = [...state.savedFilters]

    if (action.value.default) {
      updatedSaved = state.savedFilters.map((filter) => { 
        if (filter.default) {
          return {...filter, default: false}
        }

        return { ...filter }
      })
    }

    return {
      ...loadFilter(state, action.value),
      savedFilters: [...updatedSaved, action.value],
      loadedFilter: {
        id: action.value.id,
        name: action.value.name,
        default: action.value.default,
      }
    }
  }

  case 'updateSavedFilter': {
    const updatedSaved = state.savedFilters.map((filter) => { 
      if (filter.id === action.value.id) {
        return { ...action.value }
      }

      if (filter.default && action.value.default) {
        return { ...filter, default: false }
      }

      return { ...filter }
    })

    if (state.loadedFilter?.id === action.value.id) {
      return {...state, savedFilters: updatedSaved, loadedFilter: {...action.value} }
    }

    return { ...state, savedFilters: updatedSaved };
  }

  case 'deleteSavedFilter': {
    const isCurrentlyLoaded = action.value.id === state.loadedFilter?.id 
    const loaded = isCurrentlyLoaded ? null : state.loadedFilter
    
    return { ...state, savedFilters: state.savedFilters.filter(e => e.id !== action.value.id), loadedFilter: loaded};
  } 

  case 'cacheAndLoadFilter': {
    const index = findIndex(state.savedFilters, { id: action.value.id });
    const savedFilters = [...state.savedFilters.slice(0, index), action.value, ...state.savedFilters.slice(index + 1)]
    return {
      ...loadFilter(state, action.value),
      savedFilters,
      loadedFilter: {
        id: action.value.id,
        name: action.value.name,
        default: action.value.default,
      }
    }
  }

    case 'loadFilters': {
      return loadFilter(state, action.value)
  }

  default: {
    return state;
  }
  }
}
