import React, {
  useState, useContext, useEffect, useMemo, useRef, createRef,
} from 'react';
import {
  isEqual,
} from 'underscore';
import {
  Route, Switch, useHistory,
} from 'react-router-dom';
import { success as flashSuccess, error as flashError } from 'components/shared/flash';
import { alert, confirm } from 'modules/alert-confirm';
import {
  submitConfig,
  publishDocument,
  unpublishDocument,
  deleteDocument,
} from 'actions/document-manager-actions';
import { DocumentDispatchContext } from 'contexts/document-dispatch-context';
import { selectValueForMapping } from 'modules/document-mapping-helper';
import Popdown from 'components/shared/popdown';
import MappingPdfViewer from 'components/document-config/mapping-pdf-viewer';
import useOnclickOutside from 'react-cool-onclickoutside';
import DragAndDropBox from './drag-and-drop-box';
import Mapping from './mapping';

const DocumentConfig = ({
  document,
  defaultMappables,
  latestTemplateVersionNumber,
}) => {
  const dispatch = useContext(DocumentDispatchContext);
  const history = useHistory();

  const optionsMenuRef = useRef();
  const optionsAnchor = useRef();
  useOnclickOutside(() => {
    setOptionsOpen(false);
  }, { refs: [optionsAnchor], ignoreClass: 'popdown' });

  const defaultName = document.name ?? '';
  const [name, setName] = useState(defaultName);

  const defaultStartVersion = document.start_version ?? '';
  const [startVersion, setStartVersion] = useState(defaultStartVersion);

  const defaultStopVersion = document.stop_version ?? '';
  const [stopVersion, setStopVersion] = useState(defaultStopVersion);
  const [latest, setLatest] = useState(document.main);

  const [replacing, setReplacing] = useState(false);

  const [optionsOpen, setOptionsOpen] = useState(false);

  const [activeSelect, setActiveSelect] = useState(null);

  useEffect(() => {
    setConfiguredMappings((prevMappings) => {
      return {
        ...prevMappings,
        ...initialMappingState,
      };
    });
  }, [document.mappings]);

  useEffect(() => {
    setName(defaultName);
    setStartVersion(defaultStartVersion);
    setStopVersion(defaultStopVersion);
    setLatest(document.main);
  }, [document.id]);

  const initialMappingState = useMemo(() => {
    return document.mappings.reduce((_mappings, _mapping) => {
      return {
        ..._mappings,
        [_mapping.field_name]: {
          mappable: JSON.stringify(selectValueForMapping(_mapping)),
          mappingId: _mapping.id,
          fieldName: _mapping.field_name,
        },
      };
    }, {});
  }, [document.mappings]);

  const [configuredMappings, setConfiguredMappings] = useState(initialMappingState);

  const onMappingChanged = (mappable, mappingId, fieldName) => {
    setConfiguredMappings((prevMappings) => {
      return {
        ...prevMappings,
        [fieldName]: {
          mappable: mappable.value,
          mappingId,
          fieldName,
        },
      };
    });
  };

  const [working, setWorking] = useState(false);

  const nothingChanged = useMemo(() => {
    return (name === defaultName)
      && (~~startVersion === ~~defaultStartVersion)
      && (~~stopVersion === ~~defaultStopVersion)
      && (latest === document.main)
      && isEqual(configuredMappings, initialMappingState);
  }, [
    name,
    defaultName,
    startVersion,
    defaultStartVersion,
    stopVersion,
    defaultStopVersion,
    configuredMappings,
    initialMappingState,
    latest,
    document.main,
  ]);

  const saveButtonIsDisabled = working || nothingChanged;

  const renderEndVersion = () => {
    return (
      <div className='inline-block'>
        <label className='inline-block'>
          <span>Stop version</span>
          <input
            id='stopversioninput'
            type='number'
            placeholder={latest ? 'Latest' : 'Type a number...'}
            value={latest ? '' : stopVersion}
            onChange={(e) => { setStopVersion(e.currentTarget.value); }}
            disabled={latest}
          />
        </label>

        <label className='inline-block'>
          <span>Latest</span>
          <input
            id='latestcheckbox'
            type='checkbox'
            checked={latest}
            onChange={(e) => { setLatest(e.currentTarget.checked); }}
          />
        </label>
      </div>
    );
  };

  const renderVersions = () => {
    if (!document.has_file) { return; }

    return (
      <div className='margin-bottom-more well'>
        <label className='inline-block margin-right'>
          <span>Start version</span>
          <input
            id='startversioninput'
            type='number'
            placeholder='Type a number...'
            onChange={(e) => { setStartVersion(e.currentTarget.value); }}
            value={startVersion}
          />
        </label>
        {renderEndVersion()}
        <div className='margin-top'>Latest unpublished version is <strong>{latestTemplateVersionNumber}</strong></div>
      </div>
    );
  };

  const renderMapping = (m, index) => {
    const isLast = index === document.mappings.length - 1;

    return (
      <Mapping
        key={m.field_name}
        index={index}
        mapping={m}
        isLast={isLast}
        defaultMappables={defaultMappables}
        onChange={onMappingChanged}
        onMenuOpen={() => setActiveSelect(m.field_name)}
        onMenuClose={() => setActiveSelect(null)}
        latestTemplateVersionNumber={latestTemplateVersionNumber}
        documentStartVersion={startVersion}
        documentStopVersion={stopVersion}
      />
    );
  };

  const renderedMappings = useMemo(() => {
    if (!document.has_file) { return []; }

    return (
      <ul
        id='mappingslist'
        style={{ overflowY: 'auto', maxHeight: '50vh' }}
      >
        {document.mappings.map(renderMapping)}
      </ul>
    );
  }, [document.has_file, document.mappings]);

  const onSaveClick = () => {
    setWorking(true);

    const s = submitConfig(document.id, document.master_template_id, configuredMappings, name, startVersion, stopVersion, latest)
    .done((doc) => {
      dispatch({ type: 'update', document: doc });
      flashSuccess('Document saved');
    })
    .fail(() => { flashError(); })
    .always(() => { setWorking(false); });
  };

  const findErrors = () => {
    const errors = [];

    if (!nothingChanged) {
      errors.push('Your changes must be saved before publishing. Click the \'Save draft\' button to save your changes.');
    }

    if (!document.name) {
      errors.push('Document must have a name');
    }

    if (!document.start_version) {
      errors.push('Document must have a start version');
    }

    if (!document.stop_version && !document.main) {
      errors.push('Document must have a stop version');
    }

    return errors;
  };

  const validateForPublish = () => {
    return findErrors().length === 0;
  };

  const onUnpublishClick = () => {
    unpublishDocument(document.master_template_id, document.id)
      .done((doc) => {
        if (doc.errors.length > 0) {
          flashError(doc.errors[0]);
        } else {
          dispatch({ type: 'update', document: doc });
        }
      })
      .fail(() => { flashError(); })
      .always(() => { setWorking(false); });
  };

  const onPublishClick = () => {
    if (!validateForPublish()) {
      alert('Unable to publish', findErrors()[0]);
      return;
    }

    setWorking(true);

    publishDocument(document.master_template_id, document.id)
      .done((doc) => {
        if (doc.errors.length > 0) {
          flashError(doc.errors[0]);
        } else {
          dispatch({ type: 'update', document: doc });
        }
      })
      .fail(() => { flashError(); })
      .always(() => { setWorking(false); });
  };

  const toggleOptions = () => {
    setOptionsOpen(!optionsOpen);
  };

  const onDeleteClick = () => {
    setOptionsOpen(false);
    confirm('Delete Document?', 'This will delete the uploaded pdf and associated mappings', () => {
      deleteDocument(document.master_template_id, document.id)
        .done(() => {
          flashSuccess('Document deleted');
          dispatch({
            type: 'destroy', documentId: document.id,
          });
          history.push('new');
        });
    });
  };

  const saveButtonText = () => {
    if (document.published_at) {
      return 'Save changes';
    }

    return 'Save draft';
  };

  const publishButton = () => {
    if (document.published_at) {
      return (
        <button
          id='unpublishbutton'
          onClick={onUnpublishClick}
          type='button'
          className='btn-thirdary margin-left'
          disabled={working}
        >
          Unpublish
        </button>
      );
    }

    return (
      <button
        id='publishbutton'
        onClick={onPublishClick}
        type='button'
        className='btn-primary margin-left'
        disabled={working}
      >
        Publish
      </button>
    );
  };

  const onReplaceClick = () => {
    setOptionsOpen(false);
    confirm('Are you sure?', `
      You are about to replace your current document. Note that:
      <ul>
      <li>The document will not work if the replacement document does not have input fields with identical names to the mapped fields on your current document.</li>
      <li>This will remove the current file from the system. Please ensure you have a copy of it before taking this action.</li>
      </ul>
    `.trim())
    .done(() => {
      setReplacing(true);
    });
  };

  const renderContent = () => {
    if (!document.has_file) {
      return (
        <DragAndDropBox
          document={document}
          prompt='Click or drag a fillable PDF document to get started'
        />
      );
    }

    return (
      <>
        <div id='documentmanager-savebar'>
          { document.has_file && (
            <>
              <input
                id='doctitle'
                type='text'
                value={name}
                placeholder='Untitled document'
                className='bigger margin-bottom'
                style={{ width: '400px' }}
                onChange={(e) => { setName(e.currentTarget.value); }}
              />

              <div>
                <button
                  ref={optionsAnchor}
                  type='button'
                  className='unstyled'
                  onClick={toggleOptions}
                >
                  <i
                    className='icon icon-dots-horizontal bigger margin-right'
                  />
                </button>
                { optionsOpen && (
                  <Popdown
                    anchorRef={optionsAnchor}
                    justify='right'
                  >
                    <ul
                      className='popdown-listmenu'
                      ref={optionsMenuRef}
                    >
                      <li>
                        <a target='__blank' href={document.url}>Download document</a>
                      </li>

                      <li>
                        <button
                          type='button'
                          onClick={onReplaceClick}
                        >
                          Replace document
                        </button>
                      </li>

                      <li>
                        <button
                          type='button'
                          onClick={onDeleteClick}
                        >
                          Delete document
                        </button>
                      </li>
                    </ul>
                  </Popdown>
                )}
                <button
                  id='savebutton'
                  type='submit'
                  className='btn btn-primary'
                  onClick={onSaveClick}
                  disabled={saveButtonIsDisabled}
                >
                  {saveButtonText()}
                </button>

                {publishButton()}
              </div>
            </>
          )}
        </div>
        <div id='documentmanager-config'>
          <div className='margin-right'>
            {renderVersions()}

            { document.has_file && (
              <p>Choose which ClearForms fields to map the following form fields to.</p>
            )}

            {renderedMappings}
          </div>

          <Switch>
            <Route exact path='/master_templates/:master_template_id/documents/new' />

            <Route exact path='/master_templates/:master_template_id/documents/:document_id'>
              <div id='documentmanager-preview'>
                { replacing && (
                  <DragAndDropBox
                    document={document}
                    prompt='Click or drag a new file here to replace the document below'
                    onChange={() => { setReplacing(false); }}
                    replacing={replacing}
                  />
                )}

                { !replacing && document.url && (
                  <MappingPdfViewer
                    file={document.url}
                    activeSelect={activeSelect}
                  />
                )}
              </div>
            </Route>
          </Switch>
        </div>
      </>
    );
  };

  return (
    <div id='documentmanager-docwrap' className='raised bg-white'>
      {renderContent()}
    </div>
  );
};

export default DocumentConfig;
