const EditSuggestionsRequestAPI = {
  rejectSuggestion(editSuggestionsRequestId, suggestionId){
    const url = `/edit_suggestions_requests/${editSuggestionsRequestId}/reject_suggestion`;
    return $.ajax(url, {
      method: 'DELETE',
      dataType: 'json',
      data: {
        suggestion_id: suggestionId
      }
    });
  },

  suggest(entityId, suggestions){
    const url = '/edit_suggestions_requests';
    return $.ajax(url, {
      method: 'POST',
      dataType: 'json',
      data: {
        entity_id: entityId,
        suggestions: suggestions
      }
    });
  }
};

export default EditSuggestionsRequestAPI;
