import React from 'react';
import PropTypes from 'prop-types';
import { ALL_COMPARATORS } from 'constants/logic-constants';
import nameForConnective from 'modules/logic-helpers';
import truncate from 'truncate';

const ConfiguredLogicCondition = ({ sourceField, condition, multipleChoiceMatchValue }) => {
  const isNumeric = ['float', 'integer'].includes(sourceField.data_type);
  const matchValue = isNumeric ? condition.match_value : multipleChoiceMatchValue;
  const { connective } = condition;

  return (
    <>
      <div className='logicconfig-configuredcond'>
        <strong className='margin-right-less' title={sourceField.name}>{truncate(sourceField.name, 50)}</strong>
        <span className='margin-right-less'>{ALL_COMPARATORS.find(({ value }) => value === condition.comparator).label}</span>
        <strong className='margin-right-less' title={matchValue}>{truncate(matchValue, 50)}</strong>
      </div>
      {connective && (
        <div className='inline-block vmiddle'>{nameForConnective(connective)}&nbsp;</div>
      )}
    </>
  );
};

ConfiguredLogicCondition.propTypes = {
  condition: PropTypes.object,
  sourceField: PropTypes.object,
  multipleChoiceMatchValue: PropTypes.string,
};

export default ConfiguredLogicCondition;
