import Dispatcher from 'appdispatcher';
import Constants from 'constants/template-manager-constants';
import MultipleChoiceOptionConstants from 'constants/multiple-choice-option-constants';
import AttachedPeopleConstants from 'constants/attached-people-constants';
import {
  find, findWhere, compact, flatten, without, extend, reject, each, get, any
} from 'underscore';
import Stapes from 'stapes';

let _masterTemplate = null;
const _formulaTermSearchResults = [];
let _flowTemplate = {
  steps: [],
};

const CHANGE_EVENT = 'change';
const TERM_SEARCH_RESULTS_CHANGE_EVENT = 'termSearchResultsChange';

function getStep(stepId) {
  return find(_flowTemplate.steps, (step) => {
    return step.id === stepId;
  });
}

function getReviewGroups() {
  return compact(flatten(_flowTemplate.steps.map((step) => step.review_groups)));
}

function getStepAssignmentGroups() {
  return compact(flatten(_flowTemplate.steps.map((step) => step.step_assignment_groups)));
}

function getReviewGroup(reviewGroupId) {
  const reviewGroupToUpdate = getReviewGroups().find((group) => {
    return group.id === reviewGroupId;
  });

  return reviewGroupToUpdate;
}

function getStepAssignmentGroup(stepAssignmentGroupId) {
  const stepAssignmentGroupToUpdate = getStepAssignmentGroups().find((group) => {
    return group.id === stepAssignmentGroupId;
  });

  return stepAssignmentGroupToUpdate;
}

function getNotifications() {
  return compact(flatten(_flowTemplate.steps.map((step) => {
    return step.email_notifications;
  })));
}

function getNotification(notificationId) {
  return find(getNotifications(), (notif) => {
    if (notif.id === notificationId) {
      return notif;
    }
  });
}

function getAllSections() {
  return getStepSections().concat(getSurveySections());
}

function getSection(sectionId) {
  return findWhere(getAllSections(), { id: sectionId });
}

function getStepSections() {
  return compact(flatten(_flowTemplate.steps.map((step) => step.sections)));
}

function getSurveys() {
  const surveySteps = _flowTemplate.steps.filter((step) => step.type === 'SurveyStep');
  return surveySteps.map((step) => step.survey);
}

function getSurvey(surveyId) {
  return getSurveys().find((survey) => survey.id === surveyId);
}

function getSurveySections() {
  const sections = getSurveys().map((survey) => survey.sections);

  return flatten(sections);
}

function getDatum(stepDatumId) {
  return findWhere(getData(), {
    id: stepDatumId,
  });
}

function getStepData() {
  const stepData = [];
  _flowTemplate.steps.map((step) => {
    if (step.step_data) {
      stepData.push(step.step_data);
    }
  });

  return flatten(stepData);
}

function getData() {
  const stepData = [];
  _flowTemplate.steps.map((step) => {
    if (step.step_data) {
      stepData.push(step.step_data);
    }

    if (step.survey) {
      stepData.push(step.survey.step_data);
    }
  });

  return flatten(stepData);
}

function getOptions() {
  return flatten(getData().map((datum) => {
    return datum.multiple_choice_options;
  }));
}

function getOption(optionId) {
  return find(getOptions(), (option) => {
    return option.id === optionId;
  });
}

function addDataRow(sectionId, datum) {
  const section = getSection(sectionId);
  const sectionable = getSectionable(section);

  sectionable.step_data.push(datum);
}

function updateDataRow(datumId, attrs) {
  const datum = findWhere(getData(), { id: datumId });
  extend(datum, attrs);
}

function removeDataRow(stepDatumId) {
  const datum = findWhere(getData(), { id: stepDatumId });
  const section = getSection(datum.section_id);
  const sectionable = getSectionable(section);
  sectionable.step_data = without(sectionable.step_data, datum);
}

function moveDataRow(data) {
  getData().forEach((datum) => {
    const updatedMatch = findWhere(data, {
      id: datum.id,
    });

    if (updatedMatch) {
      datum.position = updatedMatch.position;
      datum.section_id = updatedMatch.section_id;
    }
  });
}

function addStep(newStep) {
  const newSteps = [..._flowTemplate.steps, newStep];
  _flowTemplate.steps = newSteps;
}

function removeStep(stepId) {
  _flowTemplate.steps = reject(_flowTemplate.steps, (step) => {
    return step.id === stepId;
  });
}

function updateStep(stepId, attrs) {
  const step = _flowTemplate.steps.find((step) => {
    return step.id === stepId;
  });

  extend(step, attrs);
}

function reorderSteps(stepPositions) {
  _flowTemplate.steps.forEach((step) => { step.position = stepPositions[step.id]; });
}

function addSection(stepId, section) {
  const step = getStep(stepId);
  step.sections.push(section);
}

function updateSection(updatedSection) {
  const sectionToUpdate = getSection(updatedSection.id);
  extend(sectionToUpdate, updatedSection);
}

function getSectionable(section) {
  switch (section.sectionable_type) {
  case 'Survey':
    return getSurvey(section.sectionable_id);
  case 'Step':
    return getStep(section.sectionable_id);
  default:
    throw new Error(`Invalid sectionable type: ${section.sectionable_type}`);
  }
}

function destroySection(sectionId) {
  const sectionToDestroy = getSection(sectionId);
  const sectionable = getSectionable(sectionToDestroy);
  sectionable.step_data = sectionable.step_data.filter((stepDatum) => {
    return stepDatum.section_id !== sectionId;
  });
  sectionable.sections = sectionable.sections.filter((section) => section !== sectionToDestroy);
}

function moveSection(sections) {
  sections.forEach((section) => {
    const match = getSection(section.id);

    if (match) {
      match.position = section.position;
    }
  });
}

function updateEmailNotification(notificationId, attrs) {
  const notif = getNotification(notificationId);
  extend(notif, attrs);
}

function addEmailNotification(stepId, attrs) {
  const step = getStep(stepId);
  step.email_notifications.push(attrs);
}

function destroyEmailNotification(notificationId) {
  const notif = getNotification(notificationId);
  const step = getStep(notif.step_id);
  step.email_notifications = without(step.email_notifications, notif);
}

function addSurveySection(section, stepId) {
  const step = getStep(stepId);
  step.survey.sections.push(section);
}

function updateSurvey(updatedSurvey) {
  const step = getStep(updatedSurvey.survey_step_id);
  step.survey = updatedSurvey;
}

function updateOutput(updatedOutput) {
  const output = getOutput(updatedOutput.id);
  extend(output, updatedOutput);
}

function addRenewalReminder(renewalReminderAttrs) {
  const output = getOutput(renewalReminderAttrs.configured_output_id);
  output.renewal_reminders.push(renewalReminderAttrs);
}

function updateRenewalReminder(attrs) {
  const output = getOutput(attrs.configured_output_id);
  const reminder = findWhere(output.renewal_reminders, { id: attrs.id });
  Object.assign(reminder, attrs);
}

function removeRenewalReminder(attrs) {
  const output = getOutput(attrs.configured_output_id);
  output.renewal_reminders = reject(output.renewal_reminders, (r) => {
    return r.id === attrs.id;
  });
}

function getOutput(outputId) {
  return _flowTemplate.configured_outputs.find((output) => {
    return output.id === outputId;
  });
}

function addReviewGroup(reviewGroupAttrs) {
  const step = getStep(reviewGroupAttrs.step_id);
  step.review_groups.push(reviewGroupAttrs);
}

function updateReviewGroup(updatedReviewGroup) {
  const reviewGroup = getReviewGroup(updatedReviewGroup.id);
  extend(reviewGroup, updatedReviewGroup);
}

function destroyReviewGroup(reviewGroupId) {
  const reviewGroupToDestroy = getReviewGroup(reviewGroupId);
  const step = getStep(reviewGroupToDestroy.step_id);
  step.review_groups = without(step.review_groups, reviewGroupToDestroy);
}

function getAttachableType(type, id) {
  if (type === 'Step') {
    return getStep(id);
  } if (type === 'ReviewGroup') {
    return getReviewGroup(id);
  }
}

function toggleAttachedPerson(person, id, type) {
  const attachable = getAttachableType(type, id);

  if (person.persisted) {
    attachable.person_attachments.push(person);
  } else {
    attachable.person_attachments = reject(attachable.person_attachments, (p) => {
      return p.id === person.id;
    });
  }
}

function updateRoleUsers(person, roleId, attachableId, type) {
  const attachable = getAttachableType(type, attachableId);
  const attachment = findWhere(attachable.person_attachments, { id: roleId, type: 'AdminRole' });
  if (person.persisted) {
    attachment.user_count += 1;
    attachment.user_ids.push(person.id);
  } else {
    attachment.user_count -= 1;
    const index = attachment.user_ids.indexOf(person.id);
    attachment.user_ids = attachment.user_ids.splice(index, 1);
  }
}

function addMultipleChoiceOption(stepDatumId, optionAttrs) {
  const datum = getDatum(stepDatumId);
  datum.multiple_choice_options.push(optionAttrs);
}

function removeMultipleChoiceOption(optionId) {
  const option = getOption(optionId);
  const datum = getDatum(option.step_datum_id);
  datum.multiple_choice_options = without(datum.multiple_choice_options, option);
}

function updateMultipleChoiceOption(optionId, optionAttrs) {
  const option = getOption(optionId);

  // If making default, clear other defaults
  if (!option.is_default && optionAttrs.is_default) {
    clearSiblingDefaults(optionId);
  }

  extend(option, optionAttrs);
}

function moveMultipleChoiceOption(options) {
  getOptions().forEach((option) => {
    const updatedMatch = findWhere(options, {
      id: option.id,
    });

    if (updatedMatch) {
      option.position = updatedMatch.position;
    }
  });
}

function clearSiblingDefaults(optionId) {
  const option = getOption(optionId);
  const datum = getDatum(option.step_datum_id);

  each(datum.multiple_choice_options, (o) => {
    o.is_default = false;
  });
}

function getLogicable(logicConfig) {
  switch (logicConfig.logicable_type) {
  case 'StepDatum::Section':
    return getSection(logicConfig.logicable_id);
  case 'ReviewGroup':
    return getReviewGroup(logicConfig.logicable_id);
  case 'StepAssignmentGroup':
    return getStepAssignmentGroup(logicConfig.logicable_id);
  default:
    throw new Error(`Unhandled logicable_type ${logicConfig.logicable_type}`);
  }
}

function addLogicConfig(logicConfig) {
  const logicable = getLogicable(logicConfig);
  logicable.logic_config = logicConfig;
}

function removeLogicConfig(logicConfig) {
  const logicable = getLogicable(logicConfig);
  logicable.logic_config = null;
}

function replaceAssignmentGroups(stepId, newGroups) {
  const step = getStep(stepId);
  step.step_assignment_groups = newGroups;
}

function setFormula(formula) {
  const existingFormula = findWhere(_flowTemplate.formulas, { id: formula.id });

  if (existingFormula) {
    extend(existingFormula, formula);
  } else {
    _flowTemplate.formulas.push(formula);
  }
}

function removeFormula(formulaId) {
  _flowTemplate.formulas = reject(_flowTemplate.formulas, (formula) => {
    return formula.id === formulaId;
  });
}

function setMasterTemplate(masterTemplate) {
  _masterTemplate = masterTemplate;
}

function setFlowTemplate(flowTemplate) {
  _flowTemplate = flowTemplate;
}

function updateFlowTemplate(attrs) {
  extend(_flowTemplate, attrs, { is_modified: get(attrs, 'is_modified', true) });
}

function addFAQ(faq) {
  _masterTemplate.faqs.push(faq);
}

function updateFAQ(updatedFaq) {
  const faq = findWhere(_masterTemplate.faqs, { id: updatedFaq.id });
  extend(faq, updatedFaq);
}

function removeFAQ(faqId) {
  _masterTemplate.faqs = reject(_masterTemplate.faqs, (faq) => { return faq.id === faqId; });
}

function addSubprocessConfig(config) {
  _flowTemplate.subprocess_configs = [config, ..._flowTemplate.subprocess_configs];
}

function removeSubprocessConfig(id) {
  _flowTemplate.subprocess_configs = reject(_flowTemplate.subprocess_configs, (config) => {
    return config.id === id;
  });
}

function addSearchConfig(config) {
  _masterTemplate.search_page_configs = [config, ..._masterTemplate.search_page_configs];
}

function updateSearchConfig(updatedConfig) {
  const config = findWhere(_masterTemplate.search_page_configs, { id: updatedConfig.id });
  extend(config, updatedConfig);
}

function removeSearchConfig(id) {
  _masterTemplate.search_page_configs = reject(_masterTemplate.search_page_configs, (config) => {
    return config.id === id;
  });
}

function setModifiedStatus(modified) {
  _flowTemplate.is_modified = modified;
}

function addCustomId(customId) {
  _masterTemplate.custom_id = customId;
}

function setSlug(slug) {
  _masterTemplate.friendly_id = slug;
  _masterTemplate.slug = slug;
}

function removeCustomId() {
  _masterTemplate.custom_id = null;
}

function addAssignmentGroup(group) {
  const step = findWhere(_flowTemplate.steps, { id: group.step_id });
  if (!step) { throw new Error('Expected to find step'); }

  step.step_assignment_groups = [...step.step_assignment_groups, group];
}

function deleteAssignmentGroup(id) {
  const group = findAssignmentGroup(id);
  if (!group) { throw new Error('Expected to find group'); }

  const step = findWhere(_flowTemplate.steps, { id: group.step_id });
  if (!step) { throw new Error('Expected to find step'); }

  step.step_assignment_groups = without(step.step_assignment_groups, group);
}

function getAssignmentGroups() {
  return _flowTemplate.steps.map((s) => s.step_assignment_groups).flat();
}

function findAssignmentGroup(groupId) {
  const groups = _flowTemplate.steps.map((s) => s.step_assignment_groups).flat();
  return findWhere(groups, { id: groupId });
}

function addAssignment(assignment) {
  const group = findAssignmentGroup(assignment.step_assignment_group_id);
  if (!group) { throw new Error('Expected to find group'); }

  group.step_assignments = [...group.step_assignments, assignment];
}

function removeAssignment(assignmentId) {
  const group = find(getAssignmentGroups(), (g) => {
    return any(g.step_assignments, (a) => a.id === assignmentId);
  });
  if (!group) { throw new Error('Expected to find group'); }

  group.step_assignments = reject(group.step_assignments, (a) => {
    return a.id === assignmentId;
  });
}

const TemplateManagerStore = Stapes.subclass({
  emitChange() {
    this.emit(CHANGE_EVENT);
  },

  emitTermSearchResultsChangeEvent() {
    this.emit(TERM_SEARCH_RESULTS_CHANGE_EVENT);
  },

  getFlowTemplate() {
    return $.extend(true, {}, _flowTemplate);
  },

  getMasterTemplate() {
    return $.extend(true, {}, _masterTemplate);
  },

  getFormulaTermSearchResults() {
    return $.extend(true, [], _formulaTermSearchResults);
  },

  getStepData() {
    return getStepData();
  },

  _setSteps(steps) {
    _flowTemplate.steps = steps;
  },

  // Will display banner indicating template has
  // been modified.
  markChanged() {
    updateFlowTemplate({});
    this.emitChange();
  },

  getDefaultActiveStepId() {
    if (_flowTemplate.steps.length === 0) {
      return null;
    }
    return _flowTemplate.steps[0].id;
  },
});

const store = new TemplateManagerStore();

Dispatcher.register((action) => {
  switch (action.actionType) {
  case Constants.FLOW_TEMPLATE_LOADED:
    setFlowTemplate(action.flowTemplate);
    store.emitChange();
    break;
  case Constants.FLOW_TEMPLATE_UPDATED:
    updateFlowTemplate(action.attrs);
    store.emitChange();
    break;
  case Constants.MASTER_TEMPLATE_LOADED:
    setMasterTemplate(action.masterTemplate);
    store.emitChange();
    break;
  case Constants.FORMULA_LOADED:
    setFormula(action.formula);
    store.emitChange();
    break;
  case Constants.FORMULA_DESTROYED:
    removeFormula(action.formulaId);
    store.emitChange();
    break;
  case Constants.MARK_FLOW_TEMPLATE_CHANGED:
    store.markChanged();
    break;
  case Constants.FAQ_CREATED:
    addFAQ(action.faq);
    store.emitChange();
    break;
  case Constants.FAQ_UPDATED:
    updateFAQ(action.faq);
    store.emitChange();
    break;
  case Constants.FAQ_DESTROYED:
    removeFAQ(action.faqId);
    store.emitChange();
    break;
  case Constants.SUBPROCESS_CONFIG_ADDED:
    addSubprocessConfig(action.subprocessConfig);
    setModifiedStatus(action.templateModified);
    store.emitChange();
    break;
  case Constants.SUBPROCESS_CONFIG_DELETED:
    removeSubprocessConfig(action.subprocessConfigId);
    setModifiedStatus(action.templateModified);
    store.emitChange();
    break;
  case Constants.SUBPROCESS_CONFIG_UPDATED:
    break;
  case Constants.SEARCH_CONFIG_ADDED:
    addSearchConfig(action.searchConfig);
    store.emitChange();
    break;
  case Constants.SEARCH_CONFIG_DELETED:
    removeSearchConfig(action.searchConfigId);
    store.emitChange();
    break;
  case Constants.SEARCH_CONFIG_UPDATED:
    updateSearchConfig(action.searchConfig);
    store.emitChange();
    break;
  case Constants.SLUG_UPDATED:
    setSlug(action.newSlug);
    store.emitChange();
    break;
  case Constants.CUSTOM_ID_CREATED:
    addCustomId(action.customIdAttrs);
    store.emitChange();
    break;
  case Constants.CUSTOM_ID_DELETED:
    removeCustomId();
    store.emitChange();
    break;
  case Constants.STEPS_REORDERED:
    reorderSteps(action.stepPositions);
    store.markChanged();
    break;
  case Constants.STEP_ADDED:
    addStep(action.step);
    store.markChanged();
    break;
  case Constants.STEP_REMOVED:
    removeStep(action.stepId);
    store.markChanged();
    break;
  case Constants.STEP_UPDATED:
    updateStep(action.stepId, action.attrs);
    store.markChanged();
    break;
  case Constants.STEP_DATA_ADDED:
    addDataRow(action.sectionId, action.datum);
    store.markChanged();
    break;
  case Constants.STEP_DATA_UPDATED:
    updateDataRow(action.datumId, action.datumAttrs);
    store.markChanged();
    break;
  case Constants.STEP_DATA_REMOVED:
    removeDataRow(action.stepDatumId);
    store.markChanged();
    break;
  case Constants.STEP_DATA_MOVED:
    moveDataRow(action.data);
    store.markChanged();
    break;
  case Constants.SECTION_ADDED:
    addSection(action.stepId, action.section);
    store.markChanged();
    break;
  case Constants.SECTION_COPIED:
    addSection(action.stepId, action.section);

    action.section.step_data.forEach((stepDatum) => {
      addDataRow(action.section.id, stepDatum);
    });

    store.markChanged();
    break;
  case Constants.SECTION_UPDATED:
    updateSection(action.section);
    store.markChanged();
    break;
  case Constants.SECTION_REMOVED:
    destroySection(action.sectionId);
    store.markChanged();
    break;
  case Constants.SECTION_MOVED:
    moveSection(action.sections);
    store.markChanged();
    break;
  case MultipleChoiceOptionConstants.ADDED:
    addMultipleChoiceOption(action.stepDatumId, action.optionAttrs);
    store.markChanged();
    break;
  case MultipleChoiceOptionConstants.DESTROYED:
    removeMultipleChoiceOption(action.optionId);
    store.markChanged();
    break;
  case MultipleChoiceOptionConstants.UPDATED:
    updateMultipleChoiceOption(action.optionId, action.optionAttrs);
    store.markChanged();
    break;
  case MultipleChoiceOptionConstants.MOVED:
    moveMultipleChoiceOption(action.options);
    store.markChanged();
    break;
  case Constants.EMAIL_NOTIFICATION_ADDED:
    addEmailNotification(action.stepId, action.attrs);
    store.markChanged();
    break;
  case Constants.EMAIL_NOTIFICATION_UPDATED:
    updateEmailNotification(action.emailNotificationId, action.attrs);
    store.markChanged();
    break;
  case Constants.EMAIL_NOTIFICATION_DESTROYED:
    destroyEmailNotification(action.notificationId);
    store.markChanged();
    break;
  case Constants.REVIEW_GROUP_ADDED:
    addReviewGroup(action.groupAttrs);
    store.markChanged();
    break;
  case Constants.REVIEW_GROUP_UPDATED:
    updateReviewGroup(action.groupAttrs);
    store.markChanged();
    break;
  case Constants.REVIEW_GROUP_DELETED:
    destroyReviewGroup(action.reviewGroupId);
    store.markChanged();
    break;
  case Constants.STEP_ASSIGNMENT_GROUP_CREATED:
    addAssignmentGroup(action.group);
    store.markChanged();
    break;
  case Constants.STEP_ASSIGNMENT_GROUP_DELETED:
    deleteAssignmentGroup(action.id);
    store.markChanged();
    break;
  case Constants.STEP_ASSIGNMENT_CREATED:
    addAssignment(action.stepAssignment);
    store.markChanged();
    break;
  case Constants.STEP_ASSIGNMENT_DELETED:
    removeAssignment(action.id);
    store.markChanged();
    break;
  case Constants.SURVEY_UPDATED:
    updateSurvey(action.survey);
    store.markChanged();
    break;
  case Constants.SURVEY_SECTION_ADDED:
    addSurveySection(action.section, action.stepId);
    store.markChanged();
    break;
  case Constants.SURVEY_SECTION_COPIED:
    addSurveySection(action.section, action.stepId);
    action.section.step_data.forEach((stepDatum) => {
      addDataRow(action.section.id, stepDatum);
    });

    store.markChanged();
    break;
  case Constants.LOGIC_CONFIG_ADDED:
    addLogicConfig(action.logicConfig);
    store.markChanged();
    break;
  case Constants.LOGIC_CONFIG_DESTROYED:

    // When destroying logic on an assignment group,
    // the members of the group must be split out into
    // their own individual groups.

    if (action.newStepAssignmentGroups) {

      // At time of writing, it's impossible for there
      // to not be at least one assignment group in the
      // response.
      const stepId = action.newStepAssignmentGroups[0].step_id;

      replaceAssignmentGroups(stepId, action.newStepAssignmentGroups);
    } else {
      removeLogicConfig(action.logicConfig);
    }
    store.markChanged();
    break;
  case AttachedPeopleConstants.TOGGLE_PERSON:
    toggleAttachedPerson(action.attachedPerson, action.attachableId, action.attachableType);
    store.markChanged();
    break;
  case AttachedPeopleConstants.UPDATE_ATTACHMENT:
    updateRoleUsers(action.attachment, action.roleId, action.attachableId, action.attachableType);
    store.markChanged();
    break;
  case Constants.OUTPUT_UPDATED:
    updateOutput(action.output);
    store.markChanged();
    break;
  case Constants.RENEWAL_REMINDER_CREATED:
    addRenewalReminder(action.attrs);
    store.markChanged();
    break;
  case Constants.RENEWAL_REMINDER_UPDATED:
    updateRenewalReminder(action.attrs);
    store.markChanged();
    break;
  case Constants.RENEWAL_REMINDER_DELETED:
    removeRenewalReminder(action.attrs);
    store.markChanged();
    break;
  default:
    // Nothing
  }
});

export default store;
