import React from 'react';
import renderer from 'modules/renderer';
import Quicklaunch from 'components/quicklaunch';
import AlertConfirm from 'modules/alert-confirm';
import CommonProviders from 'components/common-providers';
import PublicNavMenu from 'components/shared/public-nav-menu';
import { error as flashError, render as flashRender } from 'components/shared/flash';
import HeaderDisc from 'components/header-disc';
import HelpLinks from '../components/help-links';

function init() {
  configOfflineJS();
  identify();
  initFlash();
  initPublicNav();
  initHamburgerMenu();
  initProfileDisc();
  configureDefaultAjaxErrors();
  initQuicklaunch();
}

function configureDefaultAjaxErrors() {
  window.jQuery.ajaxSetup({
    error: (e) => {
      if (e.status === 429) {
        flashError('You\'re doing that too much. Wait a moment before trying again.');
        Rollbar.warning('RateLimited');
      }
    },
  });
}

function configOfflineJS() {
  Offline.options = { checks: { xhr: { url: '/images/pixel.png' } } };
}

function identify() {
  const userId = CityGrows.Server.currentUserId;
  if (userId) {
    if ((typeof analytics !== 'undefined')) {
      // Segment will only be available on prod
      analytics.identify(userId);
    }

    const cgID = CityGrows.Server.currentUser.cgId;

    if ((typeof ChurnZero !== 'undefined') && cgID) {
      ChurnZero.push(['setContact', cgID, CityGrows.Server.currentUser.email]);
      ChurnZero.push(['setModule', 'ClearForms']);
    }
  }
}

function initFlash() {
  CityGrows.Server.FlashMessages.forEach((message) => {
    switch (message.type) {
    case 'custom_display':
      return;
    case 'popup':
      const { title, body } = JSON.parse(message.message);
      AlertConfirm.alert(title, body);
      break;
    default:
      flashRender(message);
    }
  });
}

function initPublicNav() {
  const $el = document.getElementById('public-nav-links');
  if ($el) {
    renderer(
      (
        <CommonProviders>
          <PublicNavMenu currentUser={CityGrows.Server.currentUser} light/>
        </CommonProviders>
      ), $el,
    );
  }
}

function initProfileDisc() {
  const $el = document.getElementById('profile-disc');

  if ($el) {
    renderer(
      (
        <CommonProviders>
          <HeaderDisc avatar={CityGrows.Server.currentUser.avatarUrl} />
        </CommonProviders>
      ), $el,
    );
  }
}


function initHamburgerMenu() {
  $(document).on('click', '.hamburger', (e) => {
    const $popup = $('.hamburger .popup');
    if (!$popup[0]) { return; }
    if ($popup[0].contains(e.target)) { return; }

    const $menu = $('.hamburger')
    $($menu).toggleClass('is-active');
  });

  $(document).click((e) => {
    const $menu = $('.hamburger');
    if (!$menu[0]) { return; }

    if (!$menu[0].contains(e.target) && $menu.hasClass('is-active')) {
      $menu.removeClass('is-active');
    }
  });
}

function initQuicklaunch() {
  const $el = document.getElementById('quicklaunch');
  if ($el) {
    renderer(
      (
        <CommonProviders>
          <Quicklaunch />
          <HelpLinks />
        </CommonProviders>
      ), $el,
    );
  }
}

export default init;
