import React, { useEffect, useRef } from 'react';
import truncate from 'truncate';

const TasksListItem = ({active, changeActiveSubmission, task}) => {
  const itemRef = useRef();
  // useEffect(() => {
  //   if (active) {
  //     itemRef.current.scrollIntoView();
  //   }
  // }, [active]);

  function renderOverdueLabel() {
    return (
      <span className='overdue'>Overdue</span>
    );
  }

  return (
    <li className={`tasklist-item ${active ? 'active' : ''}`} key={task.id} ref={itemRef}>
      <a href='#' onClick={() => {changeActiveSubmission(task.id)}} >
        <div>
          <h3 className='inline-block'>{`#${task.scoped_id} ${truncate(task.template_name, 35)}`}</h3>
          {task.overdue && renderOverdueLabel()}
        </div>
        <span>{`${task.primary_applicant_name} - ${task.time_elapsed_string}`}</span>
        <div>{task.step_type}</div>
      </a>
    </li>
  );
};

export default TasksListItem;
