import React, { useState } from 'react';
import Flash from './shared/flash';
import UserAPI from '../apis/user-api';
import DelayedRedirect from './delayed-redirect';

const UnverifiedEmailApp = () => {
  const [textInfo, setTextInfo] = useState('We\'ve sent you an email to verify your email address and activate your account.');
  const [redirect, setRedirect] = useState(false);

  function onSendVerificationEmailClick(e) {
    e.preventDefault();
    UserAPI.sendVerificationEmail().done((res) => {
      if (res.sent) {
        setTextInfo(res.text);
      } else {
        Flash.error(res.text);
      }
      setRedirect(true);
    }).fail((r) => Flash.error(r.responseText));
  }
  return (
    <div className='minimalcontentcontainer text-center'>
      <h1>Confirm your email address to start using ClearForms</h1>
      <p>{textInfo}</p>
      {
        redirect
          ? <DelayedRedirect to='/' waitFor={5} />
          : <a id='resend-email' href='#' onClick={onSendVerificationEmailClick}>Re-send verification email</a>
      }
    </div>
  );
};
export default UnverifiedEmailApp;
