import React from 'react';
import PropTypes from 'prop-types';
import Accounting from 'accounting';
import TimeAgo from 'react-timeago';

class Receipt extends React.Component {
  refundReason() {
    if (!this.props.admin || !this.props.charge.refund_reason) { return; }

    return (
      <div>
        <div className='cell label'>Refund Reason:</div>
        <div className='cell'>{this.props.charge.refund_reason || '---'}</div>
      </div>
    );
  }

  checkNumber = () => {
    const number = this.props.activeStep.check_number;
    if (!number || number.length === 0) {
      return;
    }

    return (
      <div>
        <div className='cell label'>Check Number:</div>
        <div className='cell'>{number}</div>
      </div>
    );
  };

  nonChargeReceipt = () => {
    const paymentMethod = (this.props.activeStep.check_paid_amount ? 'check' : 'cash');
    let amountPaid;

    switch (paymentMethod) {
    case 'check':
      amountPaid = this.props.activeStep.check_paid_amount;
      break;
    case 'cash':
      amountPaid = this.props.activeStep.cash_paid_amount;
      break;
    }

    const formattedAmount = Accounting.formatMoney(amountPaid / 100);

    return (
      <span>
        <div className='receipttable'>
          <div>
            <div className='cell label'>Paid:</div>
            <div className='cell color-green bold'>{formattedAmount}</div>
          </div>

          <div>
            <div className='cell label'>Method:</div>
            <div className='cell'>{paymentMethod}</div>
          </div>
          {paymentMethod === 'check' && this.checkNumber()}
        </div>
      </span>
    );
  };

  renderPendingText = () => {
    if (this.props.charge.method === 'ACH Payment') {
      return 'Processing - may take 4 business days to complete';
    }

    return 'Processing';
  };

  renderStatus = () => {
    switch (this.props.charge.status) {
    case 'refunded':
      return <span>Refunded <TimeAgo className='timeago' date={this.props.charge.refunded_at} /></span>;
    case 'disputed':
      return <span className='text-semialert'>Disputed</span>;
    case 'pending':
      return <span>{this.renderPendingText()}</span>;
    case 'refund_pending':
      return <span>Processing Refund</span>;
    case 'paid':
      return <span className=''>Processed</span>;
    case 'failed':
      return <span className='text-alert bold'>Failed</span>;
    }
  };

  refundDidRequireAuthorization = () => {
    return this.props.charge.refunded_at && this.props.charge.refund_authorizer;
  };

  renderAuthorizationDetails = () => {
    // Only admins should see this

    if (!this.props.admin || !this.refundDidRequireAuthorization()) { return; }

    return (
      <>
        <tr>
          <td>Refund requested by:</td>
          <td>{this.props.charge.refund_requester.name}</td>
        </tr>

        <tr>
          <td>Refund request time:</td>
          <td><TimeAgo date={this.props.charge.refund_requested_at} className='timeago' /></td>
        </tr>

        <tr>
          <td>Refund authorized by:</td>
          <td>{this.props.charge.refund_authorizer.name}</td>
        </tr>
      </>
    );
  };

  renderProcessed = () => {
    return (
      <div>
        <div className='cell label'>Processed:</div>
        <div className='cell'><TimeAgo date={this.props.charge.created_at} className='timeago' /></div>
      </div>
    );
  };

  renderFailureDetails = () => {
    if (this.props.charge.status === 'refund_failed' || this.props.charge.status === 'failed') {
      return (
        <div>
          <div className='cell label'>Details:</div>
          <div className='cell'><span>{this.props.charge.failure_message}</span></div>
        </div>
      );
    }
  };

  chargeReceipt = () => {
    const showDownloadLink = this.props.charge.status === 'paid' || this.props.charge.status === 'refunded';

    return (
      <div>
        <div className='receipttable'>
          <div>
            <div className='cell label'>Amount:</div>
            <div className='cell color-green bold'>{Accounting.formatMoney(this.props.charge.amount / 100)}</div>
          </div>

          <div>
            <div className='cell label'>Method:</div>
            <div className='cell'>{this.props.charge.method}</div>
          </div>

          <div>
            <div className='cell label'>By:</div>
            <div className='cell'>{this.props.charge.user.name}</div>
          </div>

          {this.props.charge.status === 'paid' && this.renderProcessed()}

          <div>
            <div className='cell label'>Status:</div>
            <div className='cell'>{this.renderStatus()}</div>
          </div>

          {this.renderFailureDetails()}
          {this.renderAuthorizationDetails()}
          {this.refundReason()}
        </div>

        {showDownloadLink && <a className='btn-secondary block margin-top' target='_blank' href={`/charges/${this.props.charge.confirmation_number}.pdf`} rel='noreferrer'>Download receipt</a>}
      </div>
    );
  };

  render() {
    if (this.props.charge) {
      return this.chargeReceipt();
    }
    return this.nonChargeReceipt();
  }
}

Receipt.propTypes = {
  admin: PropTypes.bool.isRequired,
};

export default Receipt;
