import React from "react";

import Select from "../select";
import { DATE_TERMS } from "./constants";


const DateTerm = ({
  changeFilterValue,
  changeFilterValueOptions,
  filterState,
  minify,
}) => {
  const defaultTerm = DATE_TERMS.find((opt) => opt.id == filterState.options.term);

  const onValueChange = (e) => {
    changeFilterValue(e.target.value);
  };

  const onTermChange = (value) => {
    changeFilterValueOptions({
      term: value.id
    });
  };

  return (
    <>
      <div className='metricsdashboard-filterselect'>
        <input
          className='number-field'
          type='number'
          onChange={onValueChange}
          value={filterState.value}
        />
      </div>
      <div className='metricsdashboard-filterselect'>
        <Select
          options={DATE_TERMS}
          onChange={onTermChange}
          getOptionValue={(opt) => opt.id}
          value={defaultTerm}
          className='react-select-metrics advanced-filter'
        />
      </div>
    </>
  );
};

export default DateTerm;
