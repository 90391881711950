import renderer from 'modules/renderer';
import CommonProviders from 'components/common-providers';
import MetricsDashboard from 'components/metrics-dashboard';

const index = () => {
  renderer(
    (
      <CommonProviders>
        <MetricsDashboard 
          formData={CityGrows.Server.formData} 
          revenueData={CityGrows.Server.revenueData}
          teams={CityGrows.Server.teams}
          forms={CityGrows.Server.forms}
        />
      </CommonProviders>
    ), document.getElementById('metricsdashboard'),
  );
}

export default {
  index,
};
