import SmartyStreetsSDK from 'smartystreets-javascript-sdk';

const SmartyStreetsCore = SmartyStreetsSDK.core;
const { Lookup } = SmartyStreetsSDK.usAutocompletePro;

class SmartyStreetsClient {
  constructor() {
    if (!CityGrows.Server.websiteKey) {
      throw 'Smarty streets website key missing';
    }

    const credentials = new SmartyStreetsCore.SharedCredentials(CityGrows.Server.websiteKey);
    let clientBuilder = new SmartyStreetsCore.ClientBuilder(credentials).withLicenses(["us-autocomplete-pro-cloud"])

    this.client = clientBuilder.buildUsAutocompleteProClient();
  }

  getSuggestions(address) {
    if (address.length < 1) {
      const response = $.Deferred();
      return response.resolve({ result: [] });
    }

    const lookup = new Lookup(address);
    lookup.source = 'all';
    return this.client.send(lookup);
  }
}

export default SmartyStreetsClient;
