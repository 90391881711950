import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';

import Portal from 'components/shared/portal';
import Excerpt from 'components/shared/excerpt';

const typeToUrlSegment = {
  InitiatedFlow: 'initiated_flows',
  ImportedFlow: 'imported_flows',
};

const urlForItem = (item) => `/${typeToUrlSegment[item.dashboardable_type]}/${item.dashboardable_id}`;

const getPopupPosition = (anchorRef) => {
  if (!anchorRef.current) {
    return {};
  }
  const { left, top } = window.$(anchorRef.current).offset();
  return { left, top: top + 50 };
};
const MasterSearchResults = ({ results, searchTerms, anchorRef }) => {
  const { top, left } = getPopupPosition(anchorRef);

  let resultsHTML;

  if (!results) {
    resultsHTML = <div className='empty'>Searching</div>;
  } else if (isEmpty(results)) {
    resultsHTML = <div className='empty'>No results</div>;
  } else {
    resultsHTML = results.map((item) => (
      <a
        href={urlForItem(item)}
        key={item.dashboardable_id + item.dashboardable_type + item.collected_datum_id}
        className='dashboard-mastersearchresult'
      >

        <div>{`${item.template_name} #${item.scoped_id || '---'}`}</div>
        {item.identifier && <div>{item.identifier}</div>}
        <sub>
          <Excerpt
            input={item.match}
            phrase={searchTerms}
            options={{ paddingChars: 25 }}
          />
        </sub>
      </a>
    ));
  }

  return (
    <Portal>
      <ul style={{ top, left }} className='dashboard-filters-searchresults raised bg-white'>
        {resultsHTML}
      </ul>
    </Portal>
  );
};

MasterSearchResults.propTypes = {
  results: PropTypes.array,
  anchorRef: PropTypes.object,
  searchTerms: PropTypes.string,
};

export default MasterSearchResults;
