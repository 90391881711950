import PropTypes from 'prop-types';
import React, { Component } from 'react';

class ExistingAccountStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
    };
  }

  getInitialState() {
    return {
      password: '',
    };
  }

  isIncomplete = () => {
    return !this.state.password;
  };

  onChange = (e) => {
    this.setState({
      password: e.currentTarget.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.onSubmit(this.refs.password.value);
  };

  render() {
    return (
      <div>
        <h1>We found an existing account for that email</h1>
        <span>Please enter your password for <strong>{this.props.email}</strong></span>

        <form className='center' onSubmit={this.onSubmit}>
          <label>Password
            <input onChange={this.onChange} autoFocus ref='password' type='password' />
          </label>

          <button className='btn-primary'>Launch submission</button>

          <a href='/users/password/new'>I forgot my password</a>
        </form>
      </div>
    );
  }
}

ExistingAccountStep.propTypes = {
  email: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ExistingAccountStep;
