import React from 'react';
import Bar from 'components/shared/chart/bar';

class TemplateStats extends React.Component {
  render() {
    return (
      <Bar
        data={this.props.data}
        options={{
          plugins: {
            legend: { display: false },
          },
        }}
      />
    );
  }
}

export default TemplateStats;
