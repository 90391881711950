import EntityAPI from '../apis/entity-api';
import EditSuggestionsAPI from '../apis/edit-suggestions-api';
import {
  error as flashError,
  success as flashSuccess,
} from '../components/shared/flash';

const EntityActions = {
  createEntity(attrs, initiatedFlowId, stepDatumId) {
    const req = EntityAPI.create(attrs, initiatedFlowId, stepDatumId);

    req.fail(() => flashError());

    return req;
  },

  createEsriEntity(esriUniqueId, initiatedFlowId, stepDatumId) {
    const req = EntityAPI.createEsri(esriUniqueId, initiatedFlowId, stepDatumId);

    req.fail(() => flashError());

    return req;
  },

  fetchSuggested(userId, flowTemplateId) {
    return EntityAPI.fetchSuggested(userId, flowTemplateId);
  },

  getEntity(entityId, initiatedFlowId) {
    return EntityAPI.getEntity(entityId, initiatedFlowId);
  },

  search(initiatedFlowId, stepDatumId, terms) {
    return EntityAPI.search(initiatedFlowId, stepDatumId, terms);
  },

  toggleDestroyed(entityId) {
    return EntityAPI.toggleDestroyed(entityId)
            .fail(() => flashError());
  },

  update(id, attrs) {
    return EntityAPI.update(id, attrs)
            .done((res) => {
              flashSuccess('Update successful');
              return res;
            })
            .fail(() => flashError());
  },

  suggestEdits(id, attrs) {
    const req = EditSuggestionsAPI.suggest(id, attrs);

    req.done(() => {
      flashSuccess('Edits submitted');
    });

    req.fail(() => flashError('Something went wrong'));

    return req;
  },
};

export default EntityActions;
