import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import MentionsManager from './text-editor/mentions-manager';

/// / mentions setup

const Embed = Quill.import('blots/embed');

class Mention extends Embed {
  static create(value) {
    const node = super.create();
    node.setAttribute('data-id', value.id);
    node.setAttribute('data-object-type', value.type);
    node.textContent = value.text;

    return node;
  }

  static value(domNode) {
    return {
      id: domNode.dataset.id,
      type: domNode.dataset.objectType,
      text: domNode.textContent,
    };
  }
}

Mention.blotName = 'mention';
Mention.tagName = 'span';
Mention.className = 'mention';

Quill.register(Mention);

/// ////////////////////////////////////

const DEFAULT_QUILL_TOOLBAR = ['bold', 'italic', 'strike', 'underline', 'link', { list: 'ordered' }, { list: 'bullet' }, { color: [] }, { background: [] }];
const DEFAULT_QUILL_FORMATS = ['bold', 'italic', 'strike', 'underline', 'link', 'color', 'background', 'list', 'mention'];

class TextEditor extends Component {
  constructor(props) {
    super(props);

    this.formats = props.formats;

    this.modules = {
      toolbar: this.props.toolbar,
    };

    this.state = {
      content: '',
      cursorPos: null,
    };
  }

  get quillEditor() {
    return this.quill.getEditor();
  }

  onChange = (content) => {
    if (!this.quill) return;

    this.props.onChange(content);

    const selection = this.quillEditor.getSelection();

    this.setState({
      cursorPos: selection ? selection.index : this.state.cursorPos,
      content,
    });
  };

  onMentionInsert = (type, id, text) => {
    // find position of query text
    let pos = this.state.cursorPos;
    while (this.quillEditor.getText(pos, 1) !== '@') pos--;

    // delete the query text
    this.quillEditor.deleteText(pos, ((this.state.cursorPos - pos) || 1));

    // insert mention
    this.quillEditor.insertEmbed(pos, 'mention', { type, text, id });

    // place cursor after mention
    this.quillEditor.setSelection(pos + 1, 0);
  };

  getInputElement = () => {
    return $(this.quill.refs.editor).find('.ql-editor')[0];
  };

  render() {
    return (
      <div className='texteditor'>

        <ReactQuill
          ref={(el) => { this.quill = el; }}
          defaultValue={this.props.content}
          theme='snow'
          formats={this.formats}
          modules={this.modules}
          onChange={this.onChange}
        />

        <MentionsManager
          data={this.props.formattedMentionData}
          onMentionInsert={this.onMentionInsert}
          html={this.props.content || ''}
          getInputElement={this.getInputElement}
        />

      </div>
    );
  }
}

TextEditor.defaultProps = {
  formattedMentionData: [],
  onChange: () => {},
  toolbar: DEFAULT_QUILL_TOOLBAR,
  formats: DEFAULT_QUILL_FORMATS,
};

TextEditor.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  formattedMentionData: PropTypes.array,
  onChange: PropTypes.func,
  toolbar: PropTypes.array,
  formats: PropTypes.formats,
};

export default TextEditor;
