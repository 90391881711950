import React from 'react';
import renderer from 'modules/renderer';
import TeamPage from 'components/team-page';
import CommonProviders from 'components/common-providers';
import Directory from './directory';
import PrimaryTabs from '../components/shared/primary-tabs';

function initTabs() {
  renderer(<PrimaryTabs />, document.getElementById('tabs'));
}

function index() {
  initTabs();
}

function knew() {
  initTabs();
}

function show() {
  initTabs();
  renderer(
    (
      <CommonProviders>
        <TeamPage
          currentUserId={CityGrows.Server.currentUserId}
          team={CityGrows.Server.team}
          disabled={CityGrows.Server.disabled}
          timeZone={CityGrows.Server.timeZone}
        />
      </CommonProviders>
    ), document.getElementById('team'),
  );
}

function people() {
  Directory.init();
}

export default {
  index,
  new: knew,
  show,
  people,
};
