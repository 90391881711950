import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Route, HashRouter as Router, useHistory } from 'react-router-dom';
import { Switch } from 'react-router';
import LaunchManagerActions from 'actions/launch-manager-actions';
import InitiatedFlowActions from 'actions/initiated-flow-actions';
import UserActions from 'actions/user-actions';
import NotifBanner from 'components/shared/notif-banner';
import ErrorBoundary from 'components/shared/error-boundary';
import ExistingProcessesStep from './launch_manager/existing-processes-step';
import ExistingAccountStep from './launch_manager/existing-account-step';
import EmailStep from './launch_manager/email-step';
import LaunchingLoader from './launch_manager/launching-loader';
import PersonalInfoStep from './launch_manager/personal-info-step';
import PasswordStep from './launch_manager/password-step';

const LaunchManager = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [existingAccount, setExistingAccount] = useState(null);
  const [currentUser, setCurrentUser] = useState(props.currentUser);
  const [existingProcesses, setExistingProcesses] = useState();

  const history = useHistory();

  useEffect(() => {
    if (currentUser) {
      existingProcessCheck();
    } else {
      history.replace('/email');
    }
  }, []);

  useEffect(() => {
    if (!email) { return; }

    checkEmailAndRedirect();
  }, [email]);

  useEffect(() => {
    if (!password) { return; }

    authenticateUser().done(existingProcessCheck);
  }, [password]);

  const redirectTo = (path) => {
    history.push(path);
  };

  const onBackClick = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const checkEmailAndRedirect = () => {
    LaunchManagerActions.checkIfAccountExists(email)
      .done(handleEmailCheckResponse);
  };

  const handleEmailCheckResponse = (accountExists) => {
    if (accountExists) {
      setExistingAccount(true);
      redirectTo('/existing');
    } else {
      redirectTo('/personal-info');
    }
  };

  const onEmailSubmit = (newEmail) => {
    setEmail(newEmail);
  };

  const onPersonalInfoSubmit = (info) => {
    setName(info.name);
    redirectTo('/password');
  };

  const onPasswordSubmit = (newPassword) => {
    setPassword(newPassword);
  };

  const authenticateUser = () => {
    let authRequest;

    if (currentUser || existingAccount) {
      authRequest = UserActions.login(email, password);
    } else {
      authRequest = UserActions.signup(email, password, name, 'citizen');
    }

    return authRequest.done(setCurrentUser);
  };

  const existingProcessCheck = () => {
    LaunchManagerActions.previouslyLaunched(props.masterTemplateId).done((existing) => {
      if (canUseExistingProcesses() && existing.length) {
        setExistingProcesses(existing);
        redirectTo('/processes');
      } else {
        redirectTo('/launching');
        launchChain();
      }
    });
  };

  const canUseExistingProcesses = () => {
    if (props.subprocessRequirement && !props.subprocessRequirement.allow_link_existing) { return false; }

    return true;
  };

  const postAuth = () => {
    onLaunchFlowComplete();
  };

  const onLaunchFlowComplete = () => {
    redirectTo('/launching');
    launchChain();
  };

  const launchChain = () => {
    const opts = {
      subprocessRequirementId: props.subprocessRequirement?.id,
      primaryApplicantId: props.primaryApplicant?.id,
      secondaryApplicantIds: props.secondaryApplicantIds,
    };
    InitiatedFlowActions.launchProcess(props.flowTemplate.id, opts)
                        .fail(history.goBack);
  };

  const emailStepRenderer = () => {
    return (
      <EmailStep defaultValue={email} onSubmit={onEmailSubmit} />
    );
  };

  const existingAccountStepRenderer = () => {
    return (
      <ExistingAccountStep
        onSubmit={onPasswordSubmit}
        email={email}
      />
    );
  };

  const personalInfoStepRenderer = () => {
    return (
      <PersonalInfoStep
        defaultNameValue={name}
        onBackClick={onBackClick}
        onSubmit={onPersonalInfoSubmit}
      />
    );
  };

  const passwordStepRenderer = () => {
    return (
      <PasswordStep
        onBackClick={onBackClick}
        onSubmit={onPasswordSubmit}
      />
    );
  };

  const existingProcessStepRenderer = () => {
    return (
      <ExistingProcessesStep
        initiatedFlows={existingProcesses}
        subprocessRequirementId={props.subprocessRequirement?.id}
        onNextClick={postAuth}
      />
    );
  };

  const launchingLoaderRenderer = () => {
    return (
      <LaunchingLoader flowTemplate={props.flowTemplate} />
    );
  };

  return (
    <>
      <div>
        <NotifBanner>
          You are starting <strong>{props.flowTemplate.name}</strong>
        </NotifBanner>
        <div className='launchmanager-maincontent'>
          <div className='minimalcontentcontainer launchmanager'>
            <Switch>
              <Route path='/email' render={emailStepRenderer} />
              <Route path='/existing' render={existingAccountStepRenderer} />
              <Route path='/personal-info' render={personalInfoStepRenderer} />
              <Route path='/password' render={passwordStepRenderer} />
              <Route path='/launching' render={launchingLoaderRenderer} />
              <Route path='/processes' render={existingProcessStepRenderer} />
              <Route render={() => <div className='progress' />} />
            </Switch>
          </div>
        </div>
      </div>
      <div className='decorativefooter' />
    </>
  );
};

const withRouter = (props) => {
  return (
    <Router>
      <ErrorBoundary>
        <LaunchManager {...props} />
      </ErrorBoundary>
    </Router>
  );
};

LaunchManager.propTypes = {
  currentUser: PropTypes.object,
  flowTemplate: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  masterTemplateId: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  subprocessRequirement: PropTypes.shape({
    id: PropTypes.number.isRequired,
    allow_link_existing: PropTypes.bool.isRequired,
  }).isRequired,
  primaryApplicant: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  secondaryApplicantIds: PropTypes.arrayOf(PropTypes.number),
};

export default withRouter;
