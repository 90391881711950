import React from 'react';
import ReactDOM from 'react-dom'
import renderer from 'modules/renderer';
import _ from 'underscore';
import Shield from './shield';
import SignaturePrompt from '../components/module_views/signature-prompt';

let el;
let _promise;

function requestSignature(activeStepId) {
  _promise = $.Deferred();

  Shield.on();

  let $container = $('#signaturerequesterwrap');
  if ($container.length === 0) {
    $container = $('<div id="signaturerequesterwrap">');
    $('body').append($container);
  }

  el = renderer(
    <SignaturePrompt activeStepId={activeStepId} onSuccess={onSuccess} onCancel={onCancel} />,
    document.getElementById('signaturerequesterwrap'),
  );

  return _promise;
}

function onSuccess(res) {
  unmount();
  _promise.resolve(res);
}

function onCancel() {
  unmount();
  _promise.resolve(false);
}

function unmount() {
  ReactDOM.unmountComponentAtNode(document.getElementById('signaturerequesterwrap'));
  Shield.off();
}

export default {
  requestSignature,
};
