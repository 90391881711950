
import NProgress from 'nprogress';
import Dispatcher from '../appdispatcher';
import Constants from '../constants/template-manager-constants';
import FlowTemplateAPI from '../apis/flow-template-api';
import MasterTemplateAPI from '../apis/master-template-api';
import CustomIdAPI from '../apis/custom-id-api';
import Flash from '../components/shared/flash';
import PollableJob from '../modules/pollable-job';
import TemplateCategoryAPI from '../apis/template-category-api';

// TODO: refactor so this isn't necessary
let _publishTemplatePromise;

const TemplateManagerActions = {
  loadModificationStatus(masterTemplateId) {
    MasterTemplateAPI.modified(masterTemplateId)
                     .done((res) => {
                       Dispatcher.dispatch({
                         actionType: Constants.FLOW_TEMPLATE_UPDATED,
                         attrs: {
                           is_modified: res.modified,
                         },
                       });
                     });
  },

  fetchSearchStepDataOptions(masterTemplateId) {
    return MasterTemplateAPI.stepData(masterTemplateId);
  },

  fetchSubprocessOptions(teamId) {
    return MasterTemplateAPI.index(teamId);
  },

  setFlowTemplate(flowTemplate) {
    Dispatcher.dispatch({
      actionType: Constants.FLOW_TEMPLATE_LOADED,
      flowTemplate,
    });
  },

  setLaunchData(launchData) {
    Dispatcher.dispatch({
      actionType: Constants.SET_LAUNCH_DATA,
      flowTemplate: launchData,
    });
  },

  updateFlowTemplate(flowTemplateId, attrs) {
    return FlowTemplateAPI.update(flowTemplateId, attrs)
            .done(() => this.triggerUpdateEvent(attrs));
  },

  updateMasterTemplate(masterTemplateId, attrs) {
    MasterTemplateAPI.update(masterTemplateId, attrs)
                      .done(this.onMasterTemplateUpdateSuccess.bind(this))
                      .fail(() => {
                        Flash.error('Sorry, there was a problem updating this form.');
                      });
  },

  triggerUpdateEvent(attrs) {
    Dispatcher.dispatch({
      actionType: Constants.FLOW_TEMPLATE_UPDATED,
      attrs,
    });
  },

  onMasterTemplateUpdateSuccess(template) {
    this.dispatchMasterTemplateLoaded(template);
    Flash.success('Update successful');
  },

  changeTemplateStatus(masterTemplateId, newStatus) {
    MasterTemplateAPI.update(masterTemplateId, { status: newStatus })
                     .done((masterTemplate) => {
                       this.dispatchMasterTemplateLoaded(masterTemplate);
                       Flash.success('Form status updated');
                     })
                     .fail(() => Flash.error('Form status update failed'));
  },

  onSlugSave(masterTemplateId, newSlug) {
    NProgress.start();

    return MasterTemplateAPI.update(masterTemplateId, {
      slug: newSlug,
    })
    .done(() => {
      Dispatcher.dispatch({
        actionType: Constants.SLUG_UPDATED,
        newSlug,
      });

      Flash.success('Form URL updated');
    })
    .fail(() => { Flash.error(); })
    .always(NProgress.done);
  },

  onCustomIdSave: (masterTemplateId, pattern) => {
    return CustomIdAPI.create(masterTemplateId, pattern)
                      .done((customIdAttrs) => {
                        if (customIdAttrs.errors.length > 0) {
                          Flash.error(customIdAttrs.errors[0]);
                          return;
                        }

                        Dispatcher.dispatch({
                          actionType: Constants.CUSTOM_ID_CREATED,
                          customIdAttrs,
                        });
                      })
                      .fail(() => { Flash.error(); });
  },

  onCustomIdDelete: (masterTemplateId, pattern) => {
    return CustomIdAPI.destroy(masterTemplateId, pattern)
                      .done(() => {
                        Flash.success('Custom ID removed');

                        Dispatcher.dispatch({
                          actionType: Constants.CUSTOM_ID_DELETED,
                        });
                      })
                      .fail(() => { Flash.error(); });
  },

  dispatchMasterTemplateLoaded(masterTemplate) {
    Dispatcher.dispatch({
      actionType: Constants.MASTER_TEMPLATE_LOADED,
      masterTemplate,
    });
  },

  publish(flowTemplateId) {
    NProgress.start();

    const jobRunner = new PollableJob(() => { return FlowTemplateAPI.publish(flowTemplateId); });
    jobRunner.run()
      .done(this.onPublishJobCompleted.bind(this))
      .fail(() => { Flash.error(); })
      .always(() => { NProgress.done(); });

    _publishTemplatePromise = $.Deferred();
    return _publishTemplatePromise;
  },

  markChanged() {
    Dispatcher.dispatch({
      actionType: Constants.MARK_FLOW_TEMPLATE_CHANGED,
    });
  },

  onPublishJobCompleted(response) {
    const result = JSON.parse(response);
    if (result.hasOwnProperty('template_data')) {
      this.onTemplatePublished(result.template_data);
    } else if (result.hasOwnProperty('errors')) {
      this.onTemplatePublishFailed(result.errors);
    } else {
      throw 'Publish job yielded unknown result';
    }
  },

  onTemplatePublished(template_data) {
    const flowTemplate = Object.assign(template_data.flow_template, { is_modified: false });
    this.setFlowTemplate(flowTemplate);
    this.dispatchMasterTemplateLoaded(template_data.master_template);

    Flash.success('Form has been published');

    _publishTemplatePromise.resolve();
    NProgress.done();
  },

  onTemplatePublishFailed(errors) {
    _publishTemplatePromise.reject();
    NProgress.done();

    Flash.error(errors[0]);
  },

  onTemplateCategorySave: (name) => {
    return TemplateCategoryAPI.create(name);
  },

};

export default TemplateManagerActions;
