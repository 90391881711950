import React, { useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import { alert } from 'modules/alert-confirm';
import PaymentStepActions from 'actions/payment-step-actions';
import LoadingSpinner from 'components/shared/loading-spinner';
import AmountBreakdown from './amount-breakdown';
import Amount from './amount';

const CreditCardForm = (props) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);
  const number = useRef();
  const cvc = useRef();
  const expMonth = useRef();
  const expYear = useRef();
  const first = useRef();
  const last = useRef();

  const [amount, setAmount] = useState(props.amount);
  const [processing, setProcessing] = useState(false);
  const [editingAmount, setEditingAmount] = useState(!props.config.fixed);

  function onAmountSave(amnt) {
    setAmount(Number(amnt));
    setEditingAmount(false);
  }

  function submitClicked() {
    if (processing) { return; }

    setProcessing(true);

    const formAttrs = {
      number: number.current.value,
      verification: cvc.current.value,
      month: expMonth.current.value,
      year: expYear.current.value,
      amount,
      first: first.current.value,
      last: last.current.value,
    };

    // Clean amount
    if (!$.isNumeric(formAttrs.amount) || formAttrs.amount <= 0) {
      alert('Whoops', 'Payment amount must be a valid number. Check your input and try again.');
      endProcessingState();
      return;
    }

    formAttrs.amount = String(formAttrs.amount).replace(/\$/g, '');

    // PaymentStepActions.submitPayment(dispatch, props.activeStep.payment_id, formAttrs, props.type)
    //                   .fail(endProcessingState);
  }

  function endProcessingState() {
    setProcessing(false);
  }

  function submitStatus() {
    if (processing) {
      return (
        <div className='inline-block'>
          <LoadingSpinner className='vtop' />
          <span> Processing your payment</span>
        </div>
      );
    }
  }

  function renderSubmitButton() {
    const disabledClass = processing ? 'disabled' : '';

    return (
      <input
        type='submit'
        disabled={props.disabled}
        onClick={submitClicked}
        className={`btn-primary margin-top margin-bottom margin-right ${disabledClass}`}
        value='Submit payment'
        id='submitpaymentbutton'
      />
    );
  }

  return (
    <span>
      <Amount
        amount={amount}
        config={props.config}
        disabled={!editingAmount}
        onAmountSave={onAmountSave}
        onEditClick={() => {
          setEditingAmount(true);
          setAmount(0.0);
        }}
      />

      <div className='margin-top-more'><div className='subtitle'>Payment details</div></div>

      <label>
        First Name
        <input id='firstnamefield' disabled={props.disabled} ref={first} size='20' type='text' />
      </label>

      <label>
        Last Name
        <input id='lastnamefield' disabled={props.disabled} ref={last} size='20' type='text' />
      </label>

      <label>
        Card number
        <input id='cardnumberfield' disabled={props.disabled} ref={number} size='20' type='text' />
      </label>

      <label>
        Card CVC
        <input id='cardcvcfield' disabled={props.disabled} ref={cvc} size='20' type='text' />
      </label>

      <fieldset>
        <legend>Expiration date</legend>
        <div>
          <input
            className='inline-block'
            disabled={props.disabled}
            ref={expMonth}
            type='text'
            size='3'
            maxLength='2'
            placeholder='MM'
            id='cardmofield'
          />
          <span>/ </span>
          <input
            className='inline-block'
            disabled={props.disabled}
            ref={expYear}
            type='text'
            size='4'
            maxLength='4'
            placeholder='YYYY'
            id='cardyearfield'
          />
        </div>
      </fieldset>

      {props.config.constituent_pays_fee
        && <AmountBreakdown amount={amount} fee={props.fee} /> }

      <div className='margin-top'>
        {renderSubmitButton()}

        <span id='submitstatus'>{submitStatus()}</span>
      </div>
    </span>

  );
};

CreditCardForm.propTypes = {
  config: PropTypes.object.isRequired,
  noFee: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  amount: PropTypes.number.isRequired,
};

export default CreditCardForm;
