// Swap for lodash?
import _ from 'underscore';
import L from 'mapbox.js';
import 'offline-js';
import '@webcomponents/webcomponentsjs';

// IE 11 Polyfill
import { ResizeObserver } from '@juggle/resize-observer';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

import AdminRoles from 'initializers/admin-roles';
import BatchExportTemplates from 'initializers/batch-export-templates';
import ChargeDisputes from 'initializers/charge-disputes';
import CustomEntities from './initializers/custom-entities';
import Dashboards from './initializers/dashboards';
import EditSuggestionsRequests from './initializers/edit-suggestions-requests';
import Entities from './initializers/entities';
import EntityImports from './initializers/entity-imports';
import Exports from './initializers/exports';
import FlowTemplates from './initializers/flow-templates';
import InitiatedFlows from './initializers/initiated-flows.jsx';
import IssuedOutputs from './initializers/issued-outputs';
import Layout from './initializers/layout.jsx';
import MasterTemplateDocuments from './initializers/master-template-documents';
import MasterTemplateImports from './initializers/master-template-imports';
import MasterTemplates from './initializers/master-templates';
import MerchantAccounts from './initializers/merchant-accounts';
import MetricsDashboard from './initializers/metrics-dashboard';
import MyTasks from './initializers/my-tasks';
import Pages from './initializers/pages';
import PublicInitiatedFlows from './initializers/public/initiated-flows';
import PublicMasterTemplates from './initializers/public/master-templates';
import PublicSurveys from './initializers/public/surveys';
import PublicSurveySubmissions from './initializers/public/survey-submissions';
import PublicTeams from './initializers/public/teams';
import Registrations from './initializers/registrations';
import Review from './initializers/review';
import SearchPages from './initializers/search-pages';
import Teams from './initializers/teams';
import Transactions from './initializers/transactions';
import UnclaimedProcesses from './initializers/unclaimed-processes';
import Users from './initializers/users';
import Verifications from './initializers/verifications';
import objectPatch from './modules/object-patch';

window.ResizeObserver = ResizeObserver;
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

L.mapbox.accessToken = 'pk.eyJ1IjoiY29yd2luc3RlcGhlbiIsImEiOiJkUmYyVlYwIn0.W1x76fJSmLH8Lo34sVCSgw';

const initializers = {
  'MasterTemplate::Documents': MasterTemplateDocuments,
  'MasterTemplate::Imports': MasterTemplateImports,
  'Public::InitiatedFlows': PublicInitiatedFlows,
  'Public::MasterTemplates': PublicMasterTemplates,
  'Public::Surveys': PublicSurveys,
  'Public::SurveySubmissions': PublicSurveySubmissions,
  'Public::Teams': PublicTeams,
  AdminRoles,
  BatchExportTemplates,
  ChargeDisputes,
  CustomEntities,
  Dashboards,
  EditSuggestionsRequests,
  Entities,
  EntityImports,
  Exports,
  FlowTemplates,
  InitiatedFlows,
  IssuedOutputs,
  Layout,
  MasterTemplates,
  MerchantAccounts,
  MetricsDashboard,
  MyTasks,
  Pages,
  Review,
  SearchPages,
  Teams,
  Transactions,
  UnclaimedProcesses,
  Users,
  Registrations,
  Verifications,
};

$(document).ready(() => {
  // Apply patches
  objectPatch.run();

  //
  // Serves as the JS entrypoint on any given page. Essentially
  // just tries to call ControllerName.actionName();
  //

  initializers.Layout();
  const controller = initializers[CityGrows.Server.controllerName];
  const action = CityGrows.Server.actionName;

  if (!_.isUndefined(controller) && _.isFunction(controller[action])) {
    controller[action]();
  } else {
    console.log('No JS to init');
  }
});
