import React from 'react';
import DataVisualizer from 'components/shared/data-visualizer';
import SmallFlowTable from 'components/shared/small-flow-table';
import TemplateManagerStore from 'stores/template-manager-store';
import OverviewStats from './overview-stats';

class Stats extends React.Component {
  state = {
    flowTemplate: TemplateManagerStore.getFlowTemplate(),
    masterTemplate: TemplateManagerStore.getMasterTemplate(),
  };

  componentDidMount() {
    TemplateManagerStore.on('change', this.onTemplateChanged);
  }

  componentWillUnmount() {
    TemplateManagerStore.off('change', this.onTemplateChanged);
  }

  onTemplateChanged = () => {
    this.setState({
      flowTemplate: TemplateManagerStore.getFlowTemplate(),
      masterTemplate: TemplateManagerStore.getMasterTemplate(),
    });
  };

  render() {
    return (
      <div className='templatestats'>
        <div className='fixedcontainer padding-bottom-more'>
          <OverviewStats stats={this.state.flowTemplate.stats} launchData={this.state.flowTemplate.launch_data} />

          <div className='cf margin-top-more'>
            <div id='datavisualizer'>
              <DataVisualizer visualizableFields={this.state.flowTemplate.visualizable_fields} />
            </div>

            <div className='templatestats-activitytable'>
              <header className='margin-bottom'>
                <h2>Activity</h2>
              </header>
              <SmallFlowTable initiatedFlows={this.state.masterTemplate.recent_initiated_flows} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Stats;
