import React from 'react';
import TaskStep from './task-step';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TasksMainContent = ({initiatedFlow, flowTemplateName, activeStep, currentStep, teamFriendlyId}) => {
  function renderTitle() {
    return (
      <div className='mytasks-submissionheader'>
        <h2 className='inline-block'>{flowTemplateName}</h2>
        <div className='float-right inline-block'>
          <a href={`initiated_flows/${initiatedFlow.id}`} target='_blank'>#{initiatedFlow.scoped_id} <FontAwesomeIcon icon={icon({ name: 'arrow-up-right-from-square', style: 'regular' })} /></a>
          </div>
      </div>
    )
  }

  function renderSteps() {
    return (
      <ul className='padded'>
        {initiatedFlow.steps.map(renderStep) }
      </ul>
    )
  }

  function getStepPosition(step) {
    if (currentStep.id === step.id ) { return 'current'}

    if (step.position > initiatedFlow.current_step.position) { return 'future'}

    if (step.position < initiatedFlow.current_step.position) { return 'previous'}
  }

  function renderStep(step) {
    const position = getStepPosition(step);
    const isCurrent = position === 'current';
    const stp = isCurrent ? currentStep : step;
    return (
      <TaskStep
        key={step.position}
        initiatedFlow={initiatedFlow}
        step={stp}
        activeStep={activeStep}
        isCurrent={isCurrent}
        position={position}
        teamFriendlyId={teamFriendlyId}
      />
    );
  }

  return (
    <>
      {renderTitle()}
      {renderSteps()}
    </>
  );
}

export default TasksMainContent;
