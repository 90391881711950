import LocalStorage from 'modules/local-storage';

const LOCAL_STORAGE_KEY = 'QUICKLAUNCH_TEMPLATES';

const onTemplatesLoaded = (req) => {
  cacheTemplates(req.master_templates);

  if(!promise.isResolved){
    promise.resolve({ master_templates: req.master_templates });
  }
};

const cacheTemplates = (templates) => {
  const now = new Date();
  const ttl = 30*60*1000; // 30 minutes

  LocalStorage.save(LOCAL_STORAGE_KEY, {
    templates,
    expiresAt: now.getTime() + ttl
  });
}


const loadMasterTemplates = () => {
  const url = `/master_templates/quick_launch`;

  const req = $.ajax(url, {
    method: 'GET',
    dataType: 'json'
  });

  return req;
};

const getStoredTemplates = () => {
  const now = new Date();
  const storedTemplates = LocalStorage.fetch(LOCAL_STORAGE_KEY);

  if(!storedTemplates || !storedTemplates.expiresAt || storedTemplates.expiresAt < now.getTime()){
    LocalStorage.save(LOCAL_STORAGE_KEY, null);
    return null;
  }

  return storedTemplates.templates;
};

var promise;

const QuicklaunchActions = {

  loadAndCacheMasterTemplates: () => {
    promise = $.Deferred();
    const storedTemplates = getStoredTemplates();
    const loadRequest = loadMasterTemplates()
                          .done(onTemplatesLoaded);

    if(storedTemplates){
      promise.resolve({
        master_templates: storedTemplates,
        loadRequest
      });
    }


    return promise;
  }
};

export default QuicklaunchActions;