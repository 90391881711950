const SurveySubmissionAPI = {
  create: function(surveyId, surveyToken, data){
    const URL = '/survey_submissions';

    return $.ajax(URL, {
      method: 'POST',
      dataType: 'json',
      data: {
        survey_token: surveyToken,
        survey_submission: {
          survey_id: surveyId,
          collected_data_attributes: data.collectedDataAttrs,
          email: data.email
        }
      }
    });
  },

  update: function(submissionToken, data){
    const URL = `/survey_submissions/${submissionToken}`;

    return $.ajax(URL, {
      method: 'PUT',
      dataType: 'json',
      data: {
        survey_submission: {
          collected_data_attributes: data.collectedDataAttrs,
          email: data.email
        }
      }
    });
  },

  fetchData(surveySubmissionId){
    const url = `/survey_submissions/${surveySubmissionId}/fetch_data`;
    return $.ajax(url, {
      method: 'GET',
      dataType: 'json'
    });
  },

  destroy: function(id){
    const url = `/survey_submissions/${id}`;
    return $.ajax(url, {
      method: 'DELETE',
      dataType: 'json',
    });
  }
};

export default SurveySubmissionAPI;
