import React from 'react';
import renderer from 'modules/renderer';

import OrgHeader from 'components/shared/org-header';
import CommonProviders from 'components/common-providers';
import ProcessSearch from 'components/teams/process-search';

const show = () => {
  renderer(
    (
      <CommonProviders>
        <OrgHeader
          currentUser={CityGrows.Server.currentUser}
          team={CityGrows.Server.team}
        />
      </CommonProviders>
    ), document.getElementById('topnav'),
  );

  renderer(
    (
      <ProcessSearch team={CityGrows.Server.team} />
    ), document.getElementById('process-search'),
  );

  const publicPortalHeader = CityGrows.Server.team.public_portal_banner;
  const portalHeaderElement = document.getElementById('publicPortalHeader');
  portalHeaderElement.style.backgroundImage = `url(${publicPortalHeader.url})`;
};

export default {
  show,
  processListing: show,
};
