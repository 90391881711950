import { css } from '@emotion/react';
import { color3 } from 'theme/colors';

export const buildDisc = (size = 30) => {
  return css`
    background-color: ${color3};
    border-radius:50%;
    box-sizing:content-box;
    color:rgb(255, 255, 255, .9);
    cursor:default;
    display:inline-block;
    font-weight:normal;
    height:${size}px;
    line-height:${size}px;
    margin-right:5px;
    overflow:hidden;
    position:relative;
    text-align:center;
    text-transform:uppercase;
    vertical-align:middle;
    width:${size}px;
  `;
}

export const disc = buildDisc();
