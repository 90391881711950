import React from 'react';
import renderer from 'modules/renderer';
import CommonProviders from 'components/common-providers';
import EntityConfiguration from '../components/entity-configuration';
import PrimaryTabs from '../components/shared/primary-tabs';

function index() {
  renderer(<PrimaryTabs />, document.getElementById('tabs'));
  renderer(
    (
      <CommonProviders>
        <EntityConfiguration
          customEntities={CityGrows.Server.custom_entity_data}
          teamId={CityGrows.Server.team.id}
          esriIsEnabled={CityGrows.Server.esriIsEnabled}
        />
      </CommonProviders>
    ), document.getElementById('entity_configuration'),
  );
}

export default {
  index,
};
