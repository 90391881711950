// NOTE: The format in this array matches the
// react-select options format.
const CURRENT_STEP_TERMS = [
  { value: 'minutes', label: 'Minutes' },
  { value: 'hours', label: 'Hours' },
  { value: 'days', label: 'Days' },
];

const CURRENT_STEP_OPERATORS = [
  { value: 'less', label: 'Less' },
  { value: 'longer', label: 'Longer' },
];

const STATUSES = [
  { value: 'active', label: 'Active' },
  { value: 'completed', label: 'Completed' },
  { value: 'halted', label: 'Halted' },
  { value: 'all', label: 'All' },
];

const SORTS = [
  { value: 'last_active', label: 'Recent activity' },
  { value: 'date_launched', label: 'Date launched' },
];

// All of these other ones match react-select2-wrapper
const SOURCES = [
  { id: 'all', text: 'All' },
  { id: 'not_imported', text: 'Not imported' },
  { id: 'imported', text: 'Imported' },
  { id: 'specific_import', text: 'Specific import' },
];

const OUTPUT_STATUSES = [
  { id: 'all', text: 'All' },
  { id: 'issued', text: 'Issued' },
  { id: 'expired', text: 'Expired' },
  { id: 'unissued', text: 'Unissued' },
  { id: 'revoked', text: 'Revoked' },
];

export const RENEWAL_STATUSES = [
  { id: 'all', text: 'All' },
  { id: 'renewal', text: 'Is renewal' },
  { id: 'not_renewal', text: 'Not renewal' },
];

export { OUTPUT_STATUSES as OutputStatuses };

const DEFAULT_CURRENT_STEP_TIME_VALUES = {
  value: null,
  currentStepTimeTerm: CURRENT_STEP_TERMS[2],
  currentStepTimeOperator: CURRENT_STEP_OPERATORS[1],
}

const DEFAULT_LAUNCHED_AT = {
  from: '',
  to: '',
};

const DEFAULT_APPLICANT = {
  id: null,
};

const STATE_FILTER_KEYS = [
  'completedFrom',
  'completedTo',
  'currentStepId',
  'currentStepTimeTerm',
  'currentStepTimeOperator',
  'customId',
  'dataFilters',
  'launchedFrom',
  'launchedTo',
  'applicant',
  'applicantId',
  'onlyAssigned',
  'output',
  'renewal',
  'workflow_status',
  'processId',
  'sourceId',
  'sourceType',
  'status',
  'stepData',
  'tags',
  'teamId',
  'templateId',
];

const WORKFLOW_STATUSES = [
  { id: 'all', text: 'All' },
  { id: 'published', text: 'Published' },
  { id: 'unpublished', text: 'Unpublished' },
  { id: 'closed', text: 'Closed' },
  { id: 'archived', text: 'Archived' },
];

function defaultStatus() {
  return STATUSES[3].value;
}

function defaultSources() {
  return SOURCES[0].id;
}

const FilterDefaults = {
  CURRENT_STEP_TERMS,
  CURRENT_STEP_OPERATORS,
  STATE_FILTER_KEYS,
  DEFAULT_LAUNCHED_AT,
  DEFAULT_APPLICANT,
  DEFAULT_CURRENT_STEP_TIME_VALUES,
  STATUSES,
  SORTS,
  defaultStatus,
  defaultSources,
  WORKFLOW_STATUSES,
};

export default FilterDefaults;
