import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SearchBar from 'components/shared/search-bar';
import SearchResults from 'components/shared/search-results';
import SearchPagesAPI from 'apis/search-pages-api';
import { error as flashError } from 'components/shared/flash';
import KEYS from '../constants/keys';

const SearchPageContainer = ({ name, instructions, searchSlug }) => {
  const [searchType, setSearchType] = useState('exact');
  const [searchTerm, setSearchTerm] = useState('');
  const [searching, setSearching] = useState(false);
  const [results, setResults] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  const searchTypeChanged = ({ value }) => {
    setSearchType(value);
  };

  const searchChanged = (e) => {
    setSearchTerm(e.currentTarget.value);
  };

  const search = (_, page) => {
    setSearching(true);
    setResults(null);
    setCurrentPage(1);
    setTotalPages(0);
    setRowsPerPage(0);
    setTotalResults(0);

    const params = {
      search_terms: searchTerm,
      search_type: searchType,
      page: page || 1,
    };

    SearchPagesAPI.customSearch(params, searchSlug)
      .done((response) => {
        setResults(response.initiated_flows);
        setCurrentPage(response.pagination.current_page);
        setRowsPerPage(response.pagination.per_page);
        setTotalResults(response.pagination.total_count);
      })
      .fail((res) => {
        flashError(res.responseJSON.errors ? res.responseJSON.errors[0] : null);
      })
      .always(() => setSearching(false));
  };

  const onInputKeyUp = (e) => {
    if (e.keyCode === KEYS.ENTER_KEY) {
      e.preventDefault();
      search(e, 1);
    }
  };

  return (
    <div>
      <h2>{name}</h2>
      <div className='margin-bottom'>
        {instructions}
      </div>
      <SearchBar searchTypeChanged={searchTypeChanged} searchType={searchType} search={search}>
        <input type='text' onChange={searchChanged} onKeyUp={onInputKeyUp} value={searchTerm} placeholder='Search...' />
      </SearchBar>
      <div style={{ marginBottom: totalResults > 0 ? '-1.5rem' : '' }}>
        <SearchResults
          results={results}
          searching={searching}
          type='custom'
          slug={searchSlug}
          paginated
          currentPage={currentPage}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          totalResults={totalResults}
          searchHandler={search}
        />
      </div>
    </div>
  );
};

SearchPageContainer.propTypes = {
  name: PropTypes.string,
  instructions: PropTypes.string,
  searchSlug: PropTypes.string,
};

SearchPageContainer.defaultProps = {
  name: '',
  instructions: '',
  searchSlug: '',
};

export default SearchPageContainer;
