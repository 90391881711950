import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EntityActions from 'actions/entity-actions';
import CreateButton from './create-button';
import SearchResults from './search-results';
import { error } from '../../flash';

const EntitySelector = ({
  customEntity,
  required,
  stepDatumId,
  canCreateNewEntity,
  onSelectEntityClick,
  createNewEntity,
  initiatedFlowId,
  fieldValue,
  disabled
}) => {
  const [searchResults, setSearchResults] = useState(null);
  const [searchTerm, setSearchTerm] = useState();

  useEffect(() => {
    setSearchResults([]);
  }, [fieldValue]);

  function enterSearch(e) {
    if (e.key === 'Enter' || e.keycode === 13) { 
      fetchEntities();
    }
  }

  const fetchEntities = () => {
    setSearchResults([]);
    EntityActions.search(initiatedFlowId, stepDatumId, searchTerm)
      .done((results) => {
        setSearchResults(results.length !== 0 ? results : null);
      })
      .fail(() => {
        error('Unable to fetch entities. If this problem persists, please open a discussion.');
      });
  };

  return (
    <div className='entityinput-search raised margin-bottom padded'>
      <div className='margin-bottom color-text-medium' id={`entity-search-label-${stepDatumId}`}>{`Search for an existing ${customEntity.name}`}</div>
      <div>
        <input
          placeholder={`Type a ${customEntity.name} name or identifier...`}
          type='text'
          aria-labelledby={`entity-search-label-${stepDatumId}`}
          aria-required={required}
          value={searchTerm}
          onKeyUp={enterSearch}
          onChange={(e) => { return setSearchTerm(e.target.value); }}
          className='box'
          name='entitysearch'
          disabled={disabled}
        />
        <button
          name='entitysearch'
          type='button'
          className='fullwidth btn-secondary'
          onClick={fetchEntities}
          disabled={disabled}
        >
          Search
        </button>
      </div>
      <SearchResults
        customEntity={customEntity}
        searchResults={searchResults}
        onSelectEntityClick={onSelectEntityClick}
        searchTerm={searchTerm}
      />
      {!disabled && canCreateNewEntity && (
        <CreateButton
          customEntity={customEntity}
          createNewEntity={createNewEntity}
        />
      )}
    </div>
  );
};

EntitySelector.propTypes = {
  customEntity: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  required: PropTypes.bool.isRequired,
  stepDatumId: PropTypes.number.isRequired,
  canCreateNewEntity: PropTypes.bool.isRequired,
  onSelectEntityClick: PropTypes.func.isRequired,
  createNewEntity: PropTypes.func.isRequired,
  initiatedFlowId: PropTypes.number.isRequired,
  fieldValue: PropTypes.string.isRequired,
};

export default EntitySelector;
