import PropTypes from 'prop-types';
import React, { Component } from 'react';

class PasswordStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
    };
  }

  componentDidMount() {
    this.refs.password.focus();
  }

  isIncomplete = () => {
    return !this.state.password || this.state.password.length < 8;
  };

  onChange = (e) => {
    this.setState({
      password: e.currentTarget.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.onSubmit(this.refs.password.value);
  };

  buttonText = () => {
    if (this.isIncomplete()) {
      return 'Password too short';
    }

    return 'Launch submission >';
  };

  render() {
    return (
      <div>
        <h1>Choose a password</h1>
        <span>Use your password to return to your submission at any time.</span>

        <form className='center' onSubmit={this.onSubmit}>
          <label>Password
            <input value={this.state.password} autoFocus onChange={this.onChange} ref='password' type='password' />
          </label>

          <nav>
            <a href='#' onClick={this.props.onBackClick}>{'< Go back'}</a>
            <button className='btn-primary' disabled={this.isIncomplete()} type='submit'>{this.buttonText()}</button>
          </nav>
        </form>
      </div>
    );
  }
}

PasswordStep.propTypes = {
  onBackClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PasswordStep;
