import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { where, any } from 'underscore';

import AutonomousPopup from 'components/shared/autonomous-popup';
import DiscGroup from 'components/shared/disc-group';
import LaunchInfo from './launch-info';
import TagManager from './tag-manager';
import TagList from './tag-list';

const HeaderMeta = memo((props) => {
  const ignoredCssClasses = [
    'visible',
    'alertconfirm-container',
    'alertconfirm-icon',
    'alertconfirm-text',
    'alertconfirm-title',
    'alertconfirm-message',
    'alertconfirm-actions',
    'btn-secondary',
    'btn-primary'];

  const renderTagManager = () => {
    if (props.admin) {
      const buttonHTML = (
        <button
          type='button'
          onClick={(e) => e.preventDefault()}
          className='clickable secondary btn-link'
        >
          Manage tags
        </button>
      );

      return (
        <AutonomousPopup buttonHtml={buttonHTML} wrapperIgnoreClass={ignoredCssClasses}>
          <TagManager
            taggableId={props.initiatedFlow.id}
            taggableType='InitiatedFlow'
            taggings={props.initiatedFlow.taggings}
            suggestedTags={props.initiatedFlow.suggested_tags}
          />
        </AutonomousPopup>
      );
    }
  };

  const flowStatusClass = () => {
    const classes = ['activeflowheader-openclosed'];

    if (props.initiatedFlow.status === 'Halted') {
      classes.push('color-alert');
    }

    return classes.join(' ');
  };

  const members = useMemo(() => {
    return where(props.initiatedFlow.person_attachments, { assigned: true });
  }, [props.initiatedFlow.person_attachments]);

  return (
    <div className='activeflowheader-meta'>
      <ul>
        <LaunchInfo
          initiatedFlow={props.initiatedFlow}
          admin={props.admin}
          flowTemplateId={props.flowTemplateId}
        />

        <li>
          <div>Status</div>
          <div
            className={flowStatusClass()}
            dangerouslySetInnerHTML={{ __html: props.initiatedFlow.status }}
          />
        </li>
        { any(members) && (
          <li id='assignees'>
            <DiscGroup groupMembers={members} />
          </li>
        )}
        <li className='flex vmiddle'>
          <ul className='activeflowheader-statustags'>
            <TagList
              taggings={props.initiatedFlow.taggings}
            />
          </ul>

          {renderTagManager()}
        </li>
      </ul>
    </div>
  );
});

HeaderMeta.displayName = 'HeaderMeta';

HeaderMeta.propTypes = {
  admin: PropTypes.bool.isRequired,
  flowTemplateId: PropTypes.number.isRequired,
  initiatedFlow: PropTypes.shape({
    id: PropTypes.number.isRequired,
    suggested_tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    taggings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  teamId: PropTypes.number.isRequired,
};

export default HeaderMeta;
