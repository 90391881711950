import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import PropTypes from 'prop-types';
import { validateNumberFormat } from 'modules/collected-data-validator';
import AdvancedLabel from 'components/shared/advanced-label';
import { debounce as d } from 'underscore';

const NumericSettings = ({
  stepDatum,
  onDataChanged,
  onLimitChanged,
}) => {
  const [minValue, setMinValue] = useState(stepDatum.min_value || '');
  const [maxValue, setMaxValue] = useState(stepDatum.max_value || '');
  const debouncedOnDataChanged = d(onDataChanged, 500);

  useEffect(() => {
    if (stepDatum.min_value !== minValue) {
      if (validateMinValue(minValue)) {
        updateValue({ min_value: minValue }, true);
      }
    }
  }, [maxValue]);

  useEffect(() => {
    if (stepDatum.max_value !== maxValue) {
      if (validateMaxValue(maxValue)) {
        updateValue({ max_value: maxValue }, true);
      }
    }
  }, [minValue]);

  const validateMinValue = (value = minValue) => {
    let min = value;
    let max = maxValue;
    if (min === '') { return true; }
    if (!validateNumberFormat(min, stepDatum)) { return false; }
    if (!validateNumberFormat(max, stepDatum)) { return true; }
    min = parseFloat(min);
    max = parseFloat(max);
    return min <= max;
  };

  const validateMaxValue = (value = maxValue) => {
    let min = minValue;
    let max = value;
    if (max === '') { return true; }
    if (!validateNumberFormat(max, stepDatum)) { return false; }
    if (!validateNumberFormat(min, stepDatum)) { return true; }
    min = parseFloat(min);
    max = parseFloat(max);
    return min <= max;
  };

  const updateValue = (attrs, debounce = false) => {
    const call = debounce ? debouncedOnDataChanged : onDataChanged;
    call(
      attrs,
      () => {
        onLimitChanged();
      },
    );
  };

  const onMinValueChanged = (e) => {
    const { value } = e.target;
    setMinValue(value);

    if (validateMinValue(value)) {
      updateValue({ min_value: value }, true);
    }
  };

  const onMinValueClear = (e) => {
    e.preventDefault();
    setMinValue('');
    updateValue({ min_value: '' });
  };

  const onMaxValueChanged = (e) => {
    const { value } = e.target;
    setMaxValue(value);
    if (validateMaxValue(value)) {
      updateValue({ max_value: value }, true);
    }
  };

  const onMaxValueClear = (e) => {
    e.preventDefault();
    setMaxValue('');
    updateValue({ max_value: '' });
  };

  return (
    <>
      <tr>
        <td>
          <AdvancedLabel
            label='Min Value'
          />
        </td>
        <td>
          <div className='inline-block'>
            <input
              id='minvalue'
              className={`fieldmanager-minvalue ${!validateMinValue() ? 'invalid' : ''}`}
              type='text'
              name='minvalue'
              value={minValue}
              onChange={onMinValueChanged}
              placeholder='Add a minimum'
            />
          </div>
          <FontAwesomeIcon
            icon={icon({ name: 'trash' })}
            className='clear-button margin-left'
            onClick={onMinValueClear}
            title='Clear Min Value'
          />
          { !validateMinValue() && (
            <div className='fieldmanager-invalidfield'>
              Invalid min value
            </div>
          )}
        </td>
      </tr>
      <tr>
        <td>
          <AdvancedLabel
            label='Max Value'
          />
        </td>
        <td>
          <div className='inline-block'>
            <input
              id='maxvalue'
              className={`fieldmanager-maxvalue ${!validateMaxValue() ? 'invalid' : ''}`}
              type='text'
              name='maxvalue'
              value={maxValue}
              onChange={onMaxValueChanged}
              placeholder='Add a maximum'
            />
          </div>
          <FontAwesomeIcon
            icon={icon({ name: 'trash' })}
            className='clear-button margin-left'
            onClick={onMaxValueClear}
            title='Clear Max Value'
          />
          { !validateMaxValue() && (
            <div className='fieldmanager-invalidfield'>
              Invalid max value
            </div>
          )}
        </td>
      </tr>
    </>
  );
};

NumericSettings.propTypes = {
  stepDatum: PropTypes.shape({
    min_value: PropTypes.string.isRequired,
    max_value: PropTypes.string.isRequired,
  }),
  onDataChanged: PropTypes.func.isRequired,
  onLimitChanged: PropTypes.func.isRequired,
};

export default NumericSettings;
