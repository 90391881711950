const TemplateCategoryAPI = {

  create: (name) => {
    const url = '/template_categories';

    return $.ajax(url, {
      dataType: 'json',
      method: 'POST',
      data: {
        name,
      },
    });
  },

};

export default TemplateCategoryAPI;
