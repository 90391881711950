import React, { FunctionComponent } from 'react';
import Map from './map';
import InlineNotification from '../../inline-notification';
import RemoveEntityButton from './remove-entity-button';

type SelectedEsriEntityProps = {
  entity: {
    field_data: object;
    feature: {
      geometry: any;
    };
    esri_unique_id: string;
  };
  removeEntity: () => void;
  customEntity: {
    name: string;
  };
  disabled: any;
}

const SelectedEsriEntity: FunctionComponent<SelectedEsriEntityProps> = ({
  entity,
  removeEntity,
  customEntity,
  disabled,
}: SelectedEsriEntityProps) => {
  const renderEsriFields = () => {
    return Object.entries(entity.field_data).map(([key, val]) => {
      return (
        <li>
          <label>
            {key}
          </label>
          {val}
        </li>
      );
    });
  };

  return (
    <div className='entityinput raised'>
      <div className='entityinput-name'>
        {!disabled && <RemoveEntityButton name={customEntity.name} removeEntity={removeEntity} />}
      </div>

      {!!entity.field_data ? (
        <>
          <ul>
            {renderEsriFields()}
          </ul>
          <Map feature={entity.feature} />
        </>
      ) : (
        <InlineNotification>
          {`Remote entity (${customEntity.name} ${entity.esri_unique_id}) not found. It may have been deleted, or there may be an issue connecting to ESRI.`}
        </InlineNotification>
      )}

    </div>
  );
};

export default SelectedEsriEntity;
