import PropTypes from 'prop-types';
import React, { Component } from 'react';
import keyboard from 'keyboardjs';
import _ from 'underscore';
import RegexEscape from 'regex-escape';

const TAG_MARKER = '@';

class MentionsManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mentionStarted: false,
      suggestions: [],
      selectionIdx: 0,
      searchQueries: this.getSearchQueries(props),
    };
  }

  componentDidMount() {
    this.inputElement = this.props.getInputElement();

    // scope events to inputElement
    keyboard.watch(this.inputElement);

    keyboard.on('up', this.prevSuggestion);
    keyboard.on('down', this.nextSuggestion);

    // keydown event needs to use the useCapture option
    document.addEventListener('keydown', this.handleKeydown, true);
  }

  componentWillUnmount() {
    keyboard.stop(this.inputElement);
    keyboard.off('up', this.prevSuggestion);
    keyboard.off('down', this.nextSuggestion);
    document.removeEventListener('keydown', this.handleKeydown, true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.html === prevProps.html) return;

    this.handleTagSearch(this.props);
  }

  handleKeydown = (e) => {
    if (e.keyCode === 13 && this.state.suggestions.length) {
      // if enter is pressed prevent input element from receiving event
      e.stopPropagation();
      e.preventDefault();

      this.selectSuggestion(e);
    }
  };

  handleDefaultKeyEvents = (e) => {
    if (this.state.suggestions.length) {
      e.preventDefault();
    }
  };

  nextSuggestion = (e) => {
    this.handleDefaultKeyEvents(e);

    if (!this.state.suggestions.length) return;

    this.setState({
      selectionIdx: (this.state.selectionIdx + 1) % this.state.suggestions.length,
    });
  };

  prevSuggestion = (e) => {
    this.handleDefaultKeyEvents(e);

    if (!this.state.suggestions.length) return;

    let newIndex;
    if ((this.state.selectionIdx - 1) < 0) {
      // wrap around if already on first selection
      newIndex = this.state.suggestions.length - 1;
    } else {
      newIndex = this.state.selectionIdx - 1;
    }

    this.setState({ selectionIdx: newIndex });
  };

  selectSuggestion = (e) => {
    this.handleDefaultKeyEvents(e);

    const selectedTag = this.state.suggestions[this.state.selectionIdx];
    this.insertTag(selectedTag);
  };

  resetSuggestions = () => {
    this.setState({
      selectionIdx: 0,
      suggestions: [],
    });
  };

  mentionNames = () => {
    return this.props.data.map((mention) => this.normalizeName(mention.displayName));
  };

  normalizeName(name) {
    return name.toLowerCase().split(' ').join('-');
  }

  handleTagSearch = (newProps) => {
    const newQueries = this.getSearchQueries(newProps);
    const newActiveQuery = this.getActiveQuery(newQueries);

    // the html changing but the active query staying the same
    // means the change was made outside of the query and
    // the query should no longer be active
    const queryInactive = _.isEqual(newActiveQuery, this.state.activeQuery) && (this.props.html !== newProps.html);

    if (typeof newActiveQuery === 'undefined' || queryInactive) {
      this.resetSuggestions();
    } else {
      this.updateSuggestedMentions(newActiveQuery);
    }

    this.setState({
      searchQueries: newQueries,
      activeQuery: newActiveQuery,
    });
  };

  getActiveQuery(newQueries) {
    let activeQuery;
    newQueries.forEach((newQuery, idx) => {
      const oldQuery = this.state.searchQueries[idx];

      if ((typeof oldQuery === 'undefined') || (newQuery !== oldQuery)) {
        activeQuery = newQuery;
      }
    });

    return activeQuery;
  }

  updateSuggestedMentions = (queryStr) => {
    const suggestions = this.props.data.filter((datum) => {
      const escapedQuery = RegexEscape(queryStr);
      return this.normalizeName(datum.name).match(new RegExp(`^${escapedQuery}`));
    });

    // only show three suggestions
    this.setState({ suggestions: suggestions.slice(0, 3) });
  };

  getSearchQueries = ({ html }) => {
    const allPossibleQueries = [];

    let mentionBeginningIdx;
    for (let i = 0; i < html.length; i++) {
      const char = html[i];
      if (char === TAG_MARKER) {
        // if char matches, mark beginning of possible mention position
        mentionBeginningIdx = i;
      } else if ((char === '<' || char === ' ') && mentionBeginningIdx) {
        // if possible mention substring has ended add pair of indices to output array
        allPossibleQueries.push(html.slice(mentionBeginningIdx + 1, i));

        mentionBeginningIdx = null;
      }
    }

    return allPossibleQueries;
  };

  insertTag(mention) {
    this.props.onMentionInsert(mention.mentionType, mention.id, mention.name);
    this.resetSuggestions();
  }

  renderSuggestedTag = (mention, idx) => {
    const isSelected = this.state.selectionIdx === idx;
    return (
      <li
        className={isSelected ? 'selected' : ''}
        key={mention.id}
        onClick={() => this.insertTag(mention)}
      >

        <i className={isSelected ? mention.selectedIconClass : mention.iconClass} />
        {this.normalizeName(mention.displayName)}
      </li>
    );
  };

  renderSuggestedMentions = () => {
    return this.state.suggestions.map(this.renderSuggestedTag);
  };

  render() {
    return (
      <ul className='inlinementioner'>{this.renderSuggestedMentions()}</ul>
    );
  }
}

MentionsManager.propTypes = {
  onMentionInsert: PropTypes.func.isRequired,
  html: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      mentionType: PropTypes.string.isRequired,
      iconClass: PropTypes.string.isRequired,
      selectedIconClass: PropTypes.string.isRequired,
    }),
  ).isRequired,
  getInputElement: PropTypes.func.isRequired,
};

export default MentionsManager;
