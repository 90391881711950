const COLORS = [
  '#2797FA',
  '#74A943',
  '#FB8E28',
  '#714FB6',
  '#FDC33C',
  '#034885',
  '#00ABB6',
  '#CF1C9D',
  '#C6C613',
  '#B1B2FF',
  '#FC876D',
  '#1E6E00',
  '#B9702C',
  '#53625D',
  '#7BE0F1',
  '#0463B7',
  '#7BC0FF',
  '#FD73C6',
  '#2654C9',
  '#90A1A9',
  '#008C95',
  '#FCB878',
  '#EF3477',
  '#8F5332',
];

export default COLORS;
