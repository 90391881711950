export const waive = (subprocessRequirementId) => {
  const url = `/subprocess_requirements/${subprocessRequirementId}/waive`;

  return $.ajax(url, {
    method: 'POST',
    dataType: 'json',
  });
};

export const unwaive = (subprocessRequirementId) => {
  const url = `/subprocess_requirements/${subprocessRequirementId}/unwaive`;

  return $.ajax(url, {
    method: 'POST',
    dataType: 'json',
  });
};

export const unlinkSubprocess = (subprocessRequirementId) => {
  const url = `/subprocess_requirements/${subprocessRequirementId}/unlink_subprocess`;

  return $.ajax(url, {
    method: 'POST',
    dataType: 'json',
  });
};

export default {
  waive,
  unwaive,
  unlinkSubprocess,
};
