import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { filter, findWhere, find } from 'underscore';
import calculateTotalFee from 'modules/fee-calculator';
import Receipt from './payments/receipt';
import Paid from './payments/paid';
import UnPaid from './payments/unpaid';
import NoPaymentRequiredView from './payments/no-payment-required-view';
import ActionLinks from './payments/action-links';
import Pending from './payments/pending';
import RefundWidget from './payments/refund-widget';

const PaymentStep = (props) => {
  const { step: { config, customFeePercentage, no_fee } } = props;
  const [paymentAmount, setPaymentAmount] = useState(initialPaymentAmount());
  const [stripePaymentType, setStripePaymentType] = useState(null);
  const [refunding, setRefunding] = useState(false);

  const fee = useMemo(() => {
    if (!paymentAmount || stripePaymentType == null || !config.constituent_pays_fee) {
      return 0.0;
    }

    return calculateTotalFee(stripePaymentType, paymentAmount, no_fee, customFeePercentage);
  }, [stripePaymentType, paymentAmount, customFeePercentage, no_fee, config.constituent_pays_fee]);

  const totalAmount = useMemo(() => {
    if (!paymentAmount) {
      return 0.0;
    }
    return paymentAmount + fee;
  }, [paymentAmount, fee]);

  function noPaymentRequired() {
    return (config.fixed && fixedPaymentAmount() === 0)
           || props.activeStep.payment_waived;
  }

  function fixedPaymentAmount() {
    if (config.calculated) {
      return props.activeStep.calculated_payment;
    }

    return Number(config.amount);
  }

  function initialPaymentAmount() {
    return config.fixed
      ? fixedPaymentAmount()
      : 0;
  }

  function paidCharge() {
    return findWhere(props.activeStep.charges, { status: 'paid' });
  }

  function pendingCharge() {
    return find(props.activeStep.charges, (charge) => { return charge.status === 'pending' || charge.status === 'refund_pending'; });
  }

  function isPaid() {
    const hasNonRefundedCharge = !!paidCharge();
    return hasNonRefundedCharge
           || !!props.activeStep.check_paid_amount
           || !!props.activeStep.cash_paid_amount;
  }

  function isPending() {
    return !!pendingCharge();
  }

  function noOptionLinks() {
    if (pendingCharge()) { return false; }

    const charge = paidCharge();
    return (
      !props.activeStep.cash_paid_amount
      && !props.activeStep.check_paid_amount
      && !charge
    );
  }

  function renderProcessingInformation() {
    if (props.activeStep.payment_method === 'creditcard' && paymentAmount > 0) {
      const processingTime = stripePaymentType === 'card' ? 'up to 24 hrs' : '3-5 business days';
      const feeText = config.constituent_pays_fee ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(fee) : '';
      return (
        <dt>
          <p className='processing-information'>
            <i className={`icon-${stripePaymentType === 'card' ? 'card' : 'bank'}`} />&nbsp;Processing time: <strong>{processingTime}</strong>
            {config.constituent_pays_fee && ' | Processing Fee: '}
            {config.constituent_pays_fee && <strong>{feeText}</strong>}
          </p>
        </dt>
      );
    }
  }

  function headerText() {
    if (isPaid()) {
      return 'Payment confirmation';
    }

    if (isPending()) {
      return 'Payment processing';
    }

    if (totalAmount > 0) {
      return (
        <dl>
          <dt>Total amount due</dt>
          <dd>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalAmount)}</dd>
          {renderProcessingInformation()}
        </dl>
      );
    }

    return 'Make a payment';
  }

  function hideOptions() {
    return !props.admin || (noOptionLinks() && props.supplemental);
  }

  function failedPayments() {
    const failed = filter(props.activeStep.charges, { status: 'failed' });

    if (failed.length < 1) { return; }

    const renderedFails = failed.map((charge) => {
      return (
        <div key={charge.id} className='raised bg-white padded margin-top'>
          {renderReceiptForCharge(charge)}
        </div>
      );
    });

    return (
      <div>
        <h3 className='margin-top-more text-alert'>Failed payments</h3>
        {renderedFails}
      </div>
    );
  }

  function refundedPayments() {
    const refunded = filter(props.activeStep.charges, { status: 'refunded' });
    if (refunded.length < 1) { return; }

    const renderedRefunds = refunded.map((charge) => {
      return (
        <div key={charge.id} className='raised bg-white padded margin-top'>
          {renderReceiptForCharge(charge)}
        </div>
      );
    });

    return (
      <div>
        <h3 className='margin-top-more'>Refunded payments</h3>
        {renderedRefunds}
      </div>
    );
  }

  function renderReceiptForCharge(charge) {
    return (
      <Receipt
        charge={charge}
        activeStep={props.activeStep}
        admin={props.admin}
        key={charge.id}
      />
    );
  }

  function mainContent() {
    if (noPaymentRequired()) {
      return (<NoPaymentRequiredView admin={props.admin} waived={props.activeStep.payment_waived} />);
    }

    if (isPaid()) {
      return (
        <Paid
          nonRefundedCharge={paidCharge()}
          activeStep={props.activeStep}
          admin={props.admin}
          renderReceiptForCharge={renderReceiptForCharge}
        />
      );
    }

    if (isPending()) {
      return (
        <Pending
          charge={pendingCharge()}
          activeStep={props.activeStep}
          admin={props.admin}
          stepType={props.step.type}
        />
      );
    }

    return (
      <UnPaid
        step={props.step}
        activeStep={props.activeStep}
        amount={paymentAmount}
        fee={fee}
        admin={props.admin}
        teamFriendlyId={props.teamFriendlyId}
        onStripePaymentTypeChange={(type) => {
          setStripePaymentType(type);
          if (type == null && !config.fixed) {
            setPaymentAmount(0.0);
          }
        }}
        onStripePaymentAmountChange={(amount) => {
          setPaymentAmount(amount);
        }}
      />
    );
  }

  const showPaymentWaive = !props.supplemental && noOptionLinks();

  return (

    <div className='currentstep-stepsection paymentmanager'>
      <div className='raised bg-white'>
        <div className='paymentmanager-header'>
          <h2>{headerText()}</h2>
          {!hideOptions() && (
            <ActionLinks
              showPaymentWaive={showPaymentWaive}
              activeStep={props.activeStep}
              stepType={props.step.type}
              refunding={refunding}
              nonRefundedCharge={paidCharge()}
              onRefund={() => { setRefunding(true); }}
              authorities={props.authorities}
            />
          ) }
        </div>
        {refunding && <RefundWidget onRefundCancel={() => { setRefunding(false); }} nonRefundedCharge={paidCharge()} />}

        <div className='paymentmanager-body'>
          {mainContent()}
        </div>
      </div>

      {failedPayments()}
      {refundedPayments()}
    </div>
  );
};

PaymentStep.propTypes = {
  admin: PropTypes.bool.isRequired,
  activeStep: PropTypes.object.isRequired,
  charges: PropTypes.array.isRequired,
  step: PropTypes.object.isRequired,
  supplemental: PropTypes.bool.isRequired,
};

export default PaymentStep;
