import React, { useEffect, useState } from "react";
import { contains } from "underscore";

import Select from "../select";
import SlidingToggle from "../sliding-toggle";
import DateRange from "./date-range";

const SingleCheckboxToggle = ({
  opt,
  onToggleFallback,
  on,
}) => {
  const [toggle, setToggle] = useState(on);

  useEffect(() => {
    onToggleFallback(opt.identity, toggle);
  }, [toggle]);

  return (
    <div
      className='filter-unit-datum-single-checkbox'
      style={{ display: 'inline-block', margin: '6px 0 0 12px' }}
    >
      <span key={opt.identity}>{opt.name}</span>
      <SlidingToggle
        on={toggle}
        onToggle={() => setToggle(!toggle)}
      />
    </div>
  )
};

const CheckboxesSelect = ({
  options,
  onDatumFieldsChange,
  terms,
}) => {
  const [activeOptions, setActiveOptions] = useState([]);
  const [toggleValues, setToggleValues] = useState({});

  useEffect(() => {
    if (terms) {
      const termIds = Object.keys(terms);
      setActiveOptions(
        options.filter(item => contains(termIds, item.identity))
      );
      setToggleValues(terms);
    }
  }, []);

  useEffect(() => {
    onDatumFieldsChange(toggleValues);
  }, [toggleValues]);

  const onSelect = (value) => {
    setActiveOptions(value);

    let newTerm = {};
    value.forEach((val) => {
      if (toggleValues[val.identity] != undefined) {
        newTerm[val.identity] = toggleValues[val.identity];
      } else {
        newTerm[val.identity] = true;
      }
    });

    setToggleValues(newTerm);
  };

  const onToggleFallback = (identity, toggle) => {
    setToggleValues({
      ...toggleValues,
      [identity]: toggle
    });
  };

  const renderOption = (opt) => {
    return <SingleCheckboxToggle
      key={opt.identity}
      opt={opt}
      onToggleFallback={onToggleFallback}
      on={toggleValues[opt.identity] === true || toggleValues[opt.identity] === 'true'}
    />;
  };

  return (
    <>
      <div className='metricsdashboard-filterselect'>
        <Select
          isMulti
          options={options}
          onChange={onSelect}
          placeholder='Select a value'
          getOptionValue={(opt) => opt.identity}
          getOptionLabel={(opt) => opt.name}
          className='react-select-metrics advanced-filter'
          value={activeOptions}
        />
      </div>
      <div style={{ display: 'inline-block', maxWidth: '50%', verticalAlign: 'top' }}>
        { activeOptions.map(renderOption) }
      </div>
    </>
  );
};


const DatumFieldFilter = ({
  onDatumFieldsChange,
  fieldType,
  multipleChoiceOptions,
  datumFields,
  datumId,
}) => {
  const onInputChange = (e) => {
    onDatumFieldsChange(e.target.value);
  };

  const onMultipleChoiceChange = (value) => {
    onDatumFieldsChange(value.name);
  }

  const fetchFilterValue = () => {
    if (!datumFields.value) { return null; }

    return datumFields.value[datumId];
  };

  const renderDefaultInput = () => {
    return (
      <div className='metricsdashboard-filterselect'>
        <input
          autoComplete='chrome-off'
          onChange={onInputChange}
          type='text'
          placeholder='Type...'
          style={{ margin: '3px 0 0 3px' }}
          value={fetchFilterValue()}
        />
      </div>
    );
  };

  const renderCheckboxesSelect = () => {
    return <CheckboxesSelect
      options={multipleChoiceOptions}
      onDatumFieldsChange={onDatumFieldsChange}
      terms={fetchFilterValue()}
    />;
  };

  const renderMultipleChoiceSelect = () => {
    const filterValue = fetchFilterValue();
    const defaultValue = multipleChoiceOptions.find(item => item.name === filterValue);

    return (
      <div className='metricsdashboard-filterselect'>
        <Select
          options={multipleChoiceOptions}
          onChange={onMultipleChoiceChange}
          placeholder='Select a value'
          getOptionValue={(opt) => opt.name}
          getOptionLabel={(opt) => opt.name}
          className='react-select-metrics advanced-filter'
          value={defaultValue}
        />
      </div>
    );
  };

  switch (fieldType) {
    case 'checkboxes': {
      return renderCheckboxesSelect();
    }

    case 'date': {
      const fromTo = datumFields.value && datumFields.value[datumId];
      return <DateRange
        changeFilterValue={onDatumFieldsChange}
        fromToValues={fromTo || [null, null]}
      />
    }

    case 'multiple_choice': {
      return renderMultipleChoiceSelect();
    }

    default: {
      return renderDefaultInput();
    }
  }
};

export default DatumFieldFilter;
